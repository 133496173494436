import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/inventory";

const getProductsUrl = (page = 1, search = null, perPage = 20) => {
  let baseUrl = `/api/products/?page=${page}&per_page=${perPage}`;
  if (search) {
    baseUrl = `${baseUrl}&search=${search}`;
  }
  return baseUrl;
};

export const fetchProducts = (page = 1, search = null, perPage = 20) => ({
  [CALL_API]: {
    endpoint: getProductsUrl(page, search, perPage),
    method: "GET",
    types: [
      constants.FETCH_PRODUCTS_REQUEST,
      constants.FETCH_PRODUCTS_SUCCESS,
      constants.FETCH_PRODUCTS_FAILURE,
    ],
  },
});

export const fetchProduct = (productId) => ({
  [CALL_API]: {
    endpoint: `/api/products/${productId}/`,
    method: "GET",
    types: [
      constants.FETCH_PRODUCT_REQUEST,
      constants.FETCH_PRODUCT_SUCCESS,
      constants.FETCH_PRODUCT_FAILURE,
    ],
  },
});

export const updateProduct = (productId, data) => ({
  [CALL_API]: {
    endpoint: `/api/products/${productId}/`,
    method: "PUT",
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    types: [
      constants.UPDATE_PRODUCT_REQUEST,
      constants.UPDATE_PRODUCT_SUCCESS,
      constants.UPDATE_PRODUCT_FAILURE,
    ],
  },
});

export const createProduct = (data) => ({
  [CALL_API]: {
    endpoint: "/api/products",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: data,
    types: [
      constants.CREATE_PRODUCT_REQUEST,
      constants.CREATE_PRODUCT_SUCCESS,
      constants.CREATE_PRODUCT_FAILURE,
    ],
  },
});

export const deleteProduct = (productId) => ({
  [CALL_API]: {
    endpoint: `/api/products/${productId}`,
    method: "DELETE",
    types: [
      constants.DELETE_PRODUCT_REQUEST,
      {
        type: constants.DELETE_PRODUCT_SUCCESS,
        meta: { productId },
      },
      constants.FETCH_PRODUCTS_FAILURE,
    ],
  },
});

export const createProductCards = (payload) => ({
  [CALL_API]: {
    endpoint: "/api/products/cards",
    method: "POST",
    body: payload,
    types: [
      "CREATE_PRODUCT_CARDS_REQUEST",
      "CREATE_PRODUCT_CARDS_SUCCESS",
      "CREATE_PRODUCT_CARDS_FAILURE",
    ],
  },
});

export const duplicateProduct = (productId) => ({
  [CALL_API]: {
    endpoint: `/api/products/${productId}/duplicate`,
    method: "POST",
    types: [
      constants.DUPLICATE_PRODUCT_REQUEST,
      constants.DUPLICATE_PRODUCT_SUCCESS,
      constants.DUPLICATE_PRODUCT_FAILURE,
    ],
  },
});

export const appendProductCard = (productIds, stepId) => ({
  [CALL_API]: {
    endpoint: `/api/products/append_template_card`,
    method: "POST",
    body: { productIds, stepId },
    types: [
      "APPEND_PRODUCT_CARD_REQUEST",
      "APPEND_PRODUCT_CARD_SUCCESS",
      "APPEND_PRODUCT_CARD_FAILURE",
    ],
  },
});

export const importProductsFromShopify = () => ({
  [CALL_API]: {
    endpoint: "/api/products/shopify_import",
    method: "POST",
    types: [
      constants.IMPORT_SHOPIFY_PRODUCTS_REQUEST,
      constants.IMPORT_SHOPIFY_PRODUCTS_SUCCESS,
      constants.IMPORT_SHOPIFY_PRODUCTS_FAILURE,
    ],
  },
});
