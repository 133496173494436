import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader";
import { Redirect } from "react-router";

import { ContentHeader } from "../../components/shared";
import {
  ConversationSteps,
  SubscriberFlow,
} from "../../components/ConversationDetail";

import {
  fetchConversation,
  fetchVisibleConversationSteps,
  setTriggerChoice,
} from "../../actions/conversations";

import { fetchOrganizationFields } from "../../actions/organization-fields";
import { fetchCampaign } from "../../actions/campaigns";
import { fetchGlobalTrigger } from "../../actions/global-triggers";

import { getConversationLink } from "../../utilities/conversation";

class ConversationPreviewContainer extends React.Component {
  async componentWillMount() {
    const {
      campaignId,
      conversationId,
      triggerId,

      fetchCampaign,
      fetchConversation,
      fetchGlobalTrigger,
      fetchVisibleConversationSteps,
      fetchOrganizationFields,
    } = this.props;

    const promises = [
      fetchConversation(conversationId),
      fetchVisibleConversationSteps(conversationId),
      fetchOrganizationFields(),
    ];

    if (campaignId) promises.push(fetchCampaign(campaignId));
    else if (triggerId) promises.push(fetchGlobalTrigger(triggerId));

    await Promise.all(promises);
  }

  refreshSteps = () => {
    this.props.fetchVisibleConversationSteps(this.props.conversationId);
  };

  render() {
    const {
      conversation,
      conversationId,
      visibleConversationSteps: {
        isLoading: areVisibleConversationStepsLoading,
        stepIds: visibleConversationStepIds,
      },
    } = this.props;

    if (
      conversation &&
      !conversation.isLoading &&
      !(conversation.published || conversation.sending)
    ) {
      const buildUrl = getConversationLink(conversation) + "/build";
      return <Redirect push to={buildUrl} />;
    }

    return (
      <Loader
        loaded={!conversation.isLoading && !areVisibleConversationStepsLoading}
        loadedClassName="conversation-detail"
      >
        <ContentHeader left={<h1>{conversation.label}</h1>} right="" />
        <div className="conversation-builder">
          <div className="conversation-builder__main">
            <ConversationSteps
              conversationStepIds={visibleConversationStepIds}
              conversationId={conversationId}
              conversationType={conversation.type}
              editable={false}
              fetchVisibleConversationSteps={() =>
                this.props.fetchVisibleConversationSteps(
                  conversation.conversationId
                )
              }
            />
          </div>
          <SubscriberFlow
            conversationStepIds={visibleConversationStepIds}
            conversationId={conversationId}
            handleSelectTrigger={async trigger => {
              await this.props.setTriggerChoice({
                conversationStepId: trigger.sourceConversationStepId,
                triggerId: trigger.triggerId,
              });

              this.refreshSteps();
            }}
            handleDeselectTrigger={async trigger => {
              await this.props.setTriggerChoice({
                conversationStepId: trigger.sourceConversationStepId,
                triggerId: null,
              });

              this.refreshSteps();
            }}
          />
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { campaignId, conversationId, triggerId },
    },
  } = ownProps;

  const {
    conversations: {
      conversationsById,
      visibleConversationStepsByConversationId,
    },
    organizationFields: { organizationFields },
  } = state;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };
  const visibleConversationSteps = {
    isLoading: true,
    ...visibleConversationStepsByConversationId[conversationId],
  };
  return {
    campaignId,
    conversation,
    conversationId,
    organizationFields,
    triggerId,
    visibleConversationSteps,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchConversation,
    fetchCampaign,
    fetchGlobalTrigger,
    fetchVisibleConversationSteps,
    fetchOrganizationFields,
    setTriggerChoice,
  }
)(ConversationPreviewContainer);
