import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/conversations";

export const insertAboveStep = ({
  conversationId,
  stepIdToPaste,
  stepIdToInsertAbove,
  messageType,
}) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${stepIdToInsertAbove}/before`,
    method: "POST",
    body: { messageType, stepIdToPaste },
    types: [
      {
        type: constants.CREATE_CONVERSATION_STEP_REQUEST,
        meta: { conversationId: conversationId },
      },
      {
        type: constants.CREATE_CONVERSATION_STEP_SUCCESS,
        meta: { conversationId: conversationId },
      },
      {
        type: constants.CREATE_CONVERSATION_STEP_FAILURE,
        meta: { conversationId: conversationId },
      },
    ],
  },
});
