export const PUT_ORGANIZATION_FIELD_REQUEST = "PUT_ORGANIZATION_FIELD_REQUEST";
export const PUT_ORGANIZATION_FIELD_SUCCESS = "PUT_ORGANIZATION_FIELD_SUCCESS";
export const PUT_ORGANIZATION_FIELD_FAILURE = "PUT_ORGANIZATION_FIELD_FAILURE";

export const FETCH_ORGANIZATION_FIELDS_REQUEST =
  "FETCH_ORGANIZATION_FIELDS_REQUEST";
export const FETCH_ORGANIZATION_FIELDS_SUCCESS =
  "FETCH_ORGANIZATION_FIELDS_SUCCESS";
export const FETCH_ORGANIZATION_FIELDS_FAILURE =
  "FETCH_ORGANIZATION_FIELDS_FAILURE";

export const PUT_ORGANIZATION_FIELDS_REQUIRED_REQUEST =
  "PUT_ORGANIZATION_FIELDS_REQUIRED_REQUEST";
export const PUT_ORGANIZATION_FIELDS_REQUIRED_SUCCESS =
  "PUT_ORGANIZATION_FIELDS_REQUIRED_SUCCESS";
export const PUT_ORGANIZATION_FIELDS_REQUIRED_FAILURE =
  "PUT_ORGANIZATION_FIELDS_REQUIRED_FAILURE";

export const UPDATE_TEXT_MATCH_VALIDATION_REQUEST =
  "UPDATE_TEXT_MATCH_VALIDATION_REQUEST";
export const UPDATE_TEXT_MATCH_VALIDATION_SUCCESS =
  "UPDATE_TEXT_MATCH_VALIDATION_SUCCESS";
export const UPDATE_TEXT_MATCH_VALIDATION_FAILURE =
  "UPDATE_TEXT_MATCH_VALIDATION_FAILURE";

export const DELETE_ORGANIZATION_FIELD_REQUEST =
  "DELETE_ORGANIZATION_FIELD_REQUEST";
export const DELETE_ORGANIZATION_FIELD_SUCCESS =
  "DELETE_ORGANIZATION_FIELD_SUCCESS";
export const DELETE_ORGANIZATION_FIELD_FAILURE =
  "DELETE_ORGANIZATION_FIELD_FAILURE";

export const POST_MATCHING_TEXT_REQUEST = "POST_MATCHING_TEXT_REQUEST";
export const POST_MATCHING_TEXT_SUCCESS = "POST_MATCHING_TEXT_SUCCESS";
export const POST_MATCHING_TEXT_FAILURE = "POST_MATCHING_TEXT_FAILURE";
