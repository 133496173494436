import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/global-triggers";

const initialState = {
  globalTriggersById: {},
  isLoading: false,
  errorMessage: "",
};

export default createReducer(initialState, {
  [constants.FETCH_GLOBAL_TRIGGERS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_GLOBAL_TRIGGERS_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      globalTriggersById: action.payload.reduce(function(
        globalTriggersById,
        globalTrigger
      ) {
        return {
          ...globalTriggersById,
          [globalTrigger.triggerId]: globalTrigger,
        };
      },
      {}),
    };
  },

  [constants.FETCH_GLOBAL_TRIGGERS_FAILURE](state, action) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.response
        ? JSON.parse(action.payload.response).message
        : "",
    };
  },

  [constants.FETCH_GLOBAL_TRIGGER_REQUEST](state, action) {
    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.meta.globalTriggerId]: {
          ...(state.globalTriggersById[action.meta.globalTriggerId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.FETCH_GLOBAL_TRIGGER_SUCCESS](state, action) {
    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.payload.triggerId]: {
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.FETCH_GLOBAL_TRIGGER_FAILURE](state, action) {
    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.meta.globalTriggerId]: {
          ...(state.globalTriggersById[action.meta.globalTriggerId] || {}),
          isLoading: false,
          errorMessage: action.payload.response
            ? JSON.parse(action.payload.response).message
            : "",
        },
      },
    };
  },

  [constants.PUT_GLOBAL_TRIGGER_REQUEST](state, action) {
    if (!action.meta.globalTriggerId) {
      return {
        ...state,
        isLoading: true,
      };
    }

    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.meta.globalTriggerId]: {
          ...(state.globalTriggersById[action.meta.globalTriggerId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.PUT_GLOBAL_TRIGGER_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.payload.triggerId]: {
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.PUT_GLOBAL_TRIGGER_FAILURE](state, action) {
    if (!action.meta.globalTriggerId) {
      return {
        ...state,
        isLoading: false,
      };
    }

    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.meta.globalTriggerId]: {
          ...(state.globalTriggersById[action.meta.globalTriggerId] || {}),
          isLoading: true,
          errorMessage: action.payload.response
            ? JSON.parse(action.payload.response).message
            : "",
        },
      },
    };
  },

  [constants.DELETE_GLOBAL_TRIGGER_REQUEST](state, action) {
    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.meta.globalTriggerId]: {
          ...(state.globalTriggersById[action.meta.globalTriggerId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.DELETE_GLOBAL_TRIGGER_SUCCESS](state, action) {
    const globalTriggersById = { ...state.globalTriggersById };
    delete globalTriggersById[action.meta.globalTriggerId];
    return {
      ...state,
      isLoading: false,
      globalTriggersById: globalTriggersById,
    };
  },

  [constants.DELETE_GLOBAL_TRIGGER_FAILURE](state, action) {
    return {
      ...state,
      globalTriggersById: {
        ...state.globalTriggersById,
        [action.meta.globalTriggerId]: {
          ...(state.globalTriggersById[action.meta.globalTriggerId] || {}),
          isLoading: true,
          errorMessage: action.payload.response
            ? JSON.parse(action.payload.response).message
            : "",
        },
      },
    };
  },
});
