import createReducer from "../utilities/create-reducer";
import * as constants from "../constants/sequences";
import { byId } from "../utilities/reducers";
import values from "lodash/values";

const initialState = {
  byId: {},
  isLoading: false,
  sequences: [],
  recipientsById: {},
  settingsById: {},
};

export default createReducer(initialState, {
  [constants.CREATE_SEQUENCE_SUCCESS](state, action) {
    const { byId, sequences } = state;
    return {
      ...state,
      byId: {
        ...byId,
        [action.payload.sequenceId]: {
          ...action.payload,
        },
      },
      sequences: [...sequences, action.payload],
      isLoading: false,
    };
  },

  [constants.FETCH_SEQUENCES_SUCCESS](state, action) {
    return {
      ...state,
      byId: byId(action.payload, "sequenceId"),
      sequences: [...action.payload],
      isLoading: false,
    };
  },

  [constants.DELETE_SEQUENCE_SUCCESS](state, action) {
    const { byId } = state;
    delete byId[action.meta.sequenceId];

    return {
      ...state,
      byId,
      isLoading: false,
      sequences: values(byId),
    };
  },

  [constants.FETCH_SEQUENCE_SUCCESS](state, action) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.sequenceId]: action.payload,
      },
      sequences: [...state.sequences, action.payload],
      isLoading: false,
    };
  },

  [constants.EDIT_SEQUENCE_SUCCESS](state, action) {
    const byId = { ...state.byId, [action.payload.sequenceId]: action.payload };
    return {
      ...state,
      byId,
      sequences: values(byId),
      isLoading: false,
    };
  },

  [constants.FETCH_SEQUENCE_RECIPIENTS_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      recipientsById: {
        ...state.recipientsById,
        [action.meta.sequenceId]: { ...action.payload },
      },
    };
  },

  [constants.FETCH_SEQUENCE_SETTINGS_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      settingsById: {
        ...state.settingsById,
        [action.meta.sequenceId]: { ...action.payload },
      },
    };
  },
});
