import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/messenger-profile";

const initialState = {};

export default createReducer(initialState, {
  [constants.FETCH_MESSENGER_PROFILE_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [constants.UPDATE_MESSENGER_PROFILE_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [constants.DELETE_MESSENGER_PROFILE_SUCCESS](state, action) {
    return {
      ...initialState,
      ...action.payload,
    };
  },
});
