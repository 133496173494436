import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/user-configurations";

export const fetchUserConfiguration = userId => ({
  [CALL_API]: {
    endpoint: `/api/user_configurations/${userId}/`,
    method: "GET",
    types: [
      constants.FETCH_USER_CONFIG_REQUEST,
      constants.FETCH_USER_CONFIG_SUCCESS,
      constants.FETCH_USER_CONFIG_FAILURE,
    ],
  },
});

export const updateUserConfiguration = (userId, body) => {
  delete body.userId;
  return {
    [CALL_API]: {
      endpoint: `/api/user_configurations/${userId}/`,
      method: "POST",
      body,
      types: [
        constants.UPDATE_USER_CONFIG_REQUEST,
        constants.UPDATE_USER_CONFIG_SUCCESS,
        constants.UPDATE_USER_CONFIG_FAILURE,
      ],
    },
  };
};
