import React from "react";

import { Modal, InlineInputAndButton } from "../shared";

export default class AddPhoneNumberButtonModal extends React.Component {
  handleClose = e => {
    if (e) e.preventDefault();
    this.props.onClose();
  };

  handleAddPhoneNumber = e => {
    if (e) e.preventDefault();

    let phoneNumber = this.phoneNumberInput.value;
    phoneNumber = "+" + phoneNumber.replace(/[^\d]+/gi, "");

    this.props.addPhoneNumberButton(phoneNumber).then(this.handleClose);
  };

  render() {
    const { currentPhoneNumber, isOpen } = this.props;

    return (
      <Modal
        title="Enter a phone number"
        isOpen={isOpen}
        onClose={this.handleClose}
      >
        <div className="modal__controls">
          <form
            className="modal__form modal__form--wide"
            key={currentPhoneNumber}
          >
            <InlineInputAndButton
              inputProps={{
                name: "phone_number",
                defaultValue: currentPhoneNumber,
                placeholder: "e.g. +1 555-123-9876",
                ref: input => (this.phoneNumberInput = input),
              }}
              buttonProps={{
                className: "paloma-button paloma-button--pink modal__button",
                children: "Add",
                type: "submit",
                onClick: this.handleAddPhoneNumber,
              }}
            />
          </form>
        </div>
      </Modal>
    );
  }
}
