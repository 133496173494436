import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import WelcomeMessageForm from "./WelcomeMessageForm";

import { createWelcomeMessage } from "../../actions/welcome-messages";

import { Card } from "../../components/shared";

class NewWelcomeMessage extends React.Component {
  state = {
    name: "",
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name } = this.state;

    this.props
      .createWelcomeMessage({
        name,
      })
      .then(({ payload }) =>
        this.props.push(`/entrypoints/wm/${payload.triggerId}/`)
      );
  };

  render() {
    const { name } = this.state;

    return (
      <div>
        <h1 className="page-title">New Welcome Message</h1>
        <WelcomeMessageForm
          name={name}
          onChange={e => this.setState({ ...this.state, name: e.target.value })}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default connect(null, {
  createWelcomeMessage,
  push,
})(NewWelcomeMessage);
