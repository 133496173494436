import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Alert extends React.Component {
  static get propTypes() {
    return {
      alert: PropTypes.object,
      clearAlerts: PropTypes.func.isRequired,
    };
  }

  constructor(props) {
    super(props);

    let state = {};

    if (props.alert && props.alert.autoDismiss) {
      state.timeout = setTimeout(
        this.props.clearAlerts,
        this.props.alert.autoDismiss * 1000
      );
    }

    this.state = state;
  }

  componentWillUnmount() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  render() {
    const { alert, clearAlerts } = this.props;

    const alertClassName = classNames({
      alert: true,
      "alert--success": alert && alert.level === "success",
      "alert--error": alert && alert.level === "error",
    });

    return (
      <div className={alertClassName}>
        <div className="alert__message">{alert && alert.message}</div>
        {alert &&
          alert.dismissible && (
            <span className="alert__dismiss" onClick={clearAlerts}>
              &times;
            </span>
          )}
      </div>
    );
  }
}

export default Alert;
