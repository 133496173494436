import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/subscriber-owner";

export const updateSubscriberOwner = payload => ({
  [CALL_API]: {
    endpoint: "/api/subscriber_owners/",
    method: payload.ownerId ? "POST" : "DELETE",
    body: JSON.stringify(payload),
    types: [
      constants.UPDATE_SUBSCRIBER_OWNER_REQUEST,
      constants.UPDATE_SUBSCRIBER_OWNER_SUCCESS,
      constants.UPDATE_SUBSCRIBER_OWNER_FAILURE,
    ],
  },
});
