import React from "react";
import Tooltip from "rc-tooltip";

import ConversationStep from "../containers/ConversationDetail/ConversationStep";

class EventSummary extends React.Component {
  render() {
    const { steps } = this.props;

    return (
      <div>
        <table className="table event-summary">
          <thead>
            <tr>
              <th />
              {steps.map(s => (
                <th key={s.label + s.stepId}>
                  <Tooltip
                    overlayClassName="step-mini-preview"
                    placement="top"
                    trigger={["hover"]}
                    overlay={
                      <ConversationStep
                        conversationStepId={s.stepId}
                        editable={false}
                        displayStepId={false}
                        displayDetours={false}
                      />
                    }
                  >
                    <span>{s.label}</span>
                  </Tooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Subscribers</td>
              {steps.map(s => (
                <td key={s.label + s.stepId}>{s.unique}</td>
              ))}
            </tr>
            <tr>
              <td>Completion Rate</td>
              {steps.map(s => (
                <td key={s.label + s.stepId}>{s.completionRate}%</td>
              ))}
            </tr>
            <tr>
              <td>Dropped Off</td>
              {steps.map(s => (
                <td key={s.label + s.stepId}>{s.droppedOff}</td>
              ))}
            </tr>
            <tr>
              <td>Drop Off Rate</td>
              {steps.map(s => (
                <td key={s.label + s.stepId}>{s.dropOffRate}%</td>
              ))}
            </tr>
            <tr>
              <td>Total Events</td>
              {steps.map(s => (
                <td key={s.label + s.stepId}>{s.total}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

EventSummary.defaultProps = {
  steps: [],
};

export default EventSummary;
