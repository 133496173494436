import React from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { Link, Route } from "react-router-dom";

const Breadcrumb = ({ state, title, match }) => {
  if (match.isExact) {
    return null;
  }

  const _title = title instanceof Function ? title(state, match) : title;

  return (
    <li className="breadcrumbs__breadcrumb">
      <Link to={match.url}>&#8592; {_title}</Link>
    </li>
  );
};

const mapStateToProps = state => ({ state: state });

const ConnectedBreadcrumb = connect(
  mapStateToProps,
  {}
)(Breadcrumb);

const Breadcrumbs = ({ routes, currentPathname }) => {
  routes = routes.filter(
    route =>
      !route.excludeRouteFromBreadcrumbs &&
      !matchPath(currentPathname, { path: route.path, exact: true })
  );

  if (
    !routes.length ||
    !routes.some(route => matchPath(currentPathname, { path: route.path }))
  ) {
    return null;
  }

  return (
    <ol className="breadcrumbs">
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          render={({ match }) => (
            <ConnectedBreadcrumb title={route.breadcrumbTitle} match={match} />
          )}
        />
      ))}
    </ol>
  );
};

export default Breadcrumbs;
