export const CREATE_SEQUENCE_SUCCESS = "CREATE_SEQUENCE_SUCCESS";
export const CREATE_SEQUENCE_REQUEST = "CREATE_SEQUENCE_REQUEST";
export const CREATE_SEQUENCE_FAILURE = "CREATE_SEQUENCE_FAILURE";

export const FETCH_SEQUENCES_SUCCESS = "FETCH_SEQUENCES_SUCCESS";
export const FETCH_SEQUENCES_REQUEST = "FETCH_SEQUENCES_REQUEST";
export const FETCH_SEQUENCES_FAILURE = "FETCH_SEQUENCES_FAILURE";

export const DELETE_SEQUENCE_REQUEST = "DELETE_SEQUENCE_REQUEST";
export const DELETE_SEQUENCE_SUCCESS = "DELETE_SEQUENCE_SUCCESS";
export const DELETE_SEQUENCE_FAILURE = "DELETE_SEQUENCE_FAILURE";

export const DUPLICATE_SEQUENCE_REQUEST = "DUPLICATE_SEQUENCE_REQUEST";
export const DUPLICATE_SEQUENCE_SUCCESS = "DUPLICATE_SEQUENCE_SUCCESS";
export const DUPLICATE_SEQUENCE_FAILURE = "DUPLICATE_SEQUENCE_FAILURE";

export const FETCH_SEQUENCE_SUCCESS = "FETCH_SEQUENCE_SUCCESS";
export const FETCH_SEQUENCE_REQUEST = "FETCH_SEQUENCE_REQUEST";
export const FETCH_SEQUENCE_FAILURE = "FETCH_SEQUENCE_FAILURE";

export const EDIT_SEQUENCE_SUCCESS = "EDIT_SEQUENCE_SUCCESS";
export const EDIT_SEQUENCE_REQUEST = "EDIT_SEQUENCE_REQUEST";
export const EDIT_SEQUENCE_FAILURE = "EDIT_SEQUENCE_FAILURE";

export const FETCH_SEQUENCE_RECIPIENTS_SUCCESS =
  "FETCH_SEQUENCE_RECIPIENTS_SUCCESS";
export const FETCH_SEQUENCE_RECIPIENTS_REQUEST =
  "FETCH_SEQUENCE_RECIPIENTS_REQUEST";
export const FETCH_SEQUENCE_RECIPIENTS_FAILURE =
  "FETCH_SEQUENCE_RECIPIENTS_FAILURE";

export const FETCH_SEQUENCE_SETTINGS_SUCCESS =
  "FETCH_SEQUENCE_SETTINGS_SUCCESS";
export const FETCH_SEQUENCE_SETTINGS_REQUEST =
  "FETCH_SEQUENCE_SETTINGS_REQUEST";
export const FETCH_SEQUENCE_SETTINGS_FAILURE =
  "FETCH_SEQUENCE_SETTINGS_FAILURE";

export const UPDATE_SEQUENCE_SETTINGS_SUCCESS =
  "UPDATE_SEQUENCE_SETTINGS_SUCCESS";
export const UPDATE_SEQUENCE_SETTINGS_REQUEST =
  "UPDATE_SEQUENCE_SETTINGS_REQUEST";
export const UPDATE_SEQUENCE_SETTINGS_FAILURE =
  "UPDATE_SEQUENCE_SETTINGS_FAILURE";

export const PUBLISH_SEQUENCE_SUCCESS = "PUBLISH_SEQUENCE_SUCCESS";
export const PUBLISH_SEQUENCE_REQUEST = "PUBLISH_SEQUENCE_REQUEST";
export const PUBLISH_SEQUENCE_FAILURE = "PUBLISH_SEQUENCE_FAILURE";
