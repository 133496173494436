import React from "react";
import { connect } from "react-redux";
import Tooltip from "rc-tooltip";

import PaginatedList from "../components/PaginatedList";
import { InfoPanel } from "../components/shared";
import {
  getTaskFailures,
  rerunTask,
  dismissTask,
} from "../actions/task-failures";
import { withTime } from "../utilities/format-date";

class TaskFailures extends React.Component {
  componentWillMount() {
    this.props.getTaskFailures();
  }

  render() {
    return (
      <div>
        <InfoPanel>
          <h3>Failures</h3>
          {Object.entries(this.props.taskFailures.byOrganizationId).map(
            entry => (
              <div key={entry[0]}>
                <hr />
                <PaginatedList
                  headers={[entry[0], "Job", "Webhook", "Subscriber"]}
                  items={entry[1]}
                  className="failures"
                  component={this.taskFailure}
                />
              </div>
            )
          )}
        </InfoPanel>
      </div>
    );
  }

  taskFailure = taskFailure => (
    <tr key={taskFailure.id}>
      <td>{withTime(taskFailure.createdAt)}</td>
      <td>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={<span>{taskFailure.exception}</span>}
        >
          <span>{removeTaskNamePrefix(taskFailure.name)}</span>
        </Tooltip>
      </td>
      <td>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <pre>
              {taskFailure.webhook &&
                JSON.stringify(
                  taskFailure.webhook && taskFailure.webhook.data,
                  null,
                  4
                )}
            </pre>
          }
        >
          <span>{taskFailure.webhook && taskFailure.webhook.id}</span>
        </Tooltip>
      </td>
      <td>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <span>
              {taskFailure.subscriber && taskFailure.subscriber.fullName}
            </span>
          }
        >
          <span>
            {taskFailure.subscriber && taskFailure.subscriber.subscriberId}
          </span>
        </Tooltip>
      </td>
      <td>
        <button
          className="paloma-button--pink"
          onClick={async () => {
            await this.props.rerunTask(taskFailure.id);
            this.props.getTaskFailures();
          }}
        >
          Rerun
        </button>
      </td>
      <td>
        <button
          className="paloma-button--pink"
          onClick={async () => {
            await this.props.dismissTask(taskFailure.id);
            this.props.getTaskFailures();
          }}
        >
          Dismiss
        </button>
      </td>
    </tr>
  );
}

const removeTaskNamePrefix = name => {
  const sections = name.split(".");
  return sections[sections.length - 1];
};

const mapStateToProps = state => ({
  taskFailures: state.taskFailures,
});

export default connect(
  mapStateToProps,
  { getTaskFailures, dismissTask, rerunTask }
)(TaskFailures);
