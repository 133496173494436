import humps from "humps";
import { CALL_API } from "redux-api-middleware";

export default store => next => action => {
  const callApi = action[CALL_API];

  // Check if this action is a redux-api-middleware action.
  if (callApi) {
    if (
      callApi.body &&
      callApi.headers["Content-Type"] === "application/json"
    ) {
      let payload = callApi.body;
      if (typeof callApi.body === "string") {
        payload = JSON.parse(payload);
      }
      payload = JSON.stringify(humps.decamelizeKeys(payload));
      callApi.body = payload;
    }
  } else if (action.io && action.payload) {
    action.payload = humps.decamelizeKeys(action.payload);
  }

  return next(action);
};
