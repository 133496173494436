import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  downloadAudienceCsv,
  fetchAllSubscribersDownloadToken,
  fetchSubscribers,
} from "../../actions/subscribers";
import { fetchSubscriberLists } from "../../actions/subscriber-lists";
import { fetchMessagesForSubscriber, fetchSubscriber } from "../../actions/subscribers";
import { fetchDashboardStats } from "../../actions/dashboard";
import { SubscriberListTable } from "../../components/Audience";

import {
  ContentHeader,
  Card,
  TabSelector,
  DownloadButton,
} from "../../components/shared";
import SubscriberTable from "../../components/Audience/SortableSubscriberTable";
import SubscriberMessageHistory from "../SubscriberMessageHistory";

class Audience extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "DESC",
      page: 1,
      showSubscribers: true,
      sortBy: "Received",
      subscriberListSearchQuery: "",
      selectedSubscriberId: null,
      unreadSubscriberIds: []
    };
  }

  componentDidMount() {
    this.fetchData()

    if(!this.props.unsubscribersCount) {
      this.props.fetchDashboardStats()
    }
  }

  async fetchData() {
    const { page, order, sortBy } = this.state;
    await this.props.fetchSubscribers({ order, page, sortBy });
    if (!this.state.selectedSubscriberId && this.props.subscribers.length) {
      this.setState({
        selectedSubscriberId: this.props.subscribers[0].subscriberId,
      });
    }
    this.props.fetchSubscriberLists();
  }

  handleMessageFromSubscriber = (incomingSubscriberId) => {
    // If incoming socket message was not active chat window, mark mark unread (so row will show css highlighting)
    this.setState({ unreadSubscriberIds: [...this.state.unreadSubscriberIds, incomingSubscriberId] })
  }

  tabSelector() {
    return (
      <TabSelector
        choices={[
          {
            label: "Subscribers",
            selected: this.state.showSubscribers,
            onSelect: () => this.handleSelectTab("subscribers"),
          },
          {
            label: "Lists",
            selected: this.state.showSubscriberLists,
            onSelect: () => this.handleSelectTab("subscriberLists"),
          },
        ]}
      />
    );
  }

  setConversationDetail = id => {
    // If subscriber was unread, mark as read (remove)
    var array = [...this.state.unreadSubscriberIds];
    var index = array.indexOf(id)
    if (index !== -1) {
      array.splice(index, 1);
    }

    this.setState({
      selectedSubscriberId: id, 
      unreadSubscriberIds: array
    });
  };

  render() {
    const {
      page,
      subscribersLoading,
      subscriberListsLoading,
      subscriberLists,
      subscribers,
      subscribersTotal,
      totalSubscriberPages,
      order,
      search,
      sortBy,
    } = this.props;

    const { showSubscribers, showSubscriberLists } = this.state;
    const fetchOptions = { order, page, search, sortBy };

    // We want the first entry in lists table should be a "special" list automatically made available for each org that 
    // contains all unsubscribed subscribers. Really its just a dummy row that links to a special page, not a SubscriberList.
    var displayLists = [...subscriberLists]
    if(!this.state.subscriberListSearchQuery) {
      displayLists.unshift({
        name: "Unsubscribers",
        subscriberListId: "unsubscribers",
        numSubscribers: (this.props.unsubscribersCount || "")
      })
    }

    return (
      <div className="audience">
        <ContentHeader left={<h1 className="page-title">Audience</h1>} />
        <ContentHeader
          left={this.tabSelector()}
          right={
            showSubscribers ? (
              <div>
                <DownloadButton
                  className="paloma-button paloma-button"
                  download={this.props.downloadAudienceCsv}
                />
              </div>
            ) : (
              <Link
                className="paloma-button paloma-button--pink audience__new-list"
                to="/audience/lists/new"
              >
                + New List
              </Link>
            )
          }
          style={{ margin: "20px 13px 0px 0px" }}
        />

        {showSubscribers && (
          <div className="split">
            <Card className="list" loading={subscribersLoading}>
              <SubscriberTable
                {...fetchOptions}
                totalPages={totalSubscriberPages}
                onRowClick={subscriberId => {
                  this.setConversationDetail(subscriberId);
                }}
                subscribers={subscribers}
                fetch={options =>
                  this.props.fetchSubscribers({ ...fetchOptions, ...options })
                }
                total={subscribersTotal}
                unreadSubscriberIds={this.state.unreadSubscriberIds}
              />
            </Card>
            <Card className="profile">
              <SubscriberMessageHistory
                subscriberId={this.state.selectedSubscriberId}
                handleMessageFromSubscriber={this.handleMessageFromSubscriber}
              />
            </Card>
          </div>
        )}
        {!!showSubscriberLists && (
          <SubscriberListTable
            handleSubscriberListSearch={this.handleSubscriberListSearch}
            subscriberListSearchQuery={this.state.subscriberListSearchQuery}
            subscriberLists={displayLists}
            loading={subscriberListsLoading}
          />
        )}
      </div>
    );
  }

  handleSelectTab = toShow => {
    if (toShow === "subscribers") {
      this.setState({
        showSubscribers: true,
        showSubscriberLists: false,
      });
    } else if (toShow === "subscriberLists") {
      this.setState({
        showSubscribers: false,
        showSubscriberLists: true,
      });
    }
  };

  handleSubscriberListSearch = query => {
    this.setState({ subscriberListSearchQuery: query });
    this.props.fetchSubscriberLists(query);
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    subscribers: {
      isLoading: subscribersLoading,
      subscribersById,
      pagination: {
        currentPage: page,
        totalPages: totalSubscriberPages,
        totalItems: subscribersTotal,
        pages: subscriberPages,
      },
      meta: { order = "DESC", sortBy, search },
    },
    subscriberLists: {
      byId: subscriberListsById,
      isLoading: subscriberListsLoading,
    },
  } = state;

  return {
    unsubscribersCount: state.dashboard.totalUnsubscribers,
    page,
    subscribersLoading,
    subscriberListsLoading,
    subscriberLists: Object.values(subscriberListsById),
    subscribers: subscriberPages[page].map(
      subscriberId => subscribersById[subscriberId]
    ),
    subscribersTotal,
    totalSubscriberPages,
    order,
    sortBy,
    search,
  };
};

export default connect(mapStateToProps, {
  downloadAudienceCsv,
  fetchAllSubscribersDownloadToken,
  fetchSubscriberLists,
  fetchSubscribers,
  fetchMessagesForSubscriber,
  fetchSubscriber,
  fetchDashboardStats,
})(Audience);
