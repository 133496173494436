import React from "react";
import classNames from "classnames";
import remove from "lodash/remove";

import { Modal } from "./shared";

class MatchingResponsesModal extends React.Component {
  state = {
    matchingResponses: [],
    matchingResponse: "",
    selected: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.matchingResponses.length && props.matchingResponses.length) {
      return { ...state, matchingResponses: props.matchingResponses };
    }
    return { ...state };
  }

  addMatchingResponse = e => {
    e.preventDefault();
    const { matchingResponse, matchingResponses } = this.state;

    if (matchingResponse && !matchingResponses.includes(matchingResponse)) {
      this.setState(
        {
          matchingResponse: "",
          matchingResponses: matchingResponses.concat(
            matchingResponse.split(",").map(text => text.trim())
          ),
        },
        () => this.props.onSubmit(this.state.matchingResponses)
      );
    }
  };

  removeMatchingResponse = async text => {
    const { matchingResponses } = this.state;
    remove(matchingResponses, value => value === text);

    await this.props.onSubmit(matchingResponses);
    this.setState({ matchingResponses });
  };

  matchingResponseClassNames = text => {
    return {
      "paloma-button": true,
      selected: this.state.selected === text,
    };
  };

  renderMatchingResponse = text => {
    return (
      <div
        key={text}
        className={classNames(this.matchingResponseClassNames(text))}
        onClick={() => this.setState({ selected: text })}
      >
        {text}
        {this.state.selected === text ? (
          <span
            style={{ float: "right", color: "rgb(186, 42, 107)" }}
            onClick={() => this.removeMatchingResponse(text)}
          >
            Remove
          </span>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div className="insert-field-modal">
        <Modal
          title="Matching Responses"
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
        >
          <div className="modal__top-input">
            <div className="input-with-inline-button">
              <form onSubmit={this.addMatchingResponse}>
                <input
                  className="modal__input--inline"
                  type="text"
                  name="entry"
                  value={this.state.matchingResponse}
                  onChange={({ target: { value: matchingResponse } }) =>
                    this.setState({ matchingResponse })
                  }
                />
                <button
                  type="submit"
                  onClick={this.addMatchingResponse}
                  className="paloma-button paloma-button--pink modal__button"
                >
                  Add
                </button>
              </form>
            </div>
          </div>
          <div className="modal__fields">
            {this.state.matchingResponses.map(this.renderMatchingResponse)}
          </div>
          <div className="modal__bottom">
            <button
              style={{ float: "left", width: "96%" }}
              type="button"
              onClick={this.props.onClose}
              className="paloma-button paloma-button--white modal__button"
            >
              Back to Builder
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MatchingResponsesModal;
