import createReducer from "../utilities/create-reducer";

import { SHOW_ALERT, CLEAR_ALERTS } from "../constants/alerts";

const initialState = {
  currentAlert: null,
};

export default createReducer(initialState, {
  [SHOW_ALERT](state, action) {
    return {
      ...state,
      currentAlert: action.payload,
    };
  },

  [CLEAR_ALERTS](state, action) {
    return {
      ...state,
      currentAlert: null,
    };
  },
});
