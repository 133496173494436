import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/quick-replies";

export const addTriggerToQuickReply = (quickReplyId) => ({
  [CALL_API]: {
    endpoint: `/api/quick_replies/${quickReplyId}/trigger`,
    method: "POST",
    types: [
      constants.ADD_TRIGGER_TO_QUICK_REPLY_REQUEST,
      constants.ADD_TRIGGER_TO_QUICK_REPLY_SUCCESS,
      constants.ADD_TRIGGER_TO_QUICK_REPLY_FAILURE,
    ],
  },
});

export const addQuickReply = (stepId) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${stepId}/quick_replies`,
    method: "POST",
    types: [
      constants.ADD_QUICK_REPLY_REQUEST,
      constants.ADD_QUICK_REPLY_SUCCESS,
      constants.ADD_QUICK_REPLY_FAILURE,
    ],
  },
});

export const moveQuickReply = (id, payload) => ({
  [CALL_API]: {
    endpoint: `/api/quick_reply/${id}/move`,
    method: "PUT",
    body: JSON.stringify(payload),
    types: [
      "MOVE_QUICK_REPLY_REQUEST",
      "MOVE_QUICK_REPLY_SUCCESS",
      "MOVE_QUICK_REPLY_FAILURE",
    ],
  },
});

export const fetchQuickReplies = (stepId) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${stepId}/quick_replies`,
    method: "GET",
    types: [
      constants.FETCH_QUICK_REPLIES_REQUEST,
      constants.FETCH_QUICK_REPLIES_SUCCESS,
      constants.FETCH_QUICK_REPLIES_FAILURE,
    ],
  },
});

export const fetchQuickReply = (id) => ({
  [CALL_API]: {
    endpoint: `/api/quick_reply/${id}`,
    method: "GET",
    types: [
      constants.FETCH_QUICK_REPLY_REQUEST,
      constants.FETCH_QUICK_REPLY_SUCCESS,
      constants.FETCH_QUICK_REPLY_FAILURE,
    ],
  },
});

export const updateQuickReply = (quickReply) => {
  return {
    [CALL_API]: {
      endpoint: `/api/quick_reply/${quickReply.id}`,
      method: "PUT",
      body: quickReply,
      types: [
        constants.UPDATE_QUICK_REPLY_REQUEST,
        constants.UPDATE_QUICK_REPLY_SUCCESS,
        constants.UPDATE_QUICK_REPLY_FAILURE,
      ],
    },
  };
};

export const updateQuickReplyToText = (id) => {
  return {
    [CALL_API]: {
      endpoint: `/api/quick_reply/${id}/text`,
      method: "PUT",
      types: [
        constants.UPDATE_QUICK_REPLY_TEXT_REQUEST,
        constants.UPDATE_QUICK_REPLY_SUCCESS,
        constants.UPDATE_QUICK_REPLY_TEXT_FAILURE,
      ],
    },
  };
};

export const updateQuickReplyToEmail = (id) => {
  return {
    [CALL_API]: {
      endpoint: `/api/quick_reply/${id}/email`,
      method: "PUT",
      types: [
        constants.UPDATE_QUICK_REPLY_EMAIL_REQUEST,
        constants.UPDATE_QUICK_REPLY_SUCCESS,
        constants.UPDATE_QUICK_REPLY_EMAIL_FAILURE,
      ],
    },
  };
};

export const updateQuickReplyToPhoneNumber = (id) => {
  return {
    [CALL_API]: {
      endpoint: `/api/quick_reply/${id}/phone_number`,
      method: "PUT",
      types: [
        constants.UPDATE_QUICK_REPLY_PHONE_NUMBER_REQUEST,
        constants.UPDATE_QUICK_REPLY_SUCCESS,
        constants.UPDATE_QUICK_REPLY_PHONE_NUMBER_FAILURE,
      ],
    },
  };
};

export const deleteQuickReply = (id) => {
  return {
    [CALL_API]: {
      endpoint: `/api/quick_reply/${id}`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_QUICK_REPLY_REQUEST,
          meta: { id },
        },
        {
          type: constants.DELETE_QUICK_REPLY_SUCCESS,
          meta: { id },
        },
        {
          type: constants.DELETE_QUICK_REPLY_FAILURE,
          meta: { id },
        },
      ],
    },
  };
};

export const addCustomPayloadToQuickReply = (id, customPayload) => {
  return {
    [CALL_API]: {
      endpoint: `/api/quick_replies/${id}/custom_payload`,
      method: "POST",
      body: { customPayload },
      types: [
        constants.ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_REQUEST,
        constants.ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_SUCCESS,
        constants.ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_FAILURE,
      ],
    },
  };
};

export const toggleRandomize = (stepId, randomized = true) => {
  return {
    [CALL_API]: {
      endpoint: `/api/steps/${stepId}/quick_replies/random`,
      method: randomized ? "POST" : "DELETE",
      types: [
        constants.TOGGLE_RANDOMIZE_QUICK_REPLIES_REQUEST,
        constants.TOGGLE_RANDOMIZE_QUICK_REPLIES_SUCCESS,
        constants.TOGGLE_RANDOMIZE_QUICK_REPLIES_FAILURE,
      ],
    },
  };
};
