import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/global-default-trigger";

export const fetchGlobalDefaultTrigger = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/",
    method: "GET",
    types: [
      constants.FETCH_GLOBAL_DEFAULT_TRIGGER_REQUEST,
      constants.FETCH_GLOBAL_DEFAULT_TRIGGER_SUCCESS,
      constants.FETCH_GLOBAL_DEFAULT_TRIGGER_FAILURE,
    ],
  },
});

export const createManualMessagingOnGlobalDefaultTrigger = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/manual_messaging_activation",
    method: "POST",
    types: [
      constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_REQUEST,
      constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS,
      constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_FAILURE,
    ],
  },
});

export const deleteManualMessagingOnGlobalDefaultTrigger = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/manual_messaging_activation",
    method: "DELETE",
    types: [
      constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_REQUEST,
      constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS,
      constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_FAILURE,
    ],
  },
});

export const createThreadControlPassOnGlobalDefaultTrigger = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/thread_control_pass",
    method: "POST",
    types: [
      constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_REQUEST,
      constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS,
      constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});

export const deleteThreadControlPassOnGlobalDefaultTrigger = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/thread_control_pass",
    method: "DELETE",
    types: [
      constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_REQUEST,
      constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS,
      constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});

export const createAssignSubscriberOwnerGlobalDefaultTrigger = owner_id => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/assign_subscriber_owner",
    method: "POST",
    body: { owner_id },
    types: [
      constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_REQUEST,
      constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_SUCCESS,
      constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_FAILURE,
    ],
  },
});

export const deleteAssignSubscriberOwnerGlobalDefaultTrigger = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_trigger/assign_subscriber_owner",
    method: "DELETE",
    types: [
      constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_REQUEST,
      constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_SUCCESS,
      constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_FAILURE,
    ],
  },
});
