import React from "react";
import { connect } from "react-redux";
import { MessageHistory, ManualMessage } from "../components/SubscriberDetail";
import { UserIcon } from "../components/shared";
import {
  putSubscriber,
  sendManualMessageToSubscriber,
  fetchMessagesForSubscriber,
  fetchSubscriber,
  storeMessageForSubscriber,
} from "../actions/subscribers";
import {
  createSubscriberThreadControl,
  deleteSubscriberThreadControl,
} from "../actions/subscriber-thread-control";
import { fetchSubscriberPhoto } from "../actions/subscriber-photos";
import { fetchCurrentOrganization } from "../actions/current-organization";
import { subscibeToEvent, unsubscibeFromEvent } from '../utilities/socket';

class SubscriberMessageHistory extends React.Component {
  handleToggleManualMessaging = manualMessagingEnabled => {
    const { putSubscriber, subscriber } = this.props;

    putSubscriber({
      ...subscriber,
      manualMessagingEnabled,
    });
  };

  handleSendMessage = message => {
    const { sendManualMessageToSubscriber, subscriberId } = this.props;

    sendManualMessageToSubscriber(subscriberId, message);
  };

  componentDidMount() {
    this.fetchData(this.props.subscriberId)
    this.subscibeToMessages()
  }

  componentWillUnmount() {
    unsubscibeFromEvent('message')
  }

  componentDidUpdate(prevProps) {
    const { subscriberId } = this.props
    if(prevProps.subscriberId != subscriberId && subscriberId) {
      this.fetchData(subscriberId)
    }
  }

  async fetchData(subscriberId) {
    if(subscriberId) {
      await this.props.fetchSubscriberPhoto(subscriberId);
      await this.props.fetchSubscriber(subscriberId);
      this.props.fetchMessagesForSubscriber(subscriberId, 1);
    }
  }

  subscibeToMessages = async () => {
    await this.props.fetchCurrentOrganization()
    subscibeToEvent('message', this.props.currentOrganization.organizationId, (err, json_encoded_message) => {
      const message = JSON.parse(json_encoded_message)
      this.props.storeMessageForSubscriber(message)

      var incomingSubscriberId = message.subscriber.subscriber_id
      if(incomingSubscriberId != this.props.subscriberId) {
        // If incoming message was not active chat window, pass to higher order component to handle if callback exists
        if(this.props.handleMessageFromSubscriber) {
          this.props.handleMessageFromSubscriber(incomingSubscriberId)
        }
      }
    });
  }

  render() {
    return (
      <div className="profile-wrapper">
        {!this.props.hideProfile &&
          <div className="profile-header">
            <div className="avatar-and-name">
              <UserIcon
                size={32}
                pictureUrl={this.props.subscriberPhoto}
                status={this.props.subscriber.status}
                manualMessaging={this.props.subscriber.manualMessagingEnabled}
              />
              {this.props.subscriber.fullName}
            </div>
            <a
              className="table__link"
              href={`/audience/subscribers/${this.props.subscriber.subscriberId}`}
            >
              Profile
            </a>
          </div>
        }
        {this.props.subscriberId && (
          <MessageHistory
            subscriberId={this.props.subscriberId}
            key={this.props.subscriberId}
            messages={this.props.messages}
            fetchMessagesForSubscriber={this.props.fetchMessagesForSubscriber}
          />
        )}
        <ManualMessage
          subscriberId={this.props.subscriberId}
          manualMessagingDisabled={
            !this.props.subscriber.manualMessagingEnabled
          }
          onToggleManualMessaging={this.handleToggleManualMessaging}
          onSendMessage={this.handleSendMessage}
          standby={this.props.subscriber.standby}
          unsubscribed={!this.props.subscriber.subscribed}
          onToggleStandby={() => {
            this.handleToggleStandby();
          }}
          status={this.props.subscriber.status}
        />
      </div>
    );
  }

  handleToggleStandby = () => {
    const {
      createSubscriberThreadControl,
      deleteSubscriberThreadControl,
      subscriber,
    } = this.props;

    if (subscriber.standby) {
      createSubscriberThreadControl(subscriber.subscriberId);
    } else {
      deleteSubscriberThreadControl(subscriber.subscriberId);
    }
  };
}

const dateSort = (message1, message2) => {
  let time1 = new Date(message1.sentAt).getTime();
  let time2 = new Date(message2.sentAt).getTime();

  return time1 - time2;
};

const mapStateToProps = (state, ownProps) => {
  const {
    subscribers: { subscribersById },
  } = state;

  const { subscriberId } = ownProps;

  const { messagesById } = subscribersById[subscriberId] || {
    messagesById: {},
  };
  
  return {
    subscriber: state.subscribers.subscribersById[subscriberId] || {},
    subscriberPhoto: state.subscriberPhotos[subscriberId],
    messages: Object.values(messagesById).sort(dateSort),
    currentOrganization: state.currentOrganization
  };
};

export default connect(mapStateToProps, {
  createSubscriberThreadControl,
  deleteSubscriberThreadControl,
  putSubscriber,
  sendManualMessageToSubscriber,
  fetchMessagesForSubscriber,
  fetchSubscriber,
  fetchSubscriberPhoto,
  fetchCurrentOrganization,
  storeMessageForSubscriber,
})(SubscriberMessageHistory);
