import React from "react";
import Select from "react-select";
import { connect } from "react-redux";

import { Modal } from "../components/shared";
import { fetchSubscriberLists } from "../actions/subscriber-lists";
import { testSend } from "../actions/test-sends";
import { ContentHeader } from "../components/shared";

class TestSendConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.fetchSubscriberLists();
  }

  render = () => {
    return (
      <div className="test-send">
        <ContentHeader
          left={<h3>Test Recipients</h3>}
          right={this.targetingText()}
        />
        <div className="controls">
          <h4 className="card__section-title">Choose a List</h4>
          <Select.Creatable
            options={this.props.lists.map(optionFromList)}
            value={this.state.list}
            onChange={selected => this.setList(selected)}
            className="conversation-publisher__subscriber-list-select"
          />
          <button
            className="paloma-button modal__button modal__button--pink"
            disabled={!this.state.list}
            onClick={() => {
              this.setState({ modal: true });
            }}
          >
            Send Test
          </button>
        </div>
        <Modal
          title="Send Test Conversation?"
          isOpen={this.state.modal}
          onClose={() => this.setState({ modal: false })}
        >
          <div className="modal__controls">
            <button
              className="paloma-button modal__button modal__button"
              onClick={() => this.setState({ modal: false })}
            >
              No
            </button>
            <button
              className="paloma-button modal__button modal__button"
              onClick={this.sendTestConversation}
            >
              Yes
            </button>
          </div>
        </Modal>
      </div>
    );
  };

  setList = list => {
    if (list) {
      this.setState({ list: list.value });
    } else {
      this.setState({ list: null });
    }
  };

  sendTestConversation = () => {
    this.props.testSend(this.props.id, this.state.list);
    this.setState({ modal: false });
  };

  list = () => {
    if (this.state.list) {
      return this.props.lists.find(l => l.subscriberListId === this.state.list);
    }
  };

  targetingText = () => {
    if (this.list()) {
      return (
        <div className="conversation-publisher__num-targeted">
          Targeting {this.list().numSubscribers} Subscribers
        </div>
      );
    } else {
      return <div />;
    }
  };
}

const optionFromList = list => ({
  label: list.name,
  value: list.subscriberListId,
});

const mapStateToProps = (state, ownProps) => {
  return {
    conversation: state.conversations.conversationsById[ownProps.id] || {},
    lists: Object.values(state.subscriberLists.byId) || [],
  };
};

export default connect(
  mapStateToProps,
  {
    fetchSubscriberLists,
    testSend,
  }
)(TestSendConversation);
