import React from "react";
import { RadioGroup, Radio } from "react-radio-group";
import { connect } from "react-redux";
import { fetchOrganizationFields } from "../../actions/organization-fields";

import { Modal, Toggle } from "../shared";
import TagSelect from "../shared/TagSelect";

class AddURLButtonModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: props.url || "",
      webviewHeightRatio: props.webviewHeightRatio || "full",
      canPassData:
        props.dataFieldIds && props.dataFieldIds.length ? true : false,
      dataFieldIds: props.dataFieldIds || [],
      ...props,
    };
  }

  componentDidMount() {
    if (!this.props.fields.length) {
      this.props.fetchOrganizationFields();
    }
  }

  handleClose = e => {
    if (e) e.preventDefault();
    this.props.onClose();
  };

  handleSubmit = e => {
    if (e) e.preventDefault();
    const { url, webviewHeightRatio, dataFieldIds, shopify } = this.state;
    this.props
      .addUrlButton(
        url,
        webviewHeightRatio,
        dataFieldIds.map(({ value }) => value),
        shopify
      )
      .then(this.handleClose);
  };

  handleUrlChange = e => {
    this.setState({ url: e.target.value });
  };

  selectWebviewHeightRatio = e => {
    this.setState({ webviewHeightRatio: e });
  };

  render() {
    const {
      url,
      webviewHeightRatio,
      canPassData,
      dataFieldIds,
      shopify,
    } = this.state;
    const { isOpen } = this.props;

    return (
      <Modal title="Setup a URL" isOpen={isOpen} onClose={this.handleClose}>
        <div className="modal__controls add-url-buttom-modal">
          <form
            className="modal__form modal__form--wide"
            onSubmit={this.handleSubmit}
          >
            <label className="default-form__label" htmlFor="url">
              URL
            </label>
            <input
              className="default-form__input"
              type="text"
              name="url"
              id="url"
              defaultValue={url}
              onChange={this.handleUrlChange.bind(this)}
            />
            <label className="default-form__label" htmlFor="webviewHeightRatio">
              Screen Size
            </label>
            <RadioGroup
              className="horizontal-radio-group"
              name="webviewHeightRatio"
              selectedValue={webviewHeightRatio}
              onChange={this.selectWebviewHeightRatio}
            >
              <div className="radio-option">
                <Radio className="paloma-radio-label" id="full" value="full" />
                <label className="paloma-radio-label" htmlFor="full">
                  &nbsp;Full
                </label>
              </div>
              <div className="radio-option">
                <Radio id="tall" value="tall" />
                <label className="paloma-radio-label" htmlFor="tall">
                  &nbsp;Tall
                </label>
              </div>
              <div className="radio-option">
                <Radio id="compact" value="compact" />
                <label className="paloma-radio-label" htmlFor="compact">
                  &nbsp;Half
                </label>
              </div>
            </RadioGroup>
            <div>
              <label className="default-form__label">Pass Data</label>
              <Toggle
                checked={canPassData}
                onChange={() => this.setState({ canPassData: !canPassData })}
              />
            </div>
            <br />
            {canPassData ? (
              <TagSelect
                options={this.props.fields.map(f => ({
                  value: f.organizationFieldId,
                  label: f.name,
                }))}
                value={dataFieldIds}
                placeholder="Select data fields..."
                creatable={false}
                onChange={dataFieldIds => this.setState({ dataFieldIds })}
              />
            ) : null}
            <div>
              <label className="default-form__label">Add Tracking</label>
              <Toggle
                checked={shopify}
                onChange={() => this.setState({ shopify: !shopify })}
              />
            </div>
            <br />
            <div className="modal__footer">
              <button
                type="button"
                className="paloma-button paloma-button--white modal__button"
                onClick={this.handleClose}
              >
                Cancel
              </button>
              <button
                className="paloma-button paloma-button--pink modal__button"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state, props) => {
    const {
      organizationFields: { organizationFields: fields },
    } = state;

    return { ...props, fields };
  },
  { fetchOrganizationFields }
)(AddURLButtonModal);
