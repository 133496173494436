import React from "react";
import PropTypes from "prop-types";

import paginate from "../../utilities/paginate";

const Row = ({ columns, onClick }) => (
  <tr className="table__row" onClick={onClick}>
    {columns.map((column, i) => (
      <td className="table__data" key={i}>
        {column}
      </td>
    ))}
  </tr>
);

const PaginationFooter = ({
  headersCount,
  currentPage,
  totalPages,
  onSelectPage,
}) => {
  const hasNextPage = currentPage < totalPages;
  const hasPreviousPage = currentPage > 1;

  const showFirstPageButton = totalPages > 1 && currentPage !== 1;
  const showLastPageButton = totalPages > 1 && currentPage !== totalPages;

  const showLeftEllipsis = totalPages > 4 && currentPage > 2;
  const showRightEllipsis = currentPage + 2 < totalPages - 1;

  const showSecondPreviousPageButton =
    totalPages > 3 && currentPage === totalPages;
  const showPreviousPageButton =
    totalPages > 2 && currentPage !== 2 && currentPage >= totalPages - 1;

  const showNextPageButton = currentPage + 1 < totalPages;
  const showPageAfterNextButton = currentPage + 2 < totalPages;

  const showCurrentPageButton = totalPages > 1;

  return (
    <tfoot className="table__pagination-footer">
      <tr>
        <td colSpan={headersCount} className="table__pagination-controls">
          {hasPreviousPage && (
            <button
              onClick={() => onSelectPage(currentPage - 1)}
              className="paloma-button table__pagination-arrow-button"
            >
              &lt; Previous
            </button>
          )}
          {showFirstPageButton && (
            <button
              className="paloma-button table__pagination-page-button"
              onClick={() => onSelectPage(1)}
            >
              {1}
            </button>
          )}
          {showLeftEllipsis && (
            <span className="table__pagination-ellipsis">&hellip;</span>
          )}
          {showSecondPreviousPageButton && (
            <button
              onClick={() => onSelectPage(currentPage - 2)}
              className="paloma-button table__pagination-page-button"
            >
              {currentPage - 2}
            </button>
          )}
          {showPreviousPageButton && (
            <button
              onClick={() => onSelectPage(currentPage - 1)}
              className="paloma-button table__pagination-page-button"
            >
              {currentPage - 1}
            </button>
          )}
          {showCurrentPageButton && (
            <button
              className="paloma-button table__pagination-page-button table__pagination-page-button--current"
              disabled
            >
              {currentPage}
            </button>
          )}
          {showNextPageButton && (
            <button
              onClick={() => onSelectPage(currentPage + 1)}
              className="paloma-button table__pagination-page-button"
            >
              {currentPage + 1}
            </button>
          )}
          {showPageAfterNextButton && (
            <button
              onClick={() => onSelectPage(currentPage + 2)}
              className="paloma-button table__pagination-page-button"
            >
              {currentPage + 2}
            </button>
          )}
          {showRightEllipsis && (
            <span className="table__pagination-ellipsis">&hellip;</span>
          )}
          {showLastPageButton && (
            <button
              className="paloma-button table__pagination-page-button"
              onClick={() => onSelectPage(totalPages)}
            >
              {totalPages}
            </button>
          )}
          {hasNextPage && (
            <button
              onClick={() => onSelectPage(currentPage + 1)}
              className="paloma-button table__pagination-arrow-button"
            >
              Next &gt;
            </button>
          )}
        </td>
      </tr>
    </tfoot>
  );
};

export default class Table extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      currentPage: props.currentPage || 1,
      totalPages: props.totalPages || 1,
    };
    if (!props.controlPaginationExternally) {
      const { entitiesByPage: rowsByPage, totalPages } = paginate(
        props.rows,
        props.rowsPerPage || 20
      );
      state.rowsByPage = rowsByPage;
      state.totalPages = totalPages;
    }
    this.state = state;

    this.handleSelectPage = this.handleSelectPage.bind(this);
  }

  static get propTypes() {
    return {
      rows: PropTypes.array.isRequired,
      headers: PropTypes.array.isRequired,
      currentPage: PropTypes.number,
      totalPages: PropTypes.number,
      rowsPerPage: PropTypes.number,
      controlPaginationExternally: PropTypes.bool,
      onSelectPage: PropTypes.func,
    };
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    if (!nextProps.controlPaginationExternally) {
      const { entitiesByPage: rowsByPage, totalPages } = paginate(
        nextProps.rows,
        nextProps.rowsPerPage || 20
      );
      newState.rowsByPage = rowsByPage;
      newState.totalPages = totalPages;
    }
    this.setState(newState);
  }

  handleSelectPage(page) {
    if (this.props.controlPaginationExternally) {
      this.props.onSelectPage(page);
    } else {
      if (page >= 1 && page <= this.state.totalPages) {
        this.setState({ currentPage: page });
      }
    }
  }

  render() {
    const rows = this.props.controlPaginationExternally
      ? this.props.rows
      : this.state.rowsByPage[this.state.currentPage];

    return (
      <table className="table">
        <thead>
          <tr className="table__headers">
            {this.props.headers.map((header, i) => (
              <th key={i} className="table__header text-xs">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <Row
              key={i}
              columns={row}
              onClick={() =>
                this.props.onRowClick && this.props.onRowClick(row)
              }
            />
          ))}
        </tbody>
        <PaginationFooter
          headersCount={this.props.headers.length}
          currentPage={
            this.props.controlPaginationExternally
              ? this.props.currentPage
              : this.state.currentPage
          }
          totalPages={
            this.props.controlPaginationExternally
              ? this.props.totalPages
              : this.state.totalPages
          }
          onSelectPage={this.handleSelectPage}
        />
      </table>
    );
  }
}
