import React from "react";
import classNames from "classnames";
import onClickOutside from "react-onclickoutside";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDropdown: false };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleDropdown(e) {
    e.preventDefault();
    const showDropdown = !this.state.showDropdown;
    this.setState({ showDropdown });
    if (this.props.onToggle) {
      this.props.onToggle(showDropdown);
    }
  }

  handleClickOutside() {
    this.setState({ showDropdown: false });
    if (this.props.onToggle) {
      this.props.onToggle(false);
    }
  }

  render() {
    const {
      buttonText,
      dropdownOptions,
      disabled = false,
      className = null,
      buttonClassName = null,
      openButtonClassName = null,
      dropdownClassName = null,
      openDropdownClassName = null,
      dropdownOptionClassName = null,
      openDropdownOptionClassName = null,
    } = this.props;

    const _className = classNames({
      dropdown: this.props.direction === "up" ? false : true,
      dropup: this.props.direction === "up" ? true : false,
      [className]: !!className,
    });
    const _buttonClassName = classNames({
      "paloma-button": true,
      dropdown__button: true,
      [buttonClassName]: !!buttonClassName,
      "dropdown__button--open": this.state.showDropdown,
      [openButtonClassName]: this.state.showDropdown && !!openButtonClassName,
    });
    const _dropdownClassName = classNames({
      dropdown__options: true,
      [dropdownClassName]: !!dropdownClassName,
      "dropdown__options--open": this.state.showDropdown,
      [openDropdownClassName]:
        this.state.showDropdown && !!openDropdownClassName,
    });
    const _dropdownOptionClassName = classNames({
      dropdown__option: true,
      "dropdown__option--open": this.state.showDropdown,
      [dropdownOptionClassName]: !!dropdownOptionClassName,
      [openDropdownOptionClassName]:
        this.state.showDropdown && !!openDropdownOptionClassName,
    });

    return (
      <div className={_className} style={this.props.style}>
        <button
          className={_buttonClassName}
          onClick={this.toggleDropdown}
          disabled={disabled}
        >
          {buttonText}
        </button>
        <ul className={_dropdownClassName}>
          {dropdownOptions.map(({ text, onClick }, i) => (
            <li
              key={i}
              className={_dropdownOptionClassName}
              onClick={ev => {
                onClick(ev);
                this.handleClickOutside();
              }}
            >
              {text}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default onClickOutside(Dropdown);
