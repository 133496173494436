import { ALPHABET } from "./general";

const percentage = (a, b) => (b === 0 ? 0 : Math.round((a * 100.0) / b));

const parseEventReport = (report, steps) => {
  if (!report || !steps) {
    return [];
  }
  let lastReport = false;
  let justSteps = steps.map((step, i) => {
    const stepReport = report.steps[step];
    let stats;
    if (lastReport) {
      stats = {
        completionRate: percentage(stepReport.unique, lastReport.unique),
        overallCompletionRate: percentage(
          stepReport.unique,
          report.start.unique
        ),
        droppedOff: lastReport.unique - stepReport.unique,
        dropOffRate: percentage(
          lastReport.unique - stepReport.unique,
          lastReport.unique
        ),
      };
    } else {
      stats = {
        completionRate: percentage(stepReport.unique, report.start.unique),
        overallCompletionRate: percentage(
          stepReport.unique,
          report.start.unique
        ),
        droppedOff: report.start.unique - stepReport.unique,
        dropOffRate: percentage(
          report.start.unique - stepReport.unique,
          report.start.unique
        ),
      };
    }
    lastReport = stepReport;
    return {
      label: ALPHABET[i % 26],
      stepId: step,
      ...stepReport,
      ...stats,
    };
  });

  const stepsWithClicks = justSteps.reduce((acc, stepReport) => {
    acc.push(stepReport);
    if (stepReport.hasClickable) {
      const click = report.clicks[stepReport.stepId];
      const unique = (click) ? click.unique : 0
      const stats = {
        overallCompletionRate: percentage(unique, report.start.unique),
        completionRate: percentage(unique, stepReport.unique),
        droppedOff: stepReport.unique - unique,
        dropOffRate: percentage(
          stepReport.unique - unique,
          stepReport.unique
        ),
      };
      const clickReport = {
        label: stepReport.label + " Click",
        stepId: stepReport.stepId,
        ...stats,
        ...click,
      };
      acc.push(clickReport);
    }
    return acc;
  }, []);

  stepsWithClicks.splice(0, 0, {
    label: "Start",
    unique: report.start.unique,
    dropOffRate: 0,
    droppedOff: 0,
    completionRate: 100,
    overallCompletionRate: 100,
    total: report.start.total,
  });

  return stepsWithClicks;
};

export { parseEventReport };
