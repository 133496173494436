import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { requestPasswordResetLink } from "../actions/auth";
import { setFlash } from "../actions/flash";
import { showSuccessAlert } from "../actions/alerts";
import { Alert } from "../components/Layout";
import { clearAlerts } from "../actions/alerts";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  emailChange = e => this.setState({ email: e.target.value });

  onSubmit = async e => {
    e.preventDefault();

    this.props.clearAlerts();

    this.props.requestPasswordResetLink(this.state.email);

    await this.props.setFlash({
      body: "Check your inbox for an email with a link to reset your password.",
      title: "Got it!",
    });
    this.setState({ redirect: "/flash" });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{ pathname: this.state.redirect }} />;
    }
    return (
      <div className="login">
        <Alert
          alert={this.props.currentAlert}
          clearAlerts={this.props.clearAlerts}
        />
        <h1 className="login__paloma">Paloma</h1>
        <div className="login__form-container">
          <h2 className="login__title">Request Password Reset Link</h2>
          <form onSubmit={this.onSubmit} className="login__form">
            <label className="default-form__label">Email</label>
            <input
              className="default-form__input"
              type="text"
              value={this.state.email}
              onChange={this.emailChange}
            />

            <input
              className="default-form__submit"
              type="submit"
              value="Request"
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentAlert: state.alerts.currentAlert,
  };
};

export default connect(
  mapStateToProps,
  {
    clearAlerts,
    showSuccessAlert,
    requestPasswordResetLink,
    setFlash,
  }
)(PasswordReset);
