export const SAVE_REPORT_REQUEST = "SAVE_REPORT_REQUEST";
export const SAVE_REPORT_SUCCESS = "SAVE_REPORT_SUCCESS";
export const SAVE_REPORT_FAILURE = "SAVE_REPORT_FAILURE";

export const FETCH_REPORT_REQUEST = "FETCH_REPORT_REQUEST";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAILURE = "FETCH_REPORT_FAILURE";

export const FETCH_REPORTS_REQUEST = "FETCH_REPORTS_REQUEST";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";

export const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE";