import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/email-notifications";

export const fetchEmailNotification = globalTriggerId => ({
  [CALL_API]: {
    endpoint: "/api/triggers/" + globalTriggerId + "/email_notification",
    method: "GET",
    types: [
      constants.FETCH_EMAIL_NOTIFICATION_REQUEST,
      constants.FETCH_EMAIL_NOTIFICATION_SUCCESS,
      constants.FETCH_EMAIL_NOTIFICATION_FAILURE,
    ],
  },
});

export const createEmailNotification = globalTriggerId => ({
  [CALL_API]: {
    endpoint: "/api/triggers/" + globalTriggerId + "/email_notification",
    method: "POST",
    types: [
      constants.CREATE_EMAIL_NOTIFICATION_REQUEST,
      constants.CREATE_EMAIL_NOTIFICATION_SUCCESS,
      constants.CREATE_EMAIL_NOTIFICATION_FAILURE,
    ],
  },
});

export const deleteEmailNotification = globalTriggerId => ({
  [CALL_API]: {
    endpoint: "/api/triggers/" + globalTriggerId + "/email_notification",
    method: "DELETE",
    types: [
      constants.DELETE_EMAIL_NOTIFICATION_REQUEST,
      constants.DELETE_EMAIL_NOTIFICATION_SUCCESS,
      constants.DELETE_EMAIL_NOTIFICATION_FAILURE,
    ],
  },
});
