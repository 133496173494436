import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/messenger-profile";

export const fetchMessengerProfile = () => ({
  [CALL_API]: {
    endpoint: "/api/messenger_profile/",
    method: "GET",
    types: [
      constants.FETCH_MESSENGER_PROFILE_REQUEST,
      constants.FETCH_MESSENGER_PROFILE_SUCCESS,
      constants.FETCH_MESSENGER_PROFILE_FAILURE,
    ],
  },
});

export const updateMessengerProfile = payload => ({
  [CALL_API]: {
    endpoint: "/api/messenger_profile/",
    method: "PUT",
    body: JSON.stringify(payload),
    types: [
      constants.UPDATE_MESSENGER_PROFILE_REQUEST,
      constants.UPDATE_MESSENGER_PROFILE_SUCCESS,
      constants.UPDATE_MESSENGER_PROFILE_FAILURE,
    ],
  },
});

export const deleteMessengerProfile = payload => ({
  [CALL_API]: {
    endpoint: "/api/messenger_profile/",
    method: "DELETE",
    body: JSON.stringify(payload),
    types: [
      constants.DELETE_MESSENGER_PROFILE_REQUEST,
      constants.DELETE_MESSENGER_PROFILE_SUCCESS,
      constants.DELETE_MESSENGER_PROFILE_FAILURE,
    ],
  },
});
