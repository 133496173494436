import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { Card, ContentHeader } from "../../components/shared";
import {
  fetchGlobalTrigger,
  putGlobalTrigger,
} from "../../actions/global-triggers";

class EditGlobalTriggerDetails extends Component {
  state = {
    hasBeenEdited: false,
  };

  async componentDidMount() {
    await this.props.fetchGlobalTrigger(this.props.triggerId);
  }

  render() {
    const { globalTrigger, triggerId } = this.props;

    if (this.state.hasBeenEdited) {
      return <Redirect to={`/global/${triggerId}`} />;
    }

    return (
      <Loader
        loaded={!globalTrigger.isLoading}
        loadedClassName="global-trigger-detail"
      >
        <ContentHeader left={<h1>Edit details</h1>} right="" />
        <Card className="with-margin">
          <form
            className="default-form edit-details-form"
            onSubmit={this.editGlobalTriggerDetails}
          >
            <label className="default-form__label" htmlFor="edit-trigger-name">
              Name
            </label>
            <input
              type="text"
              className="default-form__input"
              name="name"
              defaultValue={globalTrigger.name}
              id="edit-trigger-name"
            />
            <button
              type="submit"
              className="paloma-button paloma-button--pink default-form__submit"
            >
              Update
            </button>
          </form>
        </Card>
      </Loader>
    );
  }

  editGlobalTriggerDetails = async e => {
    e.preventDefault();

    const { triggerId, putGlobalTrigger } = this.props;

    const {
      name: { value: name },
    } = e.currentTarget;

    await putGlobalTrigger({
      triggerId,
      name: name.trim(),
    });

    this.setState({ hasBeenEdited: true });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { triggerId },
    },
  } = ownProps;

  const globalTrigger = {
    isLoading: true,
    ...state.globalTriggers.globalTriggersById[triggerId],
  };

  return {
    globalTrigger,
    triggerId,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchGlobalTrigger,
    putGlobalTrigger,
  }
)(EditGlobalTriggerDetails);
