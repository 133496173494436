import React from "react";
import { connect } from "react-redux";
import SearchablePaginatedOptionModal from "./SearchablePaginatedOptionModal";
import { fetchGlobalTriggers } from "../../actions/global-triggers";

class SelectGlobalTriggerModal extends React.Component {
  state = {
    selectedId: null,
  };

  componentDidMount() {
    this.props.fetchGlobalTriggers();
  }

  handleSave = async selectedId => {
    await this.props.onSave(selectedId);
    this.props.onClose();
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <div className="select-global-trigger-modal">
        <SearchablePaginatedOptionModal
          title="Select a trigger to connect to"
          saveButtonText="Save"
          isOpen={isOpen}
          onClose={onClose}
          onSave={(selectedOptionId) => this.handleSave(selectedOptionId)}
          options={this.props.globalTriggers.map(t => ({
            name: t.name,
            value: t.triggerId,
          }))}
          idForOption={(option) => option.value}
          rowComponent={(option, _) => option.name}
          perPage={7}
          optionSearchKeys={['name']}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    globalTriggers: Object.values(state.globalTriggers.globalTriggersById),
  }),
  { fetchGlobalTriggers }
)(SelectGlobalTriggerModal);
