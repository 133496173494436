import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { Alert } from "../components/Layout";
import { clearAlerts } from "../actions/alerts";
import { resetPassword } from "../actions/auth";
import { showErrorAlert, showSuccessAlert } from "../actions/alerts";
import { showErrors } from "../utilities/notifications";
import { validatePassword } from "../utilities/password";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirm: "",
    };
  }

  passwordChange = e => this.setState({ password: e.target.value });
  passwordConfirmChange = e =>
    this.setState({ passwordConfirm: e.target.value });

  onSubmit = async e => {
    e.preventDefault();

    this.props.clearAlerts();

    if (this.state.password !== this.state.passwordConfirm) {
      this.props.showErrorAlert("Passwords must match.");
      return;
    }

    const errors = validatePassword(this.state.password);

    if (errors.length) {
      showErrors(errors, this.props.showErrorAlert);
      return;
    }

    const response = await this.props.resetPassword(
      this.props.match.params.token,
      this.state.password
    );

    if (response.error && response.payload.response.errors.length) {
      this.props.showErrorAlert(
        response.payload.response.errors.map(e => {
          return (
            <span>
              {e}
              <br />
            </span>
          );
        }),
        { autoDismiss: 0 }
      );
      return;
    }

    if (response.payload.redirect) {
      this.props.showSuccessAlert("Password reset successfully.");
      this.setState({ redirect: response.payload.redirect });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{ pathname: this.state.redirect }} />;
    }
    return (
      <div className="login">
        <Alert
          alert={this.props.currentAlert}
          clearAlerts={this.props.clearAlerts}
        />
        <h1 className="login__paloma">Paloma</h1>
        <div className="login__form-container">
          <h2 className="login__title">Reset Password</h2>
          <form
            onSubmit={this.onSubmit}
            action="/password_reset"
            method="post"
            className="login__form"
          >
            <label className="default-form__label">New Password</label>
            <input
              className="default-form__input"
              type="password"
              value={this.state.password}
              onChange={this.passwordChange}
            />

            <label className="default-form__label">Confirm New Password</label>
            <input
              className="default-form__input"
              type="password"
              value={this.state.passwordConfirm}
              onChange={this.passwordConfirmChange}
            />
            <input
              className="default-form__submit"
              type="submit"
              value="Reset"
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentAlert: state.alerts.currentAlert,
  };
};

export default connect(
  mapStateToProps,
  {
    clearAlerts,
    resetPassword,
    showErrorAlert,
    showSuccessAlert,
  }
)(PasswordReset);
