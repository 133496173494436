import React from "react";
import Select from "react-select";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { fetchReport, saveReport, deleteReport } from "../../actions/reports";
import { showSuccessAlert, showErrorAlert } from "../../actions/alerts";
import { fetchGlobalTriggers } from "../../actions/global-triggers";

import {
  ReportingSection,
  ReportingSectionResult,
} from "../../components/Reporting";
import { 
  ContentHeader, 
  PalomaDatePicker,
  TextInputModal,
  Dropdown,
  DeleteConfirmationModal,
  Card,
} from "../../components/shared";
import {
  sourceOptions,
} from "../../utilities/target-filters";

class ReportDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      sources: [],
      fromDate: moment().subtract(7, "days"),
      toDate: moment(),
      reportId: parseInt(this.props.match.params.reportId) || null,
      title: null,
      showModal: false,
      redirect: null,
    }
  };

  componentDidMount() {
    if(this.state.reportId && !this.props.reports[this.state.reportId]) {
      this.props.fetchReport(this.state.reportId)
    }
    this.props.fetchGlobalTriggers()
  }

  componentDidUpdate(prevProps) {
    const { reports } = this.props
    if (reports !== prevProps.reports && reports) {
      const report = reports[(this.state.reportId || "latest")]
      if(report && report.loading != this.state.loading) {
        this.setState({ loading: report.loading })
      }
      if(report && report.reportId) {
        if(report.reportId !== this.state.reportId) {
          // Save of new report successful, update url and state
          window.history.pushState({"pageTitle":"Paloma Reporting"},"Paloma Reporting",'/reports/' + report.reportId);
          this.setState({ reportId: report.reportId })
        }
        if(report.title !== this.state.title) {
          this.setState({ title: report.title })
        }
        if(report.params) {
          this.setStateWithReportParams(report.params)
        }
      }
    }
  }

  setStateWithReportParams(params) {
    this.setState({
      sources: (params.sources) ? sourceOptions.filter(e => params.sources.split(",").includes(e.value)) : [],
      sections: (params.sections) ? params.sections : [],
      fromDate: (params.start) ? moment.utc(params.start, "DD MM YYYY H:mm") : null,
      toDate: (params.end) ? moment.utc(params.end, "DD MM YYYY H:mm") : null,
    })
  }

  handleAddSection = () => {
    const { sections, results } = this.state;
    const section = {
      targetFilterMatchSetting: "All",
      targetFilters: [{ fieldDropdown: "Received Trigger" }],
    };
    this.setState({
      sections: [...sections, section],
    });
  };

  handleRemoveSection = (i) => {
    const { sections } = this.state;
    sections.splice(i, 1);

    this.setState({ sections });
  };

  handleSectionChange = (index, key, value) => {
    const { sections } = this.state;
    const section = sections[index];
    section[key] = value;
    this.setState({ sections });

      this.saveReportAndFetchResults()
  };

  targetFiltersAreValid = (targetFilters) => {
    if (!targetFilters.length) {
      return true;
    }
    return targetFilters.every(
      ({ conditionDropdown, fieldDropdown, value }) => {
        if (conditionDropdown && conditionDropdown.includes("exist")) {
          return conditionDropdown && fieldDropdown;
        }
        return conditionDropdown && fieldDropdown && value;
      }
    );
  };

  saveReportAndFetchResults = async () => {
    if(!this.state.sections.every(section => { return this.targetFiltersAreValid(section['targetFilters']) })){
      // If one or more of the targetFilters are missing fields we can't save the report at this time. 
      return
    }
    if(this.state.loading) {
      // If a report is currently being loaded, dont save the report at this time as it may cause overwrite
      return
    }

    var params = {
      sources: this.state.sources.map(s => s.value).join(","),
      sections: this.state.sections,
    }

    const { toDate, fromDate } = this.state
    if (toDate) {
      params["end"] = toDate.endOf("day").format("DD MM YYYY H:mm")
    }
    if (fromDate) {
      params["start"] = fromDate.startOf("day").format("DD MM YYYY H:mm")
    }
    await this.props.saveReport(this.state.reportId, { title: this.state.title, params: params });
  }

  deleteReport = async () => {
    if(this.state.reportId) {
      this.props.deleteReport(this.state.reportId).then((res) => this.setState({ redirect: "/reports" }))
    } else {
      window.location.reload(true)
    }
  }

  newReport = (keep_params=false) => {
    if(keep_params) {
      this.setState({
        reportId: null,
        title: (this.state.title) ? ("Copy of "+this.state.title) : null,
      })
      window.history.pushState({"pageTitle":"Paloma Reporting"},"Paloma Reporting",'/reports/new');
    } else {
      if(this.state.reportId) {
        this.setState({ redirect: "/reports/new" })
      } else {
        window.location.reload(true)
      }
    }
  }

  render() {
    const report = this.props.reports[(this.state.reportId || "latest")]

    if(this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    } else {
      return (
        <div>
          <ContentHeader 
            left={<h1 className="page-title">{this.state.title || ("Untitled Report" + (this.state.reportId ? ` - #${this.state.reportId}` : ""))}</h1>}
            right={
              <div className="subscriber-list-buttons">
                <Link
                  className="paloma-button"
                  to={`/reports/`}
                >
                  View Saved Reports
                </Link>
                <CopyToClipboard 
                  text={(this.state.reportId) ? window.location.href : ""}
                  onCopy={() => {
                    if(this.state.reportId) {
                      this.props.showSuccessAlert("Copied Report URL to clipboard!")
                    } else {
                      this.props.showErrorAlert("Report has not been saved yet. Please select at least one set of funnel critera to save report.")
                    }
                  }}
                >
                  <button className="paloma-button">
                    Copy URL
                  </button>
                </CopyToClipboard>
                <button className="paloma-button" onClick={() => this.setState({ showModal: "delete" })}>
                  Delete
                </button>
                <button className="paloma-button" onClick={() => this.setState({ showModal: "title" })}>
                  Edit Title
                </button>
                <Dropdown
                  buttonClassName="paloma-button--pink"
                  buttonText="New Report"
                  dropdownOptions={[
                    {
                      text: <span>Create Duplicate</span>,
                      onClick: () => { this.newReport(true) },
                    },
                    {
                      text: <span>Create New</span>,
                      onClick: () => { this.newReport() },
                    },
                  ]}
                />
              </div>
            }
          />
          <div>
            <h3>Filtering</h3>
            <div style={{ margin: "10px 0" }}>
              <h4 style={{ fontWeight: "600" }}>Date range</h4>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                  <div>
                    <PalomaDatePicker
                      selected={this.state.fromDate}
                      onChange={(fromDate) => this.setState({ fromDate })}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      paddingTop: "5px",
                      fontWeight: "bolder",
                      marginRight: "10px",
                    }}
                  >
                    _
                  </div>
                </div>
                <div>
                  <div>
                    <PalomaDatePicker
                      selected={this.state.toDate}
                      onChange={(toDate) => this.setState({ toDate })}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="paloma-button"
                    disabled={!(this.state.fromDate && this.state.toDate)}
                    onClick={() => this.saveReportAndFetchResults()}
                  >
                    Filter
                  </button>
                </div>
                <div>
                  <button
                    className="paloma-button"
                    disabled={!(this.state.fromDate || this.state.toDate)}
                    onClick={() => this.setState({ toDate: null, fromDate: null }, () => { this.saveReportAndFetchResults() })}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: "10px 0 30px 0" }}>
            <h4 style={{ fontWeight: "600" }}>Source</h4>
            <div style={{ width: 250 }}>
              <Select
                value={this.state.sources}
                style={{ border: "1px solid #E3E5EC" }}
                options={sourceOptions}
                onChange={(sources) => this.setState({ sources }, () => { this.saveReportAndFetchResults() })}
                openOnFocus={false}
                openAfterFocus={false}
                multi
              />
            </div>
          </div>
          <h3>Funnel</h3>
          {this.state.sections.length ? (
            <React.Fragment>
              {this.state.sections.map((section, i) => (
                <ReportingSection
                  id={i}
                  key={i}
                  {...section}
                  onRemove={() => this.handleRemoveSection(i)}
                  onChange={(k, v) => this.handleSectionChange(i, k, v)}
                  globalTriggersById={this.props.globalTriggersById || {}}
                />
              ))}
              <div>
                <button
                  className="paloma-button"
                  style={{ margin: "20px 0" }}
                  onClick={this.handleAddSection}
                >
                  + Add Section
                </button>
              </div>
              <h3>Results</h3>
              {report && !report.loading && this.state.sections.map((section, i) => (
                <div style={{ marginBottom: 10 }}>
                  <ReportingSectionResult
                    id={i}
                    section={section}
                    showShopifyData={this.props.currentOrganization.hasShopifyStorefront}
                    showPixelData={this.props.currentOrganization.hasPixelEnabled}
                    globalTriggersById={this.props.globalTriggersById || {}}
                    {...report.results[i]}
                  />
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div>
              {this.state.loading ? (
                <Card>
                  <div className="unpublished-conversation-cta" style={{ padding: "100px 0px" }}>
                    <div>
                      <h2>Loading...</h2>
                    </div>
                  </div>
                </Card>
              ) : (
                <Card>
                  <div className="unpublished-conversation-cta" style={{ padding: "100px 0px" }}>
                    <div>
                      <p>
                        Click below to define the first section of the funnel you'd like to 
                        report on and the results will display below.
                      </p>
                      <div className="unpublished-conversation-cta-buttons">
                        <button className="paloma-button paloma-button--pink" onClick={() => this.handleAddSection()}>
                          + Add Section
                        </button>
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          )}
          {this.state.showModal === "delete" && (
            <DeleteConfirmationModal
              title={"Delete this report?"}
              onClose={() => this.setState({ showModal: false })}
              handleDelete={() => this.deleteReport()}
            />
          )}
          {this.state.showModal === "title" && (
            <TextInputModal
              title={"Set Report Title"}
              onClose={() => this.setState({ showModal: false })}
              value={this.state.title}
              onSubmit={(title) => this.setState({ title: title }, () => { this.saveReportAndFetchResults() })}
            />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  currentOrganization: state.currentOrganization,
  globalTriggersById: state.globalTriggers.globalTriggersById
});

export default connect(
  mapStateToProps,
  { 
    fetchReport,
    saveReport,
    deleteReport,
    showSuccessAlert,
    showErrorAlert,
    fetchGlobalTriggers
  }
)(ReportDetail);