import React from "react";

const TabSelector = ({ choices = [] }) => {
  return (
    <div className="tab-select">
      {choices.map(c => (
        <a
          className={c.selected ? "selected" : ""}
          key={c.label}
          onClick={c.onSelect}
        >
          {c.label}
        </a>
      ))}
    </div>
  );
};

export default TabSelector;
