import qs from "qs";

const PAGE_FACEBOOK_SCOPE = [
  "email",
  "pages_manage_metadata",
  "pages_read_engagement",
  "pages_messaging",
  "pages_messaging_subscriptions",
  "pages_show_list",
].join(",");

const ADS_FACEBOOK_SCOPE = [
  "ads_management"
].join(",");

const getFBUserEdge = async (edgePath, accessToken, userId) => {
  let {
    data,
    paging: { next: nextUrl },
  } = await new Promise(resolve =>
    window.FB.api(`/${userId}/${edgePath}`, { access_token: accessToken }, resolve)
  );

  let edges = [...data];
  while (nextUrl) {
    const querystring = nextUrl.substring(nextUrl.indexOf("?") + 1);
    const queryparams = qs.parse(querystring);
    ({
      data,
      paging: { next: nextUrl },
    } = await new Promise(resolve =>
      window.FB.api(`/${userId}/${edgePath}`, { ...queryparams }, resolve)
    ));

    edges = [...edges, ...data];
  }
  return { edges, accessToken };
}

const loginToFacebook = async (scope, edgePath) => {
  const loginResponse = await new Promise(resolve =>
    window.FB.login(resolve, { scope })
  );
  const sessionToken = window.localStorage.getItem("paloma_session_token");

  const {
    authResponse: { accessToken, userID },
  } = loginResponse;

  const response = await fetch(
    process.env.REACT_APP_API_HOSTNAME + "/api/fb_token",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ access_token: accessToken }),
    }
  );

  const { access_token: fbToken } = await response.json();

  return getFBUserEdge(edgePath, fbToken, userID);
};

export const connectToFacebookPage = async () => {
  return await loginToFacebook(PAGE_FACEBOOK_SCOPE, 'accounts')
}

export const connectToAdAccount = async () => {
  return await loginToFacebook(ADS_FACEBOOK_SCOPE, 'adaccounts?fields=account_id,business_name,name')
}
