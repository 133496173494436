import React from "react";
import { Modal } from "./shared";

const DeleteSequenceModal = ({ sequenceId, deleteSequence, onClose }) => {
  return (
    <Modal title="Delete sequence?" isOpen={true} onClose={onClose}>
      <div className="modal__controls">
        <button
          type="button"
          className="paloma-button modal__button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="paloma-button modal__button modal__button--red"
          onClick={async () => {
            await deleteSequence({ sequenceId });
            onClose();
          }}
          type="submit"
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default DeleteSequenceModal;
