import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/active-signup-conversations";

export const fetchActiveSignupConversations = () => {
  return {
    [CALL_API]: {
      endpoint: "/api/active_signup_conversations",
      method: "GET",
      types: [
        constants.FETCH_ACTIVE_SIGNUP_CONVERSATIONS_REQUEST,
        constants.FETCH_ACTIVE_SIGNUP_CONVERSATIONS_SUCCESS,
        constants.FETCH_ACTIVE_SIGNUP_CONVERSATIONS_FAILURE,
      ],
    },
  };
};
