import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { createGlobalDefaultConversation } from "../../actions/global-default-conversations";

import { Card } from "../../components/shared";

class NewGlobalDefaultConversation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      globalDefaultConversation: { label: "" },
      formIsValid: false,
    };

    this.handleCreateConversation = this.handleCreateConversation.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
  }

  async handleCreateConversation(e) {
    e.preventDefault();
    const response = await this.props.createGlobalDefaultConversation(
      this.state.globalDefaultConversation
    );
    this.props.push(
      `/global/unexpected/conversations/${
        response.payload.data[0].conversationId
      }/details`
    );
  }

  handleInputChange = e => {
    this.setState({
      globalDefaultConversation: { label: e.target.value },
    });
    this.checkValidation();
  };

  checkValidation() {
    if (this.state.globalDefaultConversation.label) {
      this.setState({ formIsValid: true });
    } else {
      this.setState({ formIsValid: false });
    }
  }

  render() {
    return (
      <div className="new-global-default-conversation">
        <h1 className="page-title">New Global Default Conversation</h1>
        <Card>
          <form
            className="default-form"
            onSubmit={this.handleCreateConversation}
          >
            <label
              className="default-form__label"
              htmlFor="global-default-conversation-label"
            >
              Name
            </label>
            <input
              id="global-default-conversation-label"
              className="default-form__input"
              type="text"
              value={this.state.globalDefaultConversation.label}
              onChange={this.handleInputChange}
            />
            <button
              className="default-form__submit"
              type="submit"
              disabled={!this.state.formIsValid}
            >
              Create
            </button>
          </form>
        </Card>
      </div>
    );
  }
}

export default connect(
  null,
  { createGlobalDefaultConversation, push }
)(NewGlobalDefaultConversation);
