import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, ContentHeader } from "../components/shared";
import { fetchConversation } from "../actions/conversations";
import {
  fetchSequences,
  deleteSequence,
  duplicateSequence,
} from "../actions/sequences";
import { formatDate } from "../utilities/format-date";
import DeleteSequenceModal from "../components/DeleteSequenceModal";
import { push } from "react-router-redux";
import PaginatedList from "../components/PaginatedList";

class Sequences extends React.Component {
  componentDidMount() {
    const {
      conversationId,
      conversation,
      fetchConversation,
      fetchSequences,
    } = this.props;
    if (!conversation) {
      fetchConversation(conversationId);
    }

    fetchSequences({ conversationId });
  }

  render() {
    const {
      conversation,
      sequences,
      deleteSequence,
      duplicateSequence,
    } = this.props;

    return (
      <div>
        <ContentHeader
          left={
            <h1 className="page-title">{conversation && conversation.label}</h1>
          }
        />
        <Card className="info-panel">
          <h3>Sequences</h3>
          <ContentHeader
            left={
              <div className="description">
                <p>
                  Create and define sequences for subscribers after they receive
                  this conversation.
                </p>
              </div>
            }
            right={
              <button
                className="paloma-button"
                onClick={() => this.props.push("sequences/new")}
              >
                Add a sequence
              </button>
            }
          />
          <SequencesTable
            sequences={sequences}
            deleteSequence={deleteSequence}
            duplicateSequence={duplicateSequence}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { conversationId } = props.match.params;
  const conversation = state.conversations.conversationsById[conversationId];
  const { sequences } = state.sequences;
  return {
    conversationId,
    conversation,
    sequences,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchConversation,
    fetchSequences,
    deleteSequence,
    push,
    duplicateSequence,
  }
)(Sequences);

const SequenceRow = ({ sequence, onDelete, onDuplicate }) => {
  return (
    <tr>
      <td className="table__data">
        <Link className="table__link" to={`sequences/${sequence.sequenceId}`}>
          {sequence.name}
        </Link>
      </td>
      <td className="table__data">
        {sequence.published ? "Published" : "Not published"}
      </td>
      <td className="table__data">
        {sequence.published ? sequence.numRecipients : "N/A"}
      </td>
      <td className="table__data">{formatDate(sequence.lastRanAt) || "N/A"}</td>
      <td className="table__data">
        <div style={{ display: "flex" }}>
          <button className="table__link" onClick={onDuplicate}>
            Duplicate
          </button>

          <Link
            style={{ lineHeight: "normal" }}
            className="table__link"
            to={`sequences/${sequence.sequenceId}`}
          >
            View
          </Link>

          <button className="table__link" onClick={onDelete}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

class SequencesTable extends React.Component {
  state = {
    deleteModalSequenceId: null,
  };

  render() {
    const { sequences, deleteSequence, duplicateSequence } = this.props;
    const { deleteModalSequenceId } = this.state;

    return (
      <React.Fragment>
        {this.state.deleteModalSequenceId ? (
          <DeleteSequenceModal
            sequenceId={deleteModalSequenceId}
            onClose={() => this.setState({ deleteModalSequenceId: null })}
            deleteSequence={deleteSequence}
          />
        ) : null}
        <PaginatedList
          headers={["Name", "Status", "Recipients", "Last ran", ""]}
          items={sequences}
          component={sequence => (
            <SequenceRow
              sequence={sequence}
              key={sequence.sequenceId}
              onDelete={() =>
                this.setState({ deleteModalSequenceId: sequence.sequenceId })
              }
              onDuplicate={() =>
                duplicateSequence({ sequenceId: sequence.sequenceId })
              }
            />
          )}
          total={sequences.length}
        />
      </React.Fragment>
    );
  }
}
