import { CALL_API } from "redux-api-middleware";
import queryString from "query-string";
import * as constants from "../constants/messages";

export const fetchMessages = ({ page = 1, messageType = null, conversationId = null }) => {
  const queryParams = { page, message_type: messageType, conversation_id: conversationId };

  return {
    [CALL_API]: {
      endpoint: `/api/messages?${queryString.stringify(queryParams)}`,
      method: "GET",
      types: [
        constants.FETCH_MESSAGES_REQUEST,
        {
          type: constants.FETCH_MESSAGES_SUCCESS,
          meta: { messageType },
        },
        constants.FETCH_MESSAGES_FAILURE,
      ]
    },
  }
};