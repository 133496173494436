import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/current-user";

export const fetchCurrentUser = () => ({
  [CALL_API]: {
    endpoint: "/api/current_user/",
    method: "GET",
    types: [
      constants.FETCH_CURRENT_USER_REQUEST,
      constants.FETCH_CURRENT_USER_SUCCESS,
      constants.FETCH_CURRENT_USER_FAILURE,
    ],
  },
});

export const updateCurrentUser = params => ({
  [CALL_API]: {
    endpoint: "/api/current_user/",
    method: "PUT",
    body: params,
    types: [
      constants.UPDATE_CURRENT_USER_REQUEST,
      constants.UPDATE_CURRENT_USER_SUCCESS,
      constants.UPDATE_CURRENT_USER_FAILURE,
    ],
  },
});
