import createReducer from "../utilities/create-reducer";
import * as constants from "../constants/assign_subscriber_owner";
import { withoutKey } from "../utilities/reducers";
const initialState = { byTriggerId: {} };

export default createReducer(initialState, {
  [constants.FETCH_ASSIGN_SUBSCRIBER_OWNER_SUCCESS](state, action) {
    if (action.payload) {
      return {
        ...state,
        byTriggerId: {
          ...state.byTriggerId,
          [action.payload.triggerId]: action.payload,
        },
      };
    }
    return state;
  },

  [constants.CREATE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS](state, action) {
    return {
      ...state,
      byTriggerId: {
        ...state.byTriggerId,
        [action.payload.triggerId]: action.payload,
      },
    };
  },

  [constants.DELETE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS](state, action) {
    return {
      ...state,
      byTriggerId: withoutKey(state.byTriggerId, action.payload.triggerId),
    };
  },
});
