import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/shopify-admin-api-key";

export const updateShopifyConfig = params => ({
  [CALL_API]: {
    endpoint: "/api/shopify_configuration/",
    method: "POST",
    types: [
      constants.UPDATE_SHOPIFY_CONFIG_REQUEST,
      constants.UPDATE_SHOPIFY_CONFIG_SUCCESS,
      constants.UPDATE_SHOPIFY_CONFIG_FAILURE,
    ],
    body: params,
  },
});
