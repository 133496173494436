import React from "react";

import Dropdown from "./Dropdown";

const DownloadButton = ({ download, className, buttonText = "Download" }) => (
  <Dropdown
    buttonClassName={className}
    buttonText={buttonText}
    dropdownOptions={[
      {
        text: "CSV",
        onClick: () => download("text/csv"),
      },
      {
        text: "JSON",
        onClick: () => download("application/json"),
      },
    ]}
  />
);

export default DownloadButton;
