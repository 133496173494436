import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  fetchOrganizationFields,
  updateTextMatchValidation,
  fetchFieldSubscribers,
  deleteOrganizationField,
  postMatchingTexts,
} from "../actions/organization-fields";
import PaginatedList from "../components/PaginatedList";
import { MatchingResponseItem } from "../components/GlobalTriggerDetail/MatchingResponses";

import { ContentHeader, Card } from "../components/shared";
import { Link } from "react-router-dom";
import { Toggle } from "../components/shared";
import SubscriberTable from "../components/Audience/SortableSubscriberTable";
import pick from "lodash/pick";
import { DEFAULT_FIELDS } from "./Fields";
import { capitalizeString } from "../utilities/format-string";

const default_validations_info = {
  email: "Valid email addresses only.",
  state: "Valid U.S. states only.",
  "zip code": "Valid U.S. ZIP Codes only.",
  "phone number": "Valid U.S. phone numbers only.",
};

class Field extends React.Component {
  state = {
    subscribersData: null,
    newMatchingTerm: false,
  };

  componentDidMount() {
    if (!this.props.fields.organizationFields.length) {
      this.props.fetchOrganizationFields();
    }

    this.fetchSubscribers();
  }

  fetchSubscribers = (page = 1) => {
    this.props
      .fetchFieldSubscribers(this.props.match.params.fieldId, page)
      .then(({ payload: subscribersData }) =>
        this.setState({ subscribersData })
      );
  };

  toggleTextMatchValidation = value => {
    this.props
      .updateTextMatchValidation(this.props.field.organizationFieldId, !value)
      .finally(this.props.fetchOrganizationFields);
  };

  deleteField = () => {
    const {
      field: { organizationFieldId },
    } = this.props;

    this.props
      .deleteOrganizationField(organizationFieldId)
      .then(() => this.props.push("/fields"));
  };

  updateMatchingTerm = async (id, term) => {
    const { field } = this.props;
    const matchingTexts = field && field.matchingTexts;

    if (Number.isInteger(id)) {
      matchingTexts[id] = term.trim().toLowerCase();
    } else {
      matchingTexts.push(term.trim().toLowerCase());
      this.setState({ newMatchingTerm: false });
    }

    this.props
      .postMatchingTexts({
        fieldId: field.organizationFieldId,
        matchingTexts,
      })
      .finally(this.props.fetchOrganizationFields);
  };

  removeMatchingTerm = async index => {
    const { field } = this.props;
    const matchingTexts = field && field.matchingTexts;
    matchingTexts.splice(index, 1);

    this.props
      .postMatchingTexts({
        fieldId: field.organizationFieldId,
        matchingTexts,
      })
      .finally(this.props.fetchOrganizationFields);
  };

  render() {
    const { field } = this.props;
    const { subscribersData, newMatchingTerm } = this.state;
    const matchingTerms = field && field.matchingTexts;
    const hasTextMatchOn = field && field.validations.includes("text_match");

    const isDefault =
      field && DEFAULT_FIELDS.includes(field.name.toLowerCase());

    return (
      <div>
        <ContentHeader
          left={<h1 className="page-title">{field ? field.name : ""}</h1>}
          right={
            <div>
              <Link
                className="paloma-button"
                to={`/fields/${this.props.match.params.fieldId}/edit`}
              >
                Edit details
              </Link>
              {!isDefault ? (
                <button className="paloma-button" onClick={this.deleteField}>
                  Delete
                </button>
              ) : null}
            </div>
          }
        />
        <Card className="info-panel">
          <div>
            <h3>Required for complete profile</h3>
            <label className="toggle-wrapper">
              <Toggle
                className="square"
                onChange={() => ""}
                checked={field && field.required}
              />
              I would like to mark this field as required for profile
              completeness.
            </label>
          </div>
        </Card>

        {field &&
          field.validations
            .filter(v => DEFAULT_FIELDS.includes(v))
            .map(validation => (
              <Card className="info-panel">
                <div>
                  <h3>{capitalizeString(validation)} validation</h3>
                  <label className="toggle-wrapper">
                    <Toggle className="square" checked disabled />
                    {default_validations_info[validation.toLowerCase()]}
                  </label>
                </div>
              </Card>
            ))}

        {field && !DEFAULT_FIELDS.includes(field.name.toLowerCase()) ? (
          <Card className="info-panel">
            <div>
              <ContentHeader left={<h3>Text match validation</h3>} />
              <label className="toggle-wrapper">
                <Toggle
                  className="square"
                  onChange={this.toggleTextMatchValidation}
                  checked={hasTextMatchOn}
                />
                I would like to match user entered values against a list of
                texts.
              </label>
            </div>
          </Card>
        ) : null}

        {matchingTerms && hasTextMatchOn ? (
          <Card className="info-panel">
            <div>
              <h3>Matching terms</h3>
              <ContentHeader
                left={
                  <div className="description" style={{ width: "60%" }}>
                    <p>
                      When subscribers send any of the following responses where
                      this field is being captured, they will pass validation
                      and move onto the next step in the conversation. Exact
                      spelling matters, but capitalization doesn't.
                    </p>
                  </div>
                }
                right={
                  <button
                    className="paloma-button"
                    onClick={() =>
                      !newMatchingTerm &&
                      this.setState({ newMatchingTerm: true })
                    }
                  >
                    + New
                  </button>
                }
              />
              {newMatchingTerm && (
                <MatchingResponseItem
                  id={null}
                  isEditing
                  key="new-whitelist-domain"
                  matchingResponse=""
                  onSave={this.updateMatchingTerm}
                  onDelete={() => this.setState({ newMatchingTerm: false })}
                />
              )}
              <PaginatedList
                items={
                  field
                    ? field.matchingTexts.map((term, id) => ({ id, term }))
                    : []
                }
                perPage={10}
                component={({ id, term }) => (
                  <MatchingResponseItem
                    id={id}
                    key={id}
                    matchingResponse={term}
                    onSave={this.updateMatchingTerm}
                    onDelete={this.removeMatchingTerm}
                    deleteLabel="Remove"
                    deleteConfirmationText="Remove matching term?"
                  />
                )}
              />
            </div>
          </Card>
        ) : null}

        <Card className="info-panel">
          <div>
            <h3>Subscribers</h3>
            {subscribersData && (
              <SubscriberTable
                page={subscribersData.currentPage}
                total={subscribersData.totalItems}
                {...pick(subscribersData, [
                  "subscribers",
                  "perPage",
                  "totalPages",
                ])}
                excludes={["owners"]}
                fetch={({ page }) => this.fetchSubscribers(page)}
                searchable={false}
                exclude={["owner"]}
                sortable={false}
              />
            )}
          </div>
        </Card>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { byId } = state.organizationFields;
    const field = byId[props.match.params.fieldId] || null;

    return {
      field,
      fields: state.organizationFields,
      ...props,
    };
  },
  {
    fetchOrganizationFields,
    updateTextMatchValidation,
    fetchFieldSubscribers,
    deleteOrganizationField,
    push,
    postMatchingTexts,
  }
)(Field);
