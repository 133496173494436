import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/acquisition-actions";

const initialState = [];

export default createReducer(initialState, {
  [constants.FETCH_ACQUISITION_ACTIONS_SUCCESS](state, action) {
    return action.payload;
  },

  [constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS](
    state,
    action
  ) {
    return action.payload;
  },

  [constants.DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS](
    state,
    action
  ) {
    return action.payload;
  },

  [constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS](
    state,
    action
  ) {
    return action.payload;
  },

  [constants.DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS](
    state,
    action
  ) {
    return action.payload;
  },
});
