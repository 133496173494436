import React from "react";
import { Link } from "react-router-dom";

import { Card, Table, Search } from "../shared";

import { formatDate } from "../../utilities/format-date";
import SubscriberListDeleteButton from "./SubscriberListDeleteButton";

export default ({
  subscriberLists,
  subscriberListSearchQuery,
  handleSubscriberListSearch,
  loading,
}) => (
  <Card loading={loading}>
    <Search
      className="table-controls__search"
      query={subscriberListSearchQuery}
      onSearch={handleSubscriberListSearch}
      placeholder="Search by name"
    />
    <br />
    <br />
    <Table
      headers={["Name", "Subscribers", "Created", "", ""]}
      rows={subscriberLists.map(subscriberList => [
        <Link
          className="table__link"
          to={`/audience/lists/${subscriberList.subscriberListId}`}
        >
          {subscriberList.name}
        </Link>,
        subscriberList.numSubscribers,
        <div>{subscriberList.subscriberListId != "unsubscribers" ? formatDate(subscriberList.createdAt) : ""}</div>,
        <div>{subscriberList.subscriberListId != "unsubscribers" ? (<SubscriberListDeleteButton subscriberList={subscriberList} />) : ""}</div>,
        <Link
          className="table__link"
          to={`/audience/lists/${subscriberList.subscriberListId}`}
        >
          View
        </Link>,
      ])}
    />
  </Card>
);
