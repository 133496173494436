const conditionOptionsByField = {
  "Added to Cart": ["something in", "nothing in"],
  "Date added": ["is", "is before", "is after"],
  "Received Trigger": ["did", "did not"],
  "Signup conversation": ["is", "is not"],
  "Signup source": ["is", "is not"],
  Clicked: ["something in", "nothing in"],
  "Contact owner": ["is", "is not"],
  Completed: ["did", "did not"],
  Purchased: ["something in", "nothing in"],
  Received: ["did", "did not"],
  Status: ["is", "is not"],
  "Subscriber profile": ["is", "is not"],
  "Current conversation": ["is", "is not"],
  Converted: ["did", "did not"],
  "Ad Referral ID": ["is", "is not"],
};

const permanentFieldOptions = Object.keys(conditionOptionsByField);

export const fieldOptions = orgFields => [
  ...permanentFieldOptions.map(stringToOption),
  ...orgFields.map(({ name, organizationFieldId }) => ({
    label: name,
    value: organizationFieldId,
  })),
];

export const conditionOptionsForField = field => {
  return (
    conditionOptionsByField[field] || [
      "is",
      "is not",
      "exists",
      "does not exist",
    ]
  ).map(stringToOption);
};

export const sourceOptions = [
  { value: "ADS", label: "Facebook Ads" },
  { value: "CUSTOMER_CHAT_PLUGIN", label: "Customer Chat Plugin" },
  { value: "DISCOVER_TAB", label: "Discover Tab" },
  { value: "MESSENGER_CODE", label: "Messenger Code" },
  { value: "SHORTLINK", label: "Shortlink" },
  { value: "ORGANIC", label: "Organic" },
]

const stringToOption = x => ({ label: x, value: x });
