import React from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { ContentHeader } from "../../components/shared";
import {
  fetchConversation,
  publishConversation,
} from "../../actions/conversations";
import { Card } from "../../components/shared";
import { push } from "react-router-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getConversationLink } from "../../utilities/conversation";
import TestSendConversation from "../TestSendConversation";
import infoIcon from "../../images/info.svg";

class WMConversationPublisher extends React.Component {
  componentDidMount() {
    const { conversationId } = this.props;
    this.props.fetchConversation(conversationId);
  }

  render() {
    const { conversation } = this.props;

    return (
      <Loader
        loaded={!conversation.isLoading}
        loadedClassName="conversation-detail"
      >
        <ContentHeader left={<h1>{conversation.label}</h1>} />
        <Card className="info-panel">
          <div className="conversation-publisher">
            <h3>Recipients</h3>

            <h4 className="card__section-title conversation-publisher__trigger-section">
              All subscribers that enter a matching response will receive this
              conversation.
            </h4>
            <ConfirmationModal onConfirm={this.publishConversation} />
            <div className="conversation-publisher__notice-section">
              <img
                src={infoIcon}
                className="conversation-publisher__notice-section-icon"
                alt=""
              />
              Publishing this conversation will deactivate the existing active
              conversation for this trigger.
            </div>
          </div>
        </Card>
        <Card className="info-panel">
          <TestSendConversation id={this.props.conversationId} />
        </Card>
      </Loader>
    );
  }

  publishConversation = () => {
    const {
      conversation,
      conversationId,
      publishConversation,
      push,
    } = this.props;

    publishConversation({ conversationId });

    push("details");
  };
}

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { conversationId },
    },
  } = props;

  const {
    conversations: { conversationsById },
  } = state;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  return {
    conversationId,
    conversation,
  };
};

export default connect(mapStateToProps, {
  fetchConversation,
  publishConversation,
  push,
})(WMConversationPublisher);
