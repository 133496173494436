import React from "react";
import classNames from "classnames";
import Loader from "react-loader";

var loaderOptions = {
  lines: 10,
  length: 5,
  width: 2,
  radius: 5,
  scale: 1.0,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  left: "100%",
  position: "relative",
};

export default ({ children, loading = false, className = null }) => (
  <div className={classNames({ card: true, [className]: !!className })}>
    <Loader loaded={!loading} options={loaderOptions} />
    {children}
  </div>
);
