import * as constants from "../constants/conversation-recipients";
import { CALL_API } from "redux-api-middleware";
import qs from "query-string";

export const fetchConversationRecipients = (cId, { page }) => {
  const params = qs.stringify({ page });

  return {
    [CALL_API]: {
      endpoint: `/api/conversations/${cId}/recipients?${params}`,
      method: "GET",
      types: [
        constants.FETCH_CONVERATION_REQUEST,
        constants.FETCH_CONVERATION_SUCESS,
        constants.FETCH_CONVERATION_FAILURE,
      ],
    },
  };
};
