import React from "react";
import { Link } from "react-router-dom";

import { Table } from "../shared";

import { formatDate } from "../../utilities/format-date";

function getConversationUrl(conversation) {
  switch (conversation.type) {
    case "campaign":
      return `/campaigns/${conversation.campaignId}/conversations/${
        conversation.conversationId
      }/details`;
    case "global":
      return `/global/${conversation.triggerId}/conversations/${
        conversation.conversationId
      }/details`;
    case "signup":
      return `/acquisition/signup/${conversation.conversationId}/details`;
    default:
      return "";
  }
}

export default ({ conversations }) => (
  <div className="subscriber-conversations">
    <div className="content-header">
      <h3>Conversations</h3>
    </div>
    <Table
      headers={["Name", "Date received", ""]}
      rows={conversations.map(conversation => [
        <Link className="table__link" to={getConversationUrl(conversation)}>
          {conversation.label}
        </Link>,
        formatDate(conversation.receivedAt),
        <Link className="table__link" to={getConversationUrl(conversation)}>
          View
        </Link>,
      ])}
    />
  </div>
);
