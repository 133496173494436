import React from "react";
import { connect } from "react-redux";
import { fetchLink } from "../actions/links";
import { fetchCampaign } from "../actions/campaigns";
import { fetchConversation } from "../actions/conversations";
import { fetchGlobalTrigger } from "../actions/global-triggers";
import PaginatedList from "../components/PaginatedList";
import { withTime } from "../utilities/format-date";

class LinkDetails extends React.Component {
  componentWillMount = () => {
    const {
      match: {
        params: { buttonType, buttonId },
      },
    } = this.props;
    this.props.fetchLink(buttonType, buttonId).then(link => {
      this.props.fetchConversation(link.payload.conversation.conversationId);
      if (link.payload.conversation.campaignId) {
        this.props.fetchCampaign(link.payload.conversation.campaignId);
      } else if (link.payload.conversation.triggerId) {
        this.props.fetchGlobalTrigger(link.payload.conversation.triggerId);
      }
    });
  };

  render = () => {
    return (
      <div className="link-detail">
        <div className="content-header">
          <h1>Link Details</h1>
        </div>
        <div className="info-panel">
          <a href={this.props.url} className="link" target="_blank">
            {this.props.url}
          </a>
        </div>
        <div className="row">
          <div className="info-panel">
            <h3>
              Total Clicks<span className="stat">{this.props.totalClicks}</span>
            </h3>
          </div>
          <div className="info-panel">
            <h3>
              Unique Clicks<span className="stat">
                {this.props.uniqueClicks}
              </span>
            </h3>
          </div>
          <div className="info-panel">
            <h3>
              Click Rate<span className="stat">{this.props.clickRate}%</span>
            </h3>
          </div>
        </div>
        <div className="info-panel">
          <h3>Recipients Clicked</h3>
          <PaginatedList
            headers={[
              "Name",
              "Clicks",
              "Last Message",
              "Manual",
              "Subscribed",
              "",
            ]}
            items={this.props.recipientsClicked}
            component={Subscriber}
          />
        </div>
      </div>
    );
  };
}

const subscriberLink = (sub, text) => (
  <a href={`/audience/subscribers/${sub.subscriberId}`} className="table__link">
    {text}
  </a>
);

const Subscriber = sub => (
  <tr className="table__row" key={sub.subscriberId}>
    <td className="table__data">{subscriberLink(sub, sub.fullName)}</td>
    <td className="table__data">{sub.clicks}</td>
    <td className="table__data">{withTime(sub.lastMessageReceivedAt)}</td>
    <td className="table__data">{sub.manualMessagingEnabled ? "Yes" : "No"}</td>
    <td className="table__data">{sub.subscribed ? "Yes" : "No"}</td>
    <td className="table__data">{subscriberLink(sub, "View")}</td>
  </tr>
);

LinkDetails.defaultProps = { recipientsClicked: [] };

const mapStateToProps = state => state.links;

export default connect(
  mapStateToProps,
  { fetchLink, fetchCampaign, fetchGlobalTrigger, fetchConversation }
)(LinkDetails);
