import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { Card, ContentHeader } from "../../components/shared";
import {
  fetchSubscriberList,
  putSubscriberList,
} from "../../actions/subscriber-lists";

class EditSubscriberListDetailsContainer extends Component {
  state = {
    hasBeenEdited: false,
  };

  async componentDidMount() {
    await this.props.fetchSubscriberList(this.props.subscriberListId);
  }

  render() {
    const { subscriberListId, subscriberList } = this.props;

    if (this.state.hasBeenEdited) {
      return <Redirect to={`/audience/lists/${subscriberListId}`} />;
    }

    return (
      <Loader
        loaded={!subscriberList.isLoading}
        loadedClassName="subscriberList-detail"
      >
        <ContentHeader left={<h1>Edit details</h1>} right="" />
        <Card className="with-margin">
          <form
            className="default-form edit-details-form"
            onSubmit={this.editSubscriberListDetails}
          >
            <label
              className="default-form__label"
              htmlFor="edit-subscriber-list-name"
            >
              Name
            </label>
            <input
              type="text"
              className="default-form__input"
              name="name"
              defaultValue={subscriberList.name}
              id="edit-subscriber-list-name"
            />
            <button
              type="submit"
              className="paloma-button paloma-button--pink default-form__submit"
            >
              Update
            </button>
          </form>
        </Card>
      </Loader>
    );
  }

  editSubscriberListDetails = async e => {
    e.preventDefault();

    const { subscriberListId, putSubscriberList } = this.props;

    const {
      name: { value: name },
    } = e.currentTarget;

    await putSubscriberList({
      subscriberListId,
      name: name.trim(),
    });

    this.setState({ hasBeenEdited: true });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { subscriberListId },
    },
  } = ownProps;

  const subscriberList = {
    isLoading: true,
    ...state.subscriberLists.byId[subscriberListId],
  };

  return {
    subscriberListId,
    subscriberList,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchSubscriberList,
    putSubscriberList,
  }
)(EditSubscriberListDetailsContainer);
