import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/conversation-schedules";

export const createConversationSchedule = id => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/schedule`,
    method: "POST",
    types: [
      constants.CREATE_CONVERSATION_SCHEDULE_REQUEST,
      constants.CREATE_CONVERSATION_SCHEDULE_SUCCESS,
      constants.CREATE_CONVERSATION_SCHEDULE_FAILURE,
    ],
  },
});

export const deleteConversationSchedule = id => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/schedule`,
    method: "DELETE",
    types: [
      constants.DELETE_CONVERSATION_SCHEDULE_REQUEST,
      constants.DELETE_CONVERSATION_SCHEDULE_SUCCESS,
      constants.DELETE_CONVERSATION_SCHEDULE_FAILURE,
    ],
  },
});

export const updateConversationSchedule = (id, sendAt) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/schedule`,
    method: "PUT",
    body: { sendAt },
    types: [
      {
        type: constants.UPDATE_CONVERSATION_SCHEDULE_REQUEST,
        meta: { sendAt },
      },
      constants.UPDATE_CONVERSATION_SCHEDULE_SUCCESS,
      constants.UPDATE_CONVERSATION_SCHEDULE_FAILURE,
    ],
  },
});
