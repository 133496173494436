import { normalize } from "normalizr";

import { subscriberSchema } from "../schemas";

import createReducer from "../utilities/create-reducer";

import * as subscriberConstants from "../constants/subscribers";

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [subscriberConstants.FETCH_SUBSCRIBER_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, subscriberSchema);
    const normalizedSubscriberConversationStates =
      normalizedData.entities.subscriberConversationStates || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberConversationStates,
      },
    };
  },

  [subscriberConstants.FETCH_SUBSCRIBERS_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, {
      subscribers: [subscriberSchema],
    });

    const normalizedSubscriberConversationStates =
      normalizedData.entities.subscriberConversationStates || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberConversationStates,
      },
    };
  },
});
