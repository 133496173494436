// Ugh. {VERB}_{RESOURCE}_{EVENT}
export const FETCH_GLOBAL_DEFAULT_CONVERSATIONS_REQUEST =
  "FETCH_GLOBAL_DEFAULT_CONVERSATIONS_REQUEST";
export const FETCH_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS =
  "FETCH_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS";
export const FETCH_GLOBAL_DEFAULT_CONVERSATIONS_FAILURE =
  "FETCH_GLOBAL_DEFAULT_CONVERSATIONS_FAILURE";

export const CREATE_GLOBAL_DEFAULT_CONVERSATIONS_REQUEST =
  "CREATE_GLOBAL_DEFAULT_CONVERSATIONS_REQUEST";
export const CREATE_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS =
  "CREATE_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS";
export const CREATE_GLOBAL_DEFAULT_CONVERSATIONS_FAILURE =
  "CREATE_GLOBAL_DEFAULT_CONVERSATIONS_FAILURE";

export const ACTIVATE_GLOBAL_DEFAULT_CONVERSATION_REQUEST =
  "ACTIVATE_GLOBAL_DEFAULT_CONVERSATION_REQUEST";
export const ACTIVATE_GLOBAL_DEFAULT_CONVERSATION_SUCCESS =
  "ACTIVATE_GLOBAL_DEFAULT_CONVERSATION_SUCCESS";
export const ACTIVATE_GLOBAL_DEFAULT_CONVERSATION_FAILURE =
  "ACTIVATE_GLOBAL_DEFAULT_CONVERSATION_FAILURE";
