import React from "react";

const separatedSpan = content => (
  <span>
    {content}
    <br />
  </span>
);

export const showErrors = (errors, showError) => {
  showError(errors.map(separatedSpan), { autoDismiss: 0 });
};
