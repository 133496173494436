import React from "react";
import { ContentHeader } from "../../components/shared";
import { Link } from "react-router-dom";
import CopyToClipboard from "../../components/CopyToClipboard";
import { connect } from "react-redux"

const GlobalTriggerDetailContentHeader = ({
  duplicateTrigger,
  globalTrigger,
  onCopy,
  showDeleteModal,
  ...props
}) => (
  <ContentHeader
    left={<h1 className="page-title">{globalTrigger.name}</h1>}
    right={
      <div>
        {globalTrigger.deletable ? (
          <button className="paloma-button" onClick={showDeleteModal}>
            Delete
          </button>
        ) : (
          <span className="default-tag">Default</span>
        )}
        <Link
          className="paloma-button"
          to={`/global/${globalTrigger.triggerId}/edit`}
        >
          Edit details
        </Link>
        <button
          className="paloma-button"
          onClick={() => duplicateTrigger(globalTrigger.triggerId)}
        >
          Duplicate
        </button>
        <button className="paloma-button">
          <CopyToClipboard
            buttonText="Copy Shortlink"
            onSuccess={onCopy}
            toCopy={`https://www.messenger.com/t/${props.organizationInfo.facebookPageId}?ref=global_trigger_${globalTrigger.triggerId}`}
          />
        </button>
      </div>
    }
  />
);

const mapStateToProps = state => {
  return {
    organizationInfo: state.currentOrganization
  }
};

export default connect(
  mapStateToProps,
  {}
)(GlobalTriggerDetailContentHeader);
