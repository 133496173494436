import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/payments";

export const createPaymentSession = (return_url) => ({
  [CALL_API]: {
    body: { return_url },
    endpoint: "/api/payments/billing-session",
    method: "POST",
    types: [
      constants.CREATE_SESSION_REQUEST,
      constants.CREATE_SESSION_SUCCESS,
      constants.CREATE_SESSION_FAILURE,
    ],
  },
});