import React, { Component } from "react";

import { Modal } from "../shared";

export default class DeleteMatchingResponseModal extends Component {
  render() {
    const { onClose, title } = this.props;

    return (
      <Modal
        title={title ? title : "Delete matching response?"}
        isOpen={true}
        onClose={onClose}
      >
        <div className="modal__controls">
          <button
            type="button"
            className="paloma-button modal__button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="paloma-button modal__button modal__button--red"
            onClick={this.deleteMatchingResponse}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }

  deleteMatchingResponse = () => {
    const { id, onDelete, onClose } = this.props;

    onDelete(id);
    onClose();
  };
}
