export const PUT_PERSISTENT_MENU_REQUEST = "PUT_PERSISTENT_MENU_REQUEST";
export const PUT_PERSISTENT_MENU_SUCCESS = "PUT_PERSISTENT_MENU_SUCCESS";
export const PUT_PERSISTENT_MENU_FAILURE = "PUT_PERSISTENT_MENU_FAILURE";

export const FETCH_PERSISTENT_MENU_REQUEST = "FETCH_PERSISTENT_MENU_REQUEST";
export const FETCH_PERSISTENT_MENU_SUCCESS = "FETCH_PERSISTENT_MENU_SUCCESS";
export const FETCH_PERSISTENT_MENU_FAILURE = "FETCH_PERSISTENT_MENU_FAILURE";

export const FETCH_PERSISTENT_MENUS_REQUEST = "FETCH_PERSISTENT_MENUS_REQUEST";
export const FETCH_PERSISTENT_MENUS_SUCCESS = "FETCH_PERSISTENT_MENUS_SUCCESS";
export const FETCH_PERSISTENT_MENUS_FAILURE = "FETCH_PERSISTENT_MENUS_FAILURE";

export const PUBLISH_PERSISTENT_MENU_REQUEST =
  "PUBLISH_PERSISTENT_MENU_REQUEST";
export const PUBLISH_PERSISTENT_MENU_SUCCESS =
  "PUBLISH_PERSISTENT_MENU_SUCCESS";
export const PUBLISH_PERSISTENT_MENU_FAILURE =
  "PUBLISH_PERSISTENT_MENU_FAILURE";

export const CREATE_PERSISTENT_MENU_REQUEST = "CREATE_PERSISTENT_MENU_REQUEST";
export const CREATE_PERSISTENT_MENU_SUCCESS = "CREATE_PERSISTENT_MENU_SUCCESS";
export const CREATE_PERSISTENT_MENU_FAILURE = "CREATE_PERSISTENT_MENU_FAILURE";

export const ENABLE_PERSISTENT_MENU_REQUEST = "ENABLE_PERSISTENT_MENU_REQUEST";
export const ENABLE_PERSISTENT_MENU_SUCCESS = "ENABLE_PERSISTENT_MENU_SUCCESS";
export const ENABLE_PERSISTENT_MENU_FAILURE = "ENABLE_PERSISTENT_MENU_FAILURE";

export const DISABLE_PERSISTENT_MENU_REQUEST =
  "DISABLE_PERSISTENT_MENU_REQUEST";
export const DISABLE_PERSISTENT_MENU_SUCCESS =
  "DISABLE_PERSISTENT_MENU_SUCCESS";
export const DISABLE_PERSISTENT_MENU_FAILURE =
  "DISABLE_PERSISTENT_MENU_FAILURE";
