import React from "react";
import classNames from "classnames";

import { Modal } from "../shared";

export default class SetResponseAsFieldModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { newField: "", selected: props.selected || "" };
  }

  handleClose = () => {
    this.props.onClose();
  };

  selectField = id => {
    this.setState({ selected: id });
  };

  insertField = (e, id) => {
    this.props.handleSetResponseAsField(this.state.selected);
  };

  updateNewFieldName = e => this.setState({ newField: e.target.value });

  createField = async e => {
    e.preventDefault();

    const response = await this.props.putOrganizationField({
      name: this.state.newField,
    });

    this.setState({ newField: "" });

    await this.props.handleSetResponseAsField(
      response.payload.organizationFieldId
    );
  };

  fieldOptionClassNames = field => {
    return {
      "paloma-button": true,
      selected: this.state.selected === field.organizationFieldId,
    };
  };

  fieldOption = field => {
    return (
      <div
        key={field.organizationFieldId}
        className={classNames(this.fieldOptionClassNames(field))}
        onClick={() => this.selectField(field.organizationFieldId)}
      >
        {field.name}
        {this.validation(field)}
      </div>
    );
  };

  validation = field => {
    if (field.validations.length > 0) {
      return <span style={{ float: "right" }}> Validation &#10003;</span>;
    }
  };

  render() {
    return (
      <div className="insert-field-modal">
        <Modal
          title="Create or select a field"
          isOpen={this.props.isOpen}
          onClose={this.handleClose}
        >
          <div className="modal__top-input">
            <div className="input-with-inline-button">
              <form onSubmit={this.createField}>
                <input
                  className="modal__input--inline"
                  type="text"
                  name="entry"
                  value={this.state.newField}
                  onChange={this.updateNewFieldName}
                />
                <button
                  type="submit"
                  onClick={this.createField}
                  className="paloma-button paloma-button--pink modal__button"
                >
                  Create
                </button>
              </form>
            </div>
          </div>
          <div className="modal__fields">
            {this.props.organizationFields.map(this.fieldOption)}
          </div>
          <div className="modal__bottom">
            <button
              type="button"
              onClick={this.handleClose}
              className="paloma-button paloma-button--white modal__button"
            >
              Cancel
            </button>
            <button
              onClick={this.insertField}
              className="paloma-button paloma-button--pink modal__button"
            >
              Insert
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

SetResponseAsFieldModal.defaultProps = { organizationFields: [] };
