import createReducer from "../utilities/create-reducer";
import _ from "underscore";
import moment from "moment";

import * as constants from "../constants/global-default-conversations";

const initialState = { data: [], active: [], inactive: [], lastRanAt: null };

const active = c => c.active;
const inactive = c => !c.active;
const lastRanAt = c => moment(c.lastRanAt);

const activeFromConversations = conversations =>
  _.filter(conversations, active);
const inactiveFromConversations = conversations =>
  _.filter(conversations, inactive);
const maxLastRanAt = conversations => {
  if (conversations.length) {
    return _.max(conversations, lastRanAt).lastRanAt;
  }
};

export default createReducer(initialState, {
  [constants.FETCH_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      active: activeFromConversations(action.payload.data),
      inactive: inactiveFromConversations(action.payload.data),
      lastRanAt: maxLastRanAt(action.payload.data),
    };
  },
});
