import React from "react";
import { connect } from "react-redux";
import {
  createDynamicStep,
  createDynamicStepCriteria,
  deleteDynamicStep,
  deleteDynamicStepCriteria,
} from "../actions/dynamic-steps";
import xor from "lodash/xor";
import { 
  fetchConversationStep, 
  putConversationStep, 
} from "../actions/conversations";

import { 
  Toggle, 
} from "./shared";
import Criteria from "./ScenarioCriteria";

class ScenarioBody extends React.Component {
  state = {
    criterias: null,
  };

  static getDerivedStateFromProps(props, state) {
    const criterias = Object.entries(props.scenario.criteria);

    if (state.criterias === null && criterias.length) {
      return {
        criterias,
      };
    }

    if (state.criterias === null) {
      return {
        criterias: [],
      };
    }
    return null;
  }

  handleNewCriteria = () => {
    const { criterias } = this.state;
    const {
      scenario,
      step,
      createDynamicStepCriteria,
      fetchConversationStep,
    } = this.props;

    this.setState({ criterias: [...criterias, ["", ""]] }, () => {
      createDynamicStepCriteria(scenario.id, "", "", null).then(() =>
        fetchConversationStep(step.conversationStepId)
      );
    });
  };

  refreshStep = () =>
    this.props.fetchConversationStep(this.props.step.conversationStepId);

  handleCriteriaStep = (index, step) => {
    const { scenario, createDynamicStepCriteria } = this.props;
    const currStep = this.state.criterias[index][0] && null;

    const criterias = this.state.criterias.map((c, i) =>
      i !== index ? c : [step, c[1]]
    );

    this.setState({ criterias }, () => {
      const [stepId, resp] = this.state.criterias[index];
      createDynamicStepCriteria(scenario.id, stepId, resp, currStep).then(
        this.refreshStep
      );
    });
  };

  handleCriteriaResponse = (index, response) => {
    const { scenario, createDynamicStepCriteria } = this.props;
    const criterias = this.state.criterias.map((c, i) =>
      i !== index ? c : [c[0], response]
    );
    this.setState({ criterias }, () => {
      const [stepId, resp] = this.state.criterias[index];
      if (stepId.length) {
        createDynamicStepCriteria(scenario.id, stepId, resp).then(
          this.refreshStep
        );
      }
    });
  };

  handleCriteriaDelete = index => {
    const { scenario, deleteDynamicStepCriteria } = this.props;
    const criteria = this.state.criterias[index];

    const criterias = this.state.criterias.filter((c, i) => {
      return i !== index;
    });

    this.setState({ criterias }, () =>
      deleteDynamicStepCriteria(scenario.id, criteria[0]).then(this.refreshStep)
    );
  };

  handleDefaultToggle = isDefault => {
    const { step, scenario } = this.props
    const defaultChildStepId = isDefault ? scenario.childConversationStepId : null
    this.props.putConversationStep({ conversationStepId: step.conversationStepId, defaultChildStepId: defaultChildStepId })
  }

  render() {
    const { criterias } = this.state;
    const { step } = this.props;

    return (
      <div className="scenario-body">
        <div>
          <table>
            <tbody>
              <tr>
                <th>Step</th>
                <th>Response</th>
                <th />
              </tr>
              {criterias.map((c, i) => (
                <Criteria
                  key={i}
                  stepId={c[0]}
                  response={c[1]}
                  onStepChange={v => this.handleCriteriaStep(i, v)}
                  onResponseChange={v => this.handleCriteriaResponse(i, v)}
                  onDelete={() => this.handleCriteriaDelete(i)}
                  dynamicChoices={step.dynamicChoices}
                  dynamicSteps={step.dynamicSteps}
                  step={step}
                  selectedStepIds={criterias.map(s => s[0])}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <button
            className="paloma-button"
            onClick={this.handleNewCriteria}
            disabled={
              !criterias.every(c => !!c[0].length) ||
              xor(
                Object.keys(step.dynamicChoices),
                criterias.map(c => c[0])
              ).length === 0
            }
          >
            + Add
          </button>
          <div style={{ float: 'right', paddingTop: 10 }}>
            Default Scenario
            <Toggle
              className="manual-message__toggle"
              checked={this.props.isDefault}
              onLabel="ON"
              offLabel="OFF"
              onChange={this.handleDefaultToggle}
            />
          </div>
        </div>
      </div>
    );
  }
}

const Scenario = props => {
  const { editing, i } = props;
  return (
    <div className="scenario">
      <ScenarioHeader {...props} index={i + 1} />
      {editing && <ScenarioBody {...props} />}
    </div>
  );
};

export default connect(() => ({}), {
  createDynamicStep,
  createDynamicStepCriteria,
  deleteDynamicStep,
  deleteDynamicStepCriteria,
  fetchConversationStep,
  putConversationStep,
})(Scenario);

const ScenarioHeader = ({
  index,
  deleteDynamicStep,
  fetchConversationStep,
  selectScenario,
  copy,
  step,
  scenario,
  isDefault,
}) => (
  <div className="scenario-header">
    <div>Scenario {index}{isDefault ? " (Default)" : ""}</div>
    <div className="actions">
      <span
        onClick={async () => {
          await deleteDynamicStep(scenario.id);
          fetchConversationStep(step.conversationStepId);
        }}
      >
        Delete
      </span>
      <span onClick={() => selectScenario(scenario.id)}>Edit</span>
      <span
        onClick={async () => {
          copy(scenario.id);
        }}
      >
        Copy
      </span>
    </div>
  </div>
);
