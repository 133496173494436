export const FETCH_TASK_FAILURES_REQUEST = "FETCH_TASK_FAILURES_REQUEST";
export const FETCH_TASK_FAILURES_SUCCESS = "FETCH_TASK_FAILURES_SUCCESS";
export const FETCH_TASK_FAILURES_FAILURE = "FETCH_TASK_FAILURES_FAILURE";

export const RERUN_TASK_FAILURES_REQUEST = "RERUN_TASK_FAILURES_REQUEST";
export const RERUN_TASK_FAILURES_SUCCESS = "RERUN_TASK_FAILURES_SUCCESS";
export const RERUN_TASK_FAILURES_FAILURE = "RERUN_TASK_FAILURES_FAILURE";

export const DISMISS_TASK_FAILURES_REQUEST = "DISMISS_TASK_FAILURES_REQUEST";
export const DISMISS_TASK_FAILURES_SUCCESS = "DISMISS_TASK_FAILURES_SUCCESS";
export const DISMISS_TASK_FAILURES_FAILURE = "DISMISS_TASK_FAILURES_FAILURE";
