import React, { Component } from "react";

import {
  DynamicMessage,
  MediaMessage,
  TemplateMessage,
  TextMessage,
} from "../../components/ConversationDetail";
import QuickReplies from "../../components/QuickReplies";

const content = ({
  conversationStep,
  editable,
  handleFieldChange,
  uploadConversationStepMedia,
  refreshSteps,
}) => {
  switch (conversationStep.messageType) {
    case "text":
      return (
        <div className="message">
          <TextMessage
            conversationStep={conversationStep}
            editable={editable}
            handleFieldChange={handleFieldChange}
          />
        </div>
      );
    case "button":
      return (
        <div className="message">
          <TextMessage
            conversationStep={conversationStep}
            editable={editable}
            handleFieldChange={handleFieldChange}
          />
        </div>
      );
    case "image":
      return (
        <div className="message">
          <MediaMessage
            conversationStep={conversationStep}
            editable={editable}
            uploadConversationStepMedia={uploadConversationStepMedia}
          />
        </div>
      );
    case "template":
      return (
        <div className="message">
          <TemplateMessage
            editable={editable}
            errors={conversationStep.errors.templateCards}
            handleFieldChange={handleFieldChange}
            randomizeButtons={conversationStep.randomizeButtons}
            randomizeTemplateCards={conversationStep.randomizeTemplateCards}
            templateCards={conversationStep.templateCards}
            refreshSteps={refreshSteps}
          />
        </div>
      );
    case "dynamic":
      return <DynamicMessage conversationStep={conversationStep} />;
    default:
      return null;
  }
};

export default class ConversationStepMessage extends Component {
  render() {
    const { conversationStep, editable, refreshSteps } = this.props;

    return (
      <div>
        {content({
          conversationStep,
          editable,
          handleFieldChange: this.handleFieldChange,
          uploadConversationStepMedia: this.uploadConversationStepMedia,
          refreshSteps: refreshSteps,
        })}
        <QuickReplies
          quickReplies={conversationStep.quickReplies}
          lengthError={conversationStep.errors.conversationStepQuickReplies}
          errors={conversationStep.errors.quickReplies}
          random={conversationStep.randomizeQuickReplies}
          refreshSteps={refreshSteps}
          conversationStep={conversationStep}
        />
      </div>
    );
  }

  handleFieldChange = (...args) => {
    this.props.handleFieldChange &&
      this.props.handleFieldChange(this.props.conversationStep, ...args);
  };

  uploadConversationStepMedia = (file) => {
    const data = new FormData();
    data.append("file", file);
    this.props.uploadConversationStepMedia(data);
  };
}
