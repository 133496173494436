import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/custom-events";

export const createCustomEvent = (eventName, conversationStepId) => {
  return {
    [CALL_API]: {
      endpoint: `/api/conversation_step/${conversationStepId}/custom_events`,
      method: "POST",
      body: { eventName },
      types: [
        constants.CREATE_CUSTOM_EVENT_REQUEST,
        constants.CREATE_CUSTOM_EVENT_SUCCESS,
        constants.CREATE_CUSTOM_EVENT_FAILURE,
      ],
    },
  };
};
