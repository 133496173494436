import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { putOrganizationField } from "../actions/organization-fields";
import { Card, ContentHeader, Toggle } from "../components/shared";

class NewOrganizationField extends React.Component {
  state = {
    name: null,
    required: false,
  };

  handleOnSubmit = e => {
    e.preventDefault();

    this.props
      .putOrganizationField({
        ...this.state,
      })
      .then(({ payload }) =>
        this.props.push(`/fields/${payload.organizationFieldId}`)
      );
  };

  render() {
    return (
      <div>
        <Card>
          <ContentHeader left={<h2>Create a new field</h2>} />
          <form className="default-form" onSubmit={this.handleOnSubmit}>
            <label className="default-form__label" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              className="default-form__input"
              type="text"
              value={this.state.name ? this.state.name : ""}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <label
              className="default-form__label"
              htmlFor="required"
              style={{ marginBottom: "15px" }}
            >
              Required
            </label>
            <Toggle
              checked={this.state.required}
              onLabel="YES"
              offLabel="NO"
              onChange={required => this.setState({ required })}
              className="default-form__input"
              id="required"
              style={{ margin: "0 8px" }}
            />
            <div>
              <button
                className="default-form__submit"
                type="submit"
                disabled={!this.state.name}
              >
                Create
              </button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

export default connect(
  null,
  { putOrganizationField, push }
)(NewOrganizationField);
