import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import GlobalTriggerToggle from "../../components/GlobalTriggerDetail/GlobalTriggerToggle";
import GlobalTriggerAssignSubscriberOwner from "../../components/GlobalTriggerDetail/GlobalTriggerAssignSubscriberOwner";
import {
  ContentHeader,
  ConversationsTable,
  Card,
} from "../../components/shared";
import { fetchGlobalDefaultConversations } from "../../actions/global-default-conversations";
import {
  createAssignSubscriberOwnerGlobalDefaultTrigger,
  createManualMessagingOnGlobalDefaultTrigger,
  createThreadControlPassOnGlobalDefaultTrigger,
  deleteAssignSubscriberOwnerGlobalDefaultTrigger,
  deleteManualMessagingOnGlobalDefaultTrigger,
  deleteThreadControlPassOnGlobalDefaultTrigger,
  fetchGlobalDefaultTrigger,
} from "../../actions/global-default-trigger";

class GlobalDefaultConversations extends Component {
  componentDidMount() {
    this.props.fetchGlobalDefaultConversations();
    this.props.fetchGlobalDefaultTrigger();
  }

  render() {
    const {
      manualMessagingActivation,
      threadControlPass,
      assignSubscriberOwner,
    } = this.props;
    return (
      <div>
        <ContentHeader left={<h1 className="page-title">Unexpected</h1>} />
        <Card className="info-panel">
          <GlobalTriggerToggle
            onToggle={this.updateGlobalTriggerManualMessagingActivation}
            isToggled={manualMessagingActivation}
            title="Turn on Live Chat"
            bodyText="I would like live chat to be turned on when someone sends a matching response to this trigger"
          />
        </Card>
        <Card className="info-panel">
          <GlobalTriggerToggle
            onToggle={this.updateGlobalTriggerThreadControlPass}
            isToggled={threadControlPass}
            title="Pass Control to Another Application"
            bodyText="I would like to pass control to another application when someone sends a matching response to this trigger"
          />
        </Card>
        <Card className="info-panel">
          <GlobalTriggerAssignSubscriberOwner
            onCreate={this.updateGlobalTriggerAssignSubscriberOwner}
            onRemove={this.updateGlobalTriggerAssignSubscriberOwner}
            assignSubscriberOwner={assignSubscriberOwner}
          />
        </Card>
        <Card>
          <ContentHeader
            left={<h3 style={{ paddingBottom: "13px" }}>Conversations</h3>}
            right={
              <Link
                className="paloma-button--pink"
                to="/global/unexpected/conversations/new"
              >
                + New
              </Link>
            }
          />
          <ConversationsTable
            conversations={this.props.active}
            title="Active"
            showDelete={false}
          />
          <ConversationsTable
            conversations={this.props.inactive}
            title="Inactive"
          />
        </Card>
      </div>
    );
  }

  updateGlobalTriggerAssignSubscriberOwner = ownerId => {
    const { assignSubscriberOwner } = this.props;
    if (!assignSubscriberOwner && ownerId) {
      return this.props.createAssignSubscriberOwnerGlobalDefaultTrigger(
        ownerId
      );
    } else {
      return this.props.deleteAssignSubscriberOwnerGlobalDefaultTrigger();
    }
  };

  updateGlobalTriggerManualMessagingActivation = () => {
    const { manualMessagingActivation } = this.props;
    if (!manualMessagingActivation) {
      this.props.createManualMessagingOnGlobalDefaultTrigger();
    } else {
      this.props.deleteManualMessagingOnGlobalDefaultTrigger();
    }
  };

  updateGlobalTriggerThreadControlPass = () => {
    const { threadControlPass } = this.props;
    if (!!threadControlPass) {
      this.props.deleteThreadControlPassOnGlobalDefaultTrigger();
    } else {
      this.props.createThreadControlPassOnGlobalDefaultTrigger();
    }
  };
}

const mapStateToProps = state => ({
  active: state.globalDefaultConversations.active,
  inactive: state.globalDefaultConversations.inactive,
  trigger: state.globalDefaultTrigger,
  manualMessagingActivation: state.globalDefaultTrigger.actions.includes(
    "manual_messaging_activation"
  ),
  threadControlPass: state.globalDefaultTrigger.actions.includes(
    "thread_control_pass"
  ),
  assignSubscriberOwner:
    state.globalDefaultTrigger.actions.includes("assign_subscriber_owner") &&
    state.globalDefaultTrigger.ownerId
      ? { ownerId: state.globalDefaultTrigger.ownerId }
      : false,
});

export default connect(
  mapStateToProps,
  {
    createAssignSubscriberOwnerGlobalDefaultTrigger,
    createManualMessagingOnGlobalDefaultTrigger,
    createThreadControlPassOnGlobalDefaultTrigger,
    deleteAssignSubscriberOwnerGlobalDefaultTrigger,
    deleteManualMessagingOnGlobalDefaultTrigger,
    deleteThreadControlPassOnGlobalDefaultTrigger,
    fetchGlobalDefaultConversations,
    fetchGlobalDefaultTrigger,
  }
)(GlobalDefaultConversations);
