import React from "react";
import classNames from "classnames";

const PaginationBlock = ({ content, select, current }) => {
  let className = "paloma-button table__pagination-page-button";
  if (current) {
    className =
      className + " paloma-button table__pagination-page-button--current";
  }
  return (
    <div onClick={() => select && select(content)} className={className}>
      {content}
    </div>
  );
};

const PreviousButton = ({ total, current, select }) =>
  current > 1 && (
    <div
      className="paloma-button table__pagination-arrow-button"
      onClick={select.bind(this, current - 1)}
    >
      &lt; Previous
    </div>
  );
const NextButton = ({ total, current, select }) =>
  total > current && (
    <div
      className="paloma-button table__pagination-arrow-button"
      onClick={select.bind(this, current + 1)}
    >
      Next &gt;
    </div>
  );
const First = ({ current, select }) =>
  current > 3 && <PaginationBlock content={1} select={select} />;
const BeforePrevious = ({ current, select }) =>
  current > 2 && <PaginationBlock content={current - 2} select={select} />;
const Previous = ({ current, select }) =>
  current > 1 && <PaginationBlock content={current - 1} select={select} />;
const BeforeEllipse = ({ current }) =>
  current > 4 && <span className="table__pagination-ellipsis">...</span>;
const Current = ({ current }) => (
  <PaginationBlock current={true} content={current} />
);
const Next = ({ current, total, select }) =>
  total - current > 1 && (
    <PaginationBlock content={current + 1} select={select} />
  );
const AfterNext = ({ current, total, select }) =>
  total - current > 2 && (
    <PaginationBlock content={current + 2} select={select} />
  );
const AfterEllipse = ({ current, total }) =>
  total - current > 3 && (
    <span className="table__pagination-ellipsis">...</span>
  );
const Last = ({ current, total, select }) =>
  total !== current && <PaginationBlock content={total} select={select} />;

const Pagination = props => {
  if (props.total <= 1) {
    return <div />;
  }
  return (
    <div className="table__pagination-controls">
      <PreviousButton {...props} />
      {!props.hidePageNumbers && (
        <React.Fragment>
          <First {...props} />
          <BeforeEllipse {...props} />
          <BeforePrevious {...props} />
          <Previous {...props} />
          <Current {...props} />
          <Next {...props} />
          <AfterNext {...props} />
          <AfterEllipse {...props} />
          <Last {...props} />
        </React.Fragment>
      )}
      <NextButton {...props} />
    </div>
  );
};

class PaginatedList extends React.Component {
  static defaultProps = {
    currentPage: 1,
    items: [],
    perPage: 20,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      displayStart: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.currentPage &&
      nextProps.currentPage !== prevState.currentPage
    ) {
      return {
        ...prevState,
        currentPage: nextProps.currentPage,
      };
    } else {
      return null;
    }
  }

  total = (items, perPage) => {
    return Math.ceil(items / perPage);
  };

  onChoosePage = page => {
    if (this.props.onChoosePage) {
      return this.props.onChoosePage(page);
    } else {
      this.setState({
        currentPage: page,
        displayStart: (page - 1) * this.props.perPage,
      });
    }
  };

  render = () => {
    const total =
      this.props.totalPages ||
      this.total(this.props.items.length, this.props.perPage);
    return (
      <div>
        {(this.props.items === undefined || this.props.items == 0) ? (
          <div style={{ display: "flex", justifyContent: "center", margin: "15% auto"}}>
            <p>{this.props.emptyMessage}</p>
          </div>
        ) : (
            <table className={classNames("table", this.props.className)}>
              <tbody>
                <tr>
                  {this.props.headers.map((header, i) => (
                    <th key={i} className="table__header" style={header == "" ? {width:"190px"} : {}}>
                      {header}
                    </th>
                  ))}
                </tr>
                {this.props.items
                  .slice(
                    this.state.displayStart,
                    this.state.displayStart + this.props.perPage
                  )
                  .map(this.props.component)}
              </tbody>
            </table>
          )}
        <Pagination
          hidePageNumbers={this.props.hidePageNumbers}
          current={this.state.currentPage}
          total={total}
          select={this.onChoosePage}
        />
      </div>
    );
  };
}

PaginatedList.defaultProps = {
  headers: [],
  perPage: 20,
};

export default PaginatedList;
