import React from "react";
import { connect } from "react-redux";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";

import { Toggle, Dropdown, SelectGlobalTriggerModal } from "../shared";
import SendStepModal from "./SendStepModal";
import { removeHTMLEntities } from "../../utilities/dom";
import Notice from "../shared/Notice";
import SelectProductModal from "../SelectProductModal";
import { sendSubscriberProducts, sendSubscriberTrigger, sendSubscriberConversationStep } from "../../actions/subscriber-conversation-steps";
import Tooltip from "rc-tooltip";

const ENTER_KEY_KEY_CODE = 13;

class ManualMessageForm extends React.Component {
  state = {
    html: "",
    sendStepModal: false,
    sendProductsModal: false,
    customPayloadModal: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.send();
  };

  handleChange = e => {
    let sanitized = sanitizeHtml(e.target.value, { allowedTags: [] });

    this.setState({ html: removeHTMLEntities(sanitized) });
  };

  sendIfEnter = e => {
    if (e.keyCode === ENTER_KEY_KEY_CODE) {
      this.send();
    }
  };

  send = () => {
    if (this.state.html.length > 0) {
      this.props.onSendMessage(this.state.html);
      this.setState({ html: "" });
    }
  };

  customPayloadSubmit = async (triggerId) => {
    this.props.sendSubscriberTrigger(this.props.subscriberId, triggerId);
  };

  sendSubscriberConversationStepSubmit = async (conversationStepId) => {
    this.props.sendSubscriberConversationStep(this.props.subscriberId, conversationStepId);
  };

  inventorySendSubmit = async (productIds) => {
    this.props.sendSubscriberProducts(this.props.subscriberId, productIds);
  }

  render() {
    return (
      <div>
        <form className="manual-message__form" onSubmit={this.handleSubmit}>
          <ContentEditable
            className="manual-message__input"
            html={this.state.html}
            onChange={this.handleChange}
            disabled={this.props.disabled}
            onKeyUp={this.sendIfEnter}
          />
          <div className="manual-message__controls">
            <Dropdown
              direction="up"
              style={{ margin: 0 }}
              buttonText="Send Template"
              dropdownOptions={[
                {
                  text: "Step ID",
                  onClick: () => this.setState({ sendStepModal: true }),
                },
                {
                  text: "Trigger",
                  onClick: () => this.setState({ customPayloadModal: true }),
                },
                {
                  text: "Inventory",
                  onClick: () => this.setState({ sendProductsModal: true }),
                },
              ]}
            />
            <button
              className="paloma-button--pink manual-message__send"
              type="submit"
              disabled={this.props.disabled || this.state.html.length === 0}
            >
              Send Message
            </button>
          </div>
        </form>
        <SelectProductModal
          onClose={() => this.setState({ sendProductsModal: false })}
          isOpen={this.state.sendProductsModal}
          saveButtonText="Send"
          onInsert={this.inventorySendSubmit}
        />
        <SelectGlobalTriggerModal
          isOpen={this.state.customPayloadModal}
          onClose={() => this.setState({ customPayloadModal: false })}
          onSave={this.customPayloadSubmit}
        />
        <SendStepModal
          handleSubmit={this.sendSubscriberConversationStepSubmit}
          onClose={() => this.setState({ sendStepModal: false })}
          isOpen={this.state.sendStepModal}
        />
      </div>
    );
  }
}

export default connect(null, { sendSubscriberProducts, sendSubscriberTrigger, sendSubscriberConversationStep })(
  ({
    subscriberId,
    manualMessagingDisabled,
    onSendMessage,
    onToggleManualMessaging,
    onToggleStandby,
    sendSubscriberConversationStep,
    standby,
    unsubscribed,
    status,
    sendSubscriberProducts,
    sendSubscriberTrigger,
  }) => {
    return (
      <div className="manual-message">
        {status === "inactive" && (
          <div className="status-message">
            <Tooltip
              overlayClassName="manual-message-tooltip"
              placement="top"
              trigger={["hover"]}
              overlay={
                <span>
                  This subscriber is inactive and cannot be re-engaged except
                  through the native messenger.
                </span>
              }
            >
              <span>Inactive</span>
            </Tooltip>
          </div>
        )}
        {unsubscribed && (
          <div className="status-message">
            <Tooltip
              overlayClassName="manual-message-tooltip"
              placement="top"
              trigger={["hover"]}
              overlay={
                <span>
                  This subscriber has turned messaging off for your page.
                </span>
              }
            >
              <span>Unsubscribed</span>
            </Tooltip>
          </div>
        )}
        {!unsubscribed && status !== "inactive" && (
          <div>
            <div className="toggles">
              <div className="toggle-container">
                <Tooltip
                  overlayClassName="manual-message-tooltip"
                  placement="top"
                  trigger={["hover"]}
                  overlay={
                    <span>
                      Standby allows this subscriber's conversation to be
                      managed by the Page Inbox. With standby on they will not
                      receive messages from Paloma.
                    </span>
                  }
                >
                  <span>Standby</span>
                </Tooltip>

                <Toggle
                  className="manual-message__toggle standby"
                  checked={!!standby}
                  onLabel="ON"
                  offLabel="OFF"
                  onChange={onToggleStandby}
                />
              </div>
              <div className="toggle-container">
                <Tooltip
                  overlayClassName="manual-message-tooltip"
                  placement="top"
                  trigger={["hover"]}
                  overlay={
                    <span>
                      Live chat allows this subscriber to receive manual
                      messages. With live chat on they will not receive
                      automated messages from Paloma.
                    </span>
                  }
                >
                  <span>Live chat</span>
                </Tooltip>
                <Toggle
                  className="manual-message__toggle"
                  checked={!manualMessagingDisabled}
                  onLabel="ON"
                  offLabel="OFF"
                  onChange={onToggleManualMessaging}
                />
              </div>
            </div>
          </div>
        )}
        {status === "active (+1)" && !manualMessagingDisabled && (
          <Notice
            className="notice--compact"
            style={{ margin: "12px 10px" }}
            dismissible={false}
          >
            This person is outside of the 24 hour window. You can send them{" "}
            <strong>one</strong> message to re-engage them. If they don't
            respond, you'll only be able to message them through the native
            messenger inbox. Ask a question to get a response, so you can keep
            messaging them!
          </Notice>
        )}
        {!unsubscribed && status !== "inactive" && (
          <ManualMessageForm
            subscriberId={subscriberId}
            onSendMessage={onSendMessage}
            disabled={manualMessagingDisabled || unsubscribed}
            sendSubscriberConversationStep={sendSubscriberConversationStep}
            sendSubscriberProducts={sendSubscriberProducts}
            sendSubscriberTrigger={sendSubscriberTrigger}
          />
        )}
      </div>
    );
  }
);
