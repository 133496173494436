import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { Editor } from "slate-react";

import {
  BuilderInput,
  SetResponseAsFieldModal,
} from "../../components/ConversationDetail";

import {
  fetchOrganizationFields,
  putOrganizationField,
} from "../../actions/organization-fields";
import { createEditorState } from "../../utilities/editor";
import { putConversationStep } from "../../actions/conversations";

class UserTriggerResponse extends Component {
  static propTypes = {
    conversationStep: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
  };

  state = {
    displayFieldModal: false,
    editorState: createEditorState(""),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.conversationStep.organizationField) {
      const {
        conversationStep: {
          organizationField: { name: fieldType },
        },
      } = nextProps;

      const emptyEditorState = createEditorState("");
      const change = emptyEditorState.change();

      change.insertInline({
        type: "field",
        isVoid: true,
        data: { fieldType },
      });

      this.setState({ editorState: change.state });
    } else if (nextProps.trigger.type === "feedback") {
      this.setState({ editorState: createEditorState("Feedback") });
    }
  }

  componentWillMount() {
    this.props.fetchOrganizationFields();
  }

  render() {
    const {
      trigger: { matchingResponses = [""], type },
      organizationFields,
      putOrganizationField,
    } = this.props;

    const { displayFieldModal, editorState } = this.state;

    const userResponseClassName = classNames(
      "conversation-step__user-response"
    );
    if (this.props.conversationStep.organizationField) {
      return (
        <div className="conversation-step__user-response-container">
          <div
            className={userResponseClassName}
            onClick={this.openSetResponseAsFieldModal}
          >
            <form>
              <BuilderInput
                className="conversation-step__user-response-input"
                editorState={editorState}
                editable={false}
              />
            </form>
            {extractTextFromState(matchingResponses[0])}
          </div>
          {displayFieldModal && (
            <SetResponseAsFieldModal
              handleSetResponseAsField={this.handleSetResponseAsField}
              putOrganizationField={putOrganizationField}
              isOpen={true}
              organizationFields={organizationFields}
              onClose={this.hideFieldModal}
            />
          )}
        </div>
      );
    } else if (type === "feedback") {
      return (
        <div className="conversation-step__user-response-container">
          <div className={userResponseClassName}>
            <form>
              <BuilderInput
                className="conversation-step__user-response-input"
                editorState={createEditorState("Feedback")}
                editable={false}
              />
            </form>
            {extractTextFromState(matchingResponses[0])}
          </div>
          {displayFieldModal && (
            <SetResponseAsFieldModal
              handleSetResponseAsField={this.handleSetResponseAsField}
              putOrganizationField={putOrganizationField}
              isOpen={true}
              organizationFields={organizationFields}
              onClose={this.hideFieldModal}
            />
          )}
        </div>
      );
    }
    return (
      <div className="conversation-step__user-response-container">
        <div className="conversation-step__user-response">
          <Editor
            readOnly={true}
            state={createEditorState(matchingResponses[0])}
          />
        </div>
        {displayFieldModal && (
          <SetResponseAsFieldModal
            handleSetResponseAsField={this.handleSetResponseAsField}
            putOrganizationField={putOrganizationField}
            isOpen={true}
            organizationFields={organizationFields}
            onClose={this.hideFieldModal}
          />
        )}
      </div>
    );
  }

  openSetResponseAsFieldModal = async () => {
    this.showFieldModal();
  };

  handleSetResponseAsField = async organizationFieldId => {
    const {
      conversationStep,
      organizationFields,
      putConversationStep,
    } = this.props;

    const organizationField = organizationFields.find(
      organizationField =>
        organizationField.organizationFieldId === organizationFieldId
    );

    if (!conversationStep)
      throw new Error("Cannot add user response without conversation step");
    if (!organizationField) throw new Error("Invalid organization field");

    this.hideFieldModal();

    putConversationStep({
      ...conversationStep,
      organizationFieldId,
    });
  };

  showFieldModal = () => this.setState({ displayFieldModal: true });
  hideFieldModal = () => this.setState({ displayFieldModal: false });
}

const mapStateToProps = (state, ownProps) => ({
  organizationFields: state.organizationFields.organizationFields,
});

const extractTextFromState = (text = "") => {
  if (text === "") {
    return text;
  }
  return JSON.parse(text).document.nodes[0].nodes[0].leaves[0].text;
};

export default connect(
  mapStateToProps,
  {
    putOrganizationField,
    putConversationStep,
    fetchOrganizationFields,
  }
)(UserTriggerResponse);
