import React from "react";
import { Link } from "react-router-dom";

import { getConversationLink } from "../../utilities/conversation";
import { status } from "../../utilities/conversation";
import { formatDate } from "../../utilities/format-date";

import ConversationsTableRowButtons from "./ConversationTableRowButtons";
import PaginatedList from "../PaginatedList";

const ConversationRow = ({ conversation, onDuplicate }) => {
  return (
    <tr>
      <td className="table__data">
        <Link
          className="table__link"
          to={getConversationLink(conversation) + "/details"}
        >
          {conversation.label}
        </Link>
      </td>
      <td className="table__data">{conversation.numSubscribers || 0}</td>
      <td className="table__data">
        {conversation.lastRanAt ? formatDate(conversation.lastRanAt) : "Never"}
      </td>
      <td className="table__data">{status(conversation)}</td>
      <td className="table__data">
        {conversation.createdAt ? formatDate(conversation.createdAt) : ""}
      </td>
      <td className="table__data">
      {conversation.publishedAt ? formatDate(conversation.publishedAt) : "Never"}
      </td>
      <ConversationsTableRowButtons
        conversation={conversation}
        onDuplicate={onDuplicate}
        showDelete
      />
    </tr>
  );
};

export const PaginatedConversationsTable = (props) => {
  const { page, pages, onChoosePage, conversations, title, perPage } = props;
  const titleHeader = title || "Name";

  return (
    <PaginatedList
      headers={[titleHeader, "Recipients", "Last Ran", "Status", "Created", "Published", ""]}
      items={conversations || []}
      component={(c) => (
        <ConversationRow conversation={c} onDuplicate={props.onDuplicate} />
      )}
      onChoosePage={onChoosePage}
      currentPage={page}
      totalPages={pages}
      perPage={perPage}
    />
  );
};

export default PaginatedConversationsTable;
