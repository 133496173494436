import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/persistent-menu";

export const putPersistentMenu = params => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu",
    method: "PUT",
    body: params,
    types: [
      constants.PUT_PERSISTENT_MENU_REQUEST,
      constants.PUT_PERSISTENT_MENU_SUCCESS,
      constants.PUT_PERSISTENT_MENU_FAILURE,
    ],
  },
});

export const enablePersistentMenu = () => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu_activities/",
    method: "POST",
    types: [
      constants.ENABLE_PERSISTENT_MENU_REQUEST,
      constants.ENABLE_PERSISTENT_MENU_SUCCESS,
      constants.ENABLE_PERSISTENT_MENU_FAILURE,
    ],
  },
});

export const disablePersistentMenu = () => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu_activities/",
    method: "DELETE",
    types: [
      constants.DISABLE_PERSISTENT_MENU_REQUEST,
      constants.DISABLE_PERSISTENT_MENU_SUCCESS,
      constants.DISABLE_PERSISTENT_MENU_FAILURE,
    ],
  },
});

export const publishPersistentMenu = () => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu/publish",
    method: "POST",
    types: [
      constants.PUBLISH_PERSISTENT_MENU_REQUEST,
      constants.PUBLISH_PERSISTENT_MENU_SUCCESS,
      constants.PUBLISH_PERSISTENT_MENU_FAILURE,
    ],
  },
});

export const fetchPersistentMenu = () => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu",
    method: "GET",
    types: [
      constants.FETCH_PERSISTENT_MENU_REQUEST,
      constants.FETCH_PERSISTENT_MENU_SUCCESS,
      constants.FETCH_PERSISTENT_MENU_FAILURE,
    ],
  },
});

export const fetchPersistentMenus = () => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu",
    method: "GET",
    types: [
      constants.FETCH_PERSISTENT_MENUS_REQUEST,
      constants.FETCH_PERSISTENT_MENUS_SUCCESS,
      constants.FETCH_PERSISTENT_MENUS_FAILURE,
    ],
  },
});

export const createPersistentMenu = () => ({
  [CALL_API]: {
    endpoint: "/api/persistent_menu",
    method: "POST",
    types: [
      constants.CREATE_PERSISTENT_MENU_REQUEST,
      constants.CREATE_PERSISTENT_MENU_SUCCESS,
      constants.CREATE_PERSISTENT_MENU_FAILURE,
    ],
  },
});
