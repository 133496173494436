import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader";
import { ContentHeader } from "../../components/shared";
import { push } from "react-router-redux";
import { EditConversationDetailsForm } from "../../components/ConversationDetail";
import {
  fetchConversation,
  putConversation,
} from "../../actions/conversations";

class EditWelcomeMessageConversation extends React.Component {
  componentDidMount() {
    const { conversationId } = this.props;

    this.props.fetchConversation(conversationId);
  }

  editConversation = (e) => {
    e.preventDefault();

    const {
      conversationId,
      conversation: { type },
      putConversation,
      welcomeMessageId,
    } = this.props;

    const {
      label: { value: label },
      utm: { value: utm },
    } = e.currentTarget;

    putConversation({
      conversation_id: conversationId,
      type,
      label: label.trim(),
      utm: utm.trim(),
    }).then(() =>
      this.props.push(
        this.props.push(
          `/entrypoints/wm/${welcomeMessageId}/conversations/${conversationId}/details`
        )
      )
    );
  };

  render() {
    const { conversation, conversationId } = this.props;

    return (
      <Loader
        loaded={!conversation.isLoading}
        loadedClassName="conversation-detail"
      >
        <ContentHeader left={<h1>Edit details</h1>} right="" />
        <EditConversationDetailsForm
          conversationId={conversationId}
          conversationName={conversation.label}
          utm={conversation.utm}
          onSubmit={this.editConversation}
        />
      </Loader>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { conversationId, welcomeMessageId },
    },
  } = props;

  const conversation = {
    isLoading: true,
    ...state.conversations.conversationsById[conversationId],
  };

  return {
    conversation,
    conversationId,
    welcomeMessageId,
  };
};

export default connect(mapStateToProps, {
  fetchConversation,
  push,
  putConversation,
})(EditWelcomeMessageConversation);
