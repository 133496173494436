import { byId } from "../utilities/reducers";
import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/organization-fields";

const initialState = { byId: {}, organizationFields: [] };

export default createReducer(initialState, {
  [constants.PUT_ORGANIZATION_FIELD_SUCCESS](state, action) {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...byId(action.payload.organizationFields, "organizationFieldId"),
      },
      organizationFields: action.payload.organizationFields,
    };
  },

  [constants.FETCH_ORGANIZATION_FIELDS_REQUEST](state, action) {
    return state;
  },

  [constants.FETCH_ORGANIZATION_FIELDS_SUCCESS](state, action) {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...byId(action.payload, "organizationFieldId"),
      },
      organizationFields: action.payload,
    };
  },

  [constants.FETCH_ORGANIZATION_FIELDS_FAILURE](state, action) {
    return state;
  },

  [constants.PUT_ORGANIZATION_FIELDS_REQUIRED_SUCCESS](state, action) {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...byId(action.payload, "organizationFieldId"),
      },
      organizationFields: action.payload,
    };
  },

  [constants.DELETE_ORGANIZATION_FIELD_SUCCESS](state, action) {
    const organizationFields = state.organizationFields.filter(
      ({ organizationFieldId }) => organizationFieldId !== action.meta.id
    );

    return {
      ...state,
      byId: { ...byId(organizationFields, "organizationFieldId") },
      organizationFields,
    };
  },
});
