import React from "react";
import { Link } from "react-router-dom";
import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/conversations";

import { showSuccessAlert } from "./alerts";
import { getConversationLink } from "../utilities/conversation";

export const fetchConversations = () => ({
  [CALL_API]: {
    endpoint: "/api/conversations/",
    method: "GET",
    types: [
      constants.FETCH_CONVERSATIONS_REQUEST,
      constants.FETCH_CONVERSATIONS_SUCCESS,
      constants.FETCH_CONVERSATIONS_FAILURE,
    ],
  },
});

export const fetchConversationsLabel = () => ({
  [CALL_API]: {
    endpoint: "/api/conversations/?labels_only=true",
    method: "GET",
    types: [
      "FETCH_CONVERSATION_LABELS_REQUEST",
      "FETCH_CONVERSATION_LABELS_SUCCESS",
      "FETCH_CONVERSATION_LABELS_FAILURE",
    ],
  },
});

export const fetchConversation = (id) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_CONVERSATION_REQUEST,
        meta: { id: id },
      },
      constants.FETCH_CONVERSATION_SUCCESS,
      constants.FETCH_CONVERSATION_FAILURE,
    ],
  },
});

export const fetchConversationDetail = (id) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/detail`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_CONVERSATION_DETAIL_REQUEST,
        meta: { id: id },
      },
      constants.FETCH_CONVERSATION_DETAIL_SUCCESS,
      constants.FETCH_CONVERSATION_DETAIL_FAILURE,
    ],
  },
});

export const putConversation = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/`,
    method: "PUT",
    body: JSON.stringify({ conversation: payload }),
    types: [
      {
        type: constants.PUT_CONVERSATION_REQUEST,
        meta: { id: payload.conversationId },
      },
      constants.PUT_CONVERSATION_SUCCESS,
      constants.PUT_CONVERSATION_FAILURE,
    ],
  },
});

export const deleteConversation = (id) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/`,
    method: "DELETE",
    types: [
      {
        type: constants.DELETE_CONVERSATION_REQUEST,
        meta: { id: id },
      },
      {
        type: constants.DELETE_CONVERSATION_SUCCESS,
        meta: { id: id },
      },
      {
        type: constants.DELETE_CONVERSATION_FAILURE,
        meta: { id: id },
      },
    ],
  },
});

// everything below this line doesn't actually belong in this file and should be
// split out at some point.

export const createConversationStep = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${payload.conversationStepId}`,
    method: "POST",
    body: JSON.stringify({ conversationStep: payload }),
    types: [
      {
        type: constants.CREATE_CONVERSATION_STEP_REQUEST,
        meta: payload.conversationStepId,
      },
      constants.CREATE_CONVERSATION_STEP_SUCCESS,
      {
        type: constants.CREATE_CONVERSATION_STEP_FAILURE,
        meta: payload.conversationStepId,
      },
    ],
  },
});

export const putConversationStep = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${payload.conversationStepId}`,
    method: "PUT",
    body: JSON.stringify({ conversationStep: payload }),
    types: [
      {
        type: constants.PUT_CONVERSATION_STEP_REQUEST,
        meta: payload.conversationStepId,
      },
      constants.PUT_CONVERSATION_STEP_SUCCESS,
      {
        type: constants.PUT_CONVERSATION_STEP_FAILURE,
        meta: payload.conversationStepId,
      },
    ],
  },
});

export const fetchConversationStep = (stepId) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${stepId}`,
    method: "GET",
    types: [
      constants.FETCH_CONVERSATION_STEP_REQUEST,
      constants.FETCH_CONVERSATION_STEP_SUCCESS,
      constants.FETCH_CONVERSATION_STEP_FAILURE,
    ],
  },
});

export const fetchVisibleConversationSteps = (conversationId) => async (
  dispatch,
  getState
) => {
  const state = getState();

  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversations/${conversationId}/visible_conversation_steps`,
      method: "POST",
      body: JSON.stringify({
        triggerChoices: state.conversations.triggerChoicesByConversationStepId,
      }),
      types: [
        {
          type: constants.FETCH_VISIBLE_CONVERSATION_STEPS_REQUEST,
          meta: conversationId ? { conversationId: conversationId } : {},
        },
        {
          type: constants.FETCH_VISIBLE_CONVERSATION_STEPS_SUCCESS,
          meta: conversationId ? { conversationId: conversationId } : {},
        },
        {
          type: constants.FETCH_VISIBLE_CONVERSATION_STEPS_FAILURE,
          meta: conversationId ? { conversationId: conversationId } : {},
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const fetchVisibleConversationStepsForMenu = (conversationId) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${conversationId}/visible_conversation_steps`,
    method: "POST",
    body: {
      triggerChoices: {},
    },
    types: [
      constants.FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_REQUEST,
      constants.FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_SUCCESS,
      constants.FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_FAILURE,
    ],
  },
});

export const fetchVisibleConversationStepsFromStep = (
  firstStep,
  conversationId
) => async (dispatch, getState) => {
  const state = getState();
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/visible_steps`,
      method: "POST",
      body: JSON.stringify({
        firstStep: firstStep,
        triggerChoices: state.conversations.triggerChoicesByConversationStepId,
      }),
      types: [
        {
          type: constants.FETCH_VISIBLE_CONVERSATION_STEPS_REQUEST,
          meta: { conversationId },
        },
        {
          type: constants.FETCH_VISIBLE_CONVERSATION_STEPS_SUCCESS,
          meta: { conversationId },
        },
        {
          type: constants.FETCH_VISIBLE_CONVERSATION_STEPS_FAILURE,
          meta: { conversationId },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const newConversationStep = (params) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${params.conversationId}/steps`,
    method: "POST",
    body: JSON.stringify({
      previousStepId: params.previousStepId,
      messageType: params.messageType,
      triggerId: params.triggerId,
    }),
    types: [
      {
        type: constants.CREATE_CONVERSATION_STEP_REQUEST,
        meta: { conversationId: params.conversationId },
      },
      {
        type: constants.CREATE_CONVERSATION_STEP_SUCCESS,
        meta: { conversationId: params.conversationId },
      },
      {
        type: constants.CREATE_CONVERSATION_STEP_FAILURE,
        meta: { conversationId: params.conversationId },
      },
    ],
  },
});

export const deleteConversationStep = (id) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${id}/`,
    method: "DELETE",
    types: [
      {
        type: constants.DELETE_CONVERSATION_STEP_REQUEST,
        meta: { conversationStepId: id },
      },
      {
        type: constants.DELETE_CONVERSATION_STEP_SUCCESS,
        meta: { conversationStepId: id },
      },
      {
        type: constants.DELETE_CONVERSATION_STEP_FAILURE,
        meta: { conversationStepId: id },
      },
    ],
  },
});

export const enterEditMode = (payload) => ({
  type: constants.ENTER_EDIT_MODE,
  payload,
});

export const clearEditMode = (payload) => ({
  type: constants.CLEAR_EDIT_MODE,
});

export const showInsertFieldModal = (conversationStepId) => ({
  type: constants.SHOW_INSERT_FIELD_MODAL,
  payload: { conversationStepId },
});

export const closeInsertFieldModal = () => ({
  type: constants.CLOSE_INSERT_FIELD_MODAL,
});

export const publishConversation = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversations/${params.conversationId}/publish`,
      method: "POST",
      body: JSON.stringify({
        subscriber_list_id: params.subscriberListId,
      }),
      types: [
        {
          type: constants.PUBLISH_CONVERSATION_REQUEST,
          meta: { conversationId: params.conversationId },
        },
        {
          type: constants.PUBLISH_CONVERSATION_SUCCESS,
          meta: { conversationId: params.conversationId },
        },
        {
          type: constants.PUBLISH_CONVERSATION_FAILURE,
          meta: { conversationId: params.conversationId },
        },
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  } else {
    const detailsUrl = getConversationLink(actionResponse.payload) + "/details";
    dispatch(
      showSuccessAlert(
        <span>
          Nice :) Your conversation is on its way!
          <Link
            to={detailsUrl}
            style={{ fontWeight: "bold", color: "inherit" }}
          >
            Check it out &rsaquo;
          </Link>
        </span>
      )
    );
  }

  return actionResponse;
};

export const duplicateConversation = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversations/${params.conversationId}/duplicate`,
      method: "POST",
      types: [
        {
          type: constants.DUPLICATE_CONVERSATION_REQUEST,
          meta: { conversationId: params.conversationId },
        },
        {
          type: constants.DUPLICATE_CONVERSATION_SUCCESS,
          meta: { conversationId: params.conversationId },
        },
        {
          type: constants.DUPLICATE_CONVERSATION_FAILURE,
          meta: { conversationId: params.conversationId },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const createButton = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_buttons/`,
    method: "POST",
    body: { conversation_step_button: payload },
    types: [
      constants.CREATE_BUTTON_REQUEST,
      constants.CREATE_BUTTON_SUCCESS,
      constants.CREATE_BUTTON_FAILURE,
    ],
  },
});

export const toggleRandomizeButtons = (stepId, randomized = true) => {
  return {
    [CALL_API]: {
      endpoint: `/api/steps/${stepId}/buttons/random`,
      method: randomized ? "POST" : "DELETE",
      types: [
        constants.TOGGLE_RANDOMIZE_BUTTONS_REQUEST,
        constants.TOGGLE_RANDOMIZE_BUTTONS_SUCCESS,
        constants.TOGGLE_RANDOMIZE_BUTTONS_FAILURE,
      ],
    },
  };
};

export const toggleRandomizeTemplateCards = (stepId, randomized = true) => {
  return {
    [CALL_API]: {
      endpoint: `/api/steps/${stepId}/template_cards/random`,
      method: randomized ? "POST" : "DELETE",
      types: [
        constants.TOGGLE_RANDOMIZE_TEMPLATE_CARDS_REQUEST,
        constants.TOGGLE_RANDOMIZE_TEMPLATE_CARDS_SUCCESS,
        constants.TOGGLE_RANDOMIZE_TEMPLATE_CARDS_FAILURE,
      ],
    },
  };
};

export const putButton = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversation_step_buttons/`,
      method: "PUT",
      body: JSON.stringify({ conversation_step_button: payload }),
      types: [
        {
          type: constants.PUT_BUTTON_REQUEST,
          meta: payload.conversation_step_button_id
            ? { conversationStepButtonId: payload.conversation_step_button_id }
            : {},
        },
        constants.PUT_BUTTON_SUCCESS,
        {
          type: constants.PUT_BUTTON_FAILURE,
          meta: payload.conversation_step_button_id
            ? { conversationStepButtonId: payload.conversation_step_button_id }
            : {},
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const putTemplateCard = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_template_cards/`,
    method: "PUT",
    body: JSON.stringify({ conversation_step_template_card: payload }),
    types: [
      {
        type: constants.PUT_TEMPLATE_CARD_REQUEST,
        meta: payload.conversation_step_template_card_id
          ? {
              conversationStepTemplateCardId:
                payload.conversation_step_template_card_id,
            }
          : {},
      },
      constants.PUT_TEMPLATE_CARD_SUCCESS,
      {
        type: constants.PUT_TEMPLATE_CARD_FAILURE,
        meta: payload.conversation_step_template_card_id
          ? {
              conversationStepTemplateCardId:
                payload.conversation_step_template_card_id,
            }
          : {},
      },
    ],
  },
});

export const replaceTemplateCardProduct = (template_card_id, product_id) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_template_cards/${template_card_id}/replace-product`,
    method: "PUT",
    body: JSON.stringify({ product_id: product_id }),
    types: [
      {
        type: constants.REPLACE_TEMPLATE_CARD_PRODUCT_REQUEST,
        meta: template_card_id
          ? {
              conversationStepTemplateCardId: template_card_id,
            }
          : {},
      },
      constants.REPLACE_TEMPLATE_CARD_PRODUCT_SUCCESS,
      {
        type: constants.REPLACE_TEMPLATE_CARD_PRODUCT_FAILURE,
        meta: template_card_id
          ? {
              conversationStepTemplateCardId: template_card_id,
            }
          : {},
      },
    ],
  },
});

export const createTemplateCardButton = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_template_card_buttons/`,
    method: "POST",
    body: { conversation_step_template_card_button: payload },
    types: [
      constants.PUT_TEMPLATE_CARD_BUTTON_REQUEST,
      constants.PUT_TEMPLATE_CARD_BUTTON_SUCCESS,
      constants.PUT_TEMPLATE_CARD_BUTTON_FAILURE,
    ],
  },
});

export const putTemplateCardButton = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_template_card_buttons/`,
    method: "PUT",
    body: JSON.stringify({ conversation_step_template_card_button: payload }),
    types: [
      constants.PUT_TEMPLATE_CARD_BUTTON_REQUEST,
      constants.PUT_TEMPLATE_CARD_BUTTON_SUCCESS,
      constants.PUT_TEMPLATE_CARD_BUTTON_FAILURE,
    ],
  },
});

export const moveTemplateCard = (id, payload) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_template_cards/${id}/move`,
    method: "PUT",
    body: JSON.stringify(payload),
    types: [
      "MOVE_TEMPLATE_CARD_REQUEST",
      "MOVE_TEMPLATE_CARD_SUCCESS",
      "MOVE_TEMPLATE_CARD_FAILURE",
    ],
  },
});

export const setTriggerChoice = ({ conversationStepId, triggerId }) => ({
  type: constants.SET_TRIGGER_CHOICE,
  payload: {
    conversationStepId,
    triggerId,
  },
});

export const putConversationStepTrigger = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversation_step_triggers/`,
      method: "PUT",
      body: JSON.stringify({ conversation_step_trigger: payload }),
      types: [
        {
          type: constants.PUT_CONVERSATION_STEP_TRIGGER_REQUEST,
          meta: payload.conversation_step_trigger_id
            ? { conversationStepTriggerId: payload.trigger_id }
            : {},
        },
        constants.PUT_CONVERSATION_STEP_TRIGGER_SUCCESS,
        {
          type: constants.PUT_CONVERSATION_STEP_TRIGGER_FAILURE,
          meta: payload.conversation_step_trigger_id
            ? { conversationStepTriggerId: payload.trigger_id }
            : {},
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteConversationStepTrigger = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversation_step_triggers/${params.triggerId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_CONVERSATION_STEP_TRIGGER_REQUEST,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_CONVERSATION_STEP_TRIGGER_SUCCESS,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_CONVERSATION_STEP_TRIGGER_FAILURE,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const putFeedbackTrigger = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/feedback_triggers/`,
      method: "PUT",
      body: JSON.stringify({ feedback_trigger: payload }),
      types: [
        {
          type: constants.PUT_FEEDBACK_TRIGGER_REQUEST,
          meta: payload.feedback_trigger_id
            ? { feedbackTriggerId: payload.trigger_id }
            : {},
        },
        constants.PUT_FEEDBACK_TRIGGER_SUCCESS,
        {
          type: constants.PUT_FEEDBACK_TRIGGER_FAILURE,
          meta: payload.feedback_trigger_id
            ? { feedbackTriggerId: payload.trigger_id }
            : {},
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteFeedbackTrigger = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/feedback_triggers/${params.triggerId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_FEEDBACK_TRIGGER_REQUEST,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_FEEDBACK_TRIGGER_SUCCESS,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_FEEDBACK_TRIGGER_FAILURE,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const putUnexpectedTrigger = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/unexpected_triggers/`,
      method: "PUT",
      body: JSON.stringify({ unexpected_trigger: payload }),
      types: [
        {
          type: constants.PUT_UNEXPECTED_TRIGGER_REQUEST,
          meta: payload.unexpected_trigger_id
            ? { unexpectedTriggerId: payload.trigger_id }
            : {},
        },
        constants.PUT_UNEXPECTED_TRIGGER_SUCCESS,
        {
          type: constants.PUT_UNEXPECTED_TRIGGER_FAILURE,
          meta: payload.unexpected_trigger_id
            ? { unexpectedTriggerId: payload.trigger_id }
            : {},
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteUnexpectedTrigger = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/unexpected_triggers/${params.triggerId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_UNEXPECTED_TRIGGER_REQUEST,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_UNEXPECTED_TRIGGER_SUCCESS,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_UNEXPECTED_TRIGGER_FAILURE,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteConversationStepButton = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversation_step_buttons/${params.conversationStepButtonId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_CONVERSATION_STEP_BUTTON_REQUEST,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_CONVERSATION_STEP_BUTTON_SUCCESS,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
        {
          type: constants.DELETE_CONVERSATION_STEP_BUTTON_FAILURE,
          meta: {
            conversationStepId: params.conversationStepId,
          },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteTemplateCard = (params) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_template_cards/${params.conversationStepTemplateCardId}/`,
    method: "DELETE",
    types: [
      {
        type: constants.DELETE_TEMPLATE_CARD_REQUEST,
        meta: {
          conversationStepId: params.conversationStepId,
        },
      },
      {
        type: constants.DELETE_TEMPLATE_CARD_SUCCESS,
        meta: {
          conversationStepId: params.conversationStepId,
        },
      },
      {
        type: constants.DELETE_TEMPLATE_CARD_FAILURE,
        meta: {
          conversationStepId: params.conversationStepId,
        },
      },
    ],
  },
});

export const deleteTemplateCardButton = (params) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/conversation_step_template_card_buttons/${params.conversationStepTemplateCardButtonId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_TEMPLATE_CARD_BUTTON_REQUEST,
          meta: {
            conversationStepTemplateCardId:
              params.conversationStepTemplateCardId,
          },
        },
        {
          type: constants.DELETE_TEMPLATE_CARD_BUTTON_SUCCESS,
          meta: {
            conversationStepTemplateCardId:
              params.conversationStepTemplateCardId,
          },
        },
        {
          type: constants.DELETE_TEMPLATE_CARD_BUTTON_FAILURE,
          meta: {
            conversationStepTemplateCardId:
              params.conversationStepTemplateCardId,
          },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const uploadTemplateCardImage = ({ id, data }) => ({
  [CALL_API]: {
    endpoint: `/api/template_card/${id}/image`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body: data,
    types: [
      constants.UPLOAD_TEMPLATE_CARD_IMAGE_REQUEST,
      constants.UPLOAD_TEMPLATE_CARD_IMAGE_SUCCESS,
      constants.UPLOAD_TEMPLATE_CARD_IMAGE_FAILURE,
    ],
  },
});

export const uploadConversationStepMedia = ({ id, data }) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${id}/media`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: data,
    types: [
      constants.UPLOAD_CONVERSATION_STEP_IMAGE_REQUEST,
      constants.UPLOAD_CONVERSATION_STEP_IMAGE_SUCCESS,
      constants.UPLOAD_CONVERSATION_STEP_IMAGE_FAILURE,
    ],
  },
});

export const putTargetFilter = (payload) => ({
  [CALL_API]: {
    endpoint: `/api/target_filters/`,
    method: "PUT",
    body: JSON.stringify({ target_filter: payload }),
    types: [
      {
        type: constants.PUT_TARGET_FILTER_REQUEST,
        meta: payload.target_filter_id
          ? { targetFilterId: payload.target_filter_id }
          : {},
      },
      constants.PUT_TARGET_FILTER_SUCCESS,
      {
        type: constants.PUT_TARGET_FILTER_FAILURE,
        meta: payload.target_filter_id
          ? { targetFilterId: payload.target_filter_id }
          : {},
      },
    ],
  },
});

export const deleteTargetFilter = (targetFilterId) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/target_filters/${targetFilterId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_TARGET_FILTER_REQUEST,
          meta: {
            targetFilterId: targetFilterId,
          },
        },
        {
          type: constants.DELETE_TARGET_FILTER_SUCCESS,
          meta: {
            targetFilterId: targetFilterId,
          },
        },
        {
          type: constants.DELETE_TARGET_FILTER_FAILURE,
          meta: {
            targetFilterId: targetFilterId,
          },
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const fetchNumTargeted = (params) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${params.conversationId}/num_targeted/`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_NUM_TARGETED_REQUEST,
        meta: { conversationId: params.conversationId },
      },
      {
        type: constants.FETCH_NUM_TARGETED_SUCCESS,
        meta: { conversationId: params.conversationId },
      },
      {
        type: constants.FETCH_NUM_TARGETED_FAILURE,
        meta: { conversationId: params.conversationId },
      },
    ],
  },
});

export const closePublishConfirmationModal = (conversationId) => ({
  type: constants.CLOSE_PUBLISH_CONFIRMATION_MODAL,
  payload: { conversationId },
});

export const fetchConversationReportsDownloadToken = (conversationId) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${conversationId}/download_token/`,
    method: "POST",
    types: [
      constants.FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_REQUEST,
      {
        type: constants.FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_SUCCESS,
        meta: { conversationId },
      },
      constants.FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_FAILURE,
    ],
  },
});

const getConvoFunnelUrl = (convoId, fromDate = null, toDate = null) => {
  if (fromDate && toDate) {
    return `/api/conversations/${convoId}/funnel?fromDate=${fromDate}&toDate=${toDate}`;
  }
  return `/api/conversations/${convoId}/funnel`;
};

const getFunnelStepsBreakdownUrl = (
  convoId,
  fromDate = null,
  toDate = null
) => {
  if (fromDate && toDate) {
    return `/api/conversations/${convoId}/funnel_selections_breakdown?fromDate=${fromDate}&toDate=${toDate}`;
  }
  return `/api/conversations/${convoId}/funnel_selections_breakdown`;
};

export const fetchConversationFunnel = (
  id,
  fromDate = null,
  toDate = null
) => ({
  [CALL_API]: {
    endpoint: getConvoFunnelUrl(id, fromDate, toDate),
    method: "GET",
    types: [
      constants.FETCH_CONVERSATION_FUNNEL_REQUEST,
      {
        type: constants.FETCH_CONVERSATION_FUNNEL_SUCCESS,
        meta: { id: id },
      },
      constants.FETCH_CONVERSATION_FUNNEL_FAILURE,
    ],
  },
});

export const fetchFunnelStepsBreakdown = (
  id,
  stepIds,
  fromDate = null,
  toDate = null
) => ({
  [CALL_API]: {
    endpoint: getFunnelStepsBreakdownUrl(id, fromDate, toDate),
    method: "POST",
    body: { ids: stepIds },
    types: [
      "FETCH_FUNNEL_STEPS_BREAKDONW_REQUEST",
      "FETCH_FUNNEL_STEPS_BREAKDONW_SUCCESS",
      "FETCH_FUNNEL_STEPS_BREAKDONW_FAILURE",
    ],
  },
});

export const fetchFunnelConversions = (id, fromDate = null, toDate = null) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/funnel_conversions?fromDate=${
      fromDate || ""
    }&toDate=${toDate || ""}`,
    method: "GET",
    types: [
      "FETCH_FUNNEL_CONVERSIONS_REQUEST",
      "FETCH_FUNNEL_CONVERSIONS_SUCCESS",
      "FETCH_FUNNEL_CONVERSIONS_FAILURE",
    ],
  },
});

export const fetchLinksBreakdown = (id, fromDate = null, toDate = null) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/links_breakdown?fromDate=${fromDate || ""}&toDate=${toDate || ""}`,
    method: "GET",
    types: [
      "FETCH_FUNNEL_LINKS_REQUEST",
      "FETCH_FUNNEL_LINKS_SUCCESS",
      "FETCH_FUNNEL_LINKS_FAILURE",
    ],
  },
});

export const downloadConversationCsv = (id) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/download`,
    method: "GET",
    types: [
      "DOWNLOAD_CONVERSATION_CSV_REQUEST",
      "DOWNLOAD_CONVERSATION_CSV_SUCCESS",
      "DOWNLOAD_CONVERSATION_CSV_FAILURE",
    ],
  },
});

export const clearVisibleConversationSteps = (id) => ({
  type: "CLEAR_VISIBLE_CONVERSATION_STEPS",
  id: id,
});

export const createReconnection = ({ stepId, defaultNextStepId }) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${stepId}/reconnection`,
    method: "POST",
    body: { default_next_step: defaultNextStepId },
    types: [
      "CREATE_RECONNECTION_REQUEST",
      "CREATE_RECONNECTION_SUCCESS",
      "CREATE_RECONNECTION_FAILURE",
    ],
  },
});

export const removeReconnection = ({ stepId }) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${stepId}/reconnection`,
    method: "DELETE",
    types: [
      "REMOVE_RECONNECTION_REQUEST",
      "REMOVE_RECONNECTION_SUCCESS",
      "REMOVE_RECONNECTION_FAILURE",
    ],
  },
});

export const updateStepRequirement = (stepId, required) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_step_requirements/${stepId}/`,
    method: required ? "POST" : "DELETE",
    types: [
      constants.UPDATE_STEP_REQUIREMENT_REQUEST,
      constants.UPDATE_STEP_REQUIREMENT_SUCCESS,
      constants.UPDATE_STEP_REQUIREMENT_FAILURE,
    ],
  },
});
