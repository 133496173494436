import React, { Component } from "react";

import { ContentHeader } from "../shared";

import DeleteMatchingResponseModal from "./DeleteMatchingResponseModal";

export class MatchingResponseItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDeleteModal: false,
      isEditing: props.isEditing || false,
      matchingResponse: props.matchingResponse,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.matchingResponse !== this.props.matchingResponse) {
      this.setState({ matchingResponse: nextProps.matchingResponse });
    }
  }

  render() {
    const { displayDeleteModal, isEditing, matchingResponse } = this.state;

    const {
      globalTrigger,
      id,
      onDelete,
      deleteLabel,
      deleteConfirmationText,
    } = this.props;

    return (
      <li className="matching-responses__list-item">
        {isEditing ? (
          <input
            type="text"
            value={matchingResponse}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
          />
        ) : (
          matchingResponse
        )}
        <div>
          <button className="paloma-button" onClick={this.showDeleteModal}>
            {deleteLabel ? deleteLabel : "Delete"}
          </button>
          {!isEditing && (
            <button className="paloma-button" onClick={this.handleClickEdit}>
              Edit
            </button>
          )}
          {isEditing && (
            <button
              className="paloma-button--pink"
              onClick={this.handleClickSave}
            >
              Save
            </button>
          )}
          {displayDeleteModal && (
            <DeleteMatchingResponseModal
              globalTrigger={globalTrigger}
              id={id}
              onClose={this.hideDeleteModal}
              onDelete={onDelete}
              title={deleteConfirmationText}
            />
          )}
        </div>
      </li>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => this.setState({ displayDeleteModal: false });

  handleChange = e => {
    this.setState({ matchingResponse: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      if (!this.state.matchingResponse) return;

      this.handleClickSave();
    }
  };

  handleKeyDown = e => {
    if (e.keyCode !== 27) return;

    if (!this.props.matchingResponse) {
      this.handleClickDelete();
    } else {
      this.setState({
        matchingResponse: this.props.matchingResponse,
        isEditing: false,
      });
    }
  };

  handleClickDelete = () => {
    this.props.onDelete(this.props.id);
  };

  handleClickEdit = () => {
    this.setState({ isEditing: true });
  };

  handleClickSave = () => {
    const { id, onSave } = this.props;

    const { matchingResponse } = this.state;

    this.setState({ isEditing: false }, () => onSave(id, matchingResponse));
  };
}

export default class MatchingResponses extends Component {
  state = {
    unsavedMatchingResponses: [],
    nextUnsavedMatchingResponseId: 0,
  };

  render() {
    const {
      globalTrigger,
      onDeleteExistingMatchingResponse,
      onSaveExistingMatchingResponse,
    } = this.props;

    const { unsavedMatchingResponses } = this.state;

    return (
      <div className="matching-responses">
        <h3>Matching responses</h3>
        <ContentHeader
          left={
            <div className="description" style={{ width: "80%" }}>
              <p>
                When subscribers send any of the following responses, they will
                receive this trigger's conversation. Exact spelling matters, but
                capitalization doesn't.
              </p>
            </div>
          }
          right={
            (globalTrigger.matchingResponses || []).length > 0 && (
              <button
                className="paloma-button"
                onClick={this.handleAddNewMatchingResponse}
              >
                + New
              </button>
            )
          }
        />
        {!(globalTrigger.matchingResponses || []).length &&
        !unsavedMatchingResponses.length ? (
          <div>
            <p>
              Add matching responses that will initiate the trigger
              conversation.
            </p>
            <button
              className="paloma-button"
              onClick={this.handleAddNewMatchingResponse}
            >
              + Matching Response
            </button>
          </div>
        ) : (
          <ul className="matching-responses__list">
            {unsavedMatchingResponses.map(({ id }) => (
              <MatchingResponseItem
                id={id}
                isEditing
                key={`unsaved-${id}'`}
                matchingResponse=""
                onDelete={this.handleDeleteNewMatchingResponse}
                onSave={this.handleSaveNewMatchingResponse}
              />
            ))}
            {globalTrigger.matchingResponses.map((matchingResponse, i) => (
              <MatchingResponseItem
                id={i}
                key={`saved-${i}`}
                matchingResponse={matchingResponse}
                onSave={onSaveExistingMatchingResponse}
                onDelete={onDeleteExistingMatchingResponse}
                globalTrigger={globalTrigger}
              />
            ))}
          </ul>
        )}
      </div>
    );
  }

  handleAddNewMatchingResponse = () => {
    const { unsavedMatchingResponses } = this.state;

    this.setState({
      unsavedMatchingResponses: [
        { id: unsavedMatchingResponses.length },
      ].concat(unsavedMatchingResponses),
    });
  };

  handleDeleteNewMatchingResponse = id => {
    this.setState({
      unsavedMatchingResponses: this.state.unsavedMatchingResponses.filter(
        ({ id: _id }) => _id !== id
      ),
    });
  };

  handleSaveNewMatchingResponse = (id, matchingResponse) => {
    this.props.onSaveNewMatchingResponse(matchingResponse);
    this.handleDeleteNewMatchingResponse(id);
  };
}
