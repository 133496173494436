import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader";

import {
  SubscriberFields,
  SubscriberLists,
  SubscriberTags,
  SubscriberConversations,
} from "../../components/SubscriberDetail";
import SourcesTable from "../../components/shared/SourcesTable";
import orders from "../../utilities/orders";
import SubscriberOwner from "../../components/shared/SubscriberOwner";
import SubscriberMessageHistory from "../SubscriberMessageHistory";

import {
  ContentHeader,
  DownloadButton,
  InfoPanel,
  InfoPanelRow,
  Modal,
  Card,
} from "../../components/shared";

import { withTime } from "../../utilities/format-date";

import * as subscriberActions from "../../actions/subscribers";
import { fetchOrganizationUsers } from "../../actions/current-organization";

import {
  createSubscriberThreadControl,
  deleteSubscriberThreadControl,
} from "../../actions/subscriber-thread-control";
import { sendSubscriberConversationStep } from "../../actions/subscriber-conversation-steps";

import { fetchSubscriberPhoto } from "../../actions/subscriber-photos";
import { putSubscriberField } from "../../actions/subscriber-fields";
import {
  fetchSubscriberTags,
  createSubscriberTag,
  deleteSubscriberTag,
} from "../../actions/subscriber-tags";
import UserIcon from "../../components/shared/UserIcon";
import { SubscriberStatus } from "../../components/shared/SubscriberStatus";

class SubscriberDetailContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { deleteModal: false };
  }

  async componentDidMount() {
    const { subscriberId } = this.props
    await Promise.all([
      this.props.fetchSubscriber(subscriberId),
      this.props.fetchSubscriberPhoto(subscriberId),
      this.props.fetchSubscriberTags(),
      this.props.fetchOrganizationUsers(),
    ])
  }

  source = () => this.props.subscriber.source || "Organic";

  render() {
    const {
      appliedSubscriberTags,
      remainingSubscriberTagNames,
      subscriber,
      subscriberFieldsById,
      subscriberId,
      conversations,
      subscriberIsLoading,
    } = this.props;

    const {
      clickThroughRate = 0,
      clicks = 0,
      completionRate = 0,
      completions = 0,
      engagementRate = 0,
      engagements = 0,
      subscribed,
      status,
      manualMessagingEnabled,
      totalConversions,
    } = subscriber;

    const statHeaders = this.statHeaders();
    const statValues = this.statValues();

    return (
      <Loader loaded={!subscriberIsLoading} loadedClassName="subscriber-detail">
        <div className="subscriber-detail__columns">
          <div className="subscriber-detail__left-column">
            <ContentHeader
              left={
                <div className="content-header">
                  <UserIcon
                    pictureUrl={this.props.subscriberPhotoUrl}
                    status={status}
                    manualMessaging={manualMessagingEnabled}
                  />
                  <h1 className="page-title">{subscriber.fullName}</h1>
                </div>
              }
              right={
                <div>
                  <DownloadButton
                    fetchDownloadLink={this.fetchDownloadLinkForSubscriber}
                  />
                  <button
                    style={{ marginLeft: "10px" }}
                    className="paloma-button--pink"
                    onClick={() => this.setState({ deleteModal: true })}
                  >
                    Unsubscribe
                  </button>
                </div>
              }
            />
            <InfoPanel>
              <InfoPanelRow
                headers={statHeaders.slice(0, 3)}
                values={statValues.slice(0, 3)}
              />
              <InfoPanelRow
                headers={statHeaders.slice(3, 6)}
                values={statValues.slice(3, 6)}
              />
              <InfoPanelRow
                headers={statHeaders.slice(6)}
                values={statValues.slice(6)}
              />
            </InfoPanel>
            <InfoPanel>
              <InfoPanelRow
                headers={["Total Completed", "Total Responses", "Total Clicks"]}
                values={[completions, engagements, clicks]}
              />
              {this.props.hasShopifyStorefront && (
                <InfoPanelRow
                  headers={[
                    "Total Add to Carts",
                    "Total Checkouts",
                    "Total Spent",
                  ]}
                  values={[
                    (subscriber.carts || []).length,
                    (subscriber.orders || []).length,
                    "$" + orders(subscriber.orders).total,
                  ]}
                />
              )}
              <InfoPanelRow
                headers={[
                  "Completion Rate",
                  "Response Rate",
                  "Click Through Rate",
                ]}
                values={[
                  completionRate + "%",
                  engagementRate + "%",
                  clickThroughRate + "%",
                ]}
              />
              <InfoPanelRow
                headers={["Total Conversions"]}
                values={[totalConversions]}
              />
            </InfoPanel>
            <SourcesTable sources={subscriber.sources} />
            <InfoPanel>
              <SubscriberTags
                appliedSubscriberTags={appliedSubscriberTags}
                remainingSubscriberTagNames={remainingSubscriberTagNames}
                onChangeTags={this.handleChangeTags}
              />
            </InfoPanel>
            <InfoPanel>
              <SubscriberFields
                subscriberFieldsById={subscriberFieldsById}
                onSave={this.handleSaveSubscriberField}
              />
            </InfoPanel>
            <InfoPanel>
              <SubscriberLists subscriberId={this.props.subscriberId} />
            </InfoPanel>
            <InfoPanel>
              <SubscriberConversations conversations={conversations || []} />
            </InfoPanel>
          </div>
          <div className="subscriber-detail__right-column">
            <Card className="profile">
              <SubscriberMessageHistory
                subscriberId={this.props.subscriberId}
                hideProfile={true}
              />
            </Card>
          </div>
        </div>
        {this.state.deleteModal && (
          <Modal
            title="Remove Subscriber?"
            isOpen={true}
            onClose={() => this.setState({ deleteModal: false })}
          >
            <p className="modal__message">
              Are you sure you’d like to unsubscribe this subscriber? They will not be able to receive 
              automated or live chat messages through Paloma unless they message the page again.
            </p>
            <div className="modal__controls">
              <button
                type="button"
                className="paloma-button modal__button"
                onClick={() => this.setState({ deleteModal: false })}
              >
                Cancel
              </button>
              <button
                className="paloma-button--pink modal__button modal__button--red"
                onClick={() =>
                  this.props.deleteSubscriber(this.props.subscriberId)
                }
                type="submit"
              >
                Confirm
              </button>
            </div>
          </Modal>
        )}
      </Loader>
    );
  }

  handleToggleManualMessaging = manualMessagingEnabled => {
    const { putSubscriber, subscriber } = this.props;

    putSubscriber({
      ...subscriber,
      manualMessagingEnabled,
    });
  };

  handleToggleStandby = () => {
    if (this.props.subscriber.standby) {
      this.props.createSubscriberThreadControl(
        this.props.subscriber.subscriberId
      );
    } else {
      this.props.deleteSubscriberThreadControl(
        this.props.subscriber.subscriberId
      );
    }
  };

  sendSubscriberConversationStep = conversationStepId => {
    this.props.sendSubscriberConversationStep(
      this.props.subscriber.subscriberId,
      conversationStepId
    );
  };

  handleSendMessage = message => {
    const { sendManualMessageToSubscriber, subscriberId } = this.props;

    sendManualMessageToSubscriber(subscriberId, message);
  };

  handleSaveSubscriberField = subscriberField => {
    const { putSubscriberField, subscriberId } = this.props;

    putSubscriberField({
      ...subscriberField,
      subscriberId: subscriberField.subscriberId || subscriberId,
    });
  };

  handleChangeTags = tagNames => {
    const {
      appliedSubscriberTags,
      createSubscriberTag,
      deleteSubscriberTag,
      subscriberId,
    } = this.props;

    const appliedTagNames = appliedSubscriberTags.map(tag => tag.name);
    const tagIdsToDelete = appliedSubscriberTags
      .filter(tag => !tagNames.includes(tag.name))
      .map(tag => tag.subscriberTagId);
    const tagNamesToAdd = tagNames.filter(
      tagName => !appliedTagNames.includes(tagName)
    );

    tagIdsToDelete.forEach(subscriberTagId =>
      deleteSubscriberTag(subscriberTagId)
    );

    tagNamesToAdd.forEach(tagName =>
      createSubscriberTag({
        name: tagName,
        subscriberId,
      })
    );
  };

  fetchDownloadLinkForSubscriber = async mimetype => {
    const { fetchSubscriberDownloadToken, subscriberId } = this.props;

    const {
      payload: { downloadToken },
    } = await fetchSubscriberDownloadToken(subscriberId);

    return (
      process.env.REACT_APP_API_HOSTNAME +
      `/api/subscribers/${subscriberId}/download?mimetype=${mimetype}&token=${downloadToken}`
    );
  };

  statHeaders = () => {
    let headers = [
      "Status",
      "Source",
      "Owner",
      "Completed Profile",
      "Subscribed",
      "Last Received",
      "Last Sent",
    ];
    if (this.props.hasShopifyStorefront) {
      headers.push("Last Purchased");
      headers.push("");
    }
    return headers;
  };

  statValues = () => {
    const { subscriber, users } = this.props;
    let values = [
      <SubscriberStatus
        status={subscriber.status}
        manualMessaging={subscriber.manualMessagingEnabled}
        showText={true}
      />,
      this.source(),
      <SubscriberOwner subscriber={subscriber} owners={users} />,
      <div>{subscriber.isComplete ? "Yes" : "No"}</div>,
      withTime(subscriber.createdAt),
      withTime(subscriber.lastMessageReceivedAt),
      withTime(subscriber.lastMessageSentAt),
    ];
    if (this.props.hasShopifyStorefront) {
      values.push(withTime(subscriber.lastOrderPlacedAt));
      values.push("");
    }
    return values;
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { subscriberId },
    },
  } = ownProps;

  const {
    currentOrganization: { users },
  } = state;

  const {
    conversations: { conversationsById },
    subscribers: { subscribersById },
    subscriberFields,
    subscriberTags: { byId: subscriberTagsById },
    subscriberLists,
    subscriberConversationStates: { byId: subscriberConversationStatesById },
  } = state;

  const subscriber = {
    isLoading: true,
    ...subscribersById[subscriberId],
  };

  const subscriberTags = Object.values(subscriberTagsById);
  const appliedSubscriberTags = subscriberTags.filter(
    tag => tag.subscriberId === parseInt(subscriberId, 10)
  );
  const appliedSubscriberTagNames = appliedSubscriberTags.map(tag => tag.name);

  const subscriberConversationStates = Object.values(
    subscriberConversationStatesById
  );

  const conversations = subscriberConversationStates
    .filter(s => s.subscriberId === parseInt(subscriberId, 10))
    .map(s => ({
      ...conversationsById[s.conversationId],
      receivedAt: s.createdAt,
    }));

  const subscriberFieldsById = Object.values(subscriberFields.byId)
    .filter(
      subscriberField =>
        subscriberField.subscriberId === parseInt(subscriberId, 10)
    )
    .reduce((subscriberFields, subscriberField) => {
      return {
        ...subscriberFields,
        [subscriberField.subscriberFieldId]: subscriberField,
      };
    }, {});

  const remainingSubscriberTagNames = [
    ...new Set(
      subscriberTags
        .map(tag => tag.name)
        .filter(tagName => !appliedSubscriberTagNames.includes(tagName))
    ),
  ];

  return {
    appliedSubscriberTags,
    conversations,
    hasShopifyStorefront: state.currentOrganization.hasShopifyStorefront,
    subscriberIsLoading: subscriber.isLoading,
    subscriberTagsIsLoading: subscriberTags.isLoading,
    remainingSubscriberTagNames,
    subscriber,
    subscriberFieldsById,
    subscriberId,
    subscriberLists: Object.values(subscriberLists.byId),
    subscriberTagsById,
    subscriberPhotoUrl: state.subscriberPhotos[subscriberId],
    users,
  };
};

export default connect(mapStateToProps, {
  ...subscriberActions,
  createSubscriberTag,
  createSubscriberThreadControl,
  deleteSubscriberThreadControl,
  deleteSubscriberTag,
  fetchSubscriberPhoto,
  fetchSubscriberTags,
  putSubscriberField,
  sendSubscriberConversationStep,
  fetchOrganizationUsers,
})(SubscriberDetailContainer);
