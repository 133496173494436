export const CREATE_ASSIGN_SUBSCRIBER_OWNER_REQUEST =
  "CREATE_ASSIGN_SUBSCRIBER_OWNER_REQUEST";
export const CREATE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS =
  "CREATE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS";
export const CREATE_ASSIGN_SUBSCRIBER_OWNER_FAILURE =
  "CREATE_ASSIGN_SUBSCRIBER_OWNER_FAILURE";

export const FETCH_ASSIGN_SUBSCRIBER_OWNER_REQUEST =
  "FETCH_ASSIGN_SUBSCRIBER_OWNER_REQUEST";
export const FETCH_ASSIGN_SUBSCRIBER_OWNER_SUCCESS =
  "FETCH_ASSIGN_SUBSCRIBER_OWNER_SUCCESS";
export const FETCH_ASSIGN_SUBSCRIBER_OWNER_FAILURE =
  "FETCH_ASSIGN_SUBSCRIBER_OWNER_FAILURE";

export const DELETE_ASSIGN_SUBSCRIBER_OWNER_REQUEST =
  "DELETE_ASSIGN_SUBSCRIBER_OWNER_REQUEST";
export const DELETE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS =
  "DELETE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS";
export const DELETE_ASSIGN_SUBSCRIBER_OWNER_FAILURE =
  "DELETE_ASSIGN_SUBSCRIBER_OWNER_FAILURE";
