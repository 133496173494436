import { timeOnlyIfToday, subtractTime } from "../../utilities/format-date";
import React, { Component } from "react";
import Waypoint from "react-waypoint";
import Message from "../shared/Message";

const PAGE_SIZE = 100;

export default class MessageHistory extends Component {
  state = {
    currentPage: 1,
    isFetching: false,
    lastSentAt: null,
  };
  
  async componentDidUpdate(prevProps, prevState) {
    const { lastSentAt } = prevState;
    const { currentPage } = this.state;
    const newMessages = this.props.messages || [];

    if (newMessages.length) {
      const newLastSentAt = newMessages
        .map((m) => m.sentAt)
        .sort()
        .reverse()[0];
      if (newLastSentAt > lastSentAt || !lastSentAt) {
        this.scrollToBottom();
        this.setState({ lastSentAt: newLastSentAt });
      }
    }

    // when currentPage increases, fetch more messages
    if (prevState.currentPage < currentPage && this.props.subscriberId) {
      const {
        payload: { messages = {} },
      } = await this.fetchMessagePage();

      this.setState({
        isFetching: false,
        // Revert the currentPage to its previous value if the page wasn't full
        currentPage:
          messages.length >= PAGE_SIZE ? currentPage : currentPage - 1,
      });
    }
  }

  render() {
    const { messages = [] } = this.props;

    let lastTime = false;

    return (
      <div
        className="message-history"
        ref={(messageHistoryDiv) =>
          (this.messageHistoryDiv = messageHistoryDiv)
        }
      >
        <Waypoint onEnter={this.incrementPage} />
        {messages.map((message) => {
          const timestamp =
            !lastTime || subtractTime(message.sentAt, lastTime) > 60000;
          lastTime = message.sentAt;

          return (
            <div key={message.messageId}>
              {timestamp && (
                <div className="message-timestamp">
                  {timeOnlyIfToday(message.sentAt)}
                </div>
              )}
              <Message message={message} />
            </div>
          );
        })}
      </div>
    );
  }

  scrollToBottom = () => {
    this.messageHistoryDiv.scrollTop = this.messageHistoryDiv.scrollHeight;
  };

  fetchMessagePage = () => {
    const { currentPage } = this.state;
    const { fetchMessagesForSubscriber, subscriberId } = this.props;

    return fetchMessagesForSubscriber(subscriberId, currentPage);
  };

  incrementPage = () => {
    if (this.isFetching) return;

    this.setState({
      currentPage: this.state.currentPage + 1,
      isFetching: true,
    });
  };
}
