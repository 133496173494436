export const FETCH_SUBSCRIBER_REQUEST = "FETCH_SUBSCRIBER_REQUEST";
export const FETCH_SUBSCRIBER_SUCCESS = "FETCH_SUBSCRIBER_SUCCESS";
export const FETCH_SUBSCRIBER_FAILURE = "FETCH_SUBSCRIBER_FAILURE";

export const FETCH_SUBSCRIBER_MESSAGES_REQUEST =
  "FETCH_SUBSCRIBER_MESSAGES_REQUEST";
export const FETCH_SUBSCRIBER_MESSAGES_SUCCESS =
  "FETCH_SUBSCRIBER_MESSAGES_SUCCESS";
export const FETCH_SUBSCRIBER_MESSAGES_FAILURE =
  "FETCH_SUBSCRIBER_MESSAGES_FAILURE";

export const FETCH_SUBSCRIBERS_REQUEST = "FETCH_SUBSCRIBERS_REQUEST";
export const FETCH_SUBSCRIBERS_SUCCESS = "FETCH_SUBSCRIBERS_SUCCESS";
export const FETCH_SUBSCRIBERS_FAILURE = "FETCH_SUBSCRIBERS_FAILURE";

export const PUT_SUBSCRIBER_REQUEST = "PUT_SUBSCRIBER_REQUEST";
export const PUT_SUBSCRIBER_SUCCESS = "PUT_SUBSCRIBER_SUCCESS";
export const PUT_SUBSCRIBER_FAILURE = "PUT_SUBSCRIBER_FAILURE";

export const DELETE_SUBSCRIBER_REQUEST = "DELETE_SUBSCRIBER_REQUEST";
export const DELETE_SUBSCRIBER_SUCCESS = "DELETE_SUBSCRIBER_SUCCESS";
export const DELETE_SUBSCRIBER_FAILURE = "DELETE_SUBSCRIBER_FAILURE";

export const SEND_MANUAL_MESSAGE_REQUEST = "SEND_MANUAL_MESSAGE_REQUEST";
export const SEND_MANUAL_MESSAGE_SUCCESS = "SEND_MANUAL_MESSAGE_SUCCESS";
export const SEND_MANUAL_MESSAGE_FAILURE = "SEND_MANUAL_MESSAGE_FAILURE";

export const JOIN_SOCKET_IO_ROOM_FOR_SUBSCRIBER =
  "JOIN_SOCKET_IO_ROOM_FOR_SUBSCRIBER";
export const LEAVE_SOCKET_IO_ROOM_FOR_SUBSCRIBER =
  "LEAVE_SOCKET_IO_ROOM_FOR_SUBSCRIBER";

export const RECEIVE_SOCKET_IO_SUBSCRIBER_MESSAGE =
  "RECEIVE_SOCKET_IO_SUBSCRIBER_MESSAGE";

export const FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_REQUEST =
  "FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_REQUEST";
export const FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_SUCCESS =
  "FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_SUCCESS";
export const FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_FAILURE =
  "FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_FAILURE";

export const FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_REQUEST =
  "FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_REQUEST";
export const FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_SUCCESS =
  "FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_SUCCESS";
export const FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_FAILURE =
  "FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_FAILURE";

export const STORE_MESSAGE_FOR_SUBSCRIBER = "STORE_MESSAGE_FOR_SUBSCRIBER";