import React from "react";
import Select from "react-select";
import { parseEditorState } from "../utilities/editor";
import omit from "lodash/omit";

class Criteria extends React.Component {
  getStepOptions = () => {
    const {
      dynamicChoices,
      selectedStepIds,
      stepId,
      dynamicSteps,
    } = this.props;

    let childStepIds = dynamicSteps.map(d => d.childConversationStepId);
    let choices = omit(dynamicChoices, childStepIds);

    if (!stepId) {
      choices = omit(choices, selectedStepIds);
    } else {
      choices = omit(
        choices,
        selectedStepIds.filter(s => s !== stepId)
      );
    }

    return Object.keys(choices).map(k => ({
      value: k,
      label: parseEditorState(dynamicChoices[k].label),
    }));
  };

  getResponseOptions = () => {
    const { stepId, dynamicChoices } = this.props;

    let responseOptions = (
      dynamicChoices[stepId] || { choices: [] }
    ).choices.map(dc => ({
      label: dc,
      value: dc,
    }));
    responseOptions.push({ label: "Anything", value: "" });
    return responseOptions;
  };

  render() {
    var { stepId, response } = this.props;

    // Turn strings (legacy format) into a single element array (new format) to avoid issues with commas (",") that appear in strings 
    if(!Array.isArray(response)) {
      response = [response]
    }

    return (
      <tr key={stepId}>
        <td>
          <Select
            clearable={false}
            value={stepId}
            label="ok"
            options={this.getStepOptions()}
            onChange={({ value }) => this.props.onStepChange(value)}
          />
        </td>
        <td>
          <Select
            clearable={false}
            options={this.getResponseOptions()}
            onChange={( selections ) => this.props.onResponseChange(selections.map(x => x.value))}
            value={response}
            multi
          />
        </td>
        <td>
          <button
            onClick={this.props.onDelete}
            className="paloma-button subtract"
          >
            -
          </button>
        </td>
      </tr>
    );
  }
}

export default Criteria;
