export const FETCH_WELCOME_MESSAGES_REQUEST = "FETCH_WELCOME_MESSAGES_REQUEST";
export const FETCH_WELCOME_MESSAGES_SUCCESS = "FETCH_WELCOME_MESSAGES_SUCCESS";
export const FETCH_WELCOME_MESSAGES_FAILURE = "FETCH_WELCOME_MESSAGES_FAILURE";

export const FETCH_WELCOME_MESSAGE_REQUEST = "FETCH_WELCOME_MESSAGE_REQUEST";
export const FETCH_WELCOME_MESSAGE_SUCCESS = "FETCH_WELCOME_MESSAGE_SUCCESS";
export const FETCH_WELCOME_MESSAGE_FAILURE = "FETCH_WELCOME_MESSAGE_FAILURE";

export const CREATE_WELCOME_MESSAGE_REQUEST = "CREATE_WELCOME_MESSAGE_REQUEST";
export const CREATE_WELCOME_MESSAGE_SUCCESS = "CREATE_WELCOME_MESSAGE_SUCCESS";
export const CREATE_WELCOME_MESSAGE_FAILURE = "CREATE_WELCOME_MESSAGE_FAILURE";

export const UPDATE_WELCOME_MESSAGE_REQUEST = "UPDATE_WELCOME_MESSAGE_REQUEST";
export const UPDATE_WELCOME_MESSAGE_SUCCESS = "UPDATE_WELCOME_MESSAGE_SUCCESS";
export const UPDATE_WELCOME_MESSAGE_FAILURE = "UPDATE_WELCOME_MESSAGE_FAILURE";

export const DELETE_WELCOME_MESSAGE_REQUEST = "DELETE_WELCOME_MESSAGE_REQUEST";
export const DELETE_WELCOME_MESSAGE_SUCCESS = "DELETE_WELCOME_MESSAGE_SUCCESS";
export const DELETE_WELCOME_MESSAGE_FAILURE = "DELETE_WELCOME_MESSAGE_FAILURE";
