import React, { Component } from "react";
import _ from "underscore";

import { combineObjects } from "../utilities/general";
import { Error } from "./shared";

import QuickReply from "./QuickReply";

class QuickReplies extends Component {
  render() {
    const {
      quickReplies,
      lengthError,
      errors,
      random,
      refreshSteps,
      conversationStep,
    } = this.props;

    console.log(conversationStep);

    return (
      <div>
        <Error error={lengthError} />
        <div className="quick-replies">
          {quickReplies.map((quickReply, index) => (
            <QuickReply
              quickReply={quickReply}
              key={quickReply.id}
              errors={errors[index]}
              random={random}
              refreshSteps={refreshSteps}
              index={index}
              conversationStep={conversationStep}
            />
          ))}
        </div>
        {_.pairs(combineObjects(errors)).map((error) => {
          return <Error error={error[1]} key={error[0]} />;
        })}
      </div>
    );
  }
}

QuickReplies.defaultProps = { quickReplies: [] };

export default QuickReplies;
