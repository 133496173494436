import React from "react";

import { Link } from "react-router-dom";

export default ({ conversationStep }) => (
  <div className="conversation-step__bot-message">
    <Link
      to={`/conversations/${conversationStep.conversationId}/dynamic/${
        conversationStep.conversationStepId
      }/build`}
      className="paloma-button"
    >
      Edit Dynamic Step
    </Link>
  </div>
);
