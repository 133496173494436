import React from "react";
import PropTypes from "prop-types";
import { Creatable } from "react-select";

import TargetFilter from "./TargetFilter";
import { Card, ContentHeader } from "../../components/shared";
import ConfirmationModal from "../ConfirmationModal";
import ConversationScheduler from "../../containers/ConversationScheduler";

class PublishOptions extends React.Component {
  render = () => {
    const {
      conversation,
      conversations,
      globalTriggers,
      triggers,
      handleAddTargetFilter,
      handleChangeConditionDropdown,
      handleChangeFieldDropdown,
      handleChangeList,
      handleChangeMatchSettingRadio,
      handleChangeTargetFilterValue,
      handleClickPublish,
      handleDeleteTargetFilter,
      organizationFields,
      subscriberListOptions,
    } = this.props;

    return (
      <Card className="info-panel">
        <div className="conversation-publisher">
          <ContentHeader
            left={<h3>Recipients</h3>}
            right={this.props.targetingDiv}
          />
          <div className="conversation-publisher__choose-a-list card__section">
            <h4 className="card__section-title">Choose a list</h4>
            <Creatable
              className="conversation-publisher__subscriber-list-select"
              value={
                (conversation.targetSubscriberList || {}).subscriberListId ||
                "All"
              }
              options={subscriberListOptions}
              onChange={handleChangeList}
              clearable={false}
              openOnFocus={false}
              openAfterFocus={false}
            />
          </div>
          <div className="conversation-publisher__create-a-segment card__section">
            <div>
              <h4 className="card__section-title">Create a segment</h4>
              {!!(conversation.targetFilters || []).length && (
                <div className="conversation-publisher__match-settings">
                  <div>Match the settings below</div>
                  <div className="horizontal-radio-group">
                    <div className="radio-option">
                      <input
                        checked={
                          conversation.targetFilterMatchSetting === "All"
                        }
                        className="paloma-radio"
                        id="all-radio"
                        name="targetFilterMatchSetting"
                        onChange={handleChangeMatchSettingRadio.bind(
                          null,
                          "All"
                        )}
                        type="radio"
                        value="All"
                      />
                      <label className="paloma-radio-label" htmlFor="all-radio">
                        All
                      </label>
                    </div>
                    <div className="radio-option">
                      <input
                        checked={
                          conversation.targetFilterMatchSetting === "Any"
                        }
                        className="paloma-radio"
                        id="any-radio"
                        name="targetFilterMatchSetting"
                        onChange={handleChangeMatchSettingRadio.bind(
                          null,
                          "Any"
                        )}
                        type="radio"
                        value="Any"
                      />
                      <label className="paloma-radio-label" htmlFor="any-radio">
                        Any
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="conversation-publisher__target-filter-options">
                {(conversation.targetFilters || []).map((targetFilter, i) => {
                  return (
                    <TargetFilter
                      handleChangeConditionDropdown={
                        handleChangeConditionDropdown
                      }
                      handleChangeFieldDropdown={handleChangeFieldDropdown}
                      handleChangeTargetFilterValue={
                        handleChangeTargetFilterValue
                      }
                      handleDeleteTargetFilter={handleDeleteTargetFilter}
                      key={targetFilter.targetFilterId}
                      targetFilter={targetFilter}
                      conversations={conversations}
                      globalTriggers={globalTriggers}
                      triggers={triggers}
                      organizationFields={organizationFields}
                    />
                  );
                })}
                <button
                  className="paloma-button"
                  onClick={handleAddTargetFilter}
                >
                  + Add
                </button>
              </div>
            </div>
          </div>
          <ConversationScheduler conversationId={conversation.conversationId} />
          <ConfirmationModal
            onConfirm={handleClickPublish}
            schedule={conversation.sendAt}
          />
        </div>
      </Card>
    );
  };
}

PublishOptions.propTypes = {
  conversation: PropTypes.object.isRequired,
  handleAddTargetFilter: PropTypes.func.isRequired,
  handleChangeConditionDropdown: PropTypes.func.isRequired,
  handleChangeFieldDropdown: PropTypes.func.isRequired,
  handleChangeList: PropTypes.func.isRequired,
  handleChangeMatchSettingRadio: PropTypes.func.isRequired,
  handleChangeTargetFilterValue: PropTypes.func.isRequired,
  handleClickPublish: PropTypes.func.isRequired,
  handleDeleteTargetFilter: PropTypes.func.isRequired,
  subscriberListOptions: PropTypes.array.isRequired,
};

export default PublishOptions;
