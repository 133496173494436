export const FETCH_SUBSCRIBER_LIST_REQUEST = "FETCH_SUBSCRIBER_LIST_REQUEST";
export const FETCH_SUBSCRIBER_LIST_SUCCESS = "FETCH_SUBSCRIBER_LIST_SUCCESS";
export const FETCH_SUBSCRIBER_LIST_FAILURE = "FETCH_SUBSCRIBER_LIST_FAILURE";

export const FETCH_SUBSCRIBER_LISTS_REQUEST = "FETCH_SUBSCRIBER_LISTS_REQUEST";
export const FETCH_SUBSCRIBER_LISTS_SUCCESS = "FETCH_SUBSCRIBER_LISTS_SUCCESS";
export const FETCH_SUBSCRIBER_LISTS_FAILURE = "FETCH_SUBSCRIBER_LISTS_FAILURE";

export const PUT_SUBSCRIBER_LIST_REQUEST = "PUT_SUBSCRIBER_LIST_REQUEST";
export const PUT_SUBSCRIBER_LIST_SUCCESS = "PUT_SUBSCRIBER_LIST_SUCCESS";
export const PUT_SUBSCRIBER_LIST_FAILURE = "PUT_SUBSCRIBER_LIST_FAILURE";

export const POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_REQUEST = "POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_REQUEST";
export const POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_SUCCESS = "POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_SUCCESS";
export const POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_FAILURE = "POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_FAILURE";

export const ADD_SUBSCRIBER_TO_LIST_REQUEST = "ADD_SUBSCRIBER_TO_LIST_REQUEST";
export const ADD_SUBSCRIBER_TO_LIST_SUCCESS = "ADD_SUBSCRIBER_TO_LIST_SUCCESS";
export const ADD_SUBSCRIBER_TO_LIST_FAILURE = "ADD_SUBSCRIBER_TO_LIST_FAILURE";

export const REMOVE_SUBSCRIBER_FROM_LIST_REQUEST =
  "REMOVE_SUBSCRIBER_FROM_LIST_REQUEST";
export const REMOVE_SUBSCRIBER_FROM_LIST_SUCCESS =
  "REMOVE_SUBSCRIBER_FROM_LIST_SUCCESS";
export const REMOVE_SUBSCRIBER_FROM_LIST_FAILURE =
  "REMOVE_SUBSCRIBER_FROM_LIST_FAILURE";

export const DELETE_SUBSCRIBER_LIST_REQUEST = "DELETE_SUBSCRIBER_LIST_REQUEST";
export const DELETE_SUBSCRIBER_LIST_SUCCESS = "DELETE_SUBSCRIBER_LIST_SUCCESS";
export const DELETE_SUBSCRIBER_LIST_FAILURE = "DELETE_SUBSCRIBER_LIST_FAILURE";

export const FETCH_SUBSCRIBER_LIST_DOWNLOAD_TOKEN_REQUEST =
  "FETCH_SUBSCRIBER_LIST_DOWNLOAD_TOKEN_REQUEST";
export const FETCH_SUBSCRIBER_LIST_DOWNLOAD_TOKEN_SUCCESS =
  "FETCH_SUBSCRIBER_LIST_DOWNLOAD_TOKEN_SUCCESS";
export const FETCH_SUBSCRIBER_LIST_DOWNLOAD_TOKEN_FAILURE =
  "FETCH_SUBSCRIBER_LIST_DOWNLOAD_TOKEN_FAILURE";

export const CREATE_ACTIVE_SUBSCRIBER_LIST_REQUEST =
  "CREATE_ACTIVE_SUBSCRIBER_LIST_REQUEST";
export const CREATE_ACTIVE_SUBSCRIBER_LIST_SUCCESS =
  "CREATE_ACTIVE_SUBSCRIBER_LIST_SUCCESS";
export const CREATE_ACTIVE_SUBSCRIBER_LIST_FAILURE =
  "CREATE_ACTIVE_SUBSCRIBER_LIST_FAILURE";

export const STORE_SUBSCRIBER_IN_LIST = "STORE_SUBSCRIBER_IN_LIST";