import React from "react";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import { push } from "react-router-redux";
import Loader from "react-loader";
import humps from "humps";
import { connect } from "react-redux";
import { hideErrors, showErrors } from "../../actions/builder-errors";
import { fetchOrganizationFields } from "../../actions/organization-fields";
import ConversationStep from "./WMConversationStep";
import { showSuccessAlert } from "../../actions/alerts";
import {
  fetchConversation,
  fetchVisibleConversationSteps,
  putConversationStep,
  duplicateConversation,
} from "../../actions/conversations";
import { ContentHeader, Toggle } from "../../components/shared";
import merge from "lodash/merge";
import get from "lodash/get";
import { ConversationDetailsSummary } from "../../components/ConversationDetail";
import { ShowDisplayErrorContext } from "../../utilities/contexts"

class WelcomeMessageConversationBuilder extends React.Component {
  componentDidMount() {
    const { conversationId } = this.props;

    this.props.fetchConversation(conversationId);
    this.props.fetchVisibleConversationSteps(conversationId);
    this.props.fetchOrganizationFields();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.conversationId !== nextProps.conversationId) {
      this.props.fetchConversation(nextProps.conversationId);
      this.props.fetchVisibleConversationSteps(nextProps.conversationId);
    }
  }

  toCopy = () => {
    const { stepIds } = this.props.visibleConversationSteps;
    const steps = stepIds.map((id) => this.props.conversationStepsById[id]);

    return {
      message: {
        ...humps.decamelizeKeys(
          merge(steps[0].rendered, get(steps, "[1].rendered", {}))
        ),
      },
    };
  };

  copyToClipboard = (e) => {
    this.textArea.value = JSON.stringify(this.toCopy(), null, 4);
    this.textArea.select();
    document.execCommand("copy");
    e.target.focus();
    this.props.showSuccessAlert("Copied!");
  };

  onDuplicate = () => {
    const { conversationId, welcomeMessageId } = this.props;

    this.props
      .duplicateConversation({ conversationId })
      .then(({ payload: { conversationId: duplicateConversationId } }) => {
        this.props.push(
          `/entrypoints/wm/${welcomeMessageId}/conversations/${duplicateConversationId}/details`
        );
      });
  };

  render() {
    const {
      conversation,
      conversationId,
      visibleConversationSteps: {
        isLoading: stepsAreLoading,
        stepIds: visibleConversationStepIds = [],
      },
    } = this.props;

    const loading = !conversation || conversation.isLoading || stepsAreLoading;
    const isTextWelcomeMessage = !!(visibleConversationStepIds.length <= 1);

    return (
      <Loader loaded={!loading}>
        <ShowDisplayErrorContext.Provider value={this.props.areShown}>
          <ContentHeader
            left={<h1 className="page-title">{conversation.label}</h1>}
            right={
              <div className="conversation-detail-action-buttons">
                <button className="paloma-button" onClick={() => ""}>
                  Delete
                </button>

                <Link className="paloma-button" to="edit">
                  Edit details
                </Link>

                <button className="paloma-button" onClick={this.onDuplicate}>
                  Duplicate
                </button>
                <button
                  className="paloma-button"
                  onClick={this.copyToClipboard}
                >
                  Copy JSON
                </button>
                <form>
                  <textarea
                    style={{ position: "absolute", right: "-10000px" }}
                    readOnly
                    ref={(textArea) => (this.textArea = textArea)}
                    value=""
                  />
                </form>
              </div>
            }
          />

          <ConversationDetailsSummary conversation={conversation} />

          <div className="conversation-builder" style={{ minHeight: "300px" }}>
            <div
              className="conversation-builder__main"
              style={{ width: "60%", padding: "20px" }}
            >
              <div className="conversation-steps">
                {visibleConversationStepIds.length ? (
                  <div style={{ position: "relative" }}>
                    {visibleConversationStepIds.map((stepId, i) => (
                      <ConversationStep
                        conversationId={conversationId}
                        conversationStepId={stepId}
                        isTextWelcomeMessage={isTextWelcomeMessage}
                        editable={!conversation.published}
                        key={stepId}
                        refreshSteps={() =>
                          this.props.fetchVisibleConversationSteps(
                            conversationId
                          )
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <div>no conversation steps</div>
                )}
              </div>
              <div className="section errors" style={{ marginTop: "10px" }}>
                Display Errors{" "}
                <Toggle
                  checked={this.props.areShown}
                  onChange={() => {
                    if (this.props.areShown) {
                      this.props.hideErrors();
                    } else {
                      this.props.showErrors();
                    }
                  }}
                  onLabel="ON"
                  offLabel="OFF"
                />
              </div>
            </div>
            {visibleConversationStepIds.length && (
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "15px",
                  padding: "20px",
                }}
              >
                <h3 className="subscriber-flow__heading">JSON Viewer</h3>
                <ReactJson
                  src={this.toCopy()}
                  name={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  collapseStringsAfterLength={100}
                  iconStyle="circle"
                  indentWidth={4}
                />
              </div>
            )}
          </div>
        </ShowDisplayErrorContext.Provider>
      </Loader>
    );
  }
}

export default connect(
  (state, props) => {
    const { conversationId, welcomeMessageId } = props.match.params;

    const {
      conversations: {
        conversationsById,
        conversationStepsById,
        visibleConversationStepsByConversationId,
      },
      builderErrors: { areShown },
    } = state;

    const conversation = {
      isLoading: true,
      ...conversationsById[conversationId],
    };

    const visibleConversationSteps = {
      isLoading: true,
      ...visibleConversationStepsByConversationId[conversationId],
    };

    return {
      areShown,
      conversationId,
      conversation,
      visibleConversationSteps,
      conversationStepsById,
      welcomeMessageId,
    };
  },
  {
    hideErrors,
    showErrors,
    fetchConversation,
    fetchVisibleConversationSteps,
    putConversationStep,
    showSuccessAlert,
    fetchOrganizationFields,
    push,
    duplicateConversation,
  }
)(WelcomeMessageConversationBuilder);
