export const FETCH_RECENT_CONVERSATIONS_REQUEST =
  "FETCH_RECENT_CONVERSATIONS_REQUEST";
export const FETCH_RECENT_CONVERSATIONS_SUCCESS =
  "FETCH_RECENT_CONVERSATIONS_SUCCESS";
export const FETCH_RECENT_CONVERSATIONS_FAILURE =
  "FETCH_RECENT_CONVERSATIONS_FAILURE";

export const FETCH_DASHBOARD_STATS_REQUEST = "FETCH_DASHBOARD_STATS_REQUEST";
export const FETCH_DASHBOARD_STATS_SUCCESS = "FETCH_DASHBOARD_STATS_SUCCESS";
export const FETCH_DASHBOARD_STATS_FAILURE = "FETCH_DASHBOARD_STATS_FAILURE";

export const FETCH_SHOPIFY_STATS_REQUEST = "FETCH_SHOPIFY_STATS_REQUEST";
export const FETCH_SHOPIFY_STATS_SUCCESS = "FETCH_SHOPIFY_STATS_SUCCESS";
export const FETCH_SHOPIFY_STATS_FAILURE = "FETCH_SHOPIFY_STATS_FAILURE";
