const DEFAULT_PER_PAGE = 20;

export default function paginate(entities, perPage = DEFAULT_PER_PAGE) {
  return entities.reduce(
    ({ entitiesByPage, totalPages }, entity) => {
      if (entitiesByPage[totalPages].length >= perPage) {
        totalPages += 1;
        entitiesByPage[totalPages] = [];
      }
      return {
        totalPages,
        entitiesByPage: {
          ...entitiesByPage,
          [totalPages]: [...entitiesByPage[totalPages], entity],
        },
      };
    },
    { entitiesByPage: { 1: [] }, totalPages: 1 }
  );
}
