import React from "react";
import classNames from "classnames";

const MessageBubble = ({ children, className }) => (
  <div className={classNames("message-bubble-wrapper", className)}>
    <div className={classNames("message-bubble", className)}>{children}</div>
  </div>
);

const TextMessage = ({ className, text }) => (
  <MessageBubble className={className}>{text}</MessageBubble>
);

const ImageMessage = ({ className, imgUrl }) => (
  <MessageBubble className={className}>
    <img className="image" src={imgUrl} alt="" />
  </MessageBubble>
);

const VideoMessage = ({ className, videoUrl }) => (
  <MessageBubble className={className}>
    <video className="video" src={videoUrl} />
  </MessageBubble>
);

const FileMessage = ({ className, name, fileUrl }) => (
  <MessageBubble className={className}>
    <a className="file" href={fileUrl} target="_blank">
      {name || fileUrl}
    </a>
  </MessageBubble>
);

const TemplateMessage = ({ payload, className }) => {
  if (payload.templateType === "button") {
    return (
      <div className="button-template">
        <MessageBubble className={className}>{payload.text}</MessageBubble>
        <div className="buttons">
          {(payload.buttons || []).map((button, i) => (
            <div className="button" key={i}>
              {button.title}
            </div>
          ))}
        </div>
      </div>
    );
  } else if (payload.templateType === "generic") {
    return (
      <div className="generic-template">
        {payload.elements.map((element, i) => (
          <div key={i} className="conversation-step__template-card">
            <div>
              <div
                alt=""
                className="conversation-step__template-card__image"
                width="100%"
                style={{
                  backgroundImage: `url(${element.imageUrl})`,
                  backgroundPosition: "50% 50%",
                  backgroundSize: "cover",
                  position: "relative",
                  height: "100px",
                }}
              />
            </div>
            <div className="conversation-step__template-card__title">
              {element.title}
            </div>
            <div className="conversation-step__template-card__subtitle">
              {element.subtitle}
            </div>
            <div className="buttons conversation-step__buttons--no-margin">
              {(element.buttons || []).map((button, i) => (
                <div className="button" key={i}>
                  {button.title}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="message-bubble">{JSON.stringify(payload, 2, 2)}</div>
    );
  }
};

const QuickReply = quickReply => {
  let content;
  if (quickReply.contentType === "user_email") {
    content = "Email";
  } else if (quickReply.contentType === "user_phone_number") {
    content = "Phone";
  } else {
    content = quickReply.title;
  }

  return (
    <div className="quick-reply-container" key={content}>
      <div className="quick-reply">{content}</div>
    </div>
  );
};

const content = (message, className) => {
  switch (true) {
    case !message.content:
      return <TextMessage className={className} text="" />;
    case typeof message.content === "string":
      return <TextMessage className={className} text={message.content} />;
    case !!message.content.text:
      return <TextMessage className={className} text={message.content.text} />;
    case !!message.content.attachment:
      return render_attachment(message.content.attachment, className)
    case !!message.content.attachments:
      return message.content.attachments.map(e => render_attachment(e, className))
    default:
      return <TextMessage className={className} text="" />;
  }
};

const render_attachment = (attachment, className) => {
  switch (attachment.type) {
    case "image":
      return (
        <ImageMessage
          className={className}
          imgUrl={attachment.payload.url}
        />
      )
    case "video":
      return (
        <VideoMessage
          className={className}
          videoUrl={attachment.payload.url}
        />
      )
    case "file":
      return (
        <FileMessage
          className={className}
          fileUrl={attachment.payload.url}
          name={attachment.payload.name}
        />
      )
    case "template":
      return (
        <TemplateMessage
          payload={attachment.payload}
          className={className}
        />
      )
    default:
      return null
  }
}

const Message = ({ message }) => {
  const className = classNames({
    subscriber: message.sentBy === "subscriber",
    paloma: message.sentBy === "paloma",
    sending: message.status === "sending",
  });

  return (
    <div className={classNames("message-container", className)}>
      <div className="message-content">{content(message, className)}</div>
      {message.content &&
        message.content.quickReplies &&
        message.content.quickReplies.length && (
          <div className="quick-replies-container">
            <div className="quick-replies">
              {message.content.quickReplies.map(QuickReply)}
            </div>
          </div>
        )}
    </div>
  );
};

export default Message;
