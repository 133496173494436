import React from "react";
import { connect } from "react-redux";

import { fetchOrganizationFields } from "../../actions/organization-fields";
import { fetchConversationsLabel } from "../../actions/conversations";
import { fetchGlobalTriggers } from "../../actions/global-triggers";
import { fetchOrganizationUsers } from "../../actions/current-organization";
import TargetFilter from "../ConversationPublisher/TargetFilter";

class SubscriberFilter extends React.Component {
  state = {
    conversationsLabel: [],
  };
  componentDidMount() {
    this.props.fetchOrganizationFields();
    this.props.fetchConversationsLabel().then(({ payload }) => {
      this.setState({ conversationsLabel: payload });
    });
    this.props.fetchGlobalTriggers();
    this.props.fetchOrganizationUsers();
  }

  handleDelete = (index, e) => {
    e.preventDefault();
    const { targetFilters } = this.props;
    targetFilters.splice(index, 1);
    this.props.onChange(targetFilters);
  };

  handleChange = (index, key, value) => {
    const { targetFilters } = this.props;
    if (targetFilters[index][key] !== value) {
      targetFilters[index][key] = value;
      this.props.onChange(targetFilters);
    }
  };

  handleNewTargetFilter = e => {
    e.preventDefault();
    const { targetFilters } = this.props;

    this.props.onChange([
      ...targetFilters,
      {
        conditionDropdown: null,
        fieldDropdown: null,
        value: null,
      },
    ]);
  };

  render() {
    const {
      targetFilters,
      organizationFields,
      globalTriggers,
      matchSetting,
      setMatchSetting,
    } = this.props;

    const uniq_name = "targetFilterMatchSetting_"+Math.random().toString(36).substring(2, 15)

    return (
      <React.Fragment>
        <div>
          <div className="conversation-publisher__match-settings">
            <div>Match the settings below</div>
            <div className="horizontal-radio-group">
              <div className="radio-option">
                <input
                  checked={matchSetting === "All"}
                  className="paloma-radio"
                  id="all-radio"
                  name={uniq_name}
                  onChange={() => setMatchSetting("All")}
                  type="radio"
                  value="All"
                />
                <label className="paloma-radio-label" htmlFor="all-radio">
                  All
                </label>
              </div>
              <div className="radio-option">
                <input
                  checked={matchSetting === "Any"}
                  className="paloma-radio"
                  id="any-radio"
                  name={uniq_name}
                  onChange={() => setMatchSetting("Any")}
                  type="radio"
                  value="Any"
                />
                <label className="paloma-radio-label" htmlFor="any-radio">
                  Any
                </label>
              </div>
            </div>
          </div>
          {targetFilters.map((filter, i) => {
            if(this.props.filterRowComponent) {
              return this.props.filterRowComponent(filter, i, (field, value) => this.handleChange(i, field, value), (e) => this.handleDelete(i, e))
            } else {
              return (
                <TargetFilter
                  handleChangeConditionDropdown={(_, value) => {
                    this.handleChange(i, "conditionDropdown", value);
                    this.handleChange(i, "value", null);
                  }}
                  handleChangeFieldDropdown={(_, value) => {
                    this.handleChange(i, "fieldDropdown", value);
                    this.handleChange(i, "conditionDropdown", null);
                    this.handleChange(i, "value", null);
                  }}
                  handleChangeTargetFilterValue={(_, value) =>
                    this.handleChange(i, "value", value)
                  }
                  handleDeleteTargetFilter={(_, e) => this.handleDelete(i, e)}
                  key={i}
                  targetFilter={filter}
                  conversations={this.state.conversationsLabel}
                  globalTriggers={globalTriggers}
                  organizationFields={organizationFields}
                  fieldOptions={this.props.fieldOptions}
                />
              )
            }
          })}
        </div>
        <button
          className="paloma-button"
          onClick={this.handleNewTargetFilter}
          disabled={this.props.addButtonDisabled || false}
        >
          + Add
        </button>
      </React.Fragment>
    );
  }
}

export default connect(
  (state, props) => {
    const {
      organizationFields: { organizationFields },
      conversations: { conversationsById },
    } = state;

    const conversations = Object.values(conversationsById);
    const globalTriggers = Object.values(
      state.globalTriggers.globalTriggersById
    );

    return { organizationFields, conversations, globalTriggers };
  },
  {
    fetchOrganizationFields,
    fetchConversationsLabel,
    fetchGlobalTriggers,
    fetchOrganizationUsers,
  }
)(SubscriberFilter);