import React from "react";
import classNames from "classnames";
import PaginatedList from "../PaginatedList";
import {
  SubscriberStatus,
  SubscriberChatStatus,
} from "../../components/shared/SubscriberStatus";
import SubscriberOwner from "../../components/shared/SubscriberOwner";
import { timeOnly, dateOnly } from "../../utilities/format-date";
import { Search } from "../../components/shared";
import { fetchOrganizationUsers } from "../../actions/current-organization";
import { connect } from "react-redux";
import { capitalizeString } from "../../utilities/format-string";
class SubscriberTable extends React.Component {
  constructor(props) {
    super(props);

    this.headers = this.headers.bind(this);
  }

  state = {
    selected: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.selected === null && props.subscribers.length) {
      return { ...state, selected: props.subscribers[0].subscriberId };
    }

    return null;
  }

  async componentDidMount() {
    if (this.props.subscribers.length) {
      this.setState({
        selected: this.props.subscribers[0].subscriberId,
      });
    }
    this.props.fetchOrganizationUsers();
  }

  onHeaderClick = header => {
    const { sortBy, order } = this.props;

    if (header === sortBy) {
      this.props.fetch({ order: order === "ASC" ? "DESC" : "ASC" });
    } else {
      this.props.fetch({ page: 1, sortBy: header, order: "ASC" });
    }
  };

  headers = () => {
    const { excludes = [] } = this.props;
    return [
      "name",
      "received",
      "sent",
      "subscribed",
      "status",
      "chat",
      "owners",
    ].filter(h => !excludes.includes(h));
  };

  sortableHeaders = () => {
    const { sortBy, order } = this.props;
    const headers = this.headers().map(header =>
      header !== "owners" ? (
        <span
          className={classNames({
            selected: header === sortBy,
            sortable: true,
            "text-xs": true,
          })}
          onClick={this.onHeaderClick.bind(this, header)}
          style={{ lineHeight: "18px" }}
        >
          {capitalizeString(header)}&nbsp;
          <div style={{ position: "relative", display: "inline" }}>
            <div
              className={classNames({
                arrow: header === sortBy,
                up: order !== "ASC",
                down: order === "ASC",
              })}
            />
          </div>
        </span>
      ) : (
        <span className="text-sm" style={{ lineHeight: "18px" }}>
          Owner&nbsp;
        </span>
      )
    );

    return headers;
  };

  render() {
    const { selected } = this.state;
    const { searchable, sortable } = this.props;

    return (
      <div>
        <div
          className="conversation-publisher__num-targeted"
          style={{ float: "right" }}
        >
          {this.props.total || this.props.subscribers.length} subscribers
        </div>
        {searchable === undefined || searchable === true ? (
          <div className="table-controls">
            <Search
              className="table-controls__search"
              query={this.props.search}
              onSearch={(search, page = 1) =>
                this.props.fetch({ search, page })
              }
              placeholder="Search by name"
            />
          </div>
        ) : null}
        <PaginatedList
          headers={
            sortable === true || sortable === undefined
              ? this.sortableHeaders()
              : this.headers().map(capitalizeString)
          }
          controlPaginationExternally
          items={this.props.subscribers}
          component={item => (
            <SubscriberRow
              columns={this.headers()}
              key={item.subscriberId}
              subscriber={item}
              selected={selected}
              unread={(this.props.unreadSubscriberIds || []).includes(item.subscriberId)}
              onClick={selected => {
                this.setState({ selected }, () =>
                  this.props.onRowClick(selected)
                );
              }}
              owners={this.props.owners}
            />
          )}
          onChoosePage={page => this.props.fetch({ page })}
          currentPage={this.props.page}
          totalPages={this.props.totalPages}
          className="subscribersList"
        />
      </div>
    );
  }
}

const SubscriberRow = ({ selected, onClick, owners, subscriber, columns, unread }) => {
  const { subscriberId, fullName } = subscriber;
  const classes = classNames({
    selected: subscriberId === selected,
    table__row: true,
    "text-sm": true,
    unread: (unread && subscriber.manualMessagingEnabled)
  });

  const rowColumnMap = {
    name: () => (
      <td key="name" className="table__data">
        <a
          className="table__link"
          href={`/audience/subscribers/${subscriberId}`}
        >
          {fullName}
        </a>
      </td>
    ),
    received: () => (
      <td key="received" className="table__data time text-xs">
        <strong>{dateOnly(subscriber.lastMessageReceivedAt)}</strong> <br />
        {timeOnly(subscriber.lastMessageReceivedAt)}
      </td>
    ),
    sent: () => (
      <td key="sent" className="table__data time text-xs">
        <strong>{dateOnly(subscriber.lastMessageSentAt)}</strong> <br />
        {timeOnly(subscriber.lastMessageSentAt)}
      </td>
    ),
    subscribed: () => (
      <td key="subscribed" className="table__data time text-xs">
        <strong>{dateOnly(subscriber.createdAt)}</strong> <br />
        {timeOnly(subscriber.createdAt)}
      </td>
    ),

    status: () => (
      <td key="status" className="table__data">
        <SubscriberStatus
          status={subscriber.status}
          manualMessaging={subscriber.manualMessagingEnabled}
        />
      </td>
    ),
    chat: () => (
      <td key="chat" className="table__data" style={{ textAlign: "left" }}>
        <SubscriberChatStatus
          manualMessaging={subscriber.manualMessagingEnabled}
          standby={subscriber.standby}
        />
      </td>
    ),
    owners: () => (
      <td
        key="owners"
        className="table__data text-xs"
        style={{ textAlign: "left" }}
      >
        <SubscriberOwner subscriber={subscriber} owners={owners} />
      </td>
    ),
  };

  return (
    <tr
      className={classes}
      key={subscriberId}
      onClick={() => onClick(subscriberId)}
    >
      {columns.map(col => rowColumnMap[col.toLowerCase()]())}
    </tr>
  );
};

export default connect(
  state => ({
    owners: state.currentOrganization.users,
  }),
  { fetchOrganizationUsers }
)(SubscriberTable);
