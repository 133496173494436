import React from "react";
import { FacebookLoginButton } from "../components/shared";

class ConfigDebug extends React.Component {
  state = { pages: [] };

  render = () => (
    <div>
      <FacebookLoginButton
        className="paloma-button paloma-button--pink"
        text="Debug your connection"
        onFacebookLogin={this.handleFacebookLogin}
      />
      <hr />
      Your debug information will appear here:
      <hr />
      <pre
        style={{
          padding: "20px",
          border: "1px solid black",
          background: "white",
          color: "black",
          fontFamily: "monospace",
          fontSize: "8px",
        }}
      >
        {this.state.pages.map(page => (
          <span key={page.id}>{JSON.stringify(page)}</span>
        ))}
      </pre>
    </div>
  );

  handleFacebookLogin = ({ pages }) => {
    this.setState({ pages: pages });
  };
}

export default ConfigDebug;
