import React from "react";

import { SelectOptionModal } from "../shared";

export default ({ subscriberLists, isOpen, onClose, onAddToList }) => {
  const options = subscriberLists.map(subscriberList => ({
    name: subscriberList.name,
    value: subscriberList.subscriberListId,
  }));

  return (
    <SelectOptionModal
      title="Select a list"
      saveButtonText="Add"
      isOpen={isOpen}
      onClose={onClose}
      onSave={onAddToList}
      options={options}
    />
  );
};
