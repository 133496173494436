import React, { Component } from "react";
import classNames from "classnames";

import { MediaUploadModal } from "../shared";

class MediaMessage extends Component {
  state = {
    displayUploadModal: false,
  };

  render() {
    const {
      editable,
      conversationStep: { imageUrl, attachmentType },
    } = this.props;

    const { displayUploadModal } = this.state;

    const imageMessageClassName = classNames(
      "conversation-step__image-message",
      {
        "conversation-step__image-message--editable": editable,
      }
    );

    return (
      <div className="conversation-step__bot-message">
        <div className={imageMessageClassName}>
          <div
            className="conversation-step__image-message__dropzone"
            onClick={this.showUploadModal}
          >
            {attachmentType === "video" && (
              <video>
                <source src={imageUrl} type="video/mp4" />
              </video>
            )}
            {attachmentType === "image" && <img alt="" src={imageUrl} />}
          </div>
        </div>

        {displayUploadModal && (
          <MediaUploadModal
            onClose={this.hideUploadModal}
            onUpload={this.uploadMedia}
          />
        )}
      </div>
    );
  }

  showUploadModal = () => this.setState({ displayUploadModal: true });
  hideUploadModal = () => this.setState({ displayUploadModal: false });

  uploadMedia = file => {
    return this.props.uploadConversationStepMedia(file);
  };
}

export default MediaMessage;
