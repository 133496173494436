import React from "react";
import { Link } from "react-router-dom";

import GlobalTriggersTableRowButtons from "./GlobalTriggersTableRowButtons";
import { Table } from "../shared";
import { formatDate } from "../../utilities/format-date";

const rows = (globalTriggers, globalUnexpectedLastRanAt) => {
  const base = globalTriggers.map(gt => [
    <Link className="table__link" to={`/global/${gt.triggerId}`}>
      {gt.name}
    </Link>,
    formatDate(gt.lastRanAt) || "Never",
    <GlobalTriggersTableRowButtons globalTrigger={gt} />,
  ]);

  return [
    ...base,
    [
      <Link className="table__link" to={"/global/unexpected"}>
        Unexpected
      </Link>,
      formatDate(globalUnexpectedLastRanAt) || "Never",
      <Link className="table__link" to={"/global/unexpected"}>
        View
      </Link>,
    ],
  ];
};

const DefaultGlobalTriggersTable = ({
  globalTriggers,
  onDeleteTrigger,
  globalUnexpectedLastRanAt,
}) => (
  <Table
    headers={["Name", "Last Ran", ""]}
    rows={rows(globalTriggers, globalUnexpectedLastRanAt)}
  />
);

export default DefaultGlobalTriggersTable;
