import React from "react";
import { connect } from "react-redux";

import { clearAlerts } from "../actions/alerts";
import { showErrorAlert, showSuccessAlert } from "../actions/alerts";
import { showErrors } from "../utilities/notifications";
import { updateCurrentUser } from "../actions/current-user";
import { validatePassword } from "../utilities/password";

class AccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editingPassword: false,
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    };
  }

  render = () => {
    return (
      <div className="account">
        <form onSubmit={this.onSubmit}>
          <div>
            <label className="default-form__label" htmlFor="password">
              Password
            </label>
            {!this.state.editingPassword && (
              <div className="field">
                <input disabled type="password" placeholder="**********" />
                <button
                  className="paloma-button--pink-inverse"
                  style={{ display: "inline-block" }}
                  onClick={() => this.setState({ editingPassword: true })}
                >
                  Edit
                </button>
              </div>
            )}
            {this.state.editingPassword && (
              <div>
                <div className="field">
                  <input
                    type="password"
                    id="currentPassword"
                    placeholder="Current password"
                    onChange={this.updatePassword}
                  />
                </div>
                <div className="field">
                  <input
                    type="password"
                    id="newPassword"
                    placeholder="New password"
                    onChange={this.updateNewPassword}
                  />
                  <div className="help-text">
                    Must include 8 characters, one number and one symbol
                  </div>
                </div>
                <div className="field">
                  <input
                    type="password"
                    id="repeatNewPassword"
                    placeholder="Repeat new password"
                    onChange={this.updateRepeatNewPassword}
                  />
                </div>
                <button
                  type="submit"
                  className="paloma-button--pink"
                  style={{ marginTop: "10px" }}
                >
                  Save
                </button>
                <button className="paloma-button" onClick={this.reset}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  };

  reset = () =>
    this.setState({
      currentPassword: "",
      editingPassword: false,
      newPassword: "",
      repeatNewPassword: "",
    });

  updatePassword = e => {
    this.setState({ currentPassword: e.target.value });
  };

  updateNewPassword = e => {
    this.setState({ newPassword: e.target.value });
  };

  updateRepeatNewPassword = e => {
    this.setState({ repeatNewPassword: e.target.value });
  };

  onSubmit = async e => {
    e.preventDefault();

    this.props.clearAlerts();

    if (this.state.newPassword !== this.state.repeatNewPassword) {
      this.props.showErrorAlert("Passwords must match.");
      return;
    }

    const errors = validatePassword(this.state.newPassword);

    if (errors.length) {
      showErrors(errors, this.props.showErrorAlert);
      return;
    }

    const response = await this.props.updateCurrentUser({
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
    });

    if (!response.error) {
      this.props.showSuccessAlert("Account updated");
      this.reset();
    } else {
      showErrorAlert(response);
    }
  };
}

export default connect(
  null,
  {
    updateCurrentUser,
    clearAlerts,
    showErrorAlert,
    showSuccessAlert,
  }
)(AccountForm);
