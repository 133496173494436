import React from "react";

import { Modal } from "../shared";

export default ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose} singleSection>
    <div style={{ width: "450px", padding: "10px" }}>
      <span style={{ fontSize: "40px" }} role="img" aria-label="rocket">
        🚀
      </span>
      <h2 style={{ fontSize: "30px", marginBottom: "5px" }}>Congrats!</h2>
      <p style={{ maxWidth: "300px", margin: "0 auto 60px" }}>
        Your conversation's on its way to all your amazing subscribers.
      </p>

      <button
        onClick={onClose}
        className="paloma-button paloma-button--pink"
        style={{ width: "100%" }}
      >
        Back to business
      </button>
    </div>
  </Modal>
);
