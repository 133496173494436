import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import { closeMenu } from "../../actions/builder-menu";

class SeparatedButtonRow extends React.Component {
  state = { nested: null };
  static defaultProps = {
    buttons: [],
    onClose: () => null,
  };

  render = () => {
    if (this.isNested()) {
      return (
        <div>
          <SeparatedButtonRow
            closeMenu={() => {
              this.props.closeMenu();
            }}
            buttons={[
              {
                text: this.nestedMenu().text,
                onClick: () => this.setState({ nested: null }),
                className: "selected",
                leaveOpen: true,
              },
              ...this.nestedMenu().nested,
            ]}
          />
        </div>
      );
    } else {
      return (
        <div className="separated-button-row">
          {[
            ...this.props.buttons,
            {
              text: "\u00d7",
              onClick: () => {
                this.props.closeMenu();
              },
              className: "no-border close",
            },
          ].map(this.button)}
        </div>
      );
    }
  };

  isNested = () => {
    return this.state.nested !== null;
  };

  nestedMenu = () => {
    if (this.isNested()) {
      return this.props.buttons[this.state.nested];
    }
  };

  button = (b, i) => {
    let onClick;
    if (b.nested) {
      onClick = () => {
        this.setState({ nested: i });
      };
    } else {
      onClick = e => {
        if (!b.leaveOpen) {
          this.props.closeMenu();
        }
        return b.onClick();
      };
    }

    return (
      <div
        className={classNames("button", b.className, { selected: b.selected })}
        key={b.text}
        onClick={() => {
          onClick();
        }}
      >
        {b.text}
      </div>
    );
  };
}

export default connect(
  null,
  { closeMenu }
)(SeparatedButtonRow);
