import { normalize } from "normalizr";

import { subscriberSchema, subscriberFieldSchema } from "../schemas";

import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/subscriber-fields";
import * as subscriberConstants from "../constants/subscribers";

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [subscriberConstants.FETCH_SUBSCRIBER_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, subscriberSchema);
    const normalizedSubscriberFields =
      normalizedData.entities.subscriberFields || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberFields,
      },
    };
  },

  [subscriberConstants.FETCH_SUBSCRIBERS_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, {
      subscribers: [subscriberSchema],
    });
    const normalizedSubscriberFields =
      normalizedData.entities.subscriberFields || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberFields,
      },
    };
  },

  [constants.PUT_SUBSCRIBER_FIELD_REQUEST](state, action) {
    return state;
  },

  [constants.PUT_SUBSCRIBER_FIELD_SUCCESS](state, action) {
    const normalizedData = normalize(
      action.payload.subscriberField,
      subscriberFieldSchema
    );
    const normalizedSubscriberFields =
      normalizedData.entities.subscriberFields || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberFields,
      },
    };
  },
});
