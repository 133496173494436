import React from "react";
import Message from "./shared/Message";
import { withTime } from "../utilities/format-date";

const MessageDetail = props => {
  return (
    <div key={props.id} className="message-detail">
      <div className="details">
        <div className="sent-by">
          <a href={`/audience/subscribers/${props.subscriber.subscriberId}`}>
            {props.subscriber.fullName}
          </a>
        </div>
        <div className="sent-at">{withTime(props.sentAt)}</div>
      </div>
      <Message message={props} />
    </div>
  );
};

export default MessageDetail;
