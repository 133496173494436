import createReducer from "../utilities/create-reducer";
import * as constants from "../constants/messages";

const initialState = {
  messages: {
    unexpected: {
      messages: [],
      page: 1,
      pages: 1,
      total: 0,
    },
    feedback: {
      messages: [],
      page: 1,
      pages: 1,
      total: 0,
    },
  },
};


export default createReducer(initialState, {
  [constants.FETCH_MESSAGES_SUCCESS](state, action) {
    return {
      ...state,
      [action.meta.messageType] : {
        ...action.payload,
      },
    };
  },
});