import React from "react";

import MessageDetail from "./MessageDetail";
import PaginatedList from "./PaginatedList";

const message = m => <MessageDetail key={m.id} {...m} />;

const Messages = ({ messages = [], page = 1, pages = 1, onChoosePage, hidePageNumbers }) => (
  <div className="messages">
    <PaginatedList
    	hidePageNumbers={hidePageNumbers}
      items={messages}
      component={message}
      onChoosePage={onChoosePage}
      currentPage={page}
      totalPages={pages}
    />
  </div>
);

export default Messages;
