import React from "react";
import { Modal } from "../../components/shared";

export default ({ isOpen, onClose, onSubmit }) => (
  <Modal title="Delete welcome message?" isOpen={isOpen} onClose={onClose}>
    <div className="modal__controls">
      <button
        type="button"
        className="paloma-button modal__button"
        onClick={onClose}
      >
        Cancel
      </button>
      <button
        className="paloma-button modal__button modal__button--red"
        onClick={onSubmit}
        type="submit"
      >
        Confirm
      </button>
    </div>
  </Modal>
);
