import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/subscriber-fields";

export const putSubscriberField = payload => ({
  [CALL_API]: {
    endpoint: "/api/subscriber_fields/",
    method: "PUT",
    body: JSON.stringify({ subscriber_field: payload }),
    types: [
      {
        type: constants.PUT_SUBSCRIBER_FIELD_REQUEST,
        meta: payload.subscriberFieldId
          ? { subscriberFieldId: payload.subscriberFieldId }
          : {},
      },
      constants.PUT_SUBSCRIBER_FIELD_SUCCESS,
      {
        type: constants.PUT_SUBSCRIBER_FIELD_FAILURE,
        meta: payload.subscriberFieldId
          ? { subscriberFieldId: payload.subscriberFieldId }
          : {},
      },
    ],
  },
});
