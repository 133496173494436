import React from "react";
import { Modal } from ".";

class TextInputModal extends React.Component {
  state = {
    value: this.props.value
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.value)
    this.props.onClose()
  }

  render() {
    return (
      <div className="insert-field-modal">
        <Modal
          title={this.props.title}
          isOpen={this.props.isOpen || true}
          onClose={this.props.onClose}
        >
          <div className="modal__top-input">
            <div className="input-with-inline-button">
              <form onSubmit={this.onSubmit}>
                <input
                  className="modal__input--inline"
                  type="text"
                  name="entry"
                  value={this.state.value}
                  onChange={(e) => this.setState({ value: e.target.value }) }
                />
              </form>
            </div>
          </div>
          <div className="modal__bottom">
            <button
              type="button"
              onClick={this.props.onClose}
              className="paloma-button paloma-button--white modal__button"
            >
              {this.props.cancelCTA || "Back"}
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="paloma-button paloma-button--pink modal__button"
            >
              {this.props.submitCTA || "Save"}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default TextInputModal;