import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import moment from "moment";

import { PalomaDatePicker } from "../components/shared";
import { everyHalfHour, nycToUtc, utcToNyc } from "../utilities/date-time";
import {
  createConversationSchedule,
  deleteConversationSchedule,
  updateConversationSchedule,
} from "../actions/conversation-schedules";
import { clearAlerts, showErrorAlert } from "../actions/alerts";

class ConversationScheduler extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: null, clearAlertsTimeout: null };
  }

  render() {
    const { conversation: { sendAt } = {} } = this.props;

    return (
      <div className="card__section scheduler">
        <h4 className="card__section-title">Scheduling</h4>
        <div className="radio">
          <input
            checked={!sendAt}
            id="now"
            name="schedule"
            onChange={() => this.toggleSchedule(false)}
            type="radio"
            value="now"
          />
          <label htmlFor="now"> Send Now</label>
          <input
            checked={sendAt}
            id="later"
            name="schedule"
            onChange={() => this.toggleSchedule(true)}
            type="radio"
            value="later"
          />
          <label htmlFor="later"> Send Later</label>
        </div>
        {this.state.error && (
          <span
            className="error-container"
            style={{
              border: "1px solid #A90F21",
              padding: "10px",
              display: "inline-block",
            }}
          >
            {this.state.error}
          </span>
        )}
        <div className="date-time">
          {sendAt && (
            <PalomaDatePicker
              selected={utcToNyc(sendAt)}
              onChange={d => this.updateSchedule(d.format())}
            />
          )}
          {sendAt && (
            <Select.Creatable
              className="conversation-publisher__target-filter__value-select"
              clearable={false}
              defaultValue={utcToNyc(sendAt)}
              value={utcToNyc(sendAt).format()}
              options={everyHalfHour(utcToNyc(sendAt)).map(t => ({
                label: t.format("hh:mm A"),
                value: t.format(),
              }))}
              onChange={selected => {
                this.updateSchedule(nycToUtc(selected.value).format());
              }}
            />
          )}
        </div>
      </div>
    );
  }

  toggleSchedule = schedule => {
    const {
      conversation,
      deleteConversationSchedule,
      createConversationSchedule,
    } = this.props;

    if (schedule) {
      createConversationSchedule(conversation.conversationId);
    } else {
      deleteConversationSchedule(conversation.conversationId);
    }
  };

  updateSchedule = sendAt => {
    const { conversationId, updateConversationSchedule } = this.props;

    this.setState({ error: null });
    if (this.state.clearAlertsTimeout) {
      clearTimeout(this.state.clearAlertsTimeout);
    }

    if (moment.utc(sendAt).isBefore(moment.utc())) {
      this.setState({ error: "Please pick a time in the future" });

      this.setState({
        clearAlertsTimeout: setTimeout(
          () => this.setState({ error: null }),
          5000
        ),
      });
      return;
    }

    updateConversationSchedule(conversationId, sendAt);
  };
}

const mapStateToProps = (state, props) => {
  return {
    conversation: state.conversations.conversationsById[props.conversationId],
  };
};

export default connect(
  mapStateToProps,
  {
    clearAlerts,
    createConversationSchedule,
    deleteConversationSchedule,
    showErrorAlert,
    updateConversationSchedule,
  }
)(ConversationScheduler);
