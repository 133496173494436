import React from "react";
import { connect } from "react-redux";
import Toggle from "../components/shared/Toggle";
import { Link } from "react-router-dom";
import { Table } from "../components/shared";
import { formatDate } from "../utilities/format-date";
import { ConversationsTable, Card, ContentHeader } from "../components/shared";
import { fetchActiveSignupConversations } from "../actions/active-signup-conversations";
import { fetchInactiveSignupConversations } from "../actions/inactive-signup-conversations";
import {
  createManualMessagingAcquisitionAction,
  deleteManualMessagingAcquisitionAction,
  createThreadControlPassAcquisitionAction,
  deleteThreadControlPassAcquisitionAction,
  fetchAcquisitionActions,
} from "../actions/acquisition-actions";
import {
  fetchWelcomeMessages,
  duplicateTrigger,
  deleteWelcomeMessage,
} from "../actions/welcome-messages";
import values from "lodash/values";
import { push } from "react-router-redux";
import DeleteWMessageModal from "./WelcomeMessage/DeleteWMessageModal";

class Entrypoints extends React.Component {
  render() {
    return (
      <div>
        <ContentHeader left={<h1 className="page-title">Entry Points</h1>} />
        <ConnectedWelcomeMessage />
        <ConnectedOrganic />
      </div>
    );
  }
}

class WelcomeMessage extends React.Component {
  state = {
    displayDeleteModal: false,
  };

  componentDidMount() {
    this.props.fetchWelcomeMessages();
  }

  newWelcomeMsgBtn = (text = "New") => (
    <Link className="paloma-button--pink" to="/entrypoints/wm/new">
      + {text}
    </Link>
  );

  duplicate = async welcomeMessageId => {
    const {
      payload: { triggerId },
    } = await this.props.duplicateTrigger(welcomeMessageId);
    this.props.push(`/entrypoints/wm/${triggerId}/`);
  };

  delete = welcomeMessageId => {
    this.props.deleteWelcomeMessage(welcomeMessageId).then(() => {
      this.setState({ displayDeleteModal: false });
      this.props.push("/entrypoints");
    });
  };

  renderNoWelcomeMessages = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        <span>Create a welcome message to be linked to CTM Ads via JSON.</span>
      </div>
      <div style={{ margin: "25px" }}>
        {this.newWelcomeMsgBtn("Add Welcome Message")}
      </div>
    </div>
  );

  deleteWelcomeMessage = welcomeMessageId => {
    this.props.deleteWelcomeMessage(welcomeMessageId).then(() => {
      this.setState({ displayDeleteModal: false });
      this.props.push("/entrypoints");
    });
  };

  render() {
    const welcomeMessages = values(this.props.welcomeMessagesById);
    const hasWMTriggers = !!welcomeMessages.length;

    return (
      <div>
        <Card className="info-panel" loading={this.props.isLoading}>
          <ContentHeader
            left={<h3>Welcome Message</h3>}
            right={hasWMTriggers ? this.newWelcomeMsgBtn() : null}
          />

          {!hasWMTriggers ? (
            this.renderNoWelcomeMessages()
          ) : (
            <WelcomeMessageTable
              welcomeMessages={welcomeMessages}
              duplicate={this.duplicate}
              onDelete={id => this.setState({ displayDeleteModal: id })}
            />
          )}
        </Card>
        <DeleteWMessageModal
          isOpen={this.state.displayDeleteModal}
          onClose={() => this.setState({ displayDeleteModal: false })}
          onSubmit={() =>
            this.deleteWelcomeMessage(this.state.displayDeleteModal)
          }
        />
      </div>
    );
  }
}

const ConnectedWelcomeMessage = connect(
  state => {
    return {
      ...state.welcomeMessages,
    };
  },
  {
    fetchWelcomeMessages,
    push,
    duplicateTrigger,
    deleteWelcomeMessage,
  }
)(WelcomeMessage);

class Organic extends React.Component {
  componentDidMount() {
    this.props.fetchAcquisitionActions();
    this.props.fetchInactiveSignupConversations();
    this.props.fetchActiveSignupConversations();
  }

  updateThreadControlPass = () => {
    const { threadControlPass } = this.props;
    if (!!threadControlPass) {
      this.props.deleteThreadControlPassAcquisitionAction();
    } else {
      this.props.createThreadControlPassAcquisitionAction();
    }
  };

  updateManualMessagingActivation = () => {
    const { manualMessagingActivation } = this.props;
    if (!manualMessagingActivation) {
      this.props.createManualMessagingAcquisitionAction();
    } else {
      this.props.deleteManualMessagingAcquisitionAction();
    }
  };

  render() {
    const {
      activeSignupConversations,
      inactiveSignupConversations,
      manualMessagingActivation,
      threadControlPass,
      loading,
    } = this.props;

    return (
      <Card className="info-panel" loading={loading}>
        <h3>Organic</h3>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="info-panel" style={{ width: "49%" }}>
            <h4>Turn on Live Chat</h4>
            <br />
            <div style={{ display: "flex" }}>
              <Toggle
                className="square"
                onChange={this.updateManualMessagingActivation}
                checked={!!manualMessagingActivation}
              />
              <p>
                I would like live chat to be turned on when someone sends a
                matching response to this trigger
              </p>
            </div>
          </div>
          <div className="info-panel" style={{ width: "49%" }}>
            <h4>Pass Control to Another Application</h4>
            <br />
            <div style={{ display: "flex" }}>
              <Toggle
                className="square"
                onChange={this.updateThreadControlPass}
                checked={!!threadControlPass}
              />
              <p>
                I would like to pass control to another application when someone
                sends a matching response to this trigger
              </p>
            </div>
          </div>
        </div>
        <br />
        <ContentHeader
          left={<h3>Conversations</h3>}
          right={
            <Link className="paloma-button--pink" to="/acquisition/signup/new">
              + New
            </Link>
          }
        />

        {activeSignupConversations.length ? (
          <ConversationsTable
            conversations={activeSignupConversations}
            title="Active"
            showDelete={false}
          />
        ) : (
          <div className="empty-table">
            <div className="empty-table-text">
              You haven't created a signup conversation yet.
            </div>
            <Link
              className="paloma-button empty-table-button"
              to="/acquisition/signup/new"
            >
              + New
            </Link>
          </div>
        )}
        <ConversationsTable
          conversations={inactiveSignupConversations}
          title="Inactive"
          perPage={5}
        />
      </Card>
    );
  }
}

const ConnectedOrganic = connect(
  state => {
    const {
      inactiveSignupConversations: {
        loading: inactiveLoading,
        data: inactiveSignupConversations,
      },
      activeSignupConversations: {
        loading: activeLoading,
        data: activeSignupConversations,
      },
    } = state;

    const manualMessagingActivation = state.acquisitionActions
      .map(action => action.type)
      .includes("manual_messaging_activation");
    const threadControlPass = state.acquisitionActions
      .map(action => action.type)
      .includes("thread_control_pass");

    return {
      inactiveSignupConversations,
      activeSignupConversations,
      loading: activeLoading || inactiveLoading,
      manualMessagingActivation,
      threadControlPass,
    };
  },
  {
    createManualMessagingAcquisitionAction,
    createThreadControlPassAcquisitionAction,
    deleteManualMessagingAcquisitionAction,
    deleteThreadControlPassAcquisitionAction,
    fetchAcquisitionActions,
    fetchActiveSignupConversations,
    fetchInactiveSignupConversations,
  }
)(Organic);

const WelcomeMessageTable = ({ welcomeMessages, duplicate, onDelete }) => (
  <Table
    headers={["Name", "Created", ""]}
    rows={welcomeMessages.map(welcomeMessage => [
      <Link
        className="table__link"
        to={`/entrypoints/wm/${welcomeMessage.triggerId}/`}
      >
        {welcomeMessage.name}
      </Link>,
      formatDate(welcomeMessage.createdAt),
      <div>
        <button
          className="table__link"
          onClick={() => duplicate(welcomeMessage.triggerId)}
        >
          Duplicate
        </button>
        <Link
          className="table__link"
          to={`/entrypoints/wm/${welcomeMessage.triggerId}`}
        >
          View
        </Link>
        <button
          className="table__link"
          onClick={() => onDelete(welcomeMessage.triggerId)}
        >
          Delete
        </button>
      </div>,
    ])}
  />
);

export default Entrypoints;
