export const FETCH_GLOBAL_TRIGGERS_REQUEST = "FETCH_GLOBAL_TRIGGERS_REQUEST";
export const FETCH_GLOBAL_TRIGGERS_SUCCESS = "FETCH_GLOBAL_TRIGGERS_SUCCESS";
export const FETCH_GLOBAL_TRIGGERS_FAILURE = "FETCH_GLOBAL_TRIGGERS_FAILURE";

export const FETCH_GLOBAL_TRIGGER_REQUEST = "FETCH_GLOBAL_TRIGGER_REQUEST";
export const FETCH_GLOBAL_TRIGGER_SUCCESS = "FETCH_GLOBAL_TRIGGER_SUCCESS";
export const FETCH_GLOBAL_TRIGGER_FAILURE = "FETCH_GLOBAL_TRIGGER_FAILURE";

export const DUPLICATE_GLOBAL_TRIGGER_REQUEST =
  "DUPLICATE_GLOBAL_TRIGGER_REQUEST";
export const DUPLICATE_GLOBAL_TRIGGER_SUCCESS =
  "DUPLICATE_GLOBAL_TRIGGER_SUCCESS";
export const DUPLICATE_GLOBAL_TRIGGER_FAILURE =
  "DUPLICATE_GLOBAL_TRIGGER_FAILURE";

export const PUT_GLOBAL_TRIGGER_REQUEST = "PUT_GLOBAL_TRIGGER_REQUEST";
export const PUT_GLOBAL_TRIGGER_SUCCESS = "PUT_GLOBAL_TRIGGER_SUCCESS";
export const PUT_GLOBAL_TRIGGER_FAILURE = "PUT_GLOBAL_TRIGGER_FAILURE";

export const DELETE_GLOBAL_TRIGGER_REQUEST = "DELETE_GLOBAL_TRIGGER_REQUEST";
export const DELETE_GLOBAL_TRIGGER_SUCCESS = "DELETE_GLOBAL_TRIGGER_SUCCESS";
export const DELETE_GLOBAL_TRIGGER_FAILURE = "DELETE_GLOBAL_TRIGGER_FAILURE";
