import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Editor } from "slate-react";
import { capitalizeString } from "../../utilities/format-string";
import { StepsByDetourLevel } from "../../components/ConversationDetail/SubscriberFlow";
import { createEditorState } from "../../utilities/editor";
import arrow from "../../images/arrow.svg";
import arrowPink from "../../images/arrow-pink.svg";

class SubscriberFlowStepContainer extends Component {
  textFor = trigger => {
    const selected = trigger.triggerId === this.props.selectedTriggerId;

    if (trigger.type === "feedback") {
      return <div key={trigger.triggerId}>Feedback</div>;
    }

    if (trigger.type === "conversation_step") {
      return (
        <div>
          {trigger.matchingResponses.map(matchingResponse => {
            const triggerText = createEditorState(matchingResponse).startText
              .text;
            return (
              <div
                key={trigger.triggerId}
                className="detour-button"
                onClick={() =>
                  selected
                    ? this.props.handleDeselectTrigger(trigger)
                    : this.props.handleSelectTrigger(trigger)
                }
              >
                <div className="detour-section">{`Detour: ${capitalizeString(
                  triggerText
                )}`}</div>
                <div className="detour-section">
                  <img alt="arrow" src={selected ? arrow : arrowPink} />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  getStepTypeLabel() {
    let {
      conversationStep: {
        messageType,
        buttons,
        quickReplies,
        templateCards: cards,
      },
    } = this.props;
    const labels = [capitalizeString(messageType)];

    if (messageType.toLowerCase() === "template") {
      labels[0] = "Card";
      buttons = cards.reduce((acc, card) => [...acc, ...card.buttons], []);
    }

    if (buttons.length) {
      labels.push(buttons.length > 1 ? "Buttons" : "Button");
    }

    if (quickReplies.length) {
      labels.push(quickReplies.length > 1 ? "Quick replies" : "Quick reply");
    }

    return `Step: ${labels.join(", ")}`;
  }

  render() {
    const {
      conversationStep: { triggers, organizationField },
      selectedTriggerId,
    } = this.props;
    const hasTriggers = triggers.length > 0;
    const isField = !!organizationField && !hasTriggers;

    return (
      <div className="step">
        <div className="label">{this.getStepTypeLabel()} </div>
        {hasTriggers && (
          <div className="triggers">
            {triggers
              .sort((a, b) => a.triggerId > b.triggerId)
              .map(trigger => {
                const selected = trigger.triggerId === selectedTriggerId;
                return (
                  <div
                    key={trigger.triggerId}
                    className={classNames("trigger", {
                      selected,
                    })}
                  >
                    <div className="label">{this.textFor(trigger)}</div>
                    {selected && (
                      <StepsByDetourLevel
                        {...this.props}
                        detourLevel={this.props.detourLevel + 1}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        )}
        {isField && (
          <div className="field">
            <span>Field:&nbsp;</span>
            <Editor
              readOnly={true}
              state={createEditorState(organizationField.name)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { conversationStep } = ownProps;

  return {
    selectedTriggerId:
      state.conversations.triggerChoicesByConversationStepId[
        conversationStep.conversationStepId
      ],
  };
};

export default connect(mapStateToProps)(SubscriberFlowStepContainer);
