import React from "react";
import { connect } from "react-redux";
import { Card } from "../components/shared";
import SequenceDetailForm from "../components/SequenceDetailForm";
import { push } from "react-router-redux";
import { editSequence, fetchSequence } from "../actions/sequences";

class EditSequence extends React.Component {
  state = {
    name: "",
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.name && props.sequence) {
      return { name: props.sequence.name || "" };
    }
    return null;
  }

  componentDidMount() {
    const { sequenceId } = this.props;
    if (!this.props.sequence && sequenceId) {
      this.props.fetchSequence({ sequenceId });
    }
  }

  handleEditSequence = e => {
    e.preventDefault();

    const { name } = this.state;
    const { sequenceId } = this.props;

    this.props
      .editSequence({ sequenceId, name })
      .then(() => this.props.push(this.props.match.url.replace("/edit", "")));
  };

  onChange = e => this.setState({ name: e.target.value });

  render() {
    return (
      <div>
        <h1 className="page-title">Edit Sequence</h1>
        <br />
        <Card>
          <SequenceDetailForm
            onSubmit={this.handleEditSequence}
            name={this.state.name}
            onChange={this.onChange}
            canSubmit={!!this.state.name}
            submitLabel="Edit"
          />
        </Card>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { sequenceId } = props.match.params;
    const sequence = state.sequences.byId[sequenceId];

    return { sequenceId, sequence };
  },
  { fetchSequence, editSequence, push }
)(EditSequence);
