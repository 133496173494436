import React from "react";
import { Link } from "react-router-dom";

import GlobalTriggersTableRowButtons from "./GlobalTriggersTableRowButtons";
import { Table } from "../shared";
import { formatDate } from "../../utilities/format-date";

const GlobalTriggersTable = ({
  globalTriggers,
  onDeleteTrigger,
  duplicateTrigger,
}) => (
  <Table
    headers={[
      "Name",
      "Created",
      "Last Ran",
      <div style={{ float: "right" }}>
        <Link className="paloma-button--pink" to="/global/new">
          + New
        </Link>
      </div>,
    ]}
    rows={globalTriggers.map(globalTrigger => [
      <Link className="table__link" to={`/global/${globalTrigger.triggerId}`}>
        {globalTrigger.name}
      </Link>,
      formatDate(globalTrigger.createdAt),
      formatDate(globalTrigger.lastRanAt) || "Never",
      <GlobalTriggersTableRowButtons
        globalTrigger={globalTrigger}
        duplicate={duplicateTrigger}
      />,
    ])}
  />
);

export default GlobalTriggersTable;
