import React from "react";
import { connect } from "react-redux";
import {
  fetchOrganizationFields,
  putOrganizationFieldsRequired,
} from "../../actions/organization-fields";
import { Card } from "../../components/shared";
import TagSelect from "../../components/shared/TagSelect";

class OrganizationFieldConfig extends React.Component {
  componentDidMount() {
    this.props.fetchOrganizationFields();
  }

  getOptions = () => {
    return this.props.fields.map(f => ({
      value: f.organizationFieldId,
      label: f.name,
    }));
  };

  getValues = () => {
    return this.props.fields
      .filter(f => f.required)
      .map(f => f.organizationFieldId);
  };

  handleFieldSelect = selected_fields => {
    const ids = selected_fields.map(f => f.value).join();
    this.props.putOrganizationFieldsRequired(ids || []);
  };

  render() {
    const options = this.getOptions();
    return (
      <Card className="info-panel">
        <h3>Select required fields</h3>
        <div className="content-header">
          <span>
            Select fields that are required for a subscriber profile to be
            complete.
          </span>
        </div>
        <div className="organization-fields-config">
          {options.length ? (
            <TagSelect
              value={this.getValues()}
              options={options}
              creatable={false}
              placeholder="Select required fields..."
              onChange={this.handleFieldSelect}
            />
          ) : null}
        </div>
      </Card>
    );
  }
}

export default connect(
  (state, props) => {
    const {
      organizationFields: { organizationFields: fields },
    } = state;
    return { ...props, fields };
  },
  { fetchOrganizationFields, putOrganizationFieldsRequired }
)(OrganizationFieldConfig);
