import React from "react";
import { Card, ContentHeader } from "../components/shared";
import { connect } from "react-redux";
import {
  fetchOrganizationFields,
  deleteOrganizationField,
} from "../actions/organization-fields";
import { Table } from "../components/shared";
import { Link } from "react-router-dom";

export const DEFAULT_FIELDS = [
  "email",
  "phone number",
  "state",
  "zip code",
  "first name",
  "last name",
];

class FieldsContainer extends React.Component {
  componentDidMount() {
    this.props.fetchOrganizationFields();
  }

  rows = () =>
    this.props.fields.organizationFields
      .filter(field => DEFAULT_FIELDS.includes(field.name.toLowerCase()))
      .map(f => [
        <Link className="table__link" to={`/fields/${f.organizationFieldId}`}>
          {f.name}
        </Link>,
        <span className="chat-status auto">{f.required ? "Yes" : "No"}</span>,
        <span className="chat-status auto">
          {f.validations.length > 0 ? "Yes" : "No"}
        </span>,
        <Link className="table__link" to={`/fields/${f.organizationFieldId}`}>
          View
        </Link>,
      ]);

  customRows = () =>
    this.props.fields.organizationFields
      .filter(field => !DEFAULT_FIELDS.includes(field.name.toLowerCase()))
      .map(f => [
        <Link className="table__link" to={`/fields/${f.organizationFieldId}`}>
          {f.name}
        </Link>,
        <span className="chat-status auto">{f.required ? "Yes" : "No"}</span>,
        <span className="chat-status auto">
          {f.validations.length > 0 ? "Yes" : "No"}
        </span>,
        <div>
          <Link className="table__link" to={`/fields/${f.organizationFieldId}`}>
            View
          </Link>
          <button
            className="table__link"
            onClick={() =>
              this.props.deleteOrganizationField(f.organizationFieldId)
            }
          >
            Delete
          </button>
        </div>,
      ]);

  handleNewField = () => {};

  render() {
    const headers = [
      "Name",
      "Required for complete profile?",
      "Has validations?",
      "",
    ];

    return (
      <div>
        <ContentHeader left={<h1 className="page-title">Fields</h1>} />
        <Card className="info-panel">
          <h3>Default</h3>
          <Table headers={headers} rows={this.rows()} />
        </Card>
        <Card className="info-panel">
          <ContentHeader
            left={<h3>Custom</h3>}
            right={
              <a className="paloma-button--pink" href="/fields/new">
                + New
              </a>
            }
          />
          <Table headers={headers} rows={this.customRows()} />
        </Card>
      </div>
    );
  }
}

export default connect(
  state => {
    return { fields: state.organizationFields };
  },
  { fetchOrganizationFields, deleteOrganizationField }
)(FieldsContainer);
