import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Card } from "./shared";
import { fetchCurrentOrganization } from "../actions/current-organization";

class ShopifyConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminApiKey: null,
      storefrontAccessToken: null,
      storefrontSlug: null,
      isEditing: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.adminApiKey !== null) {
      return null;
    }

    return {
      storefrontAccessToken: props.storefrontAccessToken || "",
      adminApiKey: props.adminApiKey || "",
      storefrontSlug: props.storefrontSlug || "",
      isEditing: !props.adminApiKey || !props.storefrontSlug,
    };
  }

  renderConfigForm = () => {
    const { isEditing } = this.state;
    const { currentOrganization } = this.props;
    const { lastInventoryImport } = currentOrganization;

    return (
      <div>
        <div>
          <h3>
            <label
              htmlFor="shopify-storefront-access-token"
              className="default-form__label"
            >
              Shopify Storefront Access Token
            </label>
          </h3>
          <input
            disabled={!isEditing}
            className="default-form__input"
            id="shopify-storefront-access-token"
            value={this.state.storefrontAccessToken}
            type="text"
            onChange={(e) =>
              this.handleChange("storefrontAccessToken", e.target.value)
            }
          />
        </div>
        <div>
          <label
            className="default-form__label"
            htmlFor="shopify-admin-api-key"
          >
            Shopify Admin API Password
          </label>

          <input
            disabled={!isEditing}
            className="default-form__input"
            id="shopify-admin-api-key"
            value={this.state.adminApiKey}
            type={isEditing ? "text" : "password"}
            onChange={(e) => this.handleChange("adminApiKey", e.target.value)}
          />
        </div>
        <div>
          <label
            htmlFor="shopify-storefront-slug"
            className="default-form__label"
          >
            Shopify Storefront Slug
          </label>
          <input
            disabled={!isEditing}
            className="default-form__input"
            id="shopify-storefront-slug"
            value={this.state.storefrontSlug}
            type="text"
            onChange={(e) =>
              this.handleChange("storefrontSlug", e.target.value)
            }
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <span className="default-form__label">Last Import: </span>
          {lastInventoryImport
            ? moment(lastInventoryImport).format("YYYY-MM-DD HH:mm A")
            : "Unknown"}
        </div>
        <div style={{ display: "flex" }}>
          {isEditing ? (
            <div>
              <button
                style={{ marginLeft: 0, marginRight: "10px" }}
                onClick={() =>
                  this.setState({
                    adminApiKey: this.props.adminApiKey,
                    storefrontAccessToken: this.props.storefrontAccessToken,
                    storefrontSlug: this.props.storefrontSlug,
                    isEditing: false,
                  })
                }
                className="paloma-button"
              >
                Cancel
              </button>
              <button
                disabled={
                  !(this.state.adminApiKey && this.state.storefrontSlug)
                }
                style={{ marginLeft: 0 }}
                onClick={this.handleSubmitConfig}
                className="paloma-button paloma-button--pink"
              >
                Save
              </button>
            </div>
          ) : (
            <button
              style={{ marginLeft: 0 }}
              onClick={() => this.setState({ isEditing: true })}
              className="paloma-button"
            >
              Edit
            </button>
          )}
          <button
            onClick={() => {
              this.props.importShopifyInventory();
              this.props.fetchCurrentOrganization();
            }}
            className="paloma-button"
            disabled={
              !(this.state.adminApiKey && this.state.storefrontSlug) ||
              (lastInventoryImport &&
                moment().diff(moment(lastInventoryImport), "minutes") < 30)
            }
          >
            Import Shopify Inventory
          </button>
          {lastInventoryImport &&
            moment().diff(moment(lastInventoryImport), "minutes") < 30 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  color: "#A71326",
                }}
              >
                You must wait until{" "}
                {moment(lastInventoryImport)
                  .add(30, "minutes")
                  .format("YYYY-MM-DD HH:mm A")}{" "}
                to import again
              </div>
            )}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <Card className="info-panel">
        <h3>Shopify App Configuration</h3>
        {this.renderConfigForm()}
      </Card>
    );
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleSubmitConfig = async () => {
    const response = await this.props.updateShopifyConfig({
      adminApiKey: this.state.adminApiKey,
      storefrontSlug: this.state.storefrontSlug,
      storefrontAccessToken: this.state.storefrontAccessToken,
    });
    this.setState({ isEditing: false });
    if (response.type === "UPDATE_SHOPIFY_CONFIG_SUCCESS") {
      this.props.showSuccessAlert("Shopify App Configured Successfully");
    }
  };
}

const mapStateToProps = (state) => ({
  currentOrganization: state.currentOrganization,
});

export default connect(mapStateToProps, {
  fetchCurrentOrganization,
})(ShopifyConfig);
