import { CALL_API } from "redux-api-middleware";
import queryString from "query-string";
import { push } from "react-router-redux";

import * as constants from "../constants/subscribers";

// this action creator returns a Promise and can be used with async/await
// reference: https://github.com/agraboso/redux-api-middleware/issues/21#issuecomment-183439582
export const fetchSubscriber = subscriberId => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/${subscriberId}/`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_SUBSCRIBER_REQUEST,
        meta: { subscriberId },
      },
      constants.FETCH_SUBSCRIBER_SUCCESS,
      {
        type: constants.FETCH_SUBSCRIBER_FAILURE,
        meta: { subscriberId },
      },
    ],
  },
});

export const fetchMessagesForSubscriber = (subscriberId, page) => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/${subscriberId}/messages?page=${page}`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_SUBSCRIBER_MESSAGES_REQUEST,
        meta: { subscriberId },
      },
      {
        type: constants.FETCH_SUBSCRIBER_MESSAGES_SUCCESS,
        meta: { subscriberId },
      },
      {
        type: constants.FETCH_SUBSCRIBER_MESSAGES_FAILURE,
        meta: { subscriberId },
      },
    ],
  },
});

export const fetchSubscribers = ({ order, page = 1, search, sortBy, unsubscribed = null }) => {
  const queryParams = { page, order, search, sortBy, unsubscribed };

  return {
    [CALL_API]: {
      endpoint: `/api/subscribers?${queryString.stringify(queryParams)}`,
      method: "GET",
      types: [
        constants.FETCH_SUBSCRIBERS_REQUEST,
        {
          type: constants.FETCH_SUBSCRIBERS_SUCCESS,
          meta: { order, page, search, sortBy },
        },
        constants.FETCH_SUBSCRIBERS_FAILURE,
      ],
    },
  };
};

export const putSubscriber = payload => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/`,
    method: "PUT",
    body: JSON.stringify({ subscriber: payload }),
    types: [
      {
        type: constants.PUT_SUBSCRIBER_REQUEST,
        meta: payload.subscriberId
          ? { subscriberId: payload.subscriberId }
          : {},
      },
      constants.PUT_SUBSCRIBER_SUCCESS,
      {
        type: constants.PUT_SUBSCRIBER_FAILURE,
        meta: payload.subscriberId
          ? { subscriberId: payload.subscriberId }
          : {},
      },
    ],
  },
});

export const deleteSubscriber = subscriberId => async dispatch => {
  const response = await dispatch({
    [CALL_API]: {
      endpoint: `/api/subscribers/${subscriberId}/`,
      method: "DELETE",
      types: [
        constants.DELETE_SUBSCRIBER_REQUEST,
        constants.DELETE_SUBSCRIBER_SUCCESS,
        constants.DELETE_SUBSCRIBER_FAILURE,
      ],
    },
  });

  if (!response.error) {
    dispatch(push("/audience"));
  }
};

export const sendManualMessageToSubscriber = (subscriberId, message) => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/${subscriberId}/manual_message/`,
    method: "POST",
    body: JSON.stringify({ message }),
    types: [
      constants.SEND_MANUAL_MESSAGE_REQUEST,
      {
        type: constants.SEND_MANUAL_MESSAGE_SUCCESS,
        meta: { subscriberId },
      },
      {
        type: constants.SEND_MANUAL_MESSAGE_FAILURE,
        meta: { subscriberId },
      },
    ],
  },
});

export const joinSocketIoRoomForSubscriber = subscriberId => ({
  type: constants.JOIN_SOCKET_IO_ROOM_FOR_SUBSCRIBER,
  eventName: "join",
  payload: {
    room: `subscriber-${subscriberId}`,
  },
  io: true,
});

export const leaveSocketIoRoomForSubscriber = subscriberId => ({
  type: constants.LEAVE_SOCKET_IO_ROOM_FOR_SUBSCRIBER,
  eventName: "leave",
  payload: {
    room: `subscriber-${subscriberId}`,
  },
  io: true,
});

export const fetchSubscriberDownloadToken = subscriberId => async dispatch => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/subscribers/${subscriberId}/download_token/`,
      method: "POST",
      types: [
        constants.FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_REQUEST,
        {
          type: constants.FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_SUCCESS,
          meta: { subscriberId },
        },
        constants.FETCH_SUBSCRIBER_DOWNLOAD_TOKEN_FAILURE,
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const fetchAllSubscribersDownloadToken = () => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/download_token/`,
    method: "POST",
    types: [
      constants.FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_REQUEST,
      constants.FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_SUCCESS,
      constants.FETCH_ALL_SUBSCRIBERS_DOWNLOAD_TOKEN_FAILURE,
    ],
  },
});

export const downloadAudienceCsv = (mimetype, queryParams = {}) => {
  return {
    [CALL_API]: {
      headers: { "Content-Type": mimetype },
      endpoint: `/api/subscribers/download?${queryString.stringify(queryParams)}`,
      method: "GET",
      types: [
        "DOWNLOAD_AUDIENCE_CSV_REQUEST",
        "DOWNLOAD_AUDIENCE_CSV_SUCCESS",
        "DOWNLOAD_AUDIENCE_CSV_FAILURE",
      ],
    },
  }
};

export const fetchSubscriberLists = subscriberId => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/${subscriberId}/lists`,
    method: "GET",
    types: [
      "FETCH_SUBSCRIBER_SUBSCRIBER_LISTS_REQUEST",
      "FETCH_SUBSCRIBER_SUBSCRIBER_LISTS_SUCCESS",
      "FETCH_SUBSCRIBER_SUBSCRIBER_LISTS_FAILURE",
    ],
  },
});

export const storeMessageForSubscriber = message => ({
  type: constants.STORE_MESSAGE_FOR_SUBSCRIBER,
  payload: { message: message },
})
