import React from "react";
import { connect } from "react-redux";

import { DeleteConfirmationModal } from ".";

import { deleteSubscriberList } from "../../actions/subscriber-lists";

class DeleteSubscriberListModal extends React.Component {
  render() {
    return (
      <DeleteConfirmationModal
        title={"Delete subscriber list?"}
        handleDelete={this.deleteSubscriberList}
        onClose={this.props.onClose}
      />
    );
  }

  deleteSubscriberList = async () => {
    const {
      subscriberList: { subscriberListId },
      deleteSubscriberList,
      onClose,
      onDelete,
    } = this.props;

    await deleteSubscriberList(subscriberListId);
    await onClose();
    onDelete();
  };
}

export default connect(
  null,
  {
    deleteSubscriberList,
  }
)(DeleteSubscriberListModal);
