import React, { Component } from "react";
import { connect } from "react-redux";

import { DeleteSubscriberListModal } from "../shared";

import { fetchSubscriberLists } from "../../actions/subscriber-lists";

class SubscriberListDeleteButton extends Component {
  state = {
    displayDeleteModal: false,
  };

  componentDidMount() {
    this.isStillMounted = true;
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  render() {
    const { subscriberList } = this.props;

    const { displayDeleteModal } = this.state;

    return (
      <div>
        {displayDeleteModal && (
          <DeleteSubscriberListModal
            subscriberList={subscriberList}
            onClose={this.hideDeleteModal}
            onDelete={this.refresh}
          />
        )}
        <a className="table__link" onClick={this.showDeleteModal}>
          Delete
        </a>
      </div>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => {
    if (this.isStillMounted) {
      this.setState({ displayDeleteModal: false });
    }
  };

  refresh = () => this.props.fetchSubscriberLists();
}

export default connect(
  null,
  {
    fetchSubscriberLists,
  }
)(SubscriberListDeleteButton);
