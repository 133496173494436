import React from "react";
import { Card } from "../../components/shared";

export default ({ name, onChange, onSubmit }) => (
  <div>
    <br />
    <Card>
      <form className="default-form" onSubmit={onSubmit}>
        <label className="default-form__label" htmlFor="trigger-name">
          Name
        </label>
        <input
          id="trigger-name"
          className="default-form__input"
          type="text"
          value={name}
          onChange={onChange}
        />
        <button className="default-form__submit" type="submit" disabled={!name}>
          Create
        </button>
      </form>
    </Card>
  </div>
);
