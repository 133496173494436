import React from "react";
import _ from "underscore";
import { ADD_MESSAGE } from "../../constants/builder-menu";
import { openMenu } from "../../actions/builder-menu";
import { CONVERSATION_STEPS } from "../../constants/builder-menu";
import { connect } from "react-redux";
import { newConversationStep } from "../../actions/conversations";
import { insertAboveStep } from "../../actions/insert-above";
import { createProductCards } from "../../actions/inventory";

import AddInventoryButton from "./AddInventoryButton";

class AddMessageModal extends React.Component {
  render() {
    const { showAddInventory, stepIdToPaste, pasteStep, onClose } = this.props;

    return (
      <div className="conversation-builder__bottom-modal add-message-modal">
        <div>
          <button
            className="conversation-builder__bottom-modal-close-button"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="add-message-modal__message-types">
          <div
            className="add-message-modal__message-type"
            onClick={() => this.handleAddMessage("text")}
          >
            <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
              Text
            </button>
            <div className="add-message-modal__text-message" />
          </div>

          <div
            className="add-message-modal__message-type"
            onClick={() => this.handleAddMessage("image")}
          >
            <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
              Media
            </button>
            <div className="add-message-modal__image-message" />
          </div>

          <div
            className="add-message-modal__message-type"
            onClick={() => this.handleAddMessage("template")}
          >
            <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
              Card
            </button>
            <div className="add-message-modal__button-message-template" />
            <div className="add-message-modal__button-message-button-middle" />
            <div className="add-message-modal__button-message-button-middle" />
            <div className="add-message-modal__button-message-button-bottom" />
          </div>
          <div
            className="add-message-modal__message-type"
            onClick={() => this.handleAddMessage("dynamic")}
          >
            <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
              Dynamic
            </button>
            <div className="add-message-modal__text-message" />
          </div>
          {this.props.showReconnect ? (
            <div
              className="add-message-modal__message-type"
              onClick={this.openReconnectMenu}
            >
              <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
                Reconnect
              </button>
              <div className="add-message-modal__text-message" />
            </div>
          ) : null}
          {showAddInventory && (
            <AddInventoryButton handleAddInventory={this.handleAddInventory} />
          )}
          {stepIdToPaste && (
            <PasteStep pasteStep={pasteStep} stepIdToPaste={stepIdToPaste} />
          )}
        </div>
      </div>
    );
  }

  handleAddInventory = async productIds => {
    this.props.onClose();
    const {
      visibleConversationSteps: { stepIds: visibleConversationStepIds },
      conversation: { conversationId },
    } = this.props;

    const previousStepId = _.last(visibleConversationStepIds);

    this.props
      .createProductCards({
        previousStepId,
        productIds,
        conversationId,
      })
      .then(() => this.props.fetchVisibleConversationSteps(conversationId));

    this.props.fetchVisibleConversationSteps(conversationId);
  };

  openReconnectMenu = () => {
    const {
      visibleConversationSteps: { stepIds },
    } = this.props;
    const stepId = stepIds[stepIds.length - 1];

    this.props.openMenu({
      id: stepId,
      type: CONVERSATION_STEPS,
    });
    this.props.fetchVisibleConversationSteps();
  };

  handleAddMessage = async messageType => {
    this.props.onClose();

    const {
      conversation: { conversationId, type },
      fetchVisibleConversationSteps,
      insertAboveStep,
      newConversationStep,
      triggerChoicesByConversationStepId,
      stepIdToInsertAbove,
      visibleConversationSteps: { stepIds: visibleConversationStepIds },
    } = this.props;

    if (stepIdToInsertAbove) {
      await insertAboveStep({
        conversationId,
        stepIdToInsertAbove: stepIdToInsertAbove,
        messageType,
      });
    } else {
      const previousStepId = _.last(visibleConversationStepIds);
      const triggerId = triggerChoicesByConversationStepId[previousStepId];

      const { payload } = await newConversationStep({
        conversationId,
        messageType,
        previousStepId,
        triggerId,
      });

      if (previousStepId === undefined && type === "campaign") {
        const conversationStep = payload[0];
        this.handleAddRequiredFirstResponse(conversationStep);
      }
    }
    fetchVisibleConversationSteps(conversationId);
  };
}

const PasteStep = ({ pasteStep }) => {
  return (
    <div className="add-message-modal__message-type" onClick={pasteStep}>
      <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
        Paste
      </button>
      <div className="add-message-modal__paste" />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { conversationId } = ownProps;

  const {
    builderMenu: { type: menuType },
    conversations: {
      conversationsById,
      triggerChoicesByConversationStepId,
      visibleConversationStepsByConversationId,
    },
  } = state;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  const visibleConversationSteps = {
    isLoading: true,
    ...visibleConversationStepsByConversationId[conversationId],
  };

  const isAddMessageMenuOpen = menuType === ADD_MESSAGE;

  return {
    conversation,
    isAddMessageMenuOpen,
    triggerChoicesByConversationStepId,
    visibleConversationSteps,
  };
};

export default connect(mapStateToProps, {
  openMenu,
  insertAboveStep,
  newConversationStep,
  createProductCards,
})(AddMessageModal);
