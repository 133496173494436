export const FETCH_GLOBAL_DEFAULT_TRIGGER_REQUEST =
  "FETCH_GLOBAL_DEFAULT_TRIGGER_REQUEST";
export const FETCH_GLOBAL_DEFAULT_TRIGGER_SUCCESS =
  "FETCH_GLOBAL_DEFAULT_TRIGGER_SUCCESS";
export const FETCH_GLOBAL_DEFAULT_TRIGGER_FAILURE =
  "FETCH_GLOBAL_DEFAULT_TRIGGER_FAILURE";

export const CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_FAILURE";

export const DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_FAILURE";

export const CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_REQUEST =
  "CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_REQUEST";
export const CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS =
  "CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS";
export const CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_FAILURE =
  "CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_FAILURE";

export const DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_REQUEST =
  "DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_REQUEST";
export const DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS =
  "DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS";
export const DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_FAILURE =
  "DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_FAILURE";

export const UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_REQUEST =
  "UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_REQUEST";
export const UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_SUCCESS =
  "UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_SUCCESS";
export const UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_FAILURE =
  "UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_FAILURE";
