import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import "rc-slider/assets/index.css";
import "rc-time-picker/assets/index.css";
import _ from "underscore";

import TestSendConversation from "../TestSendConversation";
import { Card, ContentHeader } from "../../components/shared";
import { PublishOptions } from "../../components/ConversationPublisher";

import {
  deleteTargetFilter,
  fetchConversation,
  fetchConversationsLabel,
  fetchNumTargeted,
  publishConversation,
  putConversation,
  putTargetFilter,
} from "../../actions/conversations";

import { fetchOrganizationFields } from "../../actions/organization-fields";

import {
  fetchGlobalTrigger,
  fetchGlobalTriggers,
} from "../../actions/global-triggers";
import { fetchSubscriberLists } from "../../actions/subscriber-lists";

import { formatDate } from "../../utilities/format-date";
import { getConversationLink } from "../../utilities/conversation";

class CampaignConversationPublisherContainer extends Component {
  constructor(props) {
    super(props);

    this.putTargetFilter = _.debounce(this.props.putTargetFilter, 500);
    this.updateNumTargeted = _.debounce(this.updateNumTargeted, 500);
  }

  state = {
    loaded: false,
    targetFilterMatchSetting: null,
    targetSubscriberListId: "All",
    testEndCriteria: null,
    testEndDatetime: null,
    testEndNumEngagements: null,
    testGroupSize: 50,
    testWinnerCriteria: null,
    conversationsLabel: [],
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.conversation });
  }

  async componentDidMount() {
    const {
      conversationId,
      triggerId,

      fetchConversation,
      fetchConversationsLabel,
      fetchGlobalTrigger,
      fetchGlobalTriggers,
      fetchOrganizationFields,
      fetchSubscriberLists,
    } = this.props;

    const promises = [
      fetchConversation(conversationId),
      fetchConversationsLabel(),
      fetchGlobalTriggers(),
      fetchOrganizationFields(),
      fetchSubscriberLists(),
      this.updateNumTargeted(),
    ];

    if (triggerId) promises.push(fetchGlobalTrigger(triggerId));

    await Promise.all(promises).then(data => {
      this.setState({ conversationsLabel: data[1].payload });
    });

    this.setState({ loaded: true });
  }

  render() {
    const { conversation, numTargeted } = this.props;

    if (
      conversation &&
      !conversation.isLoading &&
      (conversation.published || conversation.sending)
    ) {
      const detailsUrl = getConversationLink(conversation) + "/details";
      return <Redirect push to={detailsUrl} />;
    }

    const numSubscribers =
      numTargeted && !numTargeted.isLoading ? numTargeted.number : "X";

    return (
      <div className="conversation-detail">
        <ContentHeader left={<h1>{conversation.label}</h1>} />
        {conversation.published ? (
          <div>
            This conversation was published on{" "}
            {formatDate(conversation.publishedAt)}
          </div>
        ) : (
          <div>
            <PublishOptions
              globalTriggers={this.props.globalTriggers || []}
              targetingDiv={
                <div className="conversation-publisher__num-targeted">
                  Targeting {numSubscribers} Subscribers
                </div>
              }
              handleAddTargetFilter={this.handleAddTargetFilter}
              handleChangeConditionDropdown={this.handleChangeConditionDropdown}
              handleChangeFieldDropdown={this.handleChangeFieldDropdown}
              handleChangeList={this.handleChangeList}
              handleChangeMatchSettingRadio={this.handleChangeMatchSettingRadio}
              handleChangeTargetFilterValue={this.handleChangeTargetFilterValue}
              handleClickPublish={this.handleClickPublish}
              handleDeleteTargetFilter={this.handleDeleteTargetFilter}
              subscriberListOptions={this.getSubscriberListOptions()}
              conversation={this.props.conversation}
              organizationFields={this.props.organizationFields}
              conversations={this.state.conversationsLabel}
            />
            <Card className="info-panel">
              <TestSendConversation id={conversation.conversationId} />
            </Card>
          </div>
        )}
      </div>
    );
  }

  handleDeleteTargetFilter = async targetFilterId => {
    this.props.deleteTargetFilter(targetFilterId);
    this.updateNumTargeted();
  };

  updateNumTargeted = () => {
    const { conversationId, fetchNumTargeted } = this.props;
    return fetchNumTargeted({ conversationId });
  };

  handleClickPublish = async () => {
    const { conversation, conversationId, publishConversation } = this.props;

    const detailsUrl = getConversationLink(conversation) + "/details";

    publishConversation({ conversationId });
    push(detailsUrl);
  };

  handleChangeList = async ({ value: listId }) => {
    this.setState({ targetSubscriberListId: listId });
    await this.props.putConversation({
      ...this.props.conversation,
      targetSubscriberListId: listId,
    });
    this.updateNumTargeted();
  };

  handleChangeMatchSettingRadio = async (matchSetting, ev) => {
    this.setState({ targetFilterMatchSetting: matchSetting });
    await this.props.putConversation({
      ...this.props.conversation,
      targetFilterMatchSetting: matchSetting,
    });
    this.updateNumTargeted();
  };

  handleAddTargetFilter = async () => {
    const {
      conversation: { conversationId },
    } = this.props;

    this.putTargetFilter({
      conditionDropdown: "is before",
      conversationId,
      fieldDropdown: "Date added",
      value: moment(new Date())
        .add(1, "days")
        .format("YYYY-MM-DD"),
    });

    this.updateNumTargeted();
  };

  getActivityValueOptions = () => {
    const activityValueOptions = [
      "All conversations",
      "Any conversations",
      "Last 7 days",
      "Last 30 days",
    ].map(fieldName => ({ label: fieldName, value: fieldName }));

    this.props.conversations.forEach(({ label, conversationId: value }) => {
      activityValueOptions.push({ label, value });
    });

    return activityValueOptions;
  };

  getSignupSourceValueOptions = () =>
    this.props.conversations
      .filter(({ type }) => type === "signup")
      .map(({ label, conversationId: value }) => ({ label, value }));

  getSubscriberListOptions = () => [
    ...this.props.subscriberLists.map(
      ({ name: label, subscriberListId: value }) => ({ label, value })
    ),
    { label: "All subscribers", value: "All" },
  ];

  handleChangeFieldDropdown = async (targetFilter, newDropdown) => {
    if (targetFilter.fieldDropdown !== newDropdown) {
      await this.putTargetFilter({
        ...targetFilter,
        fieldDropdown: newDropdown,
        value: "",
      });
      this.updateNumTargeted();
    }
  };

  handleChangeConditionDropdown = async (
    targetFilter,
    newConditionDropdown
  ) => {
    await this.putTargetFilter({
      ...targetFilter,
      conditionDropdown: newConditionDropdown,
    });
    this.updateNumTargeted();
  };

  handleChangeTargetFilterValue = async (targetFilter, value) => {
    await this.putTargetFilter({
      ...targetFilter,
      value,
    });
    this.updateNumTargeted();
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { conversationId, triggerId },
    },
  } = ownProps;

  const {
    subscriberLists: { byId: subscriberListById },
  } = state;

  const {
    conversationsById,
    numTargetedByConversationId,
  } = state.conversations;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  const globalTriggers = Object.values(state.globalTriggers.globalTriggersById);
  const subscriberLists = Object.values(subscriberListById);

  const numTargeted = numTargetedByConversationId[conversationId] || {
    isLoading: false,
  };
  const organizationFields = state.organizationFields.organizationFields;

  return {
    conversation,
    conversationId,
    globalTriggers,
    numTargeted,
    organizationFields,
    subscriberLists,
    triggerId,
  };
};

export default connect(
  mapStateToProps,
  {
    deleteTargetFilter,
    fetchConversation,
    fetchConversationsLabel,
    fetchGlobalTrigger,
    fetchGlobalTriggers,
    fetchNumTargeted,
    fetchOrganizationFields,
    fetchSubscriberLists,
    publishConversation,
    push,
    putConversation,
    putTargetFilter,
  }
)(CampaignConversationPublisherContainer);
