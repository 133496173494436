import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { updateSubscriberOwner } from "../../actions/subscriber-owner";
import { connect } from "react-redux";
import { Modal } from "../../components/shared";

class SubscriberOwner extends React.Component {
  state = {
    selectOwner: false,
  };

  updateOwner = ownerId => {
    const {
      subscriber: { subscriberId },
    } = this.props;

    const payload = {
      subscriberId,
      ownerId,
    };

    return this.props.updateSubscriberOwner(payload);
  };

  handleSelectOwner = () => this.setState({ selectOwner: true });

  removeOwner = () => {
    const {
      subscriber: { subscriberId },
    } = this.props;

    return this.props.updateSubscriberOwner({ subscriberId, ownerId: null });
  };

  render() {
    const { subscriber } = this.props;
    const { owner } = subscriber;
    const label = owner ? ownerLabel(owner) : "+";

    const classes = classnames("subscriber-owner", {
      "has-owner": !!owner,
      "new-owner": !owner,
    });

    return (
      <div className={classes}>
        <div className="select-owner" onClick={this.handleSelectOwner}>
          <span>{label}</span>
        </div>
        <SelectOwnerModal
          owners={this.props.owners}
          isOpen={this.state.selectOwner}
          onClose={() => this.setState({ selectOwner: false })}
          onSubmit={(...args) =>
            this.updateOwner(...args).finally(() =>
              this.setState({ selectOwner: false })
            )
          }
          onRemove={(...args) =>
            this.removeOwner(...args).finally(() =>
              this.setState({ selectOwner: false })
            )
          }
          currentOwnerId={owner && owner.userId}
        />
      </div>
    );
  }
}

SubscriberOwner.propTypes = {
  subscriber: PropTypes.object.isRequired,
};

export default connect(
  null,
  { updateSubscriberOwner }
)(SubscriberOwner);

export class SelectOwnerModal extends React.Component {
  state = {
    selectedId: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.selectedId && props.currentOwnerId) {
      return {
        ...state,
        selectedId: props.currentOwnerId,
      };
    }

    return null;
  }

  isRemoving = () => {
    const { currentOwnerId } = this.props;
    const { selectedId } = this.state;
    return currentOwnerId && currentOwnerId === selectedId ? true : false;
  };

  handleSubmit = () => {
    const { onSubmit, onRemove } = this.props;
    const { selectedId } = this.state;

    return this.isRemoving() ? onRemove() : onSubmit(selectedId);
  };

  render() {
    const { owners, currentOwnerId, ...restProps } = this.props;
    const { selectedId } = this.state;

    return (
      <Modal title="Select a contact owner" {...restProps}>
        <div className="modal__form">
          <div className="modal__fieldset">
            {owners.map(({ email, userId }) => (
              <div
                key={email}
                className={classnames("modal__radio-label", {
                  selected: userId === selectedId,
                })}
                onClick={() => this.setState({ selectedId: userId })}
              >
                {email}
              </div>
            ))}
          </div>

          <div className="modal__controls">
            <button
              type="button"
              className="paloma-button modal__button"
              onClick={this.props.onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="paloma-button paloma-button--pink modal__button"
              disabled={!selectedId}
              onClick={(...args) => this.handleSubmit(...args)}
            >
              {this.isRemoving() ? "Remove" : "Assign"}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export const ownerLabel = owner => owner.email.slice(0, 2);
