import React from "react";

class CopyToClipboard extends React.Component {
  copyToClipboard = e => {
    this.textArea.select();
    document.execCommand("copy");
    e.target.focus();
    this.props.onSuccess();
  };

  render() {
    return (
      <div>
        <div onClick={this.copyToClipboard}>
          {this.props.buttonText || "Copy"}
        </div>
        <form>
          <textarea
            style={{ position: "absolute", right: "-10000px" }}
            readOnly
            ref={textArea => (this.textArea = textArea)}
            value={this.props.toCopy}
          />
        </form>
      </div>
    );
  }
}

export default CopyToClipboard;
