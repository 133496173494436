import createReducer from "../utilities/create-reducer";
import * as constants from "../constants/persistent-menu";

const initialState = { menus: [], loading: true };

export default createReducer(initialState, {
  [constants.FETCH_PERSISTENT_MENU_REQUEST](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [constants.FETCH_PERSISTENT_MENU_SUCCESS](state, action) {
    if (action.payload) {
      return {
        ...state,
        loading: false,
        menus: {
          ...state.menus,
          [action.payload.id]: action.payload,
        },
      };
    } else {
      return state;
    }
  },
  [constants.PUT_PERSISTENT_MENU_SUCCESS](state, action) {
    return {
      ...state,
      menus: {
        ...state.menus,
        [action.payload.id]: action.payload,
      },
    };
  },
  [constants.CREATE_PERSISTENT_MENU_SUCCESS](state, action) {
    return {
      ...state,
      menus: {
        ...state.menus,
        [action.payload.id]: action.payload,
      },
    };
  },
  [constants.DISABLE_PERSISTENT_MENU_SUCCESS](state, action) {
    return {
      ...state,
      enabled: false,
    };
  },
  [constants.ENABLE_PERSISTENT_MENU_SUCCESS](state, action) {
    return {
      ...state,
      enabled: true,
    };
  },
});
