import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/acquisition-actions";

export const fetchAcquisitionActions = () => ({
  [CALL_API]: {
    endpoint: "/api/acquisition_actions/",
    method: "GET",
    types: [
      constants.FETCH_ACQUISITION_ACTIONS_REQUEST,
      constants.FETCH_ACQUISITION_ACTIONS_SUCCESS,
      constants.FETCH_ACQUISITION_ACTIONS_FAILURE,
    ],
  },
});

export const createManualMessagingAcquisitionAction = () => ({
  [CALL_API]: {
    endpoint: "/api/acquisition_actions/manual_messaging_activation",
    method: "POST",
    types: [
      constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_REQUEST,
      constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS,
      constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_FAILURE,
    ],
  },
});

export const deleteManualMessagingAcquisitionAction = () => ({
  [CALL_API]: {
    endpoint: "/api/acquisition_actions/manual_messaging_activation",
    method: "DELETE",
    types: [
      constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_REQUEST,
      constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS,
      constants.CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_FAILURE,
    ],
  },
});

export const createThreadControlPassAcquisitionAction = () => ({
  [CALL_API]: {
    endpoint: "/api/acquisition_actions/thread_control_pass",
    method: "POST",
    types: [
      constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_REQUEST,
      constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS,
      constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});

export const deleteThreadControlPassAcquisitionAction = () => ({
  [CALL_API]: {
    endpoint: "/api/acquisition_actions/thread_control_pass",
    method: "DELETE",
    types: [
      constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_REQUEST,
      constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS,
      constants.CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});
