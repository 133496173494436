import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { fetchSubscriberLists } from "../../actions/subscribers";
import AddSubscriberToListModal from "./AddSubscriberToListModal";
import { Table, ContentHeader } from "../shared";
import {
  fetchSubscriberLists as fetchAllLists,
  addSubscriberToList,
  removeSubscriberFromList,
} from "../../actions/subscriber-lists";

import { formatDate } from "../../utilities/format-date";

class SubscriberLists extends React.Component {
  state = {
    showAddSubscriberToListModal: false,
    subscriberLists: [],
    allSubscriberLists: [],
  };

  componentDidMount() {
    this.props
      .fetchSubscriberLists(this.props.subscriberId)
      .then(({ payload: subscriberLists }) =>
        this.setState({ ...this.state, subscriberLists })
      );

    this.props.fetchAllLists().then(({ payload: allSubscriberLists }) => {
      this.setState({ ...this.state, allSubscriberLists });
    });
  }

  render() {
    const joinedSubscriberListIds = this.state.subscriberLists.map(
      s => s.subscriberListId
    );

    const unjoinedSubscriberLists = this.state.allSubscriberLists.filter(
      l => !joinedSubscriberListIds.includes(l.subscriberListId)
    );

    return (
      <div className="subscriber-lists">
        <ContentHeader
          left={<h3>Lists</h3>}
          right={
            <button
              className="paloma-button"
              onClick={this.handleClickAddToList}
              disabled={!unjoinedSubscriberLists.length}
            >
              Add to list
            </button>
          }
        />

        <Table
          headers={["Name", "Subscribers", "Date created", ""]}
          rows={this.state.subscriberLists.map(subscriberList => [
            <Link
              className="table__link"
              to={`/audience/lists/${subscriberList.subscriberListId}`}
            >
              {subscriberList.name}
            </Link>,
            subscriberList.numSubscribers,
            formatDate(subscriberList.createdAt),
            <div>
              <button
                className="table__link"
                onClick={() =>
                  this.props
                    .removeSubscriberFromList(
                      this.props.subscriberId,
                      subscriberList.subscriberListId
                    )
                    .then(() => {
                      this.setState({
                        ...this.state,
                        subscriberLists: this.state.subscriberLists.filter(
                          l =>
                            l.subscriberListId !==
                            subscriberList.subscriberListId
                        ),
                      });
                    })
                }
              >
                Remove
              </button>
              <Link
                className="table__link"
                to={`/audience/lists/${subscriberList.subscriberListId}`}
              >
                View
              </Link>
            </div>,
          ])}
        />

        <AddSubscriberToListModal
          subscriberLists={unjoinedSubscriberLists}
          isOpen={this.state.showAddSubscriberToListModal}
          onClose={() => this.setState({ showAddSubscriberToListModal: false })}
          onAddToList={this.handleAddSubscriberToList}
        />
      </div>
    );
  }

  handleAddSubscriberToList = subscriberListId => {
    const { addSubscriberToList, subscriberId } = this.props;

    addSubscriberToList(subscriberId, subscriberListId).then(() => {
      this.setState({
        ...this.state,
        subscriberLists: [
          ...this.state.subscriberLists,
          this.state.allSubscriberLists.find(
            l => l.subscriberListId === subscriberListId
          ),
        ],
      });
    });
  };

  handleClickAddToList = () => {
    this.setState({ showAddSubscriberToListModal: true });
  };
}

export default connect(null, {
  fetchAllLists,
  addSubscriberToList,
  removeSubscriberFromList,
  fetchSubscriberLists,
})(SubscriberLists);
