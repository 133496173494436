import { CALL_API } from "redux-api-middleware";

import { push } from "react-router-redux";

import * as constants from "../constants/global-triggers";

export const fetchGlobalTriggers = () => ({
  [CALL_API]: {
    endpoint: "/api/global_triggers/",
    method: "GET",
    types: [
      constants.FETCH_GLOBAL_TRIGGERS_REQUEST,
      constants.FETCH_GLOBAL_TRIGGERS_SUCCESS,
      constants.FETCH_GLOBAL_TRIGGERS_FAILURE,
    ],
  },
});

export const fetchGlobalTrigger = globalTriggerId => ({
  [CALL_API]: {
    endpoint: `/api/global_triggers/${globalTriggerId}/`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_GLOBAL_TRIGGER_REQUEST,
        meta: { globalTriggerId },
      },
      constants.FETCH_GLOBAL_TRIGGER_SUCCESS,
      {
        type: constants.FETCH_GLOBAL_TRIGGER_FAILURE,
        meta: { globalTriggerId },
      },
    ],
  },
});

export const putGlobalTrigger = payload => async dispatch => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/global_triggers/`,
      method: "PUT",
      body: JSON.stringify({ global_trigger: payload }),
      types: [
        {
          type: constants.PUT_GLOBAL_TRIGGER_REQUEST,
          meta: payload.trigger_id
            ? { globalTriggerId: payload.trigger_id }
            : {},
        },
        constants.PUT_GLOBAL_TRIGGER_SUCCESS,
        {
          type: constants.PUT_GLOBAL_TRIGGER_FAILURE,
          meta: payload.trigger_id
            ? { globalTriggerId: payload.trigger_id }
            : {},
        },
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteGlobalTrigger = globalTriggerId => async dispatch => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/global_triggers/${globalTriggerId}/`,
      method: "DELETE",
      types: [
        {
          type: constants.DELETE_GLOBAL_TRIGGER_REQUEST,
          meta: { globalTriggerId },
        },
        {
          type: constants.DELETE_GLOBAL_TRIGGER_SUCCESS,
          // pass globalTriggerId to the reducer so the globalTrigger can be removed from the store
          meta: { globalTriggerId },
        },
        {
          type: constants.DELETE_GLOBAL_TRIGGER_FAILURE,
          meta: { globalTriggerId },
        },
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const duplicateTrigger = id => ({
  [CALL_API]: {
    endpoint: `/api/triggers/${id}/duplicate`,
    method: "POST",
    types: [
      constants.DUPLICATE_GLOBAL_TRIGGER_REQUEST,
      constants.DUPLICATE_GLOBAL_TRIGGER_SUCCESS,
      constants.DUPLICATE_GLOBAL_TRIGGER_FAILURE,
    ],
  },
});

export const redirectToGlobalTriggers = push.bind(null, "/triggers");

export const deleteGlobalTriggerAndRedirect = globalTriggerId => async dispatch => {
  await dispatch(deleteGlobalTrigger(globalTriggerId));
  return dispatch(redirectToGlobalTriggers());
};
