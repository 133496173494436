import React from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";

export default ({ selected, onChange, className, ...props }) => {
  const _className = classNames({
    "paloma-date-picker": true,
    [className]: !!className,
  });
  return (
    <div className={_className}>
      <DatePicker
        className="paloma-date-picker__date-picker"
        selected={selected}
        onChange={onChange}
        {...props}
      />
      <div className="paloma-date-picker__calendar-icon" />
    </div>
  );
};
