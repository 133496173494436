import React, { Component } from "react";
import { connect } from "react-redux";
import infoIcon from "../../images/info.svg";
import TextMessage from "./WMTextMessage";
import { TemplateMessage } from "../../components/ConversationDetail";
import QuickReplies from "../../components/QuickReplies";
import { createButton, putConversationStep } from "../../actions/conversations";
import debounce from "lodash/debounce";

class ConversationStepContainer extends Component {
  constructor(props) {
    super(props);

    this.putConversationStep = debounce(this.props.putConversationStep, 250);
  }

  renderText = () => {
    const { conversationStep, editable, isTextWelcomeMessage } = this.props;

    return (
      <div className="message">
        <TextMessage
          isTextWelcomeMessage={isTextWelcomeMessage}
          conversationStep={conversationStep}
          editable={editable}
          handleFieldChange={this.handleFieldChange}
        />
      </div>
    );
  };

  renderTemplate = () => {
    const { conversationStep, editable } = this.props;

    return (
      <div className="message">
        <TemplateMessage
          editable={editable}
          errors={conversationStep.errors.templateCards}
          handleFieldChange={this.handleFieldChange}
          randomizeButtons={conversationStep.randomizeButtons}
          randomizeTemplateCards={conversationStep.randomizeTemplateCards}
          templateCards={conversationStep.templateCards}
        />
      </div>
    );
  };

  handleFieldChange = (...args) => {
    const [field, value] = args;
    const step = { ...this.props.conversationStep, [field]: value };
    this.saveConversationStep(step);
  };

  saveConversationStep = async (conversationStep) => {
    this.putConversationStep(conversationStep);
  };

  render() {
    const { conversationStep, editable } = this.props;
    if (!conversationStep) {
      return <div />;
    }

    const renderer = {
      text: this.renderText,
      template: this.renderTemplate,
    };

    return (
      <div>
        <div className="conversation-step">
          <div className="conversation-step__bot-message-container">
            <div style={{ position: "relative" }}>
              {!editable && (
                <span
                  className="tooltip-container preview"
                  data-balloon={"ID: " + conversationStep.conversationStepId}
                  data-balloon-pos="down"
                >
                  <img
                    src={infoIcon}
                    alt={conversationStep.conversationStepId}
                  />
                </span>
              )}
              {renderer[conversationStep.messageType]()}
              <QuickReplies
                conversationStep={conversationStep}
                quickReplies={conversationStep.quickReplies}
                lengthError={
                  conversationStep.errors.conversationStepQuickReplies
                }
                errors={conversationStep.errors.quickReplies}
                random={conversationStep.randomizeQuickReplies}
                refreshSteps={this.props.refreshSteps}
                conversationStep={conversationStep}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { conversationStepId } = ownProps;
  const {
    conversations: { conversationStepsById },
  } = state;

  const conversationStep = conversationStepsById[conversationStepId];

  return {
    conversationStep,
    conversationStepId,
  };
};

export default connect(mapStateToProps, {
  createButton,
  putConversationStep,
})(ConversationStepContainer);
