import createReducer from "../utilities/create-reducer";
import { withoutKey } from "../utilities/reducers";

import * as constants from "../constants/manual-messaging-activations";

const initialState = { byTriggerId: [] };

export default createReducer(initialState, {
  [constants.CREATE_MANUAL_MESSAGING_ACTIVATION_SUCCESS](state, action) {
    return {
      ...state,
      byTriggerId: {
        ...state.byTriggerId,
        [action.payload.triggerId]: action.payload,
      },
    };
  },

  [constants.FETCH_MANUAL_MESSAGING_ACTIVATION_SUCCESS](state, action) {
    if (action.payload) {
      return {
        ...state,
        byTriggerId: {
          ...state.byTriggerId,
          [action.payload.triggerId]: action.payload,
        },
      };
    } else {
      return state;
    }
  },

  [constants.DELETE_MANUAL_MESSAGING_ACTIVATION_SUCCESS](state, action) {
    return {
      ...state,
      byTriggerId: withoutKey(state.byTriggerId, action.payload.triggerId),
    };
  },
});
