import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/inactive-signup-conversations";

const initialState = {
  loading: false,
  data: [],
};

export default createReducer(initialState, {
  [constants.FETCH_INACTIVE_SIGNUP_CONVERSATIONS_REQUEST](state, action) {
    return {
      ...state,
      data: [],
      loading: true,
    };
  },

  [constants.FETCH_INACTIVE_SIGNUP_CONVERSATIONS_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },

  [constants.FETCH_INACTIVE_SIGNUP_CONVERSATIONS_FAILURE](state, action) {
    return {
      ...state,
      errorMessage: action.payload.response
        ? JSON.parse(action.payload.response).message
        : "",
      loading: false,
    };
  },
});
