import { normalize } from "normalizr";

import { subscriberListSchema } from "../schemas";

import createReducer from "../utilities/create-reducer";
import { byId } from "../utilities/reducers";

import * as constants from "../constants/subscriber-lists";

const initialState = {
  byId: {},
  isLoading: false,
  errorMessage: "",
};

export default createReducer(initialState, {
  [constants.FETCH_SUBSCRIBER_LISTS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_SUBSCRIBER_LISTS_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      byId: byId(action.payload, "subscriberListId"),
    };
  },
  [constants.FETCH_SUBSCRIBER_LIST_REQUEST](state, action) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.meta.subscriberListId]: {
          ...(state.byId[action.meta.subscriberListId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.FETCH_SUBSCRIBER_LIST_SUCCESS](state, action) {
    const { order = "DESC", sortBy = "Received", page = 1, search = "" } =
      action.meta || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.subscriberListId]: {
          ...action.payload,
          isLoading: false,
          order,
          sortBy,
          page,
          search,
        },
      },
    };
  },

  [constants.REMOVE_SUBSCRIBER_FROM_LIST_SUCCESS](state, action) {
    const subscriberList = state.byId[action.meta.subscriberListId] || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        [action.meta.subscriberListId]: {
          ...subscriberList,
          subscribers: (subscriberList.subscribers || []).filter(
            subscriberId =>
              subscriberId !== parseInt(action.meta.subscriberId, 10)
          ),
        },
      },
    };
  },

  [constants.DELETE_SUBSCRIBER_LIST_SUCCESS](state, action) {
    const subscriberListsById = state.byId;
    delete subscriberListsById[action.meta.subscriberListId];

    return {
      ...state,
      byId: subscriberListsById,
    };
  },

  [constants.PUT_SUBSCRIBER_LIST_REQUEST](state, action) {
    if (!action.meta.subscriberListId) {
      return {
        ...state,
        isLoading: true,
      };
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [action.meta.subscriberListId]: {
          ...(state.byId[action.meta.subscriberListId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.PUT_SUBSCRIBER_LIST_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, subscriberListSchema);
    const normalizedSubscriberLists = Object.values(
      normalizedData.entities.subscriberLists || {}
    );

    delete normalizedSubscriberLists[undefined]; // for subscribers in no list

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberLists.reduce(
          (subscriberListsById, subscriberList) => ({
            [subscriberList.subscriberListId]: {
              ...subscriberList,
              isLoading: false,
            },
          }),
          {}
        ),
      },
    };
  },

  [constants.POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_SUCCESS](state, action) {
    const subscriberListId = action.payload.subscriberListId
    
    return {
      ...state,
      byId: {
        ...state.byId,
        [subscriberListId]: {
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.STORE_SUBSCRIBER_IN_LIST](state, action) {
    const subscriberListId = action.payload.subscriberListId
    const subscriber = action.payload.subscriber

    const oldList = {...state.byId[subscriberListId]} || {}
    var nextSubscribers = [...oldList.subscribers] || []
    const putAtTopOfSubscriberTable = ((oldList.order === "DESC") && (oldList.sortBy === "Received") && !oldList.search) 
    if (putAtTopOfSubscriberTable) {
      nextSubscribers = nextSubscribers.filter(el => el.subscriberId !== subscriber.subscriberId);
      nextSubscribers.unshift(subscriber)
    } else {
      // Replace subscriber object at same index
      const index = nextSubscribers.findIndex(el => el.subscriberId === subscriber.subscriberId)
      if(index !== -1) {
        nextSubscribers.splice(index, 1, subscriber)
      }
    }
        
    return {
      ...state,
      byId: {
        ...state.byId,
        [subscriberListId]: {
          ...oldList,
          subscribers: nextSubscribers
        },
      },
    };
  },
});
