import React from "react";
import { connect } from "react-redux";
import { capitalizeString } from "../../utilities/format-string";
import errorIcon from "../../images/error.svg";
import { ShowDisplayErrorContext } from "../../utilities/contexts"

const Error = ({ error }) => {
  if (typeof error === "string") {
    return (
      <ShowDisplayErrorContext.Consumer>
        {show => show && (
          <div className="error-container">
            <img
              src={errorIcon}
              className="conversation-publisher__notice-section-icon"
              alt=""
            />
            {capitalizeString(error)}
          </div>
        )}
      </ShowDisplayErrorContext.Consumer>
    );
  } else {
    return false;
  }
};

export default Error