import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/global-default-conversations";

export const fetchGlobalDefaultConversations = () => ({
  [CALL_API]: {
    endpoint: "/api/global_default_conversations/",
    method: "GET",
    types: [
      constants.FETCH_GLOBAL_DEFAULT_CONVERSATIONS_REQUEST,
      constants.FETCH_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS,
      constants.FETCH_GLOBAL_DEFAULT_CONVERSATIONS_FAILURE,
    ],
  },
});

export const createGlobalDefaultConversation = payload => ({
  [CALL_API]: {
    endpoint: "/api/global_default_conversations/",
    method: "POST",
    body: JSON.stringify({ conversation: payload }),
    types: [
      constants.CREATE_GLOBAL_DEFAULT_CONVERSATIONS_REQUEST,
      constants.CREATE_GLOBAL_DEFAULT_CONVERSATIONS_SUCCESS,
      constants.CREATE_GLOBAL_DEFAULT_CONVERSATIONS_FAILURE,
    ],
  },
});
