import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { ContentHeader } from "../../components/shared";
import { EditConversationDetailsForm } from "../../components/ConversationDetail";
import {
  fetchConversation,
  putConversation,
} from "../../actions/conversations";
import { fetchCampaign } from "../../actions/campaigns";
import { fetchGlobalTrigger } from "../../actions/global-triggers";

import { getConversationLink } from "../../utilities/conversation";

class EditConversationDetailsContainer extends Component {
  state = {
    hasBeenEdited: false,
  };

  async componentDidMount() {
    const {
      campaignId,
      conversationId,
      triggerId,

      fetchConversation,
      fetchCampaign,
      fetchGlobalTrigger,
    } = this.props;

    const promises = [fetchConversation(conversationId)];

    if (campaignId) promises.push(fetchCampaign(campaignId));
    else if (triggerId) promises.push(fetchGlobalTrigger(triggerId));

    await Promise.all(promises);
  }

  render() {
    const { conversation, conversationId } = this.props;

    if (this.state.hasBeenEdited) {
      const detailsUrl = getConversationLink(conversation) + "/details";
      return <Redirect to={detailsUrl} />;
    }

    return (
      <Loader
        loaded={!conversation.isLoading}
        loadedClassName="conversation-detail"
      >
        <ContentHeader left={<h1>Edit details</h1>} right="" />
        <EditConversationDetailsForm
          conversationId={conversationId}
          conversationName={conversation.label}
          utm={conversation.utm}
          onSubmit={this.editConversationDetails}
        />
      </Loader>
    );
  }

  editConversationDetails = async (e) => {
    e.preventDefault();

    const {
      conversationId,
      conversation: { type },
      putConversation,
    } = this.props;

    const {
      label: { value: label },
    } = e.currentTarget;

    const {
      utm: { value: utm },
    } = e.currentTarget;

    await putConversation({
      conversation_id: conversationId,
      type,
      label: label.trim(),
      utm: utm.trim(),
    });

    this.setState({ hasBeenEdited: true });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { campaignId, conversationId, triggerId },
    },
  } = ownProps;

  const conversation = {
    isLoading: true,
    ...state.conversations.conversationsById[conversationId],
  };

  return {
    campaignId,
    conversationId,
    conversation,
    triggerId,
  };
};

export default connect(mapStateToProps, {
  fetchCampaign,
  fetchConversation,
  fetchGlobalTrigger,
  putConversation,
})(EditConversationDetailsContainer);
