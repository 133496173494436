import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  downloadAudienceCsv,
  fetchSubscribers,
} from "../../actions/subscribers";

import {
  ContentHeader,
  Card,
  DeleteConfirmationModal
} from "../../components/shared";
import Tooltip from "rc-tooltip";
import Dropdown from "../../components/shared/Dropdown";
import { createUnsubscribersCustomAudience } from "../../actions/current-organization";
import SubscriberTable from "../../components/Audience/SortableSubscriberTable";
import SubscriberMessageHistory from "../SubscriberMessageHistory";

class UnsubscribersListDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "DESC",
      page: 1,
      showSubscribers: true,
      sortBy: "Received",
      subscriberListSearchQuery: "",
      selectedSubscriberId: null,
      displayConfirmCustomAudienceModal: false,
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const { page, order, sortBy } = this.state;
    await this.props.fetchSubscribers({ order, page, sortBy, unsubscribed: true });
    if (!this.state.selectedSubscriberId && this.props.subscribers.length) {
      this.setState({
        selectedSubscriberId: this.props.subscribers[0].subscriberId,
      });
    }
  }

  setConversationDetail = id => {
    this.setState({ selectedSubscriberId: id });
  };

  showConfirmModal = () => this.setState({ displayConfirmCustomAudienceModal: true });
  hideConfirmModal = () => this.setState({ displayConfirmCustomAudienceModal: false });

  createCustomAudience = () => {
    this.props.createUnsubscribersCustomAudience()
    this.hideConfirmModal()
  }

  render() {
    const {
      page,
      subscribersLoading,
      subscribers,
      subscribersTotal,
      totalSubscriberPages,
      order,
      search,
      sortBy,
    } = this.props;

    const fetchOptions = { order, page, search, sortBy, unsubscribed: true };

    return (
      <div className="audience">
        <ContentHeader
          left={<h1 className="page-title">Unsubscribers</h1>}
          right={
            <div>
              <Dropdown
                style={{ zIndex: 5000 }}
                buttonText={
                  <div style={{ padding: "0px 12px" }}>
                    Export
                  </div>
                }
                dropdownOptions={[
                  {
                    text: <span>CSV</span>,
                    onClick: () => this.props.downloadAudienceCsv("text/csv", { unsubscribed: true }),
                  },
                  {
                    text: <span>JSON</span>,
                    onClick: () => this.props.downloadAudienceCsv("application/json", { unsubscribed: true }),
                  },
                  {
                    text: <span>Create Facebook Audience</span>,
                    onClick: () => this.showConfirmModal(),
                  },
                ]}
              />
            </div>
          }
          style={{ margin: "20px 13px 0px 0px" }}
        />
        {this.props.unsubscribersCustomAudienceId &&
          <div className="targeting white" style={{ marginLeft: "20px", marginRight: "12px", position: 'relative' }}>
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Tooltip
                placement="bottom"
                trigger={["hover"]}
                overlay="This list is actively updating a Facebook Custom Audience"
              >
                <strong>Facebook Custom Audience</strong>
              </Tooltip>
            </div>
          </div>
        }
        <div className="split">
          <Card className="list" loading={subscribersLoading}>
            <SubscriberTable
              {...fetchOptions}
              totalPages={totalSubscriberPages}
              onRowClick={subscriberId => {
                this.setConversationDetail(subscriberId);
              }}
              subscribers={subscribers}
              fetch={options =>
                this.props.fetchSubscribers({ ...fetchOptions, ...options })
              }
              total={subscribersTotal}
            />
          </Card>
          <Card className="profile">
            <SubscriberMessageHistory
              subscriberId={this.state.selectedSubscriberId}
              handleMessageFromSubscriber={this.handleMessageFromSubscriber}
            />
          </Card>
        </div>
        {this.state.displayConfirmCustomAudienceModal && (
          <DeleteConfirmationModal
            title={`Create a ${this.props.unsubscribersCustomAudienceId ? "New" : ""} Custom Audience in Facebook`}
            body={this.props.unsubscribersCustomAudienceId ? (
              "This list is already connected to a Custom Audience. Clicking Confirm will create a new Custom Audience and the existing Custom Audience will no longer be updated. Would you like to create a new Custom Audience?"
            ) : (
              "Your custom audience will be created and accessible in your Facebook Ad Account, where it will be updated in real-time as new subscribers are added to this list. Clicking create below will immediately generate the audience, and Facebook will begin importing your customers – it may take a few moments, so check back in your Ads tool to confirm."
            )}
            onClose={this.hideConfirmModal}
            handleDelete={this.createCustomAudience}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    subscribers: {
      isLoading: subscribersLoading,
      subscribersById,
      pagination: {
        currentPage: page,
        totalPages: totalSubscriberPages,
        totalItems: subscribersTotal,
        pages: subscriberPages,
      },
      meta: { order = "DESC", sortBy, search },
    },
    subscriberLists: {
      byId: subscriberListsById,
      isLoading: subscriberListsLoading,
    },
    currentOrganization : {
      unsubscribersCustomAudienceId
    }
  } = state;

  return {
    unsubscribersCustomAudienceId,
    page,
    subscribersLoading,
    subscriberListsLoading,
    subscriberLists: Object.values(subscriberListsById),
    subscribers: subscriberPages[page].map(
      subscriberId => subscribersById[subscriberId]
    ),
    subscribersTotal,
    totalSubscriberPages,
    order,
    sortBy,
    search,
  };
};

export default connect(mapStateToProps, {
  downloadAudienceCsv,
  fetchSubscribers,
  createUnsubscribersCustomAudience
})(UnsubscribersListDetail);
