import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/dashboard";

const initialState = {
  loaded: false,

  recentConversations: [],

  totalSubscribers: 0,
  totalConversations: 0,
  totalMessages: 0,
  totalUnsubscribers: 0,

  orders: [],
  totalAddToCarts: 0,
  totalRevenue: 0,

  feedback: [],
};

export default createReducer(initialState, {
  [constants.FETCH_RECENT_CONVERSATIONS_SUCCESS](state, action) {
    return {
      ...state,
      recentConversations: [...action.payload],
    };
  },

  [constants.FETCH_DASHBOARD_STATS_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.FETCH_SHOPIFY_STATS_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
