import React from "react";
import PropTypes from "prop-types";

const List = ({ name }) => {
  if (name) {
    return (
      <div className="list">
        <strong>List</strong>
        <br />
        <div className="list">{name}</div>
      </div>
    );
  }
};

List.propTypes = {
  name: PropTypes.string.isRequired,
};

export default List;
