import React, { Component } from "react";
import PropTypes from "prop-types";

import { Editor } from "slate-react";

const Field = props => {
  const {
    node: { data },
  } = props;

  return <span className="builder-input__field">{data.get("fieldType")}</span>;
};

class BuilderInputContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    maxChars: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onEscape: PropTypes.func,
    placeholder: PropTypes.string,
    placeholderClassName: PropTypes.string,
  };

  state = {
    schema: {
      nodes: {
        field: Field,
      },
    },
  };

  constructor(props) {
    super(props);

    this.editor = React.createRef();
  }

  render() {
    const {
      className = "",
      editable = true,
      editorState,
      placeholder = "",
      placeholderClassName = "builder-input__placeholder",
    } = this.props;

    const { schema } = this.state;

    return (
      <Editor
        className={className}
        onBlur={this.onBlur}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyDown}
        placeholder={placeholder}
        placeholderClassName={placeholderClassName}
        readOnly={!editable}
        ref={this.editor}
        schema={schema}
        state={editorState}
      />
    );
  }

  onBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    } else if (this.props.onEscape) {
      this.props.onEscape(e);
    }
  };

  handleChange = ({ state }) => {
    const { maxChars, onChange } = this.props;

    if (maxChars) {
      const numChars = state.texts.reduce((acc, t) => t.text + acc, "").length;
      if (numChars > maxChars) return;
    }

    onChange({ state });
  };

  handleKeyDown = e => {
    if (e.key === "Escape" || (e.key === "Enter" && !e.shiftKey)) {
      this.blur();
      return false;
    }
  };

  blur = () => this.editor.current.blur();
}

export default BuilderInputContainer;
