import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/quick-replies";
import { byId, withoutKey } from "../utilities/reducers";

const initialState = {};

export default createReducer(initialState, {
  [constants.FETCH_QUICK_REPLIES_SUCCESS](state, action) {
    return {
      ...state,
      ...byId(action.payload),
    };
  },
  [constants.FETCH_QUICK_REPLY_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    };
  },
  [constants.UPDATE_QUICK_REPLY_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    };
  },
  [constants.ADD_QUICK_REPLY_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    };
  },
  [constants.ADD_TRIGGER_TO_QUICK_REPLY_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.quickReply.id]: action.payload.quickReply,
    };
  },
  [constants.ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    };
  },
  [constants.DELETE_QUICK_REPLY_SUCCESS](state, action) {
    return withoutKey(state, action.meta.id);
  },
});
