import React from "react";
import Select from "react-select";
import classNames from "classnames";

import "react-select/dist/react-select.css";

class TagValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelected: false,
    };

    this.toggleSelected = this.toggleSelected.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  toggleSelected() {
    this.setState({ isSelected: !this.state.isSelected });
  }

  handleRemove(e) {
    e.stopPropagation(); // prevent the tag from appearing de-selected during removal
    this.props.onRemove(this.props.value);
  }

  render() {
    const tagClassName = classNames({
      "tag-select__tag": true,
      "tag-select__tag--selected": this.state.isSelected,
    });

    let deleteButton = null;
    if (this.state.isSelected) {
      deleteButton = (
        <button
          className="paloma-button tag-select__delete-button"
          onClick={this.handleRemove}
        >
          <span className="tag-select__delete-icon">&times;</span>
        </button>
      );
    }

    return (
      <div className={tagClassName} onClick={this.toggleSelected}>
        <span className="tag-select__tag-label">{this.props.value.label}</span>
        {deleteButton}
      </div>
    );
  }
}

const promptTextCreator = label => `Create tag "${label}"`;
const arrowRenderer = () => {};

class TagSelect extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value.length !== this.props.value.length;
  }

  render = () => {
    const Component =
      this.props.creatable === false ? Select : Select.Creatable;
    return (
      <Component
        className="tag-select"
        value={this.props.value}
        options={this.props.options}
        onChange={this.props.onChange}
        valueComponent={TagValue}
        promptTextCreator={promptTextCreator}
        placeholder={this.props.placeholder || "Add tags..."}
        arrowRenderer={arrowRenderer}
        clearable={false}
        openOnFocus={false}
        openAfterFocus={false}
        multi
      />
    );
  };
}

export default TagSelect;
