import React from "react";
import Select from "react-select";

const optionsByField = {
  completed: ["did", "did not"],
  clicked: ["something in", "nothing in"],
  "added to cart": ["did", "did not"],
  purchased: ["did", "did not"],
  "dropped off": ["did"],
  converted: ["did", "did not"],
};

const stringToOption = value => ({
  value,
  label: value,
});

class SequenceTargetFilter extends React.Component {
  state = {
    conditionDropdown: null,
    fieldDropdown: null,
    value: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.conditionDropdown === null &&
      state.fieldDropdown === null &&
      state.value === null
    ) {
      return {
        conditionDropdown: props.conditionDropdown,
        fieldDropdown: props.fieldDropdown,
        value: props.value,
      };
    }
    return null;
  }

  renderFieldDropdown = () => {
    const { fieldDropdown } = this.state;
    const { published, conversationId } = this.props;

    const handleChange = option => {
      this.setState(
        {
          fieldDropdown: option ? option.value : null,
          conditionDropdown:
            option && option.value === "dropped off" ? "did" : null,
          value:
            option && option.value !== "dropped off" ? conversationId : null,
        },
        () => {
          this.props.onChange(this.state);
        }
      );
    };

    return (
      <Select.Creatable
        options={Object.keys(optionsByField).map(stringToOption)}
        value={fieldDropdown}
        onChange={handleChange}
        disabled={published}
        className="conversation-publisher__target-filter__field-dropdown"
      />
    );
  };

  renderConditionDropdown = () => {
    const { conditionDropdown, fieldDropdown } = this.state;
    const { published } = this.props;

    if (fieldDropdown === "dropped off") {
      return null;
    }

    return (
      <Select.Creatable
        options={
          fieldDropdown ? optionsByField[fieldDropdown].map(stringToOption) : []
        }
        value={conditionDropdown}
        onChange={option => {
          this.setState(
            { conditionDropdown: option ? option.value : null },
            () => {
              this.props.onChange(this.state);
            }
          );
        }}
        disabled={published}
        className="conversation-publisher__target-filter__condition-dropdown"
      />
    );
  };

  renderTargetValue = () => {
    const { fieldDropdown, value } = this.state;
    const { published, steps } = this.props;

    if (fieldDropdown !== "dropped off") {
      return null;
    }

    return (
      <Select.Creatable
        options={steps}
        value={value}
        onChange={option => {
          this.setState({ value: option ? option.value : null }, () => {
            this.props.onChange(this.state);
          });
        }}
        disabled={published}
        className="conversation-publisher__target-filter__condition-dropdown"
      />
    );
  };

  render() {
    return (
      <div className="conversation-publisher__target-filter">
        {this.renderFieldDropdown()}
        {this.renderConditionDropdown()}
        {this.renderTargetValue()}
        {this.props.published ? null : (
          <button
            disabled={this.props.published}
            className="conversation-publisher__target-filter__delete"
            onClick={() => this.props.onDelete(this.props.id)}
          />
        )}
      </div>
    );
  }
}

export default SequenceTargetFilter;
