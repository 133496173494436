import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

const PageControls = ({ state, controls, match }) => {
  const _controls =
    controls instanceof Function ? controls(state, match) : controls;

  return (
    <ul className="page-controls">
      {_controls.map(({ url, title }, i) => {
        const _url = url instanceof Function ? url(match) : url;
        const isExactMatch = _url === match.url;
        const pageControlClassName = classNames({
          "page-controls__page-control": true,
          "page-controls__page-control--current": isExactMatch,
        });
        return (
          <li key={i} className={pageControlClassName}>
            <Link to={_url}>{title}</Link>
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = state => ({ state: state });

export default connect(
  mapStateToProps,
  null
)(PageControls);
