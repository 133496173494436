import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchRecentConversations,
  fetchDashboardStats,
  fetchShopifyStats,
} from "../../actions/dashboard";

import { Card, ContentHeader } from "../../components/shared";
import Conversations from "../../components/Conversations";
import PaginatedMessages from "../../components/PaginatedMessages";

class Dashboard extends Component {
  state = {
    loadingConversations: true,
    loadingStats: true,
    loadingShopify: true,
  };

  componentDidMount() {
    this.props
      .fetchRecentConversations()
      .then(() => this.setState({ loadingConversations: false }));
    this.props
      .fetchDashboardStats()
      .then(() => this.setState({ loadingStats: false }));
    this.props
      .fetchShopifyStats()
      .then(() => this.setState({ loadingShopify: false }));
  }
  render = () => {
    return (
      <div className="dashboard">
        <ContentHeader left={<h1 className="page-title">Dashboard</h1>} />
        <div className="row">
          <Card loading={this.state.loadingStats}>
            <h3>Subscribers</h3>
            <span className="stat">{this.props.totalSubscribers}</span>
          </Card>
          {this.props.totalConversations ? (
            <Card loading={this.state.loadingStats}>
              <h3>Total Conversations</h3>
              <span className="stat">{this.props.totalConversations}</span>
            </Card>
          ) : null}
          <Card loading={this.state.loadingStats}>
            <h3>Total Unsubscribers</h3>
            <span className="stat">{this.props.totalUnsubscribers || 0}</span>
          </Card>
          {this.props.totalConversions ? (
            <Card loading={this.state.loadingStats}>
              <h3>Total Conversions</h3>
              <span className="stat">{this.props.totalConversions}</span>
            </Card>
          ) : null}
        </div>
        {this.props.hasShopifyStorefront ? (
          <div className="row">
            <Card loading={this.state.loadingShopify}>
              <h3>Total Add to Carts</h3>
              <span className="stat">{this.props.totalAddToCarts || 0}</span>
            </Card>
            <Card loading={this.state.loadingShopify}>
              <h3>Total Revenue</h3>
              <span className="stat">${(this.props.totalRevenue / 100.0).toFixed(2)}</span>
            </Card>
            <Card loading={this.state.loadingShopify}>
              <h3>Total Purchases</h3>
              <span className="stat">{(this.props.orders || []).length}</span>
            </Card>
            <Card loading={this.state.loadingShopify}>
              <h3>Average Purchase Size</h3>
              <span className="stat">
                $
                {(this.props.orders || []).length ? (this.props.totalRevenue / 100.0 / (this.props.orders || []).length).toFixed(2) : 0}
              </span>
            </Card>
          </div>
        ) : null}
        <div className="row">
          <Card loading={this.state.loadingConversations}>
            <h3>Recent Conversations</h3>
            <Conversations conversations={this.props.recentConversations} />
          </Card>
          <PaginatedMessages messageType={"unexpected"} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  hasShopifyStorefront: state.currentOrganization.hasShopifyStorefront,
  ...state.dashboard,
});

export default connect(mapStateToProps, {
  fetchRecentConversations,
  fetchDashboardStats,
  fetchShopifyStats,
})(Dashboard);
