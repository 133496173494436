import { CALL_API } from "redux-api-middleware";
import qs from "query-string";

import * as constants from "../constants/subscriber-lists";

export const fetchSubscriberLists = (search = null) => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_lists${
      search ? `?${qs.stringify({ search })}` : ""
    }`,
    method: "GET",
    types: [
      constants.FETCH_SUBSCRIBER_LISTS_REQUEST,
      constants.FETCH_SUBSCRIBER_LISTS_SUCCESS,
      constants.FETCH_SUBSCRIBER_LISTS_FAILURE,
    ],
  },
});

export const fetchSubscriberList = (
  subscriberListId,
  { order = "DESC", sortBy = "Received", page = 1, search = "" } = {}
) => {
  const params = qs.stringify({ order, page, search, sortBy });

  return {
    [CALL_API]: {
      endpoint: `/api/subscriber_lists/${subscriberListId}?${params}`,
      method: "GET",
      types: [
        {
          type: constants.FETCH_SUBSCRIBER_LIST_REQUEST,
          meta: { subscriberListId },
        },
        {
          type: constants.FETCH_SUBSCRIBER_LIST_SUCCESS,
          meta: { subscriberListId, order, page, search, sortBy },
        },
        {
          type: constants.FETCH_SUBSCRIBER_LIST_FAILURE,
          meta: { subscriberListId },
        },
      ],
    },
  };
};

export const putSubscriberList = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/subscriber_lists/`,
      method: "PUT",
      body: JSON.stringify({ subscriber_list: payload }),
      types: [
        {
          type: constants.PUT_SUBSCRIBER_LIST_REQUEST,
          meta: payload.subscriberListId
            ? { subscriberListId: payload.subscriberListId }
            : {},
        },
        constants.PUT_SUBSCRIBER_LIST_SUCCESS,
        {
          type: constants.PUT_SUBSCRIBER_LIST_FAILURE,
          meta: payload.subscriberListId
            ? { subscriberListId: payload.subscriberListId }
            : {},
        },
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const addSubscriberToList = (subscriberId, subscriberListId) => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_lists/${subscriberListId}/members/${subscriberId}/`,
    method: "PUT",
    types: [
      {
        type: constants.ADD_SUBSCRIBER_TO_LIST_REQUEST,
        meta: { subscriberId, subscriberListId },
      },
      {
        type: constants.ADD_SUBSCRIBER_TO_LIST_SUCCESS,
        meta: { subscriberId },
      },
      {
        type: constants.ADD_SUBSCRIBER_TO_LIST_FAILURE,
        meta: { subscriberId, subscriberListId },
      },
    ],
  },
});

export const removeSubscriberFromList = (subscriberId, subscriberListId) => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_lists/${subscriberListId}/members/${subscriberId}/`,
    method: "DELETE",
    types: [
      {
        type: constants.REMOVE_SUBSCRIBER_FROM_LIST_REQUEST,
        meta: { subscriberId, subscriberListId },
      },
      {
        type: constants.REMOVE_SUBSCRIBER_FROM_LIST_SUCCESS,
        meta: { subscriberId, subscriberListId },
      },
      {
        type: constants.REMOVE_SUBSCRIBER_FROM_LIST_FAILURE,
        meta: { subscriberId, subscriberListId },
      },
    ],
  },
});

export const deleteSubscriberList = (subscriberListId) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/subscriber_lists/${subscriberListId}/`,
      method: "DELETE",
      types: [
        constants.DELETE_SUBSCRIBER_LIST_REQUEST,
        {
          type: constants.DELETE_SUBSCRIBER_LIST_SUCCESS,
          meta: { subscriberListId },
        },
        constants.DELETE_SUBSCRIBER_LIST_FAILURE,
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const createActiveSubscriberList = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: "/api/active_subscriber_list/",
      method: "POST",
      body: JSON.stringify(payload),
      types: [
        constants.CREATE_ACTIVE_SUBSCRIBER_LIST_REQUEST,
        constants.CREATE_ACTIVE_SUBSCRIBER_LIST_SUCCESS,
        constants.CREATE_ACTIVE_SUBSCRIBER_LIST_FAILURE,
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const fetchActiveListNumTargets = (payload) => async (dispatch) => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: "/api/active_subscriber_list/num_targeted/",
      method: "POST",
      body: JSON.stringify(payload),
      types: [
        "FETCH_ACTIVE_LIST_NUM_TARGETS_REQUEST",
        "FETCH_ACTIVE_LIST_NUM_TARGETS_SUCCESS",
        "FETCH_ACTIVE_LIST_NUM_TARGETS_FAILURE",
      ],
    },
  });

  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const fetchSubscriberListTargetFilters = (subscriberListId) => ({
  [CALL_API]: {
    endpoint: `/api/target_filters/?subscriber_list_id=${subscriberListId}`,
    method: "GET",
    types: [
      "FETCH_SUBSCRIBER_LIST_TARGET_FILTERS_REQUEST",
      "FETCH_SUBSCRIBER_LIST_TARGET_FILTERS_SUCCESS",
      "FETCH_SUBSCRIBER_LIST_TARGET_FILTERS_FAILURE",
    ],
  },
});

export const downloadSubscriberList = (subscriberListId, token, mimetype) => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_lists/${subscriberListId}/download?token=${token}&mimetype=${mimetype}`,
    method: "GET",
    types: [
      "DOWNLOAD_SUBSCRIBER_LIST_REQUEST",
      "DOWNLOAD_SUBSCRIBER_LIST_SUCCESS",
      "DOWNLOAD_SUBSCRIBER_LIST_FAILURE",
    ],
  },
});

export const createCustomAudience = (subscriberListId) => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_lists/${subscriberListId}/custom_audience`,
    method: "POST",
    types: [
      constants.POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_REQUEST,
      constants.POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_SUCCESS,
      constants.POST_SUBSCRIBER_LIST_CREATE_AUDIENCE_FAILURE,
    ],
  },
});

export const storeSubscriberInList = (subscriberListId, subscriber) => ({
  type: constants.STORE_SUBSCRIBER_IN_LIST,
  payload: { subscriberListId, subscriber },
})
