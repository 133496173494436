import React from "react";
import classNames from "classnames";

import Dropdown from "./Dropdown";

export default ({
  icon,
  dropdownOptions,
  disabled = false,
  className = null,
  onToggle = null,
}) => {
  const _className = classNames({
    "icon-dropdown": true,
    [className]: !!className,
  });
  return (
    <Dropdown
      buttonText={icon}
      dropdownOptions={dropdownOptions}
      disabled={disabled}
      onToggle={onToggle}
      className={_className}
      buttonClassName="icon-dropdown__button"
      openButtonClassName="icon-dropdown__button--open"
      dropdownClassName="icon-dropdown__options"
      openDropdownClassName="icon-dropdown__options--open"
      dropdownOptionClassName="icon-dropdown__option"
      openDropdownOptionClassName="icon-dropdown__option--open"
    />
  );
};
