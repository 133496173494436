import React from "react";
import classNames from "classnames";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query || "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query !== this.props.query) {
      this.setState({ query: nextProps.query });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSearch(this.state.query);
  }

  handleQueryChange(e) {
    this.setState({ query: e.target.value });
  }

  render() {
    const { className, inputClassName, placeholder } = this.props;
    return (
      <form
        className={classNames({ search: true, [className]: !!className })}
        onSubmit={this.handleSubmit}
      >
        <input
          className={classNames({
            search__input: true,
            [inputClassName]: !!inputClassName,
          })}
          type="text"
          placeholder={placeholder}
          value={this.state.query}
          onChange={this.handleQueryChange}
        />
      </form>
    );
  }
}
