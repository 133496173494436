import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import WelcomeMessageForm from "./WelcomeMessageForm";

import {
  updateWelcomeMessage,
  fetchWelcomeMessage,
} from "../../actions/welcome-messages";

class EditWelcomeMessage extends React.Component {
  state = {
    name: "",
    editing: false,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = null;

    if (state.editing) {
      return null;
    }

    if (!state.name && props.welcomeMessage) {
      newState = { name: props.welcomeMessage.name, editing: true };
    }

    return newState;
  }

  componentDidMount() {
    const { welcomeMessageId } = this.props;
    if (!this.props.welcomeMessage && welcomeMessageId) {
      this.props.fetchWelcomeMessage(welcomeMessageId);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { name } = this.state;
    const { welcomeMessageId } = this.props;

    this.props
      .updateWelcomeMessage(welcomeMessageId, {
        name,
      })
      .then(() => this.props.push(`/entrypoints/wm/${welcomeMessageId}/`));
  };

  render() {
    const { name } = this.state;

    return (
      <div>
        <h1 className="page-title">Edit Welcome Message</h1>
        <WelcomeMessageForm
          name={name}
          onChange={e => this.setState({ ...this.state, name: e.target.value })}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { welcomeMessageId } = props.match.params;
    const welcomeMessage =
      state.welcomeMessages.welcomeMessagesById[welcomeMessageId];

    return { welcomeMessageId, welcomeMessage };
  },
  {
    updateWelcomeMessage,
    fetchWelcomeMessage,
    push,
  }
)(EditWelcomeMessage);
