import React from "react";
import { InfoPanel, InfoPanelRow } from "../shared";
import { formatDate } from "../../utilities/format-date";
import { status } from "../../utilities/conversation";

const HEADERS = ["Status", "Created", "Published", "Targeted", "Delivered"];

const CampaignConversationDetailsSummary = ({ conversation }) => {
  const createdDate = formatDate(conversation.createdAt);
  const conversationStatus = status(conversation);
  const publishedDate = formatDate(conversation.publishedAt) || "N/A";
  const delivered = conversation.numSent || "N/A";
  const targeted = conversation.numSubscribers || "N/A";

  const values = [
    conversationStatus,
    createdDate,
    publishedDate,
    targeted,
    delivered,
  ];

  return (
    <InfoPanel>
      <InfoPanelRow headers={HEADERS} values={values} />
    </InfoPanel>
  );
};

export default CampaignConversationDetailsSummary;
