import React from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

import {
  Card,
  ContentHeader,
  InfoPanel,
  InfoPanelRow,
  PaginatedConversationsTable,
} from "../../components/shared";
import DeleteCampaignModal from "../../components/Campaigns/DeleteCampaignModal";

import {
  deleteCampaign,
  fetchCampaign,
  fetchCampaignConversations,
  fetchCampaignLinks,
} from "../../actions/campaigns";
import {
  duplicateConversation,
  deleteConversation,
} from "../../actions/conversations";

import { formatDate } from "../../utilities/format-date";

class CampaignDetailsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,

      // conversations
      conversations: [],
      conversationPage: 1,
      conversationPages: 1,
      totalConversations: 0,

      // links
      links: [],
      linksPage: 1,
      linksPages: 1,
      totalLinks: 0,
    };
  }

  componentDidMount() {
    this.fetchCampaignConversations();
  }

  fetchCampaignLinks = (page = 1) => {
    const { campaignId, fetchCampaignLinks } = this.props;

    fetchCampaignLinks(campaignId, page).then(({ payload }) => {
      const { links, page, pages, total } = payload;
      this.setState({
        links,
        linksPage: page,
        linksPages: pages,
        totalLinks: total,
      });
    });
  };

  fetchCampaignConversations = (page = 1) => {
    const { campaignId, fetchCampaignConversations } = this.props;

    fetchCampaignConversations(campaignId, page).then(({ payload }) => {
      this.setState({
        conversations: payload.conversations,
        conversationPage: payload.page,
        conversationPages: payload.pages,
        totalConversations: payload.total,
      });
    });
  };

  componentWillMount() {
    this.props.fetchCampaign(this.props.campaignId);
  }

  showDeleteModal = () => this.setState({ showDeleteModal: true });
  hideDeleteModal = () => this.setState({ showDeleteModal: false });
  redirectToCampaigns = () => this.props.push("/campaigns");

  render() {
    const { campaign, campaignId } = this.props;

    const {
      campaign: {
        analytics: {
          completionRate = 0,
          engagementRate = 0,
          completions = 0,
          engagedSubscribers = 0,
          engagements = 0,
        } = {},
        numSubscribers,
      } = {},
    } = this.props;

    let targetedRecipients = 0;

    const conversations = this.state.conversations;

    const anyPublishedConversations =
      conversations && conversations.some(c => c.lastRanAt);

    return (
      <Loader loaded={!campaign.isLoading} loadedClassName="campaign-detail">
        <ContentHeader
          left={<h1 className="page-title">{campaign.title}</h1>}
          right={
            <div>
              <a className="paloma-button" onClick={this.showDeleteModal}>
                Delete
              </a>
              <Link
                className="paloma-button"
                to={`/campaigns/${campaign.campaignId}/edit`}
              >
                Edit details
              </Link>
            </div>
          }
        />

        <InfoPanel>
          <InfoPanelRow
            justify="flex-start"
            headers={["Created", "Last Ran", "Targeted", "Delivered"]}
            values={[
              campaign.createdAt ? formatDate(campaign.createdAt) : "",
              campaign.lastRanAt ? formatDate(campaign.lastRanAt) : "Never",
              targetedRecipients,
              numSubscribers,
            ]}
          />
        </InfoPanel>

        {anyPublishedConversations && (
          <div className="row">
            <div className="info-panel">
              <h3>
                Response Rate <span className="stat">{engagementRate}%</span>
              </h3>
              <div className="stat-container">
                <span className="stat-header">Total Responses</span>
                <span className="stat">{engagements}</span>
              </div>
              <div className="stat-container">
                <span className="stat-header">Subscribers Responded</span>
                <span className="stat">{engagedSubscribers}</span>
              </div>
            </div>
            <div className="info-panel">
              <h3>
                Completion Rate <span className="stat">{completionRate}%</span>
              </h3>
              <div className="stat-container">
                <span className="stat-header">Total Completed</span>
                <span className="stat">{completions}</span>
              </div>
            </div>
          </div>
        )}

        <Card>
          <ContentHeader
            style={{ paddingBottom: 10, paddingTop: 5 }}
            left={<h3 style={{ paddingBottom: "13px" }}>Conversations</h3>}
            right={
              <Link
                className="paloma-button"
                to={`/campaigns/${campaignId}/conversations/new`}
              >
                + New
              </Link>
            }
          />
          {conversations && conversations.length > 0 ? (
            <PaginatedConversationsTable
              page={this.state.conversationPage}
              pages={this.state.conversationPages}
              conversations={this.state.conversations}
              onChoosePage={this.fetchCampaignConversations}
            />
          ) : (
            <div className="empty-table">
              <div className="empty-table-text">
                Create a conversation to send to the subscribers of your choice!
              </div>
            </div>
          )}
        </Card>
        {this.state.showDeleteModal && (
          <DeleteCampaignModal
            campaign={campaign}
            onClose={this.hideDeleteModal}
            onDelete={this.redirectToCampaigns}
          />
        )}
      </Loader>
    );
  }

  handleDeleteConversation = conversationId => {
    this.props.deleteConversation(conversationId);
  };

  handleDuplicateConversation = async conversationId => {
    await this.props.duplicateConversation({ conversationId });
    this.props.fetchCampaign(this.props.campaignId);
  };
}

const mapStateToProps = (state, ownProps) => {
  const campaignId = ownProps.match.params.campaignId;
  const campaign = {
    isLoading: true,
    ...state.campaigns.campaignsById[campaignId],
  };

  return {
    campaign,
    campaignId,
  };
};

export default connect(
  mapStateToProps,
  {
    deleteCampaign,
    deleteConversation,
    duplicateConversation,
    fetchCampaign,
    fetchCampaignLinks,
    fetchCampaignConversations,
    push,
  }
)(CampaignDetailsContainer);
