export const FETCH_ACQUISITION_ACTIONS_REQUEST =
  "FETCH_ACQUISITION_ACTIONS_REQUEST";
export const FETCH_ACQUISITION_ACTIONS_SUCCESS =
  "FETCH_ACQUISITION_ACTIONS_SUCCESS";
export const FETCH_ACQUISITION_ACTIONS_FAILURE =
  "FETCH_ACQUISITION_ACTIONS_FAILURE";

export const CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "CREATE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_FAILURE";

export const CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_REQUEST =
  "CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_REQUEST";
export const CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS =
  "CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS";
export const CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_FAILURE =
  "CREATE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_FAILURE";

export const DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "DELETE_ACQUISITION_ACTION_MANUAL_MESSAGING_ACTIVATION_FAILURE";

export const DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_REQUEST =
  "DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_REQUEST";
export const DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS =
  "DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_SUCCESS";
export const DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_FAILURE =
  "DELETE_ACQUISITION_ACTION_THREAD_CONTROL_PASS_FAILURE";
