import React from "react";

import SelectProductModal from "../../components/SelectProductModal";

class AddInventoryButton extends React.Component {
  defaultState = { isOpen: false };

  constructor(props) {
    super(props);

    this.state = this.defaultState;
  }

  render() {
    return (
      <div>
        <div
          className="add-message-modal__message-type"
          onClick={this.openModal}
        >
          <button className="paloma-button paloma-button--pink add-message-modal__message-type-button">
            Inventory
          </button>
          <div className="add-message-modal__button-message-template" />
          <div className="add-message-modal__button-message-button-middle" />
          <div className="add-message-modal__button-message-button-middle" />
          <div className="add-message-modal__button-message-button-bottom" />
        </div>
        <SelectProductModal
          isOpen={this.state.isOpen}
          onClose={this.closeModal}
          onInsert={this.props.handleAddInventory}
        />
      </div>
    );
  }

  openModal = () => this.setState({ isOpen: true });

  closeModal = () => this.setState({ isOpen: false });
}

export default AddInventoryButton;
