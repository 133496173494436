import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import {
  putSubscriberList,
  createActiveSubscriberList,
  fetchActiveListNumTargets,
  fetchSubscriberList,
  fetchSubscriberListTargetFilters,
} from "../../actions/subscriber-lists";
import { Card, ContentHeader, SubscriberFilter } from "../../components/shared";
import get from "lodash/get";

class NewSubscriberListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriberList: {
        subscriberListId: null,
        name: "",
        description: "",
        targetFilterMatchSetting: "All",
      },
      targetFilters: [],
      numTargeted: 0,
    };

    this.handleCreateSubscriberList = this.handleCreateSubscriberList.bind(
      this
    );
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const {
      subscriberList,
      subscriberListId,
      fetchSubscriberList,
      fetchSubscriberListTargetFilters,
    } = this.props;

    if (subscriberListId && !subscriberList) {
      fetchSubscriberList(subscriberListId);
    }

    if (subscriberListId) {
      fetchSubscriberListTargetFilters(subscriberListId).then(({ payload }) => {
        this.setState({ targetFilters: payload }, () =>
          this.handleTargetFiltersChange(this.state.targetFilters)
        );
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.subscriberList) {
      const {
        name,
        description,
        targetFilterMatchSetting,
        subscriberListId,
      } = props.subscriberList;
      return {
        ...state,
        subscriberList: {
          subscriberListId,
          name,
          description,
          targetFilterMatchSetting,
        },
      };
    }
    return null;
  }

  async handleCreateSubscriberList(e) {
    e.preventDefault();
    if (this.state.targetFilters.length) {
      return this.handleCreateActiveSubscriberList(e);
    }
    const response = await this.props.putSubscriberList(
      this.state.subscriberList
    );
    this.props.push(`/audience/lists/${response.payload.subscriberListId}`);
  }

  handleCreateActiveSubscriberList = async () => {
    const { subscriberList, targetFilters } = this.state;
    const response = await this.props.createActiveSubscriberList({
      subscriberList,
      targetFilters,
    });
    this.props.push(`/audience/lists/${response.payload.subscriberListId}`);
  };

  handleInputChange(key, ev) {
    const subscriberList = this.state.subscriberList;
    subscriberList[key] = ev.target.value;
    this.setState({ subscriberList: subscriberList });
  }

  formIsValid = () =>
    this.targetFiltersAreValid() &&
    (this.state.subscriberList.name ? true : false);

  targetFiltersAreValid = (filters = null) => {
    let targetFilters = filters || this.state.targetFilters;

    if (!targetFilters.length) {
      return true;
    }
    return targetFilters.every(
      ({ conditionDropdown, fieldDropdown, value }) => {
        if (conditionDropdown && conditionDropdown.includes("exist")) {
          return conditionDropdown && fieldDropdown;
        }
        return conditionDropdown && fieldDropdown && value;
      }
    );
  };

  handleTargetFiltersChange = targetFilters => {
    const { subscriberList } = this.state;

    this.setState({ targetFilters }, () => {
      if (this.targetFiltersAreValid(targetFilters)) {
        this.props
          .fetchActiveListNumTargets({
            subscriberList,
            targetFilters,
          })
          .then(({ payload: { numTargeted } }) =>
            this.setState({ numTargeted })
          );
      } else {
        this.setState({ numTargeted: 0 });
      }
    });
  };

  render() {
    const { numTargeted } = this.state;

    return (
      <div className="new-subscriber-list">
        <Card>
          <ContentHeader
            left={<h2>Create new list</h2>}
            right={
              <div className="conversation-publisher__num-targeted">
                Targeting {numTargeted} Subscribers
              </div>
            }
          />
          <form
            className="default-form"
            onSubmit={this.handleCreateSubscriberList}
          >
            <label
              className="default-form__label"
              htmlFor="subscriber-list-name"
            >
              Name
            </label>
            <input
              id="subscriber-list-name"
              className="default-form__input"
              type="text"
              value={this.state.subscriberList.name || ""}
              onChange={this.handleInputChange.bind(this, "name")}
            />
            <label
              className="default-form__label"
              htmlFor="subscriber-list-description"
            >
              Description
            </label>
            <input
              id="subscriber-list-description"
              type="text"
              className="default-form__input"
              value={this.state.subscriberList.description || ""}
              onChange={this.handleInputChange.bind(this, "description")}
            />
            <div className="card__section">
              <h4 className="card__section-title">
                Define a segment to auto-populate your list
              </h4>
              <p style={{ fontStyle: "italic" }}>
                By defining a segment, existing subscribers and new subscribers
                that match the definition will automatically get added to the
                list.
              </p>
              <br></br>
              <SubscriberFilter
                targetFilters={this.state.targetFilters}
                onChange={this.handleTargetFiltersChange}
                matchSetting={
                  this.state.subscriberList.targetFilterMatchSetting
                }
                setMatchSetting={targetFilterMatchSetting => {
                  const subscriberList = {
                    ...this.state.subscriberList,
                    targetFilterMatchSetting,
                  };
                  this.setState({ subscriberList });
                }}
                addButtonDisabled={!this.targetFiltersAreValid()}
              />
            </div>
            <button
              className="default-form__submit"
              type="submit"
              disabled={!this.formIsValid()}
            >
              {this.state.subscriberList.subscriberListId ? "Update" : "Create"}
            </button>
          </form>
        </Card>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const subscriberListId = get(props, "match.params.subscriberListId", null);

    const subscriberList =
      subscriberListId &&
      get(state, `subscriberLists.byId.${subscriberListId}`, null);

    return { subscriberListId, subscriberList };
  },
  {
    putSubscriberList,
    push,
    createActiveSubscriberList,
    fetchActiveListNumTargets,
    fetchSubscriberList,
    fetchSubscriberListTargetFilters,
  }
)(NewSubscriberListContainer);
