import React from "react";
import infoIcon from "../images/info.svg";
import { connect } from "react-redux";
import { Card, ContentHeader, Modal } from "../components/shared";
import {
  fetchPersistentMenu,
  publishPersistentMenu,
} from "../actions/persistent-menu";
import { showErrorAlert, showSuccessAlert } from "../actions/alerts";
import { push } from "react-router-redux";

class PersistentMenuPublisher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { publishModal: false };
  }

  componentWillMount() {
    this.props.fetchPersistentMenu(this.props.match.params.id);
  }

  publish = async () => {
    const response = await this.props.publishPersistentMenu(
      this.props.match.params.id
    );
    if (response.type === "PUBLISH_PERSISTENT_MENU_SUCCESS") {
      this.props.showSuccessAlert("Your menu has been published!");
      this.props.push("/global");
    } else {
      this.props.showErrorAlert(response.payload.response.error, {
        autoDismiss: 0,
      });
    }
  };

  render = () => {
    return (
      <div className="conversation-publisher">
        <ContentHeader left={<h1>Global Menu</h1>} />
        <Card>
          <Modal
            title="Publish Menu?"
            isOpen={this.state.publishModal}
            onClose={() => this.setState({ publishModal: false })}
          >
            <div className="modal__controls">
              <button
                className="paloma-button modal__button modal__button--red"
                onClick={() => this.setState({ publishModal: false })}
              >
                No
              </button>
              <button
                className="paloma-button modal__button modal__button--red"
                onClick={this.publish}
              >
                Yes
              </button>
            </div>
          </Modal>
          <div className="conversation-publisher">
            <h3>Recipients</h3>
            <p>
              <strong>All subscribers will see this global menu.</strong>
            </p>
            <button
              className="conversation-publisher__publish-button paloma-button--pink"
              onClick={() => this.setState({ publishModal: true })}
            >
              Publish
            </button>
            <div className="conversation-publisher__notice-section">
              <img
                src={infoIcon}
                className="conversation-publisher__notice-section-icon"
                alt=""
              />
              <em>Publishing this menu will replace the existing menu.</em>
            </div>
          </div>
        </Card>
      </div>
    );
  };
}

const mapStateToProps = state => state;

export default connect(
  mapStateToProps,
  {
    fetchPersistentMenu,
    publishPersistentMenu,
    push,
    showErrorAlert,
    showSuccessAlert,
  }
)(PersistentMenuPublisher);
