import _ from "underscore";
import classNames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TemplateCardButton from "./TemplateCardButton";
import TemplateCardTitle from "./TemplateCardTitle";

import { ImageUploadModal, Error, Toggle } from "../shared";
import { SeparatedButtonRow } from "../../components/shared";
import { openMenu } from "../../actions/builder-menu";
import {
  createTemplateCardButton,
  deleteTemplateCard,
  fetchVisibleConversationSteps,
  toggleRandomizeTemplateCards,
  moveTemplateCard,
  replaceTemplateCardProduct,
} from "../../actions/conversations";
import { addQuickReply } from "../../actions/quick-replies";
import { appendProductCard } from "../../actions/inventory";
import { TEMPLATE_CARD } from "../../constants/builder-menu";
import { combineObjects } from "../../utilities/general";

import {
  putTemplateCard,
  uploadTemplateCardImage,
} from "../../actions/conversations";
import SelectProductModal from "../../components/SelectProductModal";

class TemplateCard extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    templateCard: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayUploadModal: false,
      subtitle: props.templateCard.subtitle,
      title: props.templateCard.title,
      productModalOpen: false,
      replaceCard: false,
    };

    this.putTemplateCard = _.debounce(this.props.putTemplateCard, 500);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { subtitle, title } = this.props.templateCard
    if (subtitle && subtitle !== prevProps.templateCard?.subtitle) {
      this.setState({ subtitle })
    }
    if (title && title !== prevProps.templateCard?.title) {
      this.setState({ title })
    }
  }

  render() {
    const {
      editable = true,
      errors,
      isMenuOpen,
      randomizeButtons,
      templateCard: {
        buttons,
        conversationId,
        conversationStepId,
        imageUrl,
        subtitle,
        title,
      },
    } = this.props;

    const { displayUploadModal } = this.state;

    const wrapperClasses = classNames("conversation-step__template-card", {
      error: errors.templateCardTitle || this.buttonErrors().length,
    });

    const templateCardClasses = classNames({
      "conversation-step__template-card__shadowzone": editable,
    });

    return (
      <div>
        <div className={wrapperClasses}>
          <div className={templateCardClasses}>
            <div
              onClick={this.showUploadModal}
              className="conversation-step__template-card__dropzone"
            >
              {imageUrl ? (
                <div
                  alt=""
                  className="conversation-step__template-card__image"
                  width="100%"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                    display: "block",
                    height: "200px",
                    position: "relative",
                  }}
                />
              ) : (
                <div className="conversation-step__template-card__upload-image-prompt">
                  Upload a .jpg, .gif, or .png.
                </div>
              )}
            </div>
            <TemplateCardTitle
              className="conversation-step__template-card__title"
              editable={editable}
              field="title"
              handleFieldChange={this.handleFieldChange}
              onClick={this.openMenu}
              placeholder="Write a title"
              placeholderClassName="conversation-step__template-card__title-placeholder"
              title={title || ""}
            />
            <TemplateCardTitle
              className="conversation-step__template-card__subtitle"
              editable={editable}
              field="subtitle"
              handleFieldChange={this.handleFieldChange}
              onClick={this.openMenu}
              placeholder="Write a subtitle"
              placeholderClassName="conversation-step__template-card__subtitle-placeholder"
              title={subtitle || ""}
            />
            <div className="conversation-step__buttons--no-margin">
              {buttons.map((button, i) => (
                <TemplateCardButton
                  button={button}
                  editable={editable}
                  conversationId={conversationId}
                  conversationStepId={conversationStepId}
                  key={button.conversationStepTemplateCardButtonId}
                  templateCard={this.props.templateCard}
                  randomized={randomizeButtons}
                />
              ))}
            </div>
            {displayUploadModal && (
              <ImageUploadModal
                onClose={this.hideUploadModal}
                onUpload={this.uploadImage}
              />
            )}
          </div>
          {editable && isMenuOpen && (
            <SeparatedButtonRow buttons={this.buttons()} />
          )}
        </div>
        <Error error={errors.templateCardTitle} />
        <Error error={errors.templateCardButtons} />
        {this.buttonErrors().map((error) => (
          <Error error={error[1]} key={error[0]} />
        ))}
        <SelectProductModal
          isOpen={this.state.productModalOpen}
          onClose={() => this.setState({ productModalOpen: false })}
          singleSelect={this.state.replaceCard}
          onInsert={(productIds) => {
            var promise
            if(this.state.replaceCard) {
              promise = this.props.replaceTemplateCardProduct(this.state.replaceCard, productIds)
            } else {
              promise = this.props.appendProductCard(productIds, conversationStepId)
            }
            promise.then(() => {
                if(this.props.refreshSteps) {
                  this.props.refreshSteps()
                } else {
                  this.props.fetchVisibleConversationSteps(
                    this.props.templateCard.conversationId
                  )
                }
              })
          }}
        />
      </div>
    );
  }

  buttonErrors = () => _.pairs(combineObjects(this.props.errors.buttons));

  buttons = () => {
    const { conversationStepTemplateCardId } = this.props.templateCard;
    console.log(conversationStepTemplateCardId);

    const maxCardLimitReached = this.props.conversationStep.templateCards.length >= 10

    let buttons = []

    buttons.push({
      text: "+ Button",
      onClick: () => {
        this.props.createTemplateCardButton(this.props.templateCard);
      },
    });

    if (this.props.conversationStep.templateCards.length < 10) {
      buttons.push({
        text: "+ Card",
        onClick: () => {
          this.props.putTemplateCard({
            conversationStepId: this.props.templateCard.conversationStepId,
          });
        },
      });
      buttons.push({
        text: "+ Inventory Card",
        onClick: () => this.setState({ productModalOpen: true, replaceCard: false }),
      });
    }

    buttons.push({
      text: "+ Quick Reply",
      onClick: () =>
        this.props.addQuickReply(this.props.templateCard.conversationStepId),
    });
    buttons.push({
      text: (
        <div>
          Randomize
          <Toggle
            onChange={() => {
              this.props.toggleRandomizeTemplateCards(
                this.props.templateCard.conversationStepId,
                !this.props.randomizeTemplateCards
              );
              this.props.fetchVisibleConversationSteps(
                this.props.templateCard.conversationId
              );
            }}
            checked={this.props.randomizeTemplateCards}
          />
        </div>
      ),
      leaveOpen: true,
      onClick: () => true,
    });

    if (this.props.conversationStep.templateCards.length > 1) {
      buttons.push({
        text: "Delete",
        onClick: async () => {
          await this.setState({ deleted: true });
          this.props.deleteTemplateCard({
            conversationStepTemplateCardId: this.props.templateCard
              .conversationStepTemplateCardId,
          });
        },
      });
    }

    buttons.unshift({
      text: "Replace",
      onClick: () => this.setState({ productModalOpen: true, replaceCard: conversationStepTemplateCardId }),
    });

    if (this.props.conversationStep.templateCards.length > 1) {
      if (
        this.props.index <
        this.props.conversationStep.templateCards.length - 1
      ) {
        buttons.unshift({
          text: "Move right",
          onClick: () =>
            this.props
              .moveTemplateCard(conversationStepTemplateCardId, {
                move: "right",
              })
              .then(() => {
                if(this.props.refreshSteps) {
                  this.props.refreshSteps()
                } else {
                  this.props.fetchVisibleConversationSteps(
                    this.props.templateCard.conversationId
                  )
                }
              }),
        });
      }

      if (this.props.index > 0) {
        buttons.unshift({
          text: "Move left",
          onClick: () =>
            this.props
              .moveTemplateCard(conversationStepTemplateCardId, {
                move: "left",
              })
              .then(() => {
                if(this.props.refreshSteps) {
                  this.props.refreshSteps()
                } else {
                  this.props.fetchVisibleConversationSteps(
                    this.props.templateCard.conversationId
                  )
                }
              }),
        });
      }
    }

    return buttons;
  };

  openMenu = () => {
    this.props.openMenu({
      id: this.props.templateCard.conversationStepTemplateCardId,
      type: TEMPLATE_CARD,
    });
  };

  showUploadModal = () => this.setState({ displayUploadModal: true });
  hideUploadModal = () => this.setState({ displayUploadModal: false });

  handleFieldChange = async (field, value) => {
    await this.setState({ [field]: value });
    if (!this.state.deleted) {
      this.putTemplateCard({
        ...this.props.templateCard,
        title: this.state.title,
        subtitle: this.state.subtitle,
      });
    }
  };

  uploadImage = (file) => {
    const {
      templateCard: { conversationStepTemplateCardId },
      uploadTemplateCardImage,
    } = this.props;

    const data = new FormData();
    data.append("file", file);

    return uploadTemplateCardImage({
      id: conversationStepTemplateCardId,
      data,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    builderMenu: { id: menuId, type: menuType },
    conversations: { conversationStepsById },
  } = state;

  const conversationStepId = ownProps.templateCard.conversationStepId;
  const conversationStep = conversationStepsById[conversationStepId];

  const isMenuOpen =
    menuId === ownProps.templateCard.conversationStepTemplateCardId &&
    menuType === TEMPLATE_CARD;

  return { conversationStep, isMenuOpen };
};

export default connect(mapStateToProps, {
  addQuickReply,
  createTemplateCardButton,
  deleteTemplateCard,
  fetchVisibleConversationSteps,
  openMenu,
  putTemplateCard,
  toggleRandomizeTemplateCards,
  uploadTemplateCardImage,
  appendProductCard,
  replaceTemplateCardProduct,
  moveTemplateCard,
})(TemplateCard);
