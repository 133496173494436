import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import { createDynamicStep, pasteDynamicStep } from "../actions/dynamic-steps";
import { showSuccessAlert } from "../actions/alerts";
import {
  clearVisibleConversationSteps,
  fetchConversation,
  fetchConversationStep,
  fetchVisibleConversationStepsFromStep,
  fetchVisibleConversationSteps,
} from "../actions/conversations";
import { pasteStep } from "../actions/paste-step";
import { ContentHeader, Notice, Error } from "../components/shared";
import { getConversationLink } from "../utilities/conversation";
import Scenario from "../components/Scenario";
import { fetchProducts } from "../actions/inventory";
import { AddMessageModal } from "../components/ConversationDetail";
import ConversationStep from "../containers/ConversationDetail/ConversationStep";
import { ADD_MESSAGE } from "../constants/builder-menu";
import { openMenu, closeMenu } from "../actions/builder-menu";
import { insertAboveStep } from "../actions/insert-above";
import _ from "underscore";
import isEmpty from "lodash/isEmpty";
import { ShowDisplayErrorContext } from "../utilities/contexts"

class DynamicMessageBuilder extends Component {
  state = {
    idToCopy: null,
    rootStep: null,
    selectedScenario: null,
    stepIdToInsertAbove: null,
    loading: true,
  };

  componentDidMount() {
    const { conversationId, id } = this.props;
    this.props.clearVisibleConversationSteps(conversationId);
    this.props
      .fetchConversationStep(id)
      .then(() => this.setState({ loading: false }));
    this.props.fetchConversation(conversationId);
    this.props.fetchProducts();
  }

  renderAddScenario = () => {
    const {
      conversationStep,
      createDynamicStep,
      fetchConversationStep,
      pasteDynamicStep,
    } = this.props;
    return (
      <div className="controls">
        <div className="section add-message">
          <button
            className="button"
            onClick={async () => {
              const resp = await createDynamicStep(
                conversationStep.conversationStepId
              );
              this.selectScenario(
                resp.payload.id,
                resp.payload.childConversationStepId
              );
              fetchConversationStep(conversationStep.conversationStepId);
            }}
          >
            New scenario
          </button>
          {this.state.idToCopy && (
            <button
              className="button paste-dynamic-step-button"
              onClick={async () => {
                const resp = await pasteDynamicStep(this.state.idToCopy);
                this.selectScenario(
                  resp.payload.id,
                  resp.payload.childConversationStepId
                );
                this.setState({
                  selectedScenario: resp.payload.id,
                  idToCopy: null,
                });
                fetchConversationStep(conversationStep.conversationStepId);
              }}
            >
              Paste scenario
            </button>
          )}
        </div>
      </div>
    );
  };

  copyStep = id => {
    this.setState({ stepIdToPaste: id });
    this.props.showSuccessAlert("Copied!");
  };

  handlePasteMessage = async () => {
    this.props.closeMenu();
    const {
      triggerChoicesByConversationStepId,
      visibleConversationStepIds,
    } = this.props;

    if (this.state.stepIdToInsertAbove) {
      await this.props.insertAboveStep({
        conversationId: this.props.conversation.conversationId,
        stepIdToPaste: this.state.stepIdToPaste,
        stepIdToInsertAbove: this.state.stepIdToInsertAbove,
      });
      this.setState({ stepIdToInsertAbove: null });
    } else {
      const previousStepId = _.last(visibleConversationStepIds);
      const selectedTriggerId =
        triggerChoicesByConversationStepId[previousStepId];
      await this.props.pasteStep(
        this.state.stepIdToPaste,
        previousStepId,
        selectedTriggerId
      );
    }

    this.refreshSteps();
  };

  refreshSteps = () => {
    if (this.state.rootStep) {
      this.props.fetchVisibleConversationStepsFromStep(
        this.state.rootStep,
        this.props.conversationId
      );
    } else {
      this.props.fetchVisibleConversationSteps(this.props.conversationId);
    }
  };

  generateCriteraSearchTree(scenario, keys, tree) {
    if ((keys === undefined || keys.length == 0) && Array.isArray(tree)) {
      // Base case: No remaining keys in this scenario and no remaining keys in tree (is an Array leaf node)
      return [...tree, scenario.id]
    }
    
    // Bug Fix Note: if tree is an Array, we want to carry the values down as if Anything ("") was selected for current_key. (For now, we set tree_key to a really big number to ensure the "else" clause on line 174 runs. It won't be possible for current_key to be undefined AND tree be an array given the logic on line 141.)
    const tree_key = Array.isArray(tree) ? Number.MAX_SAFE_INTEGER : Object.keys(tree)[0]
    var [current_key, ...remaining_keys] = keys
    var values
    var current_values

    // In all cases except when current_key is undefined, values is set from scenario.criteria
    if(current_key !== undefined) {
      values = scenario.criteria[current_key]
      if (typeof values === 'string') {
        // If scenario criter uses legacy string format, convert to newer array format
        values = [values]
      }
    }

    if(tree_key === undefined) {
      // This is the first scenario to run, we initialize current_values with {}
      current_values = {}
    } else if (tree_key === current_key) {
      // Tree already exists for current_key and we have selections for current_key
      current_values = tree[current_key]
    } else if(tree_key < current_key || current_key === undefined) {
      // The tree_key is missing from this scenario
      // We treat it as if "Anything" was selected for tree_key, since that is functionally what missing critera does
      remaining_keys = keys
      current_key = tree_key
      values = [""]
      current_values = tree[tree_key]
    } else {
      // The current_key is a new key missing from all previous scenarios
      // We treat all previous scenarios as if "Anything" was selected for current_key, since that is functionally what missing critera does
      current_values = { "" : tree }
    }

    // Recursively and additively build tree
    const new_value = values.reduce((acc, val) => ({...acc, [val]: this.generateCriteraSearchTree(scenario, remaining_keys, (val in acc) ? acc[val] : []) }), current_values)
    return { [current_key] : new_value }
  }

  findDuplicateScenarios(tree) {
    return Object.keys(tree).reduce((acc, key) => {
      const val = tree[key]
      if(Array.isArray(val)) {
        if(val.length > 1) {
          return [...acc, val]
        } else {
          return acc
        }
      } else {
        return [...acc, ...this.findDuplicateScenarios(val)]
      }
    }, [])
  }

  render() {
    const {
      conversation,
      conversationStep,
      createDynamicStepCriteria,
      deleteDynamicStep,
      deleteDynamicStepCriteria,
      visibleConversationStepIds,
      openMenu,
      closeMenu,
      addMenuOpen,
    } = this.props;

    if (!conversation || !conversationStep) {
      return <div />;
    }

    var scenarioError = null
    const defaultFound = conversationStep.dynamicSteps.some(scenario => conversationStep.defaultChildStepId == scenario.childConversationStepId)
    const criteriaSearchTree = conversationStep.dynamicSteps.reduce((masterTree, scenario) => {
      const keys = Object.keys(scenario.criteria).sort()
      if (keys === undefined || keys.length == 0) {
        //Scenario has empty criteria {} -- skip it
        return masterTree
      } else {
        return this.generateCriteraSearchTree(scenario, keys, masterTree)
      }
    }, {})
    const duplicateCriteria = this.findDuplicateScenarios(criteriaSearchTree)
    if (duplicateCriteria.length) {
      var scenarioNames = duplicateCriteria[0].map(scenario_id => `Scenario ${conversationStep.dynamicSteps.findIndex(scenario => scenario.id === scenario_id)+1}`)
      const lastScenarioName = scenarioNames.pop()
      scenarioError = `${scenarioNames.join(", ")} and ${lastScenarioName} have overlapping logic.`
    } else if (!defaultFound) {
      scenarioError = "This dynamic step does not have a Default scenario, which means in some cases a customer might not get a message. Marking a scenario as the Default will ensure that every customer will get a match."
    }

    return (
      <div>
        <ContentHeader
          left={<h1 className="page-title">{conversation.label}</h1>}
          right=""
        />
        {conversation.type === "campaign" && (
          <Notice
            headline="Heads up!"
            type="white"
            style={{ margin: "12px 0px" }}
          >
            Sending campaigns is an opportunity to re-engage your audience, so a
            response will be required on your first message to give your
            subscribers a chance to continue the conversation. Learn more about
            Facebook’s 24hr + 1 policy{" "}
            <a
              href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/#standard_messaging"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Notice>
        )}

        <div className="dynamic-message-builder">
          <div className="card dynamic-message-header">
            Dynamic Step Setup{" "}
            <div style={{ float: "right" }}>
              <Link
                to={`${getConversationLink(conversation)}/build/`}
                className="paloma-button"
              >
                Back to Builder
              </Link>
            </div>
          </div>
          <Loader loaded={!this.state.loading}>
            <div className="builder-panels-wrapper">
              <div className="builder-panels">
                <div className="conversation-builder">
                  <div
                    className="conversation-builder__main"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <h2>Flow</h2>
                    <div
                      style={{
                        overflow: "scroll",
                        height: "100%",
                        paddingBottom: "30px",
                      }}
                    >
                      <ShowDisplayErrorContext.Provider value={this.props.areShown}>
                        <div className="conversation-steps">
                          {(visibleConversationStepIds || []).map((stepId, i) => (
                            <ConversationStep
                              conversationId={conversation.conversationId}
                              conversationStepId={stepId}
                              copyStep={this.copyStep}
                              editable={true}
                              insertAbove={this.insertAbove}
                              key={stepId}
                              refreshSteps={this.refreshSteps}
                            />
                          ))}
                        </div>
                      </ShowDisplayErrorContext.Provider>
                    </div>

                    {addMenuOpen ? (
                      <div>
                        <AddMessageModal
                          conversationId={conversationStep.conversationId}
                          showAddInventory={!isEmpty(this.props.productsById)}
                          fetchVisibleConversationSteps={this.refreshSteps}
                          pasteStep={this.handlePasteMessage}
                          onClose={closeMenu}
                          stepIdToInsertAbove={this.state.stepIdToInsertAbove}
                          stepIdToPaste={this.state.stepIdToPaste}
                        />
                      </div>
                    ) : (
                      <div className="controls">
                        <div className="section add-message">
                          <button
                            className="button"
                            onClick={openMenu.bind(this, {
                              type: ADD_MESSAGE,
                            })}
                          >
                            Add Message
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="scenarios scenario-builder">
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <h2>Scenarios</h2>
                    {scenarioError &&
                      <div style={{ margin: 15 }}>
                        <Error error={scenarioError} key={0} />
                      </div>
                    }
                    <div
                      style={{
                        overflow: "scroll",
                        height: "90%",
                        maxHeight: scenarioError == null ? "86.8%" : "81.6%",
                        paddingBottom: "50px",
                      }}
                    >
                      {conversationStep.dynamicSteps.map((ds, i) => (
                        <Scenario
                          isDefault={conversationStep.defaultChildStepId == ds.childConversationStepId}
                          editing={this.state.selectedScenario === ds.id}
                          createDynamicStepCriteria={createDynamicStepCriteria}
                          deleteDynamicStep={deleteDynamicStep}
                          deleteDynamicStepCriteria={deleteDynamicStepCriteria}
                          i={i}
                          key={ds.id}
                          step={conversationStep}
                          scenario={ds}
                          selectScenario={this.selectScenario}
                          copy={id => this.setState({ idToCopy: id })}
                        />
                      ))}
                    </div>

                    {this.renderAddScenario()}
                  </div>
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </div>
    );
  }

  selectScenario = async (id, childStepId = null) => {
    await this.setState({
      selectedScenario: id,
      rootStep:
        childStepId ||
        this.props.conversationStep.dynamicSteps.find(ds => ds.id === id)
          .childConversationStepId,
    });
    this.props.fetchVisibleConversationStepsFromStep(
      this.state.rootStep,
      this.props.conversationId
    );
  };

  insertAbove = id => {
    this.setState({ stepIdToInsertAbove: id }, () =>
      this.props.openMenu({ type: ADD_MESSAGE })
    );
  };
}

DynamicMessageBuilder.defaultProps = { conversationStep: { dynamicSteps: [] } };

export default connect(
  (state, props) => {
    const {
      match: {
        params: { conversationId, id },
      },
    } = props;

    const { triggerChoicesByConversationStepId } = state.conversations;

    const visibleStepIds = state.conversations
      .visibleConversationStepsByConversationId[conversationId] || {
      stepIds: [],
    };

    const {
      builderMenu: { type: menuType },
      builderErrors: { areShown },
      inventory: { productsById },
    } = state;

    const addMenuOpen = menuType === ADD_MESSAGE;

    return {
      areShown,
      addMenuOpen,
      conversation: state.conversations.conversationsById[conversationId],
      conversationId,
      conversationStep: state.conversations.conversationStepsById[id],
      id,
      visibleConversationStepIds: visibleStepIds.stepIds,
      triggerChoicesByConversationStepId,
      productsById,
    };
  },
  {
    clearVisibleConversationSteps,
    createDynamicStep,
    fetchConversation,
    fetchConversationStep,
    fetchVisibleConversationStepsFromStep,
    pasteDynamicStep,
    openMenu,
    closeMenu,
    showSuccessAlert,
    pasteStep,
    insertAboveStep,
    fetchVisibleConversationSteps,
    fetchProducts,
  }
)(DynamicMessageBuilder);
