import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/organization-fields";

export const putOrganizationField = payload => ({
  [CALL_API]: {
    endpoint: "/api/organization_fields/",
    method: "PUT",
    body: JSON.stringify({ organization_field: payload }),
    types: [
      {
        type: constants.PUT_ORGANIZATION_FIELD_REQUEST,
        meta: payload.organizationFieldId
          ? { organizationFieldId: payload.organizationFieldId }
          : {},
      },
      constants.PUT_ORGANIZATION_FIELD_SUCCESS,
      {
        type: constants.PUT_ORGANIZATION_FIELD_FAILURE,
        meta: payload.organizationFieldId
          ? { organizationFieldId: payload.organizationFieldId }
          : {},
      },
    ],
  },
});

export const fetchOrganizationFields = () => ({
  [CALL_API]: {
    endpoint: "/api/organization_fields/",
    method: "GET",
    types: [
      constants.FETCH_ORGANIZATION_FIELDS_REQUEST,
      constants.FETCH_ORGANIZATION_FIELDS_SUCCESS,
      constants.FETCH_ORGANIZATION_FIELDS_FAILURE,
    ],
  },
});

export const putOrganizationFieldsRequired = organizationFieldIds => ({
  [CALL_API]: {
    endpoint: "/api/organization_fields/required",
    method: "PUT",
    body: { organizationFieldIds },
    types: [
      constants.PUT_ORGANIZATION_FIELDS_REQUIRED_REQUEST,
      constants.PUT_ORGANIZATION_FIELDS_REQUIRED_SUCCESS,
      constants.PUT_ORGANIZATION_FIELDS_REQUIRED_FAILURE,
    ],
  },
});

export const updateTextMatchValidation = (id, remove = true) => ({
  [CALL_API]: {
    endpoint: `/api/organization_fields/${id}/text_match`,
    method: remove ? "DELETE" : "POST",
    types: [
      constants.UPDATE_TEXT_MATCH_VALIDATION_REQUEST,
      {
        type: constants.UPDATE_TEXT_MATCH_VALIDATION_SUCCESS,
        meta: { id },
      },
      constants.UPDATE_TEXT_MATCH_VALIDATION_FAILURE,
    ],
  },
});

export const fetchFieldSubscribers = (id, page = 1) => ({
  [CALL_API]: {
    endpoint: `/api/organization_fields/${id}/subscribers?page=${page}`,
    method: "GET",
    types: [
      "FETCH_FIELD_SUBSCRIBERS_REQUEST",
      "FETCH_FIELD_SUBSCRIBERS_SUCCESS",
      "FETCH_FIELD_SUBSCRIBERS_FAILRURE",
    ],
  },
});

export const deleteOrganizationField = id => ({
  [CALL_API]: {
    endpoint: `/api/organization_fields/${id}/`,
    method: "DELETE",
    types: [
      constants.DELETE_ORGANIZATION_FIELD_REQUEST,
      {
        type: constants.DELETE_ORGANIZATION_FIELD_SUCCESS,
        meta: { id },
      },
      constants.DELETE_ORGANIZATION_FIELD_FAILURE,
    ],
  },
});

export const postMatchingTexts = ({ fieldId, matchingTexts }) => ({
  [CALL_API]: {
    endpoint: `/api/organization_fields/${fieldId}/matching_texts`,
    method: "POST",
    body: { matchingTexts },
    types: [
      constants.POST_MATCHING_TEXT_REQUEST,
      constants.POST_MATCHING_TEXT_SUCCESS,
      constants.POST_MATCHING_TEXT_FAILURE,
    ],
  },
});
