import { CALL_API } from "redux-api-middleware";
import { FETCH_CURRENT_ORGANIZATION_SUCCESS } from "../constants/current-organization";

export const updateAdSourceSignup = (globalTriggerId, isDelete) => {
  console.log(globalTriggerId, isDelete);
  return {
    [CALL_API]: {
      endpoint: "/api/ad_source_signup",
      method: !isDelete ? "POST" : "DELETE",
      body: { globalTriggerId },
      types: [
        "UPDATE_AD_SOURCE_SIGNUP_REQUEST",
        FETCH_CURRENT_ORGANIZATION_SUCCESS,
        "UPDATE_AD_SOURCE_SIGNUP_FAILURE",
      ],
    },
  };
};
