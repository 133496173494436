import moment from "moment";
import "moment-timezone";

export const everyHalfHour = date => {
  const times = [];
  let m = moment(date).startOf("day");
  let i = 0;
  while (i < 48) {
    times.push(moment(m));

    i = i + 1;
    m.add(30, "minutes");
  }
  return times;
};

export const nycToUtc = userTime =>
  moment.tz(userTime, "America/New_York").tz("Etc/UTC");

export const utcToNyc = utcTime => moment.utc(utcTime).tz("America/New_York");
