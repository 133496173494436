import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Card } from "../components/shared";
import TestSendConversation from "./TestSendConversation";
import { ContentHeader } from "../components/shared";
import {
  fetchConversation,
  publishConversation,
} from "../actions/conversations";
import ConversationScheduler from "./ConversationScheduler";
import ConfirmationModal from "../components/ConfirmationModal";

import infoIcon from "../images/info.svg";

class GlobalDefaultConversationPublisher extends Component {
  state = { publishModal: false };

  componentWillMount() {
    const { conversationId, fetchConversation } = this.props;

    fetchConversation(conversationId);
  }

  render() {
    const { conversation } = this.props;

    return (
      <Loader
        loaded={!conversation.isLoading}
        loadedClassName="conversation-detail"
      >
        <ContentHeader left={<h1>{conversation.label}</h1>} />
        <Card className="info-panel">
          <div className="conversation-publisher">
            <ConversationScheduler
              conversationId={conversation.conversationId}
            />
            <ConfirmationModal
              onConfirm={this.publishConversation}
              schedule={conversation.sendAt}
            />

            <div className="conversation-publisher__notice-section">
              <img
                src={infoIcon}
                className="conversation-publisher__notice-section-icon"
                alt=""
              />
              Publishing this conversation will deactivate the existing global
              default conversation.
            </div>
          </div>
        </Card>
        <Card className="info-panel">
          <TestSendConversation id={conversation.conversationId} />
        </Card>
      </Loader>
    );
  }

  publishConversation = () => {
    const { conversationId, publishConversation, push } = this.props;

    publishConversation({ conversationId });

    setTimeout(
      () => push(`/global/unexpected/conversations/${conversationId}/details`),
      500
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { conversationId, triggerId },
    },
  } = ownProps;

  const { conversationsById } = state.conversations;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  return {
    conversationId,
    conversation,
    triggerId,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchConversation,
    publishConversation,
    push,
  }
)(GlobalDefaultConversationPublisher);
