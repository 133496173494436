import createReducer from "../utilities/create-reducer";
import * as constants from "../constants/builder-menu";

const initialState = {
  type: null,
  id: null,
};

export default createReducer(initialState, {
  [constants.OPEN_MENU](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [constants.CLOSE_MENU](state, action) {
    return initialState;
  },
});
