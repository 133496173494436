import React from "react";
import PaginatedList from "./PaginatedList";
import { formatDate, withTime } from "../utilities/format-date";
import _ from "underscore";
import moment from "moment";

const publishedAt = c =>
  (c.publishedAt && moment.utc(c.publishedAt).format("X")) || -Infinity;

const sorted = menus => _.sortBy(menus, publishedAt).reverse();

const Menu = menu => {
  return (
    <tr className="table__row" key={menu.id}>
      <td className="table__data">
        <a className="table__link" href={"/global/persistent_menu/build"}>
          {menu.title}
        </a>
      </td>
      <td className="table__data">{formatDate(menu.createdAt)}</td>
      <td className="table__data">{withTime(menu.publishedAt) || "Never"}</td>
      <td className="table__data">
        <a className="table__link" href={"/global/persistent_menu/build"}>
          View
        </a>
      </td>
    </tr>
  );
};

const PersistentMenusTable = ({ menus = [], create }) => {
  if (menus.length > 0) {
    return (
      <PaginatedList
        headers={["Menu", "Created At", "Published At"]}
        items={sorted(menus)}
        component={Menu}
        perPage={5}
      />
    );
  } else {
    return (
      <div
        style={{
          background: "#EFF1F5",
          clear: "both",
          padding: "50px",
          textAlign: "center",
        }}
      >
        <div>Setup your global menu for your subscribers to navigate</div>
        <a
          style={{ marginTop: "30px" }}
          className="paloma-button paloma-button--pink"
          onClick={create}
        >
          Build a Menu
        </a>
      </div>
    );
  }
};

export default PersistentMenusTable;
