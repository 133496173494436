import { normalize } from "normalizr";

import { subscriberSchema, subscriberTagSchema } from "../schemas";

import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/subscriber-tags";
import * as subscriberConstants from "../constants/subscribers";

const initialState = {
  byId: {},
  isLoading: false,
};

export default createReducer(initialState, {
  [subscriberConstants.FETCH_SUBSCRIBER_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, subscriberSchema);
    const normalizedSubscriberTags =
      normalizedData.entities.subscriberTags || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberTags,
      },
    };
  },

  [subscriberConstants.FETCH_SUBSCRIBERS_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, {
      subscribers: [subscriberSchema],
    });
    const normalizedSubscriberTags =
      normalizedData.entities.subscriberTags || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberTags,
      },
    };
  },

  [constants.FETCH_SUBSCRIBER_TAGS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_SUBSCRIBER_TAGS_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload.subscriberTags, [
      subscriberTagSchema,
    ]);
    const normalizedSubscriberTags =
      normalizedData.entities.subscriberTags || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberTags,
      },
      isLoading: false,
    };
  },

  [constants.FETCH_SUBSCRIBER_TAGS_FAILURE](state, action) {
    return {
      ...state,
      isLoading: false,
    };
  },

  [constants.CREATE_SUBSCRIBER_TAG_REQUEST](state, action) {
    return state;
  },

  [constants.CREATE_SUBSCRIBER_TAG_SUCCESS](state, action) {
    const normalizedData = normalize(
      action.payload.subscriberTag,
      subscriberTagSchema
    );
    const normalizedSubscriberTags =
      normalizedData.entities.subscriberTags || {};

    return {
      ...state,
      byId: {
        ...state.byId,
        ...normalizedSubscriberTags,
      },
    };
  },

  [constants.CREATE_SUBSCRIBER_TAG_FAILURE](state, action) {
    return state;
  },

  [constants.DELETE_SUBSCRIBER_TAG_REQUEST](state, action) {
    return state;
  },

  [constants.DELETE_SUBSCRIBER_TAG_SUCCESS](state, action) {
    const subscriberTagsById = state.byId;
    delete subscriberTagsById[action.meta.subscriberTagId];

    return {
      ...state,
      byId: subscriberTagsById,
    };
  },

  [constants.DELETE_SUBSCRIBER_TAG_FAILURE](state, action) {
    return state;
  },
});
