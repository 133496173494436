import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import DeleteCampaignModal from "./DeleteCampaignModal";

import { fetchCampaigns, duplicateCampaign } from "../../actions/campaigns";

class CampaignsTableRowButtons extends Component {
  state = {
    displayDeleteModal: false,
  };

  componentDidMount() {
    this.isStillMounted = true;
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  duplicateCampaign = async () => {
    const { campaign, duplicateCampaign } = this.props;

    await duplicateCampaign({ campaignId: campaign.campaignId });

    this.refresh();
  };

  render() {
    const { campaign } = this.props;

    const { displayDeleteModal } = this.state;

    return (
      <div className="actions">
        {displayDeleteModal && (
          <DeleteCampaignModal
            campaign={campaign}
            onClose={this.hideDeleteModal}
            onDelete={this.refresh}
          />
        )}
        <button className="table__link" onClick={this.duplicateCampaign}>
          Duplicate
        </button>
        <Link className="table__link" to={`/campaigns/${campaign.campaignId}`}>
          View
        </Link>
        <button className="table__link" onClick={this.showDeleteModal}>
          Delete
        </button>
      </div>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => {
    if (this.isStillMounted) {
      this.setState({ displayDeleteModal: false });
    }
  };

  refresh = () => this.props.fetchCampaigns();
}

export default connect(
  null,
  {
    fetchCampaigns,
    duplicateCampaign,
  }
)(CampaignsTableRowButtons);
