import React from "react";
import { Card } from "../shared";
import Segments from "../ConversationDetail/Segments";

class ReportingSectionResult extends React.Component {
  render() {
    const ReportingResultCard = ({ title, units, value }) => {
      return (
        <div style={{ width: "24%", marginRight: "10px" }}>
          <Card>
            <h3>{title}</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {units &&
                <h4 style={{ fontWeight: 600 }}>{units}</h4>
              }
              <h4>{value}</h4>
            </div>
          </Card>
        </div>
      );
    }

    const { recipients, completions, clicks, conversions, carts, orders, totalOrderValue, averageOrderValue, showShopifyData, showPixelData } = this.props
    const showFinalCards = Boolean(clicks || conversions || carts || orders)

    return (
      <Card>
        <h4 style={{ fontWeight: "600" }}>{`Part ${this.props.id + 1}`}</h4>
        {this.props.section && (
          <div className="targeting white" style={{ marginLeft: "20px", marginBottom: "10px" }}>
            <Segments
              matchSetting={this.props.section["targetFilterMatchSetting"]}
              segments={this.props.section["targetFilters"].map((targetFilter) => ({...targetFilter, value: (this.props.globalTriggersById && this.props.globalTriggersById[targetFilter.value]) ? this.props.globalTriggersById[targetFilter.value].name : targetFilter.value }))}
              hideHeader={true}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            marginTop: 10,
          }}
        >
          <ReportingResultCard title={"Recipients"} units={"Subscribers"} value={recipients} />
          <ReportingResultCard title={"Completion"} units={"Subscribers"} value={completions} />
          {showFinalCards && <ReportingResultCard title={"Clicks"} units={"Subscribers"} value={clicks} /> }
          {(showFinalCards && showPixelData) && <ReportingResultCard title={"Conversions"} units={"Subscribers"} value={conversions} /> }
        </div>
        {(showFinalCards && showShopifyData) &&
          <div
            style={{
              display: "flex",
              marginTop: 10,
            }}
          >
            <ReportingResultCard title={"Add To Carts"} units={"Subscribers"} value={carts} />
            <ReportingResultCard title={"Purchases"} units={"Subscribers"} value={orders} />
            <ReportingResultCard title={"Total Revenue"} value={totalOrderValue !== undefined ? "$"+totalOrderValue.toFixed(2) : '\u00A0'} />
            <ReportingResultCard title={"Average Order Value"} value={averageOrderValue !== undefined ? "$"+averageOrderValue.toFixed(2) : '\u00A0'} />
          </div>
        }
      </Card>
    );
  }
}

export default ReportingSectionResult;
