import React from "react";
import { Card } from "../../components/shared";
import { connect } from "react-redux";
import { RadioGroup, Radio } from "react-radio-group";
import {
  fetchUserConfiguration,
  updateUserConfiguration,
} from "../../actions/user-configurations";

class SubscribersNotificationConfig extends React.Component {
  componentDidMount() {
    const { currentUser } = this.props;
    this.props.fetchUserConfiguration(currentUser.userId);
  }

  handleChange = value => {
    const {
      currentUser: { userId },
      userConfiguration,
    } = this.props;
    const data = { ...userConfiguration, subscribersNotification: value };
    this.props.updateUserConfiguration(userId, data);
  };

  get subscribersNotifiction() {
    if (!this.props.userConfiguration) {
      return "all";
    }
    return this.props.userConfiguration.subscribersNotification || "all";
  }

  render() {
    return (
      <Card className="info-panel">
        <h3>Subscriber email notification</h3>
        <RadioGroup
          className="horizontal-radio-group"
          name="subscribers-notificaton"
          selectedValue={this.subscribersNotifiction}
          onChange={this.handleChange}
        >
          <div className="radio-option">
            <Radio className="paloma-radio-label" id="all" value="all" />
            <label className="paloma-radio-label" htmlFor="all">
              &nbsp;Receive all subscriber notifications
            </label>
          </div>
          <div className="radio-option">
            <Radio id="assigned" value="assigned" />
            <label className="paloma-radio-label" htmlFor="assigned">
              &nbsp;Receive assigned subscriber notifications
            </label>
          </div>
        </RadioGroup>
      </Card>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const {
      currentUser: { currentUser },
      userConfiguration,
    } = state;

    return { userConfiguration, currentUser, ...ownProps };
  },
  { fetchUserConfiguration, updateUserConfiguration }
)(SubscribersNotificationConfig);
