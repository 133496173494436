import React, { Component } from "react";
import { axisBottom, axisRight } from "d3-axis";
import { scaleBand, scaleLinear } from "d3-scale";
import { select, selectAll } from "d3-selection";

class EventBarChart extends Component {
  componentDidMount() {
    this.createBarChart();
  }

  componentDidUpdate() {
    selectAll("svg > *").remove();
    this.createBarChart();
  }

  createBarChart = () => {
    const size = this.props.size;
    const width = size[0];
    const height = size[1];

    let { data } = this.props
    const margin = { top: 30, right: 30, bottom: 30, left: 30 };

    const node = this.node;
    const svg = select(node);

    const x = scaleBand()
      .domain(data.map(d => d.label))
      .range([margin.left, width - margin.right])
      .padding(0.25);
    window.x = x;
    const maxUnique = (data[0] || { unique: 0 }).unique;
    const maxUniqueRoundedUp = maxUnique + (5 - (maxUnique % 5));
    const y = scaleLinear()
      .domain([0, maxUniqueRoundedUp])
      .range([height - margin.bottom, margin.top])
      .nice();

    const xAxis = axisBottom(x);
    const yAxis = axisRight(y)
      .tickSize(width - margin.left)
      .ticks(4);
    const customXAxis = g => {
      g.call(xAxis);
      g.select(".domain").remove();
      g.selectAll(".tick line").remove();
    };
    const customYAxis = g => {
      g.call(yAxis);
      g.select(".domain").remove();
      g.selectAll(".tick line")
        .attr("stroke", "#EFF1F5")
        .attr("x1", 55);
      g.selectAll(".tick text").attr("x", 20);
    };

    svg
      .append("g")
      .attr("transform", `translate(${margin.left},${height - margin.bottom})`)
      .call(customXAxis);

    svg.append("g").call(customYAxis);

    svg
      .append("g")
      .attr("fill", "#FDAB42")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", (d, i) => x(d.label) + margin.left)
      .attr("y", d => y(d.unique))
      .attr("height", d => y(0) - y(d.unique))
      .attr("width", x.bandwidth())
      .attr("class", "step bar");

    svg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("text")
      .attr("x", (d, i) => x(d.label) + margin.left)
      .attr("y", d => y(d.unique))
      .attr("dx", x.bandwidth() / 2.0)
      .attr("dy", -5)
      .attr("width", x.bandwidth())
      .attr("class", "label")
      .style("text-anchor", "middle")
      .text(function(d) {
        return `${d.overallCompletionRate}%`;
      });
  };

  render() {
    return (
      <svg
        className="event-bar-chart"
        ref={node => (this.node = node)}
        width={this.props.size[0]}
        height={this.props.size[1]}
      />
    );
  }
}

EventBarChart.defaultProps = {
  data: null,
  size: [700, 300],
  steps: [],
};

export default EventBarChart;
