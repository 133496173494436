import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/subscriber-thread-control";

export const createSubscriberThreadControl = id => ({
  [CALL_API]: {
    endpoint: `/api/subscriber/${id}/thread_control`,
    method: "POST",
    types: [
      constants.CREATE_SUBSCRIBER_THREAD_CONTROL_REQUEST,
      constants.CREATE_SUBSCRIBER_THREAD_CONTROL_SUCCESS,
      constants.CREATE_SUBSCRIBER_THREAD_CONTROL_FAILURE,
    ],
  },
});

export const deleteSubscriberThreadControl = id => ({
  [CALL_API]: {
    endpoint: `/api/subscriber/${id}/thread_control`,
    method: "DELETE",
    types: [
      constants.DELETE_SUBSCRIBER_THREAD_CONTROL_REQUEST,
      constants.DELETE_SUBSCRIBER_THREAD_CONTROL_SUCCESS,
      constants.DELETE_SUBSCRIBER_THREAD_CONTROL_FAILURE,
    ],
  },
});
