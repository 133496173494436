import moment from "moment-timezone";

window.moment = moment;

const formatDate = date => {
  if (date) {
    return moment
      .utc(date)
      .tz("America/New_York")
      .format("M/D/YY");
  }
};

export const dateInWords = date => {
  if (date) {
    if (moment.utc(date) > moment().add(1, "year")) {
      return moment
        .utc(date)
        .tz("America/New_York")
        .format("MMMM D, YYYY");
    } else {
      return moment
        .utc(date)
        .tz("America/New_York")
        .format("MMMM D");
    }
  }
};

const withTimeBold = date => {
  if (date) {
    const time = moment.utc(date).tz("America/New_York");
    return `<span class='text-bold'>${time.format(
      "M/D/YY"
    )}</span> \n ${time.format("hh:mmA")}`;
  }
};

const withTime = date => {
  if (date) {
    return moment
      .utc(date)
      .tz("America/New_York")
      .format("M/D/YY[\n]hh:mmA");
  }
};

const timeOnly = date => {
  if (date) {
    return moment
      .utc(date)
      .tz("America/New_York")
      .format("h:mmA");
  }
};

const dateOnly = date => {
  if (date) {
    return moment
      .utc(date)
      .tz("America/New_York")
      .format("M/D/YY");
  }
};

const timeOnlyIfToday = date => {
  if (date) {
    if (moment(date).isSame(moment(), "day")) {
      return timeOnly(date);
    } else {
      return withTime(date);
    }
  }
};

const subtractTime = (a, b) => {
  if (a && b) {
    return moment.utc(a) - moment.utc(b);
  }
};

export {
  dateOnly,
  formatDate,
  subtractTime,
  timeOnly,
  timeOnlyIfToday,
  withTime,
  withTimeBold,
};
