import React from "react";
import PaginatedList from "../PaginatedList";
import { withTime } from "../../utilities/format-date";
import _ from "underscore";
import moment from "moment";

const linkToSource = source => {
  const prefix = `/audience/subscribers/${
    source.referred.subscriberId
  }/sources`;
  if (source.source === "ads") {
    return `${prefix}/ads/${source.adId}`;
  } else if (source.source === "customer_chat_plugin") {
    return `${prefix}/customer_chat_plugin/${source.referrer_uri}`;
  } else {
    return `${prefix}/${source.source}/${source.ref}`;
  }
};

const Source = source => (
  <tr key={source.id} className="table__row">
    <td className="table__data" style={{ textTransform: "capitalize" }}>
      <a className="table__link" href={linkToSource(source)}>
        {source.source}
      </a>
    </td>
    <td className="table__data">{withTime(source.createdAt)}</td>
    <td className="table__data">
      <a className="table__link" href={linkToSource(source)}>
        View
      </a>
    </td>
  </tr>
);

const createdAt = c =>
  (c.createdAt && moment.utc(c.createdAt).format("X")) || -Infinity;

const sorted = sources => _.sortBy(sources, createdAt).reverse();

const SourcesTable = props => {
  if (!props.sources.length) {
    return <div />;
  }
  return (
    <div className="info-panel links-table">
      <div className="content-header">
        <h3>Source</h3>
      </div>
      <PaginatedList
        headers={[]}
        items={sorted(props.sources)}
        component={Source}
        perPage={5}
      />
    </div>
  );
};

SourcesTable.defaultProps = { sources: [] };

export default SourcesTable;
