import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  deleteConversationStepTrigger,
  fetchVisibleConversationSteps,
  putConversationStep,
  setTriggerChoice,
} from "../../actions/conversations";

import { createEditorState } from "../../utilities/editor";

import { RESPONSE } from "../../constants/builder-menu";
import { openMenu } from "../../actions/builder-menu";

import { SeparatedButtonRow } from "../../components/shared";

import UserFieldResponse from "./UserFieldResponse";
import UserTextResponse from "./UserTextResponse";
import UserTriggerResponse from "./UserTriggerResponse";

import { putOrganizationField } from "../../actions/organization-fields";

import {
  DeleteUserResponseModal,
  SetResponseAsFieldModal,
} from "../../components/ConversationDetail";

class UserResponse extends Component {
  static propTypes = {
    conversationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    conversationStep: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    triggerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  state = {
    displayDeleteModal: false,
    editorState: createEditorState(""),
    displayFieldModal: false,
  };

  hasTrigger = (step, triggerType) =>
    step.triggers.some(t => t.type === triggerType);

  render() {
    const {
      conversationStep,
      isMenuOpen,
      organizationFields,
      putOrganizationField,
      editable = true,
      trigger,
    } = this.props;

    const buttons = [
      {
        text: "Capture Field",
        onClick: this.showFieldModal,
      },
      {
        text: "Delete",
        onClick: this.showDeleteModal,
      },
    ];

    const { displayDeleteModal, displayFieldModal } = this.state;

    let responseType;
    switch (true) {
      case !!trigger:
        responseType = "trigger";
        break;
      case !!conversationStep.organizationField:
        responseType = "field";
        break;
      default:
        responseType = "text";
        break;
    }

    return (
      <div style={{ float: "right" }}>
        <div className="user-response">
          <div onClick={this.focus} style={{ position: "relative" }}>
            {responseType === "text" && (
              <UserTextResponse
                conversationStep={conversationStep}
                editable={editable}
                handleDelete={this.showDeleteModal}
              />
            )}
            {responseType === "trigger" &&
              trigger && (
                <UserTriggerResponse
                  conversationStep={conversationStep}
                  editable={editable}
                  handleDelete={this.showDeleteModal}
                  trigger={trigger}
                />
              )}
            {responseType === "field" && (
              <UserFieldResponse
                conversationStep={conversationStep}
                editable={editable}
                handleDelete={this.showDeleteModal}
              />
            )}
          </div>
          {displayDeleteModal && (
            <DeleteUserResponseModal
              onClose={this.hideDeleteModal}
              handleDeleteConfirmed={this.handleDeleteConfirmed}
              isOpen={true}
            />
          )}
          {displayFieldModal && (
            <SetResponseAsFieldModal
              handleSetResponseAsField={this.handleAddFieldResponse}
              putOrganizationField={putOrganizationField}
              isOpen={true}
              organizationFields={organizationFields}
              onClose={this.hideFieldModal}
            />
          )}
        </div>
        <div className="user-response__menu">
          {editable &&
            isMenuOpen && (
              <SeparatedButtonRow
                className="conversation-step-button__button-types"
                buttons={buttons}
              />
            )}
        </div>
      </div>
    );
  }

  focus = () =>
    this.props.openMenu({ id: this.props.conversationStepId, type: RESPONSE });

  handleAddFieldResponse = async organizationFieldId => {
    await this.props.putConversationStep({
      ...this.props.conversationStep,
      organizationFieldId,
      userPlaceholderText: JSON.stringify(createEditorState("").toJSON()),
    });
  };

  handleDeleteConfirmed = async () => {
    const {
      conversationId,
      conversationStep,
      deleteConversationStepTrigger,
      fetchVisibleConversationSteps,
      putConversationStep,
      triggerId,
      setTriggerChoice,
    } = this.props;

    putConversationStep({
      ...conversationStep,
      organizationFieldId: null,
      userPlaceholderText: null,
    });

    if (this.props.trigger) {
      const { conversationStepId } = conversationStep;

      deleteConversationStepTrigger({
        conversationStepId,
        triggerId,
      });

      await setTriggerChoice({ conversationStepId, triggerId: null });
      fetchVisibleConversationSteps(conversationId);
    }
  };

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => this.setState({ displayDeleteModal: false });

  showFieldModal = () => this.setState({ displayFieldModal: true });
  hideFieldModal = () => this.setState({ displayFieldModal: false });
}

const mapStateToProps = (state, ownProps) => {
  const {
    conversationStep: { triggers, conversationStepId },
    triggerId,
  } = ownProps;

  const trigger = triggerId
    ? triggers.find(t => t.triggerId === triggerId)
    : null;

  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;

  const isMenuOpen = menuId === conversationStepId && menuType === RESPONSE;

  return {
    conversationStepId,
    isMenuOpen,
    trigger,
    organizationFields: state.organizationFields.organizationFields,
  };
};

export default connect(
  mapStateToProps,
  {
    deleteConversationStepTrigger,
    putOrganizationField,
    fetchVisibleConversationSteps,
    openMenu,
    putConversationStep,
    setTriggerChoice,
  }
)(UserResponse);
