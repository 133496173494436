import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";

import { CaretDropdown } from "../shared";

import { timeOnly, dateOnly } from "../../utilities/format-date";
import { capitalizeString } from "../../utilities/format-string";
import Targeting from "./Targeting";
import { SubscriberChatStatus } from "../shared/SubscriberStatus";
import PaginatedList from "../PaginatedList";
import { fetchConversationRecipients } from "../../actions/conversation-recipients";

const FILTER_OPTIONS = {
  all: "All",
  clicked: "Clicked",
  completed: "Completed",
  received: "Received",
  responded: "Responded",
  unsubscribed: "Unsubscribed",
};

class ConversationRecipients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conversationStatusFilter: "all",
      recipients: [],
      currentPage: 1,
      totalPages: 0,
    };
  }

  componentDidMount() {
    this.fetchRecipients();
  }

  fetchRecipients = (page = 1) => {
    this.props
      .fetchConversationRecipients(this.props.conversationId, {
        page,
      })
      .then(({ payload }) => {
        const { totalPages, recipients, currentPage } = payload;
        this.setState({ totalPages, recipients, currentPage });
      });
  };

  render() {
    const { list, matchSetting, segments } = this.props;
    const { recipients } = this.state;

    const filterOptions = Object.entries(FILTER_OPTIONS)
      .filter(([value, label]) => value !== this.state.conversationStatusFilter)
      .map(([value, label]) => ({
        text: label,
        onClick: () => this.setState({ conversationStatusFilter: value }),
      }));

    let filteredRecipients;

    switch (this.state.conversationStatusFilter) {
      case "all":
        filteredRecipients = recipients;
        break;
      case "clicked":
        filteredRecipients = recipients.filter((r) =>
          this.props.clicked.includes(r.subscriberId)
        );
        break;
      default:
        filteredRecipients = recipients.filter(
          (r) => r.conversationStatus === this.state.conversationStatusFilter
        );
    }

    return (
      <div className="conversation-recipients">
        <h3>Recipients</h3>
        {this.props.type === "campaign" && (
          <Targeting
            list={list || undefined}
            segments={segments}
            matchSetting={matchSetting}
          />
        )}
        <CaretDropdown
          buttonText={FILTER_OPTIONS[this.state.conversationStatusFilter]}
          dropdownOptions={filterOptions}
        />
        <RecipientTable
          recipients={filteredRecipients}
          onChoosePage={this.fetchRecipients}
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
        />
      </div>
    );
  }
}

const RecipientRow = (recipient) => {
  const classes = classnames("table__row text-xs");
  const renderDateTime = (datetime) => {
    if (datetime) {
      return (
        <td className="table__data time">
          <strong>{dateOnly(datetime)}</strong> <br />
          {timeOnly(datetime)}
        </td>
      );
    }
    return <td className="table__data time">N/A</td>;
  };
  return (
    <tr className={classes} key={recipient.subscriberId}>
      <td className="table__data">
        <Link
          className="table__link"
          to={`/audience/subscribers/${recipient.subscriberId}`}
        >
          {recipient.fullName}
        </Link>
      </td>
      <td className="table__data">
        {capitalizeString(recipient.conversationStatus)}
      </td>
      {renderDateTime(recipient.lastMessageReceivedAt)}
      {renderDateTime(recipient.lastMessageSentAt)}
      {renderDateTime(recipient.createdAt)}
      <td className="table__data">
        <SubscriberChatStatus
          standby={recipient.standby}
          manualMessaging={recipient.manualMessagingEnabled}
        />
      </td>
      <td className="table__data">
        <Link
          className="table__link"
          to={`/audience/subscribers/${recipient.subscriberId}`}
        >
          View
        </Link>
      </td>
    </tr>
  );
};

export class RecipientTable extends React.Component {
  get sortedRecipients() {
    // Sorts recipients by last message received date time.
    // Most recent first
    return this.props.recipients.sort((r1, r2) => {
      const date1 = new Date(r1.lastMessageReceivedAt);
      const date2 = new Date(r2.lastMessageReceivedAt);

      if (date2 > date1) return 1;
      if (date2 < date1) return -1;
      return 0;
    });
  }

  render() {
    const headers = [
      "Name",
      "Status",
      "Received",
      "Sent",
      "Subscribed",
      "Chat",
      "",
    ];

    return (
      <PaginatedList
        headers={headers}
        controlPaginationExternally
        items={this.sortedRecipients}
        component={RecipientRow}
        onChoosePage={this.props.onChoosePage}
        currentPage={this.props.currentPage}
        totalPages={this.props.totalPages}
      />
    );
  }
}

export default connect(null, { fetchConversationRecipients })(
  ConversationRecipients
);
