import React from "react";
import { connect } from "react-redux";
import { Card, Modal } from "../components/shared";
import { push } from "react-router-redux";
import { updateProduct, fetchProduct } from "../actions/inventory";
import ProductDetailForm from "../components/ProductDetailForm";
import { showSuccessAlert } from "../actions/alerts";

class EditProduct extends React.Component {
  state = {
    title: "",
    price: "",
    subtitle: "",
    url: "",
    image: "",
    buttonText: "",
    editing: false,
    displayModal: false,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = null;

    if (state.editing) {
      return null;
    }

    if (!state.title && props.product) {
      newState = { title: props.product.title, editing: true };
    }

    if (!state.price && props.product) {
      newState.price = props.product.price;
      newState.editing = true;
    }

    if (!state.subtitle && props.product) {
      newState.subtitle = props.product.subtitle;
      newState.editing = true;
    }

    if (!state.url && props.product) {
      newState.url = props.product.url;
      newState.editing = true;
    }

    if (!state.buttonText && props.product) {
      newState.buttonText = props.product.buttonText;
      newState.editing = true;
    }

    return newState;
  }

  componentDidMount() {
    const { productId } = this.props;
    if (!this.props.product && productId) {
      this.props.fetchProduct(productId);
    }
  }

  handleEditProduct = e => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("title", this.state.title);
    fd.append("subtitle", this.state.subtitle ?? '');
    fd.append("price", this.state.price);
    fd.append("url", this.state.url);
    fd.append("button_text", this.state.buttonText ?? '');
    fd.append("image", this.state.image);

    const { productId } = this.props;

    this.props.updateProduct(productId, fd).then(() => {
      document.body.scrollTop = 0;
      this.props.showSuccessAlert(
        <span>
          Success! Your product update is being made across your conversations,
          and will be live in the next few minutes
        </span>
      );
      this.props.push(this.props.match.url.replace("/edit", ""));
    });
  };

  canSubmit = () => {
    const { title, url } = this.state;
    return !!(title && url);
  };

  onChange = (k, v) => {
    this.setState({ ...this.state, [k]: v });
  };

  render() {
    return (
      <div>
        <h1 className="page-title">Edit Product</h1>
        <br />
        <Card>
          <ProductDetailForm
            onSubmit={e => {
              e.preventDefault();
              this.setState({ displayModal: true });
            }}
            {...this.state}
            onChange={this.onChange}
            canSubmit={this.canSubmit()}
            submitLabel="Publish changes"
            onCancel={() =>
              this.props.push(`/products/${this.props.productId}`)
            }
          />
        </Card>
        {this.state.displayModal && (
          <div className="modal__container">
            <Modal
              title="Publish product changes"
              isOpen={this.state.displayModal}
              onClose={() => this.setState({ displayModal: false })}
            >
              <p className="modal__message" style={{ textAlign: "left" }}>
                Are you sure you'd like to publish your changes?
              </p>
              <p className="modal__message" style={{ textAlign: "left" }}>
                This will update the content everywhere this product exists in
                active and inactive conversations.
              </p>
              <div className="modal__controls">
                <button
                  className="paloma-button modal__button modal__button--red"
                  onClick={() => this.setState({ displayModal: false })}
                >
                  No, cancel
                </button>
                <button
                  className="paloma-button--pink modal__button modal__button--red"
                  onClick={this.handleEditProduct}
                >
                  Yes, publish
                </button>
              </div>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { productId } = props.match.params;
    const product = state.inventory.productsById[productId];

    return { productId, product };
  },
  {
    fetchProduct,
    updateProduct,
    push,
    showSuccessAlert,
  }
)(EditProduct);
