import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/reports";

const initialState = {};

export default createReducer(initialState, {
  [constants.SAVE_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.reportId] : {
        ...action.payload,
        loading: false,
      },
      ["latest"] : {
        ...action.payload,
        loading: false,
      }
    };
  },
  [constants.SAVE_REPORT_FAILURE](state, action) {
    return {
      ...state,
      ["latest"] : {
        loading: false,
      }
    };
  },
  [constants.SAVE_REPORT_REQUEST](state, action) {
    return {
      ...state,
      ["latest"] : {
        loading: true,
      }
    };
  },

  [constants.FETCH_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      [action.payload.reportId] : {
        ...action.payload,
        loading: false,
      }
    };
  },
  [constants.FETCH_REPORT_FAILURE](state, action) {
    return {
      ...state,
      [action.meta.reportId] : {
        loading: false,
      }
    };
  },
  [constants.FETCH_REPORT_REQUEST](state, action) {
    return {
      ...state,
      [action.meta.reportId] : {
        loading: true,
      }
    };
  },

  [constants.FETCH_REPORTS_SUCCESS](state, action) {
    var result = action.payload.reports.reduce(function(map, obj) {
      map[obj.reportId] = obj;
      return map;
    }, {});
    return result
  },

  [constants.DELETE_REPORT_SUCCESS](state, action) {
    const next = {...state}
    delete next[action.meta.reportId]
    return next
  },
});

