import React from "react";
import { connect } from "react-redux";
import { fetchSources } from "../actions/sources";
import PaginatedList from "../components/PaginatedList";
import { withTime } from "../utilities/format-date";
import { fetchSubscriber } from "../actions/subscribers";

class SourceDetails extends React.Component {
  componentWillMount = () => {
    this.props.fetchSources(
      this.props.match.params,
      this.type(this.props.match.params)
    );
    this.props.fetchSubscriber(this.props.match.params.subscriberId);
  };

  HEADERS = {
    ads: "Facebook Ad",
    shortlink: "Shortlink",
    messenger_code: "Messenger Code",
    discover_tab: "Discover Tab",
    customer_chat_plugin: "Messenger Plugin",
  };

  type = params => {
    if (params.source) {
      return params.source;
    } else if (params.adId) {
      return "ads";
    } else if (params.url) {
      return "customer_chat_plugin";
    }
  };

  header = () => this.HEADERS[this.type(this.props.match.params)];

  render = () => {
    return (
      <div className="link-detail">
        <div className="content-header">
          <h1>{this.header()}</h1>
        </div>
        {this.props.match.params.ref && (
          <div className="info-panel">
            <h3>Ref</h3>
            <span style={{ fontWeight: "bold", color: "#ba2a6b" }}>
              {this.props.match.params.ref}
            </span>
          </div>
        )}
        {this.props.match.params.adId && (
          <div className="info-panel">
            <h3>Ad ID</h3>
            <span style={{ fontWeight: "bold", color: "#ba2a6b" }}>
              {this.props.match.params.adId}
            </span>
          </div>
        )}
        {this.props.match.params.referrerUri && (
          <div className="info-panel">
            <h3>Ad ID</h3>
            <span style={{ fontWeight: "bold", color: "#ba2a6b" }}>
              {this.props.match.params.referrerUri}
            </span>
          </div>
        )}
        <div className="row">
          <div className="info-panel">
            <h3>
              Total<span className="stat">{this.props.totalSubscribers}</span>
            </h3>
          </div>
          <div className="info-panel">
            <h3>
              Unique<span className="stat">{this.props.uniqueSubscribers}</span>
            </h3>
          </div>
        </div>
        <div className="info-panel">
          <h3>Recipients</h3>
          <PaginatedList
            headers={[
              "Name",
              "Date of referral",
              "Last Message",
              "Live chat",
              "Subscribed",
              "",
            ]}
            items={this.props.sources || []}
            component={Source}
          />
        </div>
      </div>
    );
  };
}

const subscriberLink = (id, text) => (
  <a href={`/audience/subscribers/${id}`} className="table__link">
    {text}
  </a>
);

const Source = source => (
  <tr className="table__row" key={source.id}>
    <td className="table__data">
      {subscriberLink(source.referred.subscriberId, source.referred.fullName)}
    </td>
    <td className="table__data">{withTime(source.createdAt)}</td>
    <td className="table__data">
      {withTime(source.referred.lastMessageReceivedAt)}
    </td>
    <td className="table__data">
      {source.referred.manualMessagingEnabled ? "Yes" : "No"}
    </td>
    <td className="table__data">{source.referred.subscribed ? "Yes" : "No"}</td>
    <td className="table__data">
      {subscriberLink(source.referred.subscriberId, "View")}
    </td>
  </tr>
);

SourceDetails.defaultProps = { recipientsClicked: [] };

const mapStateToProps = state => state.sources;

export default connect(
  mapStateToProps,
  { fetchSources, fetchSubscriber }
)(SourceDetails);
