import { byId } from "../utilities/reducers";

import { subscriberSchema } from "../schemas";

import { normalize } from "normalizr";
import createReducer from "../utilities/create-reducer";

import { entriesToObject } from "../utilities/reducers";

import * as analyticsConstants from "../constants/conversation-analytics";
import * as constants from "../constants/conversations";
import * as quickReplyConstants from "../constants/quick-replies";
import * as scheduleConstants from "../constants/conversation-schedules";
import * as subscriberConstants from "../constants/subscribers";

const initialState = {
  conversationsById: {},
  conversationStepsById: {},
  visibleConversationStepsByConversationId: {},
  isLoading: false,
  errorMessage: "",
  editMode: {},
  triggerChoicesByConversationStepId: {},
  conversationStepTriggersById: {},
  conversationStepButtonsById: {},
  numTargetedByConversationId: {},
  insertFieldModal: {
    show: false,
    conversationStepId: null,
  },
};

const template_card_put_success = (state, action) => {
    return {
    ...state,
    conversationStepsById: {
      ...state.conversationStepsById,
      [action.payload.conversationStep.conversationStepId]: {
        ...action.payload.conversationStep,
        isLoading: false,
      },
    },
  };
}

export default createReducer(initialState, {
  [constants.FETCH_CONVERSATIONS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_CONVERSATIONS_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      conversationsById: action.payload.reduce(function(
        conversationsById,
        conversation
      ) {
        return {
          ...conversationsById,
          [conversation.conversationId]: {
            ...state.conversationsById[conversation.conversationId],
            ...conversation,
          },
        };
      },
      {}),
    };
  },

  [constants.FETCH_CONVERSATION_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...(state.conversationsById[action.payload.conversationId] || {}),
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.FETCH_CONVERSATION_FUNNEL_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.meta.id]: {
          ...(state.conversationsById[action.meta.id] || {}),
          eventsReport: action.payload[0],
          isLoading: false,
        },
      },
    };
  },

  [analyticsConstants.FETCH_CONVERSATION_ANALYTICS_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...(state.conversationsById[action.payload.conversationId] || {}),
          analytics: action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.FETCH_CONVERSATION_STEPS_SUCCESS](state, action) {
    // When we get a refresh of a conversation, we should delete all trigger
    // choices that now point at deleted triggers.
    const validTriggerChoices = action.payload.reduce((accumulator, cs) => {
      accumulator[cs.conversationStepId] = (cs.triggers || []).map(
        t => t.triggerId
      );
      return accumulator;
    }, {});

    let triggerChoicesByConversationStepId = Object.entries(
      state.triggerChoicesByConversationStepId
    ).filter(triggerChoice => {
      const key = triggerChoice[0];
      const value = triggerChoice[1];
      if (!validTriggerChoices[key]) {
        // either this conversation step belongs to another conversation, or
        // it got deleted, in which case we will never look at this value
        // again so it doesn't matter.
        return true;
      } else {
        // if the trigger still exists it will be present in the valid
        // choices, otherwise it must have been deleted and we should no
        // longer use it.
        return validTriggerChoices[key].includes(value);
      }
    });

    triggerChoicesByConversationStepId = entriesToObject(
      triggerChoicesByConversationStepId
    );

    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        ...byId(action.payload, "conversationStepId"),
      },
      triggerChoicesByConversationStepId,
    };
  },

  [constants.FETCH_CONVERSATION_STEP_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStepId]: action.payload,
      },
    };
  },

  [constants.PUT_CONVERSATION_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.DELETE_CONVERSATION_SUCCESS](state, action) {
    const conversationsById = { ...state.conversationsById };
    delete conversationsById[action.meta.conversationId];
    return {
      ...state,
      isLoading: false,
      conversationsById: conversationsById,
    };
  },

  // everything below this line doesn't actually belong in this file and should be
  // split out at some point.

  [constants.PUT_CONVERSATION_STEP_REQUEST](state, action) {
    if (!action.meta.conversationStep) {
      return {
        ...state,
        isLoading: true,
      };
    }

    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.meta.conversationStep.conversationStepId]: {
          ...action.meta.conversationStep,
          isLoading: true,
        },
      },
    };
  },

  [constants.PUT_CONVERSATION_STEP_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStepId]: {
          ...action.payload,
        },
      },
      isLoading: false,
    };
  },

  [constants.FETCH_VISIBLE_CONVERSATION_STEPS_REQUEST](state, action) {
    return {
      ...state,
      visibleConversationStepsByConversationId: {
        ...state.visibleConversationStepsByConversationId,
        [action.meta.conversationId]: {
          ...(state.visibleConversationStepsByConversationId[
            action.meta.conversationId
          ] || {}),
        },
      },
    };
  },

  [constants.FETCH_VISIBLE_CONVERSATION_STEPS_SUCCESS](state, action) {
    return {
      ...state,
      visibleConversationStepsByConversationId: {
        ...state.visibleConversationStepsByConversationId,
        [action.meta.conversationId]: {
          stepIds: action.payload.visibleConversationStepIds,
          isLoading: false,
        },
      },
      conversationStepsById: {
        ...state.conversationStepsById,
        ...byId(action.payload.steps, "conversationStepId"),
      },
    };
  },

  [constants.FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_SUCCESS](state, action) {
    return {
      ...state,
      visibleConversationStepsForMenu: action.payload.steps,
      conversationStepsById: {
        ...state.conversationStepsById,
        ...byId(action.payload.steps, "conversationStepId"),
      },
    };
  },

  CLEAR_VISIBLE_CONVERSATION_STEPS: (state, action) => {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        ...byId(action.payload || [], "conversationStepId"),
      },
      visibleConversationStepsByConversationId: {
        ...state.visibleConversationStepsByConversationId,
        [action.id]: {
          stepIds: [],
          isLoading: false,
        },
      },
    };
  },

  [constants.CLEAR_EDIT_MODE](state, action) {
    return {
      ...state,
      editMode: {},
    };
  },

  [constants.SHOW_INSERT_FIELD_MODAL](state, action) {
    return {
      ...state,
      insertFieldModal: {
        show: true,
        conversationStepId: action.payload.conversationStepId,
      },
    };
  },

  [constants.CLOSE_INSERT_FIELD_MODAL](state, action) {
    return {
      ...state,
      insertFieldModal: {
        show: false,
        conversationStepId: null,
      },
    };
  },

  [subscriberConstants.FETCH_SUBSCRIBER_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, subscriberSchema);
    const normalizedConversations = normalizedData.entities.conversations || {};

    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        ...Object.values(normalizedConversations).reduce(
          (conversationsById, conversation) => ({
            ...conversationsById,
            [conversation.conversationId]: {
              ...(state.conversationsById[conversation.conversationId] || {}),
              ...conversation,
            },
          }),
          {}
        ),
      },
    };
  },

  [subscriberConstants.FETCH_SUBSCRIBERS_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, {
      subscribers: [subscriberSchema],
    });
    const normalizedConversations = normalizedData.entities.conversations || {};

    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        ...Object.values(normalizedConversations).reduce(
          (conversationsById, conversation) => ({
            ...conversationsById,
            [conversation.conversationId]: {
              ...(state.conversationsById[conversation.conversationId] || {}),
              ...conversation,
            },
          }),
          {}
        ),
      },
    };
  },

  [constants.PUBLISH_CONVERSATION_REQUEST](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.meta.conversationId]: {
          ...(state.conversationsById[action.meta.conversationId] || {}),
          // TODO: Right now, publishing a conversation and sending messages to every recipient
          // happens synchronously, so in order to show the conversation in a "sending" state,
          // we just set state in the Redux store based on the API request lifecycle actions.
          //
          // However, this is not robust (e.g. what happens if the user reloads the page before
          // the conversation has finished publishing/sending?)
          sending: true,
          showPublishConfirmationModal: true,
        },
      },
    };
  },

  [constants.PUBLISH_CONVERSATION_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.meta.conversationId]: {
          ...(state.conversationsById[action.meta.conversationId] || {}),
          ...action.payload.conversation,
          sending: false,
        },
      },
    };
  },

  [constants.DUPLICATE_CONVERSATION_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [quickReplyConstants.ADD_TRIGGER_TO_QUICK_REPLY_SUCCESS](state, action) {
    return {
      ...state,
      triggerChoicesByConversationStepId: {
        ...state.triggerChoicesByConversationStepId,
        [action.payload.trigger.sourceConversationStepId]:
          action.payload.trigger.triggerId,
      },
    };
  },
  [constants.SET_TRIGGER_CHOICE](state, action) {
    // if triggerId is passed, then associate this triggerId with the conversationStep
    if (action.payload.triggerId) {
      return {
        ...state,
        triggerChoicesByConversationStepId: {
          ...state.triggerChoicesByConversationStepId,
          [action.payload.conversationStepId]: action.payload.triggerId,
        },
      };
    }
    // if triggerId == null, then clear the trigger associated with this conversationStep if it was set
    else if (
      state.triggerChoicesByConversationStepId[
        action.payload.conversationStepId
      ]
    ) {
      let triggerChoicesByConversationStepId =
        state.triggerChoicesByConversationStepId;
      delete triggerChoicesByConversationStepId[
        action.payload.conversationStepId
      ];
      return {
        ...state,
        triggerChoicesByConversationStepId: triggerChoicesByConversationStepId,
      };
    } else {
      return state;
    }
  },

  [constants.PUT_BUTTON_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStep.conversationStepId]: {
          ...action.payload.conversationStep,
          errors: action.payload.errors,
          isLoading: false,
        },
      },
      conversationStepButtonsById: {
        ...state.conversationStepButtonsById,
        [action.payload.conversationStepButton.conversationStepButtonId]: {
          ...action.payload.conversationStepButton,
          isLoading: false,
        },
      },
    };
  },

  [constants.CREATE_BUTTON_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStep.conversationStepId]: {
          ...action.payload.conversationStep,
          isLoading: false,
        },
      },
      conversationStepButtonsById: {
        ...state.conversationStepButtonsById,
        [action.payload.conversationStepButton.conversationStepButtonId]: {
          ...action.payload.conversationStepButton,
          isLoading: false,
        },
      },
    };
  },  

  [constants.PUT_TEMPLATE_CARD_SUCCESS](state, action) { return template_card_put_success(state, action) },
  [constants.REPLACE_TEMPLATE_CARD_PRODUCT_SUCCESS](state, action) { return template_card_put_success(state, action) },

  [constants.PUT_TEMPLATE_CARD_BUTTON_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStep.conversationStepId]: {
          ...action.payload.conversationStep,
          isLoading: false,
        },
      },
    };
  },

  [constants.PUT_CONVERSATION_STEP_TRIGGER_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepTriggersById: {
        ...state.conversationStepTriggersById,
        [action.payload.triggerId]: { ...action.payload, isLoading: false },
      },
    };
  },

  [constants.DELETE_CONVERSATION_STEP_SUCCESS](state, action) {
    const conversationStepsById = { ...state.conversationStepsById };
    conversationStepsById[action.meta.conversationStepId] = null;

    return {
      ...state,
      conversationStepsById,
    };
  },

  [constants.DELETE_CONVERSATION_STEP_TRIGGER_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStepId]: action.payload,
      },
    };
  },

  [constants.DELETE_CONVERSATION_STEP_BUTTON_SUCCESS](state, action) {
    const { triggerChoicesByConversationStepId } = state;
    const {
      payload: {
        conversationStep: { conversationStepId },
      },
    } = action;

    delete triggerChoicesByConversationStepId[conversationStepId];

    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [conversationStepId]: action.payload.conversationStep,
      },
      triggerChoicesByConversationStepId,
    };
  },

  [quickReplyConstants.DELETE_QUICK_REPLY_SUCCESS](state, action) {
    const { triggerChoicesByConversationStepId } = state;
    const {
      payload: { conversationStepId },
    } = action;

    delete triggerChoicesByConversationStepId[conversationStepId];

    return {
      ...state,
      triggerChoicesByConversationStepId,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStepId]: action.payload,
      },
    };
  },

  [constants.DELETE_TEMPLATE_CARD_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStep.conversationStepId]: {
          ...action.payload.conversationStep,
          isLoading: false,
        },
      },
    };
  },

  [constants.DELETE_TEMPLATE_CARD_BUTTON_SUCCESS](state, action) {
    const {
      payload: { conversationStepId },
    } = action;

    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [conversationStepId]: {
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.UPLOAD_CONVERSATION_STEP_IMAGE_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStepId]: action.payload,
      },
    };
  },

  [constants.UPLOAD_TEMPLATE_CARD_IMAGE_SUCCESS](state, action) {
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [action.payload.conversationStepId]: action.payload,
      },
    };
  },

  [constants.PUT_TARGET_FILTER_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversation.conversationId]: {
          ...action.payload.conversation,
        },
      },
    };
  },

  [constants.DELETE_TARGET_FILTER_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversation.conversationId]:
          action.payload.conversation,
      },
    };
  },

  [constants.DELETE_TARGET_FILTER_FAILURE](state, action) {
    return state;
  },

  [constants.FETCH_NUM_TARGETED_REQUEST](state, action) {
    return {
      ...state,
      numTargetedByConversationId: {
        ...state.numTargetedByConversationId,
        [action.meta.conversationId]: {
          isLoading: true,
        },
      },
    };
  },

  [constants.FETCH_NUM_TARGETED_SUCCESS](state, action) {
    return {
      ...state,
      numTargetedByConversationId: {
        ...state.numTargetedByConversationId,
        [action.meta.conversationId]: {
          number: action.payload.numTargeted,
          isLoading: false,
        },
      },
    };
  },

  [constants.CLOSE_PUBLISH_CONFIRMATION_MODAL](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...(state.conversationsById[action.payload.conversationId] || {}),
          showPublishConfirmationModal: false,
        },
      },
    };
  },

  [quickReplyConstants.ADD_QUICK_REPLY_SUCCESS](state, action) {
    const conversationStepId = action.payload.conversationStepId;
    return {
      ...state,
      conversationStepsById: {
        ...state.conversationStepsById,
        [conversationStepId]: {
          ...state.conversationStepsById[conversationStepId],
          quickReplies: [
            ...state.conversationStepsById[conversationStepId].quickReplies,
            action.payload,
          ],
        },
      },
    };
  },

  [scheduleConstants.CREATE_CONVERSATION_SCHEDULE_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...state.conversationsById[action.payload.conversationId],
          sendAt: action.payload.sendAt,
        },
      },
    };
  },

  [scheduleConstants.UPDATE_CONVERSATION_SCHEDULE_REQUEST](state, action) {
    return state;
  },

  [scheduleConstants.UPDATE_CONVERSATION_SCHEDULE_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...state.conversationsById[action.payload.conversationId],
          sendAt: action.payload.sendAt,
        },
      },
    };
  },

  [scheduleConstants.DELETE_CONVERSATION_SCHEDULE_SUCCESS](state, action) {
    return {
      ...state,
      conversationsById: {
        ...state.conversationsById,
        [action.payload.conversationId]: {
          ...state.conversationsById[action.payload.conversationId],
          scheduled: false,
          sendAt: null,
        },
      },
    };
  },

  [constants.UPDATE_STEP_REQUIREMENT_SUCCESS](state, action) {
    const updatedStep = action.payload;
    const { conversationStepsById } = state;

    conversationStepsById[updatedStep.conversationStepId] = updatedStep;

    return { ...state, conversationStepsById };
  },
});
