export default store => next => action => {
  if (action.payload && action.payload.token) {
    window.localStorage.setItem("paloma_session_token", action.payload.token);
    return next(action);
  } else if (action.payload && action.payload.status === 401) {
    window.localStorage.removeItem("paloma_session_token");
    store.dispatch({ type: "API_UNAUTHORIZED" });
  } else {
    return next(action);
  }
};
