import createReducer from "../utilities/create-reducer";
import { byId } from "../utilities/reducers";

import * as constants from "../constants/welcome-messages";

const initialState = {
  welcomeMessagesById: {},
  isLoading: false,
  errorMessage: "",
};

export default createReducer(initialState, {
  [constants.FETCH_WELCOME_MESSAGES_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_WELCOME_MESSAGES_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      welcomeMessagesById: byId(action.payload, "triggerId"),
    };
  },

  [constants.FETCH_WELCOME_MESSAGE_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_WELCOME_MESSAGE_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      welcomeMessagesById: {
        ...state.welcomeMessagesById,
        [action.payload.triggerId]: {
          ...action.payload,
        },
      },
    };
  },

  [constants.UPDATE_WELCOME_MESSAGE_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.UPDATE_WELCOME_MESSAGE_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      welcomeMessagesById: {
        ...state.welcomeMessagesById,
        [action.payload.triggerId]: {
          ...state.welcomeMessagesById[action.payload.triggerId],
          ...action.payload,
        },
      },
    };
  },

  [constants.CREATE_WELCOME_MESSAGE_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.CREATE_WELCOME_MESSAGE_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      welcomeMessagesById: {
        ...state.welcomeMessagesById,
        [action.payload.triggerId]: {
          ...action.payload,
        },
      },
    };
  },

  [constants.DELETE_WELCOME_MESSAGE_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.DELETE_WELCOME_MESSAGE_SUCCESS](state, action) {
    const welcomeMessagesById = { ...state.welcomeMessagesById };
    delete welcomeMessagesById[action.meta.id];

    return {
      ...state,
      isLoading: false,
      welcomeMessagesById,
    };
  },
});
