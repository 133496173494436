import React from "react";
import { connect } from "react-redux";
import { Card } from "../components/shared";
import SequenceDetailForm from "../components/SequenceDetailForm";
import { push } from "react-router-redux";
import { createSequence } from "../actions/sequences";

class NewSequence extends React.Component {
  state = {
    name: "",
  };

  handleCreateSequence = e => {
    e.preventDefault();

    const { name } = this.state;
    const { conversationId } = this.props;

    this.props
      .createSequence({ name, conversationId })
      .then(() => this.props.push(this.props.match.url.replace("/new", "")));
  };

  onChange = e => this.setState({ name: e.target.value });

  render() {
    return (
      <div>
        <h1 className="page-title">New Sequence</h1>
        <br />
        <Card>
          <SequenceDetailForm
            onSubmit={this.handleCreateSequence}
            name={this.state.name}
            onChange={this.onChange}
            canSubmit={!!this.state.name}
            submitLabel="Create"
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  conversationId: props.match.params.conversationId,
});

export default connect(
  mapStateToProps,
  { push, createSequence }
)(NewSequence);
