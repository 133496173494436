import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import PaginatedList from "../components/PaginatedList";
import { ContentHeader, Card } from "../components/shared";
import React, { Component } from "react";
import { fetchProducts, deleteProduct } from "../actions/inventory";
import { connect } from "react-redux";
import DeleteProductModal from "../components/DeleteProductModal";
import { Search } from "../components/shared";

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalProductId: null,
      search: ""
    };
  }

  componentDidMount() {
    this.fetch();
  }

  headers = () => {
    return [
      "Title",
      <div style={{ paddingLeft: "10px" }}>Material</div>,
      "Color",
      "Size",
      "Price",
      "Available",
      "",
    ].map(h => <span className="text-xs">{h}</span>);
  };

  fetch(page = (this.props.currentPage || 1)) {
    const search = (this.state.search || null)
    this.props.fetchProducts(page, search);
  }

  render() {
    return (
      <div>
        {this.state.deleteModalProductId ? (
          <DeleteProductModal
            onClose={() => this.setState({ deleteModalProductId: null })}
            deleteProduct={() =>
              this.props.deleteProduct(this.state.deleteModalProductId)
            }
          />
        ) : null}
        <ContentHeader left={<h1 className="page-title">Inventory</h1>} />
        <ContentHeader
          left={
            <div className="description">
              <p>Create and define products.</p>
            </div>
          }
          right={
            <button
              className="paloma-button"
              onClick={() => this.props.push("products/new")}
            >
              Add a product
            </button>
          }
        />
        <Card className="info-panel">
          <h3>Products</h3>
          <div className="table-controls">
            <Search
              className="table-controls__search"
              query={this.state.search}
              onSearch={(search) => this.setState({ search }, () => this.fetch(1))}
              placeholder="Search by title"
            />
          </div>
          <div style={{ overflowX: "auto" }}>
            <PaginatedList
              headers={this.headers()}
              controlPaginationExternally
              items={this.props.products}
              emptyMessage={this.state.search ? "We can’t find any products with that name! Try searching for another Product Title." : undefined}
              component={product => (
                <ProductRow
                  product={product}
                  onDelete={() =>
                    this.setState({ deleteModalProductId: product.productId })
                  }
                />
              )}
              onChoosePage={(page) => this.fetch(page)}
              currentPage={this.props.currentPage}
              totalPages={this.props.totalPages}
            />
          </div>
        </Card>
      </div>
    );
  }
}

const ProductRow = ({ product, onDelete }) => {
  return (
    <tr className="text-sm table__row">
      <td className="table__data">
        <a
          style={{ lineHeight: "normal" }}
          className="table__link"
          href={`products/${product.productId}`}
        >
          {product.title}
        </a>
      </td>
      <td className="table__data" style={{ paddingLeft: "10px" }}>
        {product.material || "None"}
      </td>
      <td className="table__data">{product.color || "None"}</td>
      <td className="table__data">{product.size || "None"}</td>
      <td key="color" className="table__data">
        {product.price || "None"}
      </td>
      <td className="table__data">{product.available ? "Yes" : "No"}</td>
      <td className="table__data">
        <div style={{ display: "flex" }}>
          <a
            style={{ lineHeight: "normal" }}
            className="table__link"
            href={`products/${product.productId}`}
          >
            View
          </a>

          <Link
            style={{ lineHeight: "normal" }}
            className="table__link"
            to={`products/${product.productId}/edit`}
          >
            Edit
          </Link>

          <button className="table__link" onClick={onDelete}>
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default connect(
  state => {
    const {
      inventory: { productsById, pagination, products },
    } = state;

    return {
      productsById,
      products,
      ...pagination,
    };
  },
  {
    deleteProduct,
    fetchProducts,
    push,
  }
)(Products);
