import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import makeStore, { history } from "./store";
import App from "./containers/App.js";
import { unregister } from "./registerServiceWorker";

const target = document.querySelector("#root");
const store = makeStore();

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);
unregister();
