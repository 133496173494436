import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  deleteConversationStepButton,
  putButton,
} from "../../actions/conversations";

import AddPhoneNumberButtonModal from "./AddPhoneNumberButtonModal";
import AddURLButtonModal from "./AddURLButtonModal";
import DeleteConversationStepButtonModal from "./DeleteConversationStepButtonModal";
import { SelectGlobalTriggerModal } from "../shared";

class ConversationStepButtonModal extends Component {
  static propTypes = {
    button: PropTypes.object.isRequired,
    modal: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { button, modal, onClose } = this.props;

    switch (modal) {
      case "delete":
        return (
          <DeleteConversationStepButtonModal
            handleDeleteConfirmed={this.handleDelete}
            isOpen={true}
            onClose={onClose}
          />
        );
      case "phone_number":
        return (
          <AddPhoneNumberButtonModal
            isOpen={true}
            onClose={onClose}
            currentPhoneNumber={button.phoneNumber}
            addPhoneNumberButton={this.handlePhoneNumberAdded}
          />
        );
      case "custom":
        return (
          <SelectGlobalTriggerModal
            isOpen={true}
            onClose={onClose}
            onSave={this.handleCustomPayloadAdded}
          />
        );
      case "url":
      case "login":
        return (
          <AddURLButtonModal
            isOpen={true}
            onClose={onClose}
            url={button.url}
            webviewHeightRatio={button.webviewHeightRatio}
            addUrlButton={this.handleURLAdded}
            {...button}
          />
        );
      default:
        return <div />;
    }
  }

  handleURLAdded = async (url, webviewHeightRatio, dataFieldIds, shopify) => {
    const { button, modal, putButton } = this.props;

    await putButton({
      ...button,
      button_type: modal, // 'url' or 'login'
      url,
      webviewHeightRatio,
      dataFieldIds,
      shopify,
    });
  };

  handleCustomPayloadAdded = async triggerId => {
    const customPayload = `global_trigger_${triggerId}`;
    const { button, putButton } = this.props;

    await putButton({
      ...button,
      buttonType: "custom_payload",
      customPayload,
    });
  };

  handlePhoneNumberAdded = async phoneNumber => {
    const { button, putButton } = this.props;

    await putButton({
      ...button,
      button_type: "phone_number",
      phoneNumber,
    });
  };

  handleDelete = () => {
    const {
      button: { conversationStepButtonId },
      deleteConversationStepButton,
    } = this.props;

    deleteConversationStepButton({ conversationStepButtonId });
  };
}

export default connect(
  null,
  {
    deleteConversationStepButton,
    putButton,
  }
)(ConversationStepButtonModal);
