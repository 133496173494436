import React from "react";
import PersistentMenuItem from "./PersistentMenuItem";
import { Hamburger, IconDropdown } from "./shared";

const PersistentMenu = ({
  addNewItem,
  callToActions,
  deleteCallToAction,
  globalTriggers,
  header = "Menu",
  menuId,
  menuType,
  nested,
  openMenu,
  updateCallToAction,
  uuid,
}) => {
  return (
    <div className="persistent-menu-wrapper">
      {((!nested && callToActions.length < 3) ||
        (nested && callToActions.length < 5)) && (
        <IconDropdown
          className="conversation-step__dropdown"
          dropdownOptions={[
            {
              text: "Add Menu Item",
              onClick: addNewItem,
            },
          ]}
          icon={<Hamburger />}
        />
      )}
      <div className="persistent-menu">
        <div className="header">{header}</div>
        {callToActions.map((cta, i) => (
          <PersistentMenuItem
            {...cta}
            delete={() => deleteCallToAction(i)}
            globalTriggers={globalTriggers}
            key={cta.id}
            menuId={menuId}
            menuType={menuType}
            nested={nested}
            openMenu={openMenu}
            update={updateCallToAction.bind(this, i)}
            uuid={uuid}
          />
        ))}
      </div>
    </div>
  );
};

export default PersistentMenu;
