import React from "react";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedReduxRedirect } from "redux-auth-wrapper/history4/redirect";
import { routerActions } from "react-router-redux";
import Loader from "react-loader";

const locationHelper = locationHelperBuilder({});

const authenticatedLandingPage = "/configuration";

const getRedirectLocation = ownProps => {
  const redirectParam = locationHelper.getRedirectQueryParam(ownProps);

  if (!redirectParam || redirectParam === "/") {
    return authenticatedLandingPage;
  } else {
    return redirectParam;
  }
};

export const userIsAuthenticated = connectedReduxRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/login",
  // Determine if the user is authenticated or not
  authenticatedSelector: state => state.auth.isAuthenticated,
  // Determine if the user is in the process of authenticating or not
  authenticatingSelector: state => state.auth.isLoading,
  AuthenticatingComponent: () => <Loader loaded={false} />,
  // A nice display name for this check
  wrapperDisplayName: "UserIsAuthenticated",
  redirectAction: routerActions.replace,
});

export const userIsNotAuthenticated = connectedReduxRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => getRedirectLocation(ownProps),
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // Determine if the user is authenticated or not
  authenticatedSelector: state =>
    !state.auth.isAuthenticated && !state.auth.isLoading,
  // A nice display name for this check
  wrapperDisplayName: "UserIsNotAuthenticated",
  redirectAction: routerActions.replace,
});
