import React from "react";
import { Modal } from "../shared";

class SendStepModal extends React.Component {
  state = { conversationStepId: "" };

  render = () => {
    return (
      <Modal
        title="Setup a Custom Payload Button"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className="modal__controls">
          <form
            className="modal__form modal__form--wide"
            onSubmit={this.handleSubmit}
          >
            <label className="default-form__label" htmlFor="conversationStepId">
              Conversation Step ID
            </label>
            <input
              className="default-form__input"
              type="text"
              name="conversationStepId"
              id="conversationStepId"
              onChange={e =>
                this.setState({ conversationStepId: e.target.value })
              }
              value={this.state.conversationStepId}
            />
            <div className="modal__footer">
              <button
                type="button"
                className="paloma-button paloma-button--white modal__button"
                onClick={this.props.onClose}
              >
                Cancel
              </button>
              <button
                className="paloma-button paloma-button--pink modal__button"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit(this.state.conversationStepId);
    this.setState({ conversationStepId: "" });
    this.props.onClose();
  };
}

export default SendStepModal;
