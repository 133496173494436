import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SubscriberFlowStep from "../../containers/ConversationDetail/SubscriberFlowStep";

class SubscriberFlow extends Component {
  static propTypes = {
    detourLevel: PropTypes.number,
    conversationStepIds: PropTypes.array.isRequired,
    conversationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = { detourLevel: 0 };

  render() {
    const { conversationSteps } = this.props;

    return (
      <div className="subscriber-flow">
        <h3 className="subscriber-flow__heading">Flow</h3>
        {conversationSteps.length > 0 ? (
          <StepsByDetourLevel {...this.props} />
        ) : (
          <div>No conversation steps</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    conversations: {
      conversationStepsById,
      triggerChoicesByConversationStepId,
    },
  } = state;

  const conversationSteps = ownProps.conversationStepIds
    .map(stepId => conversationStepsById[stepId])
    .filter(x => !!x);

  return {
    conversationSteps,
    triggerChoices: triggerChoicesByConversationStepId,
  };
};

export default connect(mapStateToProps)(SubscriberFlow);

export const StepsByDetourLevel = props => {
  let currentLevel = 0;
  let currentTriggerId;

  return (
    <div>
      {props.conversationSteps.map(step => {
        let toReturn = null;

        if (
          currentLevel === props.detourLevel &&
          currentTriggerId === props.selectedTriggerId
        ) {
          toReturn = (
            <div key={step.conversationStepId}>
              <SubscriberFlowStep
                {...props}
                detourLevel={currentLevel}
                conversationStep={step}
              />
            </div>
          );
        }

        if (props.triggerChoices[step.conversationStepId]) {
          currentLevel += 1;
          currentTriggerId = props.triggerChoices[step.conversationStepId];
        }
        if (step.reconnection) {
          currentLevel -= 1;
          currentTriggerId = undefined;
        }

        return toReturn;
      })}
    </div>
  );
};
