import React from "react";

import { Card } from "../../components/shared";

const ConversationDetailsPerformance = ({
  carts = [],
  hasShopifyStorefront = false,
  purchases = [],
  numRecipients,
  totalClicks,
  uniqueClicks,
  uniqueClickers = [],
  numSubscribersCompleted,
  totalConversions,
}) => {
  return (
    <div className="row">
      <Card>
        <h3>
          Completion <span className="stat">{(numSubscribersCompleted * 100.0 / numRecipients).toFixed(2)}%</span>
        </h3>
        <div className="stat-container">
          <span className="stat-header">Total</span>
          <span className="stat">{numSubscribersCompleted}</span>
        </div>
      </Card>
      {!!uniqueClicks && (
        <Card>
          <h3>
            Clicks{" "}
            <span className="stat">
              {((uniqueClickers.length * 100.0) / numRecipients).toFixed(2)}%
            </span>
          </h3>
          <div className="stat-container">
            <span className="stat-header">Total</span>
            <span className="stat">{totalClicks}</span>
          </div>
          <div className="stat-container">
            <span className="stat-header">Unique</span>
            <span className="stat">{uniqueClicks}</span>
          </div>
          <div className="stat-container">
            <span className="stat-header">Subscribers</span>
            <span className="stat">{uniqueClickers.length}</span>
          </div>
        </Card>
      )}
      {totalConversions ? (
        <Card>
          <h3>
            Total Conversions
            <span className="stat">
              {(totalConversions / numRecipients).toFixed(2) * 100}%
            </span>
          </h3>
          <div className="stat-container">
            <span className="stat-header">Total</span>
            <span className="stat">{totalConversions}</span>
          </div>
        </Card>
      ) : null}
      {hasShopifyStorefront && (
        <Card>
          <h3>
            Add-to-cart{" "}
            <span className="stat">
              {((carts.length * 100.0) / numRecipients).toFixed(2)}%
            </span>
          </h3>
          <div className="stat-container">
            <span className="stat-header">Total</span>
            <span className="stat">{carts.length}</span>
          </div>
          <div className="stat-container">
            <span className="stat-header">Unique</span>
            <span className="stat">
              {uniqueBy(carts, "subscriberId").length}
            </span>
          </div>
        </Card>
      )}
      {hasShopifyStorefront && (
        <Card>
          <h3>
            Purchases{" "}
            <span className="stat">
              {((purchases.length * 100.0) / numRecipients).toFixed(2)}%
            </span>
          </h3>
          <div className="stat-container">
            <span className="stat-header">Total</span>
            <span className="stat">{purchases.length}</span>
          </div>
          <div className="stat-container">
            <span className="stat-header">Unique</span>
            <span className="stat">
              {uniqueBy(purchases, "subscriberId").length}
            </span>
          </div>
        </Card>
      )}
    </div>
  );
};

export default ConversationDetailsPerformance;

const uniqueBy = (collection, key) => {
  let keys = [];
  return collection.reduce((acc, member) => {
    if (!keys.includes(member[key])) {
      acc.push(member);
      keys.push(member[key]);
    }
    return acc;
  }, []);
};
window.uniqueBy = uniqueBy;
