import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  fetchSequenceSettings,
  updateSequenceSettings,
} from "../actions/sequences";
import { fetchGlobalTriggers } from "../actions/global-triggers";
import { fetchVisibleConversationSteps } from "../actions/conversations";
import SequenceTargetFilter from "../components/SequenceTargetFilter";
import get from "lodash/get";
import range from "lodash/range";
import trimEnd from "lodash/trimEnd";
import capitalize from "lodash/capitalize";
import { parseEditorState } from "../utilities/editor";

class EditSequenceSettings extends React.Component {
  state = {
    settings: null,
    steps: [],
  };

  render() {
    return (
      <div>
        <h3>Settings</h3>

        <h4 style={{ fontWeight: "bold", marginBottom: "8px" }}>Targeting</h4>
        <p>Send to subscribers who match the settings below.</p>
        {this.matchSetting()}
        {this.renderTargetFilters()}
        {this.newTargetFilterBtn()}

        <h4
          style={{ fontWeight: "bold", marginBottom: "8px", marginTop: "20px" }}
        >
          Scheduling
        </h4>
        <p>Set a delay for when a subscriber should get this.</p>
        {this.renderDelay()}

        <h4
          style={{ fontWeight: "bold", marginBottom: "8px", marginTop: "20px" }}
        >
          Action
        </h4>
        <p>Send the following when a subscriber meets the above criteria.</p>
        {this.renderActions()}

        <button
          className="conversation-publisher__publish-button paloma-button--pink"
          disabled={!this.canPublish()}
          onClick={
            this.props.published ? this.props.onUnpublish : this.props.onPublish
          }
        >
          {this.props.published ? "Unpublish & Edit" : "Publish"}
        </button>
      </div>
    );
  }

  canPublish = () => {
    const { settings } = this.state;
    if (!settings) {
      return false;
    }

    const { targetFilters, actions } = settings;

    if (!targetFilters.length) {
      return false;
    }

    if (!targetFiltersAreValid(targetFilters)) {
      return false;
    }

    if (!actions.length) {
      return false;
    }

    if (!actionsAreValid(actions)) {
      return false;
    }

    return true;
  };

  static getDerivedStateFromProps(props, state) {
    if (state.settings === null && props.settings) {
      const { settings } = props;
      return { settings };
    }
    return null;
  }

  componentDidMount() {
    const { sequenceId, conversationId } = this.props;
    this.props.fetchSequenceSettings({ sequenceId });
    this.props.fetchGlobalTriggers();
    this.props.fetchSteps(conversationId).then(({ payload }) => {
      const steps = payload.steps
        .filter(step => step.messageType.toLowerCase() !== "dynamic")
        .map(({ conversationStepId, messageText }) => ({
          value: conversationStepId,
          label: parseEditorState(messageText),
        }));
      this.setState({ steps });
    });
  }

  handleSettingsUpdate = () => {
    const { sequenceId } = this.props;
    if (
      targetFiltersAreValid(this.state.settings.targetFilters) &&
      actionsAreValid(this.state.settings.actions)
    ) {
      this.props.updateSequenceSettings({
        sequenceId,
        ...this.state.settings,
      });
    }
  };

  getDelayInterval = format => {
    return get(
      {
        hours: range(1, 25),
      },
      format,
      range(0, 70, 10)
    );
  };

  getDelayFormatLabel = format => {
    const { settings } = this.state;
    if (!settings) {
      return capitalize(format);
    }
    const { delay } = settings;

    if (delay !== 1) {
      return capitalize(format);
    }

    return capitalize(trimEnd(format, "s"));
  };

  renderDelay = () => {
    const { settings } = this.state;
    if (!settings) {
      return null;
    }

    const { delay, delayFormat } = settings;
    const { published } = this.props;

    return (
      <div className="conversation-publisher__target-filter">
        <input
          type="number"
          value={delay}
          min="1"
          onChange={e => 
            this.setState(
              {
                settings: {
                  ...this.state.settings,
                  delay: e.target.value>=0 ? e.target.value : 0,
                },
              },
              this.handleSettingsUpdate
            )}
          disabled={published}
          style={{"marginRight": "20px", "border": "1px solid darkgray", "width": "250px", "borderRadius": "4px", "padding": "8px"}}
        />
        <Select.Creatable
          options={["seconds", "minutes", "hours"].map(value => ({
            value,
            label: this.getDelayFormatLabel(value),
          }))}
          value={delayFormat}
          onChange={option => {
            const { settings } = this.state;
            this.setState(
              {
                settings: {
                  ...settings,
                  delayFormat:
                    option && option.value ? option.value : "seconds",
                },
              },
              this.handleSettingsUpdate
            );
          }}
          disabled={published}
          className="conversation-publisher__target-filter__field-dropdown"
        />
      </div>
    );
  };

  renderActions = () => {
    const { settings } = this.state;
    const { globalTriggers } = this.props;

    if (!settings) {
      return null;
    }

    if (!globalTriggers.length) {
      return null;
    }

    const { actions } = settings;
    const { published } = this.props;

    return (
      <div className="conversation-publisher__target-filter">
        <Select.Creatable
          options={["trigger"].map(value => ({
            value,
            label: capitalize(value),
          }))}
          value="trigger"
          disabled
          onChange={() => ""}
          className="conversation-publisher__target-filter__field-dropdown"
        />
        <Select.Creatable
          options={this.props.globalTriggers.map(t => ({
            label: t.name,
            value: t.triggerId,
          }))}
          value={
            actions[0] && actions[0].triggerId ? actions[0].triggerId : null
          }
          onChange={option => {
            const { settings } = this.state;
            this.setState(
              {
                settings: {
                  ...settings,
                  actions: [
                    {
                      triggerId: option && option.value ? option.value : null,
                      actionType: "trigger",
                    },
                  ],
                },
              },
              this.handleSettingsUpdate
            );
          }}
          disabled={published}
          className="conversation-publisher__target-filter__field-dropdown"
        />
      </div>
    );
  };

  matchSetting = () => {
    const { settings } = this.state;
    if (!settings) {
      return null;
    }

    const { published } = this.props;

    const setSetting = value => {
      this.setState(
        {
          settings: {
            ...settings,
            matchSetting: value,
          },
        },
        this.handleSettingsUpdate
      );
    };

    const { matchSetting } = settings;

    return (
      <div className="horizontal-radio-group">
        <div className="radio-option">
          <input
            checked={matchSetting === "All"}
            className="paloma-radio"
            id="all-radio"
            name="targetFilterMatchSetting"
            onChange={() => setSetting("All")}
            type="radio"
            value="All"
            disabled={published}
          />
          <label className="paloma-radio-label" htmlFor="all-radio">
            All
          </label>
        </div>
        <div className="radio-option">
          <input
            checked={matchSetting === "Any"}
            className="paloma-radio"
            id="any-radio"
            name="targetFilterMatchSetting"
            onChange={() => setSetting("Any")}
            type="radio"
            value="Any"
            disabled={published}
          />
          <label className="paloma-radio-label" htmlFor="any-radio">
            Any
          </label>
        </div>
      </div>
    );
  };

  newTargetFilterBtn = () => {
    const { settings } = this.state;
    if (!settings) {
      return null;
    }
    const { targetFilters } = settings;
    const { published } = this.props;

    const add = e => {
      e.preventDefault();

      this.setState({
        settings: {
          ...settings,
          targetFilters: [
            ...targetFilters,
            {
              conditionDropdown: null,
              fieldDropdown: null,
              value: null,
            },
          ],
        },
      });
    };

    return (
      <button
        style={{ marginLeft: "0" }}
        className="paloma-button"
        onClick={add}
        disabled={published || !targetFiltersAreValid(settings.targetFilters)}
      >
        + Add
      </button>
    );
  };

  renderTargetFilters = () => {
    const { settings, steps } = this.state;
    if (!settings) {
      return null;
    }
    const { targetFilters, conversationId } = settings;
    const { published } = this.props;

    return (
      <div>
        {targetFilters.map((filter, i) => (
          <SequenceTargetFilter
            key={i}
            id={i}
            steps={steps}
            conversationId={conversationId}
            {...filter}
            onDelete={id => {
              const { settings } = this.state;
              const { targetFilters } = settings;
              this.setState(
                {
                  settings: {
                    ...settings,
                    targetFilters: targetFilters.filter(
                      (_, index) => index !== id
                    ),
                  },
                },
                this.handleSettingsUpdate
              );
            }}
            published={published}
            onChange={({ conditionDropdown, fieldDropdown, value }) => {
              const { settings } = this.state;
              const { targetFilters } = settings;

              this.setState(
                {
                  settings: {
                    ...settings,
                    targetFilters: targetFilters.map((filter, index) => {
                      return index !== i
                        ? filter
                        : { conditionDropdown, fieldDropdown, value };
                    }),
                  },
                },
                this.handleSettingsUpdate
              );
            }}
          />
        ))}
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    const { sequenceId, conversationId } = props;
    const settings = state.sequences.settingsById[sequenceId];

    const globalTriggers = Object.values(
      state.globalTriggers.globalTriggersById
    );

    return {
      settings,
      globalTriggers,
      conversationId: parseInt(conversationId, 10),
    };
  },
  {
    fetchSequenceSettings,
    updateSequenceSettings,
    fetchGlobalTriggers,
    fetchSteps: fetchVisibleConversationSteps,
  }
)(EditSequenceSettings);

const targetFiltersAreValid = filters => {
  if (!filters.length) {
    return true;
  }
  return filters.every(
    ({ fieldDropdown, conditionDropdown, value }) =>
      conditionDropdown && fieldDropdown && value
  );
};

const actionsAreValid = actions => {
  if (!actions.length) {
    return true;
  }

  return actions.every(({ actionType, triggerId }) => actionType && triggerId);
};
