import React from "react";

import { TagSelect } from "../shared";

export default ({
  appliedSubscriberTags,
  remainingSubscriberTagNames,
  onChangeTags,
}) => {
  const appliedTagValues = appliedSubscriberTags.map(tag => ({
    value: tag.name,
    label: tag.name,
  }));
  const tagOptions = remainingSubscriberTagNames.map(tagName => ({
    value: tagName,
    label: tagName,
  }));

  return (
    <div className="subscriber-tags">
      <div className="content-header">
        <h3>Tags</h3>
      </div>
      <TagSelect
        value={appliedTagValues}
        options={tagOptions}
        onChange={tags => onChangeTags(tags.map(tag => tag.value))}
      />
    </div>
  );
};
