import React from "react";
import { Modal } from "./shared";

const DeleteProductModal = ({ deleteProduct, onClose }) => {
  return (
    <Modal title="Delete product?" isOpen={true} onClose={onClose}>
      <div className="modal__controls">
        <button
          type="button"
          className="paloma-button modal__button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="paloma-button modal__button modal__button--red"
          onClick={async () => {
            await deleteProduct();
            onClose();
          }}
          type="submit"
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default DeleteProductModal;
