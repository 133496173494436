import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/reports";
import { composeUrl } from "../utilities/general";

export const saveReport = (reportId, params = {}) => ({
  [CALL_API]: {
    endpoint: "/api/reports/" + (reportId ? reportId : ""),
    method: (reportId ? "PUT" : "POST"),
    body: JSON.stringify(params),
    types: [
      constants.SAVE_REPORT_REQUEST,
      constants.SAVE_REPORT_SUCCESS,
      constants.SAVE_REPORT_FAILURE,
    ],
  },
});

export const fetchReport = (reportId) => ({
  [CALL_API]: {
    endpoint: `/api/reports/${reportId}`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_REPORT_REQUEST,
        meta: { reportId : reportId },
      },
      {
        type: constants.FETCH_REPORT_SUCCESS,
      },
      {
        type: constants.FETCH_REPORT_FAILURE,
        meta: { reportId : reportId },
      },
    ],
  },
});

export const fetchReports = () => ({
  [CALL_API]: {
    endpoint: `/api/reports/`,
    method: "GET",
    types: [
      constants.FETCH_REPORTS_REQUEST,
      constants.FETCH_REPORTS_SUCCESS,
      constants.FETCH_REPORTS_FAILURE,
    ],
  },
});

export const deleteReport = (reportId, params = {}) => ({
  [CALL_API]: {
    endpoint: `/api/reports/${reportId}`,
    method: "DELETE",
    types: [
      {
        type: constants.DELETE_REPORT_REQUEST,
      },
      {
        type: constants.DELETE_REPORT_SUCCESS,
        meta: { reportId : reportId },
      },
      {
        type: constants.DELETE_REPORT_FAILURE,
      },
    ],
  },
});