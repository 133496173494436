import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/welcome-messages";

export const fetchWelcomeMessages = () => ({
  [CALL_API]: {
    endpoint: "/api/welcome_messages/",
    method: "GET",
    types: [
      constants.FETCH_WELCOME_MESSAGES_REQUEST,
      constants.FETCH_WELCOME_MESSAGES_SUCCESS,
      constants.FETCH_WELCOME_MESSAGES_FAILURE,
    ],
  },
});

export const fetchWelcomeMessage = id => ({
  [CALL_API]: {
    endpoint: `/api/welcome_messages/${id}/`,
    method: "GET",
    types: [
      constants.FETCH_WELCOME_MESSAGE_REQUEST,
      constants.FETCH_WELCOME_MESSAGE_SUCCESS,
      constants.FETCH_WELCOME_MESSAGE_FAILURE,
    ],
  },
});

export const updateWelcomeMessage = (id, body) => ({
  [CALL_API]: {
    endpoint: `/api/welcome_messages/${id}/`,
    method: "PUT",
    body,
    types: [
      constants.UPDATE_WELCOME_MESSAGE_REQUEST,
      constants.UPDATE_WELCOME_MESSAGE_SUCCESS,
      constants.UPDATE_WELCOME_MESSAGE_FAILURE,
    ],
  },
});

export const createWelcomeMessage = body => ({
  [CALL_API]: {
    endpoint: "/api/welcome_messages/",
    method: "POST",
    body,
    types: [
      constants.CREATE_WELCOME_MESSAGE_REQUEST,
      constants.CREATE_WELCOME_MESSAGE_SUCCESS,
      constants.CREATE_WELCOME_MESSAGE_FAILURE,
    ],
  },
});

export const deleteWelcomeMessage = id => ({
  [CALL_API]: {
    endpoint: `/api/welcome_messages/${id}/`,
    method: "DELETE",
    types: [
      constants.DELETE_WELCOME_MESSAGE_REQUEST,
      {
        type: constants.DELETE_WELCOME_MESSAGE_SUCCESS,
        meta: { id },
      },
      constants.FETCH_WELCOME_MESSAGES_FAILURE,
    ],
  },
});

export const createConversation = (id, body) => ({
  [CALL_API]: {
    endpoint: `/api/welcome_messages/${id}/conversations`,
    method: "POST",
    body,
    types: [
      "create_conversation_request",
      "create_conversation_success",
      "create_conversation_failure",
    ],
  },
});

export const duplicateTrigger = id => ({
  [CALL_API]: {
    endpoint: `/api/welcome_messages/${id}/duplicate`,
    method: "POST",
    types: [
      constants.FETCH_WELCOME_MESSAGE_REQUEST,
      constants.FETCH_WELCOME_MESSAGE_SUCCESS,
      constants.FETCH_WELCOME_MESSAGE_FAILURE,
    ],
  },
});
