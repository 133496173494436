import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/task-failures";

export const getTaskFailures = () => ({
  [CALL_API]: {
    endpoint: "/api/task_failures",
    method: "GET",
    types: [
      constants.FETCH_TASK_FAILURES_REQUEST,
      constants.FETCH_TASK_FAILURES_SUCCESS,
      constants.FETCH_TASK_FAILURES_FAILURE,
    ],
  },
});

export const rerunTask = id => ({
  [CALL_API]: {
    endpoint: `/api/task_failures/${id}`,
    method: "POST",
    types: [
      constants.RERUN_TASK_FAILURES_REQUEST,
      constants.RERUN_TASK_FAILURES_SUCCESS,
      constants.RERUN_TASK_FAILURES_FAILURE,
    ],
  },
});

export const dismissTask = id => ({
  [CALL_API]: {
    endpoint: `/api/task_failures/${id}`,
    method: "DELETE",
    types: [
      constants.DISMISS_TASK_FAILURES_REQUEST,
      constants.DISMISS_TASK_FAILURES_SUCCESS,
      constants.DISMISS_TASK_FAILURES_FAILURE,
    ],
  },
});
