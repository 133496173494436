import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/dashboard";

export const fetchRecentConversations = () => ({
  [CALL_API]: {
    endpoint: "/api/dashboard/conversations",
    method: "GET",
    types: [
      constants.FETCH_RECENT_CONVERSATIONS_REQUEST,
      constants.FETCH_RECENT_CONVERSATIONS_SUCCESS,
      constants.FETCH_RECENT_CONVERSATIONS_FAILURE,
    ],
  },
});

export const fetchDashboardStats = () => ({
  [CALL_API]: {
    endpoint: "/api/dashboard/stats",
    method: "GET",
    types: [
      constants.FETCH_DASHBOARD_STATS_REQUEST,
      constants.FETCH_DASHBOARD_STATS_SUCCESS,
      constants.FETCH_DASHBOARD_STATS_FAILURE,
    ],
  },
});

export const fetchShopifyStats = () => ({
  [CALL_API]: {
    endpoint: "/api/dashboard/shopify_stats",
    method: "GET",
    types: [
      constants.FETCH_SHOPIFY_STATS_REQUEST,
      constants.FETCH_SHOPIFY_STATS_SUCCESS,
      constants.FETCH_SHOPIFY_STATS_FAILURE,
    ],
  },
});
