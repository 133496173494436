import createReducer from "../utilities/create-reducer";
import * as constants from "../constants/user-configurations";

const initialState = null;

export default createReducer(initialState, {
  [constants.FETCH_USER_CONFIG_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.UPDATE_USER_CONFIG_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
