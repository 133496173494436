import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/inventory";

const initialState = {
  productsById: {},
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  },
  products: [],
  isLoading: false,
  errorMessage: "",
};

export default createReducer(initialState, {
  [constants.FETCH_PRODUCTS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_PRODUCTS_SUCCESS](state, action) {
    // If we are returned an object that does not contain a product
    // return state that does not handle products, productsId or pagination
    if (action.payload.products === undefined) {
      return {
        ...state,
        isLoading: false,
        products: undefined,
      };
    } else
      return {
        ...state,
        isLoading: false,
        productsById: action.payload.products.reduce(function (
          productsById,
          product
        ) {
          return { ...productsById, [product.productId]: product };
        },
          {}),
        products: action.payload.products,
        pagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          hasNextPage: action.payload.hasNextPage,
          hasPreviousPage: action.payload.hasPreviousPage,
        },
      };
  },

  [constants.FETCH_PRODUCT_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_PRODUCT_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      productsById: {
        ...state.productsById,
        [action.payload.productId]: {
          ...action.payload,
        },
      },
    };
  },

  [constants.UPDATE_PRODUCT_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.UPDATE_PRODUCT_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      productsById: {
        ...state.productsById,
        [action.payload.productId]: {
          ...action.payload,
        },
      },
    };
  },

  [constants.CREATE_PRODUCT_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.CREATE_PRODUCT_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      productsById: {
        ...state.productsById,
        [action.payload.productId]: {
          ...action.payload,
        },
      },
    };
  },

  [constants.DELETE_PRODUCT_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.DELETE_PRODUCT_SUCCESS](state, action) {
    const productsById = { ...state.productsById };
    delete productsById[action.payload];

    return {
      ...state,
      isLoading: false,
      productsById,
    };
  },

  [constants.DUPLICATE_PRODUCT_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      productsById: {
        ...state.productsById,
        [action.payload.productId]: {
          ...action.payload,
        },
      },
    };
  },
});
