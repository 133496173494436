import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/current-organization";

import { showErrorAlert } from "./alerts";

export const fetchCurrentOrganization = () => ({
  [CALL_API]: {
    endpoint: "/api/current_organization/",
    method: "GET",
    types: [
      constants.FETCH_CURRENT_ORGANIZATION_REQUEST,
      constants.FETCH_CURRENT_ORGANIZATION_SUCCESS,
      constants.FETCH_CURRENT_ORGANIZATION_FAILURE,
    ],
  },
});

export const updateCurrentOrganization = data => ({
  [CALL_API]: {
    endpoint: "/api/current_organization/",
    method: "PUT",
    body: data,
    types: [
      constants.UPDATE_CURRENT_ORGANIZATION_REQUEST,
      constants.FETCH_CURRENT_ORGANIZATION_SUCCESS,
      constants.UPDATE_CURRENT_ORGANIZATION_FAILURE,
    ],
  },
});

export const updateCurrentOrganizationFacebookPage = (
  facebookPageName,
  facebookPageId,
  facebookPageAccessToken
) => async dispatch => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: "/api/current_organization/facebook_page/",
      method: "PUT",
      body: {
        facebookPageName,
        facebookPageId,
        facebookPageAccessToken,
      },
      types: [
        constants.UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_REQUEST,
        constants.UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_SUCCESS,
        constants.UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_FAILURE,
      ],
    },
  });

  if (actionResponse.error) {
    if (
      actionResponse.payload.status &&
      actionResponse.payload.status === 409
    ) {
      dispatch(
        showErrorAlert(
          `${facebookPageName} is already associated with another Paloma organization.`
        )
      );
    } else {
      throw new Error(actionResponse);
    }
  }

  return actionResponse;
};

export const fetchOrganizationUsers = () => ({
  [CALL_API]: {
    endpoint: "/api/current_organization/users/",
    method: "GET",
    types: [
      constants.FETCH_CURRENT_ORGANIZATION_USERS_REQUEST,
      constants.FETCH_CURRENT_ORGANIZATION_USERS_SUCCESS,
      constants.FETCH_CURRENT_ORGANIZATION_USERS_FAILURE,
    ],
  },
});

export const createUnsubscribersCustomAudience = () => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_lists/unsubscribers/custom_audience`,
    method: "POST",
    types: [
      constants.POST_UNSUBSCRIBERS_CREATE_AUDIENCE_REQUEST,
      constants.POST_UNSUBSCRIBERS_CREATE_AUDIENCE_SUCCESS,
      constants.POST_UNSUBSCRIBERS_CREATE_AUDIENCE_FAILURE,
    ],
  },
});

