import React from "react";
import PropTypes from "prop-types";
import Segment from "./Segment";

const Segments = ({ matchSetting, segments, hideHeader=false }) => {
  if (segments && segments.length) {
    return (
      <div>
        {!hideHeader && (
          <div>
            <strong>Segmentation</strong>
          </div>
        )}
        <label>
          <input type="radio" checked="checked" disabled={true} />
          {matchSetting}
        </label>
        {segments.map((segment, i) => (
          <Segment key={segment.targetFilterId || i} {...segment} />
        ))}
      </div>
    );
  } else {
    return <div />;
  }
};

Segments.defaultProps = { matchSetting: "Any" };
Segments.propTypes = {
  matchSetting: PropTypes.string,
  segments: PropTypes.array.isRequired,
};

export default Segments;
