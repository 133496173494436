import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/dynamic-steps";

export const createDynamicStep = id => ({
  [CALL_API]: {
    endpoint: `/api/dynamic_steps/${id}`,
    method: "POST",
    types: [
      constants.NEW_DYNAMIC_STEP_REQUEST,
      constants.NEW_DYNAMIC_STEP_SUCCESS,
      constants.NEW_DYNAMIC_STEP_FAILURE,
    ],
  },
});

export const pasteDynamicStep = id => ({
  [CALL_API]: {
    endpoint: `/api/dynamic_steps/paste/${id}`,
    method: "POST",
    types: [
      constants.NEW_DYNAMIC_STEP_REQUEST,
      constants.NEW_DYNAMIC_STEP_SUCCESS,
      constants.NEW_DYNAMIC_STEP_FAILURE,
    ],
  },
});

export const createDynamicStepCriteria = (
  dynamicStepId,
  stepId,
  value,
  replace
) => ({
  [CALL_API]: {
    body: { criteria: { [stepId]: value }, replace },
    endpoint: `/api/dynamic_steps/${dynamicStepId}/criteria/`,
    method: "POST",
    types: [
      constants.NEW_DYNAMIC_STEP_CRITERIA_REQUEST,
      constants.NEW_DYNAMIC_STEP_CRITERIA_SUCCESS,
      constants.NEW_DYNAMIC_STEP_CRITERIA_FAILURE,
    ],
  },
});

export const deleteDynamicStepCriteria = (dynamicStepId, stepId) => ({
  [CALL_API]: {
    body: { stepId },
    endpoint: `/api/dynamic_steps/${dynamicStepId}/criteria/`,
    method: "DELETE",
    types: [
      constants.DELETE_DYNAMIC_STEP_CRITERIA_REQUEST,
      constants.DELETE_DYNAMIC_STEP_CRITERIA_SUCCESS,
      constants.DELETE_DYNAMIC_STEP_CRITERIA_FAILURE,
    ],
  },
});

export const deleteDynamicStep = id => ({
  [CALL_API]: {
    endpoint: `/api/dynamic_steps/${id}`,
    method: "DELETE",
    types: [
      constants.DELETE_DYNAMIC_STEP_REQUEST,
      constants.DELETE_DYNAMIC_STEP_SUCCESS,
      constants.DELETE_DYNAMIC_STEP_FAILURE,
    ],
  },
});
