import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/subscriber-tags";

export const fetchSubscriberTags = () => ({
  [CALL_API]: {
    endpoint: "/api/subscriber_tags/",
    method: "GET",
    types: [
      constants.FETCH_SUBSCRIBER_TAGS_REQUEST,
      constants.FETCH_SUBSCRIBER_TAGS_SUCCESS,
      constants.FETCH_SUBSCRIBER_TAGS_FAILURE,
    ],
  },
});

export const createSubscriberTag = payload => ({
  [CALL_API]: {
    endpoint: "/api/subscriber_tags/",
    method: "POST",
    body: JSON.stringify({ subscriber_tag: payload }),
    types: [
      constants.CREATE_SUBSCRIBER_TAG_REQUEST,
      constants.CREATE_SUBSCRIBER_TAG_SUCCESS,
      constants.CREATE_SUBSCRIBER_TAG_FAILURE,
    ],
  },
});

export const deleteSubscriberTag = subscriberTagId => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_tags/${subscriberTagId}/`,
    method: "DELETE",
    types: [
      constants.DELETE_SUBSCRIBER_TAG_REQUEST,
      {
        type: constants.DELETE_SUBSCRIBER_TAG_SUCCESS,
        meta: { subscriberTagId },
      },
      constants.DELETE_SUBSCRIBER_TAG_FAILURE,
    ],
  },
});
