import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

import { fetchReports, deleteReport } from "../../actions/reports";

import PaginatedList from "../../components/PaginatedList";
import { 
  ContentHeader, 
  DeleteConfirmationModal,
  Card,
} from "../../components/shared";

class ReportList extends React.Component {
  state = {
    confirmDeleteId: null
  }

  componentDidMount() {
    this.props.fetchReports()
  }

  deleteReport() {
    if(this.state.confirmDeleteId) {
      this.props.deleteReport(this.state.confirmDeleteId)
      this.setState({ confirmDeleteId: null })
    }
  }

  render() {
    return (
      <div>
        <ContentHeader 
          left={<h1 className="page-title">Reports</h1>}
          right={
            <div className="subscriber-list-buttons">
              <Link
                className="paloma-button"
                to={`/reports/new`}
              >
                New Report
              </Link>
            </div>
          }
        />
        <Card className="list">
          <PaginatedList
            className="reportsList"
            headers={["Title", ""]}
            items={Object.keys(this.props.reports).filter((k) => (k !== "latest")).map((k) => (this.props.reports[k])) || []}
            component={report => (
              <tr
                className={"table__row", "text-sm"}
                key={report.reportId}
              >
                <td key="name" className="table__data">
                  <a
                    className="table__link"
                    href={`/reports/${report.reportId}`}
                  >
                    {report.title || `Report #${report.reportId}`}
                  </a>
                </td>
                <td key="delete" className="table__data time text-xs">
                  <a className="table__link" onClick={() => this.setState({ confirmDeleteId: report.reportId })}>
                    Delete
                  </a>
                </td>
              </tr>
            )}
          />
        </Card>
        {this.state.confirmDeleteId && (
          <DeleteConfirmationModal
            title={"Delete this report?"}
            onClose={() => this.setState({ confirmDeleteId: null })}
            handleDelete={() => this.deleteReport()}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  currentOrganization: state.currentOrganization,
});

export default connect(
  mapStateToProps,
  { fetchReports, deleteReport }
)(ReportList);