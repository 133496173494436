import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "react-loader";
import { loadStripe } from '@stripe/stripe-js';

import {
  fetchCurrentOrganization,
  updateCurrentOrganizationFacebookPage,
  updateCurrentOrganization,
} from "../../actions/current-organization";
import {
  importProductsFromShopify
} from "../../actions/inventory";
import {
  createPaymentSession
} from "../../actions/payments";
import { showSuccessAlert, showErrorAlert } from "../../actions/alerts";
import { updateShopifyConfig } from "../../actions/shopify-admin-api-key";
import {
  deleteMessengerProfile,
  fetchMessengerProfile,
  updateMessengerProfile,
} from "../../actions/messenger-profile";
import ShopifyConfig from "../../components/ShopifyConfig";
import {
  Card,
  ContentHeader,
  FacebookLoginButton,
  Placeholder,
  SelectOptionModal,
  Toggle,
} from "../../components/shared";
import { MatchingResponseItem } from "../../components/GlobalTriggerDetail/MatchingResponses";

import AccountForm from "../AccountForm";
import SubscribersNotificationConfig from "./SubscribersNotification";
import OrganizationFieldConfig from "./OrganizationFields";
import isEmpty from "lodash/isEmpty";
import { connectToAdAccount } from "../../utilities/facebook";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class Configuration extends React.Component {
  state = {
    facebookPageOptions: [],
    showSelectFacebookPageModal: false,
    newWhitelistedDomain: false,
    urlTrackingEnabled: false,
    isSelectingAdAccount: false,
  };

  componentWillMount() {
    this.props.fetchCurrentOrganization();
    this.props.fetchMessengerProfile();
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  componentWillReceiveProps(nextProps) {
    const state = {};

    if (nextProps.hasGetStartedButton !== this.state.hasGetStartedButton) {
      state.hasGetStartedButton = nextProps.hasGetStartedButton;
    }

    if (nextProps.urlTrackingEnabled !== this.state.urlTrackingEnabled) {
      state.urlTrackingEnabled = nextProps.urlTrackingEnabled;
    }

    if (nextProps.webhookUrl !== this.state.webhookUrl) {
      state.webhookUrl = nextProps.webhookUrl;
    }

    if (!isEmpty(state)) {
      this.setState({ ...state });
    }
  }

  updateWhitelistedDomains = async (id, domain) => {
    const { whitelistedDomains } = this.props;
    if (Number.isInteger(id)) {
      whitelistedDomains[id] = domain;
    } else {
      whitelistedDomains.push(domain);
      this.setState({ newWhitelistedDomain: false });
    }

    this.props.updateMessengerProfile({
      whitelistedDomains,
    });
  };

  removeWhitelistedDomain = async index => {
    const whitelistedDomains = this.props.whitelistedDomains.filter(
      (_, i) => i !== index
    );
    await this.props.updateMessengerProfile({
      whitelistedDomains,
    });
  };

  importShopifyInventory = () => {
    this.props.importProductsFromShopify().then(res => {
      if(res.error){
        this.props.showErrorAlert(res.payload.response.message)
      } else{
        this.props.showSuccessAlert("Process can take up to 5 minutes. Grab a coffee and check the Inventory tab later.")
      }
    })
  }

  render() {
    const { newWhitelistedDomain } = this.state;

    if(this.props.redirect) {
      window.location.href = this.props.redirect
    }

    return (
      <Loader loaded={!this.props.isLoading} loadedClassName="configuration">
        <ContentHeader 
        left={<h1 className="page-title">Configuration</h1>} 
        right={
          <button
            className="paloma-button"
            onClick={() => this.props.createPaymentSession(window.location.href)}
          >
            Billing
          </button>
        }/>
        <div>
          <Card className="info-panel">
            <h3 className="configuration__section-header">Facebook</h3>
            {this.facebookPageDetails()}
          </Card>
          <Card className="info-panel">
            <h3>Welcome Screen</h3>
            <div className="get-started">
              <label htmlFor="get_started">Get Started Button</label>

              <Toggle
                className="get-started__toggle square"
                id="get_started"
                offLabel="Off"
                onLabel="On"
                onChange={this.handleGetStartedToggle}
                checked={this.state.hasGetStartedButton}
              />
            </div>
          </Card>
          <Card className="info-panel">
            <h3>Account</h3>
            <AccountForm />
          </Card>
          <Card className="info-panel">
            <h3>URL Tracking</h3>
            <div className="get-started">
              <label style={{ marginRight: "20px" }} htmlFor="url_tracking">
                Enable URL Tracking
              </label>

              <Toggle
                className="url-tracking__toggle square"
                id="url_tracking"
                offLabel="Off"
                onLabel="On"
                onChange={this.handleUrlTrackingToggle}
                checked={this.state.urlTrackingEnabled}
              />
            </div>
          </Card>

          <Card className="info-panel">
            <h3>
              <label 
                htmlFor="webhook-endpoint-url"
                className="default-form__label"
              >
                Webhook Endpoint URL
              </label>
            </h3>
            <input
              className="default-form__input"
              id="webhook-endpoint-url"
              value={this.state.webhookUrl}
              type="text"
              onChange={e =>
                this.handleChange("webhookUrl", e.target.value)
              }
            />
            <button
              disabled={this.props.webhookUrl == this.state.webhookUrl}
              onClick={() => this.props.updateCurrentOrganization({ currentOrganization: { webhookUrl: this.state.webhookUrl || null }})}
              className="paloma-button paloma-button--pink"
            >
              Save
            </button>
          </Card>
         
            <ShopifyConfig
              adminApiKey={this.props.shopifyAdminApiKey}
              storefrontAccessToken={this.props.shopifyStorefrontAccessToken}
              storefrontSlug={this.props.shopifyStorefrontSlug}
              showSuccessAlert={this.props.showSuccessAlert}
              updateShopifyConfig={this.props.updateShopifyConfig}
              importShopifyInventory={this.importShopifyInventory}
            />
          
          <SubscribersNotificationConfig />
          <OrganizationFieldConfig />
          <Card className="info-panel">
            <h3>Whitelisted Domains</h3>
            <ContentHeader
              left={
                <div className="description">
                  <p>
                    Web domains that are accessible in the Messenger webview.
                  </p>
                </div>
              }
              right={
                <button
                  className="paloma-button"
                  onClick={() =>
                    !newWhitelistedDomain &&
                    this.setState({ newWhitelistedDomain: true })
                  }
                >
                  + New
                </button>
              }
            />
            {newWhitelistedDomain && (
              <MatchingResponseItem
                id={null}
                isEditing
                key="new-whitelist-domain"
                matchingResponse=""
                onSave={this.updateWhitelistedDomains}
                onDelete={() => this.setState({ newWhitelistedDomain: false })}
              />
            )}
            {this.props.whitelistedDomains.map((domain, i) => (
              <MatchingResponseItem
                id={i}
                key={i}
                matchingResponse={domain}
                onSave={this.updateWhitelistedDomains}
                onDelete={this.removeWhitelistedDomain}
                deleteLabel="Remove"
                deleteConfirmationText="Remove Whitelisted Domain?"
              />
            ))}
          </Card>
        </div>

        <SelectOptionModal
          title={`Select an ${this.state.isSelectingAdAccount ? "Ad Account" : "Page"} to connect to`}
          saveButtonText="Connect"
          isOpen={this.state.showSelectFacebookPageModal}
          onClose={this.handleCloseSelectFacebookPageModal}
          onSave={this.handleSelectFacebookPage}
          options={this.state.facebookPageOptions}
        />
        <br />
        <br />
        <div style={{ marginRight: "auto", marginLeft: "auto" }}>
          By using this web application you are agreeing to the Paloma®{" "}
          <span>
            <a
              href="https://www.getpaloma.com/terms/"
              style={{ color: "#BA2A6B" }}
            >
              Terms of Service
            </a>
          </span>{" "}
          and{" "}
          <span>
            <a
              style={{ color: "#BA2A6B" }}
              href="https://www.getpaloma.com/privacy/"
            >
              Privacy Policy
            </a>
            .
          </span>
        </div>
      </Loader>
    );
  }

  handleFacebookLogin = ({ edges: pages }) => {
    if (!pages.length) {
      return;
    }

    this.setState({
      facebookPageOptions: pages.map(page => ({
        name: page.name,
        value: page,
      })),
      showSelectFacebookPageModal: true,
      isSelectingAdAccount: false,
    });
  };

  handleSelectAdAccount = ({ edges: adAccounts, accessToken }) => {
    if (!adAccounts.length) {
      return;
    }

    this.setState({
      facebookPageOptions: adAccounts.map(account => {
        const disp_name = account.business_name || account.name
        return {  
          name: account.account_id + (disp_name ? ` (${disp_name})` : ''),
          value: { id: account.account_id, accessToken },
        }
      }),
      showSelectFacebookPageModal: true,
      isSelectingAdAccount: true,
    });
  };

  handleSelectFacebookPage = async selection => {
    if(this.state.isSelectingAdAccount) {
      await this.props.updateCurrentOrganization({ currentOrganization: { 
        facebookAdAccountId: selection.id,
        facebookMarketingAccessToken: selection.accessToken
      }})
    } else {
      await this.props.updateCurrentOrganizationFacebookPage(
        selection.name,
        selection.id,
        selection.access_token
      );
    }
    this.handleCloseSelectFacebookPageModal();
    this.props.fetchCurrentOrganization();
  };

  handleDisconnectFacebookPage = () => {
    this.props.updateCurrentOrganizationFacebookPage("", "", "");
  };

  handleCloseSelectFacebookPageModal = () => {
    this.setState({
      facebookPageOptions: [],
      showSelectFacebookPageModal: false,
    });
  };

  handleGetStartedToggle = checked => {
    if (checked) {
      this.setState({ hasGetStartedButton: true });
      this.props.updateMessengerProfile({
        get_started: { payload: "get_started" },
      });
    } else {
      this.setState({ hasGetStartedButton: false });
      this.props.deleteMessengerProfile({ fields: ["get_started"] });
    }
  };

  handleUrlTrackingToggle = urlTrackingEnabled => {
    this.props
      .updateCurrentOrganization({
        currentOrganization: {
          urlTrackingEnabled,
        },
      })
      .then(() => this.setState({ urlTrackingEnabled }));
  };

  facebookPageDetails = () => {
    if (this.props.hasFacebookPage) {
      return (
        <div className="configuration__facebook-page-details">
          <div style={{ paddingBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="configuration__facebook-page-name">
                Page: {this.props.facebookPageName}
              </span>
              <div style={{ padding: "5px" }}></div>
              <span className={`configuration__facebook-page-name ${this.props.facebookAdAccountId ? "" : "configuration__facebook-page-name-disabled"}`}>
                Ad Account: {this.props.facebookAdAccountId ?? "None"}
              </span>
            </div>
            <button
              className="paloma-button paloma-button--pink"
              onClick={() => connectToAdAccount().then(this.handleSelectAdAccount)}
            >
              {this.props.facebookAdAccountId ? "Change" : "Link" } Ad Account
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <Placeholder>
          <span style={{ paddingBottom: "30px" }}>
            Sign in with Facebook to select a page to message through.
          </span>
          <FacebookLoginButton
            className="paloma-button paloma-button--pink"
            text="Connect a page"
            onFacebookLogin={this.handleFacebookLogin}
          />
        </Placeholder>
      );
    }
  };
}

const mapStateToProps = state => ({
  ...state.currentOrganization,
  whitelistedDomains: state.messengerProfile.whitelistedDomains || [],
  currentUser: state.currentUser.currentUser,
  hasGetStartedButton: !!state.messengerProfile.getStarted,
  redirect: state.payments.billingSessionUrl,
});

export default connect(mapStateToProps, {
  deleteMessengerProfile,
  fetchCurrentOrganization,
  fetchMessengerProfile,
  showSuccessAlert,
  showErrorAlert,
  updateCurrentOrganizationFacebookPage,
  updateCurrentOrganization,
  updateMessengerProfile,
  updateShopifyConfig,
  importProductsFromShopify,
  createPaymentSession,
})(Configuration);
