import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  createEmailNotification,
  deleteEmailNotification,
  fetchEmailNotification,
} from "../../actions/email-notifications";
import { showSuccessAlert } from "../../actions/alerts";
import {
  createManualMessagingActivation,
  deleteManualMessagingActivation,
  fetchManualMessagingActivation,
} from "../../actions/manual-messaging-activations";
import {
  createThreadControlPass,
  deleteThreadControlPass,
  fetchThreadControlPass,
} from "../../actions/thread-control-passes";
import {
  fetchAssignSubscriberOwner,
  createAssignSubscriberOwner,
  deleteAssignSubscriberOwner,
} from "../../actions/assign_subscriber_owner";

import ContentHeader from "../../components/GlobalTriggerDetail/ContentHeader";
import {
  MatchingResponses,
  GlobalTriggerConversations,
} from "../../components/GlobalTriggerDetail";
import { Card, DeleteGlobalTriggerModal } from "../../components/shared";
import GlobalTriggerToggle from "../../components/GlobalTriggerDetail/GlobalTriggerToggle";
import GlobalTriggerAssignSubscriberOwner from "../../components/GlobalTriggerDetail/GlobalTriggerAssignSubscriberOwner";
import AdSourceSignup from "../../components/GlobalTriggerDetail/AdSourceSignup";

import {
  duplicateTrigger,
  fetchGlobalTrigger,
  putGlobalTrigger,
} from "../../actions/global-triggers";

class GlobalTriggerDetailContainer extends Component {
  state = {
    displayDeleteModal: false,
    redirectUrl: null,
  };

  componentDidMount() {
    this.fetchData(this.props.globalTriggerId);
  }

  fetchData = id => {
    this.props.fetchGlobalTrigger(id);
    this.props.fetchEmailNotification(id);
    this.props.fetchManualMessagingActivation(id);
    this.props.fetchThreadControlPass(id);
    this.props.fetchAssignSubscriberOwner(id);
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.redirectUrl) this.setState({ redirectUrl: null });
    if (this.props.globalTriggerId !== nextProps.globalTriggerId) {
      this.fetchData(nextProps.globalTriggerId);
    }
  }

  render() {
    const {
      globalTrigger,
      globalTriggerId,
      emailNotification,
      threadControlPass,
      manualMessagingActivation,
      assignSubscriberOwner,
    } = this.props;
    const { displayDeleteModal, redirectUrl } = this.state;

    if (redirectUrl) {
      return <Redirect push to={redirectUrl} />;
    }

    const triggerName = this.props.globalTrigger.name;
    const webhookEnabled = this.props.globalTrigger.webhookEnabled;
    const isUnsubscribe =
      triggerName &&
      triggerName.toLowerCase().includes("unsubscribe") &&
      !globalTrigger.deletable;

    return (
      <div className="global-trigger-detail">
        <ContentHeader
          duplicateTrigger={this.onDuplicate}
          onCopy={() => this.props.showSuccessAlert("Copied!")}
          globalTrigger={globalTrigger}
          showDeleteModal={this.showDeleteModal}
        />
        <div className="global-trigger-detail-actions-wrapper">
          <Card className="info-panel">
            <GlobalTriggerToggle
              onToggle={this.updateGlobalTriggerEmailNotification}
              isToggled={emailNotification}
              title="Email Notifications"
              bodyText="I would like to receive a notification when someone sends a matching
      response to this trigger"
            />
          </Card>
          {!isUnsubscribe && (
            <Card className="info-panel">
              <GlobalTriggerToggle
                onToggle={this.updateGlobalTriggerManualMessagingActivation}
                isToggled={manualMessagingActivation}
                title="Turn on Live Chat"
                bodyText="I would like live chat to be turned on when someone sends a matching response to this trigger"
              />
            </Card>
          )}
          {!isUnsubscribe && (
            <Card className="info-panel">
              <GlobalTriggerToggle
                onToggle={this.updateGlobalTriggerThreadControlPass}
                isToggled={threadControlPass}
                title="Pass Control to Another Application"
                bodyText="I would like to pass control to another application when someone sends a matching response to this trigger"
              />
            </Card>
          )}
          {!isUnsubscribe && (
            <Card className="info-panel">
              <AdSourceSignup globalTrigger={globalTrigger} />
            </Card>
          )}
          <Card className="info-panel">
            <GlobalTriggerToggle
              onToggle={() => this.handleChangeWebhookEnabled(!webhookEnabled)}
              isToggled={webhookEnabled}
              title="Send Webhook on Completion"
              bodyText="I would like to recieve a webhook API call when a user completes the active conversation for this trigger"
            />
          </Card>
          <Card className="info-panel">
            <GlobalTriggerAssignSubscriberOwner
              onCreate={this.createGlobalTriggerAssignSubscriberOwner}
              onRemove={this.removeGlobalTriggerAssignSubscriberOwner}
              assignSubscriberOwner={assignSubscriberOwner}
            />
          </Card>
        </div>
        <Card className="info-panel" loading={globalTrigger.isLoading}>
          <GlobalTriggerConversations
            globalTriggerId={globalTriggerId}
            activeConversation={globalTrigger.activeConversation}
            inactiveConversations={globalTrigger.inactiveConversations}
          />
        </Card>
        <Card>
          <MatchingResponses
            globalTrigger={globalTrigger}
            onDeleteExistingMatchingResponse={
              this.handleDeleteExistingMatchingResponse
            }
            onSaveExistingMatchingResponse={
              this.handleSaveExistingMatchingResponse
            }
            onSaveNewMatchingResponse={this.handleSaveNewMatchingResponse}
          />
        </Card>
        {displayDeleteModal && (
          <DeleteGlobalTriggerModal
            globalTrigger={globalTrigger}
            onClose={this.hideDeleteModal}
            onDelete={this.onDelete}
          />
        )}
      </div>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => this.setState({ displayDeleteModal: false });

  updateGlobalTriggerEmailNotification = () => {
    const { globalTrigger, emailNotification } = this.props;
    if (!!emailNotification) {
      this.props.deleteEmailNotification(globalTrigger.triggerId);
    } else {
      this.props.createEmailNotification(globalTrigger.triggerId);
    }
  };

  updateGlobalTriggerManualMessagingActivation = () => {
    const { globalTrigger, manualMessagingActivation } = this.props;
    if (!!manualMessagingActivation) {
      this.props.deleteManualMessagingActivation(globalTrigger.triggerId);
    } else {
      this.props.createManualMessagingActivation(globalTrigger.triggerId);
    }
  };

  updateGlobalTriggerThreadControlPass = () => {
    const { globalTrigger, threadControlPass } = this.props;
    if (!!threadControlPass) {
      this.props.deleteThreadControlPass(globalTrigger.triggerId);
    } else {
      this.props.createThreadControlPass(globalTrigger.triggerId);
    }
  };

  createGlobalTriggerAssignSubscriberOwner = ownerId => {
    const { globalTriggerId } = this.props;
    if (ownerId) {
      return this.props.createAssignSubscriberOwner({
        triggerId: globalTriggerId,
        ownerId,
      });
    }
  };

  removeGlobalTriggerAssignSubscriberOwner = () => {
    const { globalTriggerId, deleteAssignSubscriberOwner } = this.props;

    return deleteAssignSubscriberOwner(globalTriggerId);
  };

  onDelete = () => this.setState({ redirectUrl: "/global" });

  onDuplicate = async () => {
    const { globalTriggerId: triggerId } = this.props;

    const {
      payload: { triggerId: duplicateTriggerId },
    } = await this.props.duplicateTrigger(triggerId);

    const redirectUrl = `/global/${duplicateTriggerId}`;

    this.setState({ redirectUrl });
  };

  handleDeleteExistingMatchingResponse = index => {
    const { globalTrigger, putGlobalTrigger } = this.props;

    const { matchingResponses } = globalTrigger;
    matchingResponses.splice(index, 1);

    putGlobalTrigger({
      ...globalTrigger,
      matchingResponses,
    });
  };

  handleSaveExistingMatchingResponse = (index, matchingResponse) => {
    const { globalTrigger, putGlobalTrigger } = this.props;

    const { matchingResponses } = globalTrigger;
    matchingResponses[index] = matchingResponse;

    putGlobalTrigger({
      ...globalTrigger,
      matchingResponses,
    });
  };

  handleChangeWebhookEnabled = (webhookEnabled) => {
    const { globalTrigger, putGlobalTrigger } = this.props;

    putGlobalTrigger({
      ...globalTrigger,
      webhookEnabled,
    });
  };

  handleSaveNewMatchingResponse = matchingResponse => {
    const { globalTrigger, putGlobalTrigger } = this.props;

    const { matchingResponses } = globalTrigger;
    matchingResponses.unshift(matchingResponse);

    putGlobalTrigger({
      ...globalTrigger,
      matchingResponses,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { triggerId: globalTriggerId },
    },
  } = ownProps;

  const globalTrigger = {
    isLoading: true,
    tempMatchingResponses: [],
    ...state.globalTriggers.globalTriggersById[globalTriggerId],
  };

  const emailNotification =
    state.emailNotifications.byTriggerId[globalTriggerId];

  const manualMessagingActivation =
    state.manualMessagingActivations.byTriggerId[globalTriggerId];

  const threadControlPass =
    state.threadControlPasses.byTriggerId[globalTriggerId];

  const assignSubscriberOwner =
    state.assignSubscriberOwner.byTriggerId[globalTriggerId];

  return {
    emailNotification,
    globalTrigger,
    globalTriggerId,
    manualMessagingActivation,
    threadControlPass,
    assignSubscriberOwner,
  };
};

export default connect(
  mapStateToProps,
  {
    createAssignSubscriberOwner,
    createEmailNotification,
    createManualMessagingActivation,
    createThreadControlPass,
    deleteAssignSubscriberOwner,
    deleteEmailNotification,
    deleteManualMessagingActivation,
    deleteThreadControlPass,
    duplicateTrigger,
    fetchAssignSubscriberOwner,
    fetchEmailNotification,
    fetchGlobalTrigger,
    fetchManualMessagingActivation,
    fetchThreadControlPass,
    putGlobalTrigger,
    showSuccessAlert,
  }
)(GlobalTriggerDetailContainer);
