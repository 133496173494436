import React from "react";
import { Card } from "../components/shared";
import { createProduct } from "../actions/inventory";
import ProductDetailForm from "../components/ProductDetailForm";
import { connect } from "react-redux";
import { push } from "react-router-redux";

class NewProduct extends React.Component {
  state = {
    title: "",
    price: "",
    subtitle: "",
    url: "",
    image: "",
    buttonText: "",
  };

  handleCreateProduct = e => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("title", this.state.title);
    fd.append("subtitle", this.state.subtitle);
    fd.append("price", this.state.price);
    fd.append("url", this.state.url);
    fd.append("button_text", this.state.buttonText);
    fd.append("image", this.state.image);

    this.props
      .createProduct(fd)
      .then(() => this.props.push(this.props.match.url.replace("/new", "")));
  };

  onChange = (k, v) => this.setState({ ...this.state, [k]: v });

  canSubmit = () => {
    const { title, url } = this.state;
    return !!(title && url);
  };

  render() {
    return (
      <div>
        <h1 className="page-title">New Product</h1>
        <br />
        <Card>
          <ProductDetailForm
            onCancel={() =>
              this.props.push(this.props.match.url.replace("/new", ""))
            }
            onSubmit={this.handleCreateProduct}
            {...this.state}
            onChange={this.onChange}
            canSubmit={this.canSubmit()}
            submitLabel="Create"
          />
        </Card>
      </div>
    );
  }
}

export default connect(null, { push, createProduct })(NewProduct);
