import React from "react";

import { SubscriberFieldModal } from "./";
import { ContentHeader } from "../shared";

const SubscriberFieldListItem = ({
  subscriberFieldId,
  name,
  value,
  onClick,
}) => {
  return (
    <li
      className="subscriber-fields__fields-list-item"
      onClick={() => onClick(subscriberFieldId)}
    >
      <span className="subscriber-fields__field-name">{name}: </span>
      <span className="subscriber-fields__field-value">{value}</span>
    </li>
  );
};

export default class SubscriberFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingNewField: false,
      isEditing: false,
      subscriberFieldIdToEdit: null,
    };

    this.handleClickAddField = this.handleClickAddField.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleClickAddField() {
    this.setState({
      isAddingNewField: true,
      isEditing: false,
      subscriberFieldIdToEdit: null,
    });
  }

  handleSave = subscriberField => {
    this.handleCloseModal();
    this.props.onSave(subscriberField);
  };

  handleClickEdit(subscriberFieldId) {
    this.setState({
      isAddingNewField: false,
      isEditing: true,
      subscriberFieldIdToEdit: subscriberFieldId,
    });
  }

  handleCloseModal() {
    this.setState({
      isAddingNewField: false,
      isEditing: false,
      subscriberFieldIdToEdit: null,
    });
  }

  render() {
    return (
      <div className="subscriber-fields">
        <ContentHeader
          left={<h3>Fields</h3>}
          right={
            <button
              className="paloma-button"
              onClick={this.handleClickAddField}
            >
              Add
            </button>
          }
        />

        <ul className="subscriber-fields__fields-list">
          {Object.values(this.props.subscriberFieldsById)
            .sort((a, b) => a.order - b.order)
            .map(subscriberField => (
              <SubscriberFieldListItem
                key={subscriberField.subscriberFieldId}
                subscriberFieldId={subscriberField.subscriberFieldId}
                name={subscriberField.name}
                value={subscriberField.value}
                onClick={this.handleClickEdit}
              />
            ))}
        </ul>
        {(this.state.isAddingNewField || this.state.isEditing) && (
          <SubscriberFieldModal
            name={
              (
                this.props.subscriberFieldsById[
                  this.state.subscriberFieldIdToEdit
                ] || {}
              ).name
            }
            value={
              (
                this.props.subscriberFieldsById[
                  this.state.subscriberFieldIdToEdit
                ] || {}
              ).value
            }
            isOpen={true}
            onClose={this.handleCloseModal}
            onSave={this.handleSave}
            subscriberFieldId={this.state.subscriberFieldIdToEdit}
          />
        )}
      </div>
    );
  }
}
