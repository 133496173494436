import React from "react";
import PropTypes from "prop-types";

class Notice extends React.Component {
  state = { visible: true };

  dismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const {
      type,
      headline,
      children,
      style,
      className,
      dismissible,
    } = this.props;

    if (this.state.visible === false) {
      return false;
    }

    return (
      <div className={`notice notice--${type} ${className}`} style={style}>
        {headline && (
          <div className="notice__headline">
            <strong>{headline}</strong>
          </div>
        )}
        <div className="notice__message">
          {children}
          {dismissible && (
            <span className="notice__dismiss" onClick={() => this.dismiss()}>
              &times;
            </span>
          )}
        </div>
      </div>
    );
  }
}

Notice.defaultProps = {
  className: "",
  dismissible: true,
  headline: null,
  style: {},
  type: "default",
};

Notice.propTypes = {
  className: PropTypes.string,
  dismissible: PropTypes.bool,
  headline: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
};

export default Notice;
