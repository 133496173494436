import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/links";

const initialState = {};

export default createReducer(initialState, {
  [constants.FETCH_LINK_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
