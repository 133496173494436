import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import _ from "underscore";

import {
  fetchVisibleConversationSteps,
  putButton,
  setTriggerChoice,
} from "../../actions/conversations";
import { parse } from "../../utilities/editor";

import { openMenu, closeMenu } from "../../actions/builder-menu";
import { CONVERSATION_STEP_BUTTON } from "../../constants/builder-menu";

import ConversationStepButtonModal from "../../components/ConversationDetail/ConversationStepButtonModal";
import { BuilderInput } from "../../components/ConversationDetail";
import { SeparatedButtonRow, Toggle, Modal } from "../../components/shared";

import { createButton } from "../../actions/conversations";
import { createEditorState } from "../../utilities/editor";
import MatchingResponsesModal from "../../components/MatchingResponsesModal";

class ConversationStepButtonContainer extends Component {
  constructor(props) {
    super(props);

    const {
      button: { buttonText },
    } = props;

    this.state = {
      editorState: createEditorState(buttonText),
      modal: "",
      openMatchingReponses: false,
      openFieldCapture: false,
    };

    this.putButton = _.debounce(this.props.putButton, 250);
  }

  componentWillReceiveProps(nextProps) {
    const {
      button: { buttonText, conversationStepButtonId },
    } = nextProps;

    // when would this be the case?
    if (
      this.props.button.conversationStepButtonId !== conversationStepButtonId
    ) {
      this.setState({
        editorState: createEditorState(buttonText),
      });
    }
  }

  render() {
    const { button, editable, isMenuOpen, randomized } = this.props;

    const { modal } = this.state;

    const buttons = [
      {
        text: "Detour",
        onClick: this.handleCreateTrigger,
      },
      {
        text: "Connect",
        onClick: this.handleCustomButton,
      },
      {
        text: "Action",
        nested: [
          {
            text: "URL",
            onClick: this.handleURLButton,
            selected: button.buttonType === "url",
          },
          {
            text: "Phone",
            onClick: this.handlePhoneNumberButton,
            selected: button.buttonType === "phone_number",
          },
          {
            text: "Login",
            onClick: this.handleLoginButton,
            selected: button.buttonType === "login",
          },
        ],
      },
      {
        text: "+ Button",
        onClick: () =>
          this.props.createButton({
            conversationStepId: this.props.conversationStep.conversationStepId,
          }),
      },
      {
        text: (
          <div>
            Randomize
            <Toggle
              onChange={this.toggleRandomizeButtonOrder}
              checked={randomized}
            />
          </div>
        ),
        leaveOpen: true,
        onClick: () => true,
      },
      {
        text: "+ Match Responses",
        onClick: () => this.setState({ openMatchingReponses: true }),
      },
      {
        text: "Field Capture",
        onClick: () => this.setState({ openFieldCapture: true }),
      },
      { text: "Delete", onClick: this.handleDeleteButton },
    ];

    const buttonWrapperClassName = classNames({
      "conversation-step-button__edit-button-wrapper": editable && isMenuOpen,
      "conversation-step-button__text": !editable || !isMenuOpen,
      "conversation-step-button__text--editable": editable && !isMenuOpen,
      error: Object.entries(this.props.errors).length,
    });

    const buttonTextClassName = classNames({
      "conversation-step-button__text conversation-step-button__text--selected": isMenuOpen,
      error: (this.props.errors.buttonText || "").length,
    });

    return (
      <div className="conversation-step-button">
        <div className={buttonWrapperClassName} onClick={this.handleClick}>
          <div className={buttonTextClassName}>
            <BuilderInput
              className="conversation-step-button__input"
              editorState={this.state.editorState}
              onChange={this.handleChange}
              editable={editable}
              placeholder="Write a title"
            />
          </div>
        </div>
        {editable && isMenuOpen && (
          <SeparatedButtonRow
            className="conversation-step-button__button-types"
            buttons={buttons}
          />
        )}
        <ConversationStepButtonModal
          button={button}
          modal={modal}
          onClose={this.closeModal}
        />
        <MatchingResponsesModal
          matchingResponses={this.props.button.matchingResponses}
          isOpen={this.state.openMatchingReponses}
          onClose={() => this.setState({ openMatchingReponses: false })}
          onSubmit={this.updateMatchingResponses}
        />
        {this.fieldCaptureModal()}
      </div>
    );
  }

  closeModal = () => this.setState({ modal: "" });

  handleURLButton = e => this.setState({ modal: "url" });
  handleCustomButton = e => this.setState({ modal: "custom" });
  handleLoginButton = e => this.setState({ modal: "login" });
  handlePhoneNumberButton = e => this.setState({ modal: "phone_number" });
  handleDeleteButton = e => this.setState({ modal: "delete" });

  toggleRandomizeButtonOrder = async random => {
    const {
      conversationStep: { conversationStepId, conversationId },
      toggleRandomize,
    } = this.props;

    await toggleRandomize(conversationStepId, random);
    this.props.fetchVisibleConversationSteps(conversationId);
  };

  handleChange = ({ state: editorState }) => {
    const { button } = this.props;

    this.setState({ editorState });

    if (parse(editorState) !== parse(this.state.editorState)) {
      this.putButton({
        ...button,
        buttonText: JSON.stringify(editorState.toJSON()),
      });
    }
  };

  updateMatchingResponses = matchingResponses => {
    const { button } = this.props;
    this.props.putButton({
      ...button,
      matchingResponses,
    });
  };

  handleCreateTrigger = async () => {
    const {
      button,
      conversationId,
      conversationStepId,
      fetchVisibleConversationSteps,
      setTriggerChoice,
    } = this.props;

    const response = await this.props.putButton({
      ...button,
      conversationStepId,
      buttonType: "trigger",
    });

    await setTriggerChoice({
      conversationStepId,
      triggerId:
        response.payload.conversationStepButton.conversationStepTriggerId,
    });

    fetchVisibleConversationSteps(conversationId);
  };

  handleClick = () => {
    if (this.props.editable) this.startEditing();
  };

  stopEditing = () => this.props.closeMenu();
  startEditing = () =>
    this.props.openMenu({
      id: this.props.button.conversationStepButtonId,
      type: CONVERSATION_STEP_BUTTON,
    });

  fieldCaptureModal = () => {
    const { button } = this.props;
    const onSubmit = e => {
      e.preventDefault();
      this.putButton({
        ...button,
        value: e.target.value || null,
      });
    };

    return (
      <Modal
        title="Edit Field Capture"
        isOpen={this.state.openFieldCapture}
        onClose={() => this.setState({ openFieldCapture: false })}
      >
        <div className="modal__controls">
          <form
            className="modal__form modal__form--wide"
            onSubmit={e => {
              e.preventDefault();
              this.setState({ openFieldCapture: false });
            }}
          >
            <label className="default-form__label" htmlFor="entry">
              Entry
            </label>
            <input
              className="default-form__input"
              defaultValue={this.props.button.value}
              type="text"
              name="entry"
              id="payload"
              onChange={onSubmit}
            />
            <div className="modal__footer">
              <button
                type="button"
                className="paloma-button paloma-button--white modal__button"
                onClick={() => this.setState({ openFieldCapture: false })}
              >
                Cancel
              </button>
              <button
                className="paloma-button paloma-button--pink modal__button"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    conversationStepId,
    button: { conversationStepButtonId },
  } = ownProps;

  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;

  const isMenuOpen =
    menuId === conversationStepButtonId &&
    menuType === CONVERSATION_STEP_BUTTON;

  return {
    conversationStep:
      state.conversations.conversationStepsById[conversationStepId],
    isMenuOpen,
  };
};

export default connect(mapStateToProps, {
  closeMenu,
  createButton,
  fetchVisibleConversationSteps,
  openMenu,
  putButton,
  setTriggerChoice,
})(ConversationStepButtonContainer);
