import React from "react";
import classnames from "classnames";
import { ownerLabel, SelectOwnerModal } from "../shared/SubscriberOwner";
import { connect } from "react-redux";
import { fetchOrganizationUsers } from "../../actions/current-organization";

class AssignOwner extends React.Component {
  state = {
    owner: null,
    selectOwner: false,
  };

  componentDidMount() {
    const { assignSubscriberOwner } = this.props;
    this.props.fetchOwners().then(({ payload }) => {
      if (assignSubscriberOwner) {
        this.setState({
          owner: selectOwnerbyId(payload, assignSubscriberOwner.ownerId),
        });
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.assignSubscriberOwner && props.owners) {
      if (
        !state.owner ||
        (state.owner &&
          props.assignSubscriberOwner.ownerId !== state.owner.userId)
      ) {
        return {
          ...state,
          owner: selectOwnerbyId(
            props.owners,
            props.assignSubscriberOwner.ownerId
          ),
        };
      }
    }
    return null;
  }

  handleSubmit = id => {
    this.props.onCreate(id).finally(() => {
      this.setState({
        selectOwner: false,
      });
    });
  };

  render() {
    const { onRemove, owners } = this.props;
    const { owner } = this.state;
    const label = owner ? ownerLabel(owner) : "+";
    return (
      <div id="assign-owner-trigger-action">
        <h3 style={{ marginBottom: "10px" }}>Assign Contact Owner</h3>
        <label className="toggle-wrapper">
          I would like to pass control to assign a contact owner to subscribers
          with a matching response to this trigger
        </label>
        <br />
        <br />
        <div
          className={classnames("subscriber-owner", {
            "has-owner": !!owner,
            "new-owner": !owner,
          })}
        >
          <div
            className="select-owner"
            onClick={() => this.setState({ selectOwner: true })}
          >
            <span>{label}</span>
          </div>

          <SelectOwnerModal
            owners={owners}
            isOpen={this.state.selectOwner}
            onClose={() => this.setState({ selectOwner: false })}
            onSubmit={this.handleSubmit}
            onRemove={() =>
              onRemove().finally(() =>
                this.setState({
                  owner: null,
                  selectOwner: false,
                })
              )
            }
            currentOwnerId={owner && owner.userId}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const {
      currentOrganization: { users: owners },
    } = state;

    return { owners, ...ownProps };
  },
  { fetchOwners: fetchOrganizationUsers }
)(AssignOwner);

const selectOwnerbyId = (owners, id) => owners.find(o => o.userId === id);
