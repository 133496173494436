import React from "react";
import { AddURLButtonModal } from "../components/ConversationDetail";
import { PERSISTENT_MENU_ITEM } from "../constants/builder-menu";
import { SeparatedButtonRow, SelectOptionModal } from "../components/shared";

class PersistentMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTriggerOpen: false,
      isUrlOpen: false,
      selected: false,
      ...props,
    };
  }

  select = () =>
    this.props.openMenu({
      id: this.props.id,
      type: PERSISTENT_MENU_ITEM,
    });

  render = () => {
    const isMenuOpen =
      this.props.menuId === this.props.id &&
      this.props.menuType === PERSISTENT_MENU_ITEM;
    return (
      <div>
        <div className="item">
          <input
            onFocus={this.select}
            onBlur={this.unselect}
            onChange={e => this.updateTitle(e)}
            defaultValue={this.props.title}
            placeholder="Write a title"
          />
        </div>
        {isMenuOpen && this.buttonRow()}
        <AddURLButtonModal
          addUrlButton={this.updateUrlAndWebviewHeightRatio}
          isOpen={this.state.isUrlOpen}
          onClose={this.hideAddUrlButtonModal}
          url={this.state.url}
          webviewHeightRatio={this.state.webviewHeightRatio}
        />
        <SelectOptionModal
          title="Select a trigger to link to"
          saveButtonText="Save"
          isOpen={this.state.isTriggerOpen}
          onClose={this.hideSelectTriggerConversationModal}
          onSave={this.updateGlobalTriggerId.bind()}
          options={this.props.globalTriggers.map(t => ({
            name: t.name,
            value: t.triggerId,
          }))}
          selectedOption={this.state.globalTriggerId}
        />
      </div>
    );
  };

  updateGlobalTriggerId = async globalTriggerId => {
    await this.setState({
      globalTriggerId: globalTriggerId,
    });
    this.updateJson();
  };

  updateUrlAndWebviewHeightRatio = async (
    url,
    webviewHeightRatio,
    dataFieldIds,
    shopify
  ) => {
    await this.setState({ url, webviewHeightRatio, dataFieldIds, shopify });
    this.updateJson();
  };

  updateTitle = async e => {
    await this.setState({ title: e.target.value });
    this.updateJson();
  };

  selectUrl = async () => {
    await this.setState({ type: "url" });
    this.displayAddUrlButtonModal();
    this.updateJson();
  };

  displayAddUrlButtonModal = () => this.setState({ isUrlOpen: true });
  hideAddUrlButtonModal = () => this.setState({ isUrlOpen: false });

  displaySelectTriggerConversationModal = () =>
    this.setState({ isTriggerOpen: true });
  hideSelectTriggerConversationModal = () =>
    this.setState({ isTriggerOpen: false });

  selectTrigger = async () => {
    await this.setState({ type: "postback" });
    this.displaySelectTriggerConversationModal();
    this.updateJson();
  };

  selectNavigate = async () => {
    await this.setState({
      type: "nested",
      callToActions: [{ id: this.props.uuid() }],
    });
    this.updateJson();
  };

  selectDelete = () => {
    this.props.delete();
  };

  updateJson = () => {
    this.props.update(this.toJson());
  };

  toJson = () => {
    return {
      callToActions: this.state.callToActions,
      globalTriggerId: this.state.globalTriggerId,
      id: this.state.id,
      title: this.state.title,
      type: this.state.type,
      url: this.state.url,
      webviewHeightRatio: this.state.webviewHeightRatio,
      dataFieldIds: this.state.dataFieldIds,
      shopify: this.state.shopify,
    };
  };

  buttonRow = () => {
    const buttons = [
      { text: "URL", onClick: this.selectUrl },
      { text: "Trigger", onClick: this.selectTrigger },
      { text: "Delete", onClick: this.selectDelete },
    ];
    if (!this.props.nested) {
      buttons.splice(2, 0, {
        text: "Navigate",
        onClick: this.selectNavigate,
      });
    }
    return <SeparatedButtonRow buttons={buttons} />;
  };
}

export default PersistentMenuItem;
