import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/subscriber-webhooks";

export const fetchSubscriberWebhooks = id => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_webhooks/${id}`,
    method: "GET",
    types: [
      constants.FETCH_SUBSCRIBER_WEBHOOKS_REQUEST,
      {
        type: constants.FETCH_SUBSCRIBER_WEBHOOKS_SUCCESS,
        meta: { id },
      },
      {
        type: constants.FETCH_SUBSCRIBER_WEBHOOKS_FAILURE,
        meta: { id },
      },
    ],
  },
});
