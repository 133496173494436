export const NEW_DYNAMIC_STEP_REQUEST = "NEW_DYNAMIC_STEP_REQUEST";
export const NEW_DYNAMIC_STEP_SUCCESS = "NEW_DYNAMIC_STEP_SUCCESS";
export const NEW_DYNAMIC_STEP_FAILURE = "NEW_DYNAMIC_STEP_FAILURE";

export const DELETE_DYNAMIC_STEP_REQUEST = "DELETE_DYNAMIC_STEP_REQUEST";
export const DELETE_DYNAMIC_STEP_SUCCESS = "DELETE_DYNAMIC_STEP_SUCCESS";
export const DELETE_DYNAMIC_STEP_FAILURE = "DELETE_DYNAMIC_STEP_FAILURE";

export const NEW_DYNAMIC_STEP_CRITERIA_REQUEST =
  "NEW_DYNAMIC_STEP_CRITERIA_REQUEST";
export const NEW_DYNAMIC_STEP_CRITERIA_SUCCESS =
  "NEW_DYNAMIC_STEP_CRITERIA_SUCCESS";
export const NEW_DYNAMIC_STEP_CRITERIA_FAILURE =
  "NEW_DYNAMIC_STEP_CRITERIA_FAILURE";

export const DELETE_DYNAMIC_STEP_CRITERIA_REQUEST =
  "DELETE_DYNAMIC_STEP_CRITERIA_REQUEST";
export const DELETE_DYNAMIC_STEP_CRITERIA_SUCCESS =
  "DELETE_DYNAMIC_STEP_CRITERIA_SUCCESS";
export const DELETE_DYNAMIC_STEP_CRITERIA_FAILURE =
  "DELETE_DYNAMIC_STEP_CRITERIA_FAILURE";
