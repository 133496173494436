export const FETCH_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "FETCH_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const FETCH_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "FETCH_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const FETCH_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "FETCH_MANUAL_MESSAGING_ACTIVATION_REQUEST";

export const CREATE_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "CREATE_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const CREATE_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "CREATE_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const CREATE_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "CREATE_MANUAL_MESSAGING_ACTIVATION_FAILURE";

export const DELETE_MANUAL_MESSAGING_ACTIVATION_REQUEST =
  "DELETE_MANUAL_MESSAGING_ACTIVATION_REQUEST";
export const DELETE_MANUAL_MESSAGING_ACTIVATION_SUCCESS =
  "DELETE_MANUAL_MESSAGING_ACTIVATION_SUCCESS";
export const DELETE_MANUAL_MESSAGING_ACTIVATION_FAILURE =
  "DELETE_MANUAL_MESSAGING_ACTIVATION_FAILURE";
