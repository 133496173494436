import React from "react";
import { Modal } from ".";

export default class DeleteConfirmationModal extends React.Component {
  render() {
    return (
      <Modal title={this.props.title || "Are you sure?"} isOpen={true} onClose={this.props.onClose}>
        {this.props.body &&
          <p style={{ padding: "20px", maxWidth: "400px", textAlign: "left" }}>{this.props.body}</p>
        }
        <div className="modal__controls">
          <button
            type="button"
            className="paloma-button modal__button"
            onClick={this.props.onClose}
          >
            Cancel
          </button>
          <button
            className="paloma-button modal__button paloma-button--pink"
            onClick={this.props.handleDelete}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }
}
