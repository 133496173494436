import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/thread-control-passes";

export const fetchThreadControlPass = globalTriggerId => ({
  [CALL_API]: {
    endpoint: "/api/triggers/" + globalTriggerId + "/thread_control_pass",
    method: "GET",
    types: [
      constants.FETCH_THREAD_CONTROL_PASS_REQUEST,
      constants.FETCH_THREAD_CONTROL_PASS_SUCCESS,
      constants.FETCH_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});

export const createThreadControlPass = globalTriggerId => ({
  [CALL_API]: {
    endpoint: "/api/triggers/" + globalTriggerId + "/thread_control_pass",
    method: "POST",
    types: [
      constants.CREATE_THREAD_CONTROL_PASS_REQUEST,
      constants.CREATE_THREAD_CONTROL_PASS_SUCCESS,
      constants.CREATE_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});

export const deleteThreadControlPass = globalTriggerId => ({
  [CALL_API]: {
    endpoint: "/api/triggers/" + globalTriggerId + "/thread_control_pass",
    method: "DELETE",
    types: [
      constants.DELETE_THREAD_CONTROL_PASS_REQUEST,
      constants.DELETE_THREAD_CONTROL_PASS_SUCCESS,
      constants.DELETE_THREAD_CONTROL_PASS_FAILURE,
    ],
  },
});
