import * as constants from "../constants/flash";

export const setFlash = params => ({
  type: constants.SET_FLASH,
  ...params,
});

export const clearFlash = () => ({
  type: constants.CLEAR_FLASH,
});
