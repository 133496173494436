import React from "react";

export default ({ onClick }) => (
  <div
    onClick={onClick}
    className="dropdown icon-dropdown conversation-step__dropdown"
  >
    <div className="paloma-button dropdown__button icon-dropdown__button">
      <div className="hamburger">
        <div className="hamburger__bar" />
        <div className="hamburger__bar" />
        <div className="hamburger__bar" />
      </div>
    </div>
  </div>
);
