import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/subscriber-photos";

export const fetchSubscriberPhoto = id => ({
  [CALL_API]: {
    endpoint: `/api/subscriber_photos/${id}`,
    method: "GET",
    types: [
      constants.FETCH_SUBSCRIBER_PHOTO_REQUEST,
      constants.FETCH_SUBSCRIBER_PHOTO_SUCCESS,
      constants.FETCH_SUBSCRIBER_PHOTO_FAILURE,
    ],
  },
});
