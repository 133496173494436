import React from "react";
import Select from "react-select";
import moment from "moment";
import { connect } from "react-redux";

import { PalomaDatePicker } from "../../components/shared";
import "react-datepicker/dist/react-datepicker.css";
import {
  conditionOptionsForField,
  fieldOptions,
  sourceOptions,
} from "../../utilities/target-filters";
import { fetchOrganizationUsers } from "../../actions/current-organization";

class TargetFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conditionDropdown: props.targetFilter.conditionDropdown,
      fieldDropdown: props.targetFilter.fieldDropdown,
      value: props.targetFilter.value,
    };
  }

  handleChangeConditionDropdown = (targetFilter, value) => {
    this.setState({ conditionDropdown: value });
    this.props.handleChangeConditionDropdown(targetFilter, value);
  };

  handleChangeFieldDropdown = (targetFilter, value) => {
    this.setState({ fieldDropdown: value });
    this.props.handleChangeFieldDropdown(targetFilter, value);
  };

  handleChangeValue = (targetFilter, value) => {
    this.setState({ value: value });
    this.props.handleChangeTargetFilterValue(targetFilter, value);
  };

  render = () => {
    const { targetFilter, handleDeleteTargetFilter } = this.props;

    let valueSelect;
    if (targetFilter.fieldDropdown === "Date added") {
      valueSelect = (
        <PalomaDatePicker
          selected={
            targetFilter.value ? moment(targetFilter.value, "YYYY-MM-DD") : ""
          }
          onChange={value => {
            this.handleChangeValue(targetFilter, value.format("YYYY-MM-DD"));
          }}
        />
      );
    } else if (
      targetFilter.fieldDropdown === "Added to Cart" ||
      targetFilter.fieldDropdown === "Clicked" ||
      targetFilter.fieldDropdown === "Completed" ||
      targetFilter.fieldDropdown === "Purchased" ||
      targetFilter.fieldDropdown === "Received" ||
      targetFilter.fieldDropdown === "Signup conversation" ||
      targetFilter.fieldDropdown === "Current conversation" ||
      targetFilter.fieldDropdown === "Converted"
    ) {
      valueSelect = (
        <Select.Creatable
          className="conversation-publisher__target-filter__value-select"
          defaultValue={targetFilter.value}
          value={targetFilter.value}
          options={this.props.conversations.map(c => ({
            label: c.label,
            value: c.conversationId,
          }))}
          onChange={selected => {
            this.handleChangeValue(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
      );
    } else if (targetFilter.fieldDropdown === "Received Trigger") {
      valueSelect = (
        <Select.Creatable
          className="conversation-publisher__target-filter__value-select"
          defaultValue={targetFilter.value}
          value={targetFilter.value}
          options={this.props.globalTriggers.map(t => ({
            label: t.name,
            value: t.triggerId,
          }))}
          onChange={selected => {
            this.handleChangeValue(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
      );
    } else if (targetFilter.fieldDropdown === "Status") {
      valueSelect = (
        <Select.Creatable
          className="conversation-publisher__target-filter__value-select"
          defaultValue={targetFilter.value}
          value={targetFilter.value}
          options={[
            {
              label: "Active",
              value: "Active",
            },
          ]}
          onChange={selected => {
            this.handleChangeValue(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
      );
    } else if (targetFilter.fieldDropdown === "Signup source") {
      valueSelect = (
        <Select.Creatable
          className="conversation-publisher__target-filter__value-select"
          defaultValue={targetFilter.value}
          value={targetFilter.value}
          options={sourceOptions}
          onChange={selected => {
            this.handleChangeValue(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
      );
    } else if (targetFilter.fieldDropdown === "Subscriber profile") {
      valueSelect = (
        <Select.Creatable
          className="conversation-publisher__target-filter__value-select"
          defaultValue={targetFilter.value}
          value={targetFilter.value}
          options={[
            {
              label: "Complete",
              value: "Complete",
            },
          ]}
          onChange={selected => {
            this.handleChangeValue(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
      );
    } else if (targetFilter.fieldDropdown === "Contact owner") {
      valueSelect = (
        <Select.Creatable
          className="conversation-publisher__target-filter__value-select"
          defaultValue={targetFilter.value}
          value={targetFilter.value}
          options={this.props.contactOwners.map(({ email, userId }) => ({
            label: email,
            value: userId,
          }))}
          onChange={selected => {
            this.handleChangeValue(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
      );
    } else {
      valueSelect = (
        <input
          type="text"
          className="conversation-publisher__target-filter__value-input"
          defaultValue={targetFilter.value}
          value={
            targetFilter.conditionDropdown &&
            targetFilter.conditionDropdown.includes("exist")
              ? ""
              : targetFilter.value
          }
          onChange={e => {
            this.handleChangeValue(targetFilter, e.target.value);
          }}
          disabled={
            targetFilter.conditionDropdown &&
            targetFilter.conditionDropdown.includes("exist")
              ? true
              : false
          }
        />
      );
    }

    return (
      <div className="conversation-publisher__target-filter">
        <Select.Creatable
          className="conversation-publisher__target-filter__field-dropdown"
          value={targetFilter.fieldDropdown}
          options={this.props.fieldOptions || fieldOptions(this.props.organizationFields)}
          onChange={selected => {
            this.handleChangeFieldDropdown(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />
        <Select.Creatable
          className="conversation-publisher__target-filter__condition-dropdown"
          value={targetFilter.conditionDropdown}
          options={conditionOptionsForField(targetFilter.fieldDropdown)}
          onChange={selected => {
            this.handleChangeConditionDropdown(targetFilter, selected.value);
          }}
          clearable={false}
          openOnFocus={false}
          openAfterFocus={false}
        />

        <div className="conversation-publisher__target-filter__value">
          {valueSelect}
        </div>

        <button
          className="conversation-publisher__target-filter__delete"
          onClick={handleDeleteTargetFilter.bind(
            this,
            targetFilter.targetFilterId
          )}
        />
      </div>
    );
  };
}

export default connect(
  (state, ownProps) => {
    const {
      currentOrganization: { users: contactOwners },
    } = state;

    return { contactOwners, ...ownProps };
  },
  { fetchContactOwners: fetchOrganizationUsers }
)(TargetFilter);
