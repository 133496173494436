import React, { Component } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import { Card, ContentHeader } from "../../components/shared";
import { createConversation } from "../../actions/welcome-messages";
import { connect } from "react-redux";
import { push } from "react-router-redux";

class NewWelcomeMessageConversation extends Component {
  state = {
    label: "",
    messageType: "text",
  };

  handleCreateConversation = async e => {
    e.preventDefault();

    const { label, messageType } = this.state;
    const { welcomeMessageId } = this.props.match.params;
    await this.props.createConversation(welcomeMessageId, {
      label,
      messageType,
    });

    this.props.push(`/entrypoints/wm/${welcomeMessageId}/`);
  };

  render() {
    return (
      <div className="new-conversation">
        <ContentHeader
          left={<h1 className="page-title">New Conversation</h1>}
        />
        <Card>
          <form
            className="default-form"
            onSubmit={this.handleCreateConversation}
          >
            <label className="default-form__label" htmlFor="conversation-label">
              Name
            </label>
            <input
              className="default-form__input"
              id="conversation-label"
              type="text"
              name="label"
              onChange={e =>
                this.setState({ ...this.state, label: e.target.value })
              }
              value={this.state.label}
            />

            <label
              className="default-form__label"
              htmlFor="trigger-message-type"
            >
              Message Type
            </label>
            <RadioGroup
              className="horizontal-radio-group"
              name="type"
              selectedValue={this.state.messageType}
              onChange={messageType =>
                this.setState({ ...this.state, messageType })
              }
            >
              <div className="radio-option">
                <Radio className="paloma-radio-label" id="text" value="text" />
                <label className="paloma-radio-label" htmlFor="text">
                  &nbsp;Text
                </label>
              </div>
              <div className="radio-option">
                <Radio id="cards" value="cards" />
                <label className="paloma-radio-label" htmlFor="cards">
                  &nbsp;Text with cards
                </label>
              </div>
            </RadioGroup>

            <button
              className="default-form__submit"
              type="submit"
              disabled={!this.state.label}
            >
              Create
            </button>
          </form>
        </Card>
      </div>
    );
  }
}

export default connect(null, { createConversation, push })(
  NewWelcomeMessageConversation
);
