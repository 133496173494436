import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { putCampaign } from "../../actions/campaigns";

import { Card } from "../../components/shared";

class NewCampaignContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {
        title: "",
        description: "",
      },
      formIsValid: false,
    };

    this.handleCreateCampaign = this.handleCreateCampaign.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkValidation = this.checkValidation.bind(this);
  }

  async handleCreateCampaign(e) {
    e.preventDefault();
    const response = await this.props.putCampaign(this.state.campaign);
    this.props.push(`/campaigns/${response.payload.campaign.campaignId}`);
  }

  handleInputChange(key, ev) {
    var campaign = this.state.campaign;
    campaign[key] = ev.target.value;
    this.setState({ campaign: campaign });
    this.checkValidation();
  }

  checkValidation() {
    if (this.state.campaign.title) {
      this.setState({ formIsValid: true });
    } else {
      this.setState({ formIsValid: false });
    }
  }

  render() {
    return (
      <div className="new-campaign">
        <h1 className="page-title">New Campaign</h1>
        <Card>
          <form className="default-form" onSubmit={this.handleCreateCampaign}>
            <label className="default-form__label" htmlFor="campaign-title">
              Name
            </label>
            <input
              id="campaign-title"
              className="default-form__input"
              type="text"
              value={this.state.campaign.title}
              onChange={this.handleInputChange.bind(this, "title")}
            />
            <label
              className="default-form__label"
              htmlFor="campaign-description"
            >
              Description
            </label>
            <input
              id="campaign-description"
              className="default-form__input"
              type="text"
              value={this.state.campaign.description}
              onChange={this.handleInputChange.bind(this, "description")}
            />
            <button
              className="default-form__submit"
              type="submit"
              disabled={!this.state.formIsValid}
            >
              Create
            </button>
          </form>
        </Card>
      </div>
    );
  }
}

export default connect(
  null,
  { putCampaign, push }
)(NewCampaignContainer);
