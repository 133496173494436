import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import TestSendConversation from "../TestSendConversation";
import { Card } from "../../components/shared";
import { ContentHeader } from "../../components/shared";
import {
  fetchConversation,
  publishConversation,
} from "../../actions/conversations";
import ConfirmationModal from "../../components/ConfirmationModal";

import { fetchGlobalTrigger } from "../../actions/global-triggers";
import { getConversationLink } from "../../utilities/conversation";
import ConversationScheduler from "../../containers/ConversationScheduler";

import infoIcon from "../../images/info.svg";

class TriggerConversationPublisher extends Component {
  state = { publishModal: false };

  componentWillMount() {
    const {
      conversationId,
      fetchConversation,
      fetchGlobalTrigger,
      triggerId,
    } = this.props;

    fetchConversation(conversationId);
    fetchGlobalTrigger(triggerId);
  }

  render() {
    const { conversation } = this.props;

    return (
      <Loader
        loaded={!conversation.isLoading}
        loadedClassName="conversation-detail"
      >
        <ContentHeader left={<h1>{conversation.label}</h1>} />
        <Card className="info-panel">
          <div className="conversation-publisher">
            <h3>Recipients</h3>

            <h4 className="card__section-title conversation-publisher__trigger-section">
              All subscribers that enter a matching response will receive this
              conversation.
            </h4>
            <ConversationScheduler
              conversationId={conversation.conversationId}
            />
            <ConfirmationModal
              onConfirm={this.publishConversation}
              schedule={conversation.sendAt}
            />
            <div className="conversation-publisher__notice-section">
              <img
                src={infoIcon}
                className="conversation-publisher__notice-section-icon"
                alt=""
              />
              Publishing this conversation will deactivate the existing active
              conversation for this trigger.
            </div>
          </div>
        </Card>
        <Card className="info-panel">
          <TestSendConversation id={this.props.conversationId} />
        </Card>
      </Loader>
    );
  }

  publishConversation = () => {
    const {
      conversation,
      conversationId,
      publishConversation,
      push,
    } = this.props;

    publishConversation({ conversationId });

    push(getConversationLink(conversation) + "/details");
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { conversationId, triggerId },
    },
  } = ownProps;

  const { conversationsById } = state.conversations;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  return {
    conversationId,
    conversation,
    triggerId,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchConversation,
    fetchGlobalTrigger,
    publishConversation,
    push,
  }
)(TriggerConversationPublisher);
