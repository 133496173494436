export const FETCH_THREAD_CONTROL_PASS_REQUEST =
  "FETCH_THREAD_CONTROL_PASS_REQUEST";
export const FETCH_THREAD_CONTROL_PASS_SUCCESS =
  "FETCH_THREAD_CONTROL_PASS_SUCCESS";
export const FETCH_THREAD_CONTROL_PASS_FAILURE =
  "FETCH_THREAD_CONTROL_PASS_REQUEST";

export const CREATE_THREAD_CONTROL_PASS_REQUEST =
  "CREATE_THREAD_CONTROL_PASS_REQUEST";
export const CREATE_THREAD_CONTROL_PASS_SUCCESS =
  "CREATE_THREAD_CONTROL_PASS_SUCCESS";
export const CREATE_THREAD_CONTROL_PASS_FAILURE =
  "CREATE_THREAD_CONTROL_PASS_FAILURE";

export const DELETE_THREAD_CONTROL_PASS_REQUEST =
  "DELETE_THREAD_CONTROL_PASS_REQUEST";
export const DELETE_THREAD_CONTROL_PASS_SUCCESS =
  "DELETE_THREAD_CONTROL_PASS_SUCCESS";
export const DELETE_THREAD_CONTROL_PASS_FAILURE =
  "DELETE_THREAD_CONTROL_PASS_FAILURE";
