import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  fetchOrganizationFields,
  putOrganizationField,
} from "../actions/organization-fields";

import { Card, ContentHeader } from "../components/shared";

class EditFieldDetailsContainer extends Component {
  componentDidMount() {
    if (!this.props.fields.organizationFields.length) {
      this.props.fetchOrganizationFields();
    }
  }

  render() {
    const { field } = this.props;

    return (
      <div className="edit-field-detail">
        <ContentHeader left={<h1>Edit details</h1>} right="" />
        <Card className="with-margin">
          <form
            className="default-form edit-details-form"
            onSubmit={this.editFieldDetails}
          >
            <label
              className="default-form__label"
              htmlFor="edit-subscriber-list-name"
            >
              Name
            </label>
            <input
              type="text"
              className="default-form__input"
              name="name"
              defaultValue={field.name}
              id="edit-subscriber-list-name"
            />
            <button
              type="submit"
              className="paloma-button paloma-button--pink default-form__submit"
            >
              Update
            </button>
          </form>
        </Card>
      </div>
    );
  }

  editFieldDetails = e => {
    e.preventDefault();

    const {
      field: { organizationFieldId },
      putOrganizationField,
    } = this.props;

    const {
      name: { value: name },
    } = e.currentTarget;

    putOrganizationField({
      organizationFieldId,
      name: name.trim(),
    }).then(() => this.props.push(`/fields/${organizationFieldId}`));
  };
}

export default connect(
  (state, props) => {
    const { byId } = state.organizationFields;
    const field = byId[props.match.params.fieldId] || null;

    return {
      field,
      fields: state.organizationFields,
      ...props,
    };
  },
  {
    fetchOrganizationFields,
    putOrganizationField,
    push,
  }
)(EditFieldDetailsContainer);
