import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/payments";

const initialState = {};

export default createReducer(initialState, {
  [constants.CREATE_SESSION_SUCCESS](state, action) {
    return {
      ...action.payload
    };
  },
});

