import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import DeleteConversationModal from "./DeleteConversationModal";

import { duplicateConversation } from "../../actions/conversations";
import { fetchCampaign } from "../../actions/campaigns";
import { fetchCurrentOrganization } from "../../actions/current-organization";
import { fetchGlobalTrigger } from "../../actions/global-triggers";
import { fetchInactiveSignupConversations } from "../../actions/inactive-signup-conversations";
import { fetchActiveSignupConversations } from "../../actions/active-signup-conversations";
import { fetchGlobalDefaultConversations } from "../../actions/global-default-conversations";
import { fetchWelcomeMessage } from "../../actions/welcome-messages";
import { getConversationLink } from "../../utilities/conversation";

class ConversationsTableRowButtons extends Component {
  state = {
    displayDeleteModal: false,
  };

  render() {
    const { conversation, showDelete } = this.props;

    const { displayDeleteModal } = this.state;

    return (
      <React.Fragment>
        {displayDeleteModal && (
          <DeleteConversationModal
            conversation={conversation}
            onClose={this.hideDeleteModal}
            onDelete={this.refresh.bind(null, conversation)}
          />
        )}
        <td className="table__data">
          <div style={{ display: "flex" }}>
            <button
              className="table__link"
              onClick={this.duplicateConversation}
            >
              Duplicate
            </button>

            <Link
              style={{ lineHeight: "normal" }}
              className="table__link"
              to={getConversationLink(conversation) + "/details"}
            >
              View
            </Link>

            {this.deleteButton(showDelete)}
          </div>
        </td>
      </React.Fragment>
    );
  }

  deleteButton = (showDelete) => {
    if (showDelete) {
      return (
        <button className="table__link" onClick={this.showDeleteModal}>
          Delete
        </button>
      );
    }
  };

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => this.setState({ displayDeleteModal: false });

  duplicateConversation = async () => {
    const { conversation, duplicateConversation, onDuplicate } = this.props;

    await duplicateConversation({
      conversationId: conversation.conversationId,
    });
    if (onDuplicate) {
      await onDuplicate();
    }

    this.refresh(conversation);
  };

  // the conversation gets deleted and is no longer in Redux, which
  // is why we bind it to the function
  refresh = (conversation) => {
    const {
      conversation: { campaignId, triggerId, type },
      fetchActiveSignupConversations,
      fetchCampaign,
      fetchCurrentOrganization,
      fetchGlobalTrigger,
      fetchInactiveSignupConversations,
      fetchGlobalDefaultConversations,
    } = this.props;

    switch (type) {
      case "campaign":
        fetchCampaign(campaignId);
        break;
      case "global":
        fetchGlobalTrigger(triggerId);
        break;
      case "signup":
        fetchInactiveSignupConversations();
        fetchActiveSignupConversations();
        break;
      case "global_default":
        fetchGlobalDefaultConversations();
        break;
      case "welcome_message":
        fetchWelcomeMessage(conversation.triggerId);
      default:
        fetchCurrentOrganization();
        break;
    }
  };
}

ConversationsTableRowButtons.defaultProps = {
  showDelete: true,
};

export default connect(null, {
  duplicateConversation,
  fetchActiveSignupConversations,
  fetchCampaign,
  fetchCurrentOrganization,
  fetchGlobalTrigger,
  fetchInactiveSignupConversations,
  fetchGlobalDefaultConversations,
})(ConversationsTableRowButtons);
