import React from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";

import { capitalizeString } from "../../utilities/format-string";

const SubscriberStatus = ({ status, showText, className }) => {
  if (showText === true) {
    return <span>{capitalizeString(status)}</span>;
  }
  return (
    <Tooltip
      placement="top"
      trigger={["hover"]}
      overlay={<span>{capitalizeString(status)}</span>}
    >
      <span className={`status-dot ${status} ${className}`} />
    </Tooltip>
  );
};

SubscriberStatus.defaultProps = {
  className: null,
  showText: false,
  status: null,
};

SubscriberStatus.propTypes = {
  className: PropTypes.string,
  showText: PropTypes.bool,
  status: PropTypes.string,
};

const SubscriberChatStatus = ({ manualMessaging, standby }) => {
  if (standby) {
    return <span className="chat-status text-xs">Standby</span>;
  }
  if (manualMessaging) {
    return <span className="chat-status text-xs">Live</span>;
  }
  return <span className="chat-status text-xs auto">Auto</span>;
};

SubscriberChatStatus.defaultProps = {
  manualMessaging: false,
  standby: false,
};

SubscriberChatStatus.propTypes = {
  manualMessaging: PropTypes.bool,
  standby: PropTypes.bool,
};

export { SubscriberStatus, SubscriberChatStatus };
