import React, { Component } from "react";
import PropTypes from "prop-types";

import BuilderInput from "./BuilderInput";
import { createEditorState } from "../../utilities/editor";

export default class TemplateCardTitle extends Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool,
    field: PropTypes.string.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    placeholderClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editorState: createEditorState(props.title),
      isEditing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title && !this.state.isEditing) {
      this.setState({ editorState: createEditorState(this.props.title) })
    }
  }

  render() {
    const {
      className = "",
      editable = true,
      placeholder = "",
      placeholderClassName = "",
    } = this.props;

    const { editorState, isEditing } = this.state;

    return (
      <div onClick={this.handleClick}>
        <BuilderInput
          className={className}
          editable={editable}
          editorState={editorState}
          isFocused={isEditing}
          maxChars={80}
          onChange={this.handleChange}
          onEscape={this.handleEscape}
          placeholder={placeholder}
          placeholderClassName={placeholderClassName}
        />
      </div>
    );
  }

  handleClick = () => {
    this.props.onClick();
    this.setState({ isEditing: true });
  };
  handleEscape = () => {
    if (this.props.onEscape) {
      this.props.onEscape();
    }
    this.setState({ isEditing: false });
  };

  handleChange = ({ state: editorState }) => {
    const { field } = this.props;

    this.setState({ editorState });

    this.props.handleFieldChange(field, JSON.stringify(editorState.toJSON()));
  };
}
