export const FETCH_MESSENGER_PROFILE_REQUEST =
  "FETCH_MESSENGER_PROFILE_REQUEST";
export const FETCH_MESSENGER_PROFILE_SUCCESS =
  "FETCH_MESSENGER_PROFILE_SUCCESS";
export const FETCH_MESSENGER_PROFILE_FAILURE =
  "FETCH_MESSENGER_PROFILE_FAILURE";

export const UPDATE_MESSENGER_PROFILE_REQUEST =
  "UPDATE_MESSENGER_PROFILE_REQUEST";
export const UPDATE_MESSENGER_PROFILE_SUCCESS =
  "UPDATE_MESSENGER_PROFILE_SUCCESS";
export const UPDATE_MESSENGER_PROFILE_FAILURE =
  "UPDATE_MESSENGER_PROFILE_FAILURE";

export const DELETE_MESSENGER_PROFILE_REQUEST =
  "DELETE_MESSENGER_PROFILE_REQUEST";
export const DELETE_MESSENGER_PROFILE_SUCCESS =
  "DELETE_MESSENGER_PROFILE_SUCCESS";
export const DELETE_MESSENGER_PROFILE_FAILURE =
  "DELETE_MESSENGER_PROFILE_FAILURE";
