import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal } from "../../components/shared";

import { deleteGlobalTrigger } from "../../actions/global-triggers";

class DeleteGlobalTriggerModal extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal title="Delete trigger?" isOpen={true} onClose={onClose}>
        <div className="modal__controls">
          <button
            type="button"
            className="paloma-button modal__button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="paloma-button modal__button modal__button--red"
            onClick={this.deleteGlobalTrigger}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }

  deleteGlobalTrigger = async () => {
    const {
      globalTrigger: { triggerId },
      deleteGlobalTrigger,
      onClose,
      onDelete,
    } = this.props;

    await deleteGlobalTrigger(triggerId);
    await onClose();

    if (onDelete) onDelete();
  };
}

export default connect(
  null,
  {
    deleteGlobalTrigger,
  }
)(DeleteGlobalTriggerModal);
