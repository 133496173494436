import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/paste-step";

export const pasteStep = (id, previousStepId, triggerId) => ({
  [CALL_API]: {
    endpoint: `/api/conversation_steps/${id}/paste`,
    body: {
      previousStepId,
      triggerId,
    },
    method: "POST",
    types: [
      constants.PASTE_STEP_REQUEST,
      constants.PASTE_STEP_SUCCESS,
      constants.PASTE_STEP_FAILURE,
    ],
  },
});
