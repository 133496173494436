import React from "react";
import { Link } from "react-router-dom";

import { getConversationLink } from "../../utilities/conversation";

const NonEmptyConversationCTA = ({ buildUrl, publishUrl }) => (
  <div>
    <p>
      Continue building your conversation, or publish it to start acquiring
      subscribers
    </p>
    <div className="unpublished-conversation-cta-buttons">
      <Link className="paloma-button paloma-button--pink" to={buildUrl}>
        Build
      </Link>
      <Link className="paloma-button paloma-button--pink" to={publishUrl}>
        Publish
      </Link>
    </div>
  </div>
);

const UnpublishedConversationCTA = ({ conversation }) => {
  const buildUrl = getConversationLink(conversation) + "/build";
  const publishUrl = getConversationLink(conversation) + "/publish";

  return (
    <div className="unpublished-conversation-cta">
      <NonEmptyConversationCTA buildUrl={buildUrl} publishUrl={publishUrl} />
    </div>
  );
};

export default UnpublishedConversationCTA;
