import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/sources";

export default createReducer(
  {},
  {
    [constants.FETCH_SOURCES_SUCCESS](state, action) {
      const uniqueSubscribers = new Set(
        action.payload.map(referral => referral.referred.subscriberId)
      ).size;

      return {
        sources: [...action.payload],
        uniqueSubscribers: uniqueSubscribers,
        totalSubscribers: action.payload.length,
      };
    },
  }
);
