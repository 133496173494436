const entriesToObject = pairs =>
  pairs.reduce((obj, tup) => {
    obj[tup[0]] = tup[1];
    return obj;
  }, {});

const withoutKey = (obj, key) => {
  return entriesToObject(
    Object.entries(obj).filter(tup => tup[0] !== String(key))
  );
};

const byId = (resources, key = "id") => {
  return resources.reduce((acc, resource) => {
    acc[resource[key]] = resource;
    return acc;
  }, {});
};

const groupByFunction = (resources, keyFunc) => {
  return resources.reduce((acc, resource) => {
    const key = keyFunc(resource);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(resource);
    return acc;
  }, {});
};

export { groupByFunction, byId, entriesToObject, withoutKey };
