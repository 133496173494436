export const FETCH_CONVERSATIONS_REQUEST = "FETCH_CONVERSATIONS_REQUEST";
export const FETCH_CONVERSATIONS_SUCCESS = "FETCH_CONVERSATIONS_SUCCESS";
export const FETCH_CONVERSATIONS_FAILURE = "FETCH_CONVERSATIONS_FAILURE";

export const FETCH_CONVERSATION_REQUEST = "FETCH_CONVERSATION_REQUEST";
export const FETCH_CONVERSATION_SUCCESS = "FETCH_CONVERSATION_SUCCESS";
export const FETCH_CONVERSATION_FAILURE = "FETCH_CONVERSATION_FAILURE";

export const FETCH_CONVERSATION_DETAIL_REQUEST =
  "FETCH_CONVERSATION_DETAIL_REQUEST";
export const FETCH_CONVERSATION_DETAIL_SUCCESS =
  "FETCH_CONVERSATION_DETAIL_SUCCESS";
export const FETCH_CONVERSATION_DETAIL_FAILURE =
  "FETCH_CONVERSATION_DETAIL_FAILURE";

export const FETCH_CONVERSATION_STEPS_REQUEST =
  "FETCH_CONVERSATION_STEPS_REQUEST";
export const FETCH_CONVERSATION_STEPS_SUCCESS =
  "FETCH_CONVERSATION_STEPS_SUCCESS";
export const FETCH_CONVERSATION_STEPS_FAILURE =
  "FETCH_CONVERSATION_STEPS_FAILURE";

export const PUT_CONVERSATION_REQUEST = "PUT_CONVERSATION_REQUEST";
export const PUT_CONVERSATION_SUCCESS = "PUT_CONVERSATION_SUCCESS";
export const PUT_CONVERSATION_FAILURE = "PUT_CONVERSATION_FAILURE";

export const DELETE_CONVERSATION_REQUEST = "DELETE_CONVERSATION_REQUEST";
export const DELETE_CONVERSATION_SUCCESS = "DELETE_CONVERSATION_SUCCESS";
export const DELETE_CONVERSATION_FAILURE = "DELETE_CONVERSATION_FAILURE";

export const CREATE_CONVERSATION_STEP_REQUEST =
  "CREATE_CONVERSATION_STEP_REQUEST";
export const CREATE_CONVERSATION_STEP_SUCCESS =
  "CREATE_CONVERSATION_STEP_SUCCESS";
export const CREATE_CONVERSATION_STEP_FAILURE =
  "CREATE_CONVERSATION_STEP_FAILURE";

export const PUT_CONVERSATION_STEP_REQUEST = "PUT_CONVERSATION_STEP_REQUEST";
export const PUT_CONVERSATION_STEP_SUCCESS = "PUT_CONVERSATION_STEP_SUCCESS";
export const PUT_CONVERSATION_STEP_FAILURE = "PUT_CONVERSATION_STEP_FAILURE";

export const PUT_TEMPLATE_CARD_REQUEST = "PUT_TEMPLATE_CARD_REQUEST";
export const PUT_TEMPLATE_CARD_SUCCESS = "PUT_TEMPLATE_CARD_SUCCESS";
export const PUT_TEMPLATE_CARD_FAILURE = "PUT_TEMPLATE_CARD_FAILURE";

export const REPLACE_TEMPLATE_CARD_PRODUCT_REQUEST = "REPLACE_TEMPLATE_CARD_PRODUCT_REQUEST";
export const REPLACE_TEMPLATE_CARD_PRODUCT_SUCCESS = "REPLACE_TEMPLATE_CARD_PRODUCT_SUCCESS";
export const REPLACE_TEMPLATE_CARD_PRODUCT_FAILURE = "REPLACE_TEMPLATE_CARD_PRODUCT_FAILURE";

export const PUT_TEMPLATE_CARD_BUTTON_REQUEST =
  "PUT_TEMPLATE_CARD_BUTTON_REQUEST";
export const PUT_TEMPLATE_CARD_BUTTON_SUCCESS =
  "PUT_TEMPLATE_CARD_BUTTON_SUCCESS";
export const PUT_TEMPLATE_CARD_BUTTON_FAILURE =
  "PUT_TEMPLATE_CARD_BUTTON_FAILURE";

export const DELETE_TEMPLATE_CARD_REQUEST = "DELETE_TEMPLATE_CARD_REQUEST";
export const DELETE_TEMPLATE_CARD_SUCCESS = "DELETE_TEMPLATE_CARD_SUCCESS";
export const DELETE_TEMPLATE_CARD_FAILURE = "DELETE_TEMPLATE_CARD_FAILURE";

export const DELETE_TEMPLATE_CARD_BUTTON_REQUEST =
  "DELETE_TEMPLATE_CARD_BUTTON_REQUEST";
export const DELETE_TEMPLATE_CARD_BUTTON_SUCCESS =
  "DELETE_TEMPLATE_CARD_BUTTON_SUCCESS";
export const DELETE_TEMPLATE_CARD_BUTTON_FAILURE =
  "DELETE_TEMPLATE_CARD_BUTTON_FAILURE";

export const FETCH_VISIBLE_CONVERSATION_STEPS_REQUEST =
  "FETCH_VISIBLE_CONVERSATION_STEPS_REQUEST";
export const FETCH_VISIBLE_CONVERSATION_STEPS_SUCCESS =
  "FETCH_VISIBLE_CONVERSATION_STEPS_SUCCESS";
export const FETCH_VISIBLE_CONVERSATION_STEPS_FAILURE =
  "FETCH_VISIBLE_CONVERSATION_STEPS_FAILURE";

export const FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_REQUEST =
  "FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_REQUEST";
export const FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_SUCCESS =
  "FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_SUCCESS";
export const FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_FAILURE =
  "FETCH_VISIBLE_CONVERSATION_STEPS_FOR_MENU_FAILURE";

export const ENTER_EDIT_MODE = "ENTER_EDIT_MODE";
export const CLEAR_EDIT_MODE = "CLEAR_EDIT_MODE";

export const SHOW_INSERT_FIELD_MODAL = "SHOW_INSERT_FIELD_MODAL";
export const CLOSE_INSERT_FIELD_MODAL = "CLOSE_INSERT_FIELD_MODAL";

export const PUBLISH_CONVERSATION_REQUEST = "PUBLISH_CONVERSATION_REQUEST";
export const PUBLISH_CONVERSATION_SUCCESS = "PUBLISH_CONVERSATION_SUCCESS";
export const PUBLISH_CONVERSATION_FAILURE = "PUBLISH_CONVERSATION_FAILURE";

export const DUPLICATE_CONVERSATION_REQUEST = "DUPLICATE_CONVERSATION_REQUEST";
export const DUPLICATE_CONVERSATION_SUCCESS = "DUPLICATE_CONVERSATION_SUCCESS";
export const DUPLICATE_CONVERSATION_FAILURE = "DUPLICATE_CONVERSATION_FAILURE";

export const PUT_BUTTON_REQUEST = "PUT_BUTTON_REQUEST";
export const PUT_BUTTON_SUCCESS = "PUT_BUTTON_SUCCESS";
export const PUT_BUTTON_FAILURE = "PUT_BUTTON_FAILURE";

export const TOGGLE_RANDOMIZE_BUTTONS_REQUEST =
  "TOGGLE_RANDOMIZE_BUTTONS_REQUEST";
export const TOGGLE_RANDOMIZE_BUTTONS_SUCCESS =
  "TOGGLE_RANDOMIZE_BUTTONS_SUCCESS";
export const TOGGLE_RANDOMIZE_BUTTONS_FAILURE =
  "TOGGLE_RANDOMIZE_BUTTONS_FAILURE";

export const TOGGLE_RANDOMIZE_TEMPLATE_CARDS_REQUEST =
  "TOGGLE_RANDOMIZE_TEMPLATE_CARDS_REQUEST";
export const TOGGLE_RANDOMIZE_TEMPLATE_CARDS_SUCCESS =
  "TOGGLE_RANDOMIZE_TEMPLATE_CARDS_SUCCESS";
export const TOGGLE_RANDOMIZE_TEMPLATE_CARDS_FAILURE =
  "TOGGLE_RANDOMIZE_TEMPLATE_CARDS_FAILURE";

export const CREATE_BUTTON_REQUEST = "CREATE_BUTTON_REQUEST";
export const CREATE_BUTTON_SUCCESS = "CREATE_BUTTON_SUCCESS";
export const CREATE_BUTTON_FAILURE = "CREATE_BUTTON_FAILURE";

export const SET_TRIGGER_CHOICE = "SET_TRIGGER_CHOICE";

export const PUT_CONVERSATION_STEP_TRIGGER_REQUEST =
  "PUT_CONVERSATION_STEP_TRIGGER_REQUEST";
export const PUT_CONVERSATION_STEP_TRIGGER_SUCCESS =
  "PUT_CONVERSATION_STEP_TRIGGER_SUCCESS";
export const PUT_CONVERSATION_STEP_TRIGGER_FAILURE =
  "PUT_CONVERSATION_STEP_TRIGGER_FAILURE";

export const DELETE_CONVERSATION_STEP_REQUEST =
  "DELETE_CONVERSATION_STEP_REQUEST";
export const DELETE_CONVERSATION_STEP_SUCCESS =
  "DELETE_CONVERSATION_STEP_SUCCESS";
export const DELETE_CONVERSATION_STEP_FAILURE =
  "DELETE_CONVERSATION_STEP_FAILURE";

export const DELETE_CONVERSATION_STEP_TRIGGER_REQUEST =
  "DELETE_CONVERSATION_STEP_TRIGGER_REQUEST";
export const DELETE_CONVERSATION_STEP_TRIGGER_SUCCESS =
  "DELETE_CONVERSATION_STEP_TRIGGER_SUCCESS";
export const DELETE_CONVERSATION_STEP_TRIGGER_FAILURE =
  "DELETE_CONVERSATION_STEP_TRIGGER_FAILURE";

export const DELETE_CONVERSATION_STEP_BUTTON_REQUEST =
  "DELETE_CONVERSATION_STEP_BUTTON_REQUEST";
export const DELETE_CONVERSATION_STEP_BUTTON_SUCCESS =
  "DELETE_CONVERSATION_STEP_BUTTON_SUCCESS";
export const DELETE_CONVERSATION_STEP_BUTTON_FAILURE =
  "DELETE_CONVERSATION_STEP_BUTTON_FAILURE";

export const UPLOAD_TEMPLATE_CARD_IMAGE_REQUEST =
  "UPLOAD_TEMPLATE_CARD_IMAGE_REQUEST";
export const UPLOAD_TEMPLATE_CARD_IMAGE_SUCCESS =
  "UPLOAD_TEMPLATE_CARD_IMAGE_SUCCESS";
export const UPLOAD_TEMPLATE_CARD_IMAGE_FAILURE =
  "UPLOAD_TEMPLATE_CARD_IMAGE_FAILURE";

export const UPLOAD_CONVERSATION_STEP_IMAGE_REQUEST =
  "UPLOAD_CONVERSATION_STEP_IMAGE_REQUEST";
export const UPLOAD_CONVERSATION_STEP_IMAGE_SUCCESS =
  "UPLOAD_CONVERSATION_STEP_IMAGE_SUCCESS";
export const UPLOAD_CONVERSATION_STEP_IMAGE_FAILURE =
  "UPLOAD_CONVERSATION_STEP_IMAGE_FAILURE";

export const PUT_FEEDBACK_TRIGGER_REQUEST = "PUT_FEEDBACK_TRIGGER_REQUEST";
export const PUT_FEEDBACK_TRIGGER_SUCCESS = "PUT_FEEDBACK_TRIGGER_SUCCESS";
export const PUT_FEEDBACK_TRIGGER_FAILURE = "PUT_FEEDBACK_TRIGGER_FAILURE";

export const DELETE_FEEDBACK_TRIGGER_REQUEST =
  "DELETE_FEEDBACK_TRIGGER_REQUEST";
export const DELETE_FEEDBACK_TRIGGER_SUCCESS =
  "DELETE_FEEDBACK_TRIGGER_SUCCESS";
export const DELETE_FEEDBACK_TRIGGER_FAILURE =
  "DELETE_FEEDBACK_TRIGGER_FAILURE";

export const PUT_UNEXPECTED_TRIGGER_REQUEST = "PUT_UNEXPECTED_TRIGGER_REQUEST";
export const PUT_UNEXPECTED_TRIGGER_SUCCESS = "PUT_UNEXPECTED_TRIGGER_SUCCESS";
export const PUT_UNEXPECTED_TRIGGER_FAILURE = "PUT_UNEXPECTED_TRIGGER_FAILURE";

export const DELETE_UNEXPECTED_TRIGGER_REQUEST =
  "DELETE_UNEXPECTED_TRIGGER_REQUEST";
export const DELETE_UNEXPECTED_TRIGGER_SUCCESS =
  "DELETE_UNEXPECTED_TRIGGER_SUCCESS";
export const DELETE_UNEXPECTED_TRIGGER_FAILURE =
  "DELETE_UNEXPECTED_TRIGGER_FAILURE";

export const PUT_TARGET_FILTER_REQUEST = "PUT_TARGET_FILTER_REQUEST";
export const PUT_TARGET_FILTER_SUCCESS = "PUT_TARGET_FILTER_SUCCESS";
export const PUT_TARGET_FILTER_FAILURE = "PUT_TARGET_FILTER_FAILURE";

export const DELETE_TARGET_FILTER_REQUEST = "DELETE_TARGET_FILTER_REQUEST";
export const DELETE_TARGET_FILTER_SUCCESS = "DELETE_TARGET_FILTER_SUCCESS";
export const DELETE_TARGET_FILTER_FAILURE = "DELETE_TARGET_FILTER_FAILURE";

export const FETCH_NUM_TARGETED_REQUEST = "FETCH_NUM_TARGETED_REQUEST";
export const FETCH_NUM_TARGETED_SUCCESS = "FETCH_NUM_TARGETED_SUCCESS";
export const FETCH_NUM_TARGETED_FAILURE = "FETCH_NUM_TARGETED_FAILURE";

export const CLOSE_PUBLISH_CONFIRMATION_MODAL =
  "CLOSE_PUBLISH_CONFIRMATION_MODAL";

export const FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_REQUEST =
  "FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_REQUEST";
export const FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_SUCCESS =
  "FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_SUCCESS";
export const FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_FAILURE =
  "FETCH_CONVERSATION_REPORTS_DOWNLOAD_TOKEN_FAILURE";

export const FETCH_CONVERSATION_FUNNEL_REQUEST =
  "FETCH_CONVERSATION_FUNNEL_REQUEST";
export const FETCH_CONVERSATION_FUNNEL_SUCCESS =
  "FETCH_CONVERSATION_FUNNEL_SUCCESS";
export const FETCH_CONVERSATION_FUNNEL_FAILURE =
  "FETCH_CONVERSATION_FUNNEL_FAILURE";

export const FETCH_CONVERSATION_STEP_REQUEST =
  "FETCH_CONVERSATION_STEP_REQUEST";
export const FETCH_CONVERSATION_STEP_SUCCESS =
  "FETCH_CONVERSATION_STEP_SUCCESS";
export const FETCH_CONVERSATION_STEP_FAILURE =
  "FETCH_CONVERSATION_STEP_FAILURE";

export const UPDATE_STEP_REQUIREMENT_REQUEST =
  "UPDATE_STEP_REQUIREMENT_REQUEST";
export const UPDATE_STEP_REQUIREMENT_SUCCESS =
  "UPDATE_STEP_REQUIREMENT_SUCCESS";
export const UPDATE_STEP_REQUIREMENT_FAILURE =
  "UPDATE_STEP_REQUIREMENT_FAILURE";
