import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const InfoPanelCell = ({ header, value }) => (
  <div className="info-panel__cell">
    <h5>{header}</h5>
    {value}
  </div>
);

InfoPanelCell.propTypes = {
  header: PropTypes.any.isRequired,
  justify: PropTypes.string,
  value: PropTypes.any,
};

const InfoPanelRow = ({ justify = "space-between", headers, values }) => (
  <div className={classNames("info-panel__row", `info-panel__row--${justify}`)}>
    {headers.map((header, i) => (
      <InfoPanelCell key={i} header={header} value={values[i]} />
    ))}
  </div>
);

InfoPanelRow.propTypes = {
  headers: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
};

const InfoPanel = ({ children }) => (
  <div className="info-panel">{children}</div>
);

export { InfoPanel, InfoPanelRow };
