import React from "react";
import { connect } from "react-redux";
import {
  fetchProduct,
  deleteProduct,
  duplicateProduct,
} from "../actions/inventory";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { ContentHeader, Card } from "../components/shared";
import { formatDate } from "../utilities/format-date";
import DeleteProductModal from "../components/DeleteProductModal";
import { capitalizeString } from "../utilities/format-string";

class Product extends React.Component {
  state = {
    deleteModal: false,
  };

  componentDidMount() {
    const { product, productId, fetchProduct } = this.props;

    if (!product) {
      fetchProduct(productId);
    }
  }

  render() {
    const { deleteProduct, productId, product } = this.props;
    const { deleteModal } = this.state;

    return (
      <div>
        {deleteModal ? (
          <DeleteProductModal
            onClose={() => this.setState({ deleteModal: false })}
            deleteProduct={() =>
              deleteProduct(productId).then(() =>
                this.props.push("../products")
              )
            }
          />
        ) : null}
        <ContentHeader
          left={
            <h1 className="page-title">
              {product && capitalizeString(product.title)}
            </h1>
          }
          right={
            <div>
              <button
                className="paloma-button"
                onClick={() => {
                  this.props
                    .duplicateProduct(productId)
                    .then(({ payload: { productId } }) =>
                      this.props.push(`/products/${productId}`)
                    );
                }}
              >
                Duplicate
              </button>
              <button
                className="paloma-button"
                onClick={() => this.setState({ deleteModal: true })}
              >
                Delete
              </button>
              <Link className="paloma-button" to={`${productId}/edit`}>
                Edit details
              </Link>
            </div>
          }
        />
        {product && (
          <Card className="info-panel">
            <div>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Title
              </h3>
              <p>{product.title}</p>
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Subtitle
              </h3>
              <p>{product.subtitle}</p>
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Price
              </h3>
              <p>{product.price}</p>
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Product URL
              </h3>
              <p>{product.url}</p>
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Paloma Image
              </h3>
              <p style={{ fontStyle: "italic" }}>If a Paloma image is uploaded, this will be used in place of the Inventory feed image</p>
              <br></br>
              {product.image &&
                <img height="100" src={product.image} />
              }
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Inventory Feed Image
              </h3>
              {product.inventoryFeedImage &&
                <img height="100" src={product.inventoryFeedImage} />
              }
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Button text
              </h3>
              <p>{product.buttonText}</p>
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Created at
              </h3>
              <p>{formatDate(product.createdAt)}</p>
              <br></br>
              <br></br>
              <h3
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#BA2A6B",
                }}
              >
                Last updated at
              </h3>
              <p>{formatDate(product.updatedAt)}</p>
            </div>
          </Card>
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { productId } = props.match.params;
    const product = state.inventory.productsById[productId];

    return { productId, product };
  },
  { fetchProduct, deleteProduct, push, duplicateProduct }
)(Product);
