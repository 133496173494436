import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import { BuilderInput } from "../../components/ConversationDetail";

import { createEditorState } from "../../utilities/editor";
import { putConversationStep } from "../../actions/conversations";

class UserTextResponse extends Component {
  static propTypes = {
    conversationStep: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    let {
      conversationStep: { userPlaceholderText },
    } = props;

    this.state = {
      editorState: createEditorState(userPlaceholderText),
    };
  }

  render() {
    const { editorState } = this.state;
    const { editable } = this.props;

    const userResponseClassName = classNames(
      "conversation-step__user-response",
      {
        "conversation-step__user-response--editable": editable,
      }
    );

    return (
      <div className="conversation-step__user-response-container">
        <div className={userResponseClassName} onClick={this.handleClick}>
          <form>
            <BuilderInput
              className="conversation-step__user-response-input"
              editable={editable}
              editorState={editorState}
              onChange={this.handleChange}
            />
          </form>
        </div>
      </div>
    );
  }

  handleChange = ({ state: editorState }) => {
    const { conversationStep, putConversationStep } = this.props;

    this.setState({ editorState });

    putConversationStep({
      ...conversationStep,
      userPlaceholderText: JSON.stringify(editorState.toJSON()),
    });
  };
}

const mapStateToProps = (state, ownProps) => ({
  conversationStepsById: state.conversations.conversationStepsById,
});

export default connect(
  mapStateToProps,
  { putConversationStep }
)(UserTextResponse);
