import React from "react";
import Toggle from "../shared/Toggle";
import { connect } from "react-redux";
import { updateAdSourceSignup } from "../../actions/ad_source_signup";

class AdSourceSignup extends React.Component {
  onToggle = () => {
    const { triggerId } = this.props.globalTrigger;
    if (this.checked()) {
      return this.props.updateAdSourceSignup(triggerId, true);
    }

    this.props.updateAdSourceSignup(triggerId, false);
  };

  checked = () =>
    this.props.globalTrigger.triggerId ===
    this.props.currentOrganization.activeAdSourceGlobalTriggerId;

  render() {
    return (
      <div>
        <h3>Default Ad Source Signup</h3>
        <label className="toggle-wrapper">
          <Toggle
            className="square"
            onChange={this.onToggle}
            checked={this.checked()}
          />
          I would like subscribers that come from an Ad who reply with free text
          to receive this conversation
        </label>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { currentOrganization } = state;
    return {
      currentOrganization,
    };
  },
  { updateAdSourceSignup }
)(AdSourceSignup);
