import { get } from "../utilities/general";

const isImpersonating = (user, org) => {
  return (
    get(user, ["organizationId"]) &&
    get(org, ["organizationId"]) &&
    user.organizationId !== org.organizationId
  );
};

export { isImpersonating };
