import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchOrganizations } from "../actions/organizations";
import { fetchCurrentOrganization } from "../actions/current-organization";
import {
  createImpersonation,
  deleteImpersonation,
} from "../actions/impersonations";
import { isImpersonating } from "../utilities/user";
import Dropdown from "../components/shared/Dropdown";

class OrganizationSwitcher extends React.Component {
  state = { redirect: false };

  componentWillMount() {
    if (this.props.currentUser.admin) {
      this.props.fetchOrganizations();
    }
  }

  componentDidUpdate() {
    if (this.state.redirect) {
      if (
        isImpersonating(this.props.currentUser, this.props.currentOrganization)
      ) {
        if (this.props.location.pathname === "/") {
          window.location.reload();
        } else {
          this.props.history.push("/");
        }
      } else {
        if (this.props.location.pathname === "/organizations") {
          window.location.reload();
        } else {
          this.props.history.push("/organizations");
        }
      }
      this.setState({ redirect: false });
    }
  }

  organization = org => {
    return {
      text: org.facebookPageName ||
        org.shopifyStorefrontSlug?.replaceAll("-", " ") ||
        org.label,
      onClick: () => this.createImpersonation(org.organizationId),
    };
  };

  render = () => {
    const { currentUser, currentOrganization } = this.props;
    if (isImpersonating(currentUser, currentOrganization, true)) {
      return (
        <div className="organization-switcher">
          <h5>IMPERSONATION MODE</h5>
          <div className="buttons">
            <Dropdown
              buttonText={this.organization(currentOrganization).text}
              dropdownOptions={this.props.organizations.map(this.organization)}
              className="caret-down"
            />
            <button
              onClick={this.deleteImpersonation}
              className="paloma-button clear"
            >
              Stop Impersonating
            </button>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  createImpersonation = async organizationId => {
    await this.props.createImpersonation(organizationId);
    await this.props.fetchCurrentOrganization();
    this.setState({ redirect: true });
  };

  deleteImpersonation = async () => {
    await this.props.deleteImpersonation();
    await this.props.fetchCurrentOrganization();
    this.setState({ redirect: true });
  };
}

const mapStateToProps = state => {
  return {
    currentOrganization: state.currentOrganization || {},
    currentUser: state.currentUser.currentUser || {},
    organizations: state.organizations.list,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      createImpersonation,
      deleteImpersonation,
      fetchCurrentOrganization,
      fetchOrganizations,
    }
  )(OrganizationSwitcher)
);
