import React from "react";
import classNames from "classnames";
import { SubscriberStatus } from "./SubscriberStatus";

export default ({
  className = null,
  manualMessaging,
  pictureUrl = null,
  size = 40,
  status = false,
}) => {
  return (
    <div
      className={classNames({ "user-icon": true, [className]: !!className })}
      style={{
        minWidth: `${size}px`,
        maxWidth: `${size}px`,
        minHeight: `${size}px`,
        maxHeight: `${size}px`,
        backgroundImage: pictureUrl ? `url(${pictureUrl})` : null,
      }}
    >
      {status && (
        <SubscriberStatus
          status={status}
          manualMessaging={manualMessaging}
          className="small float"
        />
      )}
    </div>
  );
};
