import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/conversations";

export const newInventoryStep = params => ({
  [CALL_API]: {
    endpoint: "/api/inventories",
    method: "POST",
    body: JSON.stringify(params),
    types: [
      {
        type: constants.CREATE_CONVERSATION_STEP_REQUEST,
        meta: { conversationId: params.conversationId },
      },
      {
        type: constants.CREATE_CONVERSATION_STEP_SUCCESS,
        meta: { conversationId: params.conversationId },
      },
      {
        type: constants.CREATE_CONVERSATION_STEP_FAILURE,
        meta: { conversationId: params.conversationId },
      },
    ],
  },
});
