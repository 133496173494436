import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import createHistory from "history/createBrowserHistory";
import * as Sentry from "@sentry/react";

import rootReducer from "./reducers";
import {
  authenticationRedirectMiddleware,
  apiErrorAlertMiddleware,
  apiHeaderInjectorMiddleware,
  apiHostnameMiddleware,
  camelizeMiddleware,
  decamelizeMiddleware,
} from "./middleware";

export const history = createHistory();

const initialState = {};
const enhancers = [
  Sentry.createReduxEnhancer(),
];
const middleware = [
  apiHeaderInjectorMiddleware,
  apiHostnameMiddleware,
  decamelizeMiddleware,
  apiMiddleware,
  authenticationRedirectMiddleware,
  apiErrorAlertMiddleware,
  camelizeMiddleware,
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

function makeStore() {
  return createStore(rootReducer, initialState, composedEnhancers);
}

export default makeStore;
