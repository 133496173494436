import React from "react";
import classNames from "classnames";

export default ({
  title,
  isOpen,
  onClose,
  children,
  singleSection = false,
}) => {
  const className = classNames({ modal: true, "modal--closed": !isOpen });

  const headerClassName = classNames({
    modal__header: true,
    "modal__header--no-border": singleSection,
  });

  const contentClassName = classNames({
    modal__content: true,
    "modal__content--gray-background": singleSection,
  });

  return (
    <div className={className} onClick={e => e.stopPropagation()}>
      <div className="modal__container">
        <div className={headerClassName}>
          <h2 className="modal__title">{title}</h2>
          <button className="modal__close" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className={contentClassName}>{children}</div>
      </div>
      <div className="modal__backdrop" onClick={onClose} />
    </div>
  );
};
