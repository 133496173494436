import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import {
  BuilderInput,
  SetResponseAsFieldModal,
} from "../../components/ConversationDetail";

import {
  fetchOrganizationFields,
  putOrganizationField,
} from "../../actions/organization-fields";
import { createEditorState } from "../../utilities/editor";
import { putConversationStep } from "../../actions/conversations";

class UserFieldResponse extends Component {
  static propTypes = {
    conversationStep: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
  };

  state = {
    displayFieldModal: false,
    editorState: createEditorState(""),
  };

  componentWillReceiveProps(nextProps) {
    const {
      conversationStep: {
        organizationField: { name: fieldType },
      },
    } = nextProps;

    const emptyEditorState = createEditorState("");
    const change = emptyEditorState.change();

    change.insertInline({
      type: "field",
      isVoid: true,
      data: { fieldType },
    });

    this.setState({ editorState: change.state });
  }

  async componentWillMount() {
    await this.props.fetchOrganizationFields();
  }

  render() {
    const { organizationFields, putOrganizationField, editable } = this.props;

    const { displayFieldModal, editorState } = this.state;

    const userResponseClassName = classNames(
      "conversation-step__user-response",
      { "conversation-step__user-response--editable": editable }
    );

    return (
      <div className="conversation-step__user-response-container">
        <div className={userResponseClassName}>
          <form>
            <BuilderInput
              className="conversation-step__user-response-input"
              editorState={editorState}
              editable={false}
              onChange={() => null}
            />
          </form>
        </div>

        {displayFieldModal && (
          <SetResponseAsFieldModal
            handleSetResponseAsField={this.handleSetResponseAsField}
            putOrganizationField={putOrganizationField}
            selected={
              this.props.conversationStep.organizationField.organizationFieldId
            }
            isOpen={true}
            organizationFields={organizationFields}
            onClose={this.hideFieldModal}
          />
        )}
      </div>
    );
  }

  openSetResponseAsFieldModal = async () => {
    if (this.props.editable) this.showFieldModal();
  };

  handleSetResponseAsField = async organizationFieldId => {
    const {
      conversationStep,
      organizationFields,
      putConversationStep,
    } = this.props;

    const organizationField = organizationFields.find(
      organizationField =>
        organizationField.organizationFieldId === organizationFieldId
    );

    if (!conversationStep)
      throw new Error("Cannot add user response without conversation step");
    if (!organizationField) throw new Error("Invalid organization field");

    this.hideFieldModal();

    putConversationStep({
      ...conversationStep,
      organizationFieldId,
    });
  };

  showFieldModal = () => this.setState({ displayFieldModal: true });
  hideFieldModal = () => this.setState({ displayFieldModal: false });
}

const mapStateToProps = (state, ownProps) => ({
  conversationStepsById: state.conversations.conversationStepsById,
  organizationFields: state.organizationFields.organizationFields,
});

export default connect(
  mapStateToProps,
  {
    putOrganizationField,
    putConversationStep,
    fetchOrganizationFields,
  }
)(UserFieldResponse);
