import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/flash";

const initialState = {};

export default createReducer(initialState, {
  [constants.SET_FLASH](state, action) {
    return {
      ...state,
      title: action.title,
      body: action.body,
    };
  },
  [constants.CLEAR_FLASH]() {
    return {};
  },
});
