import React from "react";
import { InfoPanel, InfoPanelRow } from "../shared";
import { formatDate } from "../../utilities/format-date";
import { status } from "../../utilities/conversation";
import CampaignConversationDetailsSummary from "./CampaignConversationDetailsSummary";

const ConversationDetailsSummary = ({ conversation }) => {
  const HEADERS = ["Status", "Created", "Published", "Recipients"];
  if (conversation.type === "campaign") {
    return CampaignConversationDetailsSummary({ conversation });
  }
  const createdDate = formatDate(conversation.createdAt);
  const conversationStatus = status(conversation);
  const publishedDate = formatDate(conversation.publishedAt) || "N/A";
  const recipients = conversation.numSubscribers || "N/A";

  const values = [conversationStatus, createdDate, publishedDate, recipients];

  return (
    <InfoPanel>
      <InfoPanelRow headers={HEADERS} values={values} />
    </InfoPanel>
  );
};

export default ConversationDetailsSummary;
