export const FETCH_EMAIL_NOTIFICATION_REQUEST =
  "FETCH_EMAIL_NOTIFICATION_REQUEST";
export const FETCH_EMAIL_NOTIFICATION_SUCCESS =
  "FETCH_EMAIL_NOTIFICATION_SUCCESS";
export const FETCH_EMAIL_NOTIFICATION_FAILURE =
  "FETCH_EMAIL_NOTIFICATION_REQUEST";

export const CREATE_EMAIL_NOTIFICATION_REQUEST =
  "CREATE_EMAIL_NOTIFICATION_REQUEST";
export const CREATE_EMAIL_NOTIFICATION_SUCCESS =
  "CREATE_EMAIL_NOTIFICATION_SUCCESS";
export const CREATE_EMAIL_NOTIFICATION_FAILURE =
  "CREATE_EMAIL_NOTIFICATION_FAILURE";

export const DELETE_EMAIL_NOTIFICATION_REQUEST =
  "DELETE_EMAIL_NOTIFICATION_REQUEST";
export const DELETE_EMAIL_NOTIFICATION_SUCCESS =
  "DELETE_EMAIL_NOTIFICATION_SUCCESS";
export const DELETE_EMAIL_NOTIFICATION_FAILURE =
  "DELETE_EMAIL_NOTIFICATION_FAILURE";
