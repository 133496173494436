import React from "react";
import { connect } from "react-redux";

import { Card, ContentHeader, LinksTable } from "../../components/shared";
import EventSummary from "../../components/EventSummary";
import { parseEventReport } from "../../utilities/event-report";
import { createEditorState } from "../../utilities/editor";
import EventBarChart from "../../components/EventBarChart";
import { SubscriberFlow } from "../../components/ConversationDetail";
import PalomaDatePicker from "../../components/shared/PalomaDatePicker";
import {
  fetchConversation,
  fetchVisibleConversationSteps,
  fetchConversationFunnel,
  fetchFunnelStepsBreakdown,
  setTriggerChoice,
  fetchFunnelConversions,
  fetchLinksBreakdown,
} from "../../actions/conversations";
import ConversationStep from "./ConversationStep";
import Tooltip from "rc-tooltip";
import truncate from "lodash/truncate";

class FunnelDetails extends React.Component {
  state = {
    fromDate: null,
    toDate: null,
    stepSelections: [],
    links: [],
    fetchingLinks: false,
    conversions: 0,
  };

  componentDidMount() {
    const {
      conversationId,
      fetchConversation,
      fetchConversationFunnel,
    } = this.props;

    Promise.all([
      fetchConversation(conversationId),
      fetchConversationFunnel(conversationId),
      this.refreshSteps(),
    ]);
  }

  fetchConversionsCount = () => {
    const { conversationId } = this.props;
    const fromDate =
      this.state.fromDate && this.state.fromDate.format("YYYY-MM-DD");
    const toDate = this.state.toDate && this.state.toDate.format("YYYY-MM-DD");
    this.props
      .fetchFunnelConversions(conversationId, fromDate, toDate)
      .then(({ payload: { totalConversions: conversions } }) =>
        this.setState({ conversions })
      );
  };

  fetchLinks = () => {
    const { conversationId } = this.props;
    const fromDate =
      this.state.fromDate && this.state.fromDate.format("YYYY-MM-DD");
    const toDate = this.state.toDate && this.state.toDate.format("YYYY-MM-DD");

    this.setState({ fetchingLinks: true });
    this.props
      .fetchLinksBreakdown(conversationId, fromDate, toDate)
      .then(({ payload }) => this.setState({ links: payload }))
      .finally(() => this.setState({ fetchingLinks: false }));
  };

  refreshSteps = () => {
    this.props
      .fetchVisibleConversationSteps(this.props.conversationId)
      .then(({ payload }) => {
        const { visibleConversationStepIds } = payload;
        this.refreshStepsBreakdown(visibleConversationStepIds);
      });

    this.fetchConversionsCount();
    this.fetchLinks();
  };

  refreshStepsBreakdown = (visibleStepIds = null) => {
    const fromDate =
      this.state.fromDate && this.state.fromDate.format("YYYY-MM-DD");
    const toDate = this.state.toDate && this.state.toDate.format("YYYY-MM-DD");

    const { visibleConversationSteps, conversationId } = this.props;

    const stepIds = visibleStepIds || visibleConversationSteps;

    this.props
      .fetchFunnelStepsBreakdown(conversationId, stepIds, fromDate, toDate)
      .then(({ payload: stepSelections }) => this.setState({ stepSelections }));
  };

  render() {
    const {
      conversation,
      visibleConversationSteps,
      conversationStepsById,
    } = this.props;

    const parsedStepsReport = parseEventReport(
      conversation.eventsReport,
      visibleConversationSteps
    );

    const parsedStepsReportById = parsedStepsReport.reduce(
      (obj, item) => ({
        ...obj,
        [item.stepId]: item,
      }),
      {}
    );

    const stepSelections = Object.keys(parsedStepsReportById).length
      ? Array.isArray(this.state.stepSelections) && this.state.stepSelections
          .filter(
            s =>
              Object.keys(s[1]).length &&
              visibleConversationSteps.includes(s[0])
          )
          .sort((a, b) => {
            return parsedStepsReportById[a[0].toString()].label.localeCompare(
              parsedStepsReportById[b[0]].label
            );
          })
      : [];

    return (
      <div>
        <ContentHeader left={<h1>{conversation.label}</h1>} right={<div />} />
        <span style={{ fontWeight: "bold", marginBottom: "5px" }}>Filter:</span>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}>
            <div>
              <PalomaDatePicker
                selected={this.state.fromDate}
                onChange={date =>
                  this.setState({ fromDate: date, toDate: null })
                }
              />
            </div>
          </div>
          <div>
            <div
              style={{
                paddingTop: "5px",
                fontWeight: "bolder",
                marginRight: "10px",
              }}
            >
              _
            </div>
          </div>
          <div>
            <div>
              <PalomaDatePicker
                selected={this.state.toDate}
                onChange={date => this.setState({ toDate: date })}
              />
            </div>
          </div>
          <div>
            <button
              className="paloma-button"
              disabled={!(this.state.fromDate && this.state.toDate)}
              onClick={() => {
                this.props.fetchConversationFunnel(
                  this.props.conversationId,
                  this.state.fromDate.format("YYYY-MM-DD"),
                  this.state.toDate.format("YYYY-MM-DD")
                );

                this.refreshStepsBreakdown(visibleConversationSteps);
                this.fetchConversionsCount();
                this.fetchLinks();
              }}
            >
              Filter
            </button>
          </div>
          <div>
            <button
              className="paloma-button"
              disabled={!(this.state.fromDate || this.state.toDate)}
              onClick={() =>
                this.setState({ fromDate: null, toDate: null }, () =>
                  this.props.fetchConversationFunnel(this.props.conversationId)
                )
              }
            >
              Clear
            </button>
          </div>
        </div>
        <br />
        <Card className="event-graph-card">
          <div className="event-graph-container">
            <div className="event-graph">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Recipients</h3>
                <h3>Conversions: {this.state.conversions}</h3>
              </div>
              <EventBarChart
                data={parsedStepsReport}
              />
              <br />
            </div>
            <SubscriberFlow
              conversationStepIds={visibleConversationSteps || []}
              conversationId={conversation.conversationId}
              handleSelectTrigger={async trigger => {
                await this.props.setTriggerChoice({
                  conversationStepId: trigger.sourceConversationStepId,
                  triggerId: trigger.triggerId,
                });

                this.refreshSteps();
              }}
              handleDeselectTrigger={async trigger => {
                await this.props.setTriggerChoice({
                  conversationStepId: trigger.sourceConversationStepId,
                  triggerId: null,
                });

                this.refreshSteps();
              }}
            />
          </div>
        </Card>
        <Card>
          <h3>Funnel breakdown</h3>
          <div className="event-summary-container">
            <EventSummary
              name={conversation.label}
              steps={parsedStepsReport}
            />
          </div>
        </Card>

        <h3 style={{ padding: "25px 0 0px 5px" }}>Links breakdown</h3>
        <div>
          <LinksBreakdown
            links={this.state.links}
            loading={this.state.fetchingLinks}
          />
        </div>

        <h3 style={{ padding: "25px 0 0px 5px" }}>Selection breakdown</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <StepSelections
            conversationStepsById={conversationStepsById}
            stepSelections={stepSelections}
            parsedStepsReportById={parsedStepsReportById}
          />
        </div>
      </div>
    );
  }
}

FunnelDetails.defaultProps = {
  conversation: {},
};

const mapStateToProps = (state, ownProps) => {
  const conversationId = ownProps.match.params.conversationId;
  const conversation = state.conversations.conversationsById[conversationId];
  const visibleConversationSteps = (
    state.conversations.visibleConversationStepsByConversationId[
      conversationId
    ] || {}
  ).stepIds;

  const {
    conversations: { conversationStepsById },
  } = state;

  return {
    conversationId,
    conversation,
    visibleConversationSteps,
    conversationStepsById,
  };
};

export default connect(mapStateToProps, {
  fetchConversation,
  fetchVisibleConversationSteps,
  fetchConversationFunnel,
  fetchFunnelStepsBreakdown,
  fetchFunnelConversions,
  setTriggerChoice,
  fetchLinksBreakdown,
})(FunnelDetails);

const LinksBreakdown = ({ links, loading }) => {
  return (
    <Card loading={loading}>
      <LinksTable links={links} />
    </Card>
  );
};

const StepSelections = ({
  stepSelections,
  parsedStepsReportById,
  conversationStepsById,
}) => {
  const stepToString = step => {
    var editorState
    switch (step.messageType) {
      case "text":
        editorState = createEditorState(step.messageText);
        return editorState.anchorText.text;
      case "template":
        editorState = createEditorState(step.templateCards[0].title);
        return editorState.anchorText.text;
      default:
        return "";
    }
  };

  return stepSelections.length > 0 && stepSelections.map(s => (
    <div key={s[0]} style={{ flex: "0 calc(50% - 5px)", marginTop: "10px" }}>
      <Card className="funnel-step-card">
        <Tooltip
          overlayClassName="step-mini-preview"
          placement="top"
          trigger={["hover"]}
          overlay={
            <ConversationStep
              conversationStepId={s[0]}
              editable={false}
              displayStepId={false}
              displayDetours={false}
            />
          }
        >
          <h3 style={{ fontWeight: "bold", width: "fit-content" }}>{`Step ${
            parsedStepsReportById[s[0]].label
          } - ${truncate(stepToString(conversationStepsById[s[0]]), {
            length: 50,
            separator: /,? +/,
          })}`}</h3>
        </Tooltip>
        <div>
          <table className="table event-summary">
            <thead>
              <tr>
                <th>Options</th>
                <th>Selections</th>
                <th>% of all selections</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(s[1]).map(k => {
                return (
                  <tr key={k}>
                    <td>{s[1][k]["label"]}</td>
                    <td>{s[1][k]["numOfSubscribers"]}</td>
                    <td>
                      {(s[1][k]["selectionPercentage"] * 100).toFixed(2)}%
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  ));
};
