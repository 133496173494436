import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { login } from "../../actions/auth";
import { Alert } from "../../components/Layout";
import { clearAlerts, showErrorAlert } from "../../actions/alerts";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  static get propTypes() {
    return {
      login: PropTypes.func.isRequired,
    };
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  submitLogin = async e => {
    e.preventDefault();

    const response = await this.props.login({
      email: this.state.email,
      password: this.state.password,
    });

    if (response.error) {
      this.props.showErrorAlert("Login Unsuccessful");
    }
  };

  render() {
    return (
      <div className="login">
        <Alert
          alert={this.props.currentAlert}
          clearAlerts={this.props.clearAlerts}
        />
        <h1 className="login__paloma">Paloma</h1>
        <div className="login__form-container">
          <h2 className="login__title">Login</h2>
          <form onSubmit={this.submitLogin} className="login__form">
            <label className="default-form__label">Email address</label>
            <input
              className="default-form__input"
              type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />

            <label className="default-form__label">Password</label>
            <input
              className="default-form__input"
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />

            <input className="default-form__submit" type="submit" value="Go!" />
            <div className="request-password-reset">
              <a href="/password_reset/request">Forgot your password?</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentAlert: state.alerts.currentAlert,
  };
};

export default connect(
  mapStateToProps,
  { clearAlerts, login, showErrorAlert }
)(LoginContainer);
