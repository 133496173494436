import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import acquisitionActions from "./acquisition-actions";
import activeSignupConversations from "./active-signup-conversations";
import alerts from "./alerts";
import assignSubscriberOwner from "./assign_subscriber_owner";
import auth from "./auth";
import builderErrors from "./builder-errors";
import builderMenu from "./builder-menu";
import campaigns from "./campaigns";
import conversations from "./conversations";
import currentOrganization from "./current-organization";
import currentUser from "./current-user";
import dashboard from "./dashboard";
import emailNotifications from "./email-notifications";
import flash from "./flash";
import globalDefaultConversations from "./global-default-conversations";
import globalDefaultTrigger from "./global-default-trigger";
import globalTriggers from "./global-triggers";
import inactiveSignupConversations from "./inactive-signup-conversations";
import inventory from "./inventory";
import links from "./links";
import manualMessagingActivations from "./manual-messaging-activations";
import messages from "./messages";
import messengerProfile from "./messenger-profile";
import organizationFields from "./organization-fields";
import organizations from "./organizations";
import payments from "./payments";
import persistentMenu from "./persistent-menu";
import quickReplies from "./quick-replies";
import reports from "./reports";
import sequences from "./sequences";
import shopifyCollections from "./shopify-collections";
import sources from "./sources";
import subscriberConversationStates from "./subscriber-conversation-states";
import subscriberFields from "./subscriber-fields";
import subscriberLists from "./subscriber-lists";
import subscriberPhotos from "./subscriber-photos";
import subscriberTags from "./subscriber-tags";
import subscribers from "./subscribers";
import taskFailures from "./task-failures";
import threadControlPasses from "./thread-control-passes";
import userConfiguration from "./user-configurations";
import welcomeMessages from "./welcome-messages";

export default combineReducers({
  acquisitionActions,
  activeSignupConversations,
  alerts,
  assignSubscriberOwner,
  auth,
  builderErrors,
  builderMenu,
  campaigns,
  conversations,
  currentOrganization,
  currentUser,
  dashboard,
  emailNotifications,
  manualMessagingActivations,
  flash,
  globalTriggers,
  globalDefaultConversations,
  globalDefaultTrigger,
  inactiveSignupConversations,
  inventory,
  links,
  messages,
  messengerProfile,
  payments,
  persistentMenu,
  organizationFields,
  organizations,
  quickReplies,
  routing: routerReducer,
  reports,
  sequences,
  shopifyCollections,
  sources,
  subscriberConversationStates,
  subscriberFields,
  subscriberLists,
  subscriberPhotos,
  subscriberTags,
  subscribers,
  taskFailures,
  threadControlPasses,
  userConfiguration,
  welcomeMessages,
});
