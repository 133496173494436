import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { ContentHeader } from "../../components/shared";
import { EditCampaignDetailsForm } from "../../components/CampaignDetail";
import { fetchCampaign, putCampaign } from "../../actions/campaigns";

class EditCampaignDetailsContainer extends Component {
  state = {
    hasBeenEdited: false,
  };

  async componentDidMount() {
    await this.props.fetchCampaign(this.props.campaignId);
  }

  render() {
    const { campaignId, campaign } = this.props;

    if (this.state.hasBeenEdited) {
      return <Redirect to={`/campaigns/${campaignId}`} />;
    }

    return (
      <Loader loaded={!campaign.isLoading} loadedClassName="campaign-detail">
        <ContentHeader left={<h1>Edit details</h1>} right="" />
        <EditCampaignDetailsForm
          campaignDescription={campaign.description}
          campaignId={campaignId}
          campaignName={campaign.title}
          onSubmit={this.editCampaignDetails}
        />
      </Loader>
    );
  }

  editCampaignDetails = async e => {
    e.preventDefault();

    const {
      campaign: { type },
      campaignId,
      putCampaign,
    } = this.props;

    const {
      title: { value: title },
      description: { value: description },
    } = e.currentTarget;

    await putCampaign({
      campaign_id: campaignId,
      type,

      description: description.trim(),
      title: title.trim(),
    });

    this.setState({ hasBeenEdited: true });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { campaignId },
    },
  } = ownProps;

  const campaign = {
    isLoading: true,
    ...state.campaigns.campaignsById[campaignId],
  };

  return {
    campaignId,
    campaign,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCampaign,
    putCampaign,
  }
)(EditCampaignDetailsContainer);
