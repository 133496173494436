import { CALL_API } from "redux-api-middleware";

export default store => next => action => {
  const callApi = action[CALL_API];

  // Check if this action is a redux-api-middleware action.
  if (callApi) {
    // Prepend the REACT_APP_API_HOSTNAME env variable to the API request endpoint
    callApi.endpoint = process.env.REACT_APP_API_HOSTNAME + callApi.endpoint;
  }

  return next(action);
};
