import React from "react";
import { Modal } from "./shared";

class PublishConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  render() {
    const { onConfirm } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Modal
          title="Publish Conversation?"
          isOpen={open}
          onClose={() => this.setState({ open: false })}
        >
          <div className="modal__controls">
            <button
              className="paloma-button modal__button modal__button--red"
              onClick={() => this.setState({ open: false })}
            >
              No
            </button>
            <button
              className="paloma-button modal__button modal__button--red"
              onClick={e => {
                e.target.disabled = true;
                e.target.innerHTML = "Sending...";
                onConfirm();
              }}
            >
              Yes
            </button>
          </div>
        </Modal>
        <button
          className="conversation-publisher__publish-button paloma-button--pink"
          onClick={() => this.setState({ open: true })}
        >
          Publish
        </button>
      </div>
    );
  }
}

class ScheduleConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  render() {
    const { onConfirm } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Modal
          title="Schedule confirmation"
          isOpen={open}
          onClose={() => this.setState({ open: false })}
        >
          <div className="modal__controls">
            <button
              className="paloma-button modal__button modal__button--red"
              onClose={() => this.setState({ open: false })}
            >
              No
            </button>
            <button
              className="paloma-button modal__button modal__button--red"
              onClick={e => {
                e.target.disabled = true;
                e.target.innerHTML = "Scheduling...";
                onConfirm();
              }}
            >
              Schedule
            </button>
          </div>
        </Modal>
        <button
          className="conversation-publisher__publish-button paloma-button--pink"
          onClick={() => this.setState({ open: true })}
        >
          Schedule
        </button>
      </div>
    );
  }
}

export default ({ onCancel, onConfirm, schedule }) =>
  schedule ? (
    <ScheduleConfirmationModal onConfirm={onConfirm} onCancel={onCancel} />
  ) : (
    <PublishConfirmationModal onConfirm={onConfirm} onCancel={onCancel} />
  );
