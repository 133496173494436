import React, { Component } from "react";
import classNames from "classnames";

import { Modal } from "../shared";

export default class ImageUploadModal extends Component {
  state = {
    hasError: false,
    isUploading: false,
  };

  render() {
    const { onClose } = this.props;
    const { hasError, isUploading } = this.state;

    return (
      <Modal title="Upload an Image" isOpen={true} onClose={onClose}>
        {isUploading && <p className="modal__message">Processing...</p>}
        {!isUploading && (
          <form action="#" onSubmit={this.uploadImage}>
            <p
              className={classNames("modal__message", {
                modal__error: hasError,
              })}
            >
              Image must be a .png, .jpg, or .gif less than 25MB.
            </p>
            <p className="modal__message">
              <input
                type="file"
                name="image"
                accept={[".jpg", ".png", ".gif"].join(",")}
              />
            </p>

            <div className="modal__controls">
              <button
                type="button"
                className="paloma-button modal__button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="paloma-button modal__button modal__button--red"
                type="submit"
              >
                Insert
              </button>
            </div>
          </form>
        )}
      </Modal>
    );
  }

  uploadImage = e => {
    e.preventDefault();

    const {
      image: { files },
    } = e.target;

    const file = files[0];

    this.setState({ isUploading: true }, async () => {
      try {
        await this.props.onUpload(file);
        this.props.onClose();
      } catch (e) {
        this.displayError(e);
      }
    });
  };

  displayError = error => {
    this.setState({
      hasError: true,
      isUploading: false,
    });
  };
}
