import React from "react";
import { connect } from "react-redux";

import { fetchSubscriberWebhooks } from "../actions/subscriber-webhooks";

class SubscriberWebhooksContainer extends React.Component {
  async componentWillMount() {
    this.props.fetchSubscriberWebhooks(this.props.subscriberId);
  }

  render() {
    const { currentUser, subscriber } = this.props;

    return (
      <div>
        {currentUser.admin &&
          subscriber.webhooks.map(webhook => (
            <pre>{JSON.stringify(webhook, null, 2)}</pre>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentUser = (state.currentUser || {}).currentUser;
  const {
    match: {
      params: { subscriberId },
    },
  } = ownProps;

  const subscriber = state.subscribers.subscribersById[subscriberId];

  return {
    currentUser: currentUser || {},
    subscriber: subscriber || { webhooks: [] },
    subscriberId,
  };
};

export default connect(
  mapStateToProps,
  { fetchSubscriberWebhooks }
)(SubscriberWebhooksContainer);
