import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/campaigns";

const initialState = {
  campaignsById: {},
  isLoading: false,
  errorMessage: "",
};

export default createReducer(initialState, {
  [constants.FETCH_CAMPAIGNS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_CAMPAIGNS_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      campaignsById: action.payload.campaigns.reduce(function(
        campaignsById,
        campaign
      ) {
        return { ...campaignsById, [campaign.campaignId]: campaign };
      },
      {}),
    };
  },

  [constants.FETCH_CAMPAIGNS_FAILURE](state, action) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.response
        ? JSON.parse(action.payload.response).message
        : "",
    };
  },

  [constants.FETCH_CAMPAIGN_REQUEST](state, action) {
    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.meta.campaignId]: {
          ...(state.campaignsById[action.meta.campaignId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.FETCH_CAMPAIGN_SUCCESS](state, action) {
    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.payload.campaign.campaignId]: {
          ...action.payload.campaign,
          isLoading: false,
        },
      },
    };
  },

  [constants.FETCH_CAMPAIGN_FAILURE](state, action) {
    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.meta.campaignId]: {
          ...(state.campaignsById[action.meta.campaignId] || {}),
          isLoading: false,
          errorMessage: action.payload.response
            ? JSON.parse(action.payload.response).message
            : "",
        },
      },
    };
  },

  [constants.PUT_CAMPAIGN_REQUEST](state, action) {
    if (!action.meta.campaignId) {
      return {
        ...state,
        isLoading: true,
      };
    }

    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.meta.campaignId]: {
          ...(state.campaignsById[action.meta.campaignId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.PUT_CAMPAIGN_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      campaignsById: {
        ...state.campaignsById,
        [action.payload.campaign.campaignId]: {
          ...action.payload.campaign,
          isLoading: false,
        },
      },
    };
  },

  [constants.PUT_CAMPAIGN_FAILURE](state, action) {
    if (!action.meta.campaignId) {
      return {
        ...state,
        isLoading: false,
      };
    }

    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.meta.campaignId]: {
          ...(state.campaignsById[action.meta.campaignId] || {}),
          isLoading: true,
          errorMessage: action.payload.response
            ? JSON.parse(action.payload.response).message
            : "",
        },
      },
    };
  },

  [constants.DELETE_CAMPAIGN_REQUEST](state, action) {
    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.meta.campaignId]: {
          ...(state.campaignsById[action.meta.campaignId] || {}),
          isLoading: true,
        },
      },
    };
  },

  [constants.DELETE_CAMPAIGN_SUCCESS](state, action) {
    const campaignsById = { ...state.campaignsById };
    delete campaignsById[action.meta.campaignId];
    return {
      ...state,
      isLoading: false,
      campaignsById: campaignsById,
    };
  },

  [constants.DELETE_CAMPAIGN_FAILURE](state, action) {
    return {
      ...state,
      campaignsById: {
        ...state.campaignsById,
        [action.meta.campaignId]: {
          ...(state.campaignsById[action.meta.campaignId] || {}),
          isLoading: true,
          errorMessage: action.payload.response
            ? JSON.parse(action.payload.response).message
            : "",
        },
      },
    };
  },
});
