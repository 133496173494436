import { CALL_API } from "redux-api-middleware";
import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from "../constants/auth";

export function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS,
  };
}

export function loginError(errorMessage) {
  return {
    type: LOGIN_FAILURE,
    payload: {
      errorMessage,
    },
  };
}

export const login = credentials => ({
  [CALL_API]: {
    endpoint: "/api/auth/",
    method: "POST",
    body: {
      auth_type: "email",
      email: credentials.email,
      password: credentials.password,
    },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  },
});

export function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export const logout = () => dispatch => {
  dispatch(requestLogout());
  window.localStorage.removeItem("paloma_session_token");
  dispatch(receiveLogout());
};

export const resetPassword = (token, newPassword) => ({
  [CALL_API]: {
    endpoint: "/api/password_resets/confirm/" + token,
    method: "POST",
    body: { password: newPassword },
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE,
    ],
  },
});

export const requestPasswordResetLink = email => ({
  [CALL_API]: {
    endpoint: "/api/password_resets/" + email,
    method: "POST",
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE,
    ],
  },
});
