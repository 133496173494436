import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader";
import { push } from "react-router-redux";

import { ContentHeader, Card } from "../../components/shared";

import { CampaignsTable } from "../../components/Campaigns";

import * as campaignsActions from "../../actions/campaigns";

class CampaignsContainer extends React.Component {
  componentWillMount() {
    this.props.fetchCampaigns();
  }

  handleClickNew = () => {
    this.props.push("/campaigns/new");
  };

  render() {
    return (
      <Loader loaded={!this.props.isLoading} loadedClassName="campaigns">
        <ContentHeader
          left={<h1 className="page-title">All campaigns</h1>}
          right={
            <button
              className="paloma-button--pink"
              onClick={this.handleClickNew}
            >
              + New
            </button>
          }
        />

        <Card>
          <CampaignsTable campaigns={this.props.campaigns} />
        </Card>
      </Loader>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: Object.values(state.campaigns.campaignsById),
  isLoading: state.campaigns.isLoading,
});

export default connect(
  mapStateToProps,
  { ...campaignsActions, push }
)(CampaignsContainer);
