import React from "react";

const SequenceDetailForm = ({
  onSubmit,
  canSubmit,
  name,
  onChange,
  submitLabel,
}) => {
  return (
    <form className="default-form" onSubmit={onSubmit}>
      <label className="default-form__label" htmlFor="sequence-name">
        Name
      </label>
      <input
        id="sequence-name"
        className="default-form__input"
        type="text"
        value={name}
        onChange={onChange}
      />
      <button
        className="default-form__submit"
        type="submit"
        disabled={!canSubmit}
      >
        {submitLabel || "Submit"}
      </button>
    </form>
  );
};

export default SequenceDetailForm;
