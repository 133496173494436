import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal } from "../shared";

import { deleteCampaign } from "../../actions/campaigns";

class DeleteCampaignModal extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal title="Delete campaign?" isOpen={true} onClose={onClose}>
        <div className="modal__controls">
          <button
            type="button"
            className="paloma-button modal__button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="paloma-button modal__button modal__button--red"
            onClick={this.deleteCampaign}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }

  deleteCampaign = async () => {
    const {
      campaign: { campaignId },
      deleteCampaign,
      onClose,
      onDelete,
    } = this.props;

    await deleteCampaign(campaignId);
    await onClose();

    onDelete();
  };
}

export default connect(
  null,
  {
    deleteCampaign,
  }
)(DeleteCampaignModal);
