import React from "react";
import { Link } from "react-router-dom";

import { ContentHeader, ConversationsTable } from "../shared";

const GlobalTriggerConversations = ({
  globalTriggerId,
  activeConversation,
  inactiveConversations = [],
}) => (
  <div className="global-trigger-conversation">
    <ContentHeader
      style={{ paddingBottom: 10, paddingTop: 5 }}
      left={<h3 style={{ paddingBottom: "13px" }}>Conversations</h3>}
      right={
        (!!activeConversation || inactiveConversations.length > 0) && (
          <Link
            className="paloma-button"
            to={`/global/${globalTriggerId}/conversations/new`}
          >
            + New
          </Link>
        )
      }
    />
    {!activeConversation && inactiveConversations.length === 0 ? (
      <div>
        <span>
          Create a conversation to be sent to subscribers when they submit a
          matching response.
        </span>
        <Link
          className="paloma-button"
          to={`/global/${globalTriggerId}/conversations/new`}
        >
          + Add Conversation
        </Link>
      </div>
    ) : (
      <div>
        {activeConversation && (
          <ConversationsTable
            conversations={[activeConversation]}
            title="Active"
          />
        )}
        {inactiveConversations.length > 0 && (
          <ConversationsTable
            conversations={inactiveConversations}
            title="Inactive"
          />
        )}
      </div>
    )}
  </div>
);

export default GlobalTriggerConversations;
