import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/subscriber-conversation-steps";

const sendSubscriberConversationStep = (subscriberId, conversationStepId) => ({
  [CALL_API]: {
    endpoint: "/api/subscribers/" + subscriberId + "/send",
    method: "POST",
    body: { conversationStepId },
    types: [
      constants.SEND_CONVERSATION_STEP_TO_SUBSCRIBER_REQUEST,
      constants.SEND_CONVERSATION_STEP_TO_SUBSCRIBER_SUCCESS,
      constants.SEND_CONVERSATION_STEP_TO_SUBSCRIBER_FAILURE,
    ],
  },
});

export const sendSubscriberTrigger = (subscriberId, triggerId) => ({
  [CALL_API]: {
    endpoint: "/api/subscribers/" + subscriberId + "/send",
    method: "POST",
    body: { triggerId },
    types: [
      "SEND_TRIGGER_TO_SUBSCRIBER_REQUEST",
      "SEND_TRIGGER_TO_SUBSCRIBER_SUCCESS",
      "SEND_TRIGGER_TO_SUBSCRIBER_FAILURE",
    ],
  },
});

export const sendSubscriberProducts = (subscriberId, productIds) => ({
  [CALL_API]: {
    endpoint: `/api/subscribers/${subscriberId}/send_products`,
    method: "POST",
    body: { productIds },
    types: [
      "SEND_PRODUCTS_TO_SUBSCRIBER_REQUEST",
      "SEND_PRODUCTS_TO_SUBSCRIBER_SUCCESS",
      "SEND_PRODUCTS_TO_SUBSCRIBER_FAILURE",
    ],
  },
});

export { sendSubscriberConversationStep };
