export const FETCH_CURRENT_ORGANIZATION_REQUEST =
  "FETCH_CURRENT_ORGANIZATION_REQUEST";
export const FETCH_CURRENT_ORGANIZATION_SUCCESS =
  "FETCH_CURRENT_ORGANIZATION_SUCCESS";
export const FETCH_CURRENT_ORGANIZATION_FAILURE =
  "FETCH_CURRENT_ORGANIZATION_FAILURE";

export const UPDATE_CURRENT_ORGANIZATION_REQUEST =
  "UPDATE_CURRENT_ORGANIZATION_REQUEST";
export const UPDATE_CURRENT_ORGANIZATION_SUCCESS =
  "UPDATE_CURRENT_ORGANIZATION_SUCCESS";
export const UPDATE_CURRENT_ORGANIZATION_FAILURE =
  "UPDATE_CURRENT_ORGANIZATION_FAILURE";

export const UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_REQUEST =
  "UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_REQUEST";
export const UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_SUCCESS =
  "UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_SUCCESS";
export const UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_FAILURE =
  "UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_FAILURE";

export const FETCH_CURRENT_ORGANIZATION_USERS_REQUEST =
  "FETCH_CURRENT_ORGANIZATION_USERS_REQUEST";
export const FETCH_CURRENT_ORGANIZATION_USERS_SUCCESS =
  "FETCH_CURRENT_ORGANIZATION_USERS_SUCCESS";
export const FETCH_CURRENT_ORGANIZATION_USERS_FAILURE =
  "FETCH_CURRENT_ORGANIZATION_USERS_FAILURE";

export const POST_UNSUBSCRIBERS_CREATE_AUDIENCE_REQUEST = "POST_UNSUBSCRIBERS_CREATE_AUDIENCE_REQUEST";
export const POST_UNSUBSCRIBERS_CREATE_AUDIENCE_SUCCESS = "POST_UNSUBSCRIBERS_CREATE_AUDIENCE_SUCCESS";
export const POST_UNSUBSCRIBERS_CREATE_AUDIENCE_FAILURE = "POST_UNSUBSCRIBERS_CREATE_AUDIENCE_FAILURE";
