import React from "react";
import classNames from "classnames";

const InlineInputAndButton = ({ inputProps: iProps, buttonProps: bProps }) => {
  let { className: inputClass, ...inputProps } = iProps;
  let { className: buttonClass, ...buttonProps } = bProps;

  inputClass = classNames(inputClass, "inline-input-and-button__input");
  buttonClass = classNames(buttonClass, "inline-input-and-button__button");

  return (
    <div className="inline-input-and-button">
      <div className="inline-input-and-button__input-container">
        <input type="text" className={inputClass} {...inputProps} />
      </div>
      <div className="inline-input-and-button__button-container">
        <button className={buttonClass} {...buttonProps} />
      </div>
    </div>
  );
};

export default InlineInputAndButton;
