import React from "react";
import { Card, Toggle } from "./shared";
import PersistentMenusTable from "./PersistentMenusTable";

const PersistentMenuToggle = ({
  onToggle = () => 0,
  enabled = false,
  create,
  menus,
}) => {
  return (
    <Card className="info-panel">
      <h3 style={{ float: "left" }}>Global Menu</h3>
      <div style={{ float: "right" }}>
        <Toggle
          checked={enabled}
          className="square"
          onLabel="On"
          offLabel="Off"
          onChange={onToggle}
        />
      </div>
      {enabled && <PersistentMenusTable create={create} menus={menus} />}
    </Card>
  );
};

export default PersistentMenuToggle;
