export const CREATE_SUBSCRIBER_THREAD_CONTROL_REQUEST =
  "CREATE_SUBSCRIBER_THREAD_CONTROL_REQUEST";
export const CREATE_SUBSCRIBER_THREAD_CONTROL_SUCCESS =
  "CREATE_SUBSCRIBER_THREAD_CONTROL_SUCCESS";
export const CREATE_SUBSCRIBER_THREAD_CONTROL_FAILURE =
  "CREATE_SUBSCRIBER_THREAD_CONTROL_FAILURE";

export const DELETE_SUBSCRIBER_THREAD_CONTROL_REQUEST =
  "DELETE_SUBSCRIBER_THREAD_CONTROL_REQUEST";
export const DELETE_SUBSCRIBER_THREAD_CONTROL_SUCCESS =
  "DELETE_SUBSCRIBER_THREAD_CONTROL_SUCCESS";
export const DELETE_SUBSCRIBER_THREAD_CONTROL_FAILURE =
  "DELETE_SUBSCRIBER_THREAD_CONTROL_FAILURE";
