import React from "react";
import PropTypes from "prop-types";
import Segments from "./Segments";
import List from "./List";

const Targeting = ({ list, segments, matchSetting }) => (
  <div className="targeting">
    <List name={list.name} />
    <Segments segments={segments} matchSetting={matchSetting} />
  </div>
);

Targeting.defaultProps = {
  list: { name: "All Subscribers" },
  matchSetting: "Any",
};
Targeting.propTypes = {
  list: PropTypes.shape({ name: PropTypes.string.isRequired }),
  matchSetting: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      conditionDropdown: PropTypes.string.isRequired,
      fieldDropdown: PropTypes.string.isRequired,
      targetFilterId: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default Targeting;
