import { normalize } from "normalizr";

import { subscriberSchema } from "../schemas";

import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/subscribers";
import * as subscriberOwnerConstants from "../constants/subscriber-owner";
import * as webhookConstants from "../constants/subscriber-webhooks";
import * as threadControlConstants from "../constants/subscriber-thread-control";

const initialState = {
  subscribersById: {},
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    pages: {
      1: [],
    },
  },
  meta: {
    order: "DESC",
    sortBy: "Received",
    search: "",
  },
  isLoading: false,
  errorMessage: "",
};

const initialSubscriberState = {
  isLoading: false,
  isLoadingMessages: false,
  messagesById: {},
  currentMessagePage: 1,
};

export default createReducer(initialState, {
  [constants.FETCH_SUBSCRIBER_REQUEST](state, action) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.subscriberId]: {
          ...initialSubscriberState,
          ...(state.subscribersById[action.meta.subscriberId] || {}),
        },
      },
    };
  },

  [constants.FETCH_SUBSCRIBER_SUCCESS](state, action) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.payload.subscriberId]: {
          ...initialSubscriberState,
          ...state.subscribersById[action.payload.subscriberId],
          ...action.payload,
          isLoading: false,
        },
      },
    };
  },

  [constants.FETCH_SUBSCRIBER_MESSAGES_REQUEST](state, action) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.subscriberId]: {
          ...initialSubscriberState,
          ...(state.subscribersById[action.meta.subscriberId] || {}),
          isLoadingMessages: true,
        },
      },
    };
  },

  [constants.FETCH_SUBSCRIBER_MESSAGES_SUCCESS](state, action) {
    const subscriber = state.subscribersById[action.meta.subscriberId] || {};
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.subscriberId]: {
          ...initialSubscriberState,
          ...subscriber,
          messagesById: {
            ...subscriber.messagesById,
            ...action.payload.messages.reduce(function(messagesById, message) {
              return {
                ...messagesById,
                [message.messageId]: message,
              };
            }, {}),
          },
          currentMessagePage: (subscriber.currentMessagePage || 1) + 1,
          isLoadingMessages: false,
        },
      },
    };
  },

  [constants.FETCH_SUBSCRIBERS_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_SUBSCRIBERS_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, {
      subscribers: [subscriberSchema],
    });
    const normalizedSubscribers = Object.values(
      normalizedData.entities.subscribers || {}
    );
    const { meta } = action;

    return {
      ...state,
      isLoading: false,
      subscribersById: {
        ...normalizedSubscribers.reduce(
          (subscribersById, subscriber) => {
            if (subscriber.subscriberId in subscribersById) {
              // We already have data on this subscriber in the store, keep all existing and overwrite only new values
              return {
                ...subscribersById,
                [subscriber.subscriberId]: {
                  ...subscribersById[subscriber.subscriberId],
                  ...subscriber,
                  isLoading: false,
                },
              }
            } else {
              // This subscriber is new to us
              return {
                ...subscribersById,
                [subscriber.subscriberId]: {
                  ...initialSubscriberState,
                  ...subscriber,
                  currentMessagePage: 1,
                  isLoading: false,
                },
              } 
            }
          },
          state.subscribersById
        ),
      },
      pagination: {
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        pages: {
          ...state.pagination.pages,
          [action.payload.currentPage]: normalizedData.result.subscribers, // array of ids
        },
      },
      meta: { ...state.meta, ...meta },
    };
  },

  [constants.PUT_SUBSCRIBER_REQUEST](state, action) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.subscriberId]: {
          ...(state.subscribersById[action.meta.subscriberId] || {}),
          isLoading: false,
        },
      },
    };
  },

  [constants.PUT_SUBSCRIBER_SUCCESS](state, action) {
    const normalizedData = normalize(action.payload, subscriberSchema);
    const normalizedSubscribers = Object.values(
      normalizedData.entities.subscribers || {}
    );

    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        ...normalizedSubscribers.reduce(
          (subscribersById, subscriber) => ({
            [subscriber.subscriberId]: {
              ...initialSubscriberState,
              ...subscriber,
              messagesById:
                state.subscribersById[subscriber.subscriberId].messagesById,
              isLoading: false,
            },
          }),
          {}
        ),
      },
    };
  },

  [constants.DELETE_SUBSCRIBER_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.DELETE_SUBSCRIBER_SUCCESS](state, action) {
    const subscribersById = { ...state.subscribersById };
    return {
      ...state,
      isLoading: false,
      subscribersById: subscribersById,
    };
  },

  [constants.SEND_MANUAL_MESSAGE_REQUEST](state, action) {
    return state;
  },

  [constants.SEND_MANUAL_MESSAGE_SUCCESS](state, action) {
    const subscriber = state.subscribersById[action.meta.subscriberId] || {};
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.subscriberId]: {
          ...initialSubscriberState,
          ...subscriber,
          messagesById: {
            ...subscriber.messagesById,
            [action.payload.messageId]: action.payload,
          },
        },
      },
    };
  },

  [constants.SEND_MANUAL_MESSAGE_FAILURE](state, action) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.subscriberId]: {
          ...initialSubscriberState,
          ...(state.subscribersById[action.meta.subscriberId] || {}),
          unsubscribed: true,
          manualMessagingEnabled: false,
        },
      },
    };
  },

  [threadControlConstants.CREATE_SUBSCRIBER_THREAD_CONTROL_SUCCESS](
    state,
    action
  ) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.payload.id]: {
          ...(state.subscribersById[action.payload.id] || {}),
          standby: false,
        },
      },
    };
  },

  [threadControlConstants.DELETE_SUBSCRIBER_THREAD_CONTROL_SUCCESS](
    state,
    action
  ) {
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.payload.id]: {
          ...(state.subscribersById[action.payload.id] || {}),
          standby: true,
        },
      },
    };
  },

  [webhookConstants.FETCH_SUBSCRIBER_WEBHOOKS_SUCCESS](state, action) {
    const subscriber = state.subscribersById[action.meta.id] || {};
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [action.meta.id]: {
          ...subscriber,
          webhooks: action.payload.data,
        },
      },
    };
  },

  [subscriberOwnerConstants.UPDATE_SUBSCRIBER_OWNER_SUCCESS](state, action) {
    const id = action.payload.subscriberId;

    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [id]: {
          ...state.subscribersById[id],
          owner: action.payload.owner ? { ...action.payload.owner } : null,
        },
      },
    };
  },

  [constants.STORE_MESSAGE_FOR_SUBSCRIBER](state, action) {
    const message = action.payload.message
    const id = message.subscriber.subscriberId

    const existingMessages = state.subscribersById[id] ? state.subscribersById[id].messagesById : {}
    
    const putAtTopOfFirstPage = ((state.meta.order === "DESC") && (state.meta.sortBy === "Received") && !state.meta.search) 
    var nextPages = {...state.pagination.pages}
    if (putAtTopOfFirstPage) {
      if(nextPages[1].includes(id)) {
        nextPages[1].sort(function(x,y){ return x == id ? -1 : y == id ? 1 : 0; });
      } else {
        nextPages[1].unshift(id)
        // FIXME: The number of subscribers per page is hardcoded in the backend as 20, we pop to ensure it stays as such.
        while(nextPages[1] > 20) {
          nextPages[1].pop()
        }
      }
    }
        
    return {
      ...state,
      subscribersById: {
        ...state.subscribersById,
        [id]: {
          ...initialSubscriberState,
          ...state.subscribersById[id],
          ...message.subscriber,
          messagesById: {
            ...existingMessages,
            [message.messageId] : message
          }
        },
      },
      pagination: {
        ...state.pagination,
        pages: {
          ...nextPages,
        },
      },
    };
  },
});
