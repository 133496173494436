import React from "react";
import { Link } from "react-router-dom";

import { Table } from "../shared";

import { formatDate } from "../../utilities/format-date";

import CampaignsTableRowButtons from "./CampaignTableRowButtons";

export default ({ campaigns }) => (
  <Table
    headers={["Name", "Conversations", "Created On", "Last Ran", ""]}
    rows={campaigns
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map(campaign => [
        <Link className="table__link" to={`/campaigns/${campaign.campaignId}`}>
          {campaign.title}
        </Link>,
        campaign.numConversations,
        campaign.createdAt ? formatDate(campaign.createdAt) : "",
        campaign.lastRanAt ? formatDate(campaign.lastRanAt) : "Never",
        <CampaignsTableRowButtons campaign={campaign} />,
      ])}
  />
);
