import React from "react";

const ProductDetailForm = ({
  onSubmit,
  onCancel,
  canSubmit,
  onChange,
  submitLabel,
  ...props
}) => {
  return (
    <form className="default-form" onSubmit={onSubmit}>
      <label className="default-form__label" htmlFor="title">
        Title
      </label>
      <input
        id="title"
        className="default-form__input"
        type="text"
        value={props.title}
        onChange={e => onChange("title", e.target.value)}
      />

      <label className="default-form__label" htmlFor="price">
        Price
      </label>
      <input
        id="price"
        className="default-form__input"
        type="text"
        value={props.price}
        onChange={e => onChange("price", e.target.value)}
      />

      <label className="default-form__label" htmlFor="subtitle">
        Subtitle
      </label>
      <p style={{ fontSize: "11px" }}>
        *If subtitle is defined, this will be used in the product card in place
        of the price
      </p>
      <br />
      <textarea
        id="subtitle"
        className="default-form__input"
        style={{
          margin: "0px",
          width: "350px",
          fontSize: "14px",
          height: "62px",
          border: "1px solid #E3E5EC",
          marginBottom: "20px",
        }}
        value={props.subtitle}
        onChange={e => onChange("subtitle", e.target.value)}
      />

      <label className="default-form__label" htmlFor="url">
        URL
      </label>
      <input
        id="url"
        className="default-form__input"
        type="text"
        value={props.url}
        onChange={e => onChange("url", e.target.value)}
      />

      <label className="default-form__label" htmlFor="buttonText">
        Button text
      </label>
      <input
        id="buttonText"
        className="default-form__input"
        type="text"
        value={props.buttonText}
        onChange={e => onChange("buttonText", e.target.value)}
      />

      <label className="default-form__label" htmlFor="image">
        Image
      </label>
      <input
        type="file"
        name="image"
        onChange={e => {
          onChange("image", e.target.files[0]);
        }}
        accept={[".jpg", ".png", ".gif", ".mp4"].join(",")}
      />
      <p style={{ fontStyle: "italic" }}>If a Paloma image is uploaded, this will be used in place of the Inventory feed image</p>
      <div style={{ display: "flex" }}>
        <button
          className="default-form__submit paloma-button"
          style={{ minWidth: "100px", marginRight: "10px", marginLeft: 0 }}
          onClick={onCancel}
        >
          Cancel
        </button>

        <button
          className="default-form__submit paloma-button--pink"
          type="submit"
          style={{ minWidth: "200px" }}
          disabled={!canSubmit}
        >
          {submitLabel || "Submit"}
        </button>
      </div>
    </form>
  );
};

export default ProductDetailForm;
