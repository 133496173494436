import React from "react";
import { connect } from "react-redux";
import pick from "lodash/pick";
import {
  fetchSequence,
  deleteSequence,
  duplicateSequence,
  fetchSequenceRecipients,
  publishSequence,
} from "../actions/sequences";
import {
  ContentHeader,
  InfoPanel,
  InfoPanelRow,
  Card,
} from "../components/shared";
import SubscriberTable from "../components/Audience/SortableSubscriberTable";
import { formatDate } from "../utilities/format-date";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import DeleteSequenceModal from "../components/DeleteSequenceModal";
import EditSequenceSettings from "../components/EditSequenceSettings";

class Sequence extends React.Component {
  state = {
    deleteModal: false,
  };

  componentDidMount() {
    const {
      sequence,
      sequenceId,
      fetchSequence,
      fetchSequenceRecipients,
    } = this.props;
    if (!sequence) {
      fetchSequence({ sequenceId });
    }

    fetchSequenceRecipients({ sequenceId });
  }

  status = () => {
    const { sequence } = this.props;
    return sequence && sequence.published ? "Published" : "Not published";
  };

  publishedAt = () => {
    const { sequence } = this.props;
    return sequence && sequence.publishedAt
      ? formatDate(sequence.publishedAt)
      : "N/A";
  };

  lastRanAt = () => {
    const { sequence } = this.props;
    return sequence && sequence.lastRanAt
      ? formatDate(sequence.lastRanAt)
      : "N/A";
  };

  totalRecipients = () => {
    const { subscribersData, sequence } = this.props;
    if (!sequence.published) {
      return "N/A";
    }

    return subscribersData && subscribersData.subscribers
      ? subscribersData.totalItems
      : "N/A";
  };

  render() {
    const {
      sequence,
      sequenceId,
      deleteSequence,
      subscribersData,
      conversationId,
    } = this.props;
    const { deleteModal } = this.state;

    return (
      <div>
        {deleteModal ? (
          <DeleteSequenceModal
            onClose={() => this.setState({ deleteModal: false })}
            sequenceId={sequenceId}
            deleteSequence={() =>
              deleteSequence({ sequenceId }).then(() =>
                this.props.push("../sequences")
              )
            }
          />
        ) : null}
        <ContentHeader
          left={<h1 className="page-title">{sequence && sequence.name}</h1>}
          right={
            <div>
              <button
                className="paloma-button"
                onClick={() => this.setState({ deleteModal: true })}
              >
                Delete
              </button>
              <button
                className="paloma-button"
                onClick={() =>
                  this.props
                    .duplicateSequence({ sequenceId })
                    .then(({ payload }) =>
                      this.props.push(`${payload.sequenceId}`)
                    )
                }
              >
                Duplicate
              </button>
              <Link className="paloma-button" to={`${sequenceId}/edit`}>
                Edit details
              </Link>
            </div>
          }
        />
        {sequence && (
          <InfoPanel>
            <InfoPanelRow
              headers={[
                "Status",
                "Created",
                "Published",
                "Recipients",
                "Last ran",
              ]}
              values={[
                this.status(),
                formatDate(sequence.createdAt),
                this.publishedAt(),
                this.totalRecipients(),
                this.lastRanAt(),
              ]}
            />
          </InfoPanel>
        )}
        {sequence && (
          <Card className="info-panel">
            <EditSequenceSettings
              sequenceId={sequenceId}
              published={sequence && sequence.published}
              onPublish={() => this.props.publishSequence({ sequenceId })}
              onUnpublish={() =>
                this.props.publishSequence({ sequenceId, publish: false })
              }
              conversationId={conversationId}
            />
          </Card>
        )}

        <Card className="info-panel">
          <div>
            <h3>Subscribers</h3>
            {subscribersData && subscribersData.subscribers && (
              <SubscriberTable
                page={subscribersData.currentPage}
                total={subscribersData.totalItems}
                {...pick(subscribersData, [
                  "subscribers",
                  "perPage",
                  "totalPages",
                ])}
                excludes={["owners"]}
                fetch={({ page }) => this.fetchSubscribers(page)}
                searchable={false}
                sortable={false}
              />
            )}
          </div>
        </Card>
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const { sequenceId, conversationId } = props.match.params;
    const sequence = state.sequences.byId[sequenceId];
    const subscribersData = state.sequences.recipientsById[sequenceId];

    return { sequenceId, sequence, subscribersData, conversationId };
  },
  {
    fetchSequence,
    deleteSequence,
    push,
    duplicateSequence,
    fetchSequenceRecipients,
    publishSequence,
  }
)(Sequence);
