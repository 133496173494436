import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { putConversation } from "../../actions/conversations";

import { Card } from "../../components/shared";

import { getConversationLink } from "../../utilities/conversation";
import { fetchCampaign } from "../../actions/campaigns";
import { fetchGlobalTrigger } from "../../actions/global-triggers";

class NewConversationContainer extends Component {
  state = {
    isFormValid: false,
  };

  async componentDidMount() {
    const {
      campaignId,
      fetchCampaign,
      fetchGlobalTrigger,
      triggerId,
    } = this.props;

    if (campaignId) await fetchCampaign(campaignId);
    else if (triggerId) await fetchGlobalTrigger(triggerId);
  }

  render() {
    const { isFormValid } = this.state;

    return (
      <div className="new-conversation">
        <h1 className="page-title">New Conversation</h1>
        <Card>
          <form
            className="default-form"
            onSubmit={this.handleCreateConversation}
          >
            <label className="default-form__label" htmlFor="conversation-label">
              Name
            </label>
            <input
              className="default-form__input"
              id="conversation-label"
              type="text"
              name="label"
              onChange={this.handleInputChange}
            />

            <label
              className="default-form__label"
              htmlFor="edit-conversation-details-utm"
            >
              UTM Parameters
            </label>
            <p style={{ fontSize: "11px" }}>
              *Enter a string of UTM parameters to be added to this
              conversation's URLs. <br /> (e.g.
              utm_source=Messenger&utm_campaign=WinterPromo)
            </p>
            <input
              type="text"
              className="default-form__input"
              name="utm"
              id="conversation-utm"
            />
            <button
              className="default-form__submit"
              type="submit"
              disabled={!isFormValid}
            >
              Create
            </button>
          </form>
        </Card>
      </div>
    );
  }

  handleCreateConversation = async e => {
    e.preventDefault();

    const {
      label: { value: label },
      utm: { value: utm },
    } = e.currentTarget;

    const { campaignId, putConversation, push, triggerId } = this.props;

    let response;
    switch (true) {
      case !!campaignId:
        response = await putConversation({
          campaignId,
          label,
          utm: utm.trim(),
          type: "campaign",
        });
        break;
      case !!triggerId:
        response = await putConversation({
          label,
          utm: utm.trim(),
          type: "global",
          triggerId,
        });
        break;
      default:
        response = await putConversation({
          label,
          utm: utm.trim(),
          type: "signup",
        });
        break;
    }

    push(getConversationLink(response.payload) + "/details");
  };

  handleInputChange = e => {
    const { value: label } = e.target;

    this.setState({ isFormValid: label.length > 0 });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { campaignId, triggerId },
    },
  } = ownProps;

  const campaign = {
    isLoading: true,
    ...state.campaigns.campaignsById[campaignId],
  };
  return {
    campaign,
    campaignId,
    triggerId,
  };
};

export default connect(mapStateToProps, {
  fetchCampaign,
  fetchGlobalTrigger,
  push,
  putConversation,
})(NewConversationContainer);
