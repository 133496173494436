import { showErrorAlert } from "../actions/alerts";
import { get } from "../utilities/general";

const DEFAULT_ERROR_MESSAGE =
  "Something went wrong :( Mind refreshing? That should do the trick.";

export default store => next => action => {
  if (action.error) {
    const error =
      get(action, ["payload", "response", "error"]) || DEFAULT_ERROR_MESSAGE;

    store.dispatch(showErrorAlert(error));
  }
  return next(action);
};
