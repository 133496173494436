import React from "react";
import { connect } from "react-redux";
import { Modal } from "../../components/shared";
import {
  fetchVisibleConversationStepsForMenu,
  createReconnection,
} from "../../actions/conversations";
import ConversationStepMessage from "../../components/ConversationDetail/ConversationStepMessage";

class SelectStepModal extends React.Component {
  componentWillMount() {
    this.props.fetchVisibleConversationStepsForMenu(this.props.conversationId);
  }

  render() {
    return (
      <Modal
        title="Choose a Step to Connect to"
        onClose={this.props.closeMenu}
        isOpen={true}
      >
        <div className="select-step-modal">
          {(this.props.visibleConversationStepsForMenu || []).map(step => (
            <div
              key={step.conversationStepId}
              onClick={async () => {
                await this.props.createReconnection({
                  stepId: this.props.stepIdToReconnect,
                  defaultNextStepId: step.conversationStepId,
                });
                this.props.closeMenu();
                this.props.refreshSteps();
              }}
            >
              <ConversationStepMessage conversationStep={step} />
            </div>
          ))}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    builderMenu: { id: stepIdToReconnect },
    conversations: { visibleConversationStepsForMenu },
  } = state;

  return {
    stepIdToReconnect,
    visibleConversationStepsForMenu,
  };
};

export default connect(
  mapStateToProps,
  { fetchVisibleConversationStepsForMenu, createReconnection }
)(SelectStepModal);
