import _ from "underscore";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  createTemplateCardButton,
  fetchVisibleConversationSteps,
  putTemplateCardButton,
  setTriggerChoice,
  toggleRandomizeButtons,
} from "../../actions/conversations";

import TemplateCardButtonModal from "./TemplateCardButtonModal";
import { BuilderInput } from "../../components/ConversationDetail";
import { SeparatedButtonRow, Toggle, Modal } from "../../components/shared";
import { openMenu, closeMenu } from "../../actions/builder-menu";
import { TEMPLATE_CARD_BUTTON } from "../../constants/builder-menu";
import MatchingResponsesModal from "../MatchingResponsesModal";

import { createEditorState } from "../../utilities/editor";

class TemplateCardButton extends Component {
  static propTypes = {
    button: PropTypes.object.isRequired,
    conversationId: PropTypes.number.isRequired,
    conversationStepId: PropTypes.number.isRequired,
    editable: PropTypes.bool,
    openFieldCapture: false,
  };

  constructor(props) {
    super(props);

    const {
      button: { buttonText },
    } = props;

    this.putTemplateCardButton = _.debounce(
      this.props.putTemplateCardButton,
      500
    );

    this.state = {
      isMenuOpen: false,
      editorState: createEditorState(buttonText),
      modal: "",
      openMatchingReponses: false,
    };
  }

  fieldCaptureModal = () => {
    const { button } = this.props;
    const onSubmit = e => {
      e.preventDefault();
      this.putTemplateCardButton({
        ...button,
        value: e.target.value || null,
      });
    };

    return (
      <Modal
        title="Edit Field Capture"
        isOpen={this.state.openFieldCapture}
        onClose={() => this.setState({ openFieldCapture: false })}
      >
        <div className="modal__controls">
          <form
            className="modal__form modal__form--wide"
            onSubmit={e => {
              e.preventDefault();
              this.setState({ openFieldCapture: false });
            }}
          >
            <label className="default-form__label" htmlFor="entry">
              Entry
            </label>
            <input
              className="default-form__input"
              defaultValue={this.props.button.value}
              type="text"
              name="entry"
              id="payload"
              onChange={onSubmit}
            />
            <div className="modal__footer">
              <button
                type="button"
                className="paloma-button paloma-button--white modal__button"
                onClick={() => this.setState({ openFieldCapture: false })}
              >
                Cancel
              </button>
              <button
                className="paloma-button paloma-button--pink modal__button"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  render() {
    const { button, editable = true, isMenuOpen, randomized } = this.props;

    const { modal } = this.state;

    const buttons = [
      {
        text: "Detour",
        onClick: this.handleCreateTrigger,
      },
      {
        text: "Connect",
        onClick: this.handleCustomButton,
      },
      {
        text: "Action",
        nested: [
          {
            text: "URL",
            onClick: this.handleURLButton,
            selected: button.buttonType === "url",
          },
          {
            text: "Phone",
            onClick: this.handlePhoneNumberButton,
            selected: button.buttonType === "phone_number",
          },
          {
            text: "Share",
            onClick: this.handleShare,
            active: button.buttonType === "share",
          },
          {
            text: "Login",
            onClick: this.handleLoginButton,
            selected: button.buttonType === "login",
          },
        ],
      },
      {
        text: "+ Button",
        onClick: () => {
          this.props.createTemplateCardButton(this.props.templateCard);
        },
      },
      {
        text: (
          <div>
            Randomize
            <Toggle
              onChange={this.toggleRandomizeButtonOrder}
              checked={randomized}
            />
          </div>
        ),
        leaveOpen: true,
        onClick: () => true,
      },
      {
        text: "+ Match Responses",
        onClick: () => this.setState({ openMatchingReponses: true }),
      },
      {
        text: "Field Capture",
        onClick: () => this.setState({ openFieldCapture: true }),
      },
    ];

    buttons.push({ text: "Delete", onClick: this.handleDeleteButton });

    return (
      <div className="conversation-step-button conversation-step-button__text">
        <div onClick={this.handleClick}>
          <BuilderInput
            className="conversation-step-button__input"
            editorState={this.state.editorState}
            onChange={this.handleChange}
            editable={editable}
            placeholder="Write a button title"
            placeholderClassName="conversation-step-button__input-placeholder"
          />
        </div>
        {editable && isMenuOpen && (
          <SeparatedButtonRow
            className="conversation-step-button__button-types"
            buttons={buttons}
          />
        )}
        <TemplateCardButtonModal
          button={button}
          modal={modal}
          onClose={this.closeModal}
        />
        <MatchingResponsesModal
          matchingResponses={this.props.button.matchingResponses}
          isOpen={this.state.openMatchingReponses}
          onClose={() => this.setState({ openMatchingReponses: false })}
          onSubmit={this.updateMatchingResponses}
        />
        {this.fieldCaptureModal()}
      </div>
    );
  }

  handleClick = () => {
    this.props.openMenu({
      id: this.props.button.conversationStepTemplateCardButtonId,
      type: TEMPLATE_CARD_BUTTON,
    });
  };

  updateMatchingResponses = matchingResponses => {
    const { button } = this.props;
    this.props.putTemplateCardButton({
      ...button,
      matchingResponses,
    });
  };

  handleEscape = () => {
    if (this.props.isMenuOpen) {
      this.props.closeMenu();
    }
  };

  handleChange = ({ state: editorState }) => {
    const { button } = this.props;

    this.setState({ editorState });

    this.putTemplateCardButton({
      ...button,
      buttonText: JSON.stringify(editorState.toJSON()),
    });
  };

  closeModal = () => this.setState({ modal: "" });

  handleURLButton = e => this.setState({ modal: "url" });
  handleLoginButton = e => this.setState({ modal: "login" });
  handlePhoneNumberButton = e => this.setState({ modal: "phone_number" });
  handleDeleteButton = e => this.setState({ modal: "delete" });
  handleCustomButton = e => this.setState({ modal: "custom" });

  handleShare = async e => {
    const {
      button,
      conversationId,
      fetchVisibleConversationSteps,
    } = this.props;

    await this.setState({ editorState: createEditorState("Share") });
    await this.putTemplateCardButton({
      ...button,
      buttonText: JSON.stringify(this.state.editorState.toJSON()),
      buttonType: "share",
    });

    fetchVisibleConversationSteps(conversationId);
  };

  handleCreateTrigger = async e => {
    const {
      button,
      conversationId,
      conversationStepId,
      fetchVisibleConversationSteps,
      setTriggerChoice,
    } = this.props;

    const response = await this.putTemplateCardButton({
      ...button,
      conversationStepId,
      buttonType: "trigger",
    });

    await setTriggerChoice({
      conversationStepId,
      triggerId: response.payload.button.conversationStepTriggerId,
    });

    fetchVisibleConversationSteps(conversationId);
  };

  toggleRandomizeButtonOrder = async random => {
    const {
      conversationId,
      conversationStepId,
      fetchVisibleConversationSteps,
      toggleRandomizeButtons,
    } = this.props;

    await toggleRandomizeButtons(conversationStepId, random);
    fetchVisibleConversationSteps(conversationId);
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;

  const isMenuOpen =
    menuType === TEMPLATE_CARD_BUTTON &&
    menuId === ownProps.button.conversationStepTemplateCardButtonId;

  return {
    isMenuOpen,
  };
};

export default connect(mapStateToProps, {
  closeMenu,
  createTemplateCardButton,
  fetchVisibleConversationSteps,
  openMenu,
  putTemplateCardButton,
  setTriggerChoice,
  toggleRandomizeButtons,
})(TemplateCardButton);
