import React from "react";

const EditConversationDetailsForm = ({
  conversationName,
  conversationId,
  utm,
  onSubmit,
}) => (
  <div className="card with-margin">
    <form className="default-form edit-details-form" onSubmit={onSubmit}>
      <label
        className="default-form__label"
        htmlFor="edit-conversation-details-name"
      >
        Name
      </label>
      <input
        type="text"
        className="default-form__input"
        name="label"
        defaultValue={conversationName}
        id="edit-conversation-details-name"
      />

      <label
        className="default-form__label"
        htmlFor="edit-conversation-details-utm"
      >
        UTM Parameters
      </label>
      <p style={{ fontSize: "11px" }}>
        *Enter a string of UTM parameters to be added to this conversation's
        <br /> URLs. (e.g. utm_source=Messenger&utm_campaign=WinterPromo)
      </p>
      <input
        type="text"
        className="default-form__input"
        name="utm"
        defaultValue={utm}
        id="edit-conversation-details-utm"
      />
      <button
        type="submit"
        className="paloma-button paloma-button--pink default-form__submit"
      >
        Update
      </button>
    </form>
  </div>
);

export default EditConversationDetailsForm;
