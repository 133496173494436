import React from "react";
import classNames from "classnames";

import { connectToFacebookPage } from "../../utilities/facebook";

const FacebookLoginButton = ({
  className = "",
  onFacebookLogin,
  text = "Login with Facebook",
}) => (
  <button
    className={classNames("facebook-login-button", className)}
    onClick={() => connectToFacebookPage().then(onFacebookLogin)}
  >
    {text}
  </button>
);

export default FacebookLoginButton;
