import React, { Component } from "react";
import PropTypes from "prop-types";

import TemplateCard from "./TemplateCard";

export default class TemplateMessage extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    templateCards: PropTypes.array.isRequired,
  };

  render() {
    const {
      editable = true,
      randomizeButtons,
      randomizeTemplateCards,
      templateCards,
      refreshSteps,
    } = this.props;

    return (
      <div className="conversation-step__template-message">
        {templateCards.map((templateCard, i) => (
          <TemplateCard
            editable={editable}
            key={templateCard.conversationStepTemplateCardId}
            templateCard={templateCard}
            errors={this.props.errors[i]}
            randomizeButtons={randomizeButtons}
            randomizeTemplateCards={randomizeTemplateCards}
            index={i}
            refreshSteps={refreshSteps}
          />
        ))}
      </div>
    );
  }
}
