import { State } from "slate";

export const getInitialEditorState = (text = "") =>
  State.fromJSON({
    document: {
      nodes: [
        {
          kind: "block",
          type: "paragraph",
          nodes: [
            {
              kind: "text",
              leaves: [{ text: text || "" }],
            },
          ],
        },
      ],
    },
  });

export const createEditorState = (text = "") => {
  try {
    return State.fromJSON(JSON.parse(text));
  } catch (e) {
    // if the value is plaintext for some reason
    return getInitialEditorState(text);
  }
};

export const parse = state => {
  if (!state) {
    return "";
  }
  return state.texts.reduce((acc, t) => t.text + acc, "");
};

export const parseEditorState = state => {
  if (state) {
    try {
      const json = JSON.parse(state);
      return State.fromJSON(json).texts.reduce((acc, t) => t.text + acc, "");
    } catch (e) {
      return state
    }
  } else {
    return "";
  }
};
