import React from "react";
import Select from "react-select";
import {
  Card,
  SubscriberFilter
} from "../shared";

export default class ReportingSection extends React.Component {
  render() {
    const { onRemove } = this.props;
    const fieldOptions = [
      {label: "Received Trigger", value: "Received Trigger"},
    ]

    return (
      <div style={{ marginBottom: 10 }}>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ fontWeight: "600" }}>{`Part ${this.props.id + 1}`}</h4>
            <button className="paloma-button" onClick={onRemove}>
              Remove
            </button>
          </div>
          <SubscriberFilter 
            targetFilters={this.props.targetFilters}
            onChange={targetFilters => this.props.onChange('targetFilters', targetFilters)}
            matchSetting={this.props.targetFilterMatchSetting}
            setMatchSetting={targetFilterMatchSetting => this.props.onChange('targetFilterMatchSetting', targetFilterMatchSetting)}
            fieldOptions={fieldOptions}
            filterRowComponent={(filter, i, handleChange, handleDeleteTargetFilter) => (
              <div className="conversation-publisher__target-filter" key={i} style={{ alignItems: "center" }}>
                <span style={{ paddingRight: 10 }}>Received Trigger:</span>
                <Select
                  className="conversation-publisher__target-filter__value-select"
                  defaultValue={filter.value}
                  value={filter.value}
                  options={Object.values(this.props.globalTriggersById).map(t => ({
                    label: t.name,
                    value: t.triggerId,
                  }))}
                  onChange={(selected) => {
                    handleChange("fieldDropdown", "Received Trigger");
                    handleChange("conditionDropdown", "did");
                    handleChange("value", selected.value)
                  }}
                  clearable={false}
                  openOnFocus={false}
                  openAfterFocus={false}
                />
                <button
                  className="conversation-publisher__target-filter__delete"
                  onClick={handleDeleteTargetFilter}
                />
              </div>
            )}
          />
        </Card>
      </div>
    );
  }
}