import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import {
  fetchWelcomeMessage,
  updateWelcomeMessage,
  deleteWelcomeMessage,
  duplicateTrigger,
} from "../../actions/welcome-messages";
import {
  Card,
  ContentHeader,
  ConversationsTable,
} from "../../components/shared";
import { MatchingResponseItem } from "../../components/GlobalTriggerDetail/MatchingResponses";
import DeleteWMessageModal from "./DeleteWMessageModal";

class WelcomeMessageDetail extends React.Component {
  state = {
    newMatchingResponse: false,
    displayDeleteModal: false,
  };

  componentDidMount() {
    this.props.fetchWelcomeMessage(this.props.welcomeMessageId);
  }

  onDuplicate = async () => {
    const { welcomeMessageId } = this.props;

    const {
      payload: { triggerId },
    } = await this.props.duplicateTrigger(welcomeMessageId);
    this.props.push(`/entrypoints/wm/${triggerId}/`);
  };

  updateMatchingResponses = async (id, matchingResponse) => {
    const { matchingResponses } = this.props.welcomeMessage;
    const { welcomeMessageId } = this.props;

    if (Number.isInteger(id)) {
      matchingResponses[id] = matchingResponse;
    } else {
      matchingResponses.push(matchingResponse);
      this.setState({ newMatchingResponse: false });
    }

    this.props.updateWelcomeMessage(welcomeMessageId, { matchingResponses });
  };

  removeMatchingResponse = (index) => {
    const matchingResponses = this.props.welcomeMessage.matchingResponses.filter(
      (_, i) => i !== index
    );
    this.props.updateWelcomeMessage(this.props.welcomeMessageId, {
      matchingResponses,
    });
  };

  deleteWelcomeMessage = () => {
    const { welcomeMessageId } = this.props;
    this.props.deleteWelcomeMessage(welcomeMessageId).then(() => {
      this.setState({ displayDeleteModal: false });
      this.props.push("/entrypoints");
    });
  };

  inactiveConversations = () => {
    const { welcomeMessage } = this.props;
    if (welcomeMessage.activeConversation) {
      return welcomeMessage.conversations.filter(
        (c) =>
          c.conversationId !== welcomeMessage.activeConversation.conversationId
      );
    }

    return welcomeMessage.conversations || [];
  };

  render() {
    const { welcomeMessage, isLoading, welcomeMessageId } = this.props;

    const { newMatchingResponse, displayDeleteModal } = this.state;
    const { matchingResponses = [] } = welcomeMessage || {};

    return (
      <div className="global-trigger-detail">
        <ContentHeader
          left={
            <h1 className="page-title">
              {welcomeMessage && welcomeMessage.name}
            </h1>
          }
          right={
            <div>
              <button
                className="paloma-button"
                onClick={() => this.setState({ displayDeleteModal: true })}
              >
                Delete
              </button>

              <Link
                className="paloma-button"
                to={`/entrypoints/wm/${welcomeMessageId}/edit`}
              >
                Edit details
              </Link>
              <button className="paloma-button" onClick={this.onDuplicate}>
                Duplicate
              </button>
            </div>
          }
        />
        <Card className="info-panel" laoding={isLoading}>
          {welcomeMessage ? (
            <Conversations
              welcomeMessageId={welcomeMessageId}
              inactiveConversations={this.inactiveConversations()}
              activeConversation={welcomeMessage.activeConversation}
              onDuplicate={() =>
                this.props.fetchWelcomeMessage(welcomeMessageId)
              }
            />
          ) : null}
        </Card>

        <Card>
          <div className="matching-responses">
            <h3>Matching responses</h3>
            <ContentHeader
              left={
                <div className="description" style={{ width: "80%" }}>
                  <p>
                    When subscribers send any of the following responses, they
                    will receive this welcome message's conversation. Exact
                    spelling matters, but capitalization doesn't.
                  </p>
                </div>
              }
              right={
                matchingResponses.length > 0 && (
                  <button
                    className="paloma-button"
                    onClick={() =>
                      !newMatchingResponse &&
                      this.setState({ newMatchingResponse: true })
                    }
                  >
                    + New
                  </button>
                )
              }
            />

            {!matchingResponses.length && !newMatchingResponse ? (
              <div>
                <p>
                  Add matching responses that will initiate the welcome message
                  conversation.
                </p>
                <button
                  className="paloma-button"
                  onClick={() =>
                    !newMatchingResponse &&
                    this.setState({ newMatchingResponse: true })
                  }
                >
                  + Matching Response
                </button>
              </div>
            ) : (
              <ul className="matching-responses__list">
                {newMatchingResponse && (
                  <MatchingResponseItem
                    id={null}
                    isEditing
                    key="new-matching-response"
                    matchingResponse=""
                    onSave={this.updateMatchingResponses}
                    onDelete={() =>
                      this.setState({ newMatchingResponse: false })
                    }
                  />
                )}
                {matchingResponses.map((matchingResponse, i) => (
                  <MatchingResponseItem
                    id={i}
                    key={i}
                    matchingResponse={matchingResponse}
                    onSave={this.updateMatchingResponses}
                    onDelete={this.removeMatchingResponse}
                  />
                ))}
              </ul>
            )}
          </div>
        </Card>
        <DeleteWMessageModal
          isOpen={displayDeleteModal}
          onClose={() => this.setState({ displayDeleteModal: false })}
          onSubmit={this.deleteWelcomeMessage}
        />
      </div>
    );
  }
}

const Conversations = ({
  welcomeMessageId,
  inactiveConversations,
  activeConversation,
  onDuplicate,
}) => (
  <div>
    <ContentHeader
      style={{ paddingBottom: 10, paddingTop: 5 }}
      left={<h3 style={{ paddingBottom: "13px" }}>Conversations</h3>}
      right={
        (activeConversation || inactiveConversations.length > 0) && (
          <Link
            className="paloma-button"
            to={`/entrypoints/wm/${welcomeMessageId}/conversations/new`}
          >
            + New
          </Link>
        )
      }
    />
    {inactiveConversations.length === 0 && !activeConversation ? (
      <div>
        <span>
          Create a conversation to be sent to subscribers when they submit a
          matching response.
        </span>
        <Link
          className="paloma-button"
          to={`/entrypoints/wm/${welcomeMessageId}/conversations/new`}
        >
          + Add Conversation
        </Link>
      </div>
    ) : (
      <div>
        <div>
          {activeConversation && (
            <ConversationsTable
              conversations={[activeConversation]}
              title="Active"
              onDuplicate={onDuplicate}
            />
          )}
        </div>
        <div>
          <ConversationsTable
            conversations={inactiveConversations}
            title="Inactive"
            onDuplicate={onDuplicate}
          />
        </div>
      </div>
    )}
  </div>
);

export default connect(
  (state, props) => {
    return {
      welcomeMessage:
        state.welcomeMessages.welcomeMessagesById[
          props.match.params.welcomeMessageId
        ],
      welcomeMessageId: props.match.params.welcomeMessageId,
      isLoading: state.welcomeMessages.isLoading,
    };
  },
  {
    fetchWelcomeMessage,
    updateWelcomeMessage,
    deleteWelcomeMessage,
    push,
    duplicateTrigger,
  }
)(WelcomeMessageDetail);
