import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/assign_subscriber_owner";

export const fetchAssignSubscriberOwner = triggerId => ({
  [CALL_API]: {
    endpoint: `/api/triggers/${triggerId}/assign_subscriber_owner`,
    method: "GET",
    types: [
      constants.FETCH_ASSIGN_SUBSCRIBER_OWNER_REQUEST,
      constants.FETCH_ASSIGN_SUBSCRIBER_OWNER_SUCCESS,
      constants.FETCH_ASSIGN_SUBSCRIBER_OWNER_FAILURE,
    ],
  },
});

export const createAssignSubscriberOwner = ({ triggerId, ownerId }) => ({
  [CALL_API]: {
    endpoint: "/api/triggers/assign_subscriber_owner",
    method: "POST",
    body: { triggerId, ownerId },
    types: [
      constants.CREATE_ASSIGN_SUBSCRIBER_OWNER_REQUEST,
      constants.CREATE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS,
      constants.CREATE_ASSIGN_SUBSCRIBER_OWNER_FAILURE,
    ],
  },
});

export const deleteAssignSubscriberOwner = triggerId => ({
  [CALL_API]: {
    endpoint: `/api/triggers/${triggerId}/assign_subscriber_owner`,
    method: "DELETE",
    types: [
      constants.DELETE_ASSIGN_SUBSCRIBER_OWNER_REQUEST,
      constants.DELETE_ASSIGN_SUBSCRIBER_OWNER_SUCCESS,
      constants.DELETE_ASSIGN_SUBSCRIBER_OWNER_FAILURE,
    ],
  },
});
