import { SHOW_ALERT, CLEAR_ALERTS } from "../constants/alerts";

const showAlert = (message, level, options = {}) => ({
  type: SHOW_ALERT,
  payload: {
    autoDismiss: 5, // set this to 0 if you want an alert that doesn't disappear on its own
    dismissible: true, // set this to false if you want an alert that can't be manually dismissed
    uid: Date.now(),
    ...options,
    message,
    level,
  },
});

export const showSuccessAlert = (message, options = {}) =>
  showAlert(message, "success", options);

export const showErrorAlert = (message, options = {}) =>
  showAlert(message, "error", options);

export const clearAlerts = () => ({ type: CLEAR_ALERTS });
