import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/conversation-analytics";

export const fetchConversationAnalytics = id => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${id}/analytics`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_CONVERSATION_ANALYTICS_REQUEST,
        meta: { id: id },
      },
      constants.FETCH_CONVERSATION_ANALYTICS_SUCCESS,
      constants.FETCH_CONVERSATION_ANALYTICS_FAILURE,
    ],
  },
});
