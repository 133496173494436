import _ from "underscore";
import Loader from "react-loader";
import React from "react";
import { Card, ContentHeader } from "../../components/shared";
import PersistentMenuToggle from "../../components/PersistentMenuToggle";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  disablePersistentMenu,
  enablePersistentMenu,
  createPersistentMenu,
  fetchPersistentMenu,
} from "../../actions/persistent-menu";
import { fetchCurrentOrganization } from "../../actions/current-organization";

import {
  GlobalTriggersTable,
  DefaultGlobalTriggersTable,
} from "../../components/GlobalTriggers";

import * as globalTriggerActions from "../../actions/global-triggers";
import { fetchGlobalDefaultConversations } from "../../actions/global-default-conversations";

class GlobalTriggersContainer extends React.Component {
  componentWillMount() {
    this.props.fetchGlobalDefaultConversations();
    this.props.fetchGlobalTriggers();
    this.props.fetchPersistentMenu();
  }

  default = () => {
    return _.select(this.props.globalTriggers, t => !t.deletable);
  };

  userDefined = () => {
    return _.select(this.props.globalTriggers, t => t.deletable);
  };

  onToggle = async persistentMenuEnabled => {
    if (persistentMenuEnabled) {
      await this.props.enablePersistentMenu();
      this.props.fetchPersistentMenu();
    } else {
      await this.props.disablePersistentMenu();
    }
    this.props.fetchCurrentOrganization();
  };

  render() {
    return (
      <Loader loaded={!this.props.isLoading} loadedClassName="global-triggers">
        <ContentHeader left={<h1 className="page-title">Triggers</h1>} />
        <PersistentMenuToggle
          create={async () => {
            await this.props.createPersistentMenu();
            this.props.push("/global/persistent_menu/build");
          }}
          menus={this.props.persistentMenus}
          onToggle={this.onToggle}
          enabled={this.props.persistentMenuEnabled}
        />
        <Card className="info-panel">
          <h3>Default Triggers</h3>
          <DefaultGlobalTriggersTable
            globalTriggers={this.default()}
            globalUnexpectedLastRanAt={this.props.globalUnexpectedLastRanAt}
          />
        </Card>
        <Card className="info-panel">
          <h3>Custom Triggers</h3>
          <GlobalTriggersTable
            duplicateTrigger={async id => {
              await this.props.duplicateTrigger(id);
              this.props.fetchGlobalTriggers();
            }}
            globalTriggers={this.userDefined()}
            onDeleteTrigger={this.props.deleteGlobalTrigger}
          />
        </Card>
      </Loader>
    );
  }
}

const mapStateToProps = state => {
  return {
    globalUnexpectedLastRanAt: state.globalDefaultConversations.lastRanAt,
    globalTriggers: Object.values(state.globalTriggers.globalTriggersById),
    persistentMenus: Object.values(state.persistentMenu.menus),
    persistentMenuEnabled: state.currentOrganization.persistentMenuEnabled,
    isLoading: state.globalTriggers.isLoading,
  };
};

export default connect(
  mapStateToProps,
  {
    ...globalTriggerActions,
    createPersistentMenu,
    disablePersistentMenu,
    enablePersistentMenu,
    fetchCurrentOrganization,
    fetchGlobalDefaultConversations,
    fetchPersistentMenu,
    push,
  }
)(GlobalTriggersContainer);
