import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/organizations";

export const fetchOrganizations = () => ({
  [CALL_API]: {
    endpoint: "/api/organizations/",
    method: "GET",
    types: [
      constants.FETCH_ORGANIZATIONS_REQUEST,
      constants.FETCH_ORGANIZATIONS_SUCCESS,
      constants.FETCH_ORGANIZATIONS_FAILURE,
    ],
  },
});
