import React from "react";

import { Modal } from "../shared";

export default class DeleteUserResponseModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
  }

  handleClose(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onClose();
  }

  handleDeleteConfirmed(ev) {
    this.props.handleDeleteConfirmed(ev);
  }

  render() {
    return (
      <Modal
        title="Delete user response?"
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
      >
        <div className="modal__controls">
          <button
            type="button"
            onClick={this.handleClose}
            className="paloma-button modal__button"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={this.handleDeleteConfirmed}
            className="paloma-button modal__button modal__button--red"
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }
}
