export const FETCH_SUBSCRIBER_TAGS_REQUEST = "FETCH_SUBSCRIBER_TAGS_REQUEST";
export const FETCH_SUBSCRIBER_TAGS_SUCCESS = "FETCH_SUBSCRIBER_TAGS_SUCCESS";
export const FETCH_SUBSCRIBER_TAGS_FAILURE = "FETCH_SUBSCRIBER_TAGS_FAILURE";

export const CREATE_SUBSCRIBER_TAG_REQUEST = "CREATE_SUBSCRIBER_TAG_REQUEST";
export const CREATE_SUBSCRIBER_TAG_SUCCESS = "CREATE_SUBSCRIBER_TAG_SUCCESS";
export const CREATE_SUBSCRIBER_TAG_FAILURE = "CREATE_SUBSCRIBER_TAG_FAILURE";

export const DELETE_SUBSCRIBER_TAG_REQUEST = "DELETE_SUBSCRIBER_TAG_REQUEST";
export const DELETE_SUBSCRIBER_TAG_SUCCESS = "DELETE_SUBSCRIBER_TAG_SUCCESS";
export const DELETE_SUBSCRIBER_TAG_FAILURE = "DELETE_SUBSCRIBER_TAG_FAILURE";
