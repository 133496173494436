import React from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import pick from "lodash/pick";

import {
  Card,
  ContentHeader,
  DeleteConversationModal,
  LinksTable,
} from "../../components/shared";
import MessagesSidebar from "../../components/ConversationDetail/MessagesSidebar";
import {
  ConversationDetailsPerformance,
  ConversationDetailsSummary,
  ConversationRecipientsTable,
  PublishConfirmationModal,
  UnpublishedConversationCTA,
} from "../../components/ConversationDetail";
import {
  closePublishConfirmationModal,
  downloadConversationCsv,
  duplicateConversation,
  fetchConversation,
  fetchConversationReportsDownloadToken,
  fetchVisibleConversationSteps,
} from "../../actions/conversations";
import { deleteConversationSchedule } from "../../actions/conversation-schedules";
import { fetchConversationAnalytics } from "../../actions/conversation-analytics";
import { fetchLinksBreakdown } from "../../actions/conversations";
import { fetchCampaign } from "../../actions/campaigns";
import { fetchGlobalTrigger } from "../../actions/global-triggers";
import { getConversationLink } from "../../utilities/conversation";
import { dateInWords, timeOnly } from "../../utilities/format-date";

const ConversationActionButtons = ({
  conversation,
  downloadLink,
  onDelete,
  onDuplicate,
}) => {
  const editUrl = getConversationLink(conversation) + "/edit";

  return (
    <div className="conversation-detail-action-buttons">
      {conversation.published && (
        <button className="paloma-button" onClick={downloadLink}>
          Download
        </button>
      )}
      <button className="paloma-button" onClick={onDelete}>
        Delete
      </button>
      {!(conversation.published || conversation.scheduled) && (
        <Link className="paloma-button" to={editUrl}>
          Edit details
        </Link>
      )}
      <button className="paloma-button" onClick={onDuplicate}>
        Duplicate
      </button>
    </div>
  );
};

class ConversationDetailsContainer extends React.Component {
  state = {
    displayDeleteModal: false,
    redirectUrl: null,
    links: [],
    fetchingLinks: false,
  };

  async componentDidMount() {
    await this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.redirectUrl) this.setState({ redirectUrl: null });
  }

  render() {
    const {
      conversation,
      hasShopifyStorefront,
      triggerId,
      conversationId,
      analytics,
    } = this.props;
    const { displayDeleteModal, redirectUrl } = this.state;

    if (redirectUrl) return <Redirect push to={redirectUrl} />;

    return (
      <Loader loaded={!conversation.isLoading}>
        <div className="row">
          <div className="conversation-detail">
            <ContentHeader
              left={<h1>{conversation.label}</h1>}
              right={
                <ConversationActionButtons
                  conversation={conversation}
                  downloadLink={() =>
                    this.props.downloadConversationCsv(
                      conversation.conversationId
                    )
                  }
                  onDelete={this.showDeleteModal}
                  onDuplicate={this.onDuplicate}
                  triggerId={triggerId}
                />
              }
            />
            <div className="row">
              <div className="main">
                <ConversationDetailsSummary conversation={conversation} />

                {conversation.scheduled && !conversation.published ? (
                  <div className="unpublished-conversation-cta">
                    <div>
                      <p>
                        Your conversation have been scheduled to be published on{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {dateInWords(conversation.sendAt)} at{" "}
                          {timeOnly(conversation.sendAt)}
                        </span>
                        .<br /> Unschedule it to make changes, or publish it
                        now.
                      </p>
                    </div>

                    <div className="unpublished-conversation-cta-buttons">
                      <button
                        className="paloma-button paloma-button--pink"
                        onClick={this.unschedule}
                      >
                        Unschedule
                      </button>
                    </div>
                  </div>
                ) : null}

                {conversation.published && (
                  <ConversationDetailsPerformance
                    carts={conversation.carts}
                    numRecipients={conversation.numSubscribers}
                    hasShopifyStorefront={hasShopifyStorefront}
                    purchases={conversation.orders}
                    totalConversions={conversation.totalConversions}
                    {...analytics}
                  />
                )}
                {conversation.published ? (
                  <Card
                    className="info-panel links-table"
                    loading={this.state.fetchingLinks}
                  >
                    <h3>Links</h3>
                    <LinksTable
                      links={this.state.links}
                      loading={this.state.fetchingLinks}
                      {...pick(analytics, ["totalClicks", "uniqueClicks"])}
                    />
                  </Card>
                ) : null}

                {!conversation.published && !conversation.scheduled && (
                  <UnpublishedConversationCTA
                    conversation={conversation}
                    triggerId={triggerId}
                  />
                )}

                {conversation.published && (
                  <Card>
                    <ConversationRecipientsTable
                      conversationId={conversationId}
                      clicked={(conversation.analytics || {}).uniqueClickers}
                      list={conversation.targetSubscriberList}
                      matchSetting={
                        conversation.targetFilterMatchSetting || "All"
                      }
                      segments={conversation.targetFilters}
                      type={conversation.type}
                    />
                  </Card>
                )}

                {displayDeleteModal && (
                  <DeleteConversationModal
                    conversation={conversation}
                    onClose={this.hideDeleteModal}
                    onDelete={this.redirectToParent.bind(null, conversation)}
                  />
                )}
                {!!conversation.showPublishConfirmationModal && (
                  <PublishConfirmationModal
                    isOpen={true}
                    onClose={this.handleClosePublishConfirmationModal}
                  />
                )}
              </div>
              {conversation.published && (
                <MessagesSidebar conversation={conversation} />
              )}
            </div>
          </div>
        </div>
      </Loader>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => this.setState({ displayDeleteModal: false });

  fetchData = async () => {
    const {
      conversationId,
      campaignId,
      triggerId,

      fetchCampaign,
      fetchConversation,
      fetchConversationAnalytics,
      fetchLinksBreakdown,
      fetchGlobalTrigger,
      fetchVisibleConversationSteps,
    } = this.props;

    const promises = [
      fetchConversation(conversationId),
      fetchConversationAnalytics(conversationId),
      fetchVisibleConversationSteps(conversationId),
    ];

    this.setState({ fetchingLinks: true });
    this.props
      .fetchLinksBreakdown(conversationId)
      .then(({ payload }) => this.setState({ links: payload }))
      .finally(() => this.setState({ fetchingLinks: false }));

    if (campaignId) promises.push(fetchCampaign(campaignId));
    else if (triggerId) promises.push(fetchGlobalTrigger(triggerId));

    await Promise.all(promises);
  };

  // the conversation gets deleted and is no longer in Redux, which
  // is why we bind it to the function
  redirectToParent = (conversation) => {
    let redirectUrl;
    switch (conversation.type) {
      case "signup":
        redirectUrl = "/acquisition";
        break;
      case "global":
        redirectUrl = `/global/${conversation.triggerId}`;
        break;
      default:
        // campaign
        redirectUrl = `/campaigns/${conversation.campaignId}`;
        break;
    }

    this.setState({
      displayDeleteModal: false,
      redirectUrl,
    });
  };

  onDuplicate = async () => {
    const { conversation, conversationId } = this.props;

    const {
      payload: { conversationId: duplicateConversationId },
    } = await this.props.duplicateConversation({ conversationId });

    const redirectUrl =
      getConversationLink({
        ...conversation,
        conversationId: duplicateConversationId,
      }) + "/details";

    this.setState({ redirectUrl });
  };

  handleClosePublishConfirmationModal = () => {
    this.props.closePublishConfirmationModal(this.props.conversationId);
  };

  unschedule = () => {
    this.props.deleteConversationSchedule(this.props.conversationId);
  };
}

const mapStateToProps = (state, ownProps) => {
  const conversationId = ownProps.match.params.conversationId;
  const conversation = {
    isLoading: true,
    ...state.conversations.conversationsById[conversationId],
  };

  const { analytics = {} } = conversation;

  return {
    campaignId: ownProps.match.params.campaignId,
    conversation: conversation,
    conversationId: conversationId,
    analytics,
    hasShopifyStorefront: state.currentOrganization.hasShopifyStorefront,
    triggerId: ownProps.match.params.triggerId,
    visibleConversationSteps: (
      state.conversations.visibleConversationStepsByConversationId[
        conversationId
      ] || {}
    ).stepIds,
  };
};

export default connect(mapStateToProps, {
  closePublishConfirmationModal,
  deleteConversationSchedule,
  downloadConversationCsv,
  duplicateConversation,
  fetchCampaign,
  fetchConversation,
  fetchConversationAnalytics,
  fetchLinksBreakdown,
  fetchConversationReportsDownloadToken,
  fetchGlobalTrigger,
  fetchVisibleConversationSteps,
})(ConversationDetailsContainer);
