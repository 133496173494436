const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const add = (a, b) => a + b;

const average = collection =>
  collection.length ? collection.reduce(add) / collection.length : 0;

const get = (o, p) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

const deleteEmpty = obj =>
  Object.keys(obj).reduce((acc, key) => {
    if (!!obj[key]) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

const composeUrl = (url, params) => {
  return [
    url,
    Object.keys(deleteEmpty(params) || {})
      .map(
        key =>
          !!params[key]
            ? encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            : ""
      )
      .join("&"),
  ].join("?");
};

const combineObjects = objects => {
  if (!objects || !objects.length) {
    return {};
  }
  return objects.reduce((obj, acc) => ({ ...acc, ...obj }), {});
};

export { ALPHABET, average, combineObjects, composeUrl, get };
