export const CREATE_CONVERSATION_SCHEDULE_REQUEST =
  "CREATE_CONVERSATION_SCHEDULE_REQUEST";
export const CREATE_CONVERSATION_SCHEDULE_SUCCESS =
  "CREATE_CONVERSATION_SCHEDULE_SUCCESS";
export const CREATE_CONVERSATION_SCHEDULE_FAILURE =
  "CREATE_CONVERSATION_SCHEDULE_FAILURE";

export const DELETE_CONVERSATION_SCHEDULE_REQUEST =
  "DELETE_CONVERSATION_SCHEDULE_REQUEST";
export const DELETE_CONVERSATION_SCHEDULE_SUCCESS =
  "DELETE_CONVERSATION_SCHEDULE_SUCCESS";
export const DELETE_CONVERSATION_SCHEDULE_FAILURE =
  "DELETE_CONVERSATION_SCHEDULE_FAILURE";

export const UPDATE_CONVERSATION_SCHEDULE_REQUEST =
  "UPDATE_CONVERSATION_SCHEDULE_REQUEST";
export const UPDATE_CONVERSATION_SCHEDULE_SUCCESS =
  "UPDATE_CONVERSATION_SCHEDULE_SUCCESS";
export const UPDATE_CONVERSATION_SCHEDULE_FAILURE =
  "UPDATE_CONVERSATION_SCHEDULE_FAILURE";
