import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal } from "../shared";

import { deleteConversation } from "../../actions/conversations";

class DeleteConversationModal extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal title="Delete conversation?" isOpen={true} onClose={onClose}>
        <div className="modal__controls">
          <button
            type="button"
            className="paloma-button modal__button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="paloma-button modal__button modal__button--red"
            onClick={this.deleteConversation}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }

  deleteConversation = async () => {
    const {
      conversation: { conversationId },
      deleteConversation,
      onClose,
      onDelete,
    } = this.props;

    await deleteConversation(conversationId);
    await onClose();

    onDelete();
  };
}

export default connect(
  null,
  {
    deleteConversation,
  }
)(DeleteConversationModal);
