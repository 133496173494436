import io from 'socket.io-client';
const socket = io.connect(process.env.REACT_APP_SOCKETS_URL);

socket.on('connect', () => {
  socket
    .emit('authenticate', { token: window.localStorage.getItem("paloma_session_token") }) //send the jwt
    .on('unauthorized', (msg) => {
      console.log(`Socket Connection Failure; Unauthorized: ${JSON.stringify(msg.data)}`);
    })
});

export function subscibeToEvent(eventName, organizationId, callback) {
	//on receipt of event, pass value to call back function
  socket.on(eventName, value => callback(null, value));
  //emit the 'subscribeToOrganization' event to let the server know we would like to recieve message events for our organization
  socket.emit('subscribeToOrganization', organizationId);
}

export function unsubscibeFromEvent(eventName) {
	//on receipt of event, pass value to call back function
  socket.off(eventName);
}