import React from "react";
import { connect } from "react-redux";
import { fetchProducts } from "../actions/inventory";
import SearchablePaginatedOptionModal from "./shared/SearchablePaginatedOptionModal"

class SelectProductModal extends React.Component {
  componentDidMount() {
    this.fetch(1);
  }

  fetch = (page, search = null) => {
    const perPage = 7;
    this.props.fetchProducts(page, search, perPage);
  }

  render() {
    return (
      <div className="select-product-modal">
        <SearchablePaginatedOptionModal
          multiSelect={!this.props.singleSelect}
          onClose={this.props.onClose}
          isOpen={this.props.isOpen}
          title={"Select Products"}
          saveButtonText={this.props.saveButtonText}
          options={this.props.products}
          idForOption={(option) => { return option.productId }}
          rowComponent={(option, selectedIndex) => (
            <ProductRow object={option} selectedIndex={selectedIndex} />
          )}
          onSave={(selectedOptionIds) => this.props.onInsert(selectedOptionIds)}
          controlPaginationExternally
          totalPages={this.props.totalPages}
          onPageOrSearch={(page, searchTerm) => this.fetch(page, searchTerm)}
        />
      </div>
    );
  }
}

const ProductRow = ({ object, selectedIndex }) => {
  return (
    <React.Fragment>
      <div>
        {selectedIndex ? `${selectedIndex}. ${object.title}` : object.title}
      </div>
      <div style={{ color: "#000", fontWeight: "normal" }}>
        {object.material || "None"}
      </div>
      <div style={{ color: "#000", fontWeight: "normal" }}>
        {object.color || "None"}
      </div>
      <div style={{ color: "#000", fontWeight: "normal" }}>
        {object.size || "None"}
      </div>
      <div style={{ color: "#000", fontWeight: "normal" }}>
        ${object.price || "None"}
      </div>
      <div style={{ width: "unset", margin: "0 20px" }}>
        <span
          className={`status-dot ${
            object.available ? "available" : "inactive"
          }`}
        />
      </div>
    </React.Fragment>
  )
}

export default connect(
  state => {
    const { pagination, products } = state.inventory;

    return {
      products,
      totalPages: pagination.totalPages,
    };
  },
  { fetchProducts }
)(SelectProductModal);
