import humps from "humps";
import _ from "underscore";
import infoIcon from "../../images/info.svg";
import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import {
  createButton,
  deleteConversationStep,
  putConversationStep,
  putConversationStepTrigger,
  putFeedbackTrigger,
  setTriggerChoice,
  showInsertFieldModal,
  uploadConversationStepMedia,
  removeReconnection,
  updateStepRequirement,
} from "../../actions/conversations";
import { showSuccessAlert } from "../../actions/alerts";
import { createCustomEvent } from "../../actions/custom-events";
import { openMenu, closeMenu } from "../../actions/builder-menu";
import {
  CONVERSATION_STEP,
  CONVERSATION_STEPS,
} from "../../constants/builder-menu";

import {
  FbTrackingModal,
  SetResponseAsFieldModal,
  DeleteConversationStepModal,
  ConversationStepMessage,
} from "../../components/ConversationDetail";
import CopyToClipboard from "../../components/CopyToClipboard";
import {
  fetchOrganizationFields,
  putOrganizationField,
} from "../../actions/organization-fields";
import { createEditorState } from "../../utilities/editor";
import { SeparatedButtonRow } from "../../components/shared";
import { Hamburger, Toggle } from "../../components/shared";
import UserResponse from "./UserResponse";

class ConversationStepContainer extends Component {
  constructor(props) {
    super(props);

    this.putConversationStep = _.debounce(this.props.putConversationStep, 250);
  }

  state = {
    deleteModalOpen: false,
    displayFieldModal: false,
    fbTrackingModal: false,
  };

  removeReconnection = async () => {
    const stepId = this.props.conversationStep.conversationStepId;
    await this.props.removeReconnection({ stepId });
    this.props.refreshSteps();
  };

  shouldDisplayDetours = () => {
    const { displayDetours } = this.props;
    if (displayDetours === undefined || displayDetours === null) {
      return true;
    }
    return !!displayDetours;
  };

  render() {
    const {
      conversationId,
      conversationStep,
      displayStepId,
      editable,
      menuIsOpen,
      organizationFields,
      putOrganizationField,
      selectedTriggerId,
      useFbCustomEvents,
    } = this.props;

    const { displayFieldModal, fbTrackingModal } = this.state;

    const stepClassNames = () =>
      classNames("conversation-step", {
        "conversation-step--show-outline": menuIsOpen,
      });

    if (!conversationStep) {
      return <div />;
    }

    const displayDetours = this.shouldDisplayDetours();

    return (
      <div>
        <div className={stepClassNames()}>
          {editable && (
            <Hamburger
              onClick={() => {
                this.toggleMenu(!menuIsOpen);
                this.props.fetchOrganizationFields();
              }}
            />
          )}
          <div className="conversation-step__bot-message-container">
            <div style={{ position: "relative" }}>
              {!editable && displayStepId && (
                <span
                  className="tooltip-container preview"
                  data-balloon={"ID: " + conversationStep.conversationStepId}
                  data-balloon-pos="down"
                >
                  <img
                    src={infoIcon}
                    alt={conversationStep.conversationStepId}
                  />
                </span>
              )}
              <ConversationStepMessage
                conversationStep={conversationStep}
                editable={editable}
                handleFieldChange={this.handleFieldChange}
                uploadConversationStepMedia={this.uploadConversationStepMedia}
                refreshSteps={this.props.refreshSteps}
              />
            </div>
            {selectedTriggerId && displayDetours ? (
              <StartDetourDivider />
            ) : null}
            {((conversationStep.organizationField &&
              conversationStep.triggers.length < 1) ||
              conversationStep.userPlaceholderText ||
              selectedTriggerId) && (
              <UserResponse
                conversationId={conversationId}
                conversationStep={conversationStep}
                editable={editable}
                triggerId={selectedTriggerId}
              />
            )}
            {conversationStep.reconnection && displayDetours ? (
              <ReconnectDetourDivider
                onEdit={this.openReconnectMenu}
                onRemove={this.removeReconnection}
              />
            ) : null}
          </div>
          <DeleteConversationStepModal
            handleDeleteConfirmed={this.handleDeleteConversationStepConfirmed}
            isOpen={this.state.deleteModalOpen}
            onClose={() => this.setState({ deleteModalOpen: false })}
          />
          {displayFieldModal && (
            <SetResponseAsFieldModal
              handleSetResponseAsField={this.handleAddFieldResponse}
              putOrganizationField={putOrganizationField}
              isOpen={true}
              organizationFields={organizationFields}
              onClose={this.hideAddFieldResponseModal}
            />
          )}
          {fbTrackingModal && useFbCustomEvents && (
            <FbTrackingModal
              createCustomEvent={async (eventName, conversationStepId) => {
                await this.props.createCustomEvent(
                  eventName,
                  conversationStepId
                );
                this.props.refreshSteps();
              }}
              onClose={this.hideFbTrackingModal}
              conversationStepId={
                this.props.conversationStep.conversationStepId
              }
              customEvents={this.props.conversationStep.customEvents}
            />
          )}
          {false && <pre>{JSON.stringify(conversationStep, null, 4)}</pre>}
        </div>
        {menuIsOpen && <SeparatedButtonRow buttons={this.menuOptions()} />}
      </div>
    );
  }

  openReconnectMenu = () => {
    this.props.openMenu({
      id: this.props.conversationStep.conversationStepId,
      type: CONVERSATION_STEPS,
    });
    this.props.refreshSteps();
  };

  hideFbTrackingModal = () => this.setState({ fbTrackingModal: false });

  handleDeleteConversationStepConfirmed = () => {
    this.setState({ deleteModalOpen: false }, () =>
      this.props
        .deleteConversationStep(this.props.conversationStepId)
        .then(this.props.refreshSteps)
    );
  };

  handleFieldChange = (conversationStep, field, value) => {
    const modifiedStep = { ...conversationStep, [field]: value };
    this.saveConversationStep(modifiedStep);
  };

  saveConversationStep = async conversationStep => {
    this.putConversationStep(conversationStep);
  };

  toggleMenu = open => {
    if (open) {
      this.props.openMenu({
        id: this.props.conversationStepId,
        type: CONVERSATION_STEP,
      });
    } else {
      this.props.closeMenu();
    }
  };

  uploadConversationStepMedia = data => {
    this.props.uploadConversationStepMedia({
      id: this.props.conversationStep.conversationStepId,
      data,
    });
  };

  handleDeleteStep = () => this.setState({ deleteModalOpen: true });

  showAddFieldResponseModal = e => this.setState({ displayFieldModal: true });
  hideAddFieldResponseModal = e => this.setState({ displayFieldModal: false });

  handleAddTextResponse = () => {
    const { putConversationStep } = this.props;

    if (!this.props.conversationStep)
      throw new Error("Cannot add user response without conversation step");

    putConversationStep({
      ...this.props.conversationStep,
      organizationFieldId: null,
      userPlaceholderText: JSON.stringify(createEditorState("").toJSON()),
    });
  };

  handleAddFeedbackResponse = async e => {
    const {
      conversationStep,
      putFeedbackTrigger,
      putConversationStep,
    } = this.props;

    const trigger = await putFeedbackTrigger({
      sourceConversationStepId: conversationStep.conversationStepId,
    });
    putConversationStep({
      ...conversationStep,
      organizationFieldId: null,
    });
    this.props.setTriggerChoice({
      conversationStepId: conversationStep.conversationStepId,
      triggerId: trigger.payload.feedbackTrigger.triggerId,
    });
  };

  handleAddFieldResponse = async organizationFieldId => {
    const { putConversationStep } = this.props;

    await putConversationStep({
      ...this.props.conversationStep,
      organizationFieldId,
      userPlaceholderText: JSON.stringify(createEditorState("").toJSON()),
    });
  };

  menuOptions = () => {
    const options = [
      {
        text: "+ Response",
        nested: [
          {
            text: "Text",
            onClick: this.handleAddTextResponse,
          },
          {
            text: "Capture Field",
            onClick: this.showAddFieldResponseModal,
          },
          {
            text: "Capture Feedback",
            onClick: this.handleAddFeedbackResponse,
          },
        ],
      },
      {
        text: "Copy",
        onClick: () => {
          this.props.copyStep(this.props.conversationStep.conversationStepId);
          this.toggleMenu(false);
        },
      },
      {
        text: "Insert Above",
        onClick: async () => {
          await this.toggleMenu(false);
          this.props.insertAbove(
            this.props.conversationStep.conversationStepId
          );
        },
      },
      {
        text: "+ FB Tracking",
        onClick: async () => {
          this.setState({ fbTrackingModal: true });
        },
      },
      {
        text: (
          <div>
            <span>Required</span>
            <Toggle
              checked={this.props.conversationStep.required}
              onChange={_.noop}
            />
          </div>
        ),
        onClick: async () => {
          const {
            conversationStepId: stepId,
            conversationStep: { required },
          } = this.props;

          await this.props.updateStepRequirement(stepId, !required);
        },
        leaveOpen: true,
      },
      { text: "Delete", onClick: this.handleDeleteStep },
    ];
    if (this.props.admin) {
      options.push({
        text: (
          <CopyToClipboard
            buttonText="Copy JSON"
            onSuccess={() => {
              this.props.showSuccessAlert("Copied!");
            }}
            toCopy={JSON.stringify(
              humps.decamelizeKeys(this.props.conversationStep.rendered),
              null,
              4
            )}
          />
        ),
        onClick: () => null,
      });
    }
    return options;
  };
}

const mapStateToProps = (state, ownProps) => {
  const { conversationStepId } = ownProps;
  const {
    conversations: {
      conversationStepsById,
      triggerChoicesByConversationStepId,
    },
    organizationFields: { organizationFields },
    currentOrganization: { useFbCustomEvents },
    currentUser: {
      currentUser: { admin },
    },
    builderMenu: { id: menuId, type: menuType },
  } = state;

  const menuIsOpen =
    menuId === conversationStepId && menuType === CONVERSATION_STEP;

  const conversationStep = conversationStepsById[conversationStepId];

  return {
    admin,
    conversationStep,
    conversationStepId,
    organizationFields,
    selectedTriggerId: triggerChoicesByConversationStepId[conversationStepId],
    menuIsOpen,
    useFbCustomEvents,
  };
};

ConversationStepContainer.defaultProps = {
  displayStepId: true,
};

export default connect(mapStateToProps, {
  closeMenu,
  createButton,
  createCustomEvent,
  deleteConversationStep,
  fetchOrganizationFields,
  openMenu,
  putConversationStep,
  putConversationStepTrigger,
  putFeedbackTrigger,
  putOrganizationField,
  setTriggerChoice,
  showInsertFieldModal,
  showSuccessAlert,
  uploadConversationStepMedia,
  removeReconnection,
  updateStepRequirement,
})(ConversationStepContainer);

export const StartDetourDivider = () => (
  <div className="detour-divider">
    <div className="detour-button">Detour starts</div>
  </div>
);

const ReconnectDetourDivider = ({ onEdit, onRemove }) => (
  <div className="detour-divider">
    <div className="detour-button">
      <div className="detour-section">Detour reconnects</div>
      <div className="detour-section">
        <a onClick={onEdit}>Edit</a>
      </div>
      <div className="detour-section">
        <a onClick={onRemove}>Remove</a>
      </div>
    </div>
  </div>
);
