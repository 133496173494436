import React from "react";
import PropTypes from "prop-types";

const Segment = ({ conditionDropdown, fieldDropdown, value }) => (
  <div className="segment">
    <strong>{fieldDropdown}</strong>&nbsp;
    {conditionDropdown}&nbsp;
    {value}
  </div>
);

Segment.propTypes = {
  conditionDropdown: PropTypes.string.isRequired,
  fieldDropdown: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Segment;
