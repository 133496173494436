import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/campaigns";

export const fetchCampaigns = () => ({
  [CALL_API]: {
    endpoint: "/api/campaigns/",
    method: "GET",
    types: [
      constants.FETCH_CAMPAIGNS_REQUEST,
      constants.FETCH_CAMPAIGNS_SUCCESS,
      constants.FETCH_CAMPAIGNS_FAILURE,
    ],
  },
});

export const fetchCampaign = campaignId => ({
  [CALL_API]: {
    endpoint: `/api/campaigns/${campaignId}/`,
    method: "GET",
    types: [
      {
        type: constants.FETCH_CAMPAIGN_REQUEST,
        meta: { campaignId },
      },
      constants.FETCH_CAMPAIGN_SUCCESS,
      {
        type: constants.FETCH_CAMPAIGN_FAILURE,
        meta: { campaignId },
      },
    ],
  },
});

export const putCampaign = payload => async dispatch => {
  const actionResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/api/campaigns/`,
      method: "PUT",
      body: JSON.stringify({ campaign: payload }),
      types: [
        {
          type: constants.PUT_CAMPAIGN_REQUEST,
          meta: payload.campaign_id ? { campaignId: payload.campaign_id } : {},
        },
        constants.PUT_CAMPAIGN_SUCCESS,
        {
          type: constants.PUT_CAMPAIGN_FAILURE,
          meta: payload.campaign_id ? { campaignId: payload.campaign_id } : {},
        },
      ],
    },
  });
  if (actionResponse.error) {
    throw new Error(actionResponse);
  }

  return actionResponse;
};

export const deleteCampaign = campaignId => ({
  [CALL_API]: {
    endpoint: `/api/campaigns/${campaignId}/`,
    method: "DELETE",
    types: [
      {
        type: constants.DELETE_CAMPAIGN_REQUEST,
        meta: { campaignId },
      },
      {
        type: constants.DELETE_CAMPAIGN_SUCCESS,
        // pass campaignId to the reducer so the campaign can be removed from the store
        meta: { campaignId },
      },
      {
        type: constants.DELETE_CAMPAIGN_FAILURE,
        meta: { campaignId },
      },
    ],
  },
});

export const duplicateCampaign = ({ campaignId }) => ({
  [CALL_API]: {
    endpoint: `/api/campaigns/${campaignId}/duplicate`,
    method: "POST",
    types: [
      constants.DUPLICATE_CAMPAIGN_REQUEST,
      constants.DUPLICATE_CAMPAIGN_SUCCESS,
      constants.DUPLICATE_CAMPAIGN_FAILURE,
    ],
  },
});

export const fetchCampaignConversations = (campaignId, page = 1) => ({
  [CALL_API]: {
    endpoint: `/api/campaign_conversations/${campaignId}/?page=${page}`,
    method: "GET",
    types: [
      constants.FETCH_CAMPAIGN_CONVERSATIONS_REQUEST,
      {
        type: constants.FETCH_CAMPAIGN_CONVERSATIONS_SUCCESS,
        meta: { campaignId },
      },
      constants.FETCH_CAMPAIGN_CONVERSATIONS_FAILURE,
    ],
  },
});

export const fetchCampaignLinks = (campaignId, page = 1) => ({
  [CALL_API]: {
    endpoint: `/api/campaign/${campaignId}/links?page=${page}`,
    method: "GET",
    types: [
      "FETCH_CAMPAIGN_LINKS_REQUEST",
      "FETCH_CAMPAIGN_LINKS_SUCCESS",
      "FETCH_CAMPAIGN_LINKS_FAILURE",
    ],
  },
});
