import React from "react";

import { Modal } from "../shared";

export default class FbTrackingModal extends React.Component {
  constructor(props) {
    super(props);

    const eventName = props.customEvents.length
      ? props.customEvents[0].eventName
      : "";

    this.state = { eventName };
  }

  createCustomEvent = () => {
    this.props.createCustomEvent(
      this.state.eventName,
      this.props.conversationStepId
    );
    this.props.onClose();
  };

  render() {
    return (
      <div className="fb-custom-event-modal">
        <Modal
          title="Add a custom FB event"
          isOpen={true}
          onClose={this.props.onClose}
        >
          <div className="modal__top-input">
            <div>
              <input
                type="text"
                name="entry"
                value={this.state.eventName}
                onChange={e => this.setState({ eventName: e.target.value })}
              />
              <button
                type="submit"
                onClick={this.createCustomEvent}
                className="paloma-button paloma-button--pink"
              >
                Create
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
