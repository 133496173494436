import { CALL_API } from "redux-api-middleware";

export default store => next => action => {
  const callApi = action[CALL_API];

  // Check if this action is a redux-api-middleware action.
  if (callApi) {
    const sessionToken = window.localStorage.getItem("paloma_session_token");

    // Inject the Accept, Content-Type, and Authorization headers
    callApi.headers = Object.assign({}, callApi.headers, {
      Authorization: `Bearer ${sessionToken}`,
    });
    if (callApi.headers["Content-Type"] === "multipart/form-data") {
      delete callApi.headers["Content-Type"];
    } else if (!callApi.headers["Content-Type"]) {
      callApi.headers["Accept"] = "application/json";
      callApi.headers["Content-Type"] = "application/json";
    }
  }

  return next(action);
};
