import React from "react";
import Table from "./Table";
import { getConversationLink } from "../../utilities/conversation";
import has from "lodash/has";

class LinksTable extends React.Component {
  render() {
    const { totalClicks, uniqueClicks } = this.props;

    if (!this.props.links.length) {
      return <div />;
    }

    const links = this.props.links.reduce((acc, link) => {
      if (has(acc, link.url)) {
        acc[link.url].totalClicks =
          acc[link.url].totalClicks + link.totalClicks;
        acc[link.url].uniqueClicks =
          acc[link.url].uniqueClicks + link.uniqueClicks;

        acc[link.url].totalClicks =
          acc[link.url].totalClicks + link.totalClicks;
      } else {
        acc[link.url] = { ...link };
      }

      return acc;
    }, {});

    const getTotalPercent = link =>
      !totalClicks
        ? ""
        : ` (${
            totalClicks
              ? ((link.totalClicks / totalClicks) * 100.0).toFixed(2)
              : 0
          }%)`;

    const getUniquePercent = link =>
      !uniqueClicks
        ? ""
        : ` (${
            uniqueClicks
              ? ((link.uniqueClicks / uniqueClicks) * 100.0).toFixed(2)
              : 0
          }%)`;

    return (
      <Table
        headers={["URL", "Total", "Unique"]}
        rows={Object.values(links).map(link => [
          <div className="link-detail">{chop(link.url)}</div>,
          <div style={{ paddingLeft: "5px" }}>
            {`${link.totalClicks} ${getTotalPercent(link)}`}
          </div>,
          <div style={{ paddingLeft: "5px", textAlign: "left" }}>
            {`${link.uniqueClicks} ${getUniquePercent(link)}`}
          </div>,
        ])}
        rowsPerPage={5}
      />
    );
  }
}

const chop = word => {
  if (word) {
    return (
      <a className="link" title={word} href={word} target="_blank">
        {word.substring(0, 50) + "..."}
      </a>
    );
  } else {
    return "None";
  }
};

LinksTable.defaultProps = { links: [] };

export default LinksTable;
