import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import _ from "underscore";

import BuilderInput from "./BuilderInput";
import { SeparatedButtonRow, Error, SelectOptionModal } from "../shared";
import ConversationStepButton from "../../containers/ConversationDetail/ConversationStepButton";
import { openMenu } from "../../actions/builder-menu";
import { TEXT_MESSAGE } from "../../constants/builder-menu";

import { createEditorState } from "../../utilities/editor";
import { combineObjects } from "../../utilities/general";

import { addQuickReply } from "../../actions/quick-replies";
import {
  createButton,
  toggleRandomizeButtons,
} from "../../actions/conversations";

class TextMessage extends Component {
  constructor(props) {
    super(props);

    const {
      conversationStep: { messageText },
    } = props;

    const editorState = createEditorState(messageText);
    this.state = {
      displayInsertFieldModal: false,
      editorState,
    };
  }

  render() {
    const {
      conversationStep: {
        buttons,
        conversationStepId,
        conversationId,
        errors,
        randomizeButtons,
      },
      editable,
      isMenuOpen,
      organizationFields,
    } = this.props;

    const { displayInsertFieldModal, editorState } = this.state;

    const messageTextClassName = classNames({
      "conversation-step__message-text": true,
      "conversation-step__message-text--selected": editable && isMenuOpen,
      "conversation-step__message-text--editable": editable,
      error: (errors.messageText || "").length,
    });

    return (
      <div>
        <div className="conversation-step__bot-message">
          <div className={messageTextClassName} onClick={this.handleClick}>
            <BuilderInput
              editorState={editorState}
              onChange={this.handleChange}
              editable={editable}
              placeholder="Write a message"
              maxChars={300}
            />
          </div>
          <SelectOptionModal
            title="Select a data field to insert"
            saveButtonText="Insert"
            isOpen={displayInsertFieldModal}
            onClose={this.hideInsertFieldModal}
            onSave={this.handleInsertFieldConfirmed}
            options={organizationFields.map(field => ({
              name: field.name,
              value: field,
            }))}
          />
        </div>
        <Error error={errors.messageText} />
        <Error error={errors.conversationStepQuickReplies} />
        <Error error={errors.conversationStepButtons} />
        {editable && isMenuOpen && (
          <SeparatedButtonRow
            className="conversation-step-button__button-types"
            buttons={[
              {
                onClick: this.displayInsertFieldModal,
                text: "Insert Field",
              },
              {
                text: "+ Quick Reply",
                onClick: () =>
                  this.props.addQuickReply(
                    this.props.conversationStep.conversationStepId
                  ),
              },
              {
                text: "+ Button",
                onClick: () =>
                  this.props.createButton({
                    conversationStepId: this.props.conversationStep
                      .conversationStepId,
                  }),
              },
            ]}
          />
        )}
        <div className="conversation-step__buttons">
          {buttons.map((button, i) => (
            <ConversationStepButton
              button={button}
              conversationId={conversationId}
              conversationStepId={conversationStepId}
              editable={editable}
              key={button.conversationStepButtonId}
              errors={errors["buttons"][i]}
              randomized={randomizeButtons}
              toggleRandomize={this.props.toggleRandomizeButtons}
            />
          ))}
        </div>
        <div>
          {_.pairs(combineObjects(errors["buttons"])).map(error => {
            return <Error error={error[1]} key={error[0]} />;
          })}
        </div>
      </div>
    );
  }

  handleChange = ({ state: editorState }) => {
    this.setState({ editorState });

    this.props.handleFieldChange(
      "messageText",
      JSON.stringify(editorState.toJSON())
    );
  };

  handleClick = async () => this.focusInput();

  handleInsertFieldConfirmed = field => {
    const { editorState } = this.state;
    const change = editorState.change();

    change.insertInline({
      type: "field",
      isVoid: true,
      data: { fieldType: field.name },
    });

    change.selectAll();
    change.collapseToEnd();

    this.handleChange(change);
    this.hideInsertFieldModal();
    this.focusInput();
  };

  displayInsertFieldModal = () =>
    this.setState({ displayInsertFieldModal: true });
  hideInsertFieldModal = () =>
    this.setState({ displayInsertFieldModal: false });

  focusInput = () => {
    this.props.openMenu({
      type: TEXT_MESSAGE,
      id: this.props.conversationStep.conversationStepId,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;

  const isMenuOpen =
    menuId === ownProps.conversationStep.conversationStepId &&
    menuType === TEXT_MESSAGE;

  return {
    isMenuOpen,
    organizationFields: state.organizationFields.organizationFields,
  };
};

export default connect(mapStateToProps, {
  addQuickReply,
  createButton,
  openMenu,
  toggleRandomizeButtons,
})(TextMessage);
