import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/test-sends";

export const testSend = (conversationId, listId) => ({
  [CALL_API]: {
    endpoint: `/api/conversations/${conversationId}/list/${listId}/test_send`,
    method: "POST",
    types: [
      constants.TEST_SEND_REQUEST,
      constants.TEST_SEND_SUCCESS,
      constants.TEST_SEND_FAILURE,
    ],
  },
});
