import React, { Component } from "react";
import Loader from "react-loader";
import classNames from "classnames";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import _ from "underscore";

import { ContentHeader, Notice, Toggle } from "../../components/shared";
import {
  AddMessageModal,
  ConversationSteps,
  SubscriberFlow,
} from "../../components/ConversationDetail";
import SelectStepModal from "./SelectStepModal";
import { hideErrors, showErrors } from "../../actions/builder-errors";
import { newInventoryStep } from "../../actions/inventories";
import { showSuccessAlert } from "../../actions/alerts";
import { fetchProducts } from "../../actions/inventory";
import { createEditorState } from "../../utilities/editor";
import isEmpty from "lodash/isEmpty";

import {
  fetchConversation,
  fetchVisibleConversationSteps,
  fetchVisibleConversationStepsFromStep,
  newConversationStep,
  putConversationStep,
  setTriggerChoice,
} from "../../actions/conversations";
import { pasteStep } from "../../actions/paste-step";
import { ADD_MESSAGE, CONVERSATION_STEPS } from "../../constants/builder-menu";
import { openMenu, closeMenu } from "../../actions/builder-menu";

import { fetchCurrentOrganization } from "../../actions/current-organization";

import { fetchCampaign } from "../../actions/campaigns";
import { fetchGlobalTrigger } from "../../actions/global-triggers";
import { fetchOrganizationFields } from "../../actions/organization-fields";
import { insertAboveStep } from "../../actions/insert-above";

import { getConversationLink } from "../../utilities/conversation";
import { ShowDisplayErrorContext } from "../../utilities/contexts"

class ConversationBuilderContainer extends Component {
  state = {
    rootStep: null,
    stepIdToPaste: null,
    stepIdToInsertAbove: null,
  };

  canReconnect = () => {
    const {
      triggerChoicesByConversationStepId,
      conversationStepsById,
      visibleConversationSteps,
    } = this.props;
    const detours = Object.entries(triggerChoicesByConversationStepId).length;

    const reconnections = visibleConversationSteps.stepIds.filter(
      stepId =>
        conversationStepsById[stepId] &&
        !!conversationStepsById[stepId].reconnection
    ).length;

    return detours > reconnections;
  };

  componentDidMount() {
    this.props.fetchProducts();
  }

  async componentWillMount() {
    const {
      campaignId,
      conversationId,
      triggerId,

      fetchCampaign,
      fetchConversation,
      fetchCurrentOrganization,
      fetchGlobalTrigger,
      fetchOrganizationFields,
      fetchVisibleConversationSteps,
    } = this.props;

    const promises = [];

    if (campaignId) promises.push(fetchCampaign(campaignId));
    else if (triggerId) promises.push(fetchGlobalTrigger(triggerId));

    await Promise.all([
      ...promises,
      fetchCurrentOrganization(),
      fetchConversation(conversationId),
      fetchOrganizationFields(),
      fetchVisibleConversationSteps(conversationId),
    ]);
  }

  render() {
    const {
      areShown,
      conversation,
      conversationId,
      isAddMessageMenuOpen,

      visibleConversationSteps: {
        isLoading: areVisibleConversationStepsLoading,
        stepIds: visibleConversationStepIds,
      },
    } = this.props;

    if (
      conversation &&
      !conversation.isLoading &&
      (conversation.published || conversation.sending)
    ) {
      const previewUrl = getConversationLink(conversation) + "/preview";
      return <Redirect push to={previewUrl} />;
    }

    const builderClassName = classNames("conversation-detail", {
      "show-errors": areShown,
    });

    return (
      <Loader
        loaded={!conversation.isLoading && !areVisibleConversationStepsLoading}
        loadedClassName={builderClassName}
      >
        <ContentHeader
          left={<h1 className="page-title">{conversation.label}</h1>}
          right=""
        />
        {conversation.isPopulating ? (
          <div style={{ position: "relative", height: 400 }}>
            <div
              style={{
                width: 450,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Loader
                options={{
                  lines: 10,
                  length: 5,
                  width: 2,
                  radius: 5,
                  scale: 1.0,
                  rotate: 0,
                  direction: 1,
                  speed: 1,
                  trail: 60,
                  fps: 20,
                  left: "50%",
                  top: "0%",
                  position: "relative",
                }}
              />
              <br />
              <br />
              <p style={{ textAlign: "center" }}>
              Your conversation is being copied and may take a few moments. Refresh the page, or navigate away and back to check the status of your new conversation.
              </p>
            </div>
          </div>
        ) : (
          <ShowDisplayErrorContext.Provider value={this.props.areShown}>
            {conversation.type === "campaign" && (
              <Notice
                headline="Heads up!"
                type="white"
                style={{ margin: "12px 0px" }}
              >
                Sending campaigns is an opportunity to re-engage your audience, so a
                response will be required on your first message to give your
                subscribers a chance to continue the conversation. Learn more about
                Facebook’s 24hr + 1 policy{" "}
                <a
                  href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/#standard_messaging"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </Notice>
            )}
            <div className="conversation-builder">
              <div className="conversation-builder__main">
                <ConversationSteps
                  conversationStepIds={visibleConversationStepIds}
                  conversationId={conversationId}
                  conversationType={conversation.type}
                  copyStep={this.copyStep}
                  fetchVisibleConversationSteps={this.refreshSteps}
                  insertAbove={this.insertAbove}
                />
                {isAddMessageMenuOpen && (
                  <AddMessageModal
                    conversationId={conversationId}
                    fetchVisibleConversationSteps={this.refreshSteps}
                    onClose={this.closeMenu}
                    pasteStep={this.handlePasteMessage}
                    showAddInventory={!isEmpty(this.props.productsById)}
                    showReconnect={this.canReconnect()}
                    stepIdToInsertAbove={this.state.stepIdToInsertAbove}
                    stepIdToPaste={this.state.stepIdToPaste}
                  />
                )}
                <div className="controls">
                  <div className="section add-message">
                    <button
                      className={classNames("button", {
                        active: isAddMessageMenuOpen,
                      })}
                      onClick={this.openAddMessageMenu}
                    >
                      + Add message
                    </button>
                  </div>
                  <div className="section errors">
                    Display Errors{" "}
                    <Toggle
                      checked={areShown}
                      onChange={() => {
                        if (areShown) {
                          this.props.hideErrors();
                        } else {
                          this.props.showErrors();
                        }
                      }}
                      onLabel="ON"
                      offLabel="OFF"
                    />
                  </div>
                </div>
              </div>
              {visibleConversationStepIds && conversationId && (
                <SubscriberFlow
                  conversationStepIds={visibleConversationStepIds}
                  conversationId={conversationId}
                  handleSelectTrigger={async trigger => {
                    await this.props.setTriggerChoice({
                      conversationStepId: trigger.sourceConversationStepId,
                      triggerId: trigger.triggerId,
                    });
                    this.refreshSteps();
                  }}
                  handleDeselectTrigger={async trigger => {
                    await this.setState({
                      rootStep: null,
                    });
                    await this.props.setTriggerChoice({
                      conversationStepId: trigger.sourceConversationStepId,
                      triggerId: null,
                    });
                    this.refreshSteps();
                  }}
                />
              )}
            </div>
            {this.props.isSelectStepMenuOpen && (
              <SelectStepModal
                closeMenu={this.closeMenu}
                conversationId={this.props.conversationId}
                refreshSteps={this.refreshSteps}
              />
            )}
          </ShowDisplayErrorContext.Provider>
        )}
      </Loader>
    );
  }

  closeMenu = () => {
    this.setState({ stepIdToInsertAbove: null }, this.props.closeMenu);
  };

  refreshSteps = () => {
    if (this.state.rootStep) {
      this.props.fetchVisibleConversationStepsFromStep(
        this.state.rootStep,
        this.props.conversationId
      );
    } else {
      this.props.fetchVisibleConversationSteps(this.props.conversationId);
    }
  };

  openAddMessageMenu = () => this.props.openMenu({ type: ADD_MESSAGE });

  handleAddRequiredFirstResponse = async conversationStep => {
    const { putConversationStep } = this.props;

    await this.refreshSteps();
    putConversationStep({
      ...conversationStep,
      organizationFieldId: null,
      userPlaceholderText: JSON.stringify(createEditorState("").toJSON()),
    });
  };

  handlePasteMessage = async conversationStepId => {
    this.closeMenu();
    const {
      triggerChoicesByConversationStepId,
      visibleConversationSteps: { stepIds: visibleConversationStepIds },
    } = this.props;

    if (this.state.stepIdToInsertAbove) {
      await this.props.insertAboveStep({
        conversationId: this.props.conversation.conversationId,
        stepIdToPaste: this.state.stepIdToPaste,
        stepIdToInsertAbove: this.state.stepIdToInsertAbove,
      });
      this.setState({ stepIdToInsertAbove: null });
    } else {
      const previousStepId = _.last(visibleConversationStepIds);
      const selectedTriggerId =
        triggerChoicesByConversationStepId[previousStepId];
      await this.props.pasteStep(
        this.state.stepIdToPaste,
        previousStepId,
        selectedTriggerId
      );
    }

    this.refreshSteps();
  };

  copyStep = id => {
    this.setState({ stepIdToPaste: id });
    this.props.showSuccessAlert("Copied!");
  };

  insertAbove = id => {
    this.setState({ stepIdToInsertAbove: id });
    this.openAddMessageMenu();
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { campaignId, conversationId, triggerId },
    },
  } = ownProps;

  const {
    builderMenu: { type: menuType },
    builderErrors: { areShown },
    currentOrganization: { shopifyStorefrontAccessToken },
    conversations: {
      conversationsById,
      conversationStepsById,
      triggerChoicesByConversationStepId,
      visibleConversationStepsByConversationId,
    },
    inventory: { productsById },
  } = state;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  const visibleConversationSteps = {
    isLoading: true,
    ...visibleConversationStepsByConversationId[conversationId],
  };

  const isAddMessageMenuOpen = menuType === ADD_MESSAGE;
  const isSelectStepMenuOpen = menuType === CONVERSATION_STEPS;

  return {
    areShown,
    campaignId,
    conversation,
    conversationId,
    isAddMessageMenuOpen,
    isSelectStepMenuOpen,
    shopifyStorefrontAccessToken,
    triggerChoicesByConversationStepId,
    conversationStepsById,
    triggerId,
    visibleConversationSteps,
    productsById,
  };
};

export default connect(mapStateToProps, {
  closeMenu,
  fetchCampaign,
  fetchConversation,
  fetchCurrentOrganization,
  fetchGlobalTrigger,
  fetchOrganizationFields,
  fetchVisibleConversationSteps,
  fetchVisibleConversationStepsFromStep,
  hideErrors,
  insertAboveStep,
  newConversationStep,
  putConversationStep,
  newInventoryStep,
  openMenu,
  pasteStep,
  push,
  setTriggerChoice,
  showErrors,
  showSuccessAlert,
  fetchProducts,
})(ConversationBuilderContainer);
