import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Loader from "react-loader";
import Tooltip from "rc-tooltip";
import Dropdown from "../../components/shared/Dropdown";

import { Card, ContentHeader, DeleteConfirmationModal } from "../../components/shared";
import Segments from "../../components/ConversationDetail/Segments";
import SubscriberTable from "../../components/Audience/SortableSubscriberTable";
import { showSuccessAlert, showErrorAlert } from "../../actions/alerts";
import {
  fetchSubscriberList,
  fetchSubscriberListTargetFilters,
  downloadSubscriberList,
  storeSubscriberInList,
  createCustomAudience,
} from "../../actions/subscriber-lists";
import { fetchCurrentOrganization } from "../../actions/current-organization";
import SubscriberMessageHistory from "../SubscriberMessageHistory";
import { DeleteSubscriberListModal } from "../../components/shared";
import { subscibeToEvent, unsubscibeFromEvent } from '../../utilities/socket';

class SubscriberListDetailContainer extends React.Component {
  state = {
    loading: false,
    selectedSubscriberId: null,
    displayDeleteModal: false,
    displayConfirmCustomAudienceModal: false,
    targetFilters: [],
    unreadSubscriberIds: []
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchList().finally(() => this.setState({ loading: false }));

    this.props
      .fetchTargetFilters(this.props.subscriberListId)
      .then(({ payload }) => this.setState({ targetFilters: payload }));

    this.subscibeToSubscriberSubscriberListAssociationUpdates()
  }

  componentWillUnmount() {
    unsubscibeFromEvent('message')
  }

  handleMessageFromSubscriber = (incomingSubscriberId) => {
    // If incoming socket message was not active chat window, mark mark unread (so row will show css highlighting)
    this.setState({ unreadSubscriberIds: [...this.state.unreadSubscriberIds, incomingSubscriberId] })
  }

  subscibeToSubscriberSubscriberListAssociationUpdates = async () => {
    await this.props.fetchCurrentOrganization()
    subscibeToEvent('subscriber_list_subscriber_association_update', this.props.currentOrganization.organizationId, (err, json_encoded_pair) => {
      const assocPair = { subscriberListIds: json_encoded_pair.subscriber_list_ids, subscriber: JSON.parse(json_encoded_pair.subscriber) }
      
      if(assocPair.subscriberListIds.includes(parseInt(this.props.subscriberListId))) {
        this.props.storeSubscriberInList(this.props.subscriberListId, assocPair.subscriber)
      }
    });
  }

  fetchList = async (options = {}) => {
    const { subscriberList = {}, subscriberListId } = this.props;

    const {
      order = "DESC",
      search = "",
      currentPage: page = 1,
      sortBy = "Received",
    } = subscriberList;
    const fetchOptions = { order, search, page, sortBy };

    await this.props.fetchSubscriberList(subscriberListId, {
      ...fetchOptions,
      ...options,
    });

    if (!this.state.selectedSubscriberId && this.props.subscribers.length) {
      this.setState({
        selectedSubscriberId: this.props.subscribers[0].subscriberId,
      });
    }
  };

  handleDownload = (mimetype) => {
    const { subscriberListId, subscriberList } = this.props;
    this.props
      .downloadSubscriberList(
        subscriberListId,
        subscriberList.downloadToken,
        mimetype
      )
      .then(() =>
        this.props.showSuccessAlert(
          "Your download is being sent to your email, and may take a few moments!"
        )
      )
      .catch(() =>
        this.props.showErrorAlert(
          "Something went wrong! Contact Support if the issue persists."
        )
      );
  };

  setConversationDetail = id => {
    // If subscriber was unread, mark as read (remove)
    var array = [...this.state.unreadSubscriberIds];
    var index = array.indexOf(id)
    if (index !== -1) {
      array.splice(index, 1);
    }

    this.setState({
      selectedSubscriberId: id, 
      unreadSubscriberIds: array
    });
  };

  render() {
    const { subscriberList, subscribers } = this.props;

    const {
      displayDeleteModal,
      shouldRedirect,
      selectedSubscriberId,
      targetFilters,
      displayConfirmCustomAudienceModal,
    } = this.state;

    const {
      order,
      search,
      currentPage: page,
      sortBy,
      totalPages,
      totalItems,
      isPopulating,
    } = subscriberList;
    const fetchOptions = { order, search, page, sortBy };

    const CustomAudienceIndicator = () => (
      <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Tooltip
          placement="bottom"
          trigger={["hover"]}
          overlay="This list is actively updating a Facebook Custom Audience"
        >
          <strong>Facebook Custom Audience</strong>
        </Tooltip>
      </div>
    )

    if (shouldRedirect) return <Redirect to="/audience?show_lists=true" />;

    return (
      <Loader
        loaded={!this.state.loading}
        loadedClassName={`subscriber-list-detail ${
          selectedSubscriberId ? "audience" : ""
        }`}
      >
        <ContentHeader
          left={<h1 className="page-title">{subscriberList.name}</h1>}
          right={
            <div className="subscriber-list-buttons">
              <button className="paloma-button" onClick={this.showDeleteModal}>
                Delete
              </button>

              <Dropdown
                style={{ zIndex: 5000 }}
                buttonText={
                  <div style={{ padding: "0px 12px" }}>
                    Export
                  </div>
                }
                dropdownOptions={[
                  {
                    text: <span>CSV</span>,
                    onClick: () => this.handleDownload("text/csv"),
                  },
                  {
                    text: <span>JSON</span>,
                    onClick: () => this.handleDownload("application/json"),
                  },
                  {
                    text: <span>Create Facebook Audience</span>,
                    onClick: () => this.showConfirmModal(),
                  },
                ]}
              />
              <Link
                className="paloma-button"
                to={`/audience/lists/${subscriberList.subscriberListId}/edit`}
              >
                Edit details
              </Link>
            </div>
          }
        />

        {targetFilters.length &&
        subscriberList &&
        subscriberList.targetFilterMatchSetting ? (
          <div className="targeting white" style={{ marginLeft: "20px", marginRight: "12px", position: 'relative' }}>
            {subscriberList.fbCustomAudienceId &&
              <CustomAudienceIndicator />
            }
            <Segments
              matchSetting={subscriberList.targetFilterMatchSetting}
              segments={targetFilters}
            />
          </div>
        ) : subscriberList.fbCustomAudienceId ? (
          <div className="targeting white" style={{ marginLeft: "20px", marginRight: "12px", position: 'relative' }}>
            <CustomAudienceIndicator />
          </div>
        ) : null}

        <div className="split">
          <Card className="list">
            {!isPopulating ? (
              <SubscriberTable
                {...fetchOptions}
                totalPages={totalPages}
                onRowClick={subscriberId => {
                  this.setConversationDetail(subscriberId);
                }}
                subscribers={subscribers}
                fetch={(options) => this.fetchList(options)}
                total={totalItems}
                unreadSubscriberIds={this.state.unreadSubscriberIds}
              />
            ) : (
              <div style={{ position: "relative", height: 400 }}>
                <div
                  style={{
                    width: 450,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Loader
                    options={{
                      lines: 10,
                      length: 5,
                      width: 2,
                      radius: 5,
                      scale: 1.0,
                      rotate: 0,
                      direction: 1,
                      speed: 1,
                      trail: 60,
                      fps: 20,
                      left: "50%",
                      top: "0%",
                      position: "relative",
                    }}
                  />
                  <br />
                  <br />
                  <p style={{ textAlign: "center" }}>
                    Your list is being generated and may take a few moments.
                    Grab a coffee, have a stretch, and come back to see your new
                    list!
                  </p>
                </div>
              </div>
            )}
            {displayDeleteModal && (
              <DeleteSubscriberListModal
                subscriberList={subscriberList}
                onClose={this.hideDeleteModal}
                onDelete={this.onDelete}
              />
            )}
            {displayConfirmCustomAudienceModal && (
              <DeleteConfirmationModal
                title={`Create a ${subscriberList.fbCustomAudienceId ? "New" : ""} Custom Audience in Facebook`}
                body={subscriberList.fbCustomAudienceId ? (
                  "This list is already connected to a Custom Audience. Clicking Confirm will create a new Custom Audience and the existing Custom Audience will no longer be updated. Would you like to create a new Custom Audience?"
                ) : (
                  "Your custom audience will be created and accessible in your Facebook Ad Account, where it will be updated in real-time as new subscribers are added to this list. Clicking create below will immediately generate the audience, and Facebook will begin importing your customers – it may take a few moments, so check back in your Ads tool to confirm."
                )}
                onClose={this.hideConfirmModal}
                handleDelete={this.createCustomAudience}
              />
            )}
          </Card>
          {selectedSubscriberId && (
            <Card className="profile">
              <SubscriberMessageHistory
                subscriberId={this.state.selectedSubscriberId}
                handleMessageFromSubscriber={this.handleMessageFromSubscriber}
              />
            </Card>
          )}
        </div>
      </Loader>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => this.setState({ displayDeleteModal: false });

  showConfirmModal = () => this.setState({ displayConfirmCustomAudienceModal: true });
  hideConfirmModal = () => this.setState({ displayConfirmCustomAudienceModal: false });

  createCustomAudience = () => {
    this.props.createCustomAudience(this.props.subscriberList.subscriberListId)
    this.hideConfirmModal()
  }

  onDelete = () => this.setState({ shouldRedirect: true });
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { subscriberListId },
    },
  } = ownProps;

  const subscriberList = {
    isLoading: true,
    ...state.subscriberLists.byId[subscriberListId],
  };

  const subscribers = subscriberList.subscribers || [];

  return {
    subscriberListId,
    subscriberList,
    subscribers,
    currentOrganization: state.currentOrganization
  };
};

export default connect(mapStateToProps, {
  fetchSubscriberList,
  fetchTargetFilters: fetchSubscriberListTargetFilters,
  downloadSubscriberList,
  showSuccessAlert,
  showErrorAlert,
  storeSubscriberInList,
  fetchCurrentOrganization,
  createCustomAudience,
})(SubscriberListDetailContainer);
