import React from "react";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import Modal from "./Modal";
import PaginatedList from "../PaginatedList";
import Fuse from "fuse.js";

export default class SearchablePaginatedOptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: props.selectedOptions || [],
      search: null,
      currentPage: props.currentPage || 1,
    };
  }

  handleSave = e => {
    e.preventDefault();
    this.props.onSave(
      this.props.multiSelect
        ? this.state.selectedOptions
        : this.state.selectedOptions[0]
    );
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ selectedOptions: [], search: null });
    this.props.onClose();
    // this resets the search to the default query of everything in product inventory
    if (typeof this.props.onPageOrSearch === 'function') {
      this.props.onPageOrSearch(1, "");
    }
  };

  handleSelect = optionId => {
    this.setState({
      selectedOptions: this.props.multiSelect
        ? [...this.state.selectedOptions, optionId]
        : [optionId],
    });
  };

  handleDeselect = optionId => {
    this.setState({
      selectedOptions: this.state.selectedOptions.filter(
        value => value !== optionId
      ),
    });
  };

  handleChoosePage = currentPage => {
    this.setState({ currentPage });
    if (this.props.controlPaginationExternally && typeof this.props.onPageOrSearch === 'function') {
      this.props.onPageOrSearch(currentPage, this.state.search);
    }
  };

  fuseSearchedOptions() {
    var opts = this.props.options;
    if (this.state.search) {
      if (this.props.optionSearchKeys) {
        const fuse = new Fuse(opts, {
          keys: this.props.optionSearchKeys,
          shouldSort: true,
          ignoreLocation: false,
          threshold: 0.3,
        });
        const results = fuse.search(this.state.search);
        opts = results.map(el => el.item);
      } else {
        console.error(
          "MultiSelectOptionModal: In order to perform a search, a set of keys to search against must be provided as props.optionSearchKeys"
        );
      }
    }
    return opts;
  }

  getItemsForCurrentPage = () => {
    if (this.props.controlPaginationExternally) {
      return this.props.options;
    } else {
      return this.fuseSearchedOptions().slice(
        (this.state.currentPage - 1) * this.props.perPage,
        this.state.currentPage * this.props.perPage
      );
    }
  };

  getTotalPages = () => {
    if (this.props.controlPaginationExternally) {
      return this.props.totalPages;
    } else {
      return Math.ceil(this.fuseSearchedOptions().length / this.props.perPage);
    }
  };

  handleSearch = async e => {
    e.preventDefault();
    this.handleChoosePage(1);
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <div className="searchable-paginated-option-modal">
        <Modal
          title={this.props.title || "Select Options"}
          isOpen={this.props.isOpen}
          onClose={this.handleClose}
        >
          <div className="modal__top-input" style={{ height: "unset" }}>
            <div
              className="input-with-inline-button"
              style={{ margin: "0 15px" }}
            >
              <form onSubmit={this.handleSearch}>
                <input
                  className="modal__input--inline"
                  placeholder="What can we help you find?"
                  type="text"
                  name="search"
                  id="search"
                  value={this.state.search === null ? "" : this.state.search}
                  onChange={e => {
                    e.preventDefault();
                    if (this.props.controlPaginationExternally) {
                      this.setState({ search: e.target.value });
                    } else {
                      this.setState({ search: e.target.value, currentPage: 1 });
                    }
                  }}
                />
                <button
                  type="submit"
                  style={{ width: "20%" }}
                  onClick={this.handleSearch}
                  className="paloma-button paloma-button--pink modal__button"
                >
                  Search
                </button>
              </form>
            </div>
          </div>

          <div
            className="modal-pagination"
            style={{ padding: "0 10px 10px 10px", width: "100%" }}
          >
            <PaginatedList
              controlPaginationExternally
              currentPage={this.state.currentPage}
              totalPages={this.getTotalPages()}
              items={this.getItemsForCurrentPage()}
              component={option => {
                const id = this.props.idForOption(option);
                const isChecked = selectedOptions.includes(id);

                const selectedIndex =
                  selectedOptions.includes(id) &&
                  selectedOptions.indexOf(id) + 1;

                return (
                  <div
                    className={classNames({
                      "modal__radio-label": true,
                      "modal__radio-label--checked": isChecked,
                    })}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() =>
                      isChecked
                        ? this.handleDeselect(id)
                        : this.handleSelect(id)
                    }
                    key={id}
                  >
                    {this.props.rowComponent(option, selectedIndex)}
                  </div>
                );
              }}
              onChoosePage={this.handleChoosePage}
            />
          </div>
          <div
            className="modal__controls"
            style={{
              paddingRight: 5,
              paddingLeft: 5,
              borderTopColor: "#ffffff",
            }}
          >
            <button
              type="button"
              style={{ marginLeft: 0 }}
              onClick={this.handleClose}
              className="paloma-button modal__button"
            >
              Cancel
            </button>
            <button
              className="paloma-button--pink modal__button"
              disabled={isEmpty(selectedOptions)}
              onClick={this.handleSave}
            >
              {this.props.saveButtonText || "Save"}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
