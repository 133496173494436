import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { putGlobalTrigger } from "../../actions/global-triggers";

import { Card } from "../../components/shared";

class NewGlobalTriggerContainer extends Component {
  state = {
    triggerName: "",
  };

  render() {
    const { triggerName } = this.state;

    return (
      <div className="new-global-trigger">
        <h1 className="page-title">New Trigger</h1>
        <Card>
          <form className="default-form" onSubmit={this.handleCreateTrigger}>
            <label className="default-form__label" htmlFor="trigger-name">
              Name
            </label>
            <input
              id="trigger-name"
              className="default-form__input"
              type="text"
              value={triggerName}
              onChange={this.handleNameChange}
            />
            <button
              className="default-form__submit"
              type="submit"
              disabled={!triggerName}
            >
              Create
            </button>
          </form>
        </Card>
      </div>
    );
  }

  handleCreateTrigger = async e => {
    e.preventDefault();

    const { triggerName } = this.state;

    if (!triggerName) return;

    const response = await this.props.putGlobalTrigger({ name: triggerName });
    this.props.push(`/global/${response.payload.triggerId}`);
  };

  handleNameChange = e => this.setState({ triggerName: e.target.value });
}

export default connect(
  null,
  { putGlobalTrigger, push }
)(NewGlobalTriggerContainer);
