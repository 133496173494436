export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const DUPLICATE_PRODUCT_REQUEST = "DUPLICATE_PRODUCT_REQUEST";
export const DUPLICATE_PRODUCT_SUCCESS = "DUPLICATE_PRODUCT_SUCCESS";
export const DUPLICATE_PRODUCT_FAILURE = "DUPLICATE_PRODUCT_FAILURE";

export const IMPORT_SHOPIFY_PRODUCTS_REQUEST = "IMPORT_SHOPIFY_PRODUCTS_REQUEST";
export const IMPORT_SHOPIFY_PRODUCTS_SUCCESS = "IMPORT_SHOPIFY_PRODUCTS_SUCCESS";
export const IMPORT_SHOPIFY_PRODUCTS_FAILURE = "IMPORT_SHOPIFY_PRODUCTS_FAILURE";