import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Loader from "react-loader";

import { RouteWithSubRoutes } from "../../components/shared";
import { fetchCurrentUser } from "../../actions/current-user";
import { fetchCurrentOrganization } from "../../actions/current-organization";
import { logout } from "../../actions/auth";
import { clearAlerts } from "../../actions/alerts";
import OrganizationSwitcher from "../OrganizationSwitcher";
import { isImpersonating } from "../../utilities/user";

import {
  NavigationSidebar,
  Breadcrumbs,
  PageControls,
  Alert,
} from "../../components/Layout";

class LayoutContainer extends React.Component {
  static get propTypes() {
    return {
      fetchCurrentUser: PropTypes.func.isRequired,
      isLoading: PropTypes.bool.isRequired,
      currentUser: PropTypes.object,
      routes: PropTypes.array,
    };
  }

  async componentWillMount() {
    await this.props.fetchCurrentUser();
    await this.props.fetchCurrentOrganization();
  }

  render() {
    const routes = this.props.routes;
    const currentPathname = this.props.location.pathname;
    const currentUser = this.props.currentUser || {};

    return (
      <Loader loaded={!this.props.isLoading} loadedClassName="layout">
        {this.props.currentAlert && (
          <Alert
            alert={this.props.currentAlert}
            clearAlerts={this.props.clearAlerts}
          />
        )}
        {
          <OrganizationSwitcher
            currentUser={currentUser}
            currentOrganization={this.props.currentOrganization}
          />
        }
        <div className="layout__main-container">
          <NavigationSidebar
            logout={this.props.logout}
            currentPathname={currentPathname}
          />
          <div className="layout__content">
            <div className="layout__header">
              <Switch>
                {routes
                  .filter(route => !route.hideBreadcrumbs)
                  .map((route, i) => (
                    <Route
                      key={`breadcrumb-${i}`}
                      path={route.path}
                      render={() => (
                        <Breadcrumbs
                          routes={routes}
                          currentPathname={currentPathname}
                        />
                      )}
                    />
                  ))}
              </Switch>

              <Switch>
                {routes
                  .filter(route => route.pageControls)
                  .map((route, i) => (
                    <Route
                      key={`page-control-${i}`}
                      path={route.path}
                      render={({ match }) => (
                        <PageControls
                          controls={route.pageControls}
                          match={match}
                        />
                      )}
                    />
                  ))}
              </Switch>
            </div>

            <div
              className={classNames({
                layout__page: true,
                impersonating: isImpersonating(
                  this.props.currentUser,
                  this.props.currentOrganization
                ),
              })}
            >
              <Switch>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.currentUser || {},
  currentOrganization: state.currentOrganization || {},
  isLoading: state.currentUser.isLoading,
  currentAlert: state.alerts.currentAlert,
});

export default connect(
  mapStateToProps,
  {
    fetchCurrentUser,
    fetchCurrentOrganization,
    logout,
    clearAlerts,
  }
)(LayoutContainer);
