import React from "react";
import classNames from "classnames";

import Modal from "./Modal";

export default class SelectOptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: props.selectedOption };
  }

  handleSave = e => {
    e.preventDefault();
    this.props.onSave(this.props.options[this.state.selectedOption].value);
    this.handleClose();
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        title={this.props.title}
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
      >
        <form className="modal__form" onSubmit={this.handleSave}>
          <fieldset className="modal__fieldset">
            {this.props.options.map((option, i) => {
              const isChecked = this.state.selectedOption === i;
              const labelClassName = classNames({
                "modal__radio-label": true,
                "modal__radio-label--checked": isChecked,
              });

              // TODO: figure out why using radio buttons for this didn't work
              return (
                <div
                  key={i}
                  className={labelClassName}
                  onClick={() => this.setState({ selectedOption: i })}
                >
                  {option.name}
                </div>
              );
            })}
          </fieldset>
          <div className="modal__controls">
            <button
              type="button"
              onClick={this.handleClose}
              className="paloma-button modal__button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="paloma-button paloma-button--pink modal__button"
              disabled={this.state.selectedOption === undefined}
              onClick={this.handleSave}
            >
              {this.props.saveButtonText || "Save"}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}
