const getConversationLink = conversation => {
  const { campaignId, conversationId, triggerId, type } = conversation;

  switch (type) {
    case "campaign":
      return `/campaigns/${campaignId}/conversations/${conversationId}`;
    case "signup":
      return `/acquisition/signup/${conversationId}`;
    case "global":
      return `/global/${triggerId}/conversations/${conversationId}`;
    case "global_default":
      return `/global/unexpected/conversations/${conversationId}`;
    case "welcome_message":
      return `/entrypoints/wm/${triggerId}/conversations/${conversationId}`;
    default:
      return "";
  }
};

const status = conversation => {
  if (conversation.sending) {
    return "Sending";
  }
  if (conversation.published) {
    return "Published";
  }
  if (conversation.scheduled) {
    return "Scheduled";
  }
  return "Not published";
};

export { getConversationLink, status };
