import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  deleteTemplateCardButton,
  fetchVisibleConversationSteps,
  putTemplateCardButton,
} from "../../actions/conversations";

import {
  AddPhoneNumberButtonModal,
  AddURLButtonModal,
  DeleteConversationStepButtonModal,
} from "../../components/ConversationDetail";
import { SelectGlobalTriggerModal } from "../shared";

class TemplateCardButtonModal extends Component {
  static propTypes = {
    button: PropTypes.object.isRequired,
    modal: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { button, modal, onClose } = this.props;

    switch (modal) {
      case "delete":
        return (
          <DeleteConversationStepButtonModal
            handleDeleteConfirmed={this.handleDelete}
            isOpen={true}
            onClose={onClose}
          />
        );
      case "custom":
        return (
          <SelectGlobalTriggerModal
            isOpen={true}
            onClose={onClose}
            onSave={this.handleCustomPayloadAdded}
          />
        );
      case "phone_number":
        return (
          <AddPhoneNumberButtonModal
            isOpen={true}
            onClose={onClose}
            currentPhoneNumber={button.phoneNumber}
            addPhoneNumberButton={this.handlePhoneNumberAdded}
          />
        );
      case "url":
      case "login":
        return (
          <AddURLButtonModal
            isOpen={true}
            onClose={onClose}
            url={button.url}
            webviewHeightRatio={button.webviewHeightRatio}
            addUrlButton={this.handleURLAdded}
            {...button}
          />
        );
      default:
        return <div />;
    }
  }

  handleURLAdded = async (url, webviewHeightRatio, dataFieldIds, shopify) => {
    const {
      button: {
        buttonText,
        conversationStepTemplateCardButtonId,
        conversationStepTemplateCardId,
      },
      modal,
      putTemplateCardButton,
    } = this.props;

    await putTemplateCardButton({
      conversationStepTemplateCardId,
      conversationStepTemplateCardButtonId,
      button_type: modal, // 'url' or 'login'
      button_text: buttonText,
      webviewHeightRatio,
      url,
      dataFieldIds,
      shopify,
    });
  };

  handleCustomPayloadAdded = triggerId => {
    const customPayload = `global_trigger_${triggerId}`;
    const { button, putTemplateCardButton } = this.props;

    putTemplateCardButton({
      ...button,
      buttonType: "custom_payload",
      customPayload,
    });
  };

  handlePhoneNumberAdded = async phoneNumber => {
    const {
      button: {
        buttonText,
        conversationStepTemplateCardButtonId,
        conversationStepTemplateCardId,
      },
      putTemplateCardButton,
    } = this.props;

    await putTemplateCardButton({
      conversationStepTemplateCardId,
      conversationStepTemplateCardButtonId,
      button_type: "phone_number",
      button_text: buttonText,
      phoneNumber,
    });
  };

  handleDelete = async () => {
    const {
      button: { conversationStepTemplateCardButtonId, conversationId },
      deleteTemplateCardButton,
      fetchVisibleConversationSteps,
    } = this.props;

    await deleteTemplateCardButton({
      conversationStepTemplateCardButtonId,
    });

    fetchVisibleConversationSteps(conversationId);
  };
}

export default connect(
  null,
  {
    fetchVisibleConversationSteps,
    deleteTemplateCardButton,
    putTemplateCardButton,
  }
)(TemplateCardButtonModal);
