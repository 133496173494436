export const TOGGLE_RANDOMIZE_QUICK_REPLIES_REQUEST =
  "TOGGLE_RANDOMIZE_QUICK_REPLIES_REQUEST";
export const TOGGLE_RANDOMIZE_QUICK_REPLIES_SUCCESS =
  "TOGGLE_RANDOMIZE_QUICK_REPLIES_SUCCESS";
export const TOGGLE_RANDOMIZE_QUICK_REPLIES_FAILURE =
  "TOGGLE_RANDOMIZE_QUICK_REPLIES_FAILURE";

export const ADD_QUICK_REPLY_REQUEST = "ADD_QUICK_REPLY_REQUEST";
export const ADD_QUICK_REPLY_SUCCESS = "ADD_QUICK_REPLY_SUCCESS";
export const ADD_QUICK_REPLY_FAILURE = "ADD_QUICK_REPLY_FAILURE";

export const DELETE_QUICK_REPLY_REQUEST = "DELETE_QUICK_REPLY_REQUEST";
export const DELETE_QUICK_REPLY_SUCCESS = "DELETE_QUICK_REPLY_SUCCESS";
export const DELETE_QUICK_REPLY_FAILURE = "DELETE_QUICK_REPLY_FAILURE";

export const UPDATE_QUICK_REPLY_REQUEST = "UPDATE_QUICK_REPLY_REQUEST";
export const UPDATE_QUICK_REPLY_SUCCESS = "UPDATE_QUICK_REPLY_SUCCESS";
export const UPDATE_QUICK_REPLY_FAILURE = "UPDATE_QUICK_REPLY_FAILURE";

export const UPDATE_QUICK_REPLY_TEXT_REQUEST =
  "UPDATE_QUICK_REPLY_TEXT_REQUEST";
export const UPDATE_QUICK_REPLY_TEXT_FAILURE =
  "UPDATE_QUICK_REPLY_TEXT_FAILURE";

export const UPDATE_QUICK_REPLY_EMAIL_REQUEST =
  "UPDATE_QUICK_REPLY_EMAIL_REQUEST";
export const UPDATE_QUICK_REPLY_EMAIL_FAILURE =
  "UPDATE_QUICK_REPLY_EMAIL_FAILURE";

export const UPDATE_QUICK_REPLY_PHONE_NUMBER_REQUEST =
  "UPDATE_QUICK_REPLY_PHONE_NUMBER_REQUEST";
export const UPDATE_QUICK_REPLY_PHONE_NUMBER_FAILURE =
  "UPDATE_QUICK_REPLY_PHONE_NUMBEr_FAILURE";

export const FETCH_QUICK_REPLIES_REQUEST = "FETCH_QUICK_REPLIES_REQUEST";
export const FETCH_QUICK_REPLIES_SUCCESS = "FETCH_QUICK_REPLIES_SUCCESS";
export const FETCH_QUICK_REPLIES_FAILURE = "FETCH_QUICK_REPLIES_FAILURE";

export const FETCH_QUICK_REPLY_REQUEST = "FETCH_QUICK_REPLY_REQUEST";
export const FETCH_QUICK_REPLY_SUCCESS = "FETCH_QUICK_REPLY_SUCCESS";
export const FETCH_QUICK_REPLY_FAILURE = "FETCH_QUICK_REPLY_FAILURE";

export const ADD_TRIGGER_TO_QUICK_REPLY_REQUEST =
  "ADD_TRIGGER_TO_QUICK_REPLY_REQUEST";
export const ADD_TRIGGER_TO_QUICK_REPLY_SUCCESS =
  "ADD_TRIGGER_TO_QUICK_REPLY_SUCCESS";
export const ADD_TRIGGER_TO_QUICK_REPLY_FAILURE =
  "ADD_TRIGGER_TO_QUICK_REPLY_FAILURE";

export const ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_REQUEST =
  "ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_REQUEST";
export const ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_SUCCESS =
  "ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_SUCCESS";
export const ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_FAILURE =
  "ADD_CUSTOM_PAYLOAD_TO_QUICK_REPLY_FAILURE";
