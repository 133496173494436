export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_RESPONSE = "ADD_RESPONSE";

export const CONVERSATION_STEP = "CONVERSATION_STEP";
export const CONVERSATION_STEPS = "CONVERSATION_STEPS";
export const CONVERSATION_STEP_BUTTON = "CONVERSATION_STEP_BUTTON";
export const PERSISTENT_MENU_ITEM = "PERSISTENT_MENU_ITEM";
export const QUICK_REPLY = "QUICK_REPLY";
export const RESPONSE = "RESPONSE";
export const TEMPLATE_CARD = "TEMPLATE_CARD";
export const TEMPLATE_CARD_BUTTON = "TEMPLATE_CARD_BUTTON";
export const TEXT_MESSAGE = "TEXT_MESSAGE";
