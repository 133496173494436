import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/links";

export const fetchLink = (type, id) => ({
  [CALL_API]: {
    endpoint: `/api/links/${type}/${id}`,
    method: "GET",
    types: [
      constants.FETCH_LINK_REQUEST,
      constants.FETCH_LINK_SUCCESS,
      constants.FETCH_LINK_FAILURE,
    ],
  },
});
