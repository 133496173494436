export const validatePassword = password => {
  const errors = [];

  if (password.length < 8) {
    errors.push("Password must be at least 8 characters long.");
  }
  if (!password.match(/[^a-zA-Z0-9]/)) {
    errors.push("Password must contain at least one special character.");
  }
  if (!password.match(/[0-9]/)) {
    errors.push("Password must contain at least one number.");
  }

  return errors;
};
