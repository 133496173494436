import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/inactive-signup-conversations";

export const fetchInactiveSignupConversations = () => {
  return {
    [CALL_API]: {
      endpoint: "/api/inactive_signup_conversations",
      method: "GET",
      types: [
        constants.FETCH_INACTIVE_SIGNUP_CONVERSATIONS_REQUEST,
        constants.FETCH_INACTIVE_SIGNUP_CONVERSATIONS_SUCCESS,
        constants.FETCH_INACTIVE_SIGNUP_CONVERSATIONS_FAILURE,
      ],
    },
  };
};
