import React from "react";

import ConversationStep from "../../containers/ConversationDetail/ConversationStep";

export default ({
  conversationId,
  conversationStepIds,
  conversationType,
  copyStep,
  editable = true,
  fetchVisibleConversationSteps,
  insertAbove,
}) => (
  <div className="conversation-steps">
    {conversationStepIds && conversationStepIds.length ? (
      <div style={{ position: "relative" }}>
        {/* if its a signup convo, then show a get started message first */}
        {conversationType === "signup" && <div>get started</div>}
        {/* then show the rest of the conversation */}
        {conversationStepIds.map((stepId, i) => (
          <ConversationStep
            conversationId={conversationId}
            conversationStepId={stepId}
            copyStep={copyStep}
            editable={editable}
            insertAbove={insertAbove}
            key={stepId}
            refreshSteps={fetchVisibleConversationSteps}
          />
        ))}
      </div>
    ) : (
      <div>no conversation steps</div>
    )}
  </div>
);
