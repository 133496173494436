import React from "react";
import Toggle from "../shared/Toggle";

const GlobalTriggerToggle = ({ isToggled, onToggle, title, bodyText }) => (
  <div id="email-notifications">
    <h3>{title}</h3>
    <label className="toggle-wrapper">
      <Toggle
        className="square"
        onChange={onToggle}
        checked={!!isToggled}
      />
      {bodyText}
    </label>
  </div>
);

export default GlobalTriggerToggle;
