import { CALL_API } from "redux-api-middleware";

import * as constants from "../constants/impersonations";

export const createImpersonation = organizationId => ({
  [CALL_API]: {
    endpoint: `/api/impersonations/${organizationId}`,
    method: "POST",
    types: [
      constants.CREATE_IMPERSONATION_REQUEST,
      constants.CREATE_IMPERSONATION_SUCCESS,
      constants.CREATE_IMPERSONATION_FAILURE,
    ],
  },
});

export const deleteImpersonation = () => ({
  [CALL_API]: {
    endpoint: "/api/impersonations/",
    method: "DELETE",
    types: [
      constants.DELETE_IMPERSONATION_REQUEST,
      constants.DELETE_IMPERSONATION_SUCCESS,
      constants.DELETE_IMPERSONATION_FAILURE,
    ],
  },
});
