import { CALL_API } from "redux-api-middleware";
import * as constants from "../constants/sequences";

export const createSequence = ({ conversationId, name }) => ({
  [CALL_API]: {
    endpoint: "/api/sequences/",
    method: "POST",
    body: { conversationId, name },
    types: [
      constants.CREATE_SEQUENCE_REQUEST,
      constants.CREATE_SEQUENCE_SUCCESS,
      constants.CREATE_SEQUENCE_FAILURE,
    ],
  },
});

export const fetchSequences = ({ conversationId }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences?conversation_id=${conversationId}`,
    method: "GET",
    types: [
      constants.FETCH_SEQUENCES_REQUEST,
      constants.FETCH_SEQUENCES_SUCCESS,
      constants.FETCH_SEQUENCES_FAILURE,
    ],
  },
});

export const deleteSequence = ({ sequenceId }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/`,
    method: "DELETE",
    types: [
      constants.DELETE_SEQUENCE_REQUEST,
      {
        type: constants.DELETE_SEQUENCE_SUCCESS,
        meta: { sequenceId },
      },
      constants.DELETE_SEQUENCE_FAILURE,
    ],
  },
});

export const duplicateSequence = ({ sequenceId }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/duplicate`,
    method: "POST",
    types: [
      constants.DUPLICATE_SEQUENCE_REQUEST,
      constants.CREATE_SEQUENCE_SUCCESS,
      constants.DUPLICATE_SEQUENCE_FAILURE,
    ],
  },
});

export const fetchSequence = ({ sequenceId }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/`,
    method: "GET",
    types: [
      constants.FETCH_SEQUENCE_REQUEST,
      constants.FETCH_SEQUENCE_SUCCESS,
      constants.FETCH_SEQUENCE_FAILURE,
    ],
  },
});

export const editSequence = ({ sequenceId, name }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/`,
    method: "PUT",
    body: { name },
    types: [
      constants.EDIT_SEQUENCE_REQUEST,
      constants.EDIT_SEQUENCE_SUCCESS,
      constants.EDIT_SEQUENCE_FAILURE,
    ],
  },
});

export const fetchSequenceRecipients = ({ sequenceId, page = 1 }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/recipients?page=${page}`,
    method: "GET",
    types: [
      constants.FETCH_SEQUENCE_RECIPIENTS_REQUEST,
      {
        type: constants.FETCH_SEQUENCE_RECIPIENTS_SUCCESS,
        meta: { sequenceId },
      },
      constants.FETCH_SEQUENCE_RECIPIENTS_FAILURE,
    ],
  },
});

export const fetchSequenceSettings = ({ sequenceId }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/settings`,
    method: "GET",
    types: [
      constants.FETCH_SEQUENCE_SETTINGS_REQUEST,
      {
        type: constants.FETCH_SEQUENCE_SETTINGS_SUCCESS,
        meta: { sequenceId },
      },
      constants.FETCH_SEQUENCE_SETTINGS_FAILURE,
    ],
  },
});

export const updateSequenceSettings = ({
  delay = 0,
  sequenceId,
  matchSetting = "All",
  targetFilters = [],
  delayFormat = "seconds",
  actions = [],
}) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/settings`,
    method: "PUT",
    body: {
      delay,
      delayFormat,
      matchSetting,
      targetFilters,
      actions,
    },
    types: [
      constants.UPDATE_SEQUENCE_SETTINGS_REQUEST,
      {
        type: constants.FETCH_SEQUENCE_SETTINGS_SUCCESS,
        meta: { sequenceId },
      },
      constants.UPDATE_SEQUENCE_SETTINGS_FAILURE,
    ],
  },
});

export const publishSequence = ({ sequenceId, publish = true }) => ({
  [CALL_API]: {
    endpoint: `/api/sequences/${sequenceId}/publish`,
    method: "PUT",
    body: { publish },
    types: [
      constants.PUBLISH_SEQUENCE_REQUEST,
      constants.FETCH_SEQUENCE_SUCCESS,
      constants.PUBLISH_SEQUENCE_FAILURE,
    ],
  },
});
