import React from "react";
import { connect } from "react-redux";
import { openMenu } from "../../actions/builder-menu";
import { SeparatedButtonRow, Error, SelectOptionModal } from "../../components/shared";
import BuilderInput from "../../components/ConversationDetail/BuilderInput";
import ConversationStepButton from "../ConversationDetail/ConversationStepButton";
import { createEditorState } from "../../utilities/editor";
import {
  createButton,
  toggleRandomizeButtons,
} from "../../actions/conversations";
import { addQuickReply } from "../../actions/quick-replies";
import classNames from "classnames";
import { TEXT_MESSAGE } from "../../constants/builder-menu";
import _ from "underscore";
import { combineObjects } from "../../utilities/general";

class TextMessage extends React.Component {
  constructor(props) {
    super(props);

    const {
      conversationStep: { messageText },
    } = props;

    const editorState = createEditorState(messageText);

    this.state = {
      displayInsertFieldModal: false,
      editorState,
    };
  }

  menuButtons = () => {
    const { isTextWelcomeMessage } = this.props;
    const btns = [
      {
        onClick: () => this.setState({ displayInsertFieldModal: true }),
        text: "+ Insert Field",
      },
    ];

    if (isTextWelcomeMessage) {
      btns.push({
        text: "+ Quick Reply",
        onClick: () =>
          this.props.addQuickReply(
            this.props.conversationStep.conversationStepId
          ),
      });

      btns.push({
        text: "+ Button",
        onClick: () =>
          this.props.createButton({
            conversationStepId: this.props.conversationStep.conversationStepId,
          }),
      });
    }

    return btns;
  };

  render() {
    const {
      conversationStep: {
        buttons,
        conversationStepId,
        conversationId,
        errors,
        randomizeButtons,
      },
      isMenuOpen,
      editable,
    } = this.props;

    const { displayInsertFieldModal, editorState } = this.state;

    const messageTextClassName = classNames({
      "conversation-step__message-text": true,
      "conversation-step__message-text--selected": editable && isMenuOpen,
      "conversation-step__message-text--editable": editable,
      error: (errors.messageText || "").length,
    });

    const insertModalfields = [
      ["First Name", "{{user_first_name}}"],
      ["Last Name", "{{user_last_name}}"],
      ["Full Name", "{{user_full_name}}"],
    ];

    return (
      <div>
        <div>
          <div className="conversation-step__bot-message">
            <div className={messageTextClassName} onClick={this.handleClick}>
              <BuilderInput
                editorState={editorState}
                onChange={this.handleChange}
                editable={editable}
                placeholder="Write a message"
                maxChars={300}
              />
            </div>
            <SelectOptionModal
              title="Select a data field to insert"
              saveButtonText="Insert"
              isOpen={displayInsertFieldModal}
              onClose={this.hideInsertFieldModal}
              onSave={this.handleInsertFieldConfirmed}
              options={insertModalfields.map(field => ({
                name: field[0],
                value: field[1],
              }))}
            />
          </div>
          <Error error={errors.messageText} />
          <Error error={errors.conversationStepQuickReplies} />
          <Error error={errors.conversationStepButtons} />
          {editable && isMenuOpen && (
            <SeparatedButtonRow
              className="conversation-step-button__button-types"
              buttons={this.menuButtons()}
            />
          )}
          <div className="conversation-step__buttons">
            {buttons.map((button, i) => (
              <ConversationStepButton
                button={button}
                conversationId={conversationId}
                conversationStepId={conversationStepId}
                editable={editable}
                key={button.conversationStepButtonId}
                errors={errors["buttons"][i]}
                randomized={randomizeButtons}
                toggleRandomize={this.props.toggleRandomizeButtons}
              />
            ))}
          </div>
          <div>
            {_.pairs(combineObjects(errors["buttons"])).map((error) => {
              return <Error error={error[1]} key={error[0]} />;
            })}
          </div>
        </div>
      </div>
    );
  }

  handleInsertFieldConfirmed = (field) => {
    const { editorState } = this.state;
    const change = editorState.change();
    change.insertText(field);

    change.selectAll();
    change.collapseToEnd();

    this.handleChange(change);
    this.hideInsertFieldModal();
    this.focusInput();
  };

  hideInsertFieldModal = () =>
    this.setState({ displayInsertFieldModal: false });

  handleChange = ({ state: editorState }) => {
    this.setState({ editorState });

    this.props.handleFieldChange(
      "messageText",
      JSON.stringify(editorState.toJSON())
    );
  };

  handleClick = async () => this.focusInput();

  focusInput = () => {
    this.props.openMenu({
      type: TEXT_MESSAGE,
      id: this.props.conversationStep.conversationStepId,
    });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;

  const isMenuOpen =
    menuId === ownProps.conversationStep.conversationStepId &&
    menuType === TEXT_MESSAGE;

  return {
    isMenuOpen,
  };
};

export default connect(mapStateToProps, {
  openMenu,
  addQuickReply,
  createButton,
  toggleRandomizeButtons,
})(TextMessage);
