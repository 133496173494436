import React from "react";
import classNames from "classnames";

import Dropdown from "./Dropdown";

export default ({
  buttonText,
  dropdownOptions,
  disabled = false,
  className = null,
  onToggle = null,
}) => {
  const _className = classNames({
    "caret-dropdown": true,
    [className]: !!className,
  });
  return (
    <Dropdown
      buttonText={buttonText}
      dropdownOptions={dropdownOptions}
      disabled={disabled}
      onToggle={onToggle}
      className={_className}
      buttonClassName="caret-dropdown__button"
      openButtonClassName="caret-dropdown__button--open"
      dropdownClassName="caret-dropdown__options"
      openDropdownClassName="caret-dropdown__options--open"
      dropdownOptionClassName="caret-dropdown__option"
      openDropdownOptionClassName="caret-dropdown__option--open"
    />
  );
};
