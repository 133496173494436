export const FETCH_CAMPAIGNS_REQUEST = "FETCH_CAMPAIGNS_REQUEST";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAILURE = "FETCH_CAMPAIGNS_FAILURE";

export const FETCH_CAMPAIGN_REQUEST = "FETCH_CAMPAIGN_REQUEST";
export const FETCH_CAMPAIGN_SUCCESS = "FETCH_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGN_FAILURE = "FETCH_CAMPAIGN_FAILURE";

export const PUT_CAMPAIGN_REQUEST = "PUT_CAMPAIGN_REQUEST";
export const PUT_CAMPAIGN_SUCCESS = "PUT_CAMPAIGN_SUCCESS";
export const PUT_CAMPAIGN_FAILURE = "PUT_CAMPAIGN_FAILURE";

export const DELETE_CAMPAIGN_REQUEST = "DELETE_CAMPAIGN_REQUEST";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

export const DUPLICATE_CAMPAIGN_REQUEST = "DUPLICATE_CAMPAIGN_REQUEST";
export const DUPLICATE_CAMPAIGN_SUCCESS = "DUPLICATE_CAMPAIGN_SUCCESS";
export const DUPLICATE_CAMPAIGN_FAILURE = "DUPLICATE_CAMPAIGN_FAILURE";

export const FETCH_CAMPAIGN_CONVERSATIONS_REQUEST =
  "FETCH_CAMPAIGN_CONVERSATIONS_REQUEST";
export const FETCH_CAMPAIGN_CONVERSATIONS_SUCCESS =
  "FETCH_CAMPAIGN_CONVERSATIONS_SUCCESS";
export const FETCH_CAMPAIGN_CONVERSATIONS_FAILURE =
  "FETCH_CAMPAIGN_CONVERSATIONS_FAILURE";
