import * as Sentry from "@sentry/react";
import React from "react";
import { Switch } from "react-router-dom";
import DocumentTitle from "react-document-title";

import { userIsAuthenticated, userIsNotAuthenticated } from "../utilities/auth";
import { RouteWithSubRoutes } from "../components/shared";

import ConfigDebug from "./ConfigDebug";
import DynamicMessageBuilder from "./DynamicMessageBuilder";
import Acquisition from "./Acquisition";
import Audience from "./Audience";
import CampaignDetails from "./CampaignDetail";
import Campaigns from "./Campaigns";
import Configuration from "./Configuration";
import Dashboard from "./Dashboard";
import Entrypoints from "./Entrypoints";
import EditCampaignDetails from "./EditCampaignDetails";
import FlashPage from "./FlashPage";
import GlobalTriggers from "./GlobalTriggers";
import Fields from "./Fields";
import NewField from "./NewField";
import Field from "./Field";
import EditField from "./EditField";
import Layout from "./Layout";
import LinkDetails from "./LinkDetails";
import Login from "./Login";
import NewCampaign from "./NewCampaign";
import NewGlobalDefaultConversation from "./NewGlobalDefaultConversation";
import Organizations from "./Organizations";
import PasswordReset from "./PasswordReset";
import PasswordResetRequest from "./PasswordResetRequest";
import PersistentMenuBuilder from "./PersistentMenuBuilder";
import PersistentMenuPublisher from "./PersistentMenuPublisher";
import Products from "./Products";
import ProductDetail from "./ProductDetail";
import EditProduct from "./EditProduct";
import NewGlobalTrigger from "./NewGlobalTrigger";
import NewSubscrliberList from "./NewSubscriberList";
import NewProduct from "./NewProduct";
import SubscriberDetail from "./SubscriberDetail";
import { 
  SubscriberListDetail, 
  UnsubscribersListDetail,
} from "./SubscriberListDetail";
import SubscriberWebhooks from "./SubscriberWebhooks";
import Sequences from "./Sequences";
import Sequence from "./SequenceDetail";
import EditSequence from "./EditSequence";
import NewWelcomeMessage from "./WelcomeMessage/NewWelcomeMessage";
import WelcomeMessageDetail from "./WelcomeMessage/WelcomeMessageDetail";
import EditWelcomeMessageConversation from "./WelcomeMessage/EditWelcomeMessageConversation";
import NewSequence from "./NewSequence";
import NewConversation from "./NewConversation";
import SourceDetails from "./SourceDetails";
import {
  AcquisitionConversationPublisher,
  ConversationBuilder,
  ConversationDetails,
  FunnelDetails,
  CampaignConversationPublisher,
  ConversationPreview,
  EditConversationDetails,
  TriggerConversationPublisher,
} from "./ConversationDetail";
import GlobalDefaultConversations from "./GlobalDefaultConversations";
import GlobalDefaultConversationPublisher from "./GlobalDefaultConversationPublisher";
import TaskFailures from "./TaskFailures";
import {
  ReportDetail,
  ReportList
} from "./Reporting";

import {
  GlobalTriggerDetail,
  EditGlobalTriggerDetails,
} from "./GlobalTriggerDetail";

import "../styles/App.css";
import NewWelcomeMessageConversation from "./WelcomeMessage/NewWelcomeMessageConversation";
import WelcomeMessageConversationBuilder from "./WelcomeMessage/WelcomeMessageConversationBuilder";
import WMConversationPublisher from "./WelcomeMessage/WMConversationPublisher";
import EditWelcomeMessage from "./WelcomeMessage/EditWelcomeMessage";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

const conversationBuilderPathConfig = {
  component: ConversationBuilder,
  exact: true,
  breadcrumbTitle: (state, match) => {
    const conversationId = match.params.conversationId;
    const conversation = state.conversations.conversationsById[conversationId];
    return conversation ? conversation.label : null;
  },
};

const getCampaignPageBreadcrumbTitle = (state, match) => {
  const campaignId = match.params.campaignId;
  const campaign = state.campaigns.campaignsById[campaignId];
  return campaign ? campaign.title : null;
};

const getTriggerPageBreadcrumbTitle = (state, match) => {
  const globalTriggerId = match.params.triggerId;
  const globalTrigger =
    state.globalTriggers.globalTriggersById[globalTriggerId];
  return globalTrigger ? globalTrigger.title : null;
};

const persistentMenuPageControls = (state, match) => {
  return [
    {
      url: (match) => `/global/persistent_menu/build`,
      title: "Edit",
    },
    {
      url: (match) => `/global/persistent_menu/publish`,
      title: "Publish",
    },
  ];
};

const globalDefaultConversationPageControls = (state, match) => {
  const conversation =
    state.conversations.conversationsById[match.params.conversationId];
  if (conversation) {
    if (conversation.published) {
      return [
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/details`,
          title: "Details",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/preview`,
          title: "Preview",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/funnel`,
          title: "Performance",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/sequences`,
          title: "Sequences",
        },
      ];
    } else if (conversation.scheduled) {
      return [
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/details`,
          title: "Details",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/preview`,
          title: "Preview",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/sequences`,
          title: "Sequences",
        },
      ];
    } else {
      return [
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/details`,
          title: "Details",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/build`,
          title: "Build",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/publish`,
          title: "Publish",
        },
        {
          url: (match) =>
            `/global/unexpected/conversations/${match.params.conversationId}/sequences`,
          title: "Sequences",
        },
      ];
    }
  } else {
    return [];
  }
};

const getCampaignConversationPageControls = (state, match) => {
  const conversationId = match.params.conversationId;
  const conversation = state.conversations.conversationsById[conversationId];

  if (!conversation) {
    return [];
  }

  if (conversation.published) {
    return [
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/preview`,
        title: "Preview",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/funnel`,
        title: "Performance",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  } else if (conversation.scheduled) {
    return [
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/preview`,
        title: "Preview",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  } else {
    return [
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/build`,
        title: "Build",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/publish`,
        title: "Publish",
      },
      {
        url: (match) =>
          `/campaigns/${match.params.campaignId}/conversations/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  }
};

const getWMConversationPageControls = (state, match) => {
  const {
    params: { conversationId, welcomeMessageId },
  } = match;

  const conversation = state.conversations.conversationsById[conversationId];

  if (!conversation) return [];

  if (conversation.published) {
    return [];
  }

  return [
    {
      url: (match) =>
        `/entrypoints/wm/${welcomeMessageId}/conversations/${conversationId}/details`,
      title: "Details",
    },
    {
      url: (match) =>
        `/entrypoints/wm/${welcomeMessageId}/conversations/${conversationId}/publish`,
      title: "Publish",
    },
  ];
};

const getTriggerConversationPageControls = (state, match) => {
  const {
    params: { conversationId },
  } = match;

  const conversation = state.conversations.conversationsById[conversationId];

  if (!conversation) return [];

  if (conversation.published) {
    return [
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/preview`,
        title: "Preview",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/funnel`,
        title: "Performance",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  } else if (conversation.scheduled) {
    return [
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/preview`,
        title: "Preview",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  } else {
    return [
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/build`,
        title: "Build",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/publish`,
        title: "Publish",
      },
      {
        url: (match) =>
          `/global/${match.params.triggerId}/conversations/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  }
};

const getAcquisitionConversationPageControls = (state, match) => {
  const {
    params: { conversationId },
  } = match;

  const conversation = state.conversations.conversationsById[conversationId];

  if (!conversation) return [];

  if (conversation.published) {
    return [
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/preview`,
        title: "Preview",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/funnel`,
        title: "Performance",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  } else if (conversation.scheduled) {
    return [
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/preview`,
        title: "Preview",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  } else {
    return [
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/details`,
        title: "Details",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/build`,
        title: "Build",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/publish`,
        title: "Publish",
      },
      {
        url: (match) =>
          `/acquisition/signup/${match.params.conversationId}/sequences`,
        title: "Sequences",
      },
    ];
  }
};

const routes = [
  {
    path: "/flash",
    component: FlashPage,
  },
  {
    path: "/login",
    component: userIsNotAuthenticated(Login),
  },
  {
    path: "/password_reset/request",
    component: PasswordResetRequest,
  },
  {
    path: "/password_reset/:token",
    component: userIsNotAuthenticated(PasswordReset),
  },
  {
    path: "/",
    component: userIsAuthenticated(Layout),
    routes: [
      {
        path: "/",
        component: Dashboard,
        exact: true,
        hideBreadcrumbs: true, // don't show any breadcrumbs on this page
        excludeRouteFromBreadcrumbs: true, // don't include this route in the breadcrumbs on ANY page
      },
      {
        path: "/configuration",
        component: Configuration,
        exact: true,
        breadcrumbTitle: "Configuration",
      },
      {
        path: "/entrypoints",
        component: Entrypoints,
        exact: true,
        breadcrumbTitle: "Entrypoints",
      },
      {
        path: "/entrypoints/wm/new",
        component: NewWelcomeMessage,
        exact: true,
      },
      {
        path: "/entrypoints/wm/:welcomeMessageId(\\d+)/",
        component: WelcomeMessageDetail,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const id = match.params.welcomeMessageId;
          const wm = state.welcomeMessages.welcomeMessagesById[id];
          return wm ? wm.name : null;
        },
      },
      {
        path: "/entrypoints/wm/:welcomeMessageId(\\d+)/conversations/new",
        component: NewWelcomeMessageConversation,
        exact: true,
      },
      {
        path: "/entrypoints/wm/:welcomeMessageId(\\d+)/edit",
        component: EditWelcomeMessage,
        exact: true,
      },
      {
        path:
          "/entrypoints/wm/:welcomeMessageId(\\d+)/conversations/:conversationId(\\d+)/details",
        component: WelcomeMessageConversationBuilder,
        exact: true,
        pageControls: getWMConversationPageControls,
      },
      {
        path:
          "/entrypoints/wm/:welcomeMessageId(\\d+)/conversations/:conversationId(\\d+)/edit",
        component: EditWelcomeMessageConversation,
        exact: true,
      },
      {
        path:
          "/entrypoints/wm/:welcomeMessageId(\\d+)/conversations/:conversationId(\\d+)/publish",
        component: WMConversationPublisher,
        exact: true,
        pageControls: getWMConversationPageControls,
      },
      {
        path: "/audience",
        component: Audience,
        exact: true,
        breadcrumbTitle: "Audience",
      },
      {
        path: "/products",
        component: Products,
        exact: true,
        breadcrumbTitle: "Inventory",
      },
      {
        path: "/products/new",
        component: NewProduct,
        exact: true,
      },
      {
        path: "/products/:productId(\\d+)/edit",
        component: EditProduct,
        exact: true,
      },
      {
        path: "/products/:productId(\\d+)",
        component: ProductDetail,
        exact: true,
      },
      {
        path: "/audience/subscribers/:subscriberId(\\d+)",
        component: SubscriberDetail,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const subscriberId = match.params.subscriberId;
          const subscriber = state.subscribers.subscribersById[subscriberId];
          return subscriber ? subscriber.fullName : null;
        },
      },
      {
        path: "/audience/subscribers/:subscriberId(\\d+)/webhooks",
        component: SubscriberWebhooks,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const subscriberId = match.params.subscriberId;
          const subscriber = state.subscribers.subscribersById[subscriberId];
          return subscriber ? subscriber.fullName : null;
        },
      },
      {
        path: "/audience/lists/new",
        component: NewSubscrliberList,
        exact: true,
        breadcrumbTitle: "New List",
      },
      {
        path: "/audience/lists/unsubscribers",
        component: UnsubscribersListDetail,
        exact: true,
        breadcrumbTitle: "Unsubscribers List",
      },
      {
        path: "/audience/lists/:subscriberListId(\\d+)",
        component: SubscriberListDetail,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const subscriberListId = match.params.subscriberListId;
          const subscriberList = state.subscriberLists.byId[subscriberListId];
          return subscriberList ? subscriberList.name : null;
        },
      },
      {
        path: "/audience/lists/:subscriberListId(\\d+)/edit",
        component: NewSubscrliberList,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const subscriberListId = match.params.subscriberListId;
          const subscriberList = state.subscriberLists.byId[subscriberListId];
          return subscriberList ? subscriberList.name : null;
        },
      },
      {
        path: "/global",
        component: GlobalTriggers,
        exact: true,
        breadcrumbTitle: "Triggers",
      },
      {
        path: "/fields",
        component: Fields,
        exact: true,
        breadcrumbTitle: "Fields",
      },
      {
        path: "/fields/:fieldId(\\d+)",
        component: Field,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const field = state.organizationFields.byId[match.params.fieldId];
          return field ? field.name : "Field";
        },
      },
      {
        path: "/fields/:fieldId(\\d+)/edit",
        component: EditField,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const field = state.organizationFields.byId[match.params.fieldId];
          return field ? `Edit '${field.name}' Field` : "Edit Field";
        },
      },
      {
        path: "/fields/new",
        component: NewField,
        exact: true,
        breadcrumbTitle: "New field",
      },
      {
        path: "/global/persistent_menu/build",
        component: PersistentMenuBuilder,
        exact: true,
        pageControls: persistentMenuPageControls,
        breadcrumbTitle: "Global Menu Builder",
      },
      {
        path: "/global/persistent_menu/publish",
        component: PersistentMenuPublisher,
        exact: true,
        pageControls: persistentMenuPageControls,
        breadcrumbTitle: "Global Menu Builder",
      },
      {
        path: "/global/new",
        component: NewGlobalTrigger,
        exact: true,
        breadcrumbTitle: "New Trigger",
      },
      {
        path: "/global/unexpected",
        breadcrumbTitle: "Unexpected",
        component: GlobalDefaultConversations,
        exact: true,
      },
      {
        path: "/global/unexpected/conversations/new",
        breadcrumbTitle: "New Conversation",
        component: NewGlobalDefaultConversation,
        exact: true,
      },
      {
        ...conversationBuilderPathConfig,
        path: "/global/unexpected/conversations/:conversationId(\\d+)/details",
        component: ConversationDetails,
        pageControls: globalDefaultConversationPageControls,
        exact: true,
      },
      {
        ...conversationBuilderPathConfig,
        component: FunnelDetails,
        pageControls: globalDefaultConversationPageControls,
        path: "/global/unexpected/conversations/:conversationId(\\d+)/funnel",
      },
      {
        path: "/global/unexpected/conversations/:conversationId(\\d+)/build",
        ...conversationBuilderPathConfig,
        component: ConversationBuilder,
        pageControls: globalDefaultConversationPageControls,
      },
      {
        path: "/global/unexpected/conversations/:conversationId(\\d+)/preview",
        ...conversationBuilderPathConfig,
        component: ConversationPreview,
        pageControls: globalDefaultConversationPageControls,
      },
      {
        path: "/global/unexpected/conversations/:conversationId(\\d+)/publish",
        ...conversationBuilderPathConfig,
        pageControls: globalDefaultConversationPageControls,
        component: GlobalDefaultConversationPublisher,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/unexpected/conversations/:conversationId(\\d+)/sequences",
        component: Sequences,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
        breadcrumbTitle: "Sequences",
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/unexpected/conversations/:conversationId(\\d+)/sequences/new",
        component: NewSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/unexpected/conversations/:conversationId(\\d+)/sequences/:sequenceId(\\d+)",
        component: Sequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
        breadcrumbTitle: (state, match) => {
          const { sequenceId } = match.params;
          const sequence = state.sequences.byId[sequenceId];
          return sequence ? sequence.name : null;
        },
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/unexpected/conversations/:conversationId(\\d+)/sequences/:sequenceId(\\d+)/edit",
        component: EditSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        path: "/global/:triggerId(\\d+)",
        component: GlobalTriggerDetail,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const globalTriggerId = match.params.triggerId;
          const globalTrigger =
            state.globalTriggers.globalTriggersById[globalTriggerId];
          return globalTrigger ? globalTrigger.name : null;
        },
      },
      {
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/details",
        pageControls: getTriggerConversationPageControls,
        ...conversationBuilderPathConfig,
        component: ConversationDetails,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        ...conversationBuilderPathConfig,
        component: FunnelDetails,
        pageControls: getTriggerConversationPageControls,
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/funnel",
      },
      {
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/build",
        pageControls: getTriggerConversationPageControls,
        ...conversationBuilderPathConfig,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/publish",
        pageControls: getTriggerConversationPageControls,
        ...conversationBuilderPathConfig,
        component: TriggerConversationPublisher,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/preview",
        pageControls: getTriggerConversationPageControls,
        ...conversationBuilderPathConfig,
        component: ConversationPreview,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/sequences",
        component: Sequences,
        exact: true,
        pageControls: getTriggerConversationPageControls,
        breadcrumbTitle: "Sequences",
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/sequences/new",
        component: NewSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/sequences/:sequenceId(\\d+)",
        component: Sequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
        breadcrumbTitle: (state, match) => {
          const { sequenceId } = match.params;
          const sequence = state.sequences.byId[sequenceId];
          return sequence ? sequence.name : null;
        },
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/sequences/:sequenceId(\\d+)/edit",
        component: EditSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        path:
          "/global/:triggerId(\\d+)/conversations/:conversationId(\\d+)/edit",
        ...conversationBuilderPathConfig,
        exact: true,
        component: EditConversationDetails,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        path: "/global/unexpected/conversations/:conversationId(\\d+)/edit",
        ...conversationBuilderPathConfig,
        exact: true,
        component: EditConversationDetails,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        path: "/global/:triggerId(\\d+)/conversations/new",
        component: NewConversation,
        exact: true,
        breadcrumbTitle: "New Conversation",
      },
      {
        path: "/global/:triggerId(\\d+)/edit",
        component: EditGlobalTriggerDetails,
        exact: true,
        breadcrumbTitle: getTriggerPageBreadcrumbTitle,
      },
      {
        path: "/campaigns",
        component: Campaigns,
        exact: true,
        breadcrumbTitle: "Campaigns",
      },
      {
        path: "/campaigns/new",
        component: NewCampaign,
        exact: true,
        breadcrumbTitle: "New Campaign",
      },
      {
        path: "/campaigns/:campaignId(\\d+)",
        component: CampaignDetails,
        exact: true,
        breadcrumbTitle: getCampaignPageBreadcrumbTitle,
      },
      {
        path: "/campaigns/:campaignId(\\d+)/edit",
        component: EditCampaignDetails,
        exact: true,
        breadcrumbTitle: getCampaignPageBreadcrumbTitle,
      },
      {
        path: "*/links/:buttonType/:buttonId(\\d+)",
        component: LinkDetails,
        exact: true,
      },
      {
        path: "/acquisition",
        component: Acquisition,
        exact: true,
        breadcrumbTitle: "Organic",
      },
      // signup conversation
      {
        path: "/acquisition/signup/:conversationId(\\d+)/build",
        pageControls: getAcquisitionConversationPageControls,
        ...conversationBuilderPathConfig,
      },
      {
        path: "/acquisition/signup/:conversationId(\\d+)/details",
        pageControls: getAcquisitionConversationPageControls,
        ...conversationBuilderPathConfig,
        component: ConversationDetails,
      },
      {
        ...conversationBuilderPathConfig,
        component: FunnelDetails,
        pageControls: getAcquisitionConversationPageControls,
        path: "/acquisition/signup/:conversationId(\\d+)/funnel",
      },
      {
        path: "/acquisition/signup/:conversationId(\\d+)/publish",
        pageControls: getAcquisitionConversationPageControls,
        ...conversationBuilderPathConfig,
        component: AcquisitionConversationPublisher,
      },
      {
        path: "/acquisition/signup/:conversationId(\\d+)/preview",
        pageControls: getAcquisitionConversationPageControls,
        ...conversationBuilderPathConfig,
        component: ConversationPreview,
      },
      {
        path: "/acquisition/signup/:conversationId(\\d+)/edit",
        ...conversationBuilderPathConfig,
        exact: true,
        component: EditConversationDetails,
        breadcrumbTitle: getAcquisitionConversationPageControls,
      },
      {
        path: "/acquisition/signup/new",
        component: NewConversation,
        exact: true,
        breadcrumbTitle: "New Signup Conversation",
      },
      {
        ...conversationBuilderPathConfig,
        path: "/acquisition/signup/:conversationId(\\d+)/sequences",
        component: Sequences,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
        breadcrumbTitle: "Sequences",
      },
      {
        ...conversationBuilderPathConfig,
        path: "/acquisition/signup/:conversationId(\\d+)/sequences/new",
        component: NewSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/acquisition/signup/:conversationId(\\d+)/sequences/:sequenceId(\\d+)",
        component: Sequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
        breadcrumbTitle: (state, match) => {
          const { sequenceId } = match.params;
          const sequence = state.sequences.byId[sequenceId];
          return sequence ? sequence.name : null;
        },
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/acquisition/signup/:conversationId(\\d+)/sequences/:sequenceId(\\d+)/edit",
        component: EditSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      // campaign conversation
      {
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/build",
        pageControls: getCampaignConversationPageControls,
        ...conversationBuilderPathConfig,
      },
      {
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/publish",
        component: CampaignConversationPublisher,
        exact: true,
        breadcrumbTitle: (state, match) => {
          const conversationId = match.params.conversationId;
          const conversation =
            state.conversations.conversationsById[conversationId];
          return conversation ? conversation.label : null;
        },
        pageControls: getCampaignConversationPageControls,
      },
      {
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/details",
        pageControls: getCampaignConversationPageControls,
        ...conversationBuilderPathConfig,
        component: ConversationDetails,
      },
      {
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/funnel",
        pageControls: getCampaignConversationPageControls,
        ...conversationBuilderPathConfig,
        component: FunnelDetails,
      },
      {
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/preview",
        pageControls: getCampaignConversationPageControls,
        ...conversationBuilderPathConfig,
        component: ConversationPreview,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/sequences",
        component: Sequences,
        exact: true,
        pageControls: getCampaignConversationPageControls,
        breadcrumbTitle: "Sequences",
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/sequences/new",
        component: NewSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/sequences/:sequenceId(\\d+)",
        component: Sequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
        breadcrumbTitle: (state, match) => {
          const { sequenceId } = match.params;
          const sequence = state.sequences.byId[sequenceId];
          return sequence ? sequence.name : null;
        },
      },
      {
        ...conversationBuilderPathConfig,
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/sequences/:sequenceId(\\d+)/edit",
        component: EditSequence,
        exact: true,
        pageControls: getAcquisitionConversationPageControls,
      },
      {
        path:
          "/campaigns/:campaignId(\\d+)/conversations/:conversationId(\\d+)/edit",
        ...conversationBuilderPathConfig,
        exact: true,
        component: EditConversationDetails,
      },
      {
        path: "/campaigns/:campaignId(\\d+)/conversations/new",
        component: NewConversation,
        exact: true,
        breadcrumbTitle: "New Conversation",
      },
      {
        path: "/config/debug",
        component: ConfigDebug,
        exact: true,
      },
      {
        path:
          "/audience/subscribers/:subscriberId(\\d+)/sources/ads/:adId(\\d+)",
        component: SourceDetails,
        exact: true,
      },
      {
        path:
          "/audience/subscribers/:subscriberId(\\d+)/sources/customer_chat_plugin/:url",
        component: SourceDetails,
        exact: true,
      },
      {
        path: "/audience/subscribers/:subscriberId(\\d+)/sources/:source/:ref?",
        component: SourceDetails,
        exact: true,
      },
      {
        path: "/organizations",
        component: Organizations,
        exact: true,
      },
      {
        path: "/failures",
        component: TaskFailures,
        exact: true,
      },
      {
        path: "/reports",
        component: ReportList,
        exact: true,
        breadcrumbTitle: "Reports",
        hideBreadcrumbs: true,    
      },
      {
        path: "/reports/new",
        component: ReportDetail,
        exact: true,
        hideBreadcrumbs: true,  // Since this is actually the entry point from the naviation sidebar right now, we don't show breadcrumbs on any reporting pages
      },
      {
        path: "/reports/:reportId",
        component: ReportDetail,
        exact: true,
        hideBreadcrumbs: true,
      },
      {
        component: DynamicMessageBuilder,
        exact: true,
        path: "/(.*)/:conversationId(\\d+)/dynamic/:id(\\d+)/build",
      },
    ],
  },
];

const App = () => (
  <div className="app">
    <DocumentTitle title="Paloma">
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </DocumentTitle>
  </div>
);

export default App;
