import React from "react";
import { connect } from "react-redux";
import { ContentHeader } from "../components/shared";
import PersistentMenu from "../components/PersistentMenu";
import _ from "underscore";
import {
  fetchPersistentMenu,
  putPersistentMenu,
} from "../actions/persistent-menu";
import { openMenu } from "../actions/builder-menu";
import { fetchGlobalTriggers } from "../actions/global-triggers";

// from https://stackoverflow.com/a/2117523/1730004
const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

class PersistentMenuBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      persistentMenuJson: this.props.persistentMenuJson || {
        callToActions: [],
      },
      title: this.props.title,
    };
  }

  componentWillReceiveProps(newProps) {
    if (
      !_.isMatch(this.state.persistentMenuJson, newProps.persistentMenuJson)
    ) {
      this.setState({
        persistentMenuJson: newProps.persistentMenuJson,
      });
    }
    if (this.state.title !== newProps.title) {
      this.setState({ title: newProps.title });
    }
  }

  componentWillMount() {
    this.props.fetchGlobalTriggers();
    this.props.fetchPersistentMenu();
  }

  render = () => {
    this.populateCtasIfEmpty();
    return (
      <div>
        <ContentHeader
          left={<h2 style={{ width: "100%" }}>{this.state.title || ""}</h2>}
        />
        <div className="persistent-menu-builder card">
          {false && (
            <pre>
              <code>
                {JSON.stringify(this.state.persistentMenuJson, null, 2)}
              </code>
            </pre>
          )}
          <PersistentMenu
            addNewItem={() =>
              this.updateCallToAction(
                this.state.persistentMenuJson.callToActions.length,
                { id: uuid() }
              )
            }
            callToActions={this.state.persistentMenuJson.callToActions}
            deleteCallToAction={this.deleteCallToAction}
            globalTriggers={this.props.globalTriggers}
            menuId={this.props.menuId}
            menuType={this.props.menuType}
            nested={false}
            openMenu={this.props.openMenu}
            updateCallToAction={this.updateCallToAction}
            uuid={uuid}
          />
          {this.nestedMenuDivs()}
        </div>
      </div>
    );
  };

  nestedMenuDivs = () => {
    if (this.nestedMenus().length) {
      return (
        <div>
          {this.nestedMenus().map(m => {
            return (
              <PersistentMenu
                header={m.cta.title}
                callToActions={m.cta.callToActions || []}
                globalTriggers={this.props.globalTriggers}
                updateCallToAction={this.updateNestedCallToAction(m.index)}
                deleteCallToAction={this.deleteNestedCallToAction(m.index)}
                addNewItem={() =>
                  this.updateNestedCallToAction(m.index)(
                    m.cta.callToActions.length,
                    { id: uuid() }
                  )
                }
                menuId={this.props.menuId}
                menuType={this.props.menuType}
                nested={true}
                openMenu={this.props.openMenu}
                key={m.index}
                uuid={uuid}
              />
            );
          })}
        </div>
      );
    }
  };

  nestedMenus = () => {
    const nested = [];
    this.state.persistentMenuJson.callToActions.forEach((cta, index) => {
      if (cta.type === "nested") {
        nested.push({ cta, index });
      }
    });
    return nested;
  };

  updateNestedCallToAction = outerIndex => {
    const functionToReturn = async (innerIndex, newObject) => {
      let callToActions = this.state.persistentMenuJson.callToActions.slice();
      callToActions[outerIndex].callToActions[innerIndex] = newObject;
      await this.setState({
        persistentMenuJson: {
          ...this.state.persistentMenuJson,
          callToActions: callToActions,
        },
      });
      this.update();
    };
    return functionToReturn;
  };

  updateCallToAction = async (i, newObject) => {
    let callToActions = this.state.persistentMenuJson.callToActions.slice();
    callToActions[i] = newObject;
    await this.props.putPersistentMenu({
      content: {
        ...this.state.persistentMenuJson,
        callToActions,
      },
    });
  };

  populateCtasIfEmpty = async () => {
    if (this.props.loading) {
      return;
    }
    if (this.state.persistentMenuJson.callToActions.length === 0) {
      await this.updateCallToAction(0, { id: uuid() });
    }
  };

  deleteCallToAction = async i => {
    let callToActions = this.state.persistentMenuJson.callToActions.slice();
    callToActions.splice(i, 1);
    await this.setState({
      persistentMenuJson: {
        ...this.state.persistentMenuJson,
        callToActions: callToActions,
      },
    });
    this.update();
  };

  deleteNestedCallToAction = outerIndex => {
    const functionToReturn = async innerIndex => {
      let callToActions = this.state.persistentMenuJson.callToActions.slice();
      callToActions[outerIndex].callToActions.splice(innerIndex, 1);
      await this.setState({
        persistentMenuJson: {
          ...this.state.persistentMenuJson,
          callToActions: callToActions,
        },
      });
      this.update();
    };
    return functionToReturn;
  };

  update = () => {
    this.props.putPersistentMenu({ content: this.state.persistentMenuJson });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;
  const defaultMenu = {
    content: {
      callToActions: [],
      composer_input_disabled: false,
      locale: "default",
    },
    title: "Loading...",
  };
  const loading = state.persistentMenu.loading;
  const menu = Object.values(state.persistentMenu.menus)[0] || defaultMenu;

  return {
    globalTriggers: Object.values(state.globalTriggers.globalTriggersById),
    loading,
    menuId,
    menuType,
    persistentMenuJson: menu.content,
    title: menu.title,
  };
};

export default connect(
  mapStateToProps,
  { putPersistentMenu, fetchPersistentMenu, fetchGlobalTriggers, openMenu }
)(PersistentMenuBuilder);
