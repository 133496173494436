import React, { Component } from "react";
import { Link } from "react-router-dom";

import { DeleteGlobalTriggerModal } from "../shared";

class GlobalTriggersTableRowButtons extends Component {
  state = {
    displayDeleteModal: false,
  };

  componentDidMount() {
    this.isStillMounted = true;
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  render() {
    const { globalTrigger } = this.props;

    const { displayDeleteModal } = this.state;

    return (
      <div>
        {displayDeleteModal && (
          <DeleteGlobalTriggerModal
            globalTrigger={globalTrigger}
            onClose={this.hideDeleteModal}
          />
        )}
        {globalTrigger.deletable && (
          <button
            className="table__link"
            onClick={() => this.props.duplicate(globalTrigger.triggerId)}
          >
            Duplicate
          </button>
        )}
        <Link className="table__link" to={`/global/${globalTrigger.triggerId}`}>
          View
        </Link>
        {globalTrigger.deletable && (
          <button className="table__link" onClick={this.showDeleteModal}>
            Delete
          </button>
        )}
      </div>
    );
  }

  showDeleteModal = () => this.setState({ displayDeleteModal: true });
  hideDeleteModal = () => {
    if (this.isStillMounted) {
      this.setState({ displayDeleteModal: false });
    }
  };
}

export default GlobalTriggersTableRowButtons;
