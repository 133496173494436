import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/current-organization";
import { UPDATE_SHOPIFY_CONFIG_SUCCESS } from "../constants/shopify-admin-api-key";

const initialState = {
  currentOrganization: null,
  isLoading: false,
  errorMessage: "",
  users: [],
};

export default createReducer(initialState, {
  [constants.FETCH_CURRENT_ORGANIZATION_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.FETCH_CURRENT_ORGANIZATION_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload.currentOrganization,
      isLoading: false,
    };
  },

  [constants.POST_UNSUBSCRIBERS_CREATE_AUDIENCE_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload.currentOrganization,
    };
  },

  [constants.FETCH_CURRENT_ORGANIZATION_FAILURE](state, action) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.response
        ? JSON.parse(action.payload.response).message
        : "",
    };
  },

  [constants.UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_REQUEST](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [constants.UPDATE_CURRENT_ORGANIZATION_FACEBOOK_PAGE_SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      currentOrganization: action.payload.currentOrganization,
    };
  },

  [constants.FETCH_CURRENT_ORGANIZATION_USERS_SUCCESS](state, action) {
    const { payload: users } = action;
    return { ...state, users };
  },

  [UPDATE_SHOPIFY_CONFIG_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload.currentOrganization,
      isLoading: false,
    };
  },
});
