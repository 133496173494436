import * as constants from "../constants/sources";
import _ from "underscore";
import humps from "humps";
import { CALL_API } from "redux-api-middleware";

const compactObject = obj => {
  const newObj = {};
  _.keys(obj).forEach(key => {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const fetchSources = (params, type) => {
  const urlParams = _
    .pairs(compactObject(humps.decamelizeKeys(params)))
    .map(param => param[0] + "=" + param[1])
    .join("&");

  return {
    [CALL_API]: {
      endpoint: `/api/sources/${type}?${urlParams}`,
      method: "GET",
      types: [
        constants.FETCH_SOURCES_REQUEST,
        constants.FETCH_SOURCES_SUCCESS,
        constants.FETCH_SOURCES_FAILURE,
      ],
    },
  };
};
