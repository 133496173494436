import React from "react";
import moment from "moment";
import _ from "underscore";

import { formatDate } from "../utilities/format-date";
import { getConversationLink } from "../utilities/conversation";
import PaginatedList from "./PaginatedList";

const Conversation = conversation => (
  <tr key={conversation.conversationId} className="conversation text-xs">
    <td className="label">
      <a href={getConversationLink(conversation) + "/details"}>
        {conversation.label}
      </a>
    </td>
    <td className="last-ran-at">
      {formatDate(conversation.lastRanAt) || "Never"}
    </td>
    <td className="link">
      <a href={getConversationLink(conversation) + "/details"}>View</a>
    </td>
  </tr>
);

const lastRanAt = c =>
  (c.lastRanAt && moment.utc(c.lastRanAt).format("X")) || -Infinity;

const Conversations = ({ conversations = [] }) => {
  const sorted = _.sortBy(conversations, lastRanAt).reverse();
  return (
    <div className="conversations">
      <PaginatedList perPage={8} items={sorted} component={Conversation} />
    </div>
  );
};

export default Conversations;
