import React from "react";
import { Card } from "../shared";

const EditCampaignDetailsForm = ({
  campaignDescription,
  campaignName,
  onSubmit,
}) => (
  <Card className="with-margin">
    <form className="default-form edit-details-form" onSubmit={onSubmit}>
      <label
        className="default-form__label"
        htmlFor="edit-campaign-details-name"
      >
        Name
      </label>
      <input
        type="text"
        className="default-form__input"
        name="title"
        defaultValue={campaignName}
        id="edit-campaign-details-name"
      />
      <label
        className="default-form__label"
        htmlFor="edit-campaign-details-name"
      >
        Description
      </label>
      <input
        type="text"
        className="default-form__input"
        name="description"
        defaultValue={campaignDescription}
        id="edit-campaign-details-name"
      />
      <button
        type="submit"
        className="paloma-button paloma-button--pink default-form__submit"
      >
        Update
      </button>
    </form>
  </Card>
);

export default EditCampaignDetailsForm;
