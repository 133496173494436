import React, { Component } from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Creatable } from "react-select";

import TestSendConversation from "../TestSendConversation";
import { Card } from "../../components/shared";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ContentHeader } from "../../components/shared";
import { fetchSubscriberLists } from "../../actions/subscriber-lists";
import { getConversationLink } from "../../utilities/conversation";
import ConversationScheduler from "../../containers/ConversationScheduler";

import {
  fetchConversation,
  publishConversation,
} from "../../actions/conversations";

import infoIcon from "../../images/info.svg";

class TriggerConversationPublisher extends Component {
  state = {
    selectedSubscriberListOption: null,
  };

  componentWillMount() {
    const {
      conversationId,
      fetchConversation,
      fetchSubscriberLists,
    } = this.props;

    fetchConversation(conversationId);
    fetchSubscriberLists();
  }

  render() {
    const { conversation, subscriberLists, isLoading } = this.props;

    const { selectedSubscriberListOption } = this.state;

    const defaultOption = { label: "None", value: null };
    const subscriberListOptions = [
      defaultOption,
      ...subscriberLists.map(({ name: label, subscriberListId: value }) => ({
        label,
        value,
      })),
    ];

    return (
      <Loader loaded={!isLoading} loadedClassName="conversation-detail">
        <ContentHeader left={<h1>{conversation.label}</h1>} />

        <Card className="info-panel">
          <h4 className="conversation-publisher__heading">
            Add subscribers to a list
          </h4>
          <Creatable
            className="conversation-publisher__target-filter__value-select"
            value={selectedSubscriberListOption}
            options={subscriberListOptions}
            onChange={this.handleChangeSubscriberListOption}
            clearable={false}
            openOnFocus={false}
            openAfterFocus={false}
          />

          <div className="conversation-publisher">
            <ConversationScheduler
              conversationId={conversation.conversationId}
            />
            <ConfirmationModal
              onConfirm={this.publishConversation}
              schedule={conversation.sendAt}
            />

            <div className="conversation-publisher__notice-section">
              <img
                src={infoIcon}
                className="conversation-publisher__notice-section-icon"
                alt=""
              />
              <p>
                Publishing this conversation will deactivate the existing signup
                conversation.
                <br />
                All new subscribers will see this when they say 'Hi' for the
                first time.
              </p>
            </div>
          </div>
        </Card>
        <Card className="info-panel">
          <TestSendConversation id={conversation.conversationId} />
        </Card>
      </Loader>
    );
  }

  handleChangeSubscriberListOption = ({ value }) => {
    this.setState({ selectedSubscriberListOption: value });
  };

  publishConversation = () => {
    const {
      conversation,
      conversationId,
      publishConversation,
      push,
    } = this.props;

    const { selectedSubscriberListOption } = this.state;

    publishConversation({
      conversationId,
      subscriberListId: selectedSubscriberListOption,
    });

    push(getConversationLink(conversation) + "/details");
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { conversationId },
    },
  } = ownProps;

  const {
    conversations: { conversationsById },
    subscriberLists: { byId: subscriberListsById },
  } = state;

  const conversation = {
    isLoading: true,
    ...conversationsById[conversationId],
  };

  return {
    conversationId,
    conversation,
    isLoading: conversation.isLoading || subscriberListsById.isLoading,
    subscriberLists: Object.values(subscriberListsById),
  };
};

export default connect(mapStateToProps, {
  fetchConversation,
  fetchSubscriberLists,
  publishConversation,
  push,
})(TriggerConversationPublisher);
