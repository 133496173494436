import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchMessages,
} from "../actions/messages";

import { Card } from "./shared";
import Messages from "./Messages";
import infoIcon from "../images/info.svg";

class PaginatedMessages extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    this.fetchMessages()
  }

  fetchMessages = (page = 1) => {
    const { conversationId, messageType } = this.props
    this.props
      .fetchMessages({ page: page, conversationId, messageType })
      .then(() => this.setState({ loading: false }));
  }

  render = () => {
    return (
      <Card loading={this.state.loading}>
        <h3>
          {this.props.messageType == "feedback" &&
            <React.Fragment>
              Feedback Responses
            </React.Fragment>
          }
          {this.props.messageType == "unexpected" &&
            <React.Fragment>
              Unexpected Responses
              <span
                className="tooltip-container unexpected-responses"
                data-balloon="Unexpected responses happen when a subscribers says something that didn't fit into a conversation or a trigger."
                data-balloon-pos="down"
              >
                <img src={infoIcon} alt="" />
              </span>
            </React.Fragment>
          }
        </h3>
        <Messages
          hidePageNumbers
          {...this.props.messages}
          onChoosePage={this.fetchMessages}
        />
      </Card>
    );
  };
}

const mapStateToProps = (state, ownProps) => ({
  messages: state.messages[ownProps.messageType]
});

export default connect(mapStateToProps, {
  fetchMessages,
})(PaginatedMessages);
