import React from "react";

import { Card, TabSelector } from "../shared";
import MessageDetail from "../MessageDetail";
import PaginatedMessages from "../PaginatedMessages";
import Messages from "../Messages";

class MessagesSidebar extends React.Component {
  render() {
    if (this.props.conversation.hasUnexpectedResponses || this.props.conversation.hasFeedbackTrigger) {
      return (
        <div className="messages-sidebar">
          {this.props.conversation.hasUnexpectedResponses &&
            <div style={{ marginBottom: 20 }}>
              <PaginatedMessages messageType="unexpected" conversationId={this.props.conversation.conversationId} />
            </div>
          }
          {this.props.conversation.hasFeedbackTrigger &&
            <PaginatedMessages messageType="feedback" conversationId={this.props.conversation.conversationId} />
          }
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default MessagesSidebar;
