import { schema } from "normalizr";

const subscriberFieldSchema = new schema.Entity(
  "subscriberFields",
  {},
  { idAttribute: "subscriberFieldId" }
);

const subscriberListSchema = new schema.Entity(
  "subscriberLists",
  {},
  { idAttribute: "subscriberListId" }
);

const subscriberTagSchema = new schema.Entity(
  "subscriberTags",
  {},
  { idAttribute: "subscriberTagId" }
);

const organizationFieldSchema = new schema.Entity(
  "organizationFields",
  {},
  { idAttribute: "organizationFieldId" }
);

const conversationSchema = new schema.Entity(
  "conversations",
  {},
  { idAttribute: "conversationId" }
);

const subscriberConversationStateSchema = new schema.Entity(
  "subscriberConversationStates",
  {},
  {
    idAttribute: "subscriberConversationId",
  }
);

const subscriberSchema = new schema.Entity(
  "subscribers",
  {
    subscriberFields: [subscriberFieldSchema],
    subscriberLists: [subscriberListSchema],
    subscriberTags: [subscriberTagSchema],
    conversations: [conversationSchema],
    subscriberConversationStates: [subscriberConversationStateSchema],
  },
  { idAttribute: "subscriberId" }
);

subscriberListSchema.define({ subscribers: [subscriberSchema] });

export {
  subscriberSchema,
  subscriberFieldSchema,
  subscriberListSchema,
  subscriberTagSchema,
  organizationFieldSchema,
};
