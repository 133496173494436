import createReducer from "../utilities/create-reducer";
import { groupByFunction } from "../utilities/reducers";

import * as constants from "../constants/task-failures";

const initialState = { byOrganizationId: {} };

export default createReducer(initialState, {
  [constants.FETCH_TASK_FAILURES_SUCCESS](state, action) {
    return {
      byOrganizationId: groupByFunction(
        action.payload,
        tf => tf.organization.label
      ),
    };
  },
});
