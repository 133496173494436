import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import classNames from "classnames";

const PalomaToggle = ({
  checked,
  onLabel,
  offLabel,
  onChange,
  className = null,
  disabled = false,
}) => (
  <Toggle
    checked={checked}
    className={classNames({ toggle: true, [className]: !!className })}
    icons={{
      checked: onLabel,
      unchecked: offLabel,
    }}
    onChange={e => {
      onChange(e.target.checked);
    }}
    disabled={disabled}
  />
);

PalomaToggle.defaultProps = {
  checked: false,
  offLabel: "Off",
  onLabel: "On",
};

export default PalomaToggle;
