import createReducer from "../utilities/create-reducer";

import * as constants from "../constants/global-default-trigger";

const initialState = { actions: [] };

export default createReducer(initialState, {
  [constants.FETCH_GLOBAL_DEFAULT_TRIGGER_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.CREATE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS](
    state,
    action
  ) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.DELETE_GLOBAL_DEFAULT_MANUAL_MESSAGING_ACTIVATION_SUCCESS](
    state,
    action
  ) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.CREATE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.DELETE_GLOBAL_DEFAULT_THREAD_CONTROL_PASS_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [constants.UPDATE_GLOBAL_DEFAULT_ASSIGN_SUBSCRIBER_OWNER_SUCCESS](
    state,
    action
  ) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
