import React from "react";

import { Modal } from "../shared";

export default class SubscriberFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      subscriberFieldId: props.subscriberFieldId,
      value: props.value,
    };
  }

  handleNameChange = e => {
    this.setState({
      name: e.target.value,
    });
  };

  handleValueChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSave = e => {
    e.preventDefault();
    if (!this.isValid()) {
      return;
    }

    this.props.onSave(this.state);
  };

  handleClose = e => {
    e.preventDefault();
    this.props.onClose();
  };

  isValid = () => {
    return this.state.name && this.state.value;
  };

  render() {
    return (
      <Modal
        title={this.props.name ? "Edit field" : "Add field"}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <form className="modal__form" onSubmit={this.handleSave}>
          <fieldset className="modal__fieldset">
            <label htmlFor="title" className="modal__label">
              Title
            </label>
            <input
              className="modal__input"
              type="text"
              name="title"
              defaultValue={this.props.name}
              onChange={this.handleNameChange}
              onKeyPress={e => (e.key === "Enter" ? this.handleSave(e) : null)}
            />
            <label htmlFor="entry" className="modal__label">
              Entry
            </label>
            <input
              className="modal__input"
              type="text"
              name="entry"
              defaultValue={this.props.value}
              onChange={this.handleValueChange}
              onKeyPress={e => (e.key === "Enter" ? this.handleSave(e) : null)}
            />
          </fieldset>
          <div className="modal__controls">
            <button
              type="button"
              onClick={this.handleClose}
              className="paloma-button modal__button"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={this.handleSave}
              className="paloma-button modal__button modal__button--red"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}
