import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserIcon from "../shared/UserIcon";
import { isImpersonating } from "../../utilities/user";

class NavigationSidebar extends Component {
  render() {
    const {
      logout,
      currentPathname,
      currentUser,
      currentOrganization,
    } = this.props;

    const links = [
      { pathname: "/", title: "Dashboard", activePaths: ["/"] },
      {
        pathname: "/entrypoints",
        title: "Entry Points",
        activePaths: ["/entrypoints", "/acquisition"],
      },
      { pathname: "/global", title: "Triggers", activePaths: ["/global"] },
      { pathname: "/audience", title: "Audience", activePaths: ["/audience"] },
      { pathname: "/products", title: "Inventory", activePaths: ["/products"] },
    ];

    const theReaRealOrgId = 147;
    if (
      currentOrganization &&
      (currentOrganization.organizationId === theReaRealOrgId ||
        currentOrganization.facebookPageName === "Free the Puppies")
    ) {
      links.push({
        pathname: "/fields",
        title: "Fields",
        activePaths: ["/fields"],
      });
    }

    links.push({
      pathname: "/reports",
      title: "Reporting",
      activePaths: ["/reporting"],
    },{
      pathname: "/configuration",
      title: "Configuration",
      activePaths: ["/configuration"],
    });

    if (
      currentUser.admin &&
      !isImpersonating(currentUser, currentOrganization)
    ) {
      links.push({
        pathname: "/organizations/",
        title: "Organizations",
        activePaths: ["/organizations/"],
      });
    }

    const linkIsActive = link => {
      if (link.pathname === "/") {
        return link.pathname === currentPathname;
      }

      for (let index = 0; index < link.activePaths.length; index++) {
        if (currentPathname.startsWith(link.activePaths[index])) {
          return true;
        }
      }

      return false;
    };

    const link = (l, i) => {
      const linkClassName = classNames({
        "navigation-sidebar__item": true,
        "navigation-sidebar__item--active": linkIsActive(l),
      });
      return (
        <Link key={i} className={linkClassName} to={l.pathname}>
          {l.title}
        </Link>
      );
    };

    return (
      <div className="navigation-sidebar">
        <div className="top">
          <Link className="navigation-sidebar__home" to="/" />
          {links.map(link)}
        </div>
        <div className="bottom">
          <div
            className="navigation-sidebar__item logout"
            onClick={() => logout()}
          >
            Logout
            <UserIcon pictureUrl={currentOrganization.facebookPic} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser.currentUser || {},
  currentOrganization: state.currentOrganization,
});

export default connect(mapStateToProps)(NavigationSidebar);
