import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { clearFlash } from "../actions/flash";

class FlashPage extends React.Component {
  render = () => {
    if (!this.props.body) {
      return <Redirect to="/" />;
    }
    return (
      <div className="flash">
        <h1 className="paloma">Paloma</h1>
        <div className="flash-container">
          <h1>{this.props.title}</h1>
          <p>{this.props.body}</p>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    this.props.clearFlash();
  }
}

const mapStateToProps = state => {
  return {
    title: state.flash.title,
    body: state.flash.body,
  };
};

export default connect(
  mapStateToProps,
  { clearFlash }
)(FlashPage);
