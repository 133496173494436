import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import {
  SeparatedButtonRow,
  Toggle,
  Modal,
  SelectGlobalTriggerModal,
} from "../components/shared";
import { BuilderInput } from "../components/ConversationDetail";
import { createEditorState } from "../utilities/editor";
import {
  addCustomPayloadToQuickReply,
  addTriggerToQuickReply,
  deleteQuickReply,
  updateQuickReply,
  updateQuickReplyToEmail,
  updateQuickReplyToPhoneNumber,
  updateQuickReplyToText,
  toggleRandomize,
  moveQuickReply,
} from "../actions/quick-replies";
import { openMenu } from "../actions/builder-menu";
import { QUICK_REPLY } from "../constants/builder-menu";
import { fetchVisibleConversationSteps } from "../actions/conversations";
import MatchingResponsesModal from "./MatchingResponsesModal";

const EMAIL = createEditorState("Email");
const PHONE_NUMBER = createEditorState("Phone Number");

class QuickReply extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: createEditorState(this.props.quickReply.title),
      openMatchingReponses: false,
      openFieldCapture: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      quickReply: { title },
    } = this.props;
    const {
      quickReply: { title: prevTitle },
    } = prevProps;

    if (title !== prevTitle) {
      this.setState({
        editorState: createEditorState(title),
      });
    }
  }

  render() {
    const { errors } = this.props;
    const quickReplyClassName = classNames("quick-reply", {
      error: (errors || []).title,
    });

    return (
      <div className="quick-reply-container">
        <div className={quickReplyClassName} onClick={this.focus}>
          <BuilderInput
            editable={this.editable()}
            editorState={this.editorStateForBuilder()}
            onChange={this.handleChange}
            onEscape={this.blur}
            maxChars={20}
          />
        </div>
        {this.buttonRow()}
        <SelectGlobalTriggerModal
          isOpen={this.state.customPayloadModal}
          onClose={() => this.setState({ customPayloadModal: false })}
          onSave={this.customPayloadSubmit}
        />
        <MatchingResponsesModal
          matchingResponses={this.props.quickReply.matchingResponses}
          isOpen={this.state.openMatchingReponses}
          onClose={() => this.setState({ openMatchingReponses: false })}
          onSubmit={(matchingResponses) =>
            this.props.updateQuickReply({
              id: this.props.quickReply.id,
              matchingResponses,
            })
          }
        />
        {this.fieldCaptureModal()}
      </div>
    );
  }

  focus = () =>
    this.props.openMenu({ id: this.props.quickReply.id, type: QUICK_REPLY });
  blur = async () => {
    if (!this.state.deleted) {
      await this.props.updateQuickReply({
        id: this.props.quickReply.id,
        contentType: this.props.contentType,
        title: JSON.stringify(this.state.editorState.toJSON()),
      });
      this.refreshConverastionSteps();
    }
  };

  handleChange = ({ state: editorState }) => {
    this.setState({ editorState });
  };

  editable = () => {
    if (
      this.contentType() === "user_email" ||
      this.contentType() === "user_phone_number" ||
      this.props.published
    ) {
      return false;
    } else {
      return true;
    }
  };

  editorStateForBuilder = () => {
    if (this.contentType() === "user_email") {
      return EMAIL;
    } else if (this.contentType() === "user_phone_number") {
      return PHONE_NUMBER;
    } else {
      return this.state.editorState;
    }
  };

  customPayloadSubmit = async (triggerId) => {
    const customPayload = `global_trigger_${triggerId}`;
    await this.props.addCustomPayloadToQuickReply(
      this.props.quickReply.id,
      customPayload
    );
    this.refreshConverastionSteps();
  };

  addTrigger = async () => {
    await this.props.addTriggerToQuickReply(this.props.quickReply.id);
    this.refreshConverastionSteps();
  };

  buttons = () => {
    const {
      contentType,
      random,
      updateQuickReply,
      updateQuickReplyToEmail,
      updateQuickReplyToPhoneNumber,
      updateQuickReplyToText,
      quickReply: { id, conversationId },
    } = this.props;

    const buttons = [
      {
        text: "Detour",
        onClick: async () => {
          await updateQuickReply({
            id: this.props.quickReply.id,
            contentType: contentType,
            title: JSON.stringify(this.state.editorState.toJSON()),
          });
          await this.addTrigger();
          this.blur();
        },
      },
      {
        text: "Connect",
        onClick: async () => {
          this.setState({ customPayloadModal: true });
          this.blur();
        },
        selected: this.contentType() === "custom",
      },
      {
        text: "Action",
        nested: [
          {
            text: "Text",
            onClick: async () => {
              await updateQuickReplyToText(this.props.quickReply.id);
              await this.refreshConverastionSteps();
              this.blur();
            },
            selected: this.contentType() === "text",
          },
          {
            text: "Email",
            onClick: async () => {
              await updateQuickReplyToEmail(this.props.quickReply.id);
              await this.refreshConverastionSteps();
              this.blur();
            },
            selected: this.contentType() === "user_email",
          },
          {
            text: "Phone Number",
            onClick: async () => {
              await updateQuickReplyToPhoneNumber(this.props.quickReply.id);
              await this.refreshConverastionSteps();
              this.blur();
            },
            selected: this.contentType() === "user_phone_number",
          },
        ],
      },
      {
        text: (
          <div style={{ display: "flex", alignItems: "center" }}>
            Randomize
            <Toggle
              onChange={this.toggleRandomizeQuickReplyOrder}
              checked={random}
            />
          </div>
        ),
        leaveOpen: true,
        onClick: () => true,
      },
      {
        text: "+ Match Responses",
        onClick: () => this.setState({ openMatchingReponses: true }),
      },
      {
        text: "Field Capture",
        onClick: () => this.setState({ openFieldCapture: true }),
      },
      {
        text: "Delete",
        onClick: () => {
          this.deleteQuickReply();
          this.blur();
        },
      },
    ];

    if (this.props.conversationStep.quickReplies.length > 1) {
      if (
        this.props.index <
        this.props.conversationStep.quickReplies.length - 1
      ) {
        buttons.unshift({
          text: "Move right",
          onClick: () =>
            this.props
              .moveQuickReply(id, {
                move: "right",
              })
              .then(() => {
                if(this.props.refreshSteps) {
                  this.props.refreshSteps()
                } else {
                  this.props.fetchVisibleConversationSteps(conversationId)
                }
              }),
        });
      }

      if (this.props.index > 0) {
        buttons.unshift({
          text: "Move left",
          onClick: () =>
            this.props
              .moveQuickReply(id, {
                move: "left",
              })
              .then(() => {
                if(this.props.refreshSteps) {
                  this.props.refreshSteps()
                } else {
                  this.props.fetchVisibleConversationSteps(conversationId)
                }
              }),
        });
      }
    }

    return buttons;
  };

  buttonRow = () => {
    const { isMenuOpen, published } = this.props;
    if (isMenuOpen && !published) {
      return (
        <SeparatedButtonRow
          className="conversation-step-button__button-types"
          buttons={this.buttons()}
        />
      );
    }
  };

  fieldCaptureModal = () => {
    const onSubmit = (e) => {
      e.preventDefault();
      this.props.updateQuickReply({
        value: e.target.value || null,
        id: this.props.quickReply.id,
      });
    };

    return (
      <Modal
        title="Edit Field Capture"
        isOpen={this.state.openFieldCapture}
        onClose={() => this.setState({ openFieldCapture: false })}
      >
        <div className="modal__controls">
          <form
            className="modal__form modal__form--wide"
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ openFieldCapture: false });
            }}
          >
            <label className="default-form__label" htmlFor="entry">
              Entry
            </label>
            <input
              className="default-form__input"
              defaultValue={
                this.props.quickReply.value || this.props.quickReply.parsedTitle
              }
              type="text"
              name="entry"
              id="payload"
              onChange={onSubmit}
            />
            <div className="modal__footer">
              <button
                type="button"
                className="paloma-button paloma-button--white modal__button"
                onClick={() => this.setState({ openFieldCapture: false })}
              >
                Cancel
              </button>
              <button
                className="paloma-button paloma-button--pink modal__button"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  toggleRandomizeQuickReplyOrder = async (random) => {
    const {
      quickReply: { conversationStepId },
      toggleRandomize,
    } = this.props;

    await toggleRandomize(conversationStepId, random);
    this.refreshConverastionSteps();
  };

  refreshConverastionSteps = async () => {
    const {
      quickReply: { conversationId },
      fetchVisibleConversationSteps,
      refreshSteps,
    } = this.props;

    return refreshSteps
      ? refreshSteps()
      : fetchVisibleConversationSteps(conversationId);
  };

  contentType = () => {
    if (this.props.quickReply.triggerId) {
      return "trigger";
    } else if (this.props.quickReply.customPayload) {
      return "custom";
    } else {
      return this.props.quickReply.contentType;
    }
  };

  deleteQuickReply = async () => {
    await this.setState({ deleted: true });
    this.props.deleteQuickReply(this.props.quickReply.id);
  };

  handleTitleChange = ({ state }) => {
    this.setState({ state });
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    builderMenu: { id: menuId, type: menuType },
  } = state;
  const isMenuOpen =
    menuId === ownProps.quickReply.id && menuType === QUICK_REPLY;
  const conversation =
    state.conversations.conversationsById[ownProps.quickReply.conversationId];
  const published = conversation.published;
  return {
    isMenuOpen,
    published: published || "",
  };
};

export default connect(mapStateToProps, {
  addCustomPayloadToQuickReply,
  addTriggerToQuickReply,
  deleteQuickReply,
  fetchVisibleConversationSteps,
  openMenu,
  toggleRandomize,
  updateQuickReply,
  updateQuickReplyToEmail,
  updateQuickReplyToPhoneNumber,
  updateQuickReplyToText,
  moveQuickReply,
})(QuickReply);
