import moment from "moment";

const ordersSummary = allOrders => {
  let summary = { total: 0, lastOrderPlacedAt: null };
  (allOrders || []).forEach(order => {
    summary.total = summary.total + order.total / 100.0;
    if (
      !summary.lastOrderPlacedAt ||
      summary.lastOrderPlacedAt < moment.utc(order.createdAt)
    ) {
      summary.lastOrderPlacedAt = moment.utc(order.createdAt);
    }
  });
  return summary;
};

export default ordersSummary;
