import React from "react";
import { Route } from "react-router-dom";

// reference: https://reacttraining.com/react-router/web/example/route-config

export default route => (
  <Route
    path={route.path}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);
