import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader";

import { ConversationsTable, Card } from "../../components/shared";
import { ContentHeader } from "../../components/shared";
import { fetchActiveSignupConversations } from "../../actions/active-signup-conversations";
import { fetchInactiveSignupConversations } from "../../actions/inactive-signup-conversations";
import {
  createManualMessagingAcquisitionAction,
  deleteManualMessagingAcquisitionAction,
  createThreadControlPassAcquisitionAction,
  deleteThreadControlPassAcquisitionAction,
  fetchAcquisitionActions,
} from "../../actions/acquisition-actions";
import GlobalTriggerToggle from "../../components/GlobalTriggerDetail/GlobalTriggerToggle";

class AcquisitionContainer extends React.Component {
  componentDidMount() {
    this.props.fetchAcquisitionActions();
    this.props.fetchInactiveSignupConversations();
    this.props.fetchActiveSignupConversations();
  }

  render() {
    const {
      activeSignupConversations,
      inactiveSignupConversations,
      manualMessagingActivation,
      threadControlPass,
      loading,
    } = this.props;

    return (
      <Loader loaded={!loading} loadedClassName="acquisition">
        <ContentHeader
          left={<h1 className="page-title">Organic</h1>}
          right={
            <Link className="paloma-button--pink" to="/acquisition/signup/new">
              + New
            </Link>
          }
        />
        <Card className="info-panel">
          <GlobalTriggerToggle
            onToggle={this.updateGlobalTriggerManualMessagingActivation}
            isToggled={manualMessagingActivation}
            title="Turn on Live Chat"
            bodyText="I would like live chat to be turned on when someone sends a matching response to this trigger"
          />
        </Card>
        <Card className="info-panel">
          <GlobalTriggerToggle
            onToggle={this.updateGlobalTriggerThreadControlPass}
            isToggled={threadControlPass}
            title="Pass Control to Another Application"
            bodyText="I would like to pass control to another application when someone sends a matching response to this trigger"
          />
        </Card>
        <Card className="info-panel">
          <ContentHeader left={<h3>Conversations</h3>} />
          {activeSignupConversations.length ? (
            <ConversationsTable
              conversations={activeSignupConversations}
              title="Active"
              showDelete={false}
            />
          ) : (
            <div className="empty-table">
              <div className="empty-table-text">
                You haven't created a signup conversation yet.
              </div>
              <Link
                className="paloma-button empty-table-button"
                to="/acquisition/signup/new"
              >
                + New
              </Link>
            </div>
          )}
          <ConversationsTable
            conversations={inactiveSignupConversations}
            title="Inactive"
          />
        </Card>
      </Loader>
    );
  }

  updateGlobalTriggerManualMessagingActivation = () => {
    const { manualMessagingActivation } = this.props;
    if (!manualMessagingActivation) {
      this.props.createManualMessagingAcquisitionAction();
    } else {
      this.props.deleteManualMessagingAcquisitionAction();
    }
  };

  updateGlobalTriggerThreadControlPass = () => {
    const { threadControlPass } = this.props;
    if (!!threadControlPass) {
      this.props.deleteThreadControlPassAcquisitionAction();
    } else {
      this.props.createThreadControlPassAcquisitionAction();
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    inactiveSignupConversations: {
      loading: inactiveLoading,
      data: inactiveSignupConversations,
    },
    activeSignupConversations: {
      loading: activeLoading,
      data: activeSignupConversations,
    },
  } = state;

  const manualMessagingActivation = state.acquisitionActions
    .map(action => action.type)
    .includes("manual_messaging_activation");
  const threadControlPass = state.acquisitionActions
    .map(action => action.type)
    .includes("thread_control_pass");

  return {
    inactiveSignupConversations,
    activeSignupConversations,
    loading: activeLoading || inactiveLoading,
    manualMessagingActivation,
    threadControlPass,
  };
};

export default connect(mapStateToProps, {
  createManualMessagingAcquisitionAction,
  createThreadControlPassAcquisitionAction,
  deleteManualMessagingAcquisitionAction,
  deleteThreadControlPassAcquisitionAction,
  fetchAcquisitionActions,
  fetchActiveSignupConversations,
  fetchInactiveSignupConversations,
})(AcquisitionContainer);
